/* eslint-disable no-undef */
(function () {
	'use strict';

	angular.module('smartbrokr')
	.controller('SellerController', function ($filter, $scope, $sce, AccountService, AlertService, ModalService, SellerService, GlobalVars, SortingService, FileService, StorageService, UserService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.sellers		= null;		// Data that is shown to user (filtered)

		self.filters		= null;		// Parameters for sort/filter
		self.status			= null;		// Filter options for listing status
		self.coOwner		= null;		// Filter options for co-owner
		self.language		= null;		// Filter options for user language

		self.singleConfig	= null;		// Selectize config

		self.sortInfo		= {};		// Sort info to pass to list/card view directives
		self.navParams		= {};		// Params for navigation when a list item is clicked
		self.cardView		= false;	// Whether to show card view or not
		self.columns		= null;		// Columns to send to list/card view directives

		let dropdowns 		= {};

		// SETUP VARIABLES ======================================================================================================================

		const page 			= 'sellers';
		self.singleConfig	= GlobalVars.singleConfig;
		self.cardView 		= StorageService.getView(page) == 'card';
		// self.filters 		= SortingService.getPageFilter(page);

		dropdowns = {
			config: GlobalVars.singleConfig,
			status: [],
			langs: [],
			cities: []
		}

		if (!self.filters) {
			self.filters = SortingService.createPageFilter(
				page,
				{
					firstName: { weight: 2, value: '', desc: -1 },
					lastName: { weight: 1, value: '', desc: -1 },
					addressString: { weight: 0, value: '', desc: 1 },
					listingId: { weight: 0, value: '', desc: 1 },
					listingType: { weight: 0, value: '', desc: 1 },
					status: { weight: 0, value: '', desc: 1 },
					email: { weight: 0, value: '', desc: 1 },
					phone: { weight: 0, value: '', desc: 1, phone: true },
					coOwners: { weight: 0, value: '', desc: 1, array: true },
					languageIds: { weight: 0, value: '', desc: 1, array: true, sortFunction: _getLangField },
					city: { weight: 0, value: '', desc: 1 },
					postalCode: { weight: 0, value: '', desc: 1 },
					emailEnabled: { weight: 0, value: '', desc: 1 },
				}
			);
		}

		self.sortInfo = {
			page: page,
			sub: ['user','listing','listing.property._address']
		}

		self.navParams = {
			state: 'main.profile',
			getParams: function(seller) {
				return { id: seller.id, role: 'seller' };
			}
		}

		self.bulkEmail = function() {
			if(!AccountService.limitedAccess()) {
				UserService.processBulkEmails($filter('searchMultiFilter')(self.sellers, self.filters));
			}
		}

		AlertService.loading();

		SellerService.getSellers().then((res) => {

			res = res || [];
			self.sellers = res.reduce((arr, curr) => {

				const seller = angular.copy(curr);

				const listing = (seller.listings || [])[0] || {};
				seller.listing = listing;
				seller.listingId = listing.id;
				seller.coOwners = (listing.sellers || []).reduce((ls, s) => {

					const name = ((s || {}).user || {}).fullName;

					if (!!name && name != (seller.user || {}).fullName) {
						ls.push(name);
					}

					return ls;
				}, []).sort();

				if (!!listing.id) {
					seller.listingType = (listing.id || '').includes('S') ? 'Smart' : 'MLS';
				}

				delete seller.listings;
				arr.push(seller);

				return arr;
			}, []);

			let aux = $filter('unique')(self.sellers, 'listing.status');
			dropdowns.status = aux.reduce((arr, status) => {
				arr.push({ name: $filter('translate')('LISTING.STATUS.' + (status || '').toUpperCase()), value: status });
				return arr;
			}, [{ name: '------', value: 'xxx' }]);

			aux = $filter('unique')(self.sellers, 'user.languages');
			dropdowns.langs = aux.reduce((arr, lang) => {
				arr.push({ name: $filter('translate')('LANGUAGES.' + lang.id), value: lang.id });
				return arr;
			}, [{ name: '------', value: 'xxx' }]);

			aux = $filter('unique')(self.sellers, 'listing.property._address.city');
			dropdowns.cities = aux.reduce((arr, city) => {
				arr.push({ name: city.name, value: city.id });
				return arr;
			}, [{ name: '------', value: 'xxx' }]);

			SortingService.sort(self.sellers,self.filters,null,['user','listing','listing.property._address'],page,true);

			self.columns = [
				{
					name: 'PERSON.FIRST_NAME',
					filter: 'firstName',
					cardView: true,
					field: function(seller) {
						return seller.user.firstName;
					}
				},
				{
					name: 'PERSON.LAST_NAME',
					filter: 'lastName',
					cardView: true,
					field: function(seller) {
						return seller.user.lastName;
					}
				},
				{
					name: 'EMAIL.EMAIL',
					filter: 'email',
					isEmail: true
				},
				{
					name: 'PERSON.PHONE',
					filter: 'phone',
					field: function(seller) {
						return $filter('getOnePhone')(seller.user.phones);
					}
				},
				{
					name: 'COMMON.STATUS',
					filter: 'status',
					select: true,
					cardView: true,
					config: dropdowns.config,
					options: dropdowns.status,
					field: function(seller) {
						if (!seller.listing.status) return '';

						return $filter('translate')('LISTING.STATUS.' + ((seller.listing || {}).status || '').toUpperCase());
					}
				},
				{
					name: 'COMMON.TYPE',
					filter: 'listingType',
					select: true,
					cardView: true,
					config: dropdowns.config,
					options: [{ name: '------', value: '' },{ name: 'MLS', value: 'MLS' }, { name: 'Smart', value: 'Smart' }],
					field: function(seller) {
						return seller.listingType;
					}
				},
				{
					name: 'LISTING.LISTING_NO',
					filter: 'listingId',
					cardView: false,
					isHtml: true,
					field: function(seller) {
						if ((seller.listing || {}).id) {
							return '<a class="hover-underline" ' +
									'ng-click="$event.stopPropagation();" ' +
									'ui-sref="main.listings.detail.submenu({ id: \'' + seller.listing.id + '\', tab: 4 })" ' +
									'target="_blank">' + seller.listing.id + '</a>';
						}
						else {
							return '';
						}
					}
				},
				{
					name: 'ADDRESS.ADDRESS',
					filter: 'addressString',
					cardView: true,
					field: function(seller) {
						return (((seller.listing || {}).property || {})._address || {}).addressString || '';
					}
				},
				{
					name: 'ADDRESS.CITY',
					filter: 'city',
					select: true,
					options: dropdowns.cities,
					config: dropdowns.config,
					field: function(seller) {
						return ((((seller.listing || {}).property || {})._address || {}).city || {}).name || '';
					}
				},
				{
					name: 'ADDRESS.POSTAL_CODE',
					filter: 'postalCode',
					field: function(seller) {
						return (((seller.listing || {}).property || {})._address || {}).postalCode || '';
					}
				},
				{
					name: 'PERSON.SELLER.CO_OWNER',
					filter: 'coOwners',
					cardView: false,
					isHtml: true,
					field: function(seller) {
						if (seller.coOwners.length === 0) return '';

						return seller.coOwners.reduce((str, curr) => {
							str += curr + '<br>';
							return str;
						}, '');
					}
				},
				{
					name: 'PERSON.LANGUAGE',
					filter: 'languageIds',
					cardView: true,
					select: true,
					isHtml: true,
					config: dropdowns.config,
					options: dropdowns.langs,
					field: _getLangField
				},
				{
					nameImgClass: 'btn-list-icon do-not-email',
					nameImgPopover: 'btnDoNotEmail',
					filter: 'emailEnabled',
					noSearch: true,
					size: 1,
					class: 'check',
					style: { 'text-align': 'center' },
					isHtml: true,
					field: function(seller) {
						return seller.user.emailEnabled ? '' : '<div class="icon-checked"></div>';
					}
				},
			]

		})
		.catch((err) => {
			console.log('err: ', err);
		})
		.finally(AlertService.doneLoading);

		// FUNCTIONS ============================================================================================================================

		function _getLangField(seller) {
			const langs = (((seller || {}).user || {}).languages || []).reduce((arr, lang) => {
				arr.push('' + $filter('translate')('LANGUAGES.' + lang.id));
				return arr;
			}, []).sort();

			if (langs.length === 0) {
				return '---';
			}

			return langs.join('<br>');
		}

		// Export csv - Complete
		$scope.exportComplete = function () {
			self.isPopoverOpen = false;
			const exportFilter = self.filters;
			exportFilter.checked = { weight: 0, value: 'true', desc: true };
			const toExport = $filter('searchMultiFilter')(self.sellers, exportFilter);
			if(toExport.length > 0) {
				FileService.exportComplete(toExport, 'seller');
			} else {
				const modal = ModalService.openModal('nothingToExport', { folder: function () { return {} }, type: function() { return '' }, options: function() { return { } } }, 'FolderController', 'folderController');
				modal.result
				.then((res) => {})
				.catch((err) => {});
			}
		};

		// Export csv - Simplified (first name, last name and email)
		$scope.exportSimple = function() {
			self.isPopoverOpen = false;
			const exportFilter = self.filters;
			exportFilter.checked = { weight: 0, value: 'true', desc: true };
			const toExport = $filter('searchMultiFilter')(self.sellers, exportFilter);
			if(toExport.length > 0) {
				FileService.exportSimple(toExport, 'ROLES.SELLER');
			} else {
				const modal = ModalService.openModal('nothingToExport', { folder: function () { return {} }, type: function() { return '' }, options: function() { return { } } }, 'FolderController', 'folderController');
				modal.result
				.then((res) => {})
				.catch((err) => {});
			}
		}

	}) // End controller
})(); // End of function()
