/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('NotificationsController', function ($filter, $sce, $state, $rootScope, AlertService, GlobalVars, MessageService, socket) {

		const self = this;

		// SETUP VARIABLES ======================================================================================================================

		self.notifications  = [];           // Array of notifications that being displayed in the current page
		self.count          = 0;            // Total count of notifications
		self.totalPages     = 1;            // Total number of pages
		self.perPage        = 15;           // Number of notifications displayed per page
		self.currentPage    = 1;            // The current page being displayed
		self.maxSize        = $rootScope.screenSize === 'xs' || $rootScope.screenSize === 'sm' ? 1 : 5;    // Max size of pagination buttons that can be displayed
		self.allSelected    = false;        // True if all notifications are selected (for deleting)
		self.oneSelected    = false;        // True if at least one notification is selected (for deleting)
		let selected        = [];           // Array containing information on whether a notification is selected or not
		self.startDate;
		self.endDate;
		self.selectedFilter = '';
		self.selectedTypes = [];

		const shared_listing_types = ['listing_shared_approved', 'listing_shared_cancelled', 'listing_shared_init', 'listing_shared_rejected'];

		socket.on('notification-new', _getCount);

		self.filterConfig = GlobalVars.createSingleConfig(false, 'id', 'name', 'name');
		self.startDateCalendar = GlobalVars.createCalendarConfig(null);
		self.endDateCalendar = GlobalVars.createCalendarConfig(null);

		self.dateSelect = function(key) {
			_getCount();
		}

		self.filterConfig.onChange = function(value) {
			switch(value) {
			case 'expiry':
				self.selectedTypes = ['listing_expiry_past', 'listing_expiry_today', 'listing_expiry_soon'];
				break;
			case 'appointments':
				self.selectedTypes = ['appointment_soon', 'appointment_today'];
				break;
			case 'closed':
				self.selectedTypes = ['listing_closing_today', 'listing_closing_soon'];
				break;
			case 'visits':
				self.selectedTypes = ['visit_soon', 'visit_today', 'visit_confirmed', 'visit_cancelled'];
				break;
			case 'personal':
				self.selectedTypes = ['personal_soon', 'personal_today'];
				break;
			case 'tasks':
				self.selectedTypes = ['task_due_soon', 'task_due_today', 'task_due_past'];
				break;
			case 'messages':
				self.selectedTypes = ['message_received'];
				break;
			case 'wordpress_lead':
				self.selectedTypes = ['contact_form_submitted'];
				break;
			}

			_getCount();
		}

		self.filterConfig.render = {
			option: function(data, escape) {
				let item = '<div class="item filter-item filter-option" data-value="' + data['id'] + '">';
				item += '<div class="filter-icon ' + data.class + '"></div>';
				item += '<span class="filter-label">' + data.name + '</span>';
				item += '</div>';
				return item;
			},
			item: function(data, escape) {
				let item = '<div class="item filter-item" data-value="' + data['id'] + '">';
				item += '<div class="filter-icon ' + data.class + '"></div>';
				item += '<span class="filter-label">' + data.name + '</span>';
				item += '</div>';
				return item;
			}
		}

		self.filterConfig.openOnFocus = true;


		self.notificationTypes = [
			{
				id: 'appointments',
				name: $filter('translate')('LISTING.APPOINTMENTS'),
				class: 'appointments-icon'
			},
			{
				id: 'visits',
				name: $filter('translate')('LISTING.DETAILS_MENU.VISITS'),
				class: 'visit-icon'
			},
			{
				id: 'personal',
				name: $filter('translate')('PERSON.PERSONAL'),
				class: 'personal-icon'
			},
			{
				id: 'expiry',
				name: $filter('translate')('LISTING.LISTING') + ' - ' + $filter('translate')('DATES.EXPIRY'),
				class: 'listing-expiry-icon'
			},
			{
				id: 'closed',
				name: $filter('translate')('LISTING.LISTING') + ' - ' + $filter('translate')('TASK.TASK_LIST.CLOSED'),
				class: 'listing-closed-icon'
			},
			{
				id: 'tasks',
				name: $filter('translate')('TASK.TASKS'),
				class: 'tasks-icon'
			},
			{
				id: 'messages',
				name: $filter('translate')('MESSAGES.TITLE'),
				class: 'messages-icon'
			},
			{
				id: 'wordpress_lead',
				name: $filter('translate')('NOTIFICATIONS.FILTER.WORDPRESS_LEAD'),
				class: 'personal-icon'
			}
		]

		_getCount();

		// FUNCTIONS ============================================================================================================================

		/**
         *  Deletes one notification based on its ID.
         *  Prompts a confirm window before deleting.
         *  After deleting the notification, the data is reloaded
         *  @param  {string}    ndId    The notification ID
         */
		self.deleteOne = function(nId) {
			MessageService.deleteNotification(nId).then((del) => {
				if (del) {
					AlertService.success('NOTIFICATIONS.NOTIFICATION','delete');
					_getCount();
				}
			})
		}

		/**
         *  Deletes all selected notifications.
         *  Prompts a confirm window before deleting.
         *  After deleting the notifications, the data is reloaded
         */
		self.deleteSelected = function() {
			MessageService.deleteSelected(selected).then((del) => {
				if (del) {
					AlertService.success('NOTIFICATIONS.NOTIFICATION','delete');
					_getCount();
				}
			})
		}

		/**
         *  Retrieves the formatted data to display a notification in the page.
         *  @param  {object}    notification    The notification entry as it was retrieved from the DB
         *  @return {object}                    Formatted object to display the notification
         */
		self.getNotification = function(notification) {
			notification = notification || {};
			const ret = {
				id: notification.id,
				image: {
					type: '',
					photoUrl: '',
				},
				data: notification.data || {},
				top: '',
				read: notification.isRead,
				bottom: '',
				created: $filter('dateFormatMoment')(notification.created, 'll') + ' <wbr>' + $filter('dateFormatMoment')(notification.created, 'LT'),
				type: notification.type
			}

			if(notification.contactForm) return _getContact(ret, notification);
			if(notification.type === 'visit_confirmed' || notification.type === 'visit_cancelled') {
				return _getConfirmedVisit(ret, notification.related, notification.type === 'visit_confirmed', notification.relatedType);
			}

			switch(notification.relatedType) {
			case 'Listing':
				return _getListing(ret, notification || {});
			case 'Message':
				return _getMessage(ret, notification.related || {});
			case 'PersonalEvent':
				return _getPersonal(ret, notification || {});
			case 'SmartFile':
				return _getVideo(ret, notification.related || {}, notification.hasError);
			case 'Task':
				return _getTask(ret, notification || {});
			case 'Visit':
				return _getVisit(ret, notification || {});
			case 'Buyer':
				return _getWpBuyer(ret, notification.related || {});
			case 'Seller':
				return _getWpSeller(ret, notification.related || {});
			case 'Supplier':
				return _getWpSupplier(ret, notification.related || {});
			}
			return ret;
		}

		/**
         *  Marks all notifications as read.
         *  Calls "turnPage" after saving so the current page is reloaded with all notifications marked as read.
         */
		self.markAllAsRead = function() {
			MessageService.readAllNotifications().then((res) => {
			})
			.finally(self.turnPage);
		}

		/**
         *  Marks one notification as read.
         *  After saving, the notification is flagged as read.
         *  @param  {object}    notification    The notification that will be read
         */
		self.markAsRead = function(notification) {
			if (!notification.read) {
				MessageService.readNotification(notification.id).then((res) => {
					notification.read = true;
				})
			}
		}

		/**
         *  Selects/Deselects all notifications for deleting.
         */
		self.selectAll = function() {
			self.allSelected = !self.allSelected;

			const pageIndex = (self.currentPage - 1) * self.perPage;

			for (const i in selected) {
				const j = i - pageIndex;

				if (j >= 0 && !!self.notifications[j]) {
					self.notifications[j].selected = self.allSelected;
					selected[i] = self.allSelected ? self.notifications[j].id : false;
				}
				else {
					selected[i] = self.allSelected ? (selected[i] || true) : false
				}
			}

			self.oneSelected = self.allSelected;
		}

		/**
         *  Selects/Deselects one notification for deleting.
         *  @param  {object}    notification    The notification that is getting selected
         *  @param  {number}    i               The index number of this notification
         */
		self.selectOne = function(notification, i) {
			notification.selected = !notification.selected;
			const index = i + ((self.currentPage - 1) * self.perPage);
			selected[index] = notification.selected ? notification.id : false;
			self.allSelected = !selected.includes(false);
			self.oneSelected = self.allSelected || notification.selected || selected.findIndex(x => !!x) >= 0;
		}

		/**
         *  Loads data for the current page in the pagination.
         */
		self.turnPage = function() {
			const skip = (self.currentPage - 1) * self.perPage;
			MessageService.getNotifications(skip, self.perPage, self.selectedTypes, self.startDate, self.endDate).then((res) => {
				self.notifications = res.reduce((arr, notification, i) => {
					if (!!selected[skip + i]) {
						selected[skip+i] = notification.id;
						notification.selected = true;
					}

					arr.push(notification);
					return arr;
				}, []);
			})
		}

		/**
         *  Gets the total number of notifications that the user has.
         *  This number is used to determine the number of pages for pagination
         */
		function _getCount() {
			MessageService.getNotificationCount(null, self.selectedTypes, self.startDate, self.endDate).then((res) => {
				self.count = res.count;
				self.totalPages = (self.count / self.perPage) + 1;
				if (self.currentPage > self.totalPages) {
					self.currentPage = 1;
				}
				selected = new Array(res.count).fill(false);
				self.allSelected = false;
				self.turnPage();
			})
		}

		self.clearFilter = function() {
			self.startDate = null;
			self.endDate = null;
			self.selectedFilter = '';
			self.selectedTypes = [];
			_getCount();
		}

		function _getContact(obj, notification) {
			obj.image = {
				type: notification.relatedType.toLowerCase()
			}

			const buyer = (notification.related || {}).user || (notification.related || {}).owner ||  {};

			obj.top = 'Wordpress contact form submitted (Wordpress)';

			const justHTML = $sce.trustAsHtml('<email person="notification.' + obj.image.type + '" function="smartbrokrController.sendMessage">' + buyer.email + '</email>');
			const phone = $sce.trustAsHtml('<a href="tel:' +  + '"></a>')
			if(notification.contactMessage) {
				const message = $sce.trustAsHtml('<p class="mb-0">' + notification.contactMessage + '</p>');
				obj.bottom = $sce.trustAsHtml( (buyer.fullName || 'N/A') + ' (' + justHTML + ')' + message);
			} else {
				obj.bottom = $sce.trustAsHtml((buyer.fullName || 'N/A') + ' (' + justHTML + ')');
			}
			obj[obj.image.type] = buyer;

			if(obj.image.type === 'supplier') {
				obj.link = $state.href('main.suppliers.profile', { role: obj.image.type, id: notification.related.id });
			} else {
				obj.link = $state.href('main.profile', { role: obj.image.type, id: notification.related.id });
			}

			return obj;
		}

		/**
         *  Gets the formatted notification for a Listing as it will be displayed in the page.
         *  @param  {object}     obj            Object that will be returned
         *  @param  {object}     notification   The notification as it was retrieved from the DB
         *  @return {object}                    The formatted notification to be displayed in the page
         */
		function _getListing(obj, notification) {
			if(shared_listing_types.indexOf(notification.type) > -1) {
				return doSharedListingNotification(obj, notification);
			}
			const split       = (notification.type || '').split('_');     // structure: listing_[type]_[frequency] or listing_[frequency] (old notifications)
			const frequency   = split.pop();
			const type        = split.pop();
			const related     = notification.related || {};
			const mainPhoto   = ((related.websitePhotos || [])[0] || (related.mlsPhotos || [])[0] || {}).file || {};

			const what        = '' + $filter('translate')('LISTING.LISTING');
			const link        = $state.href('main.listings.detail.submenu', { id: related.id });
			const verb        = '' + $filter('translate')('NOTIFICATIONS.VERBS.' + (type == 'closing' ? 'CLOSES' : 'EXPIRES' ));

			obj.bottom  = related.id + ' | ' + ((related.property || {})._address || {}).addressString;
			obj.link    = link;

			obj.image.photoUrl = mainPhoto.url;

			if (!mainPhoto.url) {
				obj.image = {
					icon: true,
					class: frequency,
					iconImg: 'iconListings.png'
				}
			}

			if (frequency == 'past') {
				obj.top = '' + $filter('translate')('NOTIFICATIONS.TOP_OVERDUE', { what: what });
			}
			else {
				obj.top = '' + $filter('translate')('NOTIFICATIONS.TOP_FREQUENCY', { what: what, verb: verb, frequency: frequency, days: notification.days });
			}

			return obj;
		}

		/**
         *  Gets the formatted notification for a Message as it will be displayed in the page.
         *  @param  {object}     obj            Object that will be returned
         *  @param  {object}     notification   The notification as it was retrieved from the DB
         *  @return {object}                    The formatted notification to be displayed in the page
         */
		function _getMessage(obj, related) {
			related = related || {};
			obj = obj || {};

			const link    = $state.href('main.messages') + '#' + related.conversationId;
			const sender  = '' + $filter('firstNameOnly')((related.sender || {}).fullName || '');

			obj.bottom  = related.text || '';
			obj.top     = $filter('translate')('NOTIFICATIONS.TOP_MESSAGE', { name: sender });
			obj.link    = link;

			obj.image.type      = 'agency';
			obj.image.photoUrl  = (related.sender || {}).photoUrl;

			return obj;
		}

		/**
         *  Gets the formatted notification for a PersonalEvent as it will be displayed in the page.
         *  @param  {object}     obj            Object that will be returned
         *  @param  {object}     notification   The notification as it was retrieved from the DB
         *  @return {object}                    The formatted notification to be displayed in the page
         */
		function _getPersonal(obj, notification) {

			const related     = notification.related || {};
			const frequency   = (notification.type || '').split('_').pop();
			const what        = '' + $filter('translate')('PERSON.PERSONAL');
			const verb        = '' + $filter('translate')('NOTIFICATIONS.VERBS.SCHEDULED');
			const link        = $state.href('main.calendar') + '#' + new Date(related.start).toISOString();

			obj.image = {
				icon: true,
				class: frequency,
				iconImg: 'icons/calendar-white.svg'
			}

			obj.top     = '' + $filter('translate')('NOTIFICATIONS.TOP_FREQUENCY', { what: what, verb: verb, frequency: frequency, days: notification.days });
			obj.bottom  = '' + $filter('date')(related.start, 'H:mm') + ' ' + (related.subject || '');
			obj.link    = link;

			return obj;
		}

		/**
         *  Gets the formatted notification for a Task as it will be displayed in the page.
         *  @param  {object}     obj            Object that will be returned
         *  @param  {object}     notification   The notification as it was retrieved from the DB
         *  @return {object}                    The formatted notification to be displayed in the page
         */
		function _getTask(obj, notification) {
			const related     = notification.related || {};
			const frequency   = (notification.type || '').split('_').pop();
			const what        = '' + $filter('translate')('TASK.TASK');
			const verb        = '' + $filter('translate')('NOTIFICATIONS.VERBS.DUE');
			const link        = $state.href('main.tasks.view', { taskId: related.id, hash: related.aboutType });

			obj.top     = '' + $filter('translate')('NOTIFICATIONS.TOP_FREQUENCY', { what: what, verb: verb, frequency: frequency, days: notification.days });
			obj.bottom  = '' + $filter('translate')(related.name);
			obj.link    = link;

			let mainPhoto = null;

			if (related.listingId) {
				const listing = related.listing || {};
				const photos = listing.websitePhotos || listing.mlsPhotos || [];
				mainPhoto = ((photos[0] || {}).file || {}).url;
			}
			else if (related.aboutType == 'Supplier') {
				mainPhoto = (related.about || {}).photoUrl;
			}
			else {
				mainPhoto = ((related.about || {}).user || {}).photoUrl;
			}

			if (!mainPhoto) {
				obj.image = {
					icon: true,
					class: frequency,
					iconImg: 'iconTasks.png'
				}
			}
			else {
				obj.image.photoUrl = mainPhoto;
				obj.image.type  = 'person';
			}

			return obj;
		}

		/**
         *  Gets the formatted notification for a Video as it will be displayed in the page.
         *  @param  {object}     obj            Object that will be returned
         *  @param  {object}     notification   The notification as it was retrieved from the DB
         *  @return {object}                    The formatted notification to be displayed in the page
         */
		function _getVideo(obj, related, error) {
			related = related || {};
			obj = obj || {};

			if (related.listingId) {
				const listing = related.listing || {};
				obj.bottom  = related.listingId + ' | ' + ((listing.property || {})._address || {}).addressString;
			}
			else {
				obj.bottom = $filter('translate')('PAGES.RESOURCES.SUB.MEDIA_MANAGER') + ': ' + (related.originalFilename || related.name);
			}

			obj.image.video = true;
			obj.link    = related.url;

			if (!!error) {
				obj.top = '<span class="text-red">' + $filter('translate')('ALERT_MESSAGES.ERROR.SERVER.Listing.generateVideo.400') + '</span>';
				obj.bottom = $filter('translate')('ALERT_MESSAGES.ERROR.CLIENT.TRY_AGAIN');
				obj.image = {
					icon: true,
					class: 'past',
					iconImg: 'iconHelp.png'
				}
			}
			else {
				obj.top = $filter('translate')('NOTIFICATIONS.TOP_VIDEO');
			}

			return obj;
		}

		/**
         *  Gets the formatted notification for a Visit as it will be displayed in the page.
         *  @param  {object}     obj            Object that will be returned
         *  @param  {object}     notification   The notification as it was retrieved from the DB
         *  @return {object}                    The formatted notification to be displayed in the page
         */
		function _getVisit(obj, notification) {
			const type        = (notification.type || '').split('_');
			const related     = notification.related || {};
			const frequency   = type.pop();
			const verb        = '' + $filter('translate')('NOTIFICATIONS.VERBS.SCHEDULED');
			let what        = type.shift();
			const link        = $state.href('main.calendar') + '#' + new Date(related.scheduled).toISOString();

			if (what == 'appointment') {
				what = '' + $filter('translate')('LISTING.APPOINTMENT');
			}
			else {
				what = '' + $filter('translate')('COMMON.VISIT');
			}

			obj.image = {
				icon: true,
				class: frequency,
				iconImg: 'icons/calendar-white.svg'
			}

			obj.top     = '' + $filter('translate')('NOTIFICATIONS.TOP_FREQUENCY', { what: what, verb: verb, frequency: frequency, days: notification.days });
			obj.bottom  = (((related.users || [])[0] || {}).fullName || 'N/A') + ' @ ' + related.location;
			obj.link    = link;

			return obj;
		}

		function doSharedListingNotification(obj, notification) {
			const related     = notification.related || {};
			const mainPhoto   = ((related.websitePhotos || [])[0] || (related.mlsPhotos || [])[0] || {}).file || {};

			let link        = $state.href('main.listings.detail.submenu', { id: related.id, submenu: notification.related.status === 'Smart' ? 'smart': 'inventory' });
			if(notification.type === 'listing_shared_cancelled' || notification.type === 'listing_shared_init') {
				link = $state.href('main.listings.detail.submenu', { id: related.id, submenu: 'shared' });
			}

			obj.bottom  = related.id + ' | ' + ((related.property || {})._address || {}).addressString;
			obj.link    = link;

			obj.image.photoUrl = mainPhoto.url;

			if (!mainPhoto.url) {
				obj.image = {
					icon: true,
					class: 'today',
					iconImg: 'iconListings.png'
				}
			}

			let notificationMessage = '';

			switch(notification.type) {
			case 'listing_shared_approved':
				notificationMessage = $filter('translate')('NOTIFICATIONS.TOP_SHARED_APPROVED');
				break;
			case 'listing_shared_cancelled':
				notificationMessage = $filter('translate')('NOTIFICATIONS.TOP_SHARED_CANCELLED');
				break;
			case 'listing_shared_init':
				notificationMessage = $filter('translate')('NOTIFICATIONS.TOP_SHARED');
				break;
			case 'listing_shared_rejected':
				notificationMessage = $filter('translate')('NOTIFICATIONS.TOP_SHARED_REJECTED');
				break;
			}

			obj.top = notificationMessage

			return obj;
		}

		function _getWpBuyer(obj, related) {
			obj.image = {
				type: 'buyer'
			}

			const buyer = (related || {}).user || {};

			obj.top = '' + $filter('translate')('NOTIFICATIONS.TOP_WP_BUYER');
			obj.bottom = $sce.trustAsHtml((buyer.fullName || 'N/A') + ' (<email person="notification.buyer" function="smartbrokrController.sendMessage"></email>)');
			obj.buyer = buyer;
			obj.link = $state.href('main.profile', { role: 'buyer', id: related.id });

			return obj;
		}

		function _getWpSeller(obj, related) {
			obj.image = {
				type: 'seller'
			}

			const seller = (related || {}).user || {};

			obj.top = '' + $filter('translate')('NOTIFICATIONS.TOP_WP_SELLER');
			obj.bottom = $sce.trustAsHtml((seller.fullName || 'N/A') + ' (<email person="notification.seller" function="smartbrokrController.sendMessage"></email>)');
			obj.seller = seller;
			obj.link = $state.href('main.seller.seller', { role: 'seller', id: related.id });

			return obj;
		}

		function _getWpSupplier(obj, related) {
			obj.image = {
				type: 'supplier'
			}

			const supplier = (related || {}).owner || {};

			obj.top = '' + $filter('translate')('NOTIFICATIONS.TOP_WP_SUPPLIER');
			obj.bottom = $sce.trustAsHtml((supplier.fullName || 'N/A') + ' (<email person="notification.supplier" function="smartbrokrController.sendMessage"></email>)');
			obj.supplier = supplier;
			obj.link = $state.href('main.suppliers.profile', { role: 'supplier', id: related.id });

			return obj;
		}

		function _getConfirmedVisit(obj, related, confirmed, relatedType) {

			let topMessage;
			let bottomMessage;
			if(confirmed) {
				topMessage = 'NOTIFICATIONS.VISIT_CONFIRMED_HEADER';
				bottomMessage = 'NOTIFICATIONS.VISIT_CONFIRMED';
			} else {
				topMessage = 'NOTIFICATIONS.VISIT_CANCELLED_HEADER';
				bottomMessage = 'NOTIFICATIONS.VISIT_CANCELLED';
			}

			obj.image = {
				type: 'buyer'
			}

			const user = relatedType === 'Buyer' ? ((related || {})._coBuyer || {}) : (related || {});
			const dateMoment = moment.tz(obj.scheduled, (obj.scheduledTZ || 'America/Toronto'));

			obj.top = '' + $filter('translate')(topMessage);
			obj.bottom = $filter('translate')(bottomMessage, { name: user.fullName, date: dateMoment ? dateMoment.format('ddd MMM Do YYYY') : 'TBD', time: dateMoment ? dateMoment.format('hh:mm a z') : 'TBD' });
			obj.buyer = related;
			obj.link = $state.href('main.profile.buyer', { role: 'buyer', id: related.id });

			return obj;
		}



	}) // End of controller
})(); // End of function()
