(function () {

	'use strict';

	angular.module('smartbrokr.messages', [])
	.service('MessageService', function (AccountService, ModalService, Conversation, SbUser, Message, $filter, $q) {
		const self = this;

		self.getMessageClass = function (message) {
			return (message.senderId == AccountService.getUserId()) ? 'from' : 'to';
		}

		self.newConversation = function (message, receivers) {

			if (typeof receivers === 'string') {
				receivers = [receivers];
			}

			const userId = AccountService.getUserId();

			const tasks = receivers.reduce((arr,receiverId) => {
				const msg = angular.copy(message);
				msg.receiverId = receiverId;
				arr.push(SbUser.newConversationClient({ id: userId, message: msg }).$promise);
				return arr;
			}, [])

			return $q.all(tasks);
		}

		self.createMessage = function (message) {
			message.senderId = AccountService.getUserId();
			return Conversation.messages.create({ id: message.conversationId }, message).$promise;
		}

		self.addFileToMessage = function (id, fk) {
			return Message.files.link({ id: id, fk: fk }).$promise;
		}

		self.getContacts = function (id) {

			let filter = {
				order: 'modified DESC',
				include: [
					{
						relation: 'users',
						scope: {
							fields: ['fullName', 'photoUrl'],
							include: [
								{
									relation: 'brokerProfile',
									scope: {
										fields: ['id']
									}
								}
							]
						}
					},
					{
						relation: 'metadata',
						scope: {
							where:{
								sbUserId: AccountService.getUserId()
							}
						}
					},
					{
						relation: 'files',
						scope: {
							order:'created DESC'
						}
					},
					'agency'
				]
			}
			if (id) {
				filter = {
					where: { or: [{ id: id }, { _id: id }] },
					order: 'modified DESC',
					include: [
						{
							relation: 'users',
							scope: {
								fields: ['fullName', 'photoUrl'],
								include: [
									{
										relation: 'brokerProfile',
										scope: {
											fields: ['id']
										}
									}
								]
							}
						},
						{
							relation: 'messages',
							scope: {
								include: 'files',
								order: 'created ASC'
							}
						},
						{
							relation: 'metadata',
							scope: {
								where:{
									sbUserId:AccountService.getUserId()
								}
							}
						},
						{
							relation: 'files',
							scope: {
								order:'created DESC'
							}
						},
						'agency'
					]
				}
			}

			return SbUser.conversations({ id: AccountService.getUserId(), filter: filter }).$promise;
		}

		self.getUnreadCount = function () {
			return SbUser.getUnreadCount({ id: AccountService.getUserId() }).$promise;
		};

		self.markAsRead = function (id) {
			return Conversation.markAsRead({ id: id }).$promise;
		};

		self.deleteFile = function (file) {

			// File was just uploaded to container -> only need to remove it there
			if (!file.messageId) {
				return SbUser.files.destroyById({ id: AccountService.getUserId(), fk: file.id }).$promise;
			}
			else {
				return Message.files.destroyById({ id: file.messageId, fk: file.id }).$promise;
			}
		}

		function formatEndDate(date) {
			if(!date) return null;
			return moment(date).add(1, 'days').endOf('day').toString();
		}

		function formatStartDate(date) {
			if(!date) return null;
			return moment(date).add(1, 'days').startOf('day').toString();
		}

		self.getNotifications = function(skip, limit, type, startDate, endDate) {
			return SbUser.getNotifications({ id: AccountService.getUserId(), types: Array.isArray(type) ? type : [type], startDate: formatStartDate(startDate), endDate: formatEndDate(endDate), skip: skip, limit: limit }).$promise;
		}

		self.getNotificationCount = function(unread, type, startDate, endDate) {
			return SbUser.getNotificationCount({ id: AccountService.getUserId(), types: Array.isArray(type) ? type : [type], startDate: formatStartDate(startDate), endDate: formatEndDate(endDate), unread: unread }).$promise;
		}

		self.readNotification = function(notificationId) {
			return SbUser.readNotification({ id: AccountService.getUserId(), nId: notificationId }).$promise;
		}

		self.readAllNotifications = function() {
			return SbUser.readAllNotifications({ id: AccountService.getUserId() }).$promise;
		}

		self.deleteNotification = function(nId) {
			const delFunction = function() {
				return SbUser.notifications.destroyById({ id: AccountService.getUserId(), fk: nId }).$promise
			}
			return ModalService.confirmDelete(delFunction, 'ALERT_MESSAGES.ALERT.DELETE_NOTIFICATION');
		}

		self.deleteSelected = function(selected) {
			const delFunction = function() {
				return SbUser.deleteNotifications({ id: AccountService.getUserId(), selected: selected }).$promise;
			}

			const count = selected.reduce((num, isSelected) => {
				if (isSelected)
					num += 1;
				return num;
			}, 0);

			return ModalService.confirmDelete(delFunction, $filter('translate')('ALERT_MESSAGES.ALERT.DELETE_NOTIFICATION', { num: count }));
		}

	}); // End of service
})(); // End of function()
