(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('MenuSearchController', function (searchValue, AccountService, GlobalVars, ModalService, TaskService, UserService, $filter, $rootScope, $sce, $scope, $state, $timeout) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.perPage      = 8;                    // Number of results to display per page
		self.searchValue  = searchValue || '';    // The searched term
		self.searched     = false;                // Whether a search was performed
		self.data         = {};                   // The retrieved data. Only includes the count and the ids for each type of result
		self.pages        = null;                 // Information to use with the pagination
		self.categories   = null;                 // Information to use with the category filter
		self.maxSize      = null;                 // The max-size attribute for pagination element
		self.columns      = [];                   // Data columns
		self.currentPage  = [];                   // The detailed information about the results displayed in the current page

		let all           = [];                   // Array containing all results from the search, sorted by modified date (descending)
		let filterOptions = [];                   // Options for filtering the results by type

		// SETUP VARIABLES ======================================================================================================================

		filterOptions = [
			{ name: '' + $filter('translate')('SEARCH.SEE_ALL'), value: 'all' },
			{ name: '' + $filter('translate')('ROLES.AGENCY_MEMBER'), value: 'AgencyMember' },
			{ name: '' + $filter('translate')('ROLES.AGENCY_OWNER'), value: 'AgencyOwner' },
			{ name: '' + $filter('translate')('ROLES.NOTARY'), value: 'Notary' },
			{ name: '' + $filter('translate')('ROLES.AUDITOR'), value: 'Auditor' },
			{ name: '' + $filter('translate')('ROLES.BROKER'), value: 'Broker' },
			{ name: '' + $filter('translate')('LISTING.LISTING'), value: 'Listing' },
			{ name: '' + $filter('translate')('ROLES.BUYER'), value: 'Buyer' },
			{ name: '' + $filter('translate')('ROLES.SELLER'), value: 'Seller' },
			{ name: '' + $filter('translate')('ROLES.SUPPLIER'), value: 'Supplier' },
			{ name: '' + $filter('translate')('TASK.TASK'), value: 'Task' },
		]

		if (!AccountService.isLikeBroker()) {
			filterOptions.splice(7,2);
		}

		self.pages = {
			total: 1,
			current: 1,
			low: 0,
			high: 0,
			max: 0
		}

		self.categories = {
			selected: 'all',
			config: GlobalVars.createSingleConfig(false, 'value', 'name', 'name', true)
		};

		self.categories.config.disabledField = 'disabled';

		self.maxSize = $rootScope.screenSize === 'xs' || $rootScope.screenSize === 'sm' ? 2 : 5;

		self.columns = [
			{
				ngClass: angular.noop,
				class: 'col-small',
				field: function(entry) {
					let file = 'result';
					let type = 'broker';

					switch(entry.type) {
					case 'listing':
						file = 'result.mlsPhotos[0].file || result.files[0]';
						type = 'listing';
						break;
					case 'supplier':
						type = 'supplier';
						file = 'result';
						break;
					case 'owner':
					case 'member':
					case 'notary':
					case 'auditor':
						type = 'agency';
						file = 'result.user';
						break;
					case 'buyer':
					case 'seller':
					case 'broker':
						file = 'result.user';
						type = entry.type;
						break;
					case 'task':
						if ((entry.about || {}).type !== 'personal') {
							type = entry.about.type;
							file = 'result.about';
						}
						else if (!!entry.about) {
							file = 'currentUser';
							type = 'broker';
						}
						break;
					}

					return $sce.trustAsHtml('<sb-image classes="\'img-rounded avatar\'" type="' + type + '" image="' + file + '"/>');
				}
			},
			{
				ngClass: angular.noop,
				class: 'col-medium',
				field: function(entry) {
					switch(entry.type) {
					case 'listing':
						entry.status = entry.status || '';
						var address = (entry.property || {})._address || {};
						var ret = '<span class="status ' + entry.status.toLowerCase() + '">' + entry.status[0] + '</span>' + (address.addressString || '---') + '<br>';

						if (address.city) {
							ret += address.city.name;
						}

						if (address.province) {
							if (address.city) {
								ret += ', ';
							}
							ret += address.province.id;
						}

						return ret;
					case 'supplier':
						var ret = entry.company || '---';
						if (entry.owner) {
							ret += '<br>(' + entry.owner.fullName + ')';
						}
						return ret;
					case 'task':
						return $filter('translate')(entry.name || '---');
					case 'owner':
					case 'member':
					case 'buyer':
					case 'seller':
					case 'broker':
					case 'notary':
					case 'auditor':
						return (entry.user || {}).fullName || '---';
					}

					return '---';
				}
			},
			{
				ngClass: function(entry) {
					switch(entry.type) {
					case 'buyer':
					case 'owner':
					case 'member':
					case 'broker':
					case 'notary':
					case 'auditor':
						return 'hidden-xs hidden-sm';
					case 'task':
						if ((entry.about || {}).type === 'personal') {
							return 'hidden-xs hidden-sm';
						}
					}

					return '';
				},
				class: 'col-medium',
				field: function(entry) {
					switch(entry.type) {
					case 'listing':
						return $filter('currency')(entry.askingPrice || entry.rentalPrice || 0);
					case 'seller':
						if ((entry.listings || []).length > 0) {
							return ((((entry.listings || [])[0] || {}).property || {})._address || {}).addressString || '---';
						}
						break;
					case 'supplier':
						return ((entry.industry || {}).labels || {})[$rootScope.language] || '---';
					case 'task':
						var about = entry.about || {};
						return about.address || about.company || about.fullName || entry.listingId || '---';
					}

					return '---';
				}
			},
			{
				ngClass: function(entry) {
					switch(entry.type) {
					case 'buyer':
					case 'owner':
					case 'member':
					case 'broker':
					case 'notary':
					case 'auditor':
					case 'supplier':
						return 'hidden-xs hidden-sm';
					}

					return '';
				},
				class: 'col-medium',
				field: function(entry) {
					switch(entry.type) {
					case 'listing':
						return entry.id;
					case 'seller':
						if ((entry.listings || []).length > 0) {
							return ((entry.listings || [])[0] || {}).id || '---';
						}
						break;
					case 'task':
						if (entry.completed || entry.due) {
							const ngClass = TaskService.getClass(entry);
							return '<span class="task-' + ngClass + '">' + $filter('dateFormatTaskMobile')(entry.completed || entry.due) + '</div>';
						}
						break;
					}

					return '---';
				}
			},
			{
				class: 'tag-container',
				field: function(entry) {

					let type = false;
					switch(entry.type) {
					case 'listing':
						type = $filter('translate')('LISTING.LISTING'); break;
					case 'task':
						type = '';
						if (entry.about) {
							switch(entry.about.type) {
							case 'personal':
								type += $filter('translate')('PERSON.PERSONAL'); break;
							case 'listing':
								type += $filter('translate')('LISTING.LISTING'); break;
							default:
								type += $filter('translate')('ROLES.' + (entry.about.type ? entry.about.type.toUpperCase() : entry.aboutType.toUpperCase()));
							}
						}
						type += ' ' + $filter('translate')('TASK.TASK'); break;
					case 'owner':
						type = $filter('translate')('ROLES.AGENCY_OWNER'); break;
					case 'member':
						type = $filter('translate')('ROLES.AGENCY_MEMBER'); break;
					case 'buyer':
					case 'supplier':
					case 'seller':
					case 'broker':
					case 'notary':
					case 'auditor':
						type = $filter('translate')('ROLES.' + entry.type.toUpperCase()); break;
					}

					if (!type) return '---';

					return '<div class="search-tag">' + type + '</div>';
				}
			},
		]

		// FUNCTIONS ============================================================================================================================

		/**
     *  Retrieves the data to be displayed in one page of results
     */
		self.details = function() {

			const type = self.categories.selected;
			let ids =  {};

			if (self.data.count === 0) {
				self.currentPage = [];
				return;
			}

			if (type === 'all') {
				ids = _getIds(all);
			}
			else {
				ids = _getIds(self.data.ids[type] || [], type);
			}

			UserService.getMenuSearchDetails(ids).then((res) => {
				self.currentPage = res;
			})
		}

		/**
     *  Changes the current page.
     *  Sets the numbers that represent the current results (x1 - x2 of y),
     *  makes call to function to retrieve the details of the current page and
     *  scrolls to the top of the page
     */
		self.turnPage = function() {
			const pageNum = self.pages.current;
			if (self.pages.max === 0) {
				self.pages.low = 0;
				self.pages.high = 0;
			}
			else {
				self.pages.low = ((pageNum - 1) * self.perPage) + 1;
				self.pages.high = pageNum * self.perPage;

				if (self.pages.high > self.pages.max) {
					self.pages.high = self.pages.max;
				}
			}

			const anchor = angular.element(document.getElementById('modal-header'));
			const container = anchor.closest('[role=dialog]').first();
			container.scrollToElement(anchor, 30, 100);

			$timeout(() => {
				self.details();
			})
		}

		/**
     *  Adds a number to the current page, then
     *  makes call to turnPage function
     */
		self.addPage = function(toAdd) {
			self.pages.current += parseInt(toAdd);
			self.turnPage();
		}

		/**
     *  Selects one category to display results.
     *  Sets the current page back to 1, then calculates the number of
     *  pages based on how many of that "result type" we have
     */
		self.selectCategory = function() {
			self.pages.current = 1;

			if (self.categories.selected === 'all') {
				self.pages.total = Math.ceil(all.length / self.perPage);
				self.pages.max = all.length;
			}
			else {
				self.pages.total = Math.ceil((self.data.ids[self.categories.selected] || []).length / self.perPage);
				self.pages.max = (self.data.ids[self.categories.selected] || []).length;
			}
			self.turnPage();
		}

		/**
     *  Makes the initial search when a term is entered in the search field.
     *  Retrieves the total number of results found (including the ids of each entry),
     *  then gets the first page of results
     */
		self.filter = function() {
			$scope.loading = true;
			self.searched = self.searchValue.length > 0;

			if (self.searchValue.length > 0) {
				UserService.getMenuSearch(self.searchValue, 'all').then((res) => {
					all = [];

					self.data = res;

					for (var i in res.ids) {
						const curr = angular.copy(res.ids[i]);
						all = all.concat(curr.map((entry) => { entry.type = i; return entry; }));
					}

					all = all.sort((a, b) => moment(b.modified).valueOf() - moment(a.modified).valueOf());

					self.categories.options = filterOptions.reduce((arr, item) => {
						const cat = angular.copy(item);
						if (cat.value !== 'all') {
							cat.name += ' (' + ((res.ids[cat.value] || []).length || 0) + ') ';
							cat.disabled = (res.ids[cat.value] || []).length == 0;
						}
						else {
							cat.name += ' (' + all.length + ')';
							cat.disabled = false;
						}

						arr.push(cat);
						return arr;
					}, []);
				})
				.finally(() => {
					self.selectCategory();
					$scope.loading = false;
				})
			}
		}

		/**
     *  Retrieves the link to the details page of a result, based on its type.
     *  If the result is a Listing that does not belong to the current user, returns null.
     */
		self.getLink = function(result) {

			let state, params = {};

			switch(result.type) {
			case 'broker':
				state = 'main.broker.account';
				params = { id: result.id };
				break;
			case 'buyer':
				state = 'main.profile';
				params = { role: 'buyer', id: result.id };
				break;
			case 'listing':
				if (result.isMine) {
					state = 'main.listings.detail.submenu';
					params = { id: result.id };
				}
				break;
			case 'seller':
				state = 'main.profile';
				params = { role: 'seller', id: result.id };
				break;
			case 'supplier':
				state = 'main.suppliers.profile';
				params = { id: result.id };
				break;
			case 'task':
				state = 'main.tasks.view';
				params = { taskId: result.id };
				break;
			case 'auditor':
			case 'notary':
			case 'member':
			case 'owner':
				state = 'main.agency.account';
				params = { type: result.type, id: result.id }
				break;
			}

			if (!!state) {
				return $state.href(state, params, { absolute: true });
			}

			return null;
		}

		/**
     *  Opens the listing summary for a listing that was displayed in the results, but
     *  does not belong to the current user.
     */
		self.openSummary = function(result) {
			result = result || {};

			if (result.type === 'listing' && !result.isMine)
				ModalService.listingSummary(result.id);
		}

		/**
     *  Retrieves the IDs of the results that will display in the
     *  current page.
     *  @param  {object[]}   arr    An array of objects containing their ids, type and modified date
     *  @param  {string=}    type   The specific type of result that we want. Optional.
     *  @return {string[]}          An array of strings with the IDs of the results that will be displayed in the current page
     */
		function _getIds(arr, type) {
			const l = self.pages.low - 1;
			const h = self.pages.high;
			return arr.slice(l, h).reduce((obj, curr) => {
				if (!obj[curr.type || type])
					obj[curr.type || type] = [];

				obj[curr.type || type].push(curr.id);
				return obj;
			}, {});
		}

	}) // End of controller
})(); // End of function()
