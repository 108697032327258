/**
 * © copyright 2017-2018, 2818779 Canada Inc.
 * 
 * Use of this software is only permitted if you have entered into a
 * license agreement with 2818779 Canada Inc. and is subject to the terms
 * and conditions of such license agreement.
 *
 */
/* eslint-disable no-undef */
(function () {
	'use strict';

	angular.module('smartbrokr')
	.constant('stateConfig', ($urlRouterProvider, $stateProvider) => {
		//$urlRouterProvider.otherwise("/error/404");
		const _ = window._;

		$urlRouterProvider.otherwise(($injector, $location) => {
			const url = $location.url().split('/');
			const section = url[1] || '';

			switch(section) {
			case 'public':
				$location.path('/public/home'); break;
			case 'signup':
				$location.path('/signup/start'); break;
			case 'owner':
				$location.path('/owner/login'); break;
			default:
				$location.path('/login'); break;
			}
		});

		const translateRefresh = function(translate, rootScope) {
			if (!translate || !rootScope) return true;

			const lang = (rootScope.language || 'en').toLowerCase();
			return translate.refresh(lang).then((res) => {
				return res;
			})
			.catch(() => {
				return false;
			});
		}

		const getCheck = function() {
			return {
				original: null,
				clone: null,
				ignore: null,
				isDirty: function() {

					const original = angular.copy(this.original);
					const clone = angular.copy(this.clone);
					const ignore = this.ignore || [];

					/* Deletes property that should be ignored in an object
						* Only works for one property for each object
						* Ex: ignore: { listing: { property: '_address' } }
						*      ->  Will delete listing.property._address from clone and original
						*          so this field is not used when comparing them */
					function delIgnored(obj, curr, key) {
						if (!!curr[key] && typeof curr[key] === 'object') {
							const keys = _.keys(curr[key]);
							if (keys.length > 0) {
								delIgnored(obj[key],curr[key], keys[0]);
							}
							else {
								delete obj[key];
								return;
							}
						}
						else if (!Array.isArray(obj[key]) && typeof obj[key] === 'object'){
							delete obj[key][curr[key]];
							return;
						}
						else {
							console.log('Ignore from array not implemented yet.');
						}
					}

					ignore.forEach((toIgnore) => {
						if (typeof toIgnore === 'object') {
							const keys = _.keys(toIgnore);
							delIgnored(clone,toIgnore,keys[0]);
							delIgnored(original,toIgnore,keys[0]);
						}
						else if (typeof toIgnore === 'string') {
							delete original[toIgnore];
							delete clone[toIgnore];
						}
					})

					return !_.isEqual(original,clone);
				},
				isSaved: false
			}
		}

		const roles = {
			ADMIN: ['adminProfile'],
			B: ['brokerProfile', 'adminProfile'],
			M_B: ['managerProfile', 'brokerProfile', 'adminProfile'],
			NO_AG: ['managerProfile', 'brokerProfile', 'adminProfile'],	// Everyone except agency owner and members
			AO: ['agencyOwnerProfile', 'adminProfile'],
			AM: ['agencyMemberProfile', 'adminProfile'],
			AM_AO: ['agencyMemberProfile', 'agencyOwnerProfile', 'adminProfile'],
			A: ['agencyMemberProfile', 'agencyOwnerProfile', 'managerProfile', 'brokerProfile', 'notaryProfile', 'auditorProfile', 'adminProfile'], //Anyone Authenticated
			AG: ['agencyMemberProfile', 'agencyOwnerProfile', 'managerProfile', 'brokerProfile', 'adminProfile'],	//anyone in the agency(broker, manager, owner member)
			A_N: ['auditorProfile', 'notaryProfile', 'adminProfile'],
			BO: [ 'agencyOwnerProfile', 'brokerProfile', 'managerProfile', 'adminProfile' ],
			S: [ 'adminProfile', 'sellerProfile' ]  // Seller login
		}

		$stateProvider
		.state('main', {
			url: '',
			abstract: true,
			views: {
				main: {
					templateUrl: 'templates/layout.html',
				},
				'sidebar@main': {
					templateUrl: 'main/main.html',
					controller: 'MainController',
					controllerAs: 'mainController'
				}
			},
			params: {},
			resolve: {
				translateReady: ['$translate', function($translate) {
					return $translate.onReady();
				}],
				translation: function($translatePartialLoader, $localStorage, $rootScope, $translate) {
					const schema = $localStorage.schema || 'centris';
					const notSchema = schema === 'realtor' ? 'centris ' : 'realtor';
					$translatePartialLoader.addPart(schema);
					$translatePartialLoader.deletePart(notSchema);
					return translateRefresh($translate, $rootScope);
				}
			}
		})

		.state('main.redirect', {
			url: '/redirect',
			views: {
				'content@main': {
					template: '<div>{{ error? "there was an error D:" : "Closing..."}}</div>',
					controller: function($window, $location) {
						const data = $location.$$search || {};
						const error = data.error;

						if (error) {
							$scope.error = JSON.parse(error);
						}
						else {
							$window.opener.postMessage(data, $window.opener.location);
							$window.close();
						}
					}
				},
				'sidebar@main': {
					template: null
				}
			},
			params: {
				public: true
			}
		})

		.state('main.redirect.error', {
			url: '/error',
			views: {
				'content@main': {
					template: '<div>There was an error. Details: {{error}}</div>',
					controller: function($window, $location) {
						$scope.error = ($location.$$search || {}).error || '{}';
					}
				},
				'sidebar@main': {
					template: null
				}
			},
			params: {
				public: true
			}
		})

		// Admin --------------------------------------------------------------------------
		.state('main.admin', {
			url: '/admin',
			abstract: true,
			views: {
				'sidebar@main': {
					templateUrl: 'admin/menu/admin.menu.html',
					controller: 'AdminMenuController',
					controllerAs: 'mainController'
				}
			},
			params: {
				whitelist: roles.ADMIN
			},
			resolve: {
				language: [ 'LanguageService', function(LanguageService) {
					return LanguageService.setLanguage('en');
				}],
				translation: function($translate, $translatePartialLoader, $rootScope) {
					$translatePartialLoader.addPart('settings');
					return translateRefresh($translate, $rootScope);
				}
			}
		})

		.state('main.admin.dashboard', {
			url: '/dashboard',
			views: {
				'content@main': {
					templateUrl: 'admin/dashboard/admin.dashboard.html',
					controller: 'AdminDashboardController',
					controllerAs: 'dashboardController'
				}
			},
			params: {
				menu: 'dashboard',
				whitelist: roles.ADMIN
			},
			data: {
				title: 'Dashboard'
			}
		})

		.state('main.admin.agencies', {
			url: '/agencies',
			views: {
				'content@main': {
					templateUrl: 'admin/agencies/admin.agencies.html',
					controller: 'AdminAgenciesController',
					controllerAs: 'agencyController'
				}
			},
			params: {
				menu: 'agencies',
				whitelist: roles.ADMIN
			},
			data: {
				title: 'Agencies'
			}
		})

		.state('main.admin.agencies.profile', {
			url: '/:id/profile',
			views: {
				'content@main': {
					templateUrl: 'admin/agencies/agency.profile.html',
					controller: 'AdminAgencyProfileController',
					controllerAs: 'profileController'
				}
			},
			params: {
				menu: 'agencies',
				id: null,
				whitelist: roles.ADMIN
			},
			data: {
				getTitle: function (params, NavService) {
					return NavService.getAgencyName(params.id).then((res) => {
						return (res || {}).legalName || 'Agency profile';
					})
				}
			}
		})

		.state('main.admin.agencies.profile.accounts', {
			url: '/accounts',
			views: {
				'content@main': {
					templateUrl: 'admin/agencies/agency.accounts.html',
					controller: 'AdminAgencyAccountsController',
					controllerAs: 'accountsController'
				}
			},
			params: {
				menu: 'agencies',
				id: null,
				whitelist: roles.ADMIN
			},
			data: {
				getTitle: null,
				title: 'Accounts'
			}
		})

		.state('main.admin.brokers', {
			url: '/brokers',
			views: {
				'content@main': {
					templateUrl: 'admin/brokers/admin.brokers.html',
					controller: 'AdminBrokersController',
					controllerAs: 'brokerController'
				}
			},
			params: {
				menu: 'brokers',
				whitelist: roles.ADMIN
			},
			data: {
				title: 'Brokers'
			}
		})

		.state('main.admin.profile', {
			url: '/:menu/:role/:id',
			views: {
				'content@main': {
					templateUrl: 'admin/profile/profile.html',
					controller: 'AdminProfileController',
					controllerAs: 'profileController'
				}
			},
			params: {
				menu: null,
				id: null,
				role: null,
				whitelist: roles.ADMIN
			},
			data: {
				getTitle: function (params, NavService) {
					return NavService.getFullName(params.id, params.role).then((res) => {
						return ((res || {}).user || {}).fullName || 'Profile';
					})
				}
			}
		})

		.state('main.admin.profile.emails', {
			url: '/emails',
			views: {
				'content@main': {
					templateUrl: 'settings/sentEmails.html',
					controller: 'SentEmailsController',
					controllerAs: 'sentEmailsController'
				}
			},
			params: {
				whitelist: roles.M_B
			},
			data: {
				title: 'PAGES.SETTINGS.SUB.SENT_EMAILS',
				profileMenu: 'emails'
			}
		})

		.state('main.admin.profile.listings', {
			url: '/listings',
			views: {
				'content@main': {
					templateUrl: 'admin/listings/admin.listings.html',
					controller: 'AdminListingsController',
					controllerAs: 'listingsController'
				}
			},
			params: {
				whitelist: roles.ADMIN
			},
			data: {
				getTitle: null,
				title: 'Listings'
			}
		})

		.state('main.admin.listings', {
			url: '/listings/:type',
			views: {
				'content@main': {
					templateUrl: 'admin/listings/admin.listings.html',
					controller: 'AdminListingsController',
					controllerAs: 'listingsController'
				}
			},
			params: {
				menu: 'listings',
				type: 'all',
				whitelist: roles.ADMIN
			},
			data: {
				title: 'Listings'
			}
		})

		.state('main.admin.codes', {
			url: '/codes',
			views: {
				'content@main': {
					templateUrl: 'admin/codes/admin.codes.html',
					controller: 'AdminCodesController',
					controllerAs: 'codesController'
				}
			},
			params: {
				menu: 'codes',
				whitelist: roles.ADMIN
			},
			data: {
				title: 'Codes'
			}
		})

		.state('main.admin.help', {
			url: '/help',
			views: {
				'content@main': {
					templateUrl: '/js/src/admin/help/admin-help.html',
					controller: 'AdminHelpController',
					controllerAs: 'helpController'
				}
			},
			params: {
				menu: 'help',
				whitelist: roles.ADMIN
			},
			data: {
				title: 'HELP.HELP_CENTER'
			}
		})

		.state('main.admin.settings', {

			url: '/settings',
			abstract: true,
			params: {
				menu: 'settings'
			},
			data: {
				title: 'PAGES.SETTINGS.TITLE'
			}
		})

		.state('main.admin.settings.account', {
			url: '/account',
			views: {
				'content@main': {
					templateUrl: 'settings/account.html',
					controller: 'AccountController',
					controllerAs: 'accountController'
				}
			},
			params: {
				submenu: 'account',
				whitelist: roles.A
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			},
			data: {
				title: 'PAGES.SETTINGS.SUB.ACCOUNT',
				previous: function (params, NavService) {
					return NavService.getState('main.admin.settings');
				}
			}
		})

		.state('main.admin.settings.docusign', {
			url: '/docusign',
			views: {
				'content@main': {
					templateUrl: 'settings/docusign.html',
					controller: 'DocusignController',
					controllerAs: 'docusignController'
				},
			},
			params: {
				submenu: 'docusign',
				whitelist: roles.A
			},
			resolve: {
				check: function() {
					return getCheck();
				}
			},
			data: {
				title: 'PAGES.SETTINGS.SUB.DOCUSIGN.TITLE',
				previous: function (params, NavService) {
					return NavService.getState('main.admin.settings')
				}
			}
		})

		.state('main.admin.settings.language', {
			url: '/language',
			views: {
				'content@main': {
					templateUrl: 'settings/language.html',
					controller: 'LanguageController',
					controllerAs: 'languageController'
				}
			},
			params: {
				submenu: 'languages',
				whitelist: null
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			},
			data: {
				title: 'PAGES.SETTINGS.SUB.LANGUAGE',
				previous: function (params, NavService) {
					return NavService.getState('main.admin.settings');
				}
			}
		})

		// Dashboard ----------------------------------------------------------------------
		.state('main.dashboard', {
			url: '/dashboard',
			views: {
				'content@main': {
					templateUrl: 'dashboard/dashboard.html',
					controller: 'DashboardController',
					controllerAs: 'dashboardController'
				}
			},
			params: {
				menu: 'dashboard',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.DASHBOARD.TITLE'
			}
		})
		.state('main.dashboard.notes', {
			url: '/notes',
			views: {
				'content@main': {
					templateUrl: 'notes/notes.html',
					controller: 'NotesController',
					controllerAs: 'notesController'
				}
			},
			params: {
				id: null,
				newNote: null,
				menu: 'dashboard',
				whitelist: roles.AG
			},
			data: {
				title: 'NOTES_LOG.NOTES'
			}
		})
		.state('main.dashboard.action-log', {
			url: '/action-log',
			views: {
				'content@main': {
					templateUrl: 'notes/action_log.html',
					controller: 'NotesController',
					controllerAs: 'notesController'
				}
			},
			params: {
				id: null,
				menu: 'dashboard',
				whitelist: roles.AG
			},
			data: {
				title: 'NOTES_LOG.ACTION_LOG'
			}
		})

		//Notifications -------------------------------------------------------------------
		.state('main.notifications', {
			url: '/notifications',
			views: {
				'content@main': {
					templateUrl: '/js/src/notifications/notifications.html',
					controller: 'NotificationsController',
					controllerAs: 'notificationsController'
				}
			},
			params: {
				menu: 'notifications'
			},
			data: {
				title: 'NOTIFICATIONS.NOTIFICATIONS'
			}
		})

		//Messages ------------------------------------------------------------------------
		.state('main.messages', {
			url: '/messages',
			views: {
				'content@main': {
					templateUrl: 'messages/messages.html',
					controller: 'MessagesController',
					controllerAs: 'messagesController'
				}
			},
			params: {
				menu: 'messages'
			},
			data: {
				title: 'MESSAGES.TITLE'
			}
		})

		//Tasks ---------------------------------------------------------------------------
		.state('main.tasks', {
			url: '/tasks',
			views: {
				'content@main': {
					templateUrl: 'tasks/tasks.html',
					controller: 'TasksController',
					controllerAs: 'tasksController',
				}
			},
			params: {
				hash: null,
				menu: 'tasks',
				whitelist: roles.AG
			},
			data: {
				title: 'TASK.TASKS'
			}
		})

		.state('main.tasks.incomplete-tasks', {
			url: '/incomplete-tasks',
			views: {
				'content@main': {
					templateUrl: 'tasks/incomplete-tasks/incomplete-tasks.html',
					controller: 'IncompleteTasksController',
					controllerAs: 'incompleteTasksController'
				}
			},
			params: {
				menu: 'tasks',
				submenu: 'incomplete',
				whitelist: roles.AG
			},
			data: {
				title: 'TASK.INCOMPLETE_TASKS.TITLE'
			}
		})

		.state('main.tasks.view', {
			url: '/:hash/:taskId/details',
			views: {
				'content@main': {
					templateUrl: 'tasks/task-details.html',
					controller: 'TaskDetailsController',
					controllerAs: 'taskDetailsController'
				}
			},
			params: {
				taskId: null,
				menu: 'tasks',
				whitelist: roles.AG,
				hash: null
			},
			data: {
				getTitle: function (params, NavService) {
					return NavService.getTaskName(params.taskId).then((res) => {
						return (res || {}).name || null;
					})
				},
				previous: function (params, NavService) {
					const toReturn = NavService.getTaskCrumb(params);
					if (toReturn) {
						return toReturn.then((data) => {
							const crumbsObject = {
								link: 'main.tasks',
								title: '',
								params: params
							};
							let title = '';
							switch(data.aboutType) {
							case 'Buyer':
							case 'Seller':
								title = ((data.about || {}).user || {}).fullName || 'ROLES.' + data.aboutType.toUpperCase();
								break;
							case 'Supplier':
								title = (data.about || {}).company || 'ROLES.SUPPLIER';
								break;
							case 'Broker':
							case 'AgencyOwner':
							case 'AgencyMember':
								title = 'PERSON.PERSONAL';
								break;
							default:
								title = title = (((data.listing || {}).property || {})._address || {}).addressString || 'N/A';
							}
							crumbsObject.title = title;

							return crumbsObject;
						});
					}
				}
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			}
		})

		//Resources -----------------------------------------------------------------------
		.state('main.resources', {
			url: '/resources',
			abstract: true,
			params: {
				menu: 'resources',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.RESOURCES.TITLE'
			}
		})
		.state('main.resources.my-files', {
			url: '/my-files',
			views: {
				'content@main': {
					templateUrl: 'reports/my-files.html',
					controller: 'ReportsController',
					controllerAs: 'reportsController'
				}
			},
			params: {
				submenu: 'files',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.REPORTS.SUB.MY_FILES',
				previous: function (params, NavService) {
					return NavService.getState('main.resources');
				}
			}
		})
		.state('main.resources.marketing-images', {
			url: '/marketing-images',
			views: {
				'content@main': {
					templateUrl: 'resources/marketing-images.html',
					controller: 'ResourcesController',
					controllerAs: 'resourcesController'
				}
			},
			params: {
				submenu: 'marketing',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.RESOURCES.SUB.MARKETING_IMAGES',
				previous: function (params, NavService) {
					return NavService.getState('main.resources');
				}
			}
		})
		.state('main.resources.images-for-video', {
			url: '/images-for-video',
			views: {
				'content@main': {
					templateUrl: 'resources/images-for-video.html',
					controller: 'ResourcesController',
					controllerAs: 'resourcesController'
				}
			},
			params: {
				submenu: 'video',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.RESOURCES.SUB.IMAGES_VIDEO',
				previous: function (params, NavService) {
					return NavService.getState('main.resources');
				}
			}
		})
		.state('main.resources.forms', {
			url: '/forms',
			views: {
				'content@main': {
					templateUrl: 'resources/forms.html',
					controller: 'ResourcesController',
					controllerAs: 'resourcesController'
				}
			},
			params: {
				submenu: 'forms',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.RESOURCES.SUB.FORMS',
				previous: function (params, NavService) {
					return NavService.getState('main.resources');
				}
			}
		})
		.state('main.resources.task-templates', {
			url: '/resources/task-templates',
			views: {
				'content@main': {
					templateUrl: 'resources/task-templates/task-templates.html',
					controller: 'TaskTemplatesController',
					controllerAs: 'taskTemplatesController'
				}
			},
			params: {
				submenu: 'task-templates',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.RESOURCES.SUB.TASK_TEMPLATES',
				previous: function (params, NavService) {
					return NavService.getState('main.resources');
				}
			}
		})

		.state('main.resources.shared-task-templates', {
			url: '/resources/shared-task-templates',
			views: {
				'content@main': {
					templateUrl: 'resources/task-templates/shared-task-templates.html',
					controller: 'TaskTemplatesController',
					controllerAs: 'taskTemplatesController'
				}
			},
			params: {
				submenu: 'shared-task-templates',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.RESOURCES.SUB.SHARED_TASK_TEMPLATES',
				previous: function (params, NavService) {
					return NavService.getState('main.resources');
				}
			}
		})

		.state('main.resources.shared-email-templates', {
			url: '/shared-email-templates',
			views: {
				'content@main': {
					templateUrl: 'resources/shared-email-templates.html',
					controller: 'ResourcesController',
					controllerAs: 'resourcesController'
				}
			},
			params: {
				submenu: 'shared-email-templates',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.RESOURCES.SUB.SHARED_EMAIL_TEMPLATES',
				previous: function (params, NavService) {
					return NavService.getState('main.resources');
				}
			}
		})

		.state('main.resources.task-templates.edit', {
			url: '/:id',
			views: {
				'content@main': {
					templateUrl: 'resources/task-templates/edit-task-template.html',
					controller: 'EditTaskTemplateController',
					controllerAs: 'editTaskTemplateController',
				}
			},
			params: {
				id: null,
				shared: false,
				template: null,
				whitelist: roles.AG,
				title: null
			},
			data: {
				previous: null,
				getTitle: function (params, NavService) {
					return NavService.getTaskTemplateName(params.id).then((res) => {
						return (res || {}).name || null;
					})
				}
			}
		})

		.state('main.resources.shared-task-templates.edit', {
			url: '/:id/shared',
			views: {
				'content@main': {
					templateUrl: 'resources/task-templates/edit-task-template.html',
					controller: 'EditTaskTemplateController',
					controllerAs: 'editTaskTemplateController',
				}
			},
			params: {
				id: null,
				shared: false,
				template: null,
				whitelist: roles.AG,
				title: null
			},
			data: {
				previous: null,
				getTitle: function (params, NavService) {
					return NavService.getTaskTemplateName(params.id).then((res) => {
						return (res || {}).name || null;
					})
				}
			}
		})

		.state('main.resources.email-templates', {
			url: '/email-templates',
			views: {
				'content@main': {
					templateUrl: 'resources/email-templates.html',
					controller: 'ResourcesController',
					controllerAs: 'resourcesController'
				}
			},
			params: {
				submenu: 'email-templates',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.RESOURCES.SUB.EMAIL_TEMPLATES',
				previous: function (params, NavService) {
					return NavService.getState('main.resources');
				}
			}
		})

		.state('main.resources.media-manager', {
			url: '/media-manager',
			views: {
				'content@main': {
					templateUrl: '/js/src/resources/media-manager/media-manager.html',
					controller: 'MediaManagerController',
					controllerAs: 'mediaManagerController'
				}
			},
			params: {
				submenu: 'media-manager',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.RESOURCES.SUB.MEDIA_MANAGER',
				previous: function (params, NavService) {
					return NavService.getState('main.resources');
				}
			}
		})

		//Reports -------------------------------------------------------------------------
		.state('main.reports', {
			url: '/reports',
			abstract: true,
			params: {
				menu: 'reports'
			},
			data: {
				title: 'PAGES.REPORTS.TITLE'
			}
		})
		.state('main.reports.last-month-listings', {
			url: '/last-month-listings',
			views: {
				'content@main': {
					templateUrl: 'reports/last-month-listings.html',
					controller: 'ReportsController',
					controllerAs: 'reportsController'
				}
			},
			params: {
				submenu: 'lmlbb',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.REPORTS.SUB.LISTINGS_BROKER',
				previous: function (params, NavService) {
					return NavService.getState('main.reports');
				}
			}
		})
		.state('main.reports.last-month-sales', {
			url: '/last-month-sales',
			views: {
				'content@main': {
					templateUrl: 'reports/last-month-sales.html',
					controller: 'ReportsController',
					controllerAs: 'reportsController'
				}
			},
			params: {
				submenu: 'lmsbb',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.REPORTS.SUB.SALES_BROKER',
				previous: function (params, NavService) {
					return NavService.getState('main.reports');
				}
			}
		})
		.state('main.reports.sales-by-region', {
			url: '/sales-by-region',
			views: {
				'content@main': {
					templateUrl: 'reports/sales-by-region.html',
					controller: 'ReportsController',
					controllerAs: 'reportsController'
				}
			},
			params: {
				submenu: 'sbr',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.REPORTS.SUB.SALES_BY_REGION',
				previous: function (params, NavService) {
					return NavService.getState('main.reports');
				}
			}
		})
		.state('main.reports.listing-expiry-report', {
			url: '/listing-expiry-report',
			views: {
				'content@main': {
					templateUrl: 'reports/listing-expiry-report.html',
					controller: 'ReportsController',
					controllerAs: 'reportsController'
				}
			},
			params: {
				submenu: 'ler',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.REPORTS.SUB.LISTING_EXPIRY',
				previous: function (params, NavService) {
					return NavService.getState('main.reports');
				}
			}
		})
		.state('main.reports.visits-by-broker', {
			url: '/visits-by-broker',
			views: {
				'content@main': {
					templateUrl: 'reports/visits-by-broker.html',
					controller: 'ReportsController',
					controllerAs: 'reportsController'
				}
			},
			params: {
				submenu: 'visits-by-broker',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.REPORTS.SUB.VISITS_BY_BROKER',
				previous: function (params, NavService) {
					return NavService.getState('main.reports');
				}
			}
		})
		.state('main.reports.new-buyers-by-property-type', {
			url: '/new-buyers-by-property-type',
			views: {
				'content@main': {
					templateUrl: 'reports/new-buyers-by-property-type.html',
					controller: 'ReportsController',
					controllerAs: 'reportsController'
				}
			},
			params: {
				submenu: 'new-buyers-by-property-type',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.REPORTS.SUB.NEW_BUYERS_BY_PROPERTY_TYPE',
				previous: function (params, NavService) {
					return NavService.getState('main.reports');
				}
			}
		})
		.state('main.reports.ytd-listings', {
			url: '/ytd-listings',
			views: {
				'content@main': {
					templateUrl: 'reports/ytd-listings.html',
					controller: 'ReportsController',
					controllerAs: 'reportsController'
				}
			},
			params: {
				submenu: 'ytd',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.REPORTS.SUB.YTD_LISTINGS',
				previous: function (params, NavService) {
					return NavService.getState('main.reports');
				}
			}
		})
		.state('main.reports.listings-with-no-seller-info', {
			url: '/listings-with-no-seller-info',
			views: {
				'content@main': {
					templateUrl: 'reports/listings-with-no-seller-info.html',
					controller: 'ReportsController',
					controllerAs: 'reportsController'
				}
			},
			params: {
				submenu: 'no-seller',
				whitelist: roles.M_B
			},
			data: {
				title: 'PAGES.REPORTS.SUB.NO_SELLER_INFO',
				previous: function (params, NavService) {
					return NavService.getState('main.reports');
				}
			}
		})
		.state('main.reports.missing-documents', {
			url: '/missing-documents-report',
			views: {
				'content@main': {
					templateUrl: 'reports/missing-documents.html',
					controller: 'ReportsController',
					controllerAs: 'reportsController'
				}
			},
			params: {
				submenu: 'missing-documents',
				whitelist: roles.M_B
			},
			data: {
				title: 'PAGES.REPORTS.SUB.MISSING_DOCUMENTS',
				previous: function (params, NavService) {
					return NavService.getState('main.reports');
				}
			}
		})
		.state('main.reports.hits-tracker', {
			url: '/hits-tracker',
			views: {
				'content@main': {
					templateUrl: 'reports/hits-tracker/hits-tracker.html',
					controller: 'HitsTrackerController',
					controllerAs: 'hitsTrackerController'
				}
			},
			params: {
				submenu: 'hits',
				whitelist: roles.M_B
			},
			data: {
				title: 'PAGES.REPORTS.SUB.HITS_TRACKER',
				previous: function (params, NavService) {
					return NavService.getState('main.reports');
				}
			}
		})

		//Agency --------------------------------------------------------------------------
		.state('main.agency', {
			url: '/agency',
			views: {
				'content@main': {
					templateUrl: 'agency/agency.html',
					controller: 'AgencyController',
					controllerAs: 'agencyController'
				}
			},
			params: {
				menu: 'agency',
				whitelist: roles.AG
			},
			data: {
				title: 'ROLES.AGENCY'
			}
		})
		.state('main.agency.account', {
			url: '/:type/:id/details',
			views: {
				'content@main': {
					templateUrl: 'agency/agency.profile.html',
					controller: 'AgencyProfileController',
					controllerAs: 'agencyProfileController'
				}
			},
			params: {
				menu: 'agency',
				type: 'member',
				id: null,
				whitelist: roles.AG
			},
			data: {
				super: true,
				title: 'COMMON.DETAILS',
				getTitle: function (params, NavService) {
					return NavService.getFullName(params.id, params.type).then((res) => {
						return (((res || [])[0] || {}).user || {}).fullName;
					})
				}
			},
		})

		.state('main.agency.sent-emails', {
			url: '/:id/:role/:fk/sent-emails',
			views: {
				'content@main': {
					templateUrl: 'settings/sentEmails.html',
					controller: 'SentEmailsController',
					controllerAs: 'sentEmailsController'
				}
			},
			params: {
				whitelist: roles.M_B
			},
			data: {
				title: 'PAGES.SETTINGS.SUB.SENT_EMAILS',
				profileMenu: 'emails'
			}
		})

		// .state('main.profile.emails', {
		// 	url: '/sent-emails',
		// 	views: {
		// 		'content@main': {
		// 			templateUrl: 'settings/sentEmails.html',
		// 			controller: 'SentEmailsController',
		// 			controllerAs: 'sentEmailsController'
		// 		}
		// 	},
		// 	params: {
		// 		whitelist: roles.M_B
		// 	},
		// 	data: {
		// 		title: 'PAGES.SETTINGS.SUB.SENT_EMAILS',
		// 		profileMenu: 'emails'
		// 	}
		// })

		.state('main.agency.edit', {
			url: '/:type/:action/:id',
			views: {
				'content@main': {
					templateUrl: 'agency/agency.edit.html',
					controller: 'AgencyEditController',
					controllerAs: 'agencyEditController'
				}
			},
			params: {
				menu: 'agency',
				type: 'member',
				id: null,
				action: 'new',
				whitelist: roles.AO
			},
			data: {
				getTitle: function (params) {
					return new Promise(((resolve, reject) => {
						if (params) {
							const type = params.type.toUpperCase();
							resolve(params.id ? 'COMMON.EDIT' : 'PERSON.' + type + '.NEW_' + type);
						}
						else {
							reject(new Error('No params'));
						}
					}))
				},
				previous: function (params, NavService) {
					if ((params || {}).id) {
						return NavService.getState('main.agency.account');
					}
					return null;
				},
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			}
		})

		//Broker --------------------------------------------------------------------------
		.state('main.broker', {
			url: '/broker',
			views: {
				'content@main': {
					templateUrl: 'broker/broker.html',
					controller: 'BrokerController',
					controllerAs: 'brokerController'
				}
			},
			params: {
				menu: 'broker',
				whitelist: roles.AG,
				role: 'broker'
			},
			data: {
				title: 'ROLES.BROKER'
			}
		})
		.state('main.broker.account', {
			url: '/profile/:id',
			views: {
				'content@main': {
					templateUrl: 'broker/brokerProfile.html',
					controller: 'BrokerProfileController',
					controllerAs: 'brokerProfileController'
				}
			},
			params: {
				id: null,
				menu: 'broker',
				whitelist: roles.AG,
				role: 'broker',
				title: null
			},
			data: {
				super: true,
				title: 'COMMON.DETAILS',
				getTitle: function (params, NavService) {
					return NavService.getFullName(params.id, params.role).then((res) => {
						return (((res || [])[0] || {}).user || {}).fullName;
					})
				},
			}
		})

		.state('main.broker.emails', {
			url: '/emails/:id',
			views: {
				'content@main': {
					templateUrl: 'settings/sentEmails.html',
					controller: 'SentEmailsController',
					controllerAs: 'sentEmailsController'
				}
			},
			params: {
				menu: 'broker',
				type: 'broker',
				id: null,
				whitelist: roles.A
			},
			data: {
				title: 'PAGES.SETTINGS.SUB.SENT_EMAILS',
				profileMenu: 'emails'
			}
		})

		.state('main.broker.edit', {
			url: '/edit/:id',
			views: {
				'content@main': {
					templateUrl: 'broker/broker.edit.html',
					controller: 'BrokerEditController',
					controllerAs: 'brokerEditController'
				}
			},
			params: {
				menu: 'broker',
				type: 'broker',
				id: null,
				whitelist: roles.AO
			},
			data: {
				getTitle: function (params) {
					return new Promise(((resolve, reject) => {
						if (params) {
							resolve(params.id ? 'COMMON.EDIT' : 'PERSON.BROKER.NEW_BROKER');
						}
						else {
							reject(new Error('No params'));
						}
					}))
				},
				previous: function (params, NavService) {
					if ((params || {}).id) {
						return NavService.getState('main.broker.account');
					}
					return null;
				}
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			}
		})

		// Buyer/Seller profile -----------------------------------------------------------
		.state('main.profile', {
			url: '/:role/:id/profile',
			views: {
				'content@main': {
					templateUrl: 'profile/profile.html',
					controller: 'ProfileController',
					controllerAs: 'profileController',
				}
			},
			params: {
				id: null,
				profileId: null,
				whitelist: roles.M_B,
				menu: null,
				role: null,
				title: null,
				subsubmenu: 'profile'
			},
			data: {
				title: 'COMMON.DETAILS',
				super: true,
				profileMenu: 'profile',
				getTitle: function (params, NavService) {
					return NavService.getFullName(params.profileId ? params.profileId : params.id, params.role).then((res) => {
						return (res.user || {}).fullName;
					});
				},
				previous: function (params, NavService) {
					if (NavService.navStack.length == 0 && params.role) {
						return {
							link: 'main.' + params.role,
							title: 'ROLES.' + params.role.toUpperCase()
						}
					}
					return null;
				},
			}
		})
		.state('main.profile.files', {
			url: '/files',
			views: {
				'content@main': {
					templateUrl: 'profile/files.html',
					controller: 'ProfileFilesController',
					controllerAs: 'profileFilesController'
				}
			},
			params: {
				whitelist: roles.M_B,
			},
			data: {
				title: 'FILES.FILES',
				profileMenu: 'files'
			}
		})
		.state('main.profile.tasks', {
			url: '/tasks',
			views: {
				'content@main': {
					templateUrl: 'tasks/tasks.html',
					controller: 'TasksController',
					controllerAs: 'tasksController'
				}
			},
			params: {
				whitelist: roles.M_B
			},
			data: {
				title: 'TASK.TASKS',
				super: false,
				profileMenu: 'tasks'
			}
		})
		.state('main.profile.visits', {
			url: '/visits',
			views: {
				'content@main': {
					templateUrl: 'profile/visits.html',
					controller: 'VisitsController',
					controllerAs: 'visitsController',
				}
			},
			params: {
				whitelist: roles.M_B
			},
			data: {
				title: 'LISTING.DETAILS_MENU.VISITS',
				profileMenu: 'visits'
			}
		})
		.state('main.profile.appointments', {
			url: '/appointments',
			views: {
				'content@main': {
					templateUrl: 'profile/visits.html',
					controller: 'VisitsController',
					controllerAs: 'visitsController',
				}
			},
			params: {
				whitelist: roles.M_B
			},
			data: {
				title: 'LISTING.DETAILS_MENU.APPOINTMENTS',
				profileMenu: 'appointments'
			}
		})
		.state('main.profile.emails', {
			url: '/sent-emails',
			views: {
				'content@main': {
					templateUrl: 'settings/sentEmails.html',
					controller: 'SentEmailsController',
					controllerAs: 'sentEmailsController'
				}
			},
			params: {
				whitelist: roles.M_B
			},
			data: {
				title: 'PAGES.SETTINGS.SUB.SENT_EMAILS',
				profileMenu: 'emails'
			}
		})
		.state('main.profile.tasks.view', {
			url: '/:taskId/details',
			views: {
				'content@main': {
					templateUrl: 'tasks/task-details.html',
					controller: 'TaskDetailsController',
					controllerAs: 'taskDetailsController'
				}
			},
			params: {
				taskId: null,
				task: null,
				whitelist: roles.M_B
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			},
			data: {
				profileMenu: 'tasks',
				getTitle: function (params, NavService) {
					return NavService.getTaskName(params.taskId).then((res) => {
						return (res || {}).name || null;
					})
				}
			}
		})
		.state('main.profile.notes', {
			url: '/notes',
			views: {
				'content@main': {
					templateUrl: 'notes/notes.html',
					controller: 'NotesController',
					controllerAs: 'notesController'
				}
			},
			params: {
				newNote: null,
				whitelist: roles.M_B
			},
			data: {
				profileMenu: 'notes',
				title: 'NOTES_LOG.NOTES'
			}
		})
		.state('main.profile.action-log', {
			url: '/action-log',
			views: {
				'content@main': {
					templateUrl: 'notes/action_log.html',
					controller: 'NotesController',
					controllerAs: 'notesController'
				}
			},
			params: {
				whitelist: roles.M_B
			},
			data: {
				profileMenu: 'log',
				title: 'NOTES_LOG.ACTION_LOG'
			}
		})
		.state('main.profile.edit-property', {
			url: '/add-listing',
			views: {
				'content@main': {
					templateUrl: 'profile/edit_property.html',
					controller: 'EditPropertyController',
					controllerAs: 'editPropertyController',
				}
			},
			params: {
				whitelist: roles.M_B
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			},
			data: {
				title: 'PROPERTY.ADD_PROPERTY'
			}
		})

		//Buyer ---------------------------------------------------------------------------
		.state('main.buyer', {
			url: '/buyer',
			views: {
				'content@main': {
					templateUrl: 'buyer/buyer.html',
					controller: 'BuyerController',
					controllerAs: 'buyerController'
				}
			},
			params: {
				menu: 'buyer',
				whitelist: roles.M_B,
				role: 'buyer'
			},
			data: {
				title: 'ROLES.BUYER'
			}
		})
		.state('main.buyer.buyer', {
			url: '/edit/:id',
			views: {
				'content@main': {
					templateUrl: 'buyer/add_buyer.html',
					controller: 'AddBuyerController',
					controllerAs: 'addBuyerController',
				}
			},
			params: {
				newPerson: null,
				id: null,
				savedProp: null,
				whitelist: roles.M_B
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			},
			data: {
				title: 'PERSON.BUYER.WHAT_BUYING',
				previous: function (params, NavService) {
					if ((params || {}).id) {
						return NavService.getState('main.profile');
					}
					return null;
				}
			}
		})
		.state('main.buyer.search-listings', {
			url: '/:id/search-listings',
			views: {
				'content@main': {
					templateUrl: 'buyer/buyer_search.html',
					controller: 'BuyerSearchController',
					controllerAs: 'buyerSearchController',
				}
			},
			params: {
				id: null,
				buyer: null,
				listing: false,
				whitelist: roles.M_B
			},
			data: {
				title: 'PERSON.BUYER.PROPERTY_SEARCH',
				previous: function (params, NavService) {
					if ((params || {}).id) {
						return NavService.getState('main.profile');
					}
					return null;
				}
			}
		})
		.state('main.buyer.person', {
			url: '/person/:id/edit/:profileId',
			views: {
				'content@main': {
					templateUrl: 'profile/add_person.html',
					controller: 'NewPersonController',
					controllerAs: 'newPersonController',
				}
			},
			params: {
				newPerson: null,
				edit: false,
				id: null,
				profileId: null,
				addListing: null,
				listingId: null,
				whitelist: roles.M_B
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			},
			data: {
				getTitle: function (params) {
					return new Promise(((resolve, reject) => {
						if (params) {
							resolve(params.id ? 'COMMON.EDIT' : 'PERSON.BUYER.NEW_BUYER_TITLE');
						}
						else { reject(new Error('No params')); };
					}))
				},
				previous: function (params, NavService) {
					if ((params || {}).id) {
						const stateName = NavService.getState('main.profile');
						return stateName;
					}
					return null;
				},
			}
		})

		//Seller --------------------------------------------------------------------------
		.state('main.seller', {
			url: '/seller',
			views: {
				'content@main': {
					templateUrl: 'seller/seller.html',
					controller: 'SellerController',
					controllerAs: 'sellerController'
				}
			},
			params: {
				menu: 'seller',
				whitelist: roles.M_B,
				role: 'seller'
			},
			data: {
				title: 'ROLES.SELLER'
			}
		})
		.state('main.seller.seller', {
			url: '/edit/:id',
			views: {
				'content@main': {
					templateUrl: 'seller/add_seller.html',
					controller: 'AddSellerController',
					controllerAs: 'addSellerController',
				}
			},
			params: {
				id: null,
				newPerson: null,
				whitelist: roles.M_B
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			},
			data: {
				title: 'PERSON.SELLER.WHAT_SELLING',
				previous: function (params, NavService) {
					if ((params || {}).id) {
						return NavService.getState('main.profile');
					}
					return null;
				}
			}
		})

		.state('main.seller.person', {
			url: '/person/:id/edit/:profileId',
			views: {
				'content@main': {
					templateUrl: 'profile/add_person.html',
					controller: 'NewPersonController',
					controllerAs: 'newPersonController',
				}
			},
			params: {
				newPerson: null,
				edit: false,
				id: null,
				profileId: null,
				addListing: null,
				listingId: null,
				whitelist: roles.M_B
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			},
			data: {
				getTitle: function (params) {
					return new Promise(((resolve, reject) => {
						if (params) {
							resolve(params.id ? 'COMMON.EDIT' : 'PERSON.SELLER.NEW_SELLER_TITLE');
						}
						else { reject(new Error('No params')); };
					}))
				},
				previous: function (params, NavService) {
					if ((params || {}).id) {
						return NavService.getState('main.profile');
					}
					return null;
				},
			}
		})

		//Listings ------------------------------------------------------------------------
		.state('main.listings', {
			url: '/listings/:submenu',
			views: {
				'content@main': {
					templateUrl: 'listings/listings.html',
					controller: 'ListingsController',
					controllerAs: 'listingsController'
				}
			},
			params: {
				menu: 'listings',
				submenu: 'inventory',
				whitelist: roles.AG
			},
			data: {
				getTitle: function (params) {
					return new Promise(((resolve, reject) => {
						if (params) {
							switch (params.submenu) {
							case 'inventory': resolve('LISTING.MAIN_MENU.INVENTORY'); break;
							case 'smart': resolve('LISTING.MAIN_MENU.SMART'); break;
							case 'mls': resolve('LISTING.MAIN_MENU.MLS'); break;
							case 'shared': resolve('LISTING.MAIN_MENU.SHARED'); break;
							case 'archive': resolve('LISTING.MAIN_MENU.ARCHIVE'); break;
							case 'converted': resolve('LISTING.MAIN_MENU.CONVERTED'); break;
							case 'drafts': resolve('LISTING.MAIN_MENU.DRAFTS'); break;
							default: resolve(null);
							}
						}
						else { reject(new Error('No params')); }
					}))
				},
				previous: {
					title: 'LISTING.LISTINGS',
					link: '.'
				}
			}
		})
		.state('main.listings.search-buyers', {
			url: '/:id/search-buyers',
			views: {
				'content@main': {
					templateUrl: 'buyer/buyer.html',
					controller: 'BuyerSearchController',
					controllerAs: 'buyerController',
				}
			},
			params: {
				id: null,
				listing: null,
				buyer: false,
				whitelist: roles.M_B
			},
			data: {
				title: 'PERSON.BUYER.BUYER_SEARCH',
				getTitle: null,
				previous: function (params, NavService) {
					if ((params || {}).id) {
						return NavService.getState('main.listings.detail.submenu');
					}
					return null;
				}
			}
		})
		.state('main.listings.detail', {
			url: '/:id/details',
			abstract: true,
			params: {
				id: null,
				tab: 4,
				whitelist: roles.M_B,
				title: null,
				subsubmenu: 'listing'
			},
			views: {
				'content@main': {
					templateUrl: 'listings/listing-detail/detail.html',
					controller: 'ListingsDetailController',
					controllerAs: 'listingsDetailController',
				}
			},
			data: {
				getTitle: function (params, NavService) {
					return NavService.getListingAddress(params.id).then((res) => {
						return (res || {}).address || 'LISTINGS.LISTING';
					})
				},
				super: true,
				previous: function (params, NavService) {
					if (NavService.navStack.length == 0) {
						return {
							link: 'main.listings',
							title: 'LISTING.LISTINGS'
						}
					}
					return null;
				},
				next: 'main.listings.detail.submenu',
				title: 'COMMON.DETAILS'
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			}
		})
		.state('main.listings.detail.submenu', {
			url: '/menu/{tab:int}',
			views: {
				costs: { templateUrl: 'listings/listing-detail/costs.html' },
				lot: { templateUrl: 'listings/listing-detail/lot.html' },
				evaluation: { templateUrl: 'listings/listing-detail/evaluation.html' },
				building: { templateUrl: 'listings/listing-detail/building.html' },
				description: { templateUrl: 'listings/listing-detail/description.html' },
				addenda: { templateUrl: 'listings/listing-detail/addenda.html' },
				features: { templateUrl: 'listings/listing-detail/features.html' },
				rooms: { templateUrl: 'listings/listing-detail/rooms.html' },
			},
			params: {
				tab: null,
				whitelist: roles.M_B
			},
			data: {
				title: 'COMMON.DETAILS',
				listingMenu: 'detail'
			}
		})
		.state('main.listings.detail.tasks', {
			url: '/tasks',
			views: {
				'content@main': {
					templateUrl: 'tasks/tasks.html',
					controller: 'TasksController',
					controllerAs: 'tasksController'
				}
			},
			params: {
				whitelist: roles.M_B
			},
			data: {
				title: 'TASK.TASKS',
				super: false,
				listingMenu: 'tasks'
			}
		})
		.state('main.listings.detail.tasks.view', {
			url: '/:taskId/details',
			views: {
				'content@main': {
					templateUrl: 'tasks/task-details.html',
					controller: 'TaskDetailsController',
					controllerAs: 'taskDetailsController'
				}
			},
			params: {
				taskId: null,
				task: null,
				whitelist: roles.M_B
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			},
			data: {
				getTitle: function (params, NavService) {
					return NavService.getTaskName(params.taskId).then((res) => {
						return (res || {}).name || null;
					})
				}
			}
		})
		.state('main.listings.detail.photos', {
			url: '/photos',
			views: {
				'content@main': {
					templateUrl: 'listings/photos.html',
					controller: 'PhotosController',
					controllerAs: 'photosController'
				}
			},
			params: {
				whitelist: roles.M_B
			},
			data: {
				title: 'LISTING.DETAILS_MENU.PHOTOS',
				listingMenu: 'photos'
			}
		})
		.state('main.listings.detail.marketing', {
			url: '/marketing',
			views: {
				'content@main': {
					templateUrl: 'listings/listingResources.html',
					controller: 'ListingResourcesController',
					controllerAs: 'listingResourcesController'
				}
			},
			params: {
				type: 'marketing',
				whitelist: roles.M_B
			},
			data: {
				title: 'LISTING.DETAILS_MENU.MARKETING',
				listingMenu: 'marketing'
			}
		})
		.state('main.listings.detail.documents', {
			url: '/documents',
			views: {
				'content@main': {
					templateUrl: 'listings/listingDocuments.html',
					controller: 'ListingDocumentsController',
					controllerAs: 'listingDocumentsController'
				}
			},
			params: {
				type: 'documents',
				whitelist: roles.M_B
			},
			data: {
				title: 'FILES.DOCUMENTS',
				listingMenu: 'documents'
			}
		})
		.state('main.listings.detail.resources', {
			url: '/resources',
			views: {
				'content@main': {
					templateUrl: 'listings/listingResources.html',
					controller: 'ListingResourcesController',
					controllerAs: 'listingResourcesController',
				}
			},
			params: {
				type: 'resources',
				whitelist: roles.M_B
			},
			data: {
				title: 'FILES.FILES',
				listingMenu: 'resources'
			}
		})
		.state('main.listings.detail.notes', {
			url: '/notes',
			views: {
				'content@main': {
					templateUrl: 'notes/notes.html',
					controller: 'NotesController',
					controllerAs: 'notesController',
				}
			},
			params: {
				listing: true,
				newNote: null,
				whitelist: roles.M_B
			},
			data: {
				title: 'NOTES_LOG.NOTES',
				listingMenu: 'notes'
			}
		})
		.state('main.listings.detail.log', {
			url: '/log',
			views: {
				'content@main': {
					templateUrl: 'notes/action_log.html',
					controller: 'NotesController',
					controllerAs: 'notesController',
				}
			},
			params: {
				listing: true,
				whitelist: roles.M_B
			},
			data: {
				title: 'NOTES_LOG.ACTION_LOG',
				listingMenu: 'log'
			}
		})
		.state('main.listings.detail.visits', {
			url: '/visits',
			views: {
				'content@main': {
					templateUrl: 'listings/visits.html',
					controller: 'VisitsController',
					controllerAs: 'visitsController',
				}
			},
			params: {
				whitelist: roles.M_B
			},
			data: {
				title: 'LISTING.DETAILS_MENU.VISITS',
				listingMenu: 'visits'
			}
		})
		.state('main.listings.detail.appointments', {
			url: '/appointments',
			views: {
				'content@main': {
					templateUrl: 'listings/appointments.html',
					controller: 'VisitsController',
					controllerAs: 'visitsController'
				}
			},
			params: {
				whitelist: roles.M_B
			},
			data: {
				title: 'LISTING.DETAILS_MENU.APPOINTMENTS',
				listingMenu: 'appointments'
			}
		})

		.state('main.listings.detail.suppliers', {
			url: '/suppliers',
			views: {
				'content@main': {
					templateUrl: 'suppliers/suppliers.html',
					controller: 'SuppliersController',
					controllerAs: 'suppliersController'
				}
			},
			params: {
				whitelist: roles.M_B,
				listing: true
			},
			data: {
				title: 'ROLES.SUPPLIERS',
				listingMenu: 'suppliers'
			}
		})

		.state('main.listings.detail.suppliers.profile', {
			url: '/:sId',
			views: {
				'content@main': {
					templateUrl: 'suppliers/supplier.profile.html',
					controller: 'SupplierProfileController',
					controllerAs: 'supplierProfileController',
				}
			},
			params: {
				sId: null,
				whitelist: roles.M_B.concat(roles.S),
				role: 'supplier'
			},
			data: {
				skipParent: true,
				super: true,
				title: 'COMMON.DETAILS',
				getTitle: function (params, NavService) {
					return NavService.getFullName(params.sId, 'supplier').then((res) => {
						return res.company;
					})
				},
				listingMenu: 'suppliers'
			}
		})

		//Suppliers -----------------------------------------------------------------------
		.state('main.suppliers', {
			url: '/suppliers',
			views: {
				'content@main': {
					templateUrl: 'suppliers/suppliers.html',
					controller: 'SuppliersController',
					controllerAs: 'suppliersController'
				}
			},
			params: {
				menu: 'suppliers',
				whitelist: roles.AG.concat(roles.S)
			},
			data: {
				title: 'ROLES.SUPPLIERS'
			},
			resolve: {
				translation: function($translate, $translatePartialLoader, $rootScope) {
					$translatePartialLoader.addPart('owner');
					return translateRefresh($translate, $rootScope);
				}
			}
		})
		.state('main.suppliers.add', {
			url: '/edit/{listingId}_{id}',
			views: {
				'content@main': {
					templateUrl: 'suppliers/supplier.edit.html',
					controller: 'AddSupplierController',
					controllerAs: 'addSupplierController'
				}
			},
			params: {
				id: null,
				editSupplier: null,
				listingId: null,
				whitelist: roles.AG.concat(roles.S)
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			},
			data: {
				getTitle: function (params) {
					return new Promise(((resolve, reject) => {
						if (params) {
							resolve(params.id || (params.editSupplier || {}).id ? 'COMMON.EDIT' : 'PERSON.SUPPLIER.NEW_SUPPLIER_TITLE');
						}
						else reject(new Error('No params'));
					}));
				},
				previous: function (params, NavService) {
					if (params.id || (params.editSupplier || {}).id) {
						return NavService.getState('main.suppliers.profile');
					}
					return null;
				},
			}
		})
		.state('main.suppliers.profile', {
			url: '/:id',
			views: {
				'content@main': {
					templateUrl: 'suppliers/supplier.profile.html',
					controller: 'SupplierProfileController',
					controllerAs: 'supplierProfileController',
				}
			},
			params: {
				id: null,
				whitelist: roles.AG.concat(roles.S),
				role: 'supplier',
				title: null,
				subsubmenu: 'profile'
			},
			data: {
				super: true,
				title: 'COMMON.DETAILS',
				profileMenu: 'profile',
				getTitle: function (params, NavService) {
					return NavService.getFullName(params.id, params.role).then((res) => {
						return res.company;
					})
				},
				previous: function (params, NavService) {
					if (NavService.navStack.length == 0 && params.role) {
						return {
							link: 'main.suppliers',
							title: 'ROLES.SUPPLIERS'
						}
					}
					return null;
				},
			}
		})
		.state('main.suppliers.profile.files', {
			url: '/files',
			views: {
				'content@main': {
					templateUrl: 'profile/files.html',
					controller: 'ProfileFilesController',
					controllerAs: 'profileFilesController'
				}
			},
			params: {
				whitelist: roles.AG.concat(roles.S),
				role: 'supplier'
			},
			data: {
				title: 'FILES.FILES',
				profileMenu: 'files'
			}
		})
		.state('main.suppliers.profile.tasks', {
			url: '/tasks',
			views: {
				'content@main': {
					templateUrl: 'tasks/tasks.html',
					controller: 'TasksController',
					controllerAs: 'tasksController'
				}
			},
			params: {
				whitelist: roles.AG,
				role: 'supplier'
			},
			data: {
				title: 'TASK.TASKS',
				super: false,
				profileMenu: 'tasks'
			}
		})
		.state('main.suppliers.profile.tasks.view', {
			url: '/:taskId/details',
			views: {
				'content@main': {
					templateUrl: 'tasks/task-details.html',
					controller: 'TaskDetailsController',
					controllerAs: 'taskDetailsController'
				}
			},
			params: {
				taskId: null,
				task: null,
				whitelist: roles.AG,
				role: 'supplier'
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			},
			data: {
				getTitle: function (params, NavService) {
					return NavService.getTaskName(params.taskId).then((res) => {
						return (res || {}).name || null;
					})
				}
			}
		})
		.state('main.suppliers.profile.visits', {
			url: '/visits',
			views: {
				'content@main': {
					templateUrl: 'profile/visits.html',
					controller: 'VisitsController',
					controllerAs: 'visitsController',
				}
			},
			params: {
				whitelist: roles.M_B
			},
			data: {
				title: 'LISTING.DETAILS_MENU.VISITS',
				profileMenu: 'visits'
			}
		})
		.state('main.suppliers.profile.appointments', {
			url: '/appointments',
			views: {
				'content@main': {
					templateUrl: 'profile/visits.html',
					controller: 'VisitsController',
					controllerAs: 'visitsController',
				}
			},
			params: {
				whitelist: roles.M_B
			},
			data: {
				title: 'LISTING.DETAILS_MENU.APPOINTMENTS',
				profileMenu: 'appointments'
			}
		})
		.state('main.suppliers.profile.emails', {
			url: '/sent-emails',
			views: {
				'content@main': {
					templateUrl: 'settings/sentEmails.html',
					controller: 'SentEmailsController',
					controllerAs: 'sentEmailsController'
				}
			},
			params: {
				whitelist: roles.M_B
			},
			data: {
				title: 'PAGES.SETTINGS.SUB.SENT_EMAILS',
				profileMenu: 'emails'
			}
		})
		.state('main.suppliers.profile.notes', {
			url: '/notes',
			views: {
				'content@main': {
					templateUrl: 'notes/notes.html',
					controller: 'NotesController',
					controllerAs: 'notesController'
				}
			},
			params: {
				newNote: null,
				whitelist: roles.AG.concat(roles.S),
				role: 'supplier'
			},
			data: {
				title: 'NOTES_LOG.NOTES',
				profileMenu: 'notes'
			}
		})
		.state('main.suppliers.profile.action-log', {
			url: '/action-log',
			views: {
				'content@main': {
					templateUrl: 'notes/action_log.html',
					controller: 'NotesController',
					controllerAs: 'notesController'
				}
			},
			params: {
				whitelist: roles.AG
			},
			data: {
				title: 'NOTES_LOG.ACTION_LOG',
				profileMenu: 'log'
			}
		})

		//Help ------------------------------------------------------------------------
		.state('main.help', {
			url: '/help',
			views: {
				'content@main': {
					templateUrl: '/js/src/help/help.html',
					controller: 'HelpController',
					controllerAs: 'helpController'
				}
			},
			params: {
				menu: 'help',
				whitelist: roles.A
			},
			data: {
				title: 'HELP.HELP_CENTER'
			},
			resolve: {
				translation: function($rootScope, $translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('help');
					return translateRefresh($translate, $rootScope);
				}
			}
		})

		//Settings ------------------------------------------------------------------------
		.state('main.settings', {

			url: '/settings',
			abstract: true,
			params: {
				menu: 'settings'
			},
			data: {
				title: 'PAGES.SETTINGS.TITLE'
			},
			resolve: {
				translation: function($translatePartialLoader, $translate, $rootScope) {
					$translatePartialLoader.addPart('settings');
					return translateRefresh($translate, $rootScope);
				}
			}
		})
		.state('main.settings.account', {
			url: '/account',
			views: {
				'content@main': {
					templateUrl: 'settings/account.html',
					controller: 'AccountController',
					controllerAs: 'accountController'
				}
			},
			params: {
				submenu: 'account',
				whitelist: roles.A
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			},
			data: {
				title: 'PAGES.SETTINGS.SUB.ACCOUNT',
				previous: function (params, NavService) {
					return NavService.getState('main.settings');
				}
			}
		})
		.state('main.settings.agency', {
			url: '/agency-profile',
			views: {
				'content@main': {
					templateUrl: 'settings/agency.edit.html',
					controller: 'AgencyProfileEditController',
					controllerAs: 'profileController'
				}
			},
			params: {
				submenu: 'agency',
				whitelist: roles.AO
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			},
			data: {
				title: 'PAGES.SETTINGS.SUB.AGENCY_PROFILE',
				previous: function (params, NavService) {
					return NavService.getState('main.settings');
				}
			}
		})
		.state('main.settings.emails', {
			url: '/sent-emails',
			views: {
				'content@main': {
					templateUrl: 'settings/sentEmails.html',
					controller: 'SentEmailsController',
					controllerAs: 'sentEmailsController'
				}
			},
			params: {
				submenu: 'sent-emails',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.SETTINGS.SUB.SENT_EMAILS',
				previous: function (params, NavService) {
					return NavService.getState('main.settings');
				}
			}
		})
		.state('main.settings.payment', {
			url: '/payment',
			views: {
				'content@main': {
					templateUrl: 'settings/payment.html',
					controller: 'PaymentController',
					controllerAs: 'paymentController'
				}
			},
			params: {
				submenu: 'payment',
				whitelist: null
				//TODO
			},
			data: {
				title: 'PAGES.SETTINGS.SUB.PAYMENT',
				previous: function (params, NavService) {
					return NavService.getState('main.settings');
				}
			}
		})
		.state('main.settings.language', {
			url: '/language',
			views: {
				'content@main': {
					templateUrl: 'settings/language.html',
					controller: 'LanguageController',
					controllerAs: 'languageController'
				}
			},
			params: {
				submenu: 'languages',
				whitelist: null
			},
			resolve: {
				check: function () {
					return getCheck();
				}
			},
			data: {
				title: 'PAGES.SETTINGS.SUB.LANGUAGE',
				previous: function (params, NavService) {
					return NavService.getState('main.settings');
				}
			}
		})
		.state('main.settings.roles', {
			url: '/roles',
			views: {
				'content@main': {
					templateUrl: 'settings/roles.html',
					controller: 'RoleController',
					controllerAs: 'roleController'
				}
			},
			params: {
				submenu: 'roles',
				whitelist: roles.AO
			},
			data: {
				title: 'PAGES.SETTINGS.SUB.ROLES',
				previous: function (params, NavService) {
					return NavService.getState('main.settings');
				}
			}
		})
		// .state('main.settings.teams', {
		//     url: '/teams',
		//     views: {
		//         'content@main': {
		//             templateUrl: 'settings/teams.html',
		//             controller: 'TeamsController',
		//             controllerAs: 'teamsController'
		//         }
		//     },
		//     params: {
		//         submenu: 6,
		//         whitelist: null
		//     },
		//     data: {
		//         title: "PAGES.SETTINGS.SUB.TEAMS",
		//         previous: function (params, NavService) {
		//             return NavService.getState('main.settings');
		//         }
		//     }
		// })
		.state('main.settings.managers', {
			url: '/managers',
			views: {
				'content@main': {
					templateUrl: 'settings/managers.html',
					controller: 'ManagersController',
					controllerAs: 'managersController'
				}
			},
			params: {
				submenu: 'managers',
				whitelist: roles.B
			},
			data: {
				title: 'ROLES.MANAGERS',
				previous: function (params, NavService) {
					return NavService.getState('main.settings');
				}
			}
		})
		.state('main.settings.migrate', {
			url: '/migrate',
			views: {
				'content@main': {
					templateUrl: 'settings/migrate.html',
					controller: 'MigrateController',
					controllerAs: 'migrateController'
				}
			},
			params: {
				submenu: 'migrate',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.SETTINGS.SUB.MIGRATE',
				previous: function (params, NavService) {
					return NavService.getState('main.settings');
				}
			}
		})
		// .state('main.settings.preferences', {
		//     url: '/preferences',
		//     views: {
		//         'content@main': {
		//             templateUrl: 'settings/preferences.html',
		//             controller: 'PreferencesController',
		//             controllerAs: 'preferencesController'
		//         }
		//     },
		//     params: {
		//         submenu: 9,
		//         whitelist: null
		//     },
		//     data: {
		//         title: "PAGES.SETTINGS.SUB.PREFERENCES",
		//         previous: function (params, NavService) {
		//             return NavService.getState('main.settings');
		//         }
		//     }
		// })

		//Auditor/Notary Listings -------------------------------------------------------------------
		.state('main.role-listings', {
			url: '/:role',
			abstract: true,
			params: {
				menu: 'role-listings',
				role: function (AccountService) {
					const currentRole = AccountService.getRole();
					if (currentRole != 'notaryProfile' && currentRole != 'auditorProfile') {
						return null;
					}
					const newRole = AccountService.getRole() == 'notaryProfile' ? 'notary' : 'auditor';
					return newRole;
				}
			}
		})
		.state('main.role-listings.listings', {
			url: '/listings',
			views: {
				'content@main': {
					templateUrl: 'role-listings/role-listings.html',
					controller: 'RoleListingsController',
					controllerAs: 'roleListingsController'
				}
			},
			params: {
				menu: 'role-listings',
				whitelist: roles.A_N
			},
			data: {
				title: 'LISTING.LISTINGS'
			}
		})

		//Wordpress -----------------------------------------------------------------------

		.state('main.wordpress', {
			url: '/wordpress',
			views: {
				'content@main': {
					templateUrl: 'wordpress/wordpress.html',
					controller: 'WordpressController',
					controllerAs: 'wordpressController'
				}
			},
			params: {
				menu: 'wordpress',
				whitelist: roles.BO
			},
			data: {
				title: 'WORDPRESS.WORDPRESS',
			},
			resolve: {
				check: function () {
					return getCheck();
				},
				translation: function($translatePartialLoader, $translate, $rootScope) {
					$translatePartialLoader.addPart('wordpress');
					return translateRefresh($translate, $rootScope);
				}
			}
		})

		//Login ---------------------------------------------------------------------------
		.state('main.login', {
			url: '/login',
			views: {
				'sidebar@main': {
					templateUrl: null
				},
				content: {
					templateUrl: 'login/login.html',
					controller: 'LoginController',
					controllerAs: 'loginController'
				}
			},
			params: {
				menu: 0,
				whitelist: null,
				public: true,
				internal: false
			},
			data: {
				title: 'LOGIN.TITLE'
			},
			resolve: {
				translation: function($translatePartialLoader, $translate, $rootScope) {
					$translatePartialLoader.addPart('login');
					return translateRefresh($translate, $rootScope);
				}
			}
		})

		.state('main.enable', {
			url: '/enable/:token',
			views: {
				'sidebar@main': {
					templateUrl: null
				},
				content: {
					templateUrl: 'enable/enable.html',
					controller: 'EnableController',
					controllerAs: 'enableController'
				}
			},
			params: {
				menu: 0,
				whitelist: null,
				public: true,
				internal: false,
				title: 'Enable'
			},
			// data: {
			// 	title: 'LOGIN.TITLE'
			// },
			// resolve: {
			// 	translation: function($translatePartialLoader, $translate, $rootScope) {
			// 		$translatePartialLoader.addPart('login');
			// 		return translateRefresh($translate, $rootScope);
			// 	}
			// }
		})

		//Confirm/Error -------------------------------------------------------------------
		.state('main.confirm', {
			url: '/confirm/:accountId/:accessToken/:confirmState',
			views: {
				'sidebar@main': {
					templateUrl: null
				},
				'content@main': {
					templateUrl: 'confirm/confirm.html',
					controller: 'ConfirmAccountController',
					controllerAs: 'confirmAccountController'
				}
			},
			params: {
				accessToken: null,
				confirmState: null,
				menu: 0,
				whitelist: null,
				title: 'Confirm',
				public: true
			}
		})
		.state('main.error', {
			url: '/error/{code:int}',
			views: {
				'content@main': {
					templateUrl: 'error/error.html',
					controller: 'ErrorController',
					controllerAs: 'errorController'
				}
			},
			params: {
				menu: null,
				error: null,
				code: null,
				title: 'ALERT_MESSAGES.ERROR.CLIENT.ERROR',
				whitelist: null,
			}
		})

		//Public --------------------------------------------------------------------------
		.state('main.signup', {
			url: '/signup',
			abstract: true,
			views: {
				'sidebar@main': {
					templateUrl: 'public/header.html',
					controller: 'PublicController',
					controllerAs: 'publicController'
				},
				'footer@main': {
					templateUrl: 'public/footer.html',
					controller: 'PublicController',
					controllerAs: 'publicController'
				}
			},
			params: {
				menu: 0,
				type: 'broker',
				system: 'centris',
				title: 'Sign Up',
				public: true
			},
			resolve: {
				translation: function($translatePartialLoader, $translate, $rootScope) {
					$translatePartialLoader.addPart('public');
					$translatePartialLoader.addPart('signup');
					return translateRefresh($translate, $rootScope);
				}
			}
		})
		.state('main.signup.start-signup', {
			url: '/start',
			views: {
				'content@main': {
					templateUrl: 'signup/start/start-signup.html',
					controller: 'StartSignupController',
					controllerAs: 'startSignupController'
				}
			},
			params: {
				menu: 0,
				public: true
			},
			data: {
				title: 'Tell Us About Yourself'
			}
		})
		.state('main.signup.tell-us-about-yourself', {
			url: '/tell-us-about-yourself',
			views: {
				'content@main': {
					templateUrl: 'signup/tell-us-about-yourself/tell-us-about-yourself.html',
					controller: 'TellUsController',
					controllerAs: 'tellUsController'
				}
			},
			params: {
				menu: 0,
				public: true
			},
			data: {
				title: 'Tell Us About Yourself'
			}
		})
		.state('main.signup.permissions-realtor', {
			url: '/permissions',
			views: {
				'content@main': {
					templateUrl: 'signup/permissions/permissions-realtor.html',
					controller: 'PermissionsController',
					controllerAs: 'permissionsController'
				}
			},
			params: {
				menu: 0,
				public: true
			},
			data: {
				title: 'Permissions'
			}
		})
		.state('main.signup.permissions-centris', {
			url: '/permissions',
			views: {
				'content@main': {
					templateUrl: 'signup/permissions/permissions-centris.html',
					controller: 'PermissionsController',
					controllerAs: 'permissionsController'
				}
			},
			params: {
				menu: 0,
				public: true
			},
			data: {
				title: 'Permissions'
			}
		})
		.state('main.signup.situation', {
			url: '/situation',
			views: {
				'content@main': {
					templateUrl: 'signup/situation/situation.html',
					controller: 'SituationController',
					controllerAs: 'situationController'
				}
			},
			params: {
				menu: 0,
				public: true
			},
			data: {
				title: 'Situation'
			}
		})
		.state('main.signup.website', {
			url: '/website/{option:int}',
			views: {
				'content@main': {
					templateUrl: 'signup/website/website.html',
					controller: 'WebsiteController',
					controllerAs: 'websiteController'
				}
			},
			params: {
				menu: 0,
				public: true
			},
			data: {
				title: 'Website'
			}
		})
		.state('main.signup.invite-brokers', {
			url: '/invite-brokers',
			views: {
				'content@main': {
					templateUrl: 'signup/invite-brokers/invite-brokers.html',
					controller: 'InviteBrokersController',
					controllerAs: 'inviteBrokersController'
				}
			},
			params: {
				state: null,
				menu: 0,
				public: true
			},
			data: {
				title: 'Invite Brokers'
			}
		})
		.state('main.signup.invite-staff', {
			url: '/invite-staff',
			views: {
				'content@main': {
					templateUrl: 'signup/invite-staff/invite-staff.html',
					controller: 'InviteStaffController',
					controllerAs: 'inviteStaffController'
				}
			},
			params: {
				menu: 0,
				public: true
			},
			data: {
				title: 'Invite Staff'
			}
		})
		.state('main.signup.finish', {
			url: '/finish',
			views: {
				'content@main': {
					templateUrl: 'signup/finish-signup/finish-signup.html',
					controller: 'FinishSignupController',
					controllerAs: 'finishSignupController'
				}
			},
			params: {
				menu: 0,
				public: true
			},
			data: {
				title: 'Finish'
			}
		})
		.state('main.signup.review', {
			url: '/review',
			views: {
				'content@main': {
					templateUrl: 'signup/review/review.html',
					controller: 'ReviewController',
					controllerAs: 'reviewController'
				}
			},
			params: {
				menu: 0,
				public: true
			},
			data: {
				title: 'Review'
			}
		})
		.state('main.signup.complete', {
			url: '/complete',
			views: {
				'content@main': {
					templateUrl: 'signup/complete-signup/complete-signup.html',
					controller: 'SignupCompleteController',
					controllerAs: 'signupCompleteController'
				}
			},
			params: {
				menu: 0,
				whitelist: null,
				public: true
			},
			data: {
				title: 'Confirm'
			}
		})
		.state('main.calendar', {
			url: '/calendar',
			views: {
				'content@main': {
					templateUrl: 'calendar/calendar.html',
					controller: 'CalendarController',
					controllerAs: 'calendarController'
				}
			},
			params: {
				menu: 'calendar',
				whitelist:roles.AG
			},
			data: {
				title: 'PAGES.CALENDAR.TITLE'
			}
		})
		.state('main.calendar.visit', {
			url: '/visit/:secret/:action',
			views: {
				'sidebar@main': {
					templateUrl: null
				},
				'content@main': {
					templateUrl: 'calendar/public/public-visits.html',
					controller: 'PublicVisitsController',
					controllerAs: 'publicVisitsController'
				}
			},
			params: {
				menu: 0,
				secret: null,
				action: null,
				whitelist: null,
				public: true
			},
			data: {
				title: 'PAGES.CALENDAR.TITLE'
			}
		})

		.state('main.emails', {
			url: '/sent-emails',
			views: {
				'content@main': {
					templateUrl: 'settings/sentEmails.html',
					controller: 'SentEmailsController',
					controllerAs: 'sentEmailsController'
				}
			},
			params: {
				submenu: 'sent-emails',
				whitelist: roles.AG
			},
			data: {
				title: 'PAGES.SETTINGS.SUB.SENT_EMAILS',
				previous: function (params, NavService) {
					return NavService.getState('main.settings');
				}
			}
		})

		.state('main.public', {
			url: '/public',
			abstract: true,
			views: {
				'sidebar@main': {
					templateUrl: 'public/header.html',
					controller: 'PublicController',
					controllerAs: 'publicController'
				},
				'footer@main': {
					templateUrl: 'public/footer.html',
					controller: 'PublicController',
					controllerAs: 'publicController'
				}
			},
			params: {
				public: true
			},
			resolve: {
				translation: function($translatePartialLoader, $translate, $rootScope) {
					$translatePartialLoader.addPart('public');
					return translateRefresh($translate, $rootScope);
				}
			}
		})
		.state('main.public.home', {
			url: '/home',
			views: {
				'content@main': {
					templateUrl: 'public/home.html',
					controller: 'PublicController',
					controllerAs: 'publicController'
				}
			},
			params: {
				public: true
			}
		})
		.state('main.public.about', {
			url: '/about',
			views: {
				'content@main': {
					templateUrl: 'public/about.html',
				}
			},
			params: {
				public: true
			}
		})
		.state('main.public.features', {
			url: '/features',
			views: {
				'content@main': {
					templateUrl: 'public/features.html',
					controller: 'PublicController',
					controllerAs: 'publicController'
				}
			},
			params: {
				public: true
			}
		})
		.state('main.public.roles', {
			url: '/roles',
			views: {
				'content@main': {
					templateUrl: 'public/roles.html',
					controller: 'PublicController',
					controllerAs: 'publicController'
				}
			},
			params: {
				public: true
			}
		})
		.state('main.public.pricing', {
			url: '/pricing',
			views: {
				'content@main': {
					templateUrl: 'public/pricing.html',
					controller: 'PublicController',
					controllerAs: 'publicController'
				}
			},
			params: {
				public: true
			}
		})
		.state('main.public.support', {
			url: '/support',
			views: {
				'content@main': {
					templateUrl: 'public/support.html',
					controller: 'PublicController',
					controllerAs: 'publicController'
				}
			},
			params: {
				public: true
			}
		})
		.state('main.public.contact', {
			url: '/contact',
			views: {
				'content@main': {
					templateUrl: 'public/contact.html',
					controller: 'PublicController',
					controllerAs: 'publicController'
				}
			},
			params: {
				public: true
			}
		})
		.state('main.public.privacy-policy', {
			url: '/privacy-policy',
			views: {
				'content@main': {
					templateUrl: 'public/privacy-policy.html',
					controller: 'PublicController',
					controllerAs: 'publicController'
				}
			},
			params: {
				public: true
			}
		})
		.state('main.public.security', {
			url: '/security',
			views: {
				'content@main': {
					templateUrl: 'public/security.html',
					controller: 'PublicController',
					controllerAs: 'publicController'
				}
			},
			params: {
				public: true
			}
		})
		.state('main.public.terms-of-service', {
			url: '/terms-of-service',
			views: {
				'content@main': {
					templateUrl: 'public/terms-of-service.html',
					controller: 'PublicController',
					controllerAs: 'publicController'
				}
			},
			params: {
				public: true
			}
		})

		// Owner login
		.state('main.owner', {
			url: '/owner',
			abstract: true,
			params: {
				public: true
			},
			resolve: {
				translation: function($translate, $translatePartialLoader, $rootScope) {
					$translatePartialLoader.addPart('owner');
					return translateRefresh($translate, $rootScope);
				}
			}
		})

		.state('main.owner.login', {
			url: '/login',
			views: {
				'sidebar@main': {
					templateUrl: null
				},
				'content@main': {
					templateUrl: 'seller-section/seller.login.html',
					controller: 'SellerLoginController',
					controllerAs: 'loginController'
				}
			},
			params: {
				public: true
			},
			data: {
				title: 'PAGES.LOGIN.TITLE'
			},
			resolve: {
				translation: function($translatePartialLoader, $translate, $rootScope) {
					$translatePartialLoader.addPart('login');
					return translateRefresh($translate, $rootScope);
				}
			}
		})

		.state('main.owner.listing', {
			url: '/listing',
			views: {
				'content@main': {
					templateUrl: 'seller-section/seller.listing.html',
					controller: 'SellerMainController',
					controllerAs: 'listingController'
				}
			},
			params: {
				menu: 'seller-listing',
				tab: 'listing',
				whitelist: roles.S
			},
			resolve: {
				check: function () {
					return null;
				}
			},
			data: {
				getTitle: function (params, NavService) {
					return NavService.getListingAddress(params.id).then((res) => {
						return (res || {}).address || 'LISTINGS.LISTING';
					})
				}
			}
		})
	});
})();
