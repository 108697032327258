/* eslint-disable no-undef */
/**
 *  Add new bookmark modal
 */

(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('BookmarksController', function ($uibModalInstance, $scope, bookmark, BookmarkService, MenuService) {

		const self = this;

		self.bookmark = bookmark || { url: '', title: '' };

		$uibModalInstance.scope = $scope;
		$uibModalInstance.check = {
			original: angular.copy(self.bookmark),
			clone: self.bookmark
		}

		self.saveBookmark = function () {
			if (!self.bookmark.url.includes('http')) {
				self.bookmark.url = 'http://' + self.bookmark.url;
			}

			BookmarkService.saveBookmark(self.bookmark).then((res) => {
				MenuService.addBookmark(res, !self.bookmark.id);
				$uibModalInstance.close();
			})
			.catch((err) => {})
		}

		self.close = function () {
			$uibModalInstance.dismiss('cancel');
		};


	}) // End of controller
})(); // End of function()
