(function () {

    "use strict";

    angular.module("smartbrokr")

    .controller("RoleListingsController", function ($filter, $location, $log, $scope, $state, $stateParams, AlertService, FileService, GlobalVars, RoleService, SortingService) {
        
        var self = this;
        var page            = 'role-listings';
        var cityOptions     = [];
        var provOptions     = [];
        var countryOptions  = [];
        var statusOptions   = [];
        var brokerOptions   = [];

        var singleConfig    = GlobalVars.singleConfig;
        var locationConfig  = GlobalVars.locationConfig;

        var hashes = {};

        self.folders    = [];
        self.crumbAddOn = {};
        self.legends    = GlobalVars.popover('listingStatusLegends');

        $scope.$watch(function () { return $location.hash(); }, function () {
            doHash();
        });
        
        self.back = function() {
            $location.hash(null);
        }

        self.sortInfo = {
            page: page,
            sub: ['property', 'property._address', 'brokers.user']
        }

        self.navParams = {
            hash: function(listing) {
                return listing.id;
            }    
        }

        self.mobileNav = function(id) {
            $location.hash(id);
        }

        self.filters = SortingService.getPageFilter(page);

        if (!self.filters) {
            self.filters = SortingService.createPageFilter(
                page,
                {
                    addressString: { weight: 0, value: '', desc: 1 },
                    city: { weight: 0, value: '', desc: 1 },
                    province: { weight: 0, value: null, desc: 1 },
                    country: { weight: 0, value: null, desc: 1 },
                    id: { weight: 5, value: '', desc: -1, id: true },
                    status: { weight: 0, value: '', desc: 1, top: true },
                    fullName: { weight: 0, value: '', desc: 1 }
                }
            );
        }

        self.fileFoldersOptions = {
            hideFunction: function(options) {
                options.hideFlag = !options.hideFlag;
            },
            hideCondition: function(folder, flag) {
                if (folder) {
                    return flag && (!folder.required || (folder.required && folder.files.length > 0) );
                }
                else {
                    return flag;
                }
            },
            folderClass: function(folder) {
                return folder.required ? 'mandatory' : null;
            },
            folderName: function(folder) {
                return 'FILES.DOCUMENT_TYPES.' + folder.documentTypeId;
            },
            readOnly: true,
            download: function(file, folder) {
                file = file || {};
                folder = folder || {};

                if (file.id && folder.id) {
                    FileService.downloadDocument(folder.listingId, folder.id, file.id);
                }
            }
        }
        
        if ($stateParams.role != 'notary' && $stateParams.role != 'auditor') {
            $state.go('main.error', { menu: null, type: "", error: "Your role [" + $stateParams.role + "] does not have access to that page.",code:403 });
        }
        else {
            AlertService.loading();
            RoleService.getListings().then(function (res) {
                self.listings = res;

                hashes = res.reduce(function(obj, item, i){
                    if (item.id) {
                        obj[item.id] = i;
                    }
                    return obj;
                }, {});

                var aux = $filter('unique')(self.listings, 'property._address.city');
                cityOptions = aux.reduce(function(arr, curr) {
                    arr.push(curr);
                    return arr;
                }, [{ name: '------', id: '' }]);

                aux = $filter('unique')(self.listings, 'property._address.province');
                provOptions = aux.reduce(function(arr, curr) {
                    arr.push(curr);
                    return arr;
                }, [{ name: '------', id: '' }]);

                aux = $filter('unique')(self.listings, 'property._address.country');
                countryOptions = aux.reduce(function(arr, curr) {
                    arr.push(curr);
                    return arr;
                }, [{ name: '------', id: '' }]);

                aux = $filter('unique')(self.listings, 'status');
                statusOptions.push({ name: '------', value: '' });
                aux = aux.sort(function (a, b) { return a.localeCompare(b) - b.localeCompare(a); });
                for (var i = 0; i < aux.length; i++) {
                    statusOptions.push({ name: $filter('translate')('LISTING.STATUS.' + (aux[i] || '').toUpperCase()), value: aux[i] });
                }

                aux = $filter('unique')(self.listings, 'brokers');
                brokerOptions = aux.reduce(function(arr,item){
                    if (!!(item.user || {}).fullName) {
                        arr.push({ name: item.user.fullName, value: item.id });
                    }
                    return arr;
                }, []);
                brokerOptions = brokerOptions.sort(function(a,b){
                    return a.name.localeCompare(b.name) - b.name.localeCompare(a.name);
                })
                brokerOptions.unshift({ name: '------', value: '' });

                self.columns = [
                    {
                        name: "ID",
                        filter: 'id',
                        field: function(listing) {
                            return listing.id;
                        }
                    },
                    {
                        name: "ADDRESS.ADDRESS",
                        filter: 'addressString',
                        field: function(listing) {
                            return ((listing.property || {})._address || {}).addressString || 'N/A';
                        }
                    },
                    {
                        name: "ADDRESS.REGION",
                        select: true,
                        options: cityOptions,
                        filter: 'city',
                        config: locationConfig,
                        field: function(listing) {
                            return (((listing.property || {})._address || {}).city || {}).name || 'N/A';
                        }
                    },
                    {
                        name: "ADDRESS.PROVINCE",
                        select: true,
                        selectType: 'province',
                        options: provOptions,
                        filter: 'province',
                        config: locationConfig,
                        field: function(listing) {
                            return (((listing.property || {})._address || {}).province || {}).id || 'N/A';
                        }
                    },
                    {
                        name: "ADDRESS.COUNTRY",
                        select: true,
                        options: countryOptions,
                        filter: 'country',
                        config: locationConfig,
                        field: function(listing) {
                            return (((listing.property || {})._address || {}).country || {}).name || 'N/A';
                        }
                    },
                    {
                        name: "COMMON.STATUS",
                        select: true,
                        options: statusOptions,
                        filter: 'status',
                        config: singleConfig,
                        field: function(listing) {
                            return $filter('translate')('LISTING.STATUS.' + (listing.status || '').toUpperCase());
                        }
                    },
                    {
                        name: "ROLES.BROKER",
                        select: true,
                        options: brokerOptions,
                        filter: 'fullName',
                        isHtml: true,
                        style: { 'min-width': '150px' },
                        config: singleConfig,
                        field: function(listing) {
                            var ret = '';
                            var i, length = (listing.brokers || []).length;
                            for (i = 0; i < length; i++) {
                                if ((listing.brokers[i].user || {}).fullName) {
                                    ret += '<div>' + listing.brokers[i].user.fullName + '</div>';
                                }
                            }
                            
                            return ret;
                        }
                    }
                ];

                if (!!$location.hash()) {
                    doHash();
                }
            })
            .catch(function (err) {
                $log.error(err);
            })
            .finally(AlertService.doneLoading)
        }

        function doHash() {
            var curr = $location.hash();
            var i = hashes[curr];
            if (i || i == 0) {
                self.show = self.listings[i] || null;
                
                if (self.show) {
                    self.crumbAddOn.strong = self.show.id;
                    self.crumbAddOn.title = ': ' + 
                        (((self.show.property || {})._address || {}).addressString || '---') + ', ' + 
                        ((((self.show.property || {})._address || {}).city || {}).name || '---') + ', ' + 
                        ((((self.show.property || {})._address || {}).province || {}).id || '---');

                    AlertService.loading();

                    RoleService.getDocuments(self.show.id).then(function(res){
                        self.show.documentMetadata = res;
                    })
                    .finally(function(){
                        AlertService.doneLoading();
                    })
                }
                else {
                    self.crumbAddOn.title = null;
                    self.crumbAddOn.strong = null;
                }
            }
            else {
                self.show = null;
                self.crumbAddOn.title = null;
                self.crumbAddOn.strong = null;
            }
        }
    });
})();
