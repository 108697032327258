/**
 * © copyright 2017, 2818779 Canada Inc.
 * 
 * Use of this software is only permitted if you have entered into a
 * license agreement with 2818779 Canada Inc. and is subject to the terms
 * and conditions of such license agreement.
 *
 */

(function () {
    
      "use strict";
    
      angular.module("smartbrokr.cropper", [])
    
        .service("CropperService", function () {
    
          var self = this;
          self.cropper = {
              sourceImage: null,
              croppedImage: null,
                cropWidth: 100,
                cropHeight: 100
          };
          self.bounds = {
              left:0, 
              right:0,
              top:0, 
              bottom: 0
          };
        });
    })(); // End of function()
    