/* eslint-disable no-undef */
/**
 *  Profile page for Buyers and Sellers
 */

(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('ProfileController', function ($sce, $filter, $scope, $stateParams, $timeout, AccountService, AlertService, NavService, ModalService, BuyerService, $state, SellerService, SupplierService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.person     = null;   // SbUser profile
		self.buyers     = [];     // One Buyer object for each parameter combination in this person's buyer profile
		self.seller     = null;   // Seller profile
		self.listings   = [];     // Listings belonging to the person we are viewing

		self.type       = null;   // Type of profile used to get to page (buyer or seller)

		self.suppliers = [];
		self.options    = null;
		self.buyerCities = [];
		self.actionLogs = [];

		self.searchTerm     = '';

		// SETUP VARIABLES ======================================================================================================================

		self.type = $stateParams.role;

		self.options = {
			buyer: BuyerService,
			seller: SellerService,
			supplier: SupplierService,
		};

		if ($stateParams.id != null) {
			$scope.role = $stateParams.role;
			getPerson($stateParams.id);
		}

		self.expand = function(i) {
			if ($scope.expand === i) {  // Closing log that is open
				$scope.expand = null;
			}
			else if ($scope.expand !== null) {  // Changing opened log. Close first one, then open the next
				$scope.expand = null;

				$timeout(() => {
					$scope.expand = i;
				}, 175);

			}
			else {  // No other log is expanded -> Just open it
				$scope.expand = i;
			}
		}

		function doTranslations(allItems) {
			let i, length = allItems.length;

			const getOne = function(item) {
				item = item || '';
				if (typeof item === 'object') {
					if (item.vars.scheduled) {
						item.vars.scheduled = $sce.valueOf($filter('date')(item.vars.scheduled, 'EEE, MMM dd yyyy, hh:mm a'));
					}
					return '' + $filter('translate')(item.translation,item.vars);
				}
				else if (typeof item === 'string') {
					if (item.includes('LOG') || item.includes('TASK_LIST')){
						return '' + $filter('translate')(item);
					}
					else if (item.includes('Content')){     // Remove email content from old logs
						return item.substring(0, item.indexOf('Content'));
					}
				}

				return item || '';
			}

			for (i = 0; i < length; i++) {
				allItems[i].action = getOne(allItems[i].action);
				allItems[i].actionResult = getOne(allItems[i].actionResult);

				if ((allItems[i].actionResult || '').replace)
					allItems[i].actionResult = (allItems[i].actionResult || '').replace(/@/g, '@<wbr>');
			}

			return angular.copy(allItems);
		}


		function getPerson(id) {
			const API = self.options[$stateParams.role];
			AlertService.loading();
			API.getPerson(id).then((res) => {

				if (res.length > 0) {
					res = res[0];
					self.relation = angular.copy(res);
					self.person = (res.seller || res.buyer).user;
					self.buyer = self.relation.buyer || self.person.buyerProfile;
					self.seller = self.relation.seller || self.person.sellerProfile;
					self.person.buyerProfile = self.buyer;
					self.person.sellerProfile = self.seller;
					self.listings = (self.seller || {}).listings || [];

					const buyerLogs = (self.buyer || {}).logsAbout || [];
					const userLogs = self.person.logsAbout || [];

					self.suppliers = (self.buyer || {}).suppliers || (self.seller || {}).suppliers || [];

					const concatenatedLogs = buyerLogs.concat(userLogs);

					if(concatenatedLogs.length > 3) {
						self.actionLogs = doTranslations(concatenatedLogs);
					} else {
						concatenatedLogs.sort((valueA, valueB) => {
							const d1 = new Date(valueA.created);
							const d2 = new Date(valueB.created);

							if(d1.getTime() < d2.getTime()) return 1;
							if(d1.getTime() > d2.getTime()) return -1;

							return 0;
						})
						self.actionLogs = doTranslations(concatenatedLogs.slice(0, 2));
					}


					self.buyerCities = (self.buyer || {}).buyerCities || [];

					self.newNote = {
						header: $filter('translate')('ROLES.' + ($stateParams.role || 'person').toUpperCase()) + ': ' + self.person.fullName,
						text: '',
						aboutId: self.person.id,
						aboutType: 'SbUser'
					};

					let buyer = self.relation.buyer || null;
					if (buyer) {
						$state.params.fk = buyer.id;
					}
					else if (self.buyer) {
						buyer = self.buyer;
					}
					// Creates one "buyer" object for each city
					if (buyer != null) {
						self.cities = buyer.cities;
						if (!buyer.cities) {
							buyer.cities = [];
						}

						self.buyers = [];
						let i = 0;
						do {
							self.buyers.push({
								id: buyer.id,
								preApproved: buyer.preApproved,
								cities: [buyer.cities[i] || null],
								neighbourhoods: [],
								minPrice: buyer.minPrice,
								maxPrice: buyer.maxPrice,
								user: { id: self.person.id, fullName: self.person.fullName, firstName: self.person.firstName, lastName: self.person.lastName },
								propertyTypeIds: buyer.propertyTypeIds
							});
							i += 1;
						}
						while (i < (buyer.cities || []).length);
					}
					else {
						self.buyers = [];
					}
				}
				else {
					AlertService.error('COMMON.DATA', 'load');
				}
			})
			.catch((err) => { console.error(err); AlertService.error('COMMON.DATA', 'load'); })
			.finally(() => { AlertService.doneLoading(); })
		}


		// FUNCTIONS ============================================================================================================================

		self.addVisit = function () {
			const visit = {
				type: 'V',
				users: [self.person]
			}

			const retFunction = function (result) {
				if (!!result.id) {
					getPerson($stateParams.id);
					AlertService.success('COMMON.VISIT', 'create');
				}
			}

			const isManager = AccountService.isAdmin();
			self.user = isManager ? AccountService.getManaging() : AccountService.getAccount().user;

			ModalService.openModalWithFunction( 'createVisit',
				{
					visit: function () { return visit },
					isAppointment: function () { return false },
					isGoogle: () => (self.user && self.user.googleCalendarId && self.user.isGoogleIntegrated) ? true : false,
				},
				'VisitController',
				'visitController',
				retFunction );
		}

		const openModal = function (people, peopleType) {
			const linkType = self.type;
			let id = '';
			if($stateParams.role == 'buyer') {
				id = self.buyer.id;
			} else {
				id = self.seller.id;
			}
			return ModalService.openModal('addPersonToListing', {
				id: function () { return id },
				people: function () { return people },
				peopleType: function () { return peopleType },
				linkType: function() { return linkType }
			}, 'AddPersonController', 'addPersonController');
		}

		self.openSupplierModal = function() {
			SupplierService.getSuppliers(null, null, null).then((res) => {
				const modal = openModal(res, 'Supplier');
				modal.result.then((res) => {
					if(res.closed) return;
					else {
						AlertService.saved();
						getSuppliers();
					}
				})
				.catch((err) => {});
			})
			.catch((err) => {});
		}

		function getSuppliers() {
			const API = self.options[$stateParams.role];
			API.getSuppliers($stateParams.id)
			.then((res) => {
				self.suppliers = res;
			})
			.catch((err) => {
				console.error(err);
			});
		}

		self.unlinkSupplier = function(supplierId) {
			const API = self.options[$stateParams.role];
			let id = '';
			if($stateParams.role == 'buyer') {
				id = self.buyer.id;
			} else {
				id = self.seller.id;
			}
			API.unlinkSupplier(id,  supplierId).then((res) => {
				getSuppliers();
			})
			.catch((err) => {
				console.error(err);
			});
		}

		self.deleteProfile = function () {
			const retFunction = function () {
				NavService.navigateStack(0);
			}

			let delFunction, id, userType;

			const isBuyer = !!self.buyer;
			const isSeller = !!self.seller;
			const isBoth = !!self.buyer && !!self.seller;
			const hasListings = self.listings.length > 0;

			if (isBuyer && !isSeller) {
				delFunction = BuyerService.deleteBuyer;
				id = self.buyer.id;
				userType = 'buyer';
			}
			else if (!isBuyer && isSeller && !hasListings) {
				delFunction = SellerService.deleteSeller;
				id = self.seller.id;
				userType = 'seller';
			}

			if (!isBoth && !hasListings) {
				ModalService.openModalWithFunction( 'deleteUser',
					{
						id: function () { return id; },
						user: function () { return self.person; },
						deleteFunction: function () { return delFunction; },
						userType: function () { return userType }
					},
					'DeleteUserController',
					'deleteUserController',
					retFunction );
			}
			else {
				let message, title;

				if (!isBoth && hasListings) {
					message = 'PERSON.SELLER.ERRORS.DELETE_HAS_LISTINGS';
					title = 'PERSON.SELLER.ERRORS.CANT_DELETE';
				}
				else if (isBoth) {
					message = 'PERSON.ERRORS.CANT_DELETE_IS_BOTH';
					title = 'PERSON.ERRORS.CANT_DELETE';
				}

				ModalService.prompt(message, title, 'Ok', null, null, false, null);
			}
		}

		self.viewSentEmails = function() {
			NavService.navigate('main.profile.emails', { id: $stateParams.id, role: $stateParams.role });
		}

	}) // End of controller
})(); // End of function()
