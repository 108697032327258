/* eslint-disable no-undef */
(function() {

	'use strict';

	angular.module('smartbrokr')
	.controller('TaskTemplateModalController', function($filter, $scope, $translate, $uibModalInstance, template, editTask, AlertService, GlobalVars, ResourceService, TaskService) {

		const self = this;

		// VARIABLES ============================================================================================================================
		let maxSeq          = 0;        // Maximum task sequence number
		self.next           = false;
		self.editing        = false;    // Whether this is a new template or not
		self.header         = null;     // Title shown by modal
		$scope.loadingData  = true;	    // False when loading is done

		self.newTemplate    = null;     // Template that is being created/edited
		self.tasks          = [];       // Task models belonging to that template

		self.currentTask    = null;     // Task that we are currently editing

		self.agencyPeople   = null;     // Options for "assigned to"

		self.addTasks       = false;    // Used to change form when creating a new task template

		self.config         = null;     // Config for selectize input
		self.options        = [];       // Task template category options
		self.categories     = [];     // Task template selected categories

		const taskNames       = [];

		// SETUP VARIABLES ======================================================================================================================

		self.header         = $filter('translate')('TASK.TEMPLATE_MODAL.TITLE'); // + New Tasks
		self.newTemplate    = template || {};
		self.config         = GlobalVars.createMultiConfig(false, 'value', 'label', true);
		self.editing        = !!editTask || !!self.newTemplate.id;

		self.options = [
			{ value: 'isPersonal', label: $filter('translate')('PERSON.PERSONAL') },
			{ value: 'isListing', label: $filter('translate')('LISTING.LISTING') },
			{ value: 'isBuyer', label: $filter('translate')('ROLES.BUYER') },
			{ value: 'isSeller', label: $filter('translate')('ROLES.SELLER') },
			{ value: 'isSupplier', label: $filter('translate')('ROLES.SUPPLIER') }
		]

		if (!!self.newTemplate.id && !!self.newTemplate.tasks) {
			self.tasks = self.newTemplate.taskList;
			let i, length = (self.tasks || []).length || 0;
			for (i = 0; i < length; i++) {
				if (self.tasks[i].seq > maxSeq) {
					maxSeq = self.tasks[i].seq;
				}
			}

			self.options.forEach((opt) => {
				if (self.newTemplate[opt.value]) {
					self.categories.push(opt.value);
				}
			})
		}
		else {
			self.header = $filter('translate')('RESOURCES.NEW_TASK_TEMPLATE');
		}

		maxSeq += 1;

		if (editTask) {
			self.currentTask = editTask;
			self.header = $filter('translate')('TASK.EDIT_TASK');
		}
		else {
			self.currentTask = {
				name: '',
				seq: maxSeq,
				due: 0,
				assignedToId: null,
				assignedToType: null
			};
		}

		$uibModalInstance.scope = $scope;
		$uibModalInstance.check = {
			original: {
				template: angular.copy(self.newTemplate),
				tasks: angular.copy(self.tasks),
				currentTask: angular.copy(self.currentTask),
				categories: angular.copy(self.categories)
			},
			clone: {
				template: self.newTemplate,
				tasks: self.tasks,
				currentTask: self.currentTask,
				categories: self.categories
			}
		}

		TaskService.getAgencyPeople().then((res) => {
			self.agencyPeople = res;

			self.agencyPeople = res.reduce((arr,item) => {
				item.hidden = $filter('translate')(item.dataType);
				item.search = (item.user || {}).fullName + '|' + item.hidden;
				arr.push(item);
				return arr;
			},[]);
		})
		.catch((err) => {})
		.finally(() => { $scope.loadingData = false; })

		getTaskNames();

		// FUNCTIONS ============================================================================================================================

		self.close = function () {
			$uibModalInstance.dismiss('cancel');
		};

		self.save = function(goNext, taskForm) {

			self.currentTask.assignedToId   = (self.currentTask.assignedTo || {}).id || null;
			self.currentTask.assignedToType = (self.currentTask.assignedTo || {}).dataType || self.currentTask.assignedToType || null;

			if (!!self.currentTask.name && typeof self.currentTask.name === 'object') {
				self.currentTask.name = self.currentTask.name.label;
			}
			else if (self.currentTask.translated){
				const found = taskNames.find((item) => {
					return item.translated === self.currentTask.name;
				})

				if (found) {
					self.currentTask.name = found.label;
				}

				delete self.currentTask.translated;
			}

			if (editTask != false) {
				if (editTask == null) {
					maxSeq += 1;
					self.tasks.push(self.currentTask);
				}

				if (goNext) {
					self.currentTask = {
						name: '',
						seq: maxSeq,
						due: 0,
						assignedToId: null,
						assignedToType: null
					}

					if (taskForm) {
						taskForm.$setPristine();
					}
				}
			}

			self.newTemplate.tasks = angular.copy(self.tasks);
			ResourceService.saveTaskTemplate(self.newTemplate).then((res) => {
				if (!goNext) {
					AlertService.success('RESOURCES.TEMPLATE','save');
					$uibModalInstance.close(res);
				}
				else {
					$uibModalInstance.check = {
						original: {
							template: angular.copy(self.newTemplate),
							tasks: angular.copy(self.tasks),
							currentTask: angular.copy(self.currentTask)
						},
						clone: {
							template: self.newTemplate,
							tasks: self.tasks,
							currentTask: self.currentTask
						}
					}
				}
			})
			.catch((err) => {})
		}

		self.createTemplate = function() {
			AlertService.loading();

			self.categories.forEach((cat) => {
				self.newTemplate[cat] = true;
			})
			ResourceService.saveTaskTemplate(self.newTemplate).then((res) => {
				AlertService.doneLoading();
				if (!!self.newTemplate.id) {
					$uibModalInstance.close(res);
				}
				else {
					self.newTemplate = res;
					self.addTasks = true;
					self.currentTask = {
						name: '',
						seq: maxSeq,
						due: 0,
						assignedToId: null,
						assignedToType: null
					};
					editTask = null;

					$uibModalInstance.check = {
						original: {
							template: angular.copy(self.newTemplate),
							tasks: angular.copy(self.tasks),
							currentTask: angular.copy(self.currentTask)
						},
						clone: {
							template: self.newTemplate,
							tasks: self.tasks,
							currentTask: self.currentTask
						}
					}
				}
			})
			.catch((err) => {
				AlertService.doneLoading();
			});
		}

		self.searchAgency = function(value) {
			const ret = $filter('searchSingleFilter')(self.agencyPeople,value,{ 'user.fullName':'' });

			return ret.sort((a, b) => {
				return a.user.fullName.localeCompare(b.user.fullName) - b.user.fullName.localeCompare(a.user.fullName);
			});
		};

		self.searchTaskName = function(value) {
			value = value.toLowerCase();

			const ret = taskNames.reduce((arr, curr) => {

				if (curr.translated.toLowerCase().includes(value)) {
					arr.push(curr);
				}

				return arr;
			}, []);

			return ret.sort((a,b) => {
				return a.translated.localeCompare(b.translated);
			});
		}

		/**
     *  Changes a task's sequence number and changes other tasks' sequence number accordingly.
     *  @param seq: boolean. if true, sequence is being added 1. If false, it's substracting one.
     */
		self.changeSeq = function(add) {
			const tasks = self.tasks;
			const i = tasks.indexOf(self.currentTask);

			// All tasks with lower sequence num
			let lower;

			// All tasks with higher sequence num
			let higher;

			// The two tasks which will have its sequence num inverted
			let change;

			// Concats lower, change (with inverted indexes) and higher into a new array, then changes the necessary sequence numbers
			const finish = function() {
				self.tasks = [].concat(lower);

				if(change[1]) self.tasks.push(change[1]);
				if(change[0]) self.tasks.push(change[0]);
				self.tasks = self.tasks.concat(higher);
				let j, length = self.tasks.length;

				for (j = 0; j < length; j++) {
					if (self.tasks[j].seq != j+1) {
						self.tasks[j].seq = j+1;
					}
				}
			}

			if (add) {
				if (self.currentTask.seq < maxSeq-1) {
					lower = tasks.slice(0,i);
					higher = tasks.slice(i+2);
					change = tasks.slice(i,i+2);

					finish();
				}
			}
			else {
				if (self.currentTask.seq > 1) {
					lower = tasks.slice(0,i-1);
					higher = tasks.slice(i+1);
					change = tasks.slice(i-1,i+1);

					finish();
				}
			}
		}

		function getTaskNames() {

			$scope.translating = true;

			const labels = [
				'TASK.TASK_LIST.AGREEMENT', 'TASK.TASK_LIST.ANALYTICS', 'TASK.TASK_LIST.APPRAISAL',
				'TASK.TASK_LIST.BUYER_NOTIFICATION', 'TASK.TASK_LIST.CARAVAN_SHEET', 'TASK.TASK_LIST.CREATE_LISTING',
				'TASK.TASK_LIST.CLOSED', 'TASK.TASK_LIST.CONDITIONS', 'TASK.TASK_LIST.COUNTER_OFFER',
				'TASK.TASK_LIST.DOCUMENTS_COLLECTED', 'TASK.TASK_LIST.CLOSING_DATE', 'TASK.TASK_LIST.FEATURE_SHEET',
				'TASK.TASK_LIST.FINANCE', 'TASK.TASK_LIST.INSPECTIONS', 'TASK.TASK_LIST.MEASUREMENTS',
				'TASK.TASK_LIST.MARKETING', 'TASK.TASK_LIST.NOTIFY', 'TASK.TASK_LIST.OFFER_PROGRESS',
				'TASK.TASK_LIST.RECEIVED_OFFER', 'TASK.TASK_LIST.OFFER_ACCEPTED', 'TASK.TASK_LIST.OFFER_REFUSED',
				'TASK.TASK_LIST.OPEN_HOUSE', 'TASK.TASK_LIST.PEER_TO_PEER', 'TASK.TASK_LIST.PHOTOGRAPHY',
				'TASK.TASK_LIST.PRINT_ADS', 'TASK.TASK_LIST.PROPOSAL', 'TASK.TASK_LIST.PROGRESS_REPORT',
				'TASK.TASK_LIST.SIGNAGE', 'TASK.TASK_LIST.UPDATE_BROKER_SITE', 'TASK.TASK_LIST.CREATE_VIDEO',
				'TASK.TASK_LIST.VIRTUAL_CARAVAN' ];

			$translate(labels).then(
				(translated) => {
					for (const label in translated) {
						const toAdd = { label: label, translated: '' + translated[label] };
						if (self.currentTask.name === label) {
							self.currentTask.name = '' + translated[label];
							self.currentTask.translated = true;
						}

						taskNames.push(toAdd);
					}
				}, (ids) => {})
			.finally(() => {
				$scope.translating = false;
			})
		}

	}) // End of controller
})(); // End of function()
