(function () {

  "use strict";

  angular.module("smartbrokr")
  .controller("NewUserController", function ($uibModalInstance, $scope, $filter, AlertService, AccountService, AgencyService, GlobalVars, LanguageService, type, agencyId) {

    var self = this;

    // VARIABLES ============================================================================================================================

    self.title;                   // Modal title
    self.account;                 // Account for user that is getting created
    self.accountType;             // Type of account that we are creating (broker, member, auditor, notary)
    self.agencyId;                // Agency that member or broker will belong to

    self.languageConfig;          // Selectize config
    self.agencyConfig;            // Selectize config
    self.statusConfig;            // Selectize config
    self.auditorCalendar;         // Calendar config - auditor
    self.notaryCalendar;          // Calendar config - notary

    self.agencyOptions;           // Options for agency dropdown
    self.languages;               // Options for language dropdown
    self.statusOpt;               // Options for broker status dropdown
    self.emailRequired;           // True if account type is not broker or account type is broker and broker is active

    // SETUP VARIABLES ======================================================================================================================

    self.agencyId         = agencyId;
    self.accountType      = type;
    self.languageConfig   = GlobalVars.createSingleConfig(false, 'id', 'nameNative', 'nameNative');
    self.agencyConfig     = GlobalVars.createSingleConfig(false, 'id', 'legalName', 'legalName');
    self.statusConfig     = GlobalVars.singleConfig;
    self.emailRequired    = type != 'broker';
    self.auditorCalendar  = GlobalVars.createCalendarConfig(new Date(),null);
    self.notaryCalendar   = GlobalVars.createCalendarConfig(new Date(),null);
    
    self.account = {
      agencyId: self.agencyId || AccountService.getAgencyId(),
      type:     type || null
    }

    switch(type) {
      case 'member':
        self.title = 'PERSON.MEMBER.NEW_MEMBER'; break;
      case 'broker':
        self.title = 'PERSON.BROKER.NEW_BROKER'; break;
      case 'auditor':
        self.title = 'PERSON.AUDITOR.NEW_AUDITOR'; break;
      case 'notary':
        self.title = 'PERSON.NOTARY.NEW_NOTARY'; break;
      default:
        self.title = 'PERSON.NEW_USER';
    }

    /*self.auditorCalendar = {
      opened: false,
      openMe: function () { this.opened = !this.opened; },
      options: {
        formatYear: 'yy',
        maxDate: null,
        minDate: new Date(),
        startingDay: 1
      }
    };

    self.notaryCalendar = {
      opened: false,
      openMe: function () { this.opened = !this.opened; },
      options: {
        formatYear: 'yy',
        maxDate: null,
        minDate: new Date(),
        startingDay: 1
      }
    };*/

    self.statusOpt 			= [
      { name: $filter('translate')('COMMON.ACTIVE'), value: 'ACTIVE' },
      { name: $filter('translate')('COMMON.INACTIVE'), value: 'INACTIVE' }
    ];

    self.rolesOpt       = [
      { name: $filter('translate')('ROLES.BROKER'), value: 'broker' },
      { name: $filter('translate')('ROLES.AGENCY_MEMBER'), value: 'member' },
      { name: $filter('translate')('ROLES.AGENCY_OWNER'), value: 'owner' },
      { name: $filter('translate')('ROLES.AUDITOR'), value: 'auditor' },
      { name: $filter('translate')('ROLES.NOTARY'), value: 'notary' }
    ]

    // FUNCTIONS ============================================================================================================================

    self.checkEmail = function() {
      self.account = self.account || {};
      self.emailRequired = !!self.account.type && self.account.type != 'broker';
      $scope.userForm.email.$validate();
    }

    self.close = function () {
      $uibModalInstance.dismiss('cancel');
    }

    self.createAccount = function (userForm) {
      AlertService.loading();
      AccountService.createAccount(self.account)
        .then(function (res) {
          AlertService.doneLoading();
          $uibModalInstance.close(res);
        })
        .catch(function (err) {
          console.log("err? ", err);

          if (err.status === 406 && self.account.type === 'auditor') {
            $scope.userForm.email.$setValidity('uniqueEmail',false);
          }
          AlertService.doneLoading();
        })
    }

  }) // End of controller
})(); // End of function()
