/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('SmartbrokrController', function ($filter, $rootScope, $scope, $timeout, $window, AccountService, AlertService, BrokerService, BrowserInformation, GlobalVars, ModalService, NavService, StorageService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.browserClass 	= BrowserInformation.getClass();			// Which browser is being used
		self.showMenu 		= false;									// Whether to show main menu (used only for mobile navigation)
		self.isMobile 		= BrowserInformation.isMobile();			// Whether this is the mobile version
		$rootScope.isMobile = self.isMobile;
		self.limitedAccess 	= AccountService.limitedAccess;				// Getter function for limited access flag
		$rootScope.managing = AccountService.getManaging() || {};

		$scope.pagination = {
			current: 1
		}

		let contentScope = null;

		// SETUP VARIABLES ======================================================================================================================

		_checkScreenSize();

		angular.element($window).bind('resize', () => {
			_checkScreenSize();
			$rootScope.$emit('resize-window');
			$rootScope.$digest();
		});

		// ROOTSCOPE CHANGES ====================================================================================================================

		$rootScope.$on('resetPagination', () => {
			$scope.pagination.current = 1;
		})

		$rootScope.$on('$stateChangeSuccess', () => {
			self.currentLanguage = $rootScope.language;
			self.showMenu = false;
		})

		// Only add listeners on iOS
		if ( BrowserInformation.getClass().includes('ios') ) {
			// Get scope for main-content
			$rootScope.$on('$viewContentLoading', (event, view) => {
				if (view === 'content@main') {
					contentScope = (event.targetScope || {}).$id;
				}
			});

			// After view is loaded -> Add listener for touch events
			$rootScope.$on('$viewContentLoaded', (event) => {
				if (event.targetScope.$id === contentScope) {
					const mainContent = angular.element('.main-content');

					// Touch start -> Add listener for touch move
					mainContent.on('touchstart', (startEvent) => {
						mainContent.off('touchmove');		// Remove any other touchmove listener
						mainContent.off('touchend');		// Remove any other touchend listener

						// var inputs = angular.element(document).find('input:focus, textarea:focus, .selectize-input.input-active input');
						const active = document.activeElement;

						// Touch move -> Trigger once after touch start, then it's removed
						mainContent.on('touchmove', () => {
							active.value += 'x';						// If you change the input's value, safari automatically repositions the cursor
							active.value = active.value.slice(0,-1);
							mainContent.off('touchmove');

							mainContent.on('touchend', () => {
								active.blur();		// Blur on end, so cursor is not shown out of view
								mainContent.off('touchend');
							})
						})
					});

					contentScope = null;
				}
			})
		}

		self.changeLanguage = function (lang, dontReload) {
			if (lang && ($rootScope.TRANSLATABLE || []).indexOf(lang) >= 0) {
				self.currentLanguage = lang;
			}
			NavService.changeLanguage(self.currentLanguage, dontReload);
		}

		$timeout(() => {
			self.currentLanguage = $rootScope.language || StorageService.getLanguage();
			self.browserClass += ' ' + self.currentLanguage.toLowerCase();
		}, 0, true);

		// FUNCTIONS ============================================================================================================================

		self.getGlobalVar = function(name) {
			return GlobalVars[name];
		}

		self.getTranslate = function (term) {
			return $filter('translate')(term);
		}

		self.getLoadingText = function() {
			return AlertService.getLoadingText();
		}

		/** Gets a template url based on an id */
		self.popover = function (id) {
			return GlobalVars.popover(id);
		}

		self.getRole = function() {
			return AccountService.getRole();
		}

		/** Opens modal to send an email */
		self.sendMessage = function (receiver, supplier, listing, email, files, previousEmail) {

			const isAdmin = AccountService.getRole() === 'adminProfile';
			const isSeller = AccountService.getRole() === 'sellerProfile';

			if (!AccountService.limitedAccess() && !isSeller) {
				if (((receiver || {}).emailEnabled && (receiver || {}).email) || !receiver || previousEmail || isAdmin) {
					const resolve = {
						params: function() {
							return {
								receiverId: (receiver || {}).id || null,
								receiver: receiver || null,
								supplier: supplier,
								listing: listing,
								email: email,
								files: files || [],
								previousEmail: previousEmail
							}
						}
					}

					ModalService.openModal('sendEmail', resolve, 'SendEmailController', 'sendEmailController', null, 'email-modal');
				}
				else {
					ModalService.prompt('ALERT_MESSAGES.ALERT.CANT_EMAIL', null, 'Ok', null, null, true);
				}
			}
			else if ((receiver || {}).email) {
				$window.open('mailto:' + receiver.email);
			}
		}

		self.listingSummary = function(id) {
			ModalService.listingSummary(id);
		}

		/** Creates message to email an action log */
		self.sendLog = function (log) {
			if (!AccountService.limitedAccess()) {
				const email = {};
				email.text = '<h2>' + $filter('translate')('NOTES_LOG.LOG') + '</h2>' +
						'<p><strong>' + $filter('translate')('PERSON.USER') + '</strong>: ' + ((log.user || {}).fullName || 'N/A') + '</p>' +
						'<p><strong>' + $filter('translate')('DATES.TIME') + '</strong>: ' + $filter('dateFormat')(log.created) + '</p>' +
						'<p><strong>' + $filter('translate')('NOTES_LOG.ACTION') + '</strong>: ' + log.action;

				if(log.videoUrl) {
					email.text += '<p><strong>' + $filter('translate')('COMMON.VIDEO') + '</strong>: <a href="' + log.videoUrl + '">' + log.videoUrl + '</a>';
				}

				if (log.actionResult) {
					email.text += '<br>' + log.actionResult;
				}

				const from = (AccountService.getAccount() || {}).user || {};

				email.subject = $filter('translate')('NOTES_LOG.LOG_FROM') +
						(from.fullName || ((from.firstName || 'N/A') + ' ' + (from.lastName || '')));

				self.sendMessage(null, null, null, email);
			}
		}

		/** Opens modal to create new visit **/
		self.listingAppointment = function (listingId, person, role, supplier) {
			if (!AccountService.limitedAccess()) {
				const min = new Date();
				min.setHours(12);
				min.setMinutes(0);

				const visit = { listingId: listingId, time: min, type: 'V' };

				if (person && role && !supplier) {
					visit.users = [person];
				}
				else if (supplier) {

					visit.type = 'A';

					if (supplier != true) {
						person.supplier = supplier;

						visit.users = [person];
					}
				}

				const retFunction = function (result) {
					if (!!result.id) {
						$rootScope.$emit('updateLogs');
						AlertService.successMessage('NOTES_LOG.LOG_MESSAGES.VISIT_CREATED');
					}
				}

				ModalService.openModalWithFunction(
					'createVisit',
					{
						visit: function () { return visit },
						isAppointment: function () { return !!supplier },
						isGoogle: () => (self.user && self.user.googleCalendarId && self.user.isGoogleIntegrated) ? true : false,
					},
					'VisitController',
					'visitController',
					retFunction );
			}
		}

		self.navigate = function (state, params, pop) {
			if (pop) {
				NavService.popStack();
			}
			NavService.navigate(state, params, false);
		}

		self.navigateListing = function (listing, params) {
			const role = AccountService.getRole();
			switch(role) {
			case 'agencyMemberProfile':
			case 'agencyOwnerProfile':
				self.listingSummary(listing.id);
				break;
			case 'auditorProfile':
			case 'notaryProfile':
				self.navigate('main.role-listings.listings.documents', params);
				break;
			default:
				self.navigate('main.listings.detail.submenu', params);
			}
		}

		self.navigateBack = function (pop) {
			NavService.navigateBack(pop);
		}

		/** Navigation using crumbs **/
		self.goToStackItem = function (i, params) {
			NavService.navigateStack(i, params);
		}

		/** Logout **/
		self.logout = AccountService.logout;

		/** Gets class to display icons for files (currently used only in messages page) **/
		self.getIconClass = function (type) {
			if ((type || '').includes('pdf')) {
				return 'far fa-file-pdf';
			}
			else if ((type || '').includes('zip')) {
				return 'far fa-file-archive';
			}
			else if ((type || '').includes('csv') || (type || '').includes('sheet') || (type || '').includes('excel')) {
				return 'far fa-file-excel';
			}
			else {
				return 'far fa-file-alt';
			}
		}

		$rootScope.getIconClass = function (type) {
			return self.getIconClass(type);
		}

		self.openLink = function (link) {
			$window.open(link, '_blank');
		}

		// Used to open listings that cannot be accessed in the system
		self.outsideLink = function (listing) {
			if (listing.externalLink) {
				$window.open(listing.externalLink);
			}
			else {
				alert('No link.');
			}
		}

		self.messageNote = function (note) {
			if (!AccountService.limitedAccess()) {
				const text =
						$filter('translate')('NOTES_LOG.NOTE_ABOUT_MSG') + ':\n' + note.header + '\n\n' +
						$filter('translate')('COMMON.TEXT') + ':\n' + note.text;

				ModalService.openModal(
					'newMessage',
					{
						message: function () { return text; },
						sender: function () { return null; },
						receiver: function () { return null; }
					},
					'MessageModalController',
					'messageModalController' );
			}
		}

		self.handleNavigation = function(readOnly, listing) {
			if(!readOnly) {
				if(!listing.readOnly) {
					if(listing.isSoldDuplicate) {
						self.navigate('main.listings.detail.submenu', { submenu: 'smart', id:listing.id });
					} else if(listing.soldDuplicateId) {
						if(listing.status == 'Active') {
							self.navigate('main.listings.detail.submenu', { submenu: 'active', id:listing.id });
						} else {
							self.navigate('main.listings.detail.submenu', { submenu: 'archive', id:listing.id });
						}
					} else {
						self.navigateListing(listing, { id:listing.id })
					}
				} else {
					self.listingSummary(listing.id)
				}
			}
		}

		self.editLog = function (log) {
			if (!AccountService.limitedAccess()) {
				BrokerService.getAppointment(log.aboutId)
				.then((res) => {
					const visit = res[0];
					ModalService.openModal(
						'createVisit',
						{
							visit: function () { return visit; },
							isAppointment: function () { return true; }
						},
						'VisitController',
						'visitController' );
				})
				.catch((err) => {})
			}
		}

		self.showBrokerButton = function () {
			return AccountService.isLikeBroker();
		}

		$rootScope.close = function (instance) {
			instance.dismiss('cancel');
		}

		$rootScope.cancelLoop = AlertService.cancelLoop;

		/* 	Used so we don't load mobile-header and page-header directives on every page,
			 *	even though we need only one of them */
		function _checkScreenSize() {
			const screenSize = $window.innerWidth || 0;

			if (screenSize >= 1200) {
				$rootScope.screenSize = 'lg';
			}
			else if (screenSize >= 992) {
				$rootScope.screenSize = 'md';
			}
			else if (screenSize >= 768) {
				$rootScope.screenSize = 'sm';
			}
			else {
				$rootScope.screenSize = 'xs';
			}
		}

	})	// End of controller
})();
