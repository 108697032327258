/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('BrokerEditController', function (check, UploadService, GlobalVars, AgencyService, AccountService, AlertService, BrokerService, NavService, $filter, $stateParams, $scope) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.broker         = null;     // Broker that we are editing
		self.isNew          = false;    // Whether it's a new profile or not

		self.singleConfig   = null;     // Selectize config
		self.statusOptions  = null;     // Options for status dropdown
		self.canUpload      = null;     // Whether we can upload something
		self.uploader       = null;     // Uploader object

		// SETUP VARIABLES ======================================================================================================================

		self.canUpload      = true;
		self.uploader       = UploadService.initUserUploader(true, null, { w: 640, h: 640 });
		self.singleConfig   = GlobalVars.createSingleConfig(false, 'value', 'name', 'name');

		self.singleConfig.allowEmptyOption = false;

		self.statusOptions = [
			{ value: 'ACTIVE', name: $filter('translate')('COMMON.ACTIVE') },
			{ value: 'INACTIVE', name: $filter('translate')('COMMON.INACTIVE') }
		]

		self.photoOptions = {
			classes: {
				upload: 'btn btn-green no-size upload',
				confirm: 'btn btn-green no-size upload',
				current: 'round avatar',
				new: 'round avatar'
			},
			dimensions: {
				init_w: 640,
				init_h: 640,
				result_w: 640,
				result_h: 640
			},
			checkSize: true
		}

		if (!!$stateParams.id) {
			getAccount();
		}
		else {
			self.broker = {
				_address: {},
				languageIds: [],
				regionsCoveredIds: []
			};
			self.user = self.broker;
			self.isNew = true;

			check.original = angular.copy(self.broker);
			check.clone = self.broker;
		}

		// FUNCTIONS ============================================================================================================================

		self.save = function() {
			check.isSaved = true;
			AlertService.loading();

			if (!self.isNew) {
				return BrokerService.updateBroker(self.broker.id, {
					status: self.broker.status,
					code: self.broker.code,
					currentCourses: self.broker.currentCourses,
					regionsCoveredIds: self.broker.regionsCoveredIds,
					person: self.user,
					url: self.broker.url,
					jobTitle: self.broker.jobTitle,
					description: self.broker.description,
					social_media_facebook: self.broker.social_media_facebook,
					social_media_facebook_show: self.broker.social_media_facebook_show,
					social_media_twitter: self.broker.social_media_twitter,
					social_media_twitter_show: self.broker.social_media_twitter_show,
					social_media_youtube: self.broker.social_media_youtube,
					social_media_youtube_show: self.broker.social_media_youtube_show,
					social_media_linkedin: self.broker.social_media_linkedin,
					social_media_linkedin_show: self.broker.social_media_linkedin_show,
					social_media_instagram: self.broker.social_media_instagram
				})
				.then((res) => {
					AlertService.doneLoading();
					AlertService.success('ROLES.BROKER','save');
					NavService.navigateBack();
				})
				.catch((err) => {
					AlertService.doneLoading();
					if (err.status == 422) {
						$scope.brokerForm.brokerCode.$valid = false;
						$scope.brokerForm.brokerCode.$invalid = true;
					}
					else {
						NavService.navigate('main.broker');
					}
				})
			}
			else {
				const account = angular.copy(self.broker);
				account.type = 'broker';
				account.agencyId = AccountService.getAgencyId();
				account.mainLanguage = (account.languageIds || [])[0] || 'EN';
				account.brokerCode = account.code;
				delete account.code;

				AccountService.createAccount(account).then((res) => {
					AlertService.doneLoading();
					NavService.navigate('main.broker.account', { id: res.id }, true);
				})
				.catch((err) => {
					if (err.status == 422) {
						$scope.brokerForm.brokerCode.$valid = false;
						$scope.brokerForm.brokerCode.$invalid = true;
					}
					AlertService.doneLoading();
				})
			}
		}

		function getAccount() {
			AlertService.loading();
			$scope.loading = true;
			AgencyService.getBroker($stateParams.id)
			.then((res) => {
				if (res.length === 1) {
					self.broker = res[0] || {};
					self.user = self.broker.user;

					if ((self.broker.currentCourses || []).length > 0) {
						self.coursesOpts = self.broker.currentCourses.reduce((arr, item) => {
							arr.push({ value: item });
							return arr;
						}, [])
					}
				}
				else {
					AlertService.errorMessage('accountDetails');
				}
			})
			.catch((err) => {})
			.finally(() => {
				$scope.loading = false;

				check.original = {
					broker: angular.copy(self.broker),
					user: angular.copy(self.user)
				}

				check.clone = {
					broker: self.broker,
					user: self.user
				}
				AlertService.doneLoading();
			})
		}
	});
})(); // End of function()
