(function () {

  "use strict";

  angular.module("smartbrokr.location", [])
  .service("LocationService", function (Country,Province, City, Region) {
    var self = this;

    // Countries with all their subdivisions
    self.getCountries = function(excludeOther) {
      var filter = {
        include: {
          provinces: {
            regions: 'cities'
          }
        }
      }

      if (excludeOther === true) {
        filter.where = {
          id: {
            neq: 'OTHER'
          }
        }
      }

      return Country.find({filter:filter}).$promise;
    }

    // Provinces by country
    self.getProvinces = function (countryId) {
      var filter = {
        where: {
          countryId: countryId
        }
      };
      return Province.find({filter:filter}).$promise;
    }

    // One province -> Includes regions and cities
    self.getProvinceById = function(id) {
      return Province.findById({
        id: id,
        filter: {
          include: {
            relation: 'regions',
            scope: {
              include: 'cities'
            }
          }
        }
      }).$promise;
    }

    // Gets cities by province
    self.getCitiesByProvince = function(province) {
      var ret = [];
      for (var i=0; i<province.regions.length; i++){
        if (province.regions[i].cities.length > 0) {
          ret = ret.concat(province.regions[i].cities);
        }
      }
      return ret.sort(function (a,b){ return a['name'].localeCompare(b['name']) - b['name'].localeCompare(a['name'])});
    }

    // All cities
    self.getCities = function () {
      return City.find().$promise;
    }

    self.getCityAreas = function(cityId) {
      return City.findById({
        id: cityId,
        filter: {
          include: [
            'neighbourhoods',
            'region'
          ]
        }
      }).$promise;
    }
    self.getCountry = function(id){
      return Country.findById({ id:id}).$promise;
    }
    self.getProvince = function(id){
      return Province.findById({ id:id}).$promise;
    }
    self.getRegion = function(id){
      return Region.findById({ id:id}).$promise;
    }
    self.getCity = function(id) {
      var filter = {
        include: {
          region: { province: 'country' }
        }
      }
      return City.findById({ id:id, filter: filter }).$promise;
    }


  }) // Service

})();
