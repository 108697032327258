(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('SupplierProfileController', function ($filter, $stateParams, AccountService, AlertService, ModalService, NavService, OwnerService, SupplierService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.supplier   = null;   // Supplier whose profile we are showing
		self.newNote    = null;   // Object to create new note about this supplier, with some data already filled in
		self.isSeller   = AccountService.getRole() === 'sellerProfile';
		const id          = $stateParams.sId || $stateParams.id;

		// SETUP VARIABLES ======================================================================================================================

		getSupplier();

		// FUNCTIONS ============================================================================================================================

		function getSupplier() {
			AlertService.loading();
			let api = SupplierService.getMySupplier;

			if (self.isSeller) {
				api = OwnerService.getSuppliers;
			}

			api(id).then((res) => {

				if (Array.isArray(res)) {
					res = res[0] || {};
				}

				self.supplier = res;

				if (!res.isMine && !!res.owner) {
					res.owner.emailEnabled = false;
				}

				self.newNote = {
					header: $filter('translate')('ROLES.SUPPLIER') + ': ' + self.supplier.company,
					text: '',
					aboutId: self.supplier.id,
					aboutType: 'Supplier'
				};
			})
			.catch((err) => {})
			.finally(AlertService.doneLoading)
		}


		self.deleteProfile = function () {
			const retFunction = function () {
				NavService.navigateStack(0);
			}

			ModalService.openModalWithFunction('deleteUser', {
				id: function () { return self.supplier.id },
				user: function () { return self.supplier; },
				deleteFunction: function () { return SupplierService.deleteSupplier; },
				userType: function () { return 'supplier'; }
			},
			'DeleteUserController',
			'deleteUserController',
			retFunction );
		}

		self.viewSentEmails = function() {
			NavService.navigate('main.suppliers.profile.emails', { id: $stateParams.id, role: $stateParams.role });
		}

	});  // End of controller
})(); // End of function()
