/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

(function () {

 	'use strict';

 	angular.module('smartbrokr')
	.controller('PublicController', function ($filter, $rootScope, $state, $scope, $timeout, PublicService) {
		const self = this;

		self.showPublicMenu       = false;
		$rootScope.pushContent    = true;

		self.features = [
			{
				icon: 'public/img/website/features-smart.png',
				item: 'SMART'
			},
			{
				icon: 'public/img/website/features-mobile.png',
				item: 'MOBILE'
			},
			{
				icon: 'public/img/website/features-centralize.png',
				item: 'CENTRALIZE'
			},
			{
				icon: 'public/img/website/features-tasks.png',
				item: 'TASK_MANAGER'
			},
			{
				icon: 'public/img/website/features-res-creator.png',
				item: 'MARKETING'
			},
			{
				icon: 'public/img/website/features-reports.png',
				item: 'REPORTS'
			},
			{
				icon: 'public/img/website/features-sharing.png',
				item: 'SHARING'
			},
			{
				icon: 'public/img/website/features-langs.png',
				item: 'MULTI_LANGUAGE'
			}
		]

		self.homeFeatures = [
			{
				icon: 'public/img/website/features-resources.png',
				key: 'RESOURCE_HUB',
				hasDescription: true
			},
			{
				icon: 'public/img/website/features-appts.png',
				key: 'APPOINTMENTS',
				hasDescription: true
			},
			{
				icon: 'public/img/website/features-match.png',
				key: 'MATCH',
				hasDescription: true
			},
			{
				icon: 'public/img/website/features-marketing.png',
				key: 'MARKETING',
				hasDescription: true
			},
			{
				icon: 'public/img/website/features-list.png',
				key: 'LIST',
				hasDescription: false
			},
			{
				icon: 'public/img/website/features-mobile.png',
				key: 'MOBILE',
				hasDescription: false
			}
		]

		self.plans = {
			broker: {
				vars: '{ price: "" }',
				features: [ 'CREATE', 'MANAGE', 'WORDPRESS' ]
			},
			agency: {
				vars: '{ price: "", price2: "" }',
				features: [ 'BROKER_TIER', 'MANAGERS', 'REPORTS', 'CREATE' ]
			}
		}

		self.roles = [
			{
				icon: 'public/img/website/role-auditor.png',
				key: 'AUDITORS'
			},
			{
				icon: 'public/img/website/role-notary.png',
				key: 'NOTARIES'
			},
			{
				icon: 'public/img/website/role-manager.png',
				key: 'MANAGERS'
			},
			{
				icon: 'public/img/website/role-broker.png',
				key: 'BROKERS'
			},
			{
				icon: 'public/img/website/role-agency.png',
				key: 'AGENCIES'
			}
		]

		self.selectizeConfig = {
			create: true,
			valueField: 'value',
			labelField: 'label',
			maxItems: 1,
			maxOptions: 2000 ,
			searchField: 'label',
			sortField: 'label',
			allowEmptyOption: true,
			placeholder: $filter('translate')('PUBLIC.SUPPORT.FORM.TOPIC'),
			onChange: function (value) {
				if (value.length == 0 || value === 'xxx') {       // Empty option ({ name: '-----', value: 'xxx'}) -> go back to ng-pristine
					this.clear();
				}
				this.clearCache();  // Previously selected items are deselected
			}
		}

		self.testimonials = [
			{
				name: 'Dan Tumelo',
				photo: 'public/img/website/dan-tumelo.png',
				jobTitle: 'Job Title',
				text: 'Donec at tortor neque. Curabitur blandit mauris sit amet condimentum semper. Morbi non ligula bibendum, blandit nisi non, dignissim nisl.'
			},
			{
				name: 'Joanne Lan',
				photo: 'public/img/website/joanne-lan.png',
				jobTitle: 'Job Title',
				text: 'Morbi non ligula bibendum, blandit nisi non, dignissim nisl. Donec at tortor neque. Curabitur blandit mauris sit amet condimentum semper.'
			},
			{
				name: 'Julita Sybilla',
				photo: 'public/img/website/julita-sybilla.png',
				jobTitle: 'Job Title',
				text: 'Sit amet condimentum semper donec at tortor neque. Curabitur blandit. Morbi non ligula bibendum, blandit nisi non, dignissim nisl.'
			}
		]

		self.topics = [
			{
				value: '',
				label: '----------------'
			},
			{
				value: 'Support',
				label: $filter('translate')('PUBLIC.SUPPORT.FORM.TOPICS.SUPPORT')
			},
			{
				value: 'Questions',
				label: $filter('translate')('PUBLIC.SUPPORT.FORM.TOPICS.QUESTIONS')
			},
			{
				value: 'I\'m a Broker',
				label: $filter('translate')('PUBLIC.SUPPORT.FORM.TOPICS.IM_BROKER')
			},
			{
				value: 'I\'m an Agency',
				label: $filter('translate')('PUBLIC.SUPPORT.FORM.TOPICS.IM_AGENCY')
			}
		]

		self.navigate = function(stateName, stateParams) {
			self.showPublicMenu = false;
			$state.go(stateName, stateParams);
		}

		self.sendEmail = function(form,type){
			PublicService.sendEmail(form,type).then((res) => {
				$scope.message = true;
			})
		}

	})
})(); // End of function()
