/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.admin', [])
	.service('AdminService', function (AccountService, AccountWordpress, Admin, Agency, AlertService, AvailablePlan, Code, HelpTopic, ModalService, $filter, $rootScope, $q, $sce, $window) {

		const self = this;
		const AGENCY_MEMBER = 'AGENCY_MEMBER';
		const AGENCY_OWNER = 'AGENCY_OWNER';
		const BROKER = 'BROKER';

		function _id() {
			return AccountService.getAdminId();
		}

		/** Log columns that are used in more than one page */
		self.logColumns = {
			action: function(size, ngClass) {
				return {
					name: 'Action',
					filter: 'action',
					isClick: true,
					class: ngClass || null,
					size: size || 1,
					clickFunction: function(log, i) {
						log.showExpanded = !log.showExpanded;
					},
					field: function(log) {

						// Replace UNIX_timestamp_UNIX to time formatted in current user's timezone
						if ((log.action || '').includes('UNIX_') && (log.action || '').includes('_UNIX')) {
							let time = log.action.substring(log.action.indexOf('UNIX_') + 5, log.action.indexOf('_UNIX')).trim();
							time = moment(Number(time)).format('YYYY-MM-DD hh:mm a');
							log.action = log.action.replace(/UNIX_.+_UNIX/, time);
						}

						let ret = '<div' + (log.undone ? ' class="line-through"' : '') + '>' + $filter('translate')(log.action) + '</div>';
						log.expanded = log.expanded || [];

						if (log.expanded) {
							ret+= '<div class="log-expanded" ng-show="entry.showExpanded">';

							if (log.expanded.forEach) {
								log.expanded.forEach((change) => {
									ret += '<div class="log-change">' +
                                        '<label>Property: </label> ' + change.property + '<br>';

									if ((change.newValue !== undefined && change.newValue !== null) || (change.oldValue !== undefined && change.oldValue !== null)) {
										ret += '<label>New Value: </label> ' + $filter('adminLog')(change.newValue, log.isAuto, log.aboutType === 'Code') + '<br>' +
                                            '<label>Old Value: </label> ' + $filter('adminLog')(change.oldValue, log.isAuto, log.aboutType === 'Code');
									}
									else if (change.value !== undefined || change.value !== null) {
										ret += '<label>Value: </label> ' + $filter('adminLog')(change.value, log.isAuto, log.aboutType === 'Code');
									}

									ret += '</div>';
								})
							}
							else if (log.expanded.html) {      // Email log
								ret += '<div class="log-change">';
								ret += $filter('translate')(log.actionResult.translation,log.actionResult.vars) + '<br><br>';
								ret += '<div class="email-content">' + $filter('emailTemplate')(log.expanded.html) + '</div>'
								ret += '</div>';
							}

							ret += '<div class="log-change">Admin: ' + (((log.admin || {}).user || {}).account || {}).email + '</div>';

							ret += '</div>';
						}

						return $sce.trustAsHtml(ret);
					}
				}
			},
			date: function(size, ngClass, ngStyle) {
				return {
					name: 'Date',
					filter: 'created',
					style: ngStyle || null,
					size: size || 1,
					class: ngClass || null,
					field: function(log) {
						return $filter('dateFormat4')(log.created);
					}
				}
			},
			button: {
				name: '',
				noSearch: true,
				noSort: true,
				isHtml: true,
				class: 'stripe-edit',
				field: function(sub) {
					return '<i class="fas fa-ellipsis-h pull-right"' +
                        'uib-popover-template="\'/js/src/admin/templates/popover.logs.html\'" ' +
                        'popover-placement="auto bottom-left" ' +
                        'popover-trigger="\'outsideClick\'"></i>';
				}
			}
		}

		self.assignOffices = function(officeId, people, agencyId) {

			people = people || [];

			const promises = people.reduce((arr, person) => {
				let type;

				switch(person.type) {
				case AGENCY_MEMBER:
					type = 'member'; break;
				case AGENCY_OWNER:
					type = 'owner'; break;
				case BROKER:
					type = 'broker'; break;
				}

				if (type) {
					const toAdd = {
						id: person.id,
						officeId: officeId,
						partial: true
					}

					arr.push(self.saveProfile(toAdd, type, agencyId).$promise);
				}

				return arr;
			}, []);

			return $q.all(promises);
		}

		self.countLogs = function(startDate, endDate, userIds) {
			if ((userIds || []).length > 0)
				userIds = (userIds || []).join(',').split(',');

			if (startDate) {
				startDate = startDate.toJSON();
			}

			if (endDate) {
				endDate = endDate.toJSON();
			}

			return Admin.countLogs({ id: AccountService.getAdminId(), startDate: startDate, endDate: endDate, userIds: userIds }).$promise;
		}

		self.createCharge = function(charge, agencyId) {
			return Admin.createCharge({ id: _id() }, { charge: charge, agencyId: agencyId }).$promise;
		}

		self.deleteCode = function(code) {
			const del = function() {
				AlertService.loading();
				return Code.deleteCode({ id: code.id }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_CODE');
		}

		self.deleteHelpFile = function(topicId, fileId) {
			const del = function() {
				return HelpTopic.deleteHelpFile({ id: topicId, fileId: fileId }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FILE');
		}

		self.deleteItem = function(model, modelId) {
			const del = function() {
				AlertService.loading();
				return Admin.deleteItem({ id: _id(), model: model, modelId: modelId }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FEATURE');
		}

		self.editSubscription = function(agencyId, subscriptionId, subItemId, changes) {
			return Admin.editSubscription({ id: _id(), agencyId: agencyId, subscriptionId: subscriptionId, subItemId: subItemId, changes: JSON.stringify(changes) }).$promise;
		}

		self.exportLogs = function(startDate, endDate, userIds) {

			if ((userIds || []).length > 0)
				userIds = (userIds || []).join(',').split(',');

			if (startDate) {
				startDate = startDate.toJSON();
			}

			if (endDate) {
				endDate = endDate.toJSON();
			}

			const baseUrl = $rootScope.baseURL;
			const params = {
				access_token: AccountService.getAccessToken(),
				startDate: startDate,
				endDate: endDate,
				userIds: JSON.stringify(userIds)
			};

			const apiURL = baseUrl + '/Admins/' + AccountService.getAdminId() + '/exportLogs';
			const url = [apiURL, $.param(params)].join('?');
			$window.open(url);
		}

		self.getActiveBrokers = function() {
			return Admin.getActiveBrokers({ id: _id() }).$promise;
		}

		self.getAgencies = function() {
			return Admin.getAgencies({ id: _id() }).$promise;
		}

		self.getAgencyAccounts = function(agencyId) {
			return Admin.getAgencyAccounts({ id: _id(), agencyId: agencyId }).$promise;
		}

		self.getAgencyOptions = function(value, label) {

			const def = $q.defer();

			Agency.find({ fields: [ 'id', 'legalName' ] }, (agencies) => {
				if (!agencies) {
					def.reject();
				}

				const ret = agencies.reduce((arr, ag) => {
					const toAdd = {};
					toAdd[value] = ag.id;
					toAdd[label] = ag.legalName || ag.id;
					arr.push(toAdd);

					return arr;
				}, []);

				def.resolve(ret);
			})

			return def.promise;
		}

		self.getAgencyProfile = function(agencyId, includeLogs) {

			if (!includeLogs) {
				return Admin.getAgency({ id: _id(), agencyId: agencyId }).$promise;
			}

			const tasks = [];
			const ret = {};

			tasks.push(newPromise(Admin.getAgency({ id: _id(), agencyId: agencyId }).$promise, (res) => {
				ret.agency = res;
			}));

			tasks.push(newPromise(getLogs(agencyId, 'Agency'), (res) => {
				ret.logs = res;
			}));

			return $q.all(tasks).then((res) => {
				return ret;
			});
		}

		self.getBrokers = function() {
			return Admin.getBrokers({ id: _id() }).$promise;
		}

		self.getCards = function(customerId) {
			return Admin.getCards({ id: _id(), customerId: customerId }).$promise;
		}

		self.getCodes = function() {
			return Code.find({ filter: { order: 'id ASC' } }).$promise;
		}

		self.getEmails = function() {
			return Admin.getEmails({ id: _id() }).$promise;
		}

		self.getHelpTopics = function() {
			return HelpTopic.find({ filter: { include: { folder: 'files' } } }).$promise;
		}

		self.getListOfUsers = function() {
			return Admin.getListOfUsers({ id: AccountService.getAdminId() }).$promise;
		}

		self.getListings = function(type, profileId, role) {
			return Admin.getListings({ id: _id(), type: type, profileId: profileId, role: role }).$promise;
		}

		self.getLogs = function(aboutId, aboutType) {
			return Admin.getLogs({ id: _id(), aboutId: aboutId, aboutType: aboutType }).$promise;
		}

		self.getPlans = function() {
			const tasks = [];
			const ret = {};

			tasks.push(newPromise(AvailablePlan.find({ filter: { where: { active: true, type: 'agency' } } }).$promise, (res) => {
				ret.agency = res;
			}));

			tasks.push(newPromise(AvailablePlan.find({ filter: { where: { active: true, type: 'agency-broker' } } }).$promise, (res) => {
				ret.agencyBroker = res;
			}));

			tasks.push(newPromise(AvailablePlan.find({ filter: { where: { active: true, type: 'broker' } } }).$promise, (res) => {
				ret.indBroker = res;
			}));

			return $q.all(tasks).then((res) => {
				return ret;
			})
		}

		self.getProfile = function(profileId, type, includeLogs) {
			if (!includeLogs) {
				return Admin.getProfile({ id: _id(), profileId: profileId, type: type }).$promise;
			}

			const tasks = [];
			const ret = {};
			let logAbout = 'Broker';

			switch(type) {
			case 'owner':
				logAbout = 'AgencyOwner'; break;
			case 'member':
				logAbout = 'AgencyMember'; break;
			case 'notary':
				logAbout = 'Notary'; break;
			case 'auditor':
				logAbout = 'Auditor'; break;
			}

			tasks.push(newPromise(Admin.getProfile({ id: _id(), profileId: profileId, type: type }).$promise, (res) => {
				ret[type] = res;
			}));

			tasks.push(newPromise(getLogs(profileId, logAbout), (res) => {
				ret.logs = res;
			}));

			return $q.all(tasks).then((res) => {
				const tasks2 = [];

				if ((ret[type] || {}).sbUserId) {
					tasks2.push(newPromise(getLogs(ret[type].sbUserId, 'SbUser'), (res2) => {
						ret.logs = ret.logs.concat(res2);
					}))

					if (ret[type].user.accountId) {
						tasks2.push(newPromise(getLogs(ret[type].user.accountId, 'Account'), (res2) => {
							ret.logs = ret.logs.concat(res2);
						}))

						tasks2.push(newPromise(Admin.getWordpress({ id: _id(), accountId: ret[type].user.accountId }).$promise, (res2) => {
							ret.wordpress = res2;
						}))
					}

					return $q.all(tasks2).then((final) => {
						ret.logs = ret.logs.sort((a, b) => {
							if (moment(a.created).isAfter(b.created)) return -1;
							if (moment(a.created).isSame(b.created)) return 0
							return 1;
						})

						return ret;
					})
				}
				else {
					return ret;
				}
			});
		}

		self.getSentEmails = function(profileId, type) {
			return Admin.getProfileSentEmails({ id: _id(), profileId: profileId, type: type }).$promise;
		}

		self.loginAsUser = function(sbUserId, agencyId, profileId, profileType) {
			AccountService.chooseRole('SB_ADMIN', sbUserId, profileType).then((res) => {
				AccountService.setupAdmin(res, AccountService.getAvailableRoles(), profileId, agencyId, sbUserId, AccountService.getAccount(), 'adminProfile', profileType);
			})
			.catch((err) => {})
		}

		self.menuSearch = function(term) {
			return Admin.menuSearch({ id: _id(), term: term }).$promise;
		}

		self.refundCharge = function(options) {

			options = options || {};
			if (!options.agencyId || (!options.charge && !options.invoice)) return;

			const del = function() {
				AlertService.loading();
				return Admin.refundCharge({ id: _id(), options: options }).$promise;
			}
			return ModalService.confirmDelete(del, 'Are you sure you want to refund this charge?', 'Refund Credit');

		}

		self.remoteWpLogin = function(id) {
			return AccountWordpress.remoteLogin({ id: id }).$promise;
		}

		self.resetPassword = function(email, accountId) {
			return AccountService.resetPassword(email).then((res) => {
				const log = {
					aboutType: 'Account',
					aboutId: accountId,
					action: 'Sent email to Reset Password'
				}

				return Admin.logs.create({ id: _id() }, log).$promise;
			})
		}

		self.saveAccount = function(account, sbUserId, agencyId) {
			return Admin.saveAccount({ id: _id(), account: JSON.stringify(account), sbUserId: sbUserId, agencyId: agencyId }).$promise;
		}

		self.saveCode = function(code) {
			return Admin.saveInternal({ id: _id(), entry: code, type: 'code' }).$promise;
		}

		self.saveHelpTopic = function(topic) {
			if (!topic.id) {
				topic.isNew = true;
			}
			return Admin.saveInternal({ id: _id(), entry: topic, type: 'help' }).$promise;
		}

		self.saveProfile = function(profile, type, agencyId) {
			if (profile.disabled == 0 || profile.disabled == 1) {
				profile.disabled = profile.disabled == 1;
			}

			return Admin.saveProfile({ id: _id(), profile: JSON.stringify(profile), type: type, agencyId: agencyId }).$promise;
		}

		self.saveWordpress = function(info) {
			delete info.options;

			if (info.id) {
				const id = info.id;
				delete info.id;
				return AccountWordpress.prototype$updateAttributes({ id: id }, info).$promise;
			}

			return AccountWordpress.create(info).$promise;
		}

		self.toggleSubscription = function(subscriptionId, agencyId, isCancel) {
			const del = function() {
				AlertService.loading();
				return Admin.toggleSubscription({ id: _id(), subscriptionId: subscriptionId, agencyId: agencyId, isCancel: isCancel }).$promise;
			}

			let msg = 'Are you sure you want to renew this plan?';
			let title = 'Renew Plan';

			if (isCancel) {
				msg = 'Are you sure you want to suspend this subscription? All accounts connected to this Agency will be disabled at the end the current billing period.';
				title = 'Cancel Plan';
			}

			return ModalService.confirmDelete(del, msg, title);
		}

		self.undoAction = function(logId) {
			const del = function() {
				AlertService.loading();
				return Admin.undoAction({ id: _id(), logId: logId }).$promise;
			}

			return ModalService.confirmDelete(del,
				'<img src="/public/img/exclamation.svg" width="27px" height="27px" style="float: left; margin-right: 10px" /> <p>You are about to revert this action.<br>Would you like to continue?</p>',
				'Revert Admin Action');
		}

		function getLogs(aboutId, aboutType) {
			return self.getLogs(aboutId, aboutType);
		}

		function newPromise(call, then) {
			return $q((resolve, reject) => {
				call.then((res) => {
					then(res);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				})
			});
		}

	})

	/** Filter for admin logs - oldValue/newValue */
	.filter('adminLog', ($rootScope) => {
		return function (text, isAuto, isCode) {

			if (text === null || text === undefined || text === '') return '----';

			let ret = '';

			function add(key, val) {
				ret += key + ': ';

				if (typeof val !== 'object') {
					ret += val + '<br>';
				}
				else {
					ret += '<br>';
					for (const i in val) {
						add(key + '.' + i, val[i]);
					}
				}
			}

			try {
				text = JSON.parse(text);
			}
			catch(e) {}     // Not JSON

			if (typeof text === 'object') {
				if (text.labels && !isCode) {
					return text.labels[$rootScope.language || 'EN'] || text.labels['EN'];
				}

				if (text[$rootScope.language || 'EN']) {
					return text[$rootScope.language || 'EN'];
				}

				if (isAuto && text.user) {
					return (text.user || {}).fullName;
				}

				for (const i in text) {
					add(i, text[i]);
				}
				return ret;
			}

			// Regex to check if it's date (is JSON string format). Must contain: 0000-00-00T00:00:00
			if (typeof text === 'string' && (text || '').match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/) !== null) {
				return new Date(text).toString();
			}

			return text;
		};
	})
})();
