(function () {

	'use strict';

	angular.module('smartbrokr.admin')
	.controller('AdminDashboardController', function ($filter, $log, AdminService, GlobalVars, ModalService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.brokers        = null;
		self.brokerColumns  = null;

		self.logs           = null;
		self.logColumns     = null;

		self.navParams = {
			state: 'main.admin.profile',
			getParams: function(entry) {
				if (entry) {
					return { id: entry.id, role: 'broker', menu: 'brokers' };
				}
				return {};
			}
		}

		self.logFilters = {
			action: { weight: 0, value: '', desc: -1 },
			account: { weight: 0, value: '', desc: -1,
				filterFunction: function(value, item) {
					let toMatch = 'N/A';

					if (item.related) {
						toMatch = item.aboutType + ': ' + item.related.fullName;
					}
					else {
						const about = item.about || {};
						switch(item.aboutType) {
						case 'SbUser':
							toMatch = 'Personal: ' + about.fullName; break;
						case 'Broker':
							toMatch = 'Broker: ' + about.agencyId + ' | ' + about.code; break;
						case 'Account':
							toMatch = 'Account: ' + about.email; break;
						case 'Agency':
							toMatch = 'Agency: ' + about.legalName; break;
						case 'Code':
							toMatch = 'Code: ' + item.aboutId;
						}
					}

					return toMatch.toLowerCase().match(value.toLowerCase()) !== null;
				},
				sortFunction: function(item) {
					if (item.related) {
						return item.aboutType + ': ' + item.related.fullName;
					}

					const about = item.about || {};

					switch(item.aboutType) {
					case 'SbUser':
						return 'Personal: ' + about.fullName;
					case 'Broker':
						return 'Broker: ' + about.agencyId + ' | ' + about.code;
					case 'Account':
						return 'Account: ' + about.email;
					case 'Agency':
						return 'Agency: ' + about.legalName;
					case 'Code':
						return 'Code: ' + item.aboutId;
					default:
						return 'N/A'
					}
				}
			},
			legalName: { weight: 0, value: '', desc: -1, sortFunction: function(item) {
				if (item.aboutType === 'Agency') {
					return (item.about || {}).legalName;
				}

				if (item.relatedAgency) {
					return item.relatedAgency.legalName;
				}

				return 'zzzzzzzz';
			} },
			created: { weight: 0, value: '', desc: -1, date: true, dateFilter: 'dateFormat4' },
		}

		self.sortInfo = {
			page: 'adminDashboard',
			sub: [ 'relatedAgency' ]
		}

		getBrokers();
		getLogs();

		// FUNCTIONS ============================================================================================================================

		self.exportLogs = function() {

			const model = {
				startDate: null,
				endDate: null,
				userIds: [],
				total: 0
			}

			AdminService.getListOfUsers().then((users) => {
				const start   = GlobalVars.createCalendarConfig(null,new Date());
				const end     = GlobalVars.createCalendarConfig(null,new Date());

				const fields = [
					{
						label: 'Start Date',
						model: 'startDate',
						isCalendar: true,
						calendar: start,
						onChange: function(val) {
							end.options.minDate = val;
							if (!val || moment(val).isAfter(model.endDate)) {
								model.endDate = null;
							}
							AdminService.countLogs(model.startDate, model.endDate, model.userIds).then((res) => {
								model.total = res.count;
							})
						}
					},
					{
						label: 'End Date',
						model: 'endDate',
						isCalendar: true,
						calendar: end,
						onChange: function(val) {
							start.options.maxDate = val || new Date();
							if (!val || moment(val).isBefore(model.startDate)) {
								model.startDate = null;
							}
							AdminService.countLogs(model.startDate, model.endDate, model.userIds).then((res) => {
								model.total = res.count;
							})
						}
					},
					{
						label: 'Users',
						model: 'userIds',
						isSelect: true,
						select: {
							config: GlobalVars.createMultiConfig(false, 'profileIds', 'label', true),
							options: users
						},
						onChange: function(val) {
							AdminService.countLogs(model.startDate, model.endDate, model.userIds).then((res) => {
								model.total = res.count;
							})
						}
					},
					{
						justText: true,
						labels: [ '<label style="margin-right: 8px">Total: </label>', ' {{model.total}}' ]
					}
				]

				AdminService.countLogs(model.startDate, model.endDate, model.userIds).then((res) => {
					model.total = res.count;

					const m = ModalService.openForm(fields, 'Export Logs', true, model);

					m.then((res) => {
						res = res || {};
						if (res.total > 0) {
							AdminService.exportLogs(res.startDate, res.endDate, res.userIds);
						}
						else {
							ModalService.prompt('<span class="text-20">Nothing to export</span>');
						}

					})
					.catch((err) => {})
				})
			})
		}

		function getBrokers() {
			AdminService.getActiveBrokers().then((res)=> {
				self.brokers = $filter('orderBy')(res, 'user.firstName');
			})
			.catch((err) => {
				$log.error(err);
			})
			.finally(() => {
				self.brokerColumns = [
					{
						field: function(broker) {
							return (broker.user || {}).firstName || 'No first name';
						}
					},
					{
						field: function(broker) {
							return (broker.user || {}).lastName || 'No last name';
						}
					},
					{
						field: function(broker) {
							return $filter('getOnePhone')((broker.user || {}).phones || {});
						}
					},
					{
						isHtml: true,
						field: function(broker) {
							broker.user.email = ((broker.user || {}).account || {}).email;
							broker.user.emailEnabled = true;
							return '<email person="entry.user" function="smartbrokrController.sendMessage"></email>';
						}
					},
					{
						field: function(broker) {
							return (((broker.user || {}).languages || {})[0] || {}).nameEn;
						}
					}
				]
			})
		}

		function getLogs() {
			AdminService.getLogs().then((res) => {
				self.logs = res;
			})
			.catch((err) => {
				$log.error(err);
			})
			.finally(() => {
				self.logColumns = [
					AdminService.logColumns.action(1, 'logs'),
					{
						name: 'Account',
						filter: 'account',
						class: 'logs',
						isHtml: true,
						field: function(log) {

							let ret = 'N/A';

							if (log.related) {
								ret = log.aboutType + ': ' + log.related.fullName;
							}
							else {
								switch(log.aboutType) {
								case 'SbUser':
									ret = 'Personal: ' + (log.about || {}).fullName || '----'; break;
								case 'Broker':
									ret = 'Broker: ' + (log.about || {}).agencyId + ' | ' + (log.about || {}).code; break;
								case 'Account':
									ret = 'Account: ' + (log.about || {}).email || '----'; break;
								case 'Agency':
									ret = 'Agency: ' + (log.about || {}).legalName || '----'; break;
								case 'Code':
									ret = 'Code: ' + log.aboutId || '----'; break;
								}
							}

							if (log.link) {
								ret = '<a ng-href="' + log.link + '">' + ret + '</a>';
							}

							return ret;
						}
					},
					{
						name: 'Agency',
						filter: 'legalName',
						class: 'logs',
						isHtml: true,
						field: function(log) {

							let agencyId, agencyName;

							if (log.relatedAgency) {
								agencyId = log.relatedAgencyId;
								agencyName = log.relatedAgency.legalName;
							}
							else if (log.aboutType === 'Agency') {
								agencyId = log.aboutId;
								agencyName = log.about.legalName;
							}

							if (agencyId && agencyName) {
								return '<a ui-sref="main.admin.agencies.profile({ id: \'' + agencyId + '\'})">' + agencyName + '</a>';
							}
							return 'N/A';
						}
					},
					AdminService.logColumns.date(1, 'logs')
				]
			})
		}

	})
})();
