/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.payment', [])

	.service('PaymentService', function (Account, AccountService, AvailablePlan, Subscription, $q) {
		const stripe_key = 'pk_test_Wldo6Xfrsp6Qxe1AKIGHziCz';
		const self = this;


		self.getStripeKey = function() {
			return stripe_key;
		}

		/*
                My Account/Payment
            */

		self.getCard = function () {
			return Account.getCard({ id: AccountService.getAccountId(true) }).$promise;
		}

		self.getSubscription = function () {
			return Account.getSubscription({ id: AccountService.getAccountId(true) }).$promise;
		}

		self.getUpcomingInvoice = function () {
			return Account.getUpcomingInvoice({ id: AccountService.getAccountId(true) }).$promise;
		}
		self.getSubscriptionHistory = function () {
			return Account.getSubscriptionHistory({ id: AccountService.getAccountId(true) }).$promise;
		}

		/*
                Plans
            */

		self.getAgencyPlans = function () {
			return AvailablePlan.find({ filter: { where: { active: true, type: 'agency' } } }).$promise;
		}

		self.getAgencyBrokerPlans = function () {
			return AvailablePlan.find({ filter: { where: { active: true, type: 'agency-broker' } } }).$promise;
		}

		self.getBrokerPlans = function () {
			return AvailablePlan.find({ filter: { where: { active: true, type: 'broker' } } }).$promise;
		}

		/* Actions */
		self.cancelPlan = function(id) {
			return Subscription.cancelPlan({ id: id }).$promise;
		}

		self.renewPlan = function(id) {
			return Subscription.renewPlan({ id: id }).$promise;
		}

		self.updateCard = function(token, cardInfo) {
			cardInfo = cardInfo || {};
			const isAdmin = AccountService.isAdmin();
			let accountId = AccountService.getAccountId(true);

			if (isAdmin && !AccountService.getManagingUserId()) {
				accountId = AccountService.getAdminId();
			}

			return Account.updateCard({ id: accountId, token: token, customerId: cardInfo.customer, isAdmin: isAdmin && !AccountService.getManagingUserId() }).$promise;
		}

		self.updatePlan = function(id, agencyPlan, brokerPlan) {
			return Subscription.updatePlan({ id: id, brokerPlan: brokerPlan, agencyPlan: agencyPlan }).$promise;
		}
	});
})();
