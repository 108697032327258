(function () {

	'use strict';

  	angular.module('smartbrokr')

	.directive('wordpressSetup', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/wordpress/wordpress.setup.html'
		};
	})

  	.directive('wordpressTab', ($parse, $templateRequest, $compile) => {
		return {
			restrict: 'E',
			link: function(scope, element, attrs) {	// Logic for template copied from uibTypeaheadMatch directive
				const tplUrl = $parse(attrs.sbTemplate)(scope.$parent) || '/js/src/wordpress/tabs/general.html';
				$templateRequest(tplUrl).then((tplContent) => {
					const tplEl = angular.element(tplContent.trim());
					element.replaceWith(tplEl);
					$compile(tplEl)(scope);
				});

				if (attrs.sbScope) {
					const _scope = scope.$eval(attrs.sbScope);

					for (var i in _scope) {
						scope[i] = _scope[i];
					}

					if (!!scope.pages) {
						for (var i in scope.pages) {
							scope.pages[i].content = {};
							scope.pages[i].content.EN = scope.pages[i].getContent('EN');
							scope.pages[i].content.FR = scope.pages[i].getContent('FR');
						}
					}
				}
			}
		};
  	})

	.directive('wordpressTabs', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/wordpress/wordpress.tabs.html'
		};
	})

	.directive('wordpressTop', () => {
		return {
			restrict: 'E',
			scope: {
				hasWebsite: '=',
				tabDescription: '='
			},
			templateUrl: '/js/src/wordpress/wordpress.top.html'
		};
	})

	.directive('wpBasicEditor', () => {
		return {
			restrict: 'E',
			require: 'ngModel',
			scope: {
				ngModel: '='
			},
			template: '<div ui-tinymce="editorConfig" ng-model="ngModel"></div>',
			controller: function($scope, GlobalVars) {
				const plugins = 'advlist autolink code colorpicker contextmenu link lists paste searchreplace textcolor textpattern visualblocks visualchars nonbreaking';
				const toolbar = 'bold italic strikethrough forecolor fontsizeselect | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent link | cut copy paste | code';
				$scope.editorConfig = GlobalVars.createEditorConfig(true, false, plugins, toolbar);
		  	}
		};
	})

	.directive('wpCardBase', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/wordpress/content-modal/directives/card-base.html',
			scope: {
				content: '=',
				wid: '=',
				initialIndex: '=',
			},
			controller: function($filter, $log, $sce, $scope, AlertService, GlobalVars, ModalService, UploadService, UserService, WordpressService, AccountService) {
				let currentLinkIndex = null;
				$scope.uploader = UploadService.initUserUploader(false, AccountService.getUserId(), null, true);
				$scope.content.text = decodeURIComponent($scope.content.text);
				$scope.content.text_title = decodeURIComponent($scope.content.text_title);

				$scope.deleteTooltip = $filter('translate')('WORDPRESS.CONTENT.CARD_MARKETING.DELETE_LINK');
				$scope.removeFileTooltip = $filter('translate')('WORDPRESS.CONTENT.CARD_MARKETING.REMOVE_PDF');
				$scope.uploadFileTooltip = $filter('translate')('WORDPRESS.CONTENT.CARD_MARKETING.UPLOAD_PDF');

				for(let i = 0; i < $scope.content.links.length; i++) {
					$scope.content.links[i].title = decodeURIComponent($scope.content.links[i].title);
					$scope.content.links[i].url = decodeURIComponent($scope.content.links[i].url);
				}

				$scope.formatUrl = function(url) {
					url = url || '';
					url = url.replace(/\//g, '/<wbr>');
					return $sce.trustAsHtml(url);
				}

				$scope.icons = [
					{
						className: 'far fa-image',
						action: function() {
							// const minWidth = 500;
							// const minHeight = 596;
							const minWidth = 1900;
							const minHeight = 600;

							const dimensions = {
								init_w: minWidth,
								init_h: minHeight,
								result_w: minWidth,
								result_h: minHeight,
							};

							const _cb = function(res) {
								res = res || {};
								$scope.content.image_url = res.url;
							}

							WordpressService.uploadPhoto(dimensions, 'header_gallery', $scope.wid, _cb, 'marketing');
						}
					}
				];

				$scope.addLink = function(links) {
					links.unshift([ '', '' ]);
				}

				$scope.deleteLink = function(links, index) {
					const link = links[index] || {};

					// Only ask for confirmation if link has content
					if (link.url || link.title) {
						ModalService.confirmDelete(_del, 'ALERT_MESSAGES.ALERT.DELETE_ITEM');
					}
					else {
						_del();
					}

					function _del() {
						links.splice(index, 1);
					}
				}

				$scope.removeFile = function(links, index) {
					links[index].url = '';
					links[index].uploaded = false;
				}

				$scope.setUrl = function(index) {
					currentLinkIndex = index;
				}

				$scope.uploader.onAfterAddingFile = function(item) {
					$scope.uploader.uploadItem(item);
				}

				$scope.uploader.onSuccessItem = function(fileItem, response, status, headers) {
					if(currentLinkIndex != null) {
						$scope.content.links[currentLinkIndex].title = fileItem.file.name;
						$scope.content.links[currentLinkIndex].url = response.url;
						$scope.content.links[currentLinkIndex].type = 'normal';
						$scope.content.links[currentLinkIndex].uploaded = true;
						currentLinkIndex = null;
					}
				}


				$scope.doReorder = function(srcIndex, targetIndex, list) {
					list.splice(targetIndex, 0, list[srcIndex]);
					// Remove the item from the source, possibly correcting the index first.
					// We must do this immediately, otherwise ng-repeat complains about duplicates.
					if (targetIndex <= srcIndex) srcIndex++;
					list.splice(srcIndex, 1);
					return true;
				}

				$scope.doNewLink = function(ev) {
					if(ev.preventDefault) {
						ev.preventDefault();
					}
					if(!$scope.content.links) $scope.content.links = [];
					$scope.content.links.push({ title: '', url: '' });
				}
			}
		}
	})

	.directive('wpCardSupplier', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/wordpress/content-modal/directives/card-supplier.html',
			scope: {
				content: '=',
				wid: '=',
				initialIndex: '=',
				offices: '=',
				config: '=',
				updateSupplierBlock: '='
			},
			controller: function($filter, $log, $sce, $scope, UploadService, WordpressService, AccountService) {

				$scope.content.featured = Number($scope.content.featured);

				$scope.uploader = UploadService.initUserUploader(false, AccountService.getUserId(), null, true);

				$scope.deleteTooltip = $filter('translate')('WORDPRESS.CONTENT.CARD_MARKETING.DELETE_LINK');
				$scope.removeFileTooltip = $filter('translate')('WORDPRESS.CONTENT.CARD_MARKETING.REMOVE_PDF');
				$scope.uploadFileTooltip = $filter('translate')('WORDPRESS.CONTENT.CARD_MARKETING.UPLOAD_PDF');

				$scope.formatUrl = function(url) {
					url = url || '';
					url = url.replace(/\//g, '/<wbr>');
					return $sce.trustAsHtml(url);
				}

				$scope.icons = [
					{
						className: 'far fa-image',
						action: function() {
							// const minWidth = 500;
							// const minHeight = 596;
							const minWidth = 200;
							const minHeight = 200;

							const dimensions = {
								init_w: minWidth,
								init_h: minHeight,
								result_w: minWidth,
								result_h: minHeight,
							};

							const _cb = function(res) {
								res = res || {};
								$scope.content.imageUrl = res.url;
								$scope.changedValue('imageUrl');
							}

							WordpressService.uploadPhoto(dimensions, 'header_gallery', $scope.wid, _cb, 'marketing');
						}
					}
				];

				$scope.changedValue = function(property) {
					$scope.updateSupplierBlock(property, $scope.content[property], $scope.content.id);
				}


				$scope.removeFile = function(links, index) {
					links[index].url = '';
					links[index].uploaded = false;
				}

				// $scope.setUrl = function(index) {
				// 	currentLinkIndex = index;
				// }

				$scope.uploader.onAfterAddingFile = function(item) {
					$scope.uploader.uploadItem(item);
				}


				$scope.doReorder = function(srcIndex, targetIndex, list) {
					list.splice(targetIndex, 0, list[srcIndex]);
					// Remove the item from the source, possibly correcting the index first.
					// We must do this immediately, otherwise ng-repeat complains about duplicates.
					if (targetIndex <= srcIndex) srcIndex++;
					list.splice(srcIndex, 1);
					return true;
				}
			}
		}
	})

	.directive('wpCardLinks', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/wordpress/content-modal/directives/card-links.html',
			scope: {
				content: '='
			},
			controller: function($scope, ModalService) {

				$scope.addLink = function(links) {
					links.unshift([ '', '' ]);
				}

				$scope.deleteLink = function(links, index) {
					const link = links[index] || {};

					// Only ask for confirmation if link has content
					if (link.url || link.title) {
						ModalService.confirmDelete(_del, 'ALERT_MESSAGES.ALERT.DELETE_ITEM');
					}
					else {
						_del();
					}

					function _del() {
						links.splice(index, 1);
					}
				}

				$scope.doReorder = function(srcIndex, targetIndex, list) {
					list.splice(targetIndex, 0, list[srcIndex]);

					// Remove the item from the source, possibly correcting the index first.
					// We must do this immediately, otherwise ng-repeat complains about duplicates.
					if (targetIndex <= srcIndex) srcIndex++;

					list.splice(srcIndex, 1);
					return true;
				}

			}
		};
	})

	.directive('wpCardPerson', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/wordpress/content-modal/directives/card-person.html',
			scope: {
				content: '=',
				wid: '='
			},
			controller: function($scope, GlobalVars, WordpressService) {
				const plugins = 'autolink code colorpicker contextmenu link paste searchreplace textcolor textpattern visualblocks visualchars nonbreaking';
				const toolbar = 'bold italic strikethrough fontsizeselect | forecolor link | cut copy paste | code';
				const config = GlobalVars.createEditorConfig(true, false, plugins, toolbar);

				let bio = JSON.parse(JSON.stringify($scope.content.bio));
				bio = decodeURIComponent(bio);

				config.entity_encoding = 'named+numeric';
				$scope.editorConfig = config;
				$scope.content.bio = bio.split('\\u0022').join('"');
				$scope.icons = [
					{
						className: 'far fa-image',
						action: function() {
							const dimensions = {
								init_w: 500,
								init_h: 596,
								result_w: 500,
								result_h: 596,
							};

							const _cb = function(res) {
								res = res || {};
								$scope.content.image_url = res.url;
							}

							WordpressService.uploadPhoto(dimensions, 'header_gallery', $scope.wid, _cb);
						}
					}
				];
		  	}
		};
	})

	.directive('wpColumns', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/wordpress/content-modal/directives/wp-columns.html',
			scope: {
				content: '='
			}
		};
	})

	.directive('wpColumnPersonal', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/wordpress/content-modal/directives/wp-column-personal.html',
			scope: {
				content: '=',
				wid: '='
			},
			controller: function($filter, $scope, GlobalVars, WordpressService) {
				$scope.icons = [
					{
						className: 'far fa-image',
						action: function() {
							const dimensions = {
								init_w: 400,
								init_h: 496,
								result_w: 400,
								result_h: 496,
							};

							const _cb = function(res) {
								res = res || {};
								$scope.content.image_url = res.url;
							}

							WordpressService.uploadPhoto(dimensions, 'header_gallery', $scope.wid, _cb);
						}
					}
				];
			}
		}
	})

	.directive('wpFormBase', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/wordpress/content-modal/directives/wp-form-base.html',
			scope: {
				content: '=',
				wid: '='
			},
			controller: function($scope) {}
		}
	})

	.directive('wpFeaturedListing', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/wordpress/content-modal/directives/featured-listing.html',
			scope: {
				block: '='
			},
			controller: function($scope, UserService) {
				const searchFilter = {	// Filter for self.searchListing function
					include: {
						relation: 'property',
						scope: {
							fields: [ 'id', 'listingId', '_address' ]
						}
					},
					fields: [ 'id' ],
					limit: 5,
					onlyMine: true
				}

				/**
				 * 	Searches for a listing based on its ID and address.
				 * 	@param 		{string}		$viewValue		String entered by the user in the Listing input
				 * 	@return 	{object[]}						Array of listings found using the search parameter
				 */
				$scope.searchListing = function($viewValue) {
					return UserService.getBasicSearch($viewValue, 'Listing', searchFilter);
				}
		  	}
		};
	})

	.directive('wpHeading', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/wordpress/content-modal/directives/wp-heading.html',
			scope: {
				content: '=',
				wid: '='
			},
			controller: function($filter, $scope, GlobalVars, WordpressService) {
				const plugins = 'autolink code colorpicker contextmenu link paste searchreplace textcolor textpattern visualblocks visualchars';
				const toolbar = 'bold italic strikethrough forecolor | cut copy paste';
				$scope.editorConfig = GlobalVars.createEditorConfig(true, false, plugins, toolbar, {
					setup: function(ed) {
						ed.on('keydown', (event) => {
							if (event.keyCode == 13) {
								event.preventDefault();
								event.stopPropagation();
								return false;
							}
						});
					}
				});
				$scope.icons = [
					{
						className: 'far fa-image',
						popover: $filter('translate')('FILES.UPLOAD_IMAGE'),
						action: function() {
							const dimensions = {
								init_w: 180,
								init_h: 64,
								result_w: 180,
								result_h: 64,
							};

							const _cb = function(res) {
								res = res || {};
								$scope.content.imageUrl = res.url;
								$scope.content.attachmentId = res.attachment_id;
							}

							WordpressService.uploadPhoto(dimensions, 'logo', $scope.wid, _cb);
						}
					}
				];
		  	}
		};
	})

	.directive('wpImage', () => {
		return {
			restrict: 'E',
			template: '<div class="sb-overlay-container"><img ng-src="{{ content.imageUrl }}" /><sb-overlay icons="icons"></sb-overlay></div>',
			scope: {
				content: '=',
				wid: '='
			},
			controller: function($filter, $scope, WordpressService) {
				$scope.icons = [
					{
						className: 'far fa-image',
						popover: $filter('translate')('FILES.UPLOAD_IMAGE'),
						action: function() {
							const dimensions = {
								init_w: 1368,
								init_h: 372,
								result_w: 1368,
								result_h: 372,
							};

							const _cb = function(res) {
								res = res || {};
								$scope.content.imageUrl = res.url;
								$scope.content.attachmentId = res.attachment_id;
							}

							WordpressService.uploadPhoto(dimensions, 'header_gallery', $scope.wid, _cb);
						}
					}
				];
		  	}
		};
	})

	.directive('wpMapSingle', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/wordpress/content-modal/directives/map-single.html',
			scope: {
				content: '='
			}
		};
	})

	.directive('wpMediaText', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/wordpress/content-modal/directives/wp-media-text.html',
			scope: {
				content: '=',
				wid: '='
			},
			controller: function($filter, $log, $sce, $scope, AlertService, ModalService, UploadService, UserService, WordpressService) {
				const mediaUploader = UploadService.initWpUploader();		// Video uploader
				$scope.mediaType = (($scope.content || {}).blockInfo || {}).mediaType || 'image';

				$scope.formatUrl = function(url) {
					url = url || '';
					url = url.replace(/\//g, '/<wbr>');
					return $sce.trustAsHtml(url);
				}

				$scope.icons = [
					{
						className: 'far fa-image',
						popover: $filter('translate')('FILES.UPLOAD_IMAGE'),
						action: function() {
							const dimensions = {
								init_w: 300,
								init_h: 200
							};

							const _cb = function(res) {
								res = res || {};
								$scope.content.blockInfo.mediaType = 'image';
								$scope.content.blockInfo.mediaId = res.attachment_id;
								$scope.content.imgSrc = res.url;
								$scope.mediaType = 'image';
							}

							WordpressService.uploadPhoto(dimensions, 'header_gallery', $scope.wid, _cb);
						}
					},
					{
						className: 'fas fa-film',
						popover: $filter('translate')('WORDPRESS.CONTENT.UPLOAD_VIDEO'),
						action: function() {
							const input = document.createElement('input');
							input.setAttribute('type', 'file');
							input.setAttribute('accept', '.mp4');
							input.onchange = function() {
								const file = this.files[0];
								AlertService.loading();
								mediaUploader.onSuccessItem = function (fileItem, response, status, headers) {
									if (response.url) {
										WordpressService.sendUpdate($scope.wid, 'upload', response).then((res) => {
											res = res.result || {};

											UserService.deleteFile(response).then((deleted) => {
												$scope.content.blockInfo.mediaId = res.attachment_id;
												$scope.content.blockInfo.mediaType = 'video';
												$scope.content.imgSrc = res.url;
												$scope.mediaType = 'video';
											})
											.catch($log.error)
											.finally(AlertService.doneLoading);
										})
									}
									else {
										AlertService.doneLoading();
									}
								};

								mediaUploader.addToQueue(file);
								mediaUploader.uploadAll();
							};

							input.click();

						}
					}
				];
		  	}
		};
	})

	.directive('wpTestimonials', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/wordpress/content-modal/directives/testimonials.html',
			scope: {
				block: '='
			},
			controller: function($scope, ModalService) {
				/**
				 * 	Sets a testimonial as the one being current edited in a block.
				 * 	Does nothing if either the block or the testimonial are undefined/null/false
				 * 	@param 	{object}	testimonial		Testimonial to be edited
				 * 	@param 	{number}	index			Index number of the testimonial (in the array of testimonials of that block)
				 * 	@param 	{object}	block			Block that owns the testimonial.
				 */
				$scope.editTestimonial = function(testimonial, index, block) {
					if (!block || !testimonial)
						return;

					testimonial = testimonial || {};

					block.editTestimonial = {
						text: testimonial.text,
						author: testimonial.author,
						position: testimonial.position,
						index: index
					}
				}

				/**
				 * 	Removes a testimonial from a block content.
				 * 	Does nothing if the block has no testimonials array.
				 * 	@param 	{number}	index 		Index number of the testmonial to be removed
				 * 	@param 	{object}	block		Block of content that contains testimonials
				 */
				$scope.deleteTestimonial = function(index, block) {

					if (!(block || {}).testimonials)
						return;

					ModalService.confirmDelete(_del, 'ALERT_MESSAGES.ALERT.DELETE_ITEM');

					function _del() {
						block.testimonials.splice(index, 1);
					}
				}

				/**
				 * 	Saves a testimonial in a block of contents.
				 * 	Does nothing if the block has no array of testimonials
				 * 	@param 	{object}	block					Block that will have the new testimonial added to it.
				 * 	@param 	{object[]}	block.testimonials		Array of testimonials. The new testimonial will be pushed to this array.
				 * 	@param 	{object}	block.editTestimonial	Testimonial that will be added to the block's testimonials. Set to an empty object after adding the new testimonial to the block.
			 	 * 	@param	{string=}	block.editTestimonial.text		Text for the testimonial
				 * 	@param	{string=}	block.editTestimonial.author	Name of the person who gave the testimonial
				 * 	@param	{string=}	block.editTestimonial.position 	Position of the testimonial's author (like Broker or Home Owner, etc)
				 * 	@param 	{number=}	block.editTestimonial.index		If set, the new testimonial will replace the one in that index (in the array of testimonials)
				 */
				$scope.saveTestimonial = function(block) {

					if (!(block || {}).testimonials)
						return;

					const testimonial = block.editTestimonial || {};
					const i = testimonial.index;

					if (typeof i == 'number' && i >= 0 && !!block.testimonials[i]) {	// Replace
						block.testimonials[i].text = testimonial.text || '';
						block.testimonials[i].author = testimonial.author || '';
						block.testimonials[i].position = testimonial.position || '';
					}
					else {	// Add new
						block.testimonials.push({
							text: testimonial.text || '',
							author: testimonial.author || '',
							position: testimonial.position || ''
						});
					}

					block.editTestimonial = {};
				}
		  	}
		};
	})
})();
