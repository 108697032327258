/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('ShareListingModalController', function (selectedListing, listingId, brokers, sharedBrokers, showBrokers, selectedRecipient, $filter, $log, $scope, $timeout, $translate, $uibModalInstance, AccountService, AgencyService, AlertService, BrokerService, GlobalVars, ListingService, MessageService, ModalService, UploadService, UserService) {
		const self = this;

		// VARIABLES ============================================================================================================================
		self.brokers = [];     // All options for recipients

		self.receivers =  selectedRecipient ? [selectedRecipient] : [];     // Selected receivers for message
		self.externalReceivers = [];

		self.config = null;   // Config for selectize dropdown

		self.offices = [];
		self.selectedOffice = null;
		self.selectedListing = listingId;
		self.selectedListings = [];

		self.sharingListing = selectedListing;
		self.sharedBrokers = [];
		if(sharedBrokers) {
			self.sharedBrokers = sharedBrokers.reduce((arr, item) => {
				return item.id;
			}, []);
		}

		self.showError = false;
		self.error = '';

		self.doExternalRecipients = false;

		self.showBrokers = showBrokers;

		let userId = null;   // Current user's id - used to get senderId

		// SETUP VARIABLES ======================================================================================================================

		userId = AccountService.getUserId();
		self.listingConfig = GlobalVars.createMultiConfig(false, 'id', 'searchText', true, 'hidden', 'search');

		self.listingConfig.render = {
			option: function(data, escape) {
				return '<div class="option" data-selectable="" data-value="' + data['id'] + '">' + data.searchText + '</div>';
			},
			item: function(data, escape) {
				return '<div class="item" data-value="' + data['id'] + '">' + data.searchText + '</div>';
			}
		}

		$uibModalInstance.scope = $scope;
		$uibModalInstance.check = {
			original: {
				receivers: angular.copy(self.receivers),
				listingId: angular.copy(self.selectedListing)
			},
			clone: {
				receivers: self.receivers,
				listingId: self.selectedListing
			}
		}

		getListings();
		getUsers();
		getOffices();

		// FUNCTIONS ============================================================================================================================

		/**
     *  Closes the modal window
     */
		self.close = function () {
			$uibModalInstance.dismiss('cancel');
		}

		/**
		 *  Creates a new conversation with each recipient selected
		 *  and adds all files to that conversation (if required)
		 */
		self.send = function () {
			if(self.receivers.length > 0 || self.externalReceivers.length > 0) {
				self.showError = false;
				AlertService.loading();
				if(!showBrokers) {
					ListingService.shareListings(self.selectedListings, selectedRecipient).then((res) => {
						if(res && res.length > 0) {
							AlertService.doneLoading();
							const m = ModalService.prompt($filter('translate')('MESSAGES.ERRORS.NO_USER.TEXT', { names: res.join(', ') }), 'MESSAGES.ERRORS.NO_USER.TITLE', 'Ok', null, null, true);
							m.result.then((res) => {})
							.catch((err) => {})
							.finally(() => {
								if(self.externalReceivers.length !== res.length) {
									done();
								}
							})
						} else {
							done();
						}
					})
					.catch(doError);
				} else {
					ListingService.shareListing(listingId, self.receivers, self.externalReceivers).then((res) => {
						if(res && res.length > 0) {
							AlertService.doneLoading();
							const m = ModalService.prompt($filter('translate')('MESSAGES.ERRORS.NO_USER.TEXT', { names: res.join(', ') }), 'MESSAGES.ERRORS.NO_USER.TITLE', 'Ok', null, null, true);
							m.result.then((res) => {})
							.catch((err) => {})
							.finally(() => {
								if(self.externalReceivers.length !== res.length) {
									done();
								}
							})
						} else {
							done();
						}
					})
					.catch(doError);
				}
			} else {
				self.showError = true;
				self.error = $filter('translate')('MESSAGES.ERRORS.MISSING_RECIPIENTS');
			}
		}

		self.addRecipient = function(ev) {
			if(ev) {
				ev.preventDefault();
				ev.stopPropagation();
			}
			self.externalReceivers.push('');
		}


		self.toggleExternalRecipients = function(ev) {
			if(ev) {
				ev.preventDefault();
				ev.stopPropagation();
			}
			self.doExternalRecipients = !self.doExternalRecipients;
			if(self.doExternalRecipients) {
				self.externalReceivers = [''];
			} else {
				self.externalReceivers = [];
			}

		}

		self.deleteRecipient = function(ev, index) {
			if(ev) {
				ev.preventDefault();
				ev.stopPropagation();
			}
			self.externalReceivers.splice(index, 1);
		}

		/**
		 *  Callback function for uploader.onWhenAddingFileFailed
		 *  Shows a message to the user explaining why the file was not added to the message.
		 *  It assumes that it will only fail due to the file format not being allowed.
		 *  @param {object}  item  Item that could not be added to the uploader
		 */
		function doError(error) {
			AlertService.doneLoading();
			self.error = error.data.error.message;
			self.showError = true;
			$timeout(() => {
				self.showError = false;
			}, 5000);
		}

		/**
		 *  Closes the modal.
		 */
		function done() {
			AlertService.doneLoading();
			if(self.selectedListings && self.selectedListings.length > 1) {
				AlertService.success('MESSAGES.MESSAGE', 'sharedListings');
			} else {
				AlertService.success('MESSAGES.MESSAGE', 'sharedListing');
			}

			const listings = getFullListings(self.selectedListings);

			self.shareListingForm.$setPristine();
			$uibModalInstance.close({ message: self.message, listings: listings });
		}

		/**
		 *  Gets users from the DB to be used a message receivers.
		 */
		function getUsers() {
			$scope.loading = true;
			BrokerService.getActiveBrokers().then((res) => {
				self.brokers = res.reduce((arr,item) => {
					if (item.user.id !== userId) {
						item.hidden = $filter('translate')(item.user.dataType);
						item.search = item.user.fullName + '|' + item.user.hidden;
						arr.push(item);
					}
					return arr;
				},[]);
			})
			.catch($log.error)
			.finally(() => {
				$scope.loading = false;
			});
		}

		function getOffices() {
			$scope.loading = true;
			AgencyService.getAgency(true).then((res) => {
				self.offices = res.offices.filter((office) => {
					if((office.brokers && office.brokers.length > 0) || (office.members && office.members.length > 0) || (office.owners && office.owners.length > 0)) return office;
				});
			});
		}

		function getListings() {
			ListingService.getSmart().then((res) => {
				for(let i = 0; i < res.length; i++) {
					res[i].searchText = res[i]['property']['_address']['addressString'] + ' (' + res[i]['id'] + ')';
				}
				self.listings = res;
			})
			.catch($log.error)
			.finally(() => {
				$scope.loading = false;
			})
		}

		function getFullListings(listingIds) {
			const fullListings = [];
			for(let i = 0; i < listingIds.length; i++) {
				const fullListing = self.listings.find((listing) => {
					if(listing.id === listingIds[i]) return listing;
				});
				if(fullListing) {
					fullListings.push(fullListing);
				}
			}
			return fullListings;
		}
	}) // End of controller
})(); // End of function()
