/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('TaskDetailsController', function ($filter, $log, $rootScope, $scope, $state, $stateParams, $window, check, AlertService, GlobalVars, ModalService, NavService, TaskService, UploadService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.uploader         = null;   // Uploader object for Task
		self.commentUploader  = null;   // Uploader object for Comment

		self.class            = null;   // Determines header background colour
		self.hash                = null;   // Listing's index in list of listings (left menu) -> Used to go back to right listing

		self.selectedPerson   = null;   // Broker assigned to this task (ng-model for dropdown list)
		self.taskLegends      = null;   // Template for task legend popover

		self.newUpdate        = null;   // New comment
		self.commentFiles     = [];     // Files to attach to new comment
		self.user             = null;   // Current user (owner of new comment, if posted)

		self.singleConfig     = null;   // Selectize config
		self.multiConfig      = null;   // Selectize config

		self.task             = null;   // Task that is being viewed
		self.agencyPeople     = [];     // Array with people that can have task assigned to them
		self.notify           = [];     // Array with people to notify when writing a new comment

		self.showTaskFiles    = false;  // Whether to display task files - used in the mobile version
		self.showMoreFiles    = false;  // Whether to expand number of files shown in files row (mobile)

		const _ = $window._;

		// SETUP VARIABLES ======================================================================================================================
		const taskId        = $stateParams.taskId;
		self.hash         = $stateParams.hash || null;

		self.taskLegends  = GlobalVars.popover('taskLegends'); // Task legends popup
		self.singleConfig = GlobalVars.createSingleConfig(false, 'value', 'name', 'search', false, 'tag');
		self.multiConfig  = GlobalVars.createMultiConfig(false, 'value', 'name', true, 'tag', 'search');

		self.newUpdate = {
			content: '',
			notifiedId: []
		};

		let loadingOptions  = true;
		let loadingTask     = true;

		loadOptions();
		loadTask();

		// FUNCTIONS ============================================================================================================================

		// Aux function to setup variables that depend on the current task
		// (called after loading task)
		function setup() {

			if (!loadingOptions && !loadingTask) {
				$scope.loading = false;

				// Uploader to add files to task
				self.uploader = UploadService.initTaskUploader(taskId);

				self.uploader.onSuccessItem = function (fileItem, response, status, headers) {
					loadTask();
					AlertService.success('FILES.FILE', 'upload');
				};

				// Uploader to add files to comment
				self.commentUploader = UploadService.initCommentUploader();
				self.commentUploader.onAfterAddingFile = function (item) {
					self.commentFiles.push(item);
					AlertService.success('FILES.FILE', 'add');
				};
				self.commentUploader.onBeforeUploadItem = function (item) {
					self.commentUploader.url = getUrl();
				}
				self.commentUploader.onCompleteAll = function () {
					finishComment();
				}

				self.commentUploader.onErrorItem = function (fileItem, response, status, headers) {
					AlertService.errorMessage('basic');
					$log.error(response);
				}

				self.class = TaskService.getClass(self.task);

				if (self.task.assignedToId) {
					self.selectedPerson = self.task.assignedToId + '|' + self.task.assignedToType;
				}

				check.original = angular.copy(self.newUpdate);
				check.clone = self.newUpdate;
			}
		}

		function loadOptions() {
			AlertService.loading();

			/** Used to show picture in the "Add Comment" section */
			TaskService.getCurrentUser()
			.then((res) => {
				self.user = res;
			})
			.catch($log.error)

			/** AssignedTo dropdown list */
			TaskService.getAgencyPeople().then((res) => {

				self.agencyPeople.push({ name: '------', value: 'xxx', search: '' });

				let i, length = res.length;

				for (i = 0; i < length; i++) {
					if (res[i].user) {
						const tag = $filter('translate')(res[i].tag);
						self.agencyPeople.push({
							name: res[i].user.fullName,
							value: res[i].id + '|' + res[i].dataType,
							tag: tag,
							search: res[i].user.fullName + '|' + tag });

						self.notify.push({
							name: res[i].user.fullName,
							value: res[i].user.id,
							tag: tag,
							search: res[i].user.fullName + '|' + tag });

					} else if(res[i].owner) {
						const tag = $filter('translate')(res[i].tag);
						self.notify.push({
							name: res[i].owner.fullName,
							value: res[i].owner.id,
							tag: tag,
							search: res[i].owner.fullName + '|' + tag });
					}
				}
			})
			.catch($log.error)
			.finally(() => {
				loadingOptions = false;
				setup();
				AlertService.doneLoading();
			})
		}

		/** Happens when page is refreshed */
		function loadTask() {
			loadingTask = true;
			if (taskId) {
				AlertService.loading();

				TaskService.getTask(taskId).then((res) => {
					self.task = res;

					self.task.suppliers = _getTaskSuppliers([ res ]);

					if (self.task.listing) {
						self.task.listing.taskSuppliers = _getTaskSuppliers(self.task.listing.tasks, true);
					}

					let desc = self.task.description || '';
					desc = $filter('linky')(desc, '_blank');
					desc = $filter('addNewLines')(desc);
					desc = $filter('addMLS')(desc);
					$scope.taskDescription = desc;

					loadingTask = false;
					setup();
				})
				.catch($log.error)
				.finally(AlertService.doneLoading)
			}
			/** There is no task to load -> go back to Tasks page */
			else {
				$state.go('main.tasks');
			}
		}

		/** If task is not complete -> Adds new date for Task.completed
        If task is complete -> Deletes Task.completed date */
		self.completeTask = function () {
			AlertService.loading();

			TaskService.completeTask(self.task).then((res) => {
				self.task.completed = res.completed;
				self.class = TaskService.getClass(self.task);
				AlertService.saved();
			})
			.catch((err) => { })
			.finally(() => {
				AlertService.doneLoading();
			})
		}

		self.changeAssignedTo = function() {
			const task = self.task || {};
			const compare = !!task.assignedToId ? task.assignedToId + '|' + task.assignedToType : null;

			if ((self.selectedPerson || null) != compare) {
				self.saveTask();
			}
		}

		self.deleteTask = function() {
			self.isDeleting = true;
			TaskService.deleteOne(self.task.id).then((res) => {
				if (res) {
					AlertService.doneLoading();
					$rootScope.$emit('updateTasks');

					if (self.hash || ((NavService.navStack || []).length < 2)) {
						$state.go('main.tasks', { hash: self.hash });
					}
					else {
						NavService.navigateBack();
					}
				}
			})
			.finally(() => {
				self.isDeleting = false;
			});
		}

		/** Saves current task */
		self.saveTask = function () {

			AlertService.loading();
			const task = self.task;
			const selected = (self.selectedPerson || '').split('|');
			let assignedToId = null;
			let assignedToType = null;

			if (selected.length > 1) {
				assignedToId = selected[0];
				assignedToType = selected[1];
			}

			if (assignedToId != task.assignedToId) {
				task.updateText = {
					id: assignedToId,
					type: assignedToType,
					text: 'assigned to '
				}
			}

			task.assignedToId = assignedToId;
			task.assignedToType = assignedToType;
			delete task.assignedTo;

			TaskService.saveTask(task).then((res) => {
				loadTask();
				AlertService.saved();
			})
			.catch($log.error)
			.finally(AlertService.doneLoading)
		}

		/** Saves a new comment in the current task */
		self.addUpdate = function () {
			AlertService.loading();
			let apiCall = null;
			if (self.newUpdate.id) {
				apiCall = TaskService.updateComment($stateParams.taskId, self.newUpdate.id, self.newUpdate)
			}
			else {
				apiCall = TaskService.createComment($stateParams.taskId, self.newUpdate);
			}
			apiCall.then((res) => {
				if (res.id) {
					self.newUpdate.id = res.id;
					self.commentUploader.url = getUrl();
					self.commentUploader.queue = self.commentFiles;
					if (self.commentUploader.queue.length > 0) {
						for (let i = 0; i < self.commentUploader.queue.length; i++) {
							self.commentUploader.queue[i].uploader = self.commentUploader;
							self.commentUploader.queue[i].url = getUrl();
							self.commentUploader.queue[i].upload();
						}
						//self.commentUploader.uploadAll();
					}
					else {
						finishComment();
					}
				}
			})
			.catch($log.error)
			.finally(AlertService.doneLoading)
		}

		/** Opens modal to edit current task */
		self.editTask = function () {
			const task = self.task;

			// Updates self.task object according to the response
			const retFunction = function (res) {
				if (res.task) {
					loadTask();
				}
			}

			ModalService.openModalWithFunction('newTask', {
				itemId: function () {
					return task.aboutId ? task.aboutId : task.listingId;
				},
				itemType: function () {
					return task.aboutType ? task.aboutType : task.listingId ? 'Listing' : null;
				},
				task: function () {
					return task;
				},
				taskSuppliers: function() {
					return (task.listing || {}).taskSuppliers || [];
				}
			},
			'NewTaskModalController',
			'newTaskModalController',
			retFunction);
		}

		self.downloadFiles = function (files) {
			files = files || [];

			if (files.length > 0) {
				TaskService.downloadFiles(taskId, files);
			}
		}

		self.downloadCommentFiles = function(files, commentId) {
			files = files || [];

			if (files.length > 0) {
				TaskService.downloadCommentFiles(taskId, commentId, files);
			}
		}

		/** Deletes file from the new comment that user is editing */
		self.removeFromQueue = function (i) {
			self.commentFiles[i].remove()
			self.commentFiles.splice(i, 1);
		}

		function _getTaskSuppliers(tasks, onlyNotMine) {
			// Get array with all unique suppliers related to an array of tasks
			const suppliers = tasks.reduce((arr, task) => {
				task.suppliers = task.suppliers || [];
				task.suppliers.forEach((supplier) => {
					if (arr.indexOf(supplier) < 0) {
						supplier.isMine = (supplier.supplierAgencies || []).length > 0 || (supplier.supplierBrokers || []).length > 0;
						if ((onlyNotMine && !supplier.isMine) || !onlyNotMine)
							arr.push(supplier);
					}
				})
				return arr;
			}, []);
			return suppliers;
		}

		function getUrl() {
			return UploadService.initCommentUrl(self.newUpdate.id);
		}

		function finishComment() {
			self.newUpdate = { content: '', notifiedId: [] };
			self.commentUploader.url = null;
			self.commentUploader.clearQueue();
			self.commentFiles = [];
			self.commentForm.$setPristine();
			loadTask();
		}

	}) // End of controller
})(); // End of function()
