(function () {

    "use strict";

    angular.module("smartbrokr")

        .controller("PermissionsController", function ($state, AccountService) {
            var thisState = {state: $state.current.name};
            var self = this;
            var brokerCopy = 'PAGES.SIGNUP.PERMISSIONS.BROKER_COPY';
            var agencyCopy = 'PAGES.SIGNUP.PERMISSIONS.AGENCY_COPY';
            self.visitedReview = AccountService.getVisitedReview();
            self.isAgency = $state.params.type == 'agency';
            self.isBroker = !self.isAgency;
            self.permissionCopy = self.isAgency ? agencyCopy : brokerCopy;
            self.next = function () {
                if (self.isAgency) {
                    $state.go('main.signup.invite-brokers', thisState);
                }
                else {
                    $state.go('main.signup.situation', thisState);
                }
            }
            self.accept = function () {
                AccountService.savePermissions(true);
                self.next();
            }
            self.decline = function () {
                AccountService.savePermissions(false);
                self.next();
            }
            self.prev = function () {
                $state.go('main.signup.tell-us-about-yourself');
            }

            self.enSignup = "public/files/Request_for_Data_Transfer.pdf";
            self.enRemoval = "public/files/Cancellation_of_data_transfer.pdf";
            self.frSignup = "public/files/Demande_de_transfert_de_donnees.pdf";
            self.frRemoval = "public/files/Annulation_de_transfert_de_donnees.pdf";
        });
})();
