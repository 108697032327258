/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/**
 *  Controller for modal to attach a person to a Listing
 */
(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('AddPersonController', function ($uibModalInstance, $filter, $scope, AccountService, ListingService, ModalService, id, peopleType, people, linkType, NavService, BuyerService, SellerService) {
		const self = this;
		self.id               = id;
		self.peopleType       = peopleType;
		self.title            = $filter('translate')('ROLES.' + (self.peopleType || 'Role').toUpperCase());
		self.people           = people;
		$scope.peopleType     = peopleType.toUpperCase();
		$scope.canAdd         = _canAdd();
		$scope.limitedAccess  = AccountService.limitedAccess();

		self.showLimit = 3;
		self.search = '';
		self.canShowMore = true;
		self.filteredResults = [];

		const actions = {
			link: null,
			unlink: null
		}

		if(linkType == 'buyer') {
			actions.link = BuyerService.linkSupplier;
			actions.unlink = BuyerService.unlinkSupplier;
		} else if(linkType == 'seller') {
			actions.link = SellerService.linkSupplier;
			actions.unlink = SellerService.unlinkSupplier;
		} else {
			switch($scope.peopleType) {
			case 'BUYER':
				actions.link = ListingService.linkBuyer;
				actions.unlink = ListingService.unlinkBuyer;
				break;
			case 'SELLER':
				actions.link = ListingService.linkSeller;
				actions.unlink = ListingService.unlinkSeller;
				break;
			case 'NOTARY':
				actions.link = ListingService.linkNotary;
				actions.unlink = ListingService.unlinkNotary;
				break;
			case 'SUPPLIER':
				actions.link = ListingService.linkSupplier;
				actions.unlink = ListingService.unlinkSupplier;
				break;
			default:
				actions.link = ListingService.linkBroker;
				actions.unlink = ListingService.unlinkBroker;
			}
		}

		self.checkIfCanShowMore = function() {
			if(self.showLimit < self.filteredResults.length) {
				self.canShowMore = true;
			} else {
				self.canShowMore = false;
			}
		}

		self.showMore = function() {
			self.showLimit += 3;
		}

		self.close = function () {
			$uibModalInstance.dismiss('cancel');
		};

		self.addPerson = function(person){
			actions.link(self.id, person.id)
			.then((res) => {
				$uibModalInstance.close(res);
			})
			.catch((err) => {})
		}

		self.newProfile = function() {
			switch($scope.peopleType) {
			case 'BUYER':
				newBuyer();
				break;
			case 'NOTARY':
				newNotary();
				break;
			case 'SELLER':
				newSeller();
				break;
			case 'SUPPLIER':
				newSupplier();
				break;
			}
		}

		function newSupplier() {
			NavService.navigate('main.suppliers.add', { id: null, listingId: self.id });
			$uibModalInstance.dismiss('cancel');
		}

		function newSeller() {
			NavService.navigate('main.seller.person', { addListing: true, listingId: self.id });
			$uibModalInstance.dismiss('cancel');
		}

		function newBuyer() {
			NavService.navigate('main.buyer.person', { addListing: true, listingId: self.id });
			$uibModalInstance.dismiss('cancel');
		}

		function newNotary() {
			const modal = ModalService.openModal('createUser', {
				type: function(){ return 'notary'; },
				agencyId: function(){ return null }
			}, 'NewUserController', 'newUserController' );


			modal.result.then((res) => {
				if ((res || {}).notaryProfile) {
					self.addPerson(res.notaryProfile);
				}
			})
			.catch((err) => {});
		}

		function _canAdd() {
			const isRightType   = $scope.peopleType === 'NOTARY' || $scope.peopleType === 'SELLER' || $scope.peopleType === 'SUPPLIER' || $scope.peopleType === 'BUYER';
			const isManager     = AccountService.getRole(true) === 'managerProfile';
			let canAddManager = false;

			if (isManager) {
				const manager = AccountService.getManaging() || {};
				canAddManager = manager.allClients;
			}

			return isRightType && (!isManager || canAddManager);
		}
	}) // End of controller
})(); // End of function()
