/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('TaskTemplatesController', function (ModalService, $filter, $stateParams, AccountService, AlertService, GlobalVars, NavService, $sce, ResourceService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.templates		= [];		// All loaded resources
		self.singleConfig	= null;		// Selectize config
		self.filters		= null;		// Parameters for sort/filter
		self.inject         = null;
		self.columns        = [];
		self.userId = AccountService.getUserId();

		self.shareType = 'task';

		const templateCats 	= [];

		// SETUP VARIABLES ======================================================================================================================

		self.singleConfig = GlobalVars.singleConfig;

		templateCats.push({ value: 'xxx', name: '------' });
		templateCats.push({ value: 'isPersonal', name: $filter('translate')('PERSON.PERSONAL') });
		templateCats.push({ value: 'isListing', name: $filter('translate')('LISTING.LISTING') });
		templateCats.push({ value: 'isBuyer', name: $filter('translate')('ROLES.BUYER') });
		templateCats.push({ value: 'isSeller', name: $filter('translate')('ROLES.SELLER') });
		templateCats.push({ value: 'isSupplier', name: $filter('translate')('ROLES.SUPPLIER') });

		self.filters = {
			name: { weight: 0, value: '', desc: 1 },
			description: { weight: 0, value: '', desc: 1 },
			tasks: { weight: 0, value: '', desc: 1,
				sortFunction: function(item) {
					return item.taskList.length;
				}
			},
			category: { weight: 0, value: null, desc: 1,
				filterFunction: function(value, item) {
					return item[value];
				},
				sortFunction: function(item) {
					let ret = '';
					templateCats.forEach((cat) => {
						if (cat.value !== 'xxx' && item[cat.value]) {
							ret += cat.name + '+';
						}
					})
					return ret;
				}
			}
		}

		self.shared = ($stateParams.submenu || {}).indexOf('shared') > -1;

		self.inject = {
			name: 'taskTemplatesController',
			inject: self
		}

		self.navParams = {
			state: 'main.resources.task-templates.edit',
			getParams: function(entry) {
				if (entry) {
					return { id: entry.id, shared: entry.shared };
				}
				return {};
			}
		}

		if(self.shared) {
			self.navParams.state = 'main.resources.shared-task-templates.edit';
		}

		self.columns = [
			{
				name: 'RESOURCES.TASK_TEMPLATE_NAME',
				filter: 'name',
				field: function (template) {
					return template.name;
				}
			},
			{
				name: 'COMMON.DESCRIPTION',
				filter: 'description',
				isHtml: true,
				noSearch: true,
				field: function (template) {
					return $filter('addNewLines')(template.description);
				}
			},
			{
				name: 'TASK.NUMBER_OF_TASKS',
				filter: 'tasks',
				noSearch: true,
				field: function (template) {
					return (template.tasks || []).length || 0;
				}
			},
			{
				name: 'PROPERTY.CATEGORY',
				filter: 'category',
				select: true,
				options: templateCats,
				config: self.singleConfig,
				isHtml: true,
				field: function (template) {
					let ret = '';
					templateCats.forEach((cat) => {
						if (cat.value !== 'xxx' && template[cat.value]) {
							ret += '<div>' + cat.name + '</div>';
						}
					})

					return ret;
				}
			},
			{
				name: '',
				noSearch: true,
				noSort: true,
				size: 2,
				class: 'buttons',
				isClick: true,
				clickFunction: angular.noop,
				field: function (template) {
					if(self.shared) return '';
					const buttons = getShareIndicator(template) + '<button class="btn btn-green medium"' +
                        'ng-disabled="limitedAccess"' +
                        'ng-click="taskTemplatesController.newTaskTemplate(entry)">' +
                        $filter('translate')('COMMON.EDIT') + '</button>' +
                        '<button class="btn btn-link"' +
                        'ng-disabled="limitedAccess"' +
                        'ng-click="taskTemplatesController.deleteTaskTemplate(entry.id)">' +
                        '<img width="16" height="16" src="/public/img/iconTrash.svg"></button>';

					return $sce.trustAsHtml(buttons);
				}
			},
		];

		load();

		// FUNCTIONS ============================================================================================================================

		self.deleteTaskTemplate = function (id) {
			ResourceService.deleteTaskTemplate(id).then((deleted) => {
				if (deleted) {
					load();
				}
			})
			.catch((err) => {})
		}

		function getShareIndicator(template) {
			const shareButton = '<button class="btn btn-green medium mr-1"' +
			'ng-disabled="limitedAccess"' +
			'ng-click="taskTemplatesController.share(entry.id)">' +
			$filter('translate')('RESOURCES.SHARE') +
			'</button>';

			if(template.sbUserId === self.userId) return shareButton;

			return '<label class="mr-2">' + $filter('translate')('RESOURCES.SHARED_WITH_YOU') + '</label>' ;
		}

		self.share = function(templateId) {

			const retFunction = function (result) {
				load();
			}

			ModalService.openModalWithFunction('shareTemplate', {
				message: function () { return null; },
				sender: function () { return null; },
				receiver: function () { return null; },
				type: function() { return self.shareType; },
				templateId: function() { return templateId }
			}, 'ShareTemplateModalController', 'shareTemplateModalController', retFunction);
		}

		self.newTaskTemplate = function (template) {
			const retFunction = function (result) {
				if (!(template || {}).id) { // It was a new template
					NavService.navigate('main.resources.task-templates.edit', { id: result.id, template: result });
				}
				else {
					AlertService.success('RESOURCES.TEMPLATE', 'save');
					load();
				}
			};

			template = template || {};

			ModalService.openModalWithFunction('taskTemplate', {
				template: () => angular.copy(template),
				editTask: () => false,
			},
			'TaskTemplateModalController',
			'taskTemplateModalController',
			retFunction
			);
		};

		function load() {
			let thing;
			if(self.shared) {
				thing = ResourceService.getSharedTaskTemplates();
			} else {
				thing = ResourceService.getTaskTemplates();
			}
			thing.then((res) => {
				self.templates = res;
			})
			.finally(AlertService.doneLoading)
		}
	}) // End of controller
})(); // End of function()
