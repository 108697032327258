(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('WPContentModalController', function ($uibModalInstance, $filter, $scope, $timeout, html, title, pageLabel, wp, latest_revision, offices, lang, GlobalVars, ModalService, ResourceService, UploadService, UserService, WordpressService) {
		const self = this;

		// Block types - WP tag format: <!-- TYPE --><!-- /TYPE --> or <!-- TYPE -- />
		const CARD_BASE           = 'wp:sb-gutenberg/card-base';
		const CARD_LINKS 			= 'wp:sb-gutenberg/card-links';
		const MARKETING_SECTION   = 'wp:sb-gutenberg/marketing-section';
		const WP_COLUMN_PERSONAL  = 'wp:sb-gutenberg/column-personal';
		const WP_FORM_BASE        = 'wp:sb-gutenberg/form-base';
		const CARD_PERSON 		= 'wp:sb-gutenberg/card-person';
		const FEATURED_LISTING 	= 'wp:sb-gutenberg/featured-listing';
		const MAP_SINGLE	 		= 'wp:sb-gutenberg/map-single';
		const TESTIMONIALS 		= 'wp:sb-gutenberg/testimonials';
		const WP_COLUMN			= 'wp:column';
		const WP_COLUMNS 			= 'wp:columns';
		const WP_HEADING			= 'wp:heading';
		const WP_HTML 			= 'wp:html';
		const WP_IMAGE 			= 'wp:image';
		const WP_MEDIA_TEXT		= 'wp:media-text';
		const CARD_MARKETING      = 'wp:sb-gutenberg/card-marketing';
		const SUPPLIERS_SECTION   = 'wp:sb-gutenberg/suppliers-section';
		const CARD_SUPPLIER       = 'wp:sb-gutenberg/card-supplier';

		const marketingCardProperties = ['image_url', 'image_position', 'image_class', 'text_class', 'text_title', 'text', 'links'];

		let hasMarketingSection = false;
		let hasSuppliersSection = false;
		let supplierPageBlocks = [];

		// Move directions for blocks (used with CARD_PERSON block)
		const UP 					= -1;
		const DOWN 				= 1;

		// Equivalents between HTML tags and WP blocks (only added the ones currently used)
		const WP_TAGS = {
			p: 	'wp:paragraph',
			ol: 	'wp:list',
			ul: 	'wp:list'
		}

		if (!(wp || {}).id) {
			$uibModalInstance.dismiss('cancel');
			return;
		}

		self.editor 		= {};					// Configuration for TinyMCE editors
		self.pageLabel 		= pageLabel;			// Label for page type -> Used for modal title
		self.wId 			= wp.id;
		self.addIcons 		= [];
		self.lang = lang;

		self.supplierSearch = '';

		self.singleConfig = GlobalVars.createSingleConfig(false, 'value', 'label', 'label', false, null, 'value', $filter('translate')('WORDPRESS.CONTENT.ALL_SUPPLIERS'));
		self.officeSearchConfig = GlobalVars.createSingleConfig(false, 'value', 'label', 'label', false, null, 'value', $filter('translate')('WORDPRESS.CONTENT.ALL_OFFICES'));
		self.officeConfig = GlobalVars.createSingleConfig(false, 'value', 'label', 'label', false, null, 'value', $filter('translate')('WORDPRESS.CONTENT.NO_OFFICE'));

		self.supplierSearchCategory = '';
		self.supplierCategories = [''];
		self.supplierCategoryOptions = [
			{
				label: $filter('translate')('WORDPRESS.CONTENT.ALL_SUPPLIERS'), value: ''
			}
		];

		self.supplierSearchOffice = '';
		self.supplierOfficeOptions = [
			{
				label: $filter('translate')('WORDPRESS.CONTENT.NO_OFFICE'), value: ''
			}
		];
		self.supplierOfficeSearchOptions = [
			{
				label: $filter('translate')('WORDPRESS.CONTENT.ALL_OFFICES'), value: ''
			}
		];

		self.offices = offices.reduce((arr, curr) => {
			arr.push({ label: curr.legalName, value: curr.legalName });
			return arr;
		}, []);

		self.supplierOfficeOptions = self.supplierOfficeOptions.concat(self.offices);
		self.supplierOfficeSearchOptions = self.supplierOfficeSearchOptions.concat(self.offices);
		self.page = {								// Page that is being edited
			title: title,							// Page title (WordPress: post->post_title)
			blocks: _splitHtml(html),				// Blocks of content in the page (html is the data from post->post_content on WordPress)
			latest_revision: latest_revision
		}

		_setupIcons();
		_setupTitleEditor();
		_setupFullEditor();
		_assignIndexes();

		$uibModalInstance.scope = $scope;
		$uibModalInstance.check = {
			original: angular.copy(self.page),
			clone: self.page
		}

		self.searchSuppliers = function() {
			const isNotSearching = (self.supplierSearch === '' || !self.supplierSearch);
			const isNotFilteringByCategory = (self.supplierSearchCategory === '' || !self.supplierSearchCategory);
			const isNotFilteringByOffice = (self.supplierSearchOffice === '' || !self.supplierSearchOffice);

			if(isNotSearching && isNotFilteringByCategory && isNotFilteringByOffice) {
				self.page.blocks = supplierPageBlocks;
			} else {
				const blocks = supplierPageBlocks.filter((value) => {
					if(!value || !value.type) return false;
					if(value.type !== CARD_SUPPLIER) return true;
					if(value.type === CARD_SUPPLIER) {
						const isFilteredByCategory = (isNotFilteringByCategory || value.content.category === self.supplierSearchCategory);
						const isFilteredByOffice = (isNotFilteringByOffice || value.content.office === self.supplierSearchOffice);
						const isFilteredBySearch = (isNotSearching || (value.content.name || '').toLowerCase().includes(self.supplierSearch.toLowerCase()));
						return isFilteredByCategory && isFilteredByOffice && isFilteredBySearch;
					}
					return false;
				});
				self.page.blocks = blocks;
			}
		}

		self.updateSupplierBlock = function(property, value, id) {
			const index = supplierPageBlocks.findIndex((supplier) => {
				if(!supplier.content) return false;
				return supplier.content.id === id
			});
			if(supplierPageBlocks[index]) {
				supplierPageBlocks[index].content[property] = value;
				if(property === 'category' && value !== '') {
					if(
						!self.supplierCategories.includes(value)
					) {
						self.supplierCategoryOptions.push({ label: value, value: value });
						self.supplierCategories.push(value);
					}
				}
			}
		}

		// FUNCTIONS ============================================================================================================================
		/**
		 * 	Removes a block from the page.
		 * 	Does nothing if the block doesn't exist.
		 * 	@param 	{number}	index 		Index number of the block to be deleted
		 */
		self.deleteBlock = function(index, id) {
			let foundSupplier;
			let foundSupplierIndex;
			if(hasSuppliersSection) {
				foundSupplier = supplierPageBlocks.find((supplier, index) => {
					if(supplier && supplier.content && supplier.content.id === id) {
						foundSupplierIndex = index;
						return supplier;
					}
				});
			}

			if (!self.page.blocks[index] && !foundSupplier)
				return;


			ModalService.confirmDelete(_del, 'ALERT_MESSAGES.ALERT.DELETE_ITEM');

			function _del() {
				if(hasSuppliersSection) supplierPageBlocks.splice(foundSupplierIndex, 1);
				self.page.blocks.splice(index, 1);
				_assignIndexes();
			}
		}

		/**
		 * 	Checks if a block has no content
		 * 	@param 		{object}		block		Block to be checked for content
		 * 	@return 	{boolean}					True if the block has no text content or only has shortcodes
		 */
		self.isEmpty = function(block) {
			block = block || {};
			let text = (block.text || '').replace(/\[\/?[^\]]+\]/gim, '');	// Blocks that only contain shortcodes are considered empty
			text = text.replace(/<!-- \/?wp:html -->/g, '');	// Remove wp:html tags
			return text.match(/\w+/) == null && !block.isNew;
		}

		/**
		 * 	Saves the current page and then closes the modal.
		 */
		self.savePage = function() {
			const newHtml = _joinHtml(self.page.blocks);
			$uibModalInstance.close({ title: self.page.title, content: newHtml });
		}

		self.restoreDefault = function() {
			const newHtml = _joinHtml(self.page.blocks);
			$uibModalInstance.close({ title: self.page.title, content: newHtml, restore: true });
		}

		self.rollbackLatestChange = function() {
			var newHtml;
			if(hasSuppliersSection) {
				var newHtml = _joinHtml(supplierPageBlocks);
			} else {
				var newHtml = _joinHtml(self.page.blocks);
			}

			$uibModalInstance.close({ title: self.page.title, content: newHtml, restore: false, rollback: true });
		}

		self.openRestoreModal = function() {
			const message = 'Restoring a page to the default state means all of your changes will be erased. Do you still wish to restore the page to its default state?';

			const m = ModalService.openModal('confirm', {
				message: () => message
			}, 'ConfirmController', 'confirmController', null);

			m.result.then((res) => {
				self.restoreDefault();
			});
		}

		self.openRollbackModal = function() {
			const revisionDate = new moment(self.page.latest_revision).format('MMMM Do YYYY hh:mm a');
			const message = 'The last change of this page was on ' + revisionDate + '. Do you still wish to rollback the last revision?';

			const m = ModalService.openModal('confirm', {
				message: () => message
			}, 'ConfirmController', 'confirmController', null);

			m.result.then((res) => {
				self.rollbackLatestChange();
			});
		}


		/**
		 * 	Changes the block that currently has focus (used to add focus class)
		 * 	If the block that was clicked is already focused, then the class is removed.
		 * 	Block always has focus if it has an input or tinyMCE form element that is focused.
		 * 	@param 	{string}	blockId 	The element ID of the block that was clicked.
		 * 	@param 	{boolean}	scroll		If true, will scroll to newly focused element
		 */
		self.switchFocus = function(blockId, scroll) {

			const hasInnerFocus = $( '#' + blockId ).find( '.mce-edit-focus, input:focus' ).length > 0;	// Check if inner contents have focus

			if (self.hasFocus == blockId && !hasInnerFocus) {
				self.hasFocus = null;
			}
			else {
				self.hasFocus = blockId;

				if (scroll) {
					const anchor = document.getElementById(blockId);
					const container = angular.element(document.getElementById('modal-body'));
					container.scrollToElement(anchor, 20, 200);
				}
			}
		}

		/**
		 * 	Sets the "index" property of every block according to their index number.
		 * 	The block's index number is used to delete, add new, move up and move down.
		 * 	The indexes are reassigned whenever one of those actions occurs.
		 * 	(didn't use the index from ng-repeat because we have nested ng-repeats and the index set with ng-init didn't update when the array changed)
		 */
		function _assignIndexes() {
			self.page.blocks.forEach((block, i) => {
				block.index = i;
			})
		}

		/**
		 * 	Joins blocks of content into an HTML string.
		 * 	@param 	{object[]} 		blocks		Blocks to be joined as HTML in a page
		 * 	@return {string}					HTML of a Wordpress page containing the blocks
		 */
		function _joinHtml(blocks) {
			const marketingBlocks = [];
			const supplierBlocks = [];
			return blocks.reduce((str, block, index, originalArray) => {
				block = angular.copy(block);
				let _links;

				switch(block.type) {
				case CARD_PERSON:
					var bio = block.content.bio || '';
					bio = bio.replace(/"/g, '\\u0022');
					block.content.bio = encodeURIComponent(bio);
					block.text = _addTagFromText(CARD_PERSON, block.content, true);
					break;
				case FEATURED_LISTING:
					if (block.listingId) {
						block.text = _addTagFromText(FEATURED_LISTING, { listing_id: block.listingId }, true);
					}
					break;
					// case SUPPLIERS_SECTION:
					// 	break;
				case CARD_MARKETING:
				case CARD_BASE:
					_links = block.content.links.reduce((str2, link) => {
						return str2 + 'title=' + encodeURIComponent(link.title) + '&url=' + encodeURIComponent(link.url === undefined ? '' : link.url) + '&type=' + encodeURIComponent(link.type)  + '&uploaded=' + link.uploaded + ',';
					}, '');
					block.content.links = _links;
					block.content.text = encodeURIComponent(block.content.text);
					block.content.text_title = encodeURIComponent(block.content.text_title);
					if(hasMarketingSection) {
						marketingBlocks.push(block);
					} else {
						block.text = _addTagFromText(block.type, block.content, true);
					}
					break;
				case SUPPLIERS_SECTION:
					const cards = [];
					for(let i = 0; i < supplierPageBlocks.length; i++) {
						if(supplierPageBlocks[i].type === CARD_SUPPLIER) {
							cards.push(supplierPageBlocks[i].content);
						}
					}
					const strContent = _addTagFromText(SUPPLIERS_SECTION, { suppliers: cards }, true);
					str += strContent;

					// if(hasSuppliersSection) {
					// 	block.text = '';
					// 	const fieldsToGetURI = ['name', 'address', 'city', 'province', 'category', 'office'];
					// 	for(let j = 0; j < fieldsToGetURI.length; j++) {
					// 		if(block.content[fieldsToGetURI[j]]) block.content[fieldsToGetURI[j]] = encodeURIComponent(block.content[fieldsToGetURI[j]]);
					// 	}
					// 	supplierBlocks.push(block);
					// } else {
					// 	block.text = _addTagFromText(block.type, block.content, true);
					// }
					break;
				case CARD_SUPPLIER:
					break;
				case CARD_LINKS:
					_links = block.content.links.reduce((str2, link) => {
						return str2 + ';' + 'title=' + link[0] + '&url=' + link[1];
					}, '');
					block.content.links = encodeURIComponent(_links);
					block.text = _addTagFromText(block.type, block.content, true);
					break;
				case WP_COLUMN_PERSONAL:
				case WP_FORM_BASE:
				case MAP_SINGLE:
					block.text = _addTagFromText(block.type, block.content, true);
					break;
				case TESTIMONIALS:
					var _testimonials = block.testimonials.reduce((str2, testimonial) => {
						return str2 + ';' + testimonial.author + ',' + testimonial.position + ',' + encodeURIComponent(testimonial.text);
					}, '');
					block.text = _addTagFromText(TESTIMONIALS, { testimonials: _testimonials }, true);
					break;
				case WP_COLUMNS:
					var content = block.content || {};
					block.text = _addTagFromText(WP_COLUMNS, content.info);
					block.text += '<div class="wp-block-columns has-' + content.children.length + '-columns ' + content.info.className + '">';
					content.children.forEach((child) => {
						block.text += _addTagFromText(WP_COLUMN) + '<div class="wp-block-column">';
						if (child.heading) {
							block.text += _parseHeading(child.heading);
						}
						if (child.content) {
							const els = $( child.content );
							els.each(function() {
								block.text += _addTagFromElement($(this));
							});
						}
						block.text += '</div>' + _closeTag(WP_COLUMN);
					})
					block.text += '</div>' + _closeTag(WP_COLUMNS);
					break;
				case WP_HEADING:
					block.text = _parseHeading(block.content);
					break;
				case WP_IMAGE:
					var info = block.content || {};
					var json = {
						id: info.attachmentId,
						className: info._className
					}
					var el = info.$element;
					el.find( 'img' ).removeClass().addClass( 'wp-image-' + info.attachmentId );
					el.find( 'img' ).attr( 'src', info.imageUrl );

					block.text = _addTagFromText(WP_IMAGE, json);
					block.text += el.prop('outerHTML') || el.html();
					block.text += _closeTag(WP_IMAGE);
					break;
				case WP_MEDIA_TEXT:
					if (!block.content.title.includes('h2')) {
						block.content.title = '<h2>' + block.content.title + '</h2>';
					}

					block.content.imgSrc = _formatMediaImage(block.content.imgSrc, block.content.blockInfo.mediaId, block.content.blockInfo.mediaType);

					var content = '';

					$(block.content.content).each(function() {
						const _this = $( this );
						if ((_this.html() || '').length > 0) {
							content += _addTagFromElement(_this);
						}
					})

					block.content.content 	= content;

					block.text = _addTagFromText(WP_MEDIA_TEXT, block.content.blockInfo);
					block.text += '<div class="wp-block-media-text alignwide has-media-on-the-' + block.content.blockInfo.mediaPosition + ' ' + block.content.blockInfo.className + '">';
					block.text += '<figure class="wp-block-media-text__media">' + block.content.imgSrc + '</figure>';
					block.text += '<div class="wp-block-media-text__content">';
					block.text += _addTagFromText(WP_HEADING) + block.content.title + _closeTag(WP_HEADING);
					block.text += block.content.content + '</div></div>' + _closeTag(WP_MEDIA_TEXT);
				}

				if(
					(index == (originalArray.length) - 1) && (
						(block.type == CARD_MARKETING && hasMarketingSection)
					)
				) {
					if(hasMarketingSection) {
						const cards = [];
						for(let i = 0; i < marketingBlocks.length; i++) {
							cards.push(marketingBlocks[i].content);
						}
						str += _addTagFromText(MARKETING_SECTION, { cards: cards }, true);
					}
				} else if(
					(
						(block.type != CARD_MARKETING && hasMarketingSection) ||
						!hasMarketingSection
					) &&
					block.type !== SUPPLIERS_SECTION &&
					block.type !== CARD_SUPPLIER
				) {
					str += block.text;
				}

				return str;
			}, '');

			/**
			 * 	Generates HTML for the wp:heading tag.
			 * 	Used with the wp:heading block and wp:columns block
			 * 	@param 	{object}	content 	Object with information about the block
			 * 	@param 	{object}	content.info 			Object with information that will be added to the start tag (in JSON format)
			 * 	@param 	{jQuery}	content.$element		Original JQuery element created when the content was fetched from WP
			 * 	@param 	{string}	content.heading 		Heading text
			 * 	@param 	{boolean}	content.hasImage 		If true, image is added to the resulting HTML
			 * 	@param 	{string=}	content.attachmentId	Optional. Attachment ID of the image in this heading
			 * 	@param 	{string=}	content.imageUrl 		Optional. URL of the image added to this heading
			 * 	@return {string}							Generated HTML string for this heading block
			 */
			function _parseHeading(content) {
				let ret = _addTagFromText(WP_HEADING, content.info);
				const el = content.$element;
				let img = '';

				if (content.hasImage) {
					const imgEl = el.find('img');
					imgEl.attr( 'class', 'wp-image-' + content.attachmentId);
					imgEl.attr('src', content.imageUrl);
					img = imgEl.prop('outerHTML') || '';
				}
				el.html(content.heading + img);
				ret += el.prop('outerHTML') + _closeTag(WP_HEADING);

				return ret;
			}
		}

		/**
		 * 	Generates the HTML for a tagged WP block based on a JQuery element.
		 * 	The WP tag is chosen according to the type of element, as specified in the WP_TAGS variable.
		 * 	If no equivalent exists for this element, the tag is added as wp:html
		 * 	@param 	{jQuery} 	el			JQuery element
		 * 	@param 	{object=} 	props 		Optional. Object with information to be added to the open tag of the WP block (JSON)
		 * 	@return {string}				HTML content containg the element HTML wrapped in a WP block tag
		 */
		function _addTagFromElement(el, props) {
			const tag = (el.prop('tagName') || '').toLowerCase();
			const wpType = WP_TAGS[tag] || WP_HTML;
			const text = el.prop('outerHTML') || '';
			let json = '';

			if (text.length == 0)
				return text;

			if (tag == 'ol') {
				props = { ordered: true };
			}

			if (!!props) {
				json = ' ' + JSON.stringify(props);
			}

			return '<!-- ' + wpType + json + ' -->' + (el.prop('outerHTML') || '') + '<!-- /' + wpType + ' -->';
		}

		/**
		 * 	Creates an open tag for a WP block based on its type + properties
		 * 	@param 	{string} 	type 			Type of block
		 * 	@param 	{object=} 	props 			Optional. Properties to be added to the tag (JSON)
		 * 	@param 	{boolean} 	isSelfClose 	If true, adds / to the end of the tag
		 * 	@return {string}					Open tag for a WP block
		 */
		function _addTagFromText(type, props, isSelfClose) {
			let ret = '<!-- ';

			ret += type + ' ';

			if (!!props) {
				ret += JSON.stringify(props) + ' ';
			}

			if (isSelfClose) {
				ret += '/';
			}

			return ret + '-->';
		}

		/**
		 * 	Generates open tag for a WP block.
		 * 	@param	{string}	type	Type of WP block
		 * 	@return {string}			Formatted open tag for a WP block
		 */
		function _closeTag(type) {
			return '<!-- /' + type + ' -->';
		}

		/**
		 * 	Generates HTML content for a media element, based on its type and source.
		 * 	If the mediaType is "image", a class based on the attachment id is also added to the element.
		 * 	Valid values for mediaType:
		 * 		"image" - Returns <img>
		 * 		"video"	- Returns <video></video>
		 * 	Other values return an embed shortcode ([embed]src[/embed] - see: https://wordpress.org/support/article/embeds/)
		 * 	@param 	{string} 	src 		Media URL
		 * 	@param 	{string} 	mediaId 	Attachment ID of the uploaded media on WP (used to add proper class to img elements)
		 * 	@param 	{string} 	mediaType 	Type of media.
		 * 	@return {string}				HTML content for the media element
		 */
		function _formatMediaImage(src, mediaId, mediaType) {
			src = src || '';
			mediaId = mediaId || '';
			mediaType = mediaType || 'image';

			switch(mediaType) {
			case 'image':
				return '<img class="wp-image-' + mediaId + '" src="' + src + '">';
			case 'video':
				return '<video controls src="' + src + '"></video>';
			}

			return '[embed]' + src + '[/embed]';
		}

		/**
		 * 	Splits an HTML string into content blocks to be edited.
		 * 	@param 	{string} 	content 	HTML content from a WordPress page
		 * 	@return {object[]}				Array of blocks
		 */
		function _splitHtml(content) {
			content = content || '';
			const blockReg 	= /<!-- (wp:[^ ]+\b)(?: {[^}]+})* \/?-->/;	// Regular expression to check for a block
			const altBlockReg = /<!--\s?wp:sb-gutenberg\/marketing-section\s?(?<json>.+)-->/g;
			const supplierBlockReg = /<!--\s?wp:sb-gutenberg\/suppliers-section\s?(?<json>.+)-->/g;
			let control 	= 100;	// Control variable to avoid infinite loops
			let ret 		= [];	// Return value

			while (control > 0) {
				if (content.length == 0) {	// Nothing more to check

					break;
				}

				let openBlock = content.match(blockReg);
				if (!openBlock) {		// No new blocks were found
					openBlock = content.match(altBlockReg);
					if(!openBlock) {
						openBlock = content.match(supplierBlockReg);
						if(!openBlock) {
							ret.push({ text: content, type: 'none' });	// Add last content to the blocks value
							break;
						}
					}
				}

				if (openBlock.index > 0) {	// The block is not the first item in the HTML content -> Add previous chunk as a block with no type
					const previous = content.slice(0, openBlock.index);
					ret.push({ text: previous, type: 'none' });
					content = content.replace(previous, '');
				}

				let blockText   = openBlock[0] || '';
				let blockTag    = openBlock[1];
				const closeReg    = new RegExp('<!-- /' + blockTag + ' -->');

				const endBlock = content.match(closeReg);


				if (!!endBlock) {
					blockText = content.slice(0, endBlock.index) + endBlock[0];
				}

				if(!blockTag && blockText.match(altBlockReg)) {
					blockTag = MARKETING_SECTION;
				}

				if(!blockTag && blockText.match(supplierBlockReg)) {
					blockTag = SUPPLIERS_SECTION;
				}

				var block = {
					text: blockText,
					type: blockTag
				}

				switch(blockTag) {
				case CARD_LINKS:
					block.content = _parseLinkCard(blockText);
					break;
				case MARKETING_SECTION:
					hasMarketingSection = true;
					block.content = _parseMarketingSection(blockText);
					break;
				case CARD_MARKETING:
					block.content = _parseMarketingCard(blockText);
					break;
				case SUPPLIERS_SECTION:
					hasSuppliersSection = true;
					block.content = _parseSuppliersSection(blockText);
					break;
				case WP_COLUMN_PERSONAL:
				case WP_FORM_BASE:
				case CARD_BASE:
					block.content = _getBlockInfo(blockText, blockTag);
					break;
				case CARD_PERSON:
					block.content = _parseCardPerson(blockText); break;
				case FEATURED_LISTING:
					block.listingId = _parseFeaturedListing(blockText); break;
				case MAP_SINGLE:
					block.content = _parseMapSingle(blockText); break;
				case TESTIMONIALS:
					block.testimonials = _parseTestimonials(blockText); break;
				case WP_COLUMNS:
					block.content = _parseColumns(blockText); break;
				case WP_HEADING:
					block.content = _parseHeading(blockText, true); break;
				case WP_IMAGE:
					block.content = _parseImageBlock(blockText); break;
				case WP_MEDIA_TEXT:
					block.content = _parseMediaText(blockText); break;
				default:
					block.content = null;
				}

				if([MARKETING_SECTION, SUPPLIERS_SECTION].includes(blockTag)) {
					if(blockTag == MARKETING_SECTION) {
						ret = ret.concat(_getSubCards(block.content, 'cards', CARD_MARKETING));
					} else {
						const subCards = _getSubCards(block.content, 'suppliers', CARD_SUPPLIER);
						ret = ret.concat(block, subCards);
					}
				} else {
					ret.push(block);
				}
				content = content.replace(blockText, '');
				control --;
			}

			if(hasSuppliersSection) {
				supplierPageBlocks = JSON.parse(JSON.stringify(ret));
			}

			return ret;

			/**
			 * 	Gets the JSON information added to the open tag of a WP block.
			 * 	If nothing is found, returns an empty object
			 * 	@param 	{string} 	text 	HTML string for the block
			 * 	@param 	{string} 	type 	Type of block (WP tag)
			 * 	@return {object}			Parse JSON object retrieved the from block's open tag
			 */
			function _getBlockInfo(text, type) {
				const block 	= text.match('(?:<!-- ' + type + ' ){1}({[^}]+}){1}(?: \\/?-->){1}', 'im') || [];
				try {
					return JSON.parse(block[1]);
				}
				catch(e) {
					return {};
				}
			}

			/**
			 * Gets the array embedded in a gutenberg content block (currently used for suppliers and marketing content)
			 * @param {any} content The content to get the embedded array from
			 * @param {string} arrayProp The array property name
			 * @param {string} type The type of remote editing content to display for each array item
			 * @returns array
			 */
			function _getSubCards(content, arrayProp, type) {
				const subRet = [];
				if(!content) return subRet;
				if(typeof content == 'string' || content instanceof String) {
					try {
						content = JSON.parse(block.content);
					} catch (e) {
						console.error('_getSubCards error', e);
						return subRet;
					}
				}
				if(!content[arrayProp]) return subRet;
				for(let i = 0; i < content[arrayProp].length; i++) {
					const newBlock = {
						type: type,
						text: 'ABCDEF',
						content: block.content[arrayProp][i]
					};
					const fieldsToGetURI = ['title', 'name', 'address', 'city', 'province', 'category' ];
					if(!newBlock.content) newBlock.content = {};
					for(let j = 0; j < fieldsToGetURI.length; j++) {
						if(newBlock.content[fieldsToGetURI[j]]) newBlock.content[fieldsToGetURI[j]] = getURIComponent(newBlock.content[fieldsToGetURI[j]]);
					}
					if(arrayProp === 'suppliers') {
						if(
							!self.supplierCategories.includes(newBlock.content['category']) &&
							(newBlock.content['category'] && newBlock.content['category'] !== '')
						) {
							self.supplierCategoryOptions.push({ label: newBlock.content['category'], value: newBlock.content['category'] });
							self.supplierCategories.push(newBlock.content['category']);
						}
					}
					subRet.push(newBlock);
				}
				return subRet;
			}

			/**
			 * 	Retrieves the information that can be edited from a Card Person block.
			 * 	@param 	{string}	text 			Original HTML content of the block (also works with the shortcode version)
			 * 	@return {object}	personInfo		Object containing information used to render the sb_card_person shortcode. All attributes are optional.
			 * 	@return {string=}	personInfo.image_url		URL of this person's photo
			 * 	@return {string=}	personInfo.title 			Job title
			 * 	@return {string=}	personInfo.full_name 		Full name
			 * 	@return {string=}	personInfo.phone_office		Work phone
			 * 	@return {string=}	personInfo.phone_mobile 	Mobile phone
			 * 	@return {string=}	personInfo.email			Email
			 * 	@return {string=}	personInfo.bio				Short bio (text)
			 */
			function _parseCardPerson(text) {
				const card = text.match(/(?:(?:(?:\[sb_card_person )([^\]]+)(?:\]))|(?:(?:<!-- wp:sb-gutenberg\/card-person )({.+}){1}(?: \/-->)))/, 'i');
				let personInfo = {};

				if (!!card[1]) {	// is shortcode
					const split = card[1].split('=');
					let control = split.length || 10;
					while (split.length > 0 && control > 0) {
						let attr = '';
						while (!attr && split.length > 0) {
							attr = split.shift();
						}
						personInfo[attr] = split.shift().replace(/"/g, '');
						control--;
					}
				}
				else if (!!card[2]) {	// is Gutenberg block
					try {
						personInfo = JSON.parse(card[2]);
					}
					catch(e) {
						personInfo = {};
					}
				}

				return personInfo;
			}

			function _parseColumns(text) {
				const block 	= text.match(/(?:<!-- wp:columns ){1}({[^}]+}){1}(?: -->){1}([\s\S]+)(?:<!-- \/wp:columns -->){1}/, 'im') || [];
				const ret = {
					info: _getBlockInfo(text, WP_COLUMNS),
					children: []
				};

				const el = $( block[2] );
				ret.$element = el;
				el.children().each(function() {
					const _this = $(this);
					if ((_this[0] || {}).outerHTML) {
						const toAdd = {
							text: _this[0].outerHTML,
						}

						toAdd.heading = _parseHeading(toAdd.text);
						toAdd.content = '';

						_this.children(':not(:first-child)').each(function() {
							toAdd.content += $(this)[0].outerHTML;
						})

						ret.children.push(toAdd);
					}
				});

				return ret;
			}

			function _parseHeading(text, doHasImage = false) {
				const block 	= text.match(/(?:<!-- wp:heading ){1}({[^}]+}){1}(?: -->){1}([\s\S]+)(?:<!-- \/wp:heading -->){1}/, 'im') || [];
				const ret = {
					info: _getBlockInfo(text, WP_HEADING)
				};
				const el = $( block[2] );
				ret.$element = el;
				ret.hasImage = el.hasClass('has-image');
				ret.heading = (el[0] || {}).innerHTML || '';
				if (ret.hasImage) {
					ret.imageUrl = el.find('img').attr('src') || '';
					const imgClass = el.find( 'img' ).attr( 'class' ) || '';
					ret.attachmentId = imgClass.replace('wp-image-', '');
					if(doHasImage) {
						try {
							const headingElement = $(ret.heading);
							ret.heading = headingElement[0].outerHTML || '';
						} catch(e) {
							const headingElement = $('<p>' + ret.heading + '</p>');
							headingElement.remove('.' + imgClass);
							ret.heading = headingElement[0].innerText || '';
						}

					}
				}

				return ret;
			}

			function _parseFeaturedListing(text) {
				text = text || '';
				const listingId = text.match(/(?:\[sb_featured_listing listing_id=|<!-- wp:sb-gutenberg\/featured-listing {"listing_id":")([A-z0-9]+){1}(?:\]|"} \/-->)/) || [];
				return listingId[1] || '';
			}

			function _parseImageBlock(text) {
				const block 	= text.match(/(?:<!-- wp:image ){1}({.+}){1}(?: -->){1}([\s\S]+)(?:<!-- \/wp:image -->){1}/, 'im') || [];
				let info 	= {};
				const ret 	= {};
				let img 	= null;

				try {
					info = JSON.parse(block[1]);
				}
				catch(e) {
					info = {};
				}

				ret.attachmentId = info.id;
				ret._className = info.className;
				ret.$element = $( block[2] );

				img = ret.$element.find( 'img' );

				if (!!img) {
					ret.imageUrl = img.attr( 'src' ) || '';
				}

				return ret;
			}

			function _parseLinkCard(text) {
				const content = _getBlockInfo(text, CARD_LINKS);
				content.links = decodeURIComponent(content.links);
				content.links = content.links.split(';').reduce((arr, str) => {
					if(str === '' || str === ' ') return arr;
					const splitter = str.split('&');
					const values = [];

					splitter.forEach((value, index) => {
						const splitValue = value.split('=');
						values.push(splitValue[1]);
					});

					arr.push(values);
					return arr;
				}, []);
				return content;
			}



			function _parseMarketingCard(text) {
				const content = _getBlockInfo(text, CARD_MARKETING);
				content.links = content.links.split(',').reduce((arr, str) => {
					if(str === '' || str === ' ') return arr;
					const splitter = str.split('&amp;');
					const properties = {};

					splitter.forEach((value, index) => {
						const splitValue = value.split('=');
						if(splitValue[0] === 'uploaded') {
							if(splitValue[1] === 'undefined') {
								properties[splitValue[0]] = false;
							} else {
								try {
									properties[splitValue[0]] = splitValue[1] === 'true' ? true : false;
								} catch(err) {
									properties[splitValue[0]] = false;
								}
							}
						} else if(
							(splitValue[0] === 'type' && (splitValue[1] === 'null' || splitValue[1] === 'undefined')) ||
                            (splitValue[0] === 'url' && (splitValue[1] === 'null' || splitValue[1] === 'undefined'))
						) {
							properties[splitValue[0]] = '';
						} else {
							properties[splitValue[0]] = splitValue[1];
						}
					});

					arr.push(properties);
					return arr;
				}, []);
				return content;
			}

			function _parseSuppliersSection(text) {
				const sectionRegex = /<!--\s?wp:sb-gutenberg\/suppliers-section\s?(?<json>.+)\s?\/-->/;
				const block 	= text.match(sectionRegex, 'im') || [];
				let content;
				try {
					content = JSON.parse(block[1]);
				}
				catch(e) {
					console.log('e', e);
					return {};
				}

				return content;
			}

			function _parseMarketingSection(text) {
				const sectionRegex = /<!--\s?wp:sb-gutenberg\/marketing-section\s?(?<json>.+)\s?\/-->/;
				const block 	= text.match(sectionRegex, 'im') || [];
				let content;
				try {
					content = JSON.parse(block[1]);
				}
				catch(e) {
					console.log('e', e);
					return {};
				}

				if(content.cards) {
					for(let i = 0; i < content.cards.length; i++) {
						content.cards[i].links = content.cards[i].links.split(',').reduce((arr, str) => {
							if(str === '' || str === ' ') return arr;
							const splitter = str.split('&amp;');
							const properties = {};

							splitter.forEach((value, index) => {
								const splitValue = value.split('=');
								if(splitValue[0] === 'uploaded') {
									if(splitValue[1] === 'undefined') {
										properties[splitValue[0]] = false;
									} else {
										try {
											properties[splitValue[0]] = splitValue[1] === 'true' ? true : false;
										} catch(err) {
											properties[splitValue[0]] = false;
										}
									}
								} else if(
									(splitValue[0] === 'type' && (splitValue[1] === 'null' || splitValue[1] === 'undefined')) ||
									(splitValue[0] === 'url' && (splitValue[1] === 'null' || splitValue[1] === 'undefined'))
								) {
									properties[splitValue[0]] = '';
								} else {
									properties[splitValue[0]] = splitValue[1];
								}
							});

							arr.push(properties);
							return arr;
						}, [])
					}
				}
				return content;
			}

			function _parseMapSingle(text) {
				return _getBlockInfo(text, MAP_SINGLE);
			}

			function _parseMediaText(text) {
				text = text || '';

				const block = text.match(/(?:<!-- wp:media-text ){1}({[^}]+}){1}(?: -->){1}([\s\S]+)(?:<!-- \/wp:media-text -->){1}/, 'im') || [];
				const blockInfo = _getBlockInfo(text, WP_MEDIA_TEXT);

				const el = $( block[2] );
				const html = block[2] || '';

				let title = html.match(/(?:<!-- wp:heading -->)([\s\S]+)(?:<!-- \/wp:heading -->)/, 'i') || [];
				title = title[1] || '';

				let content = html.match(/(?:<!-- \/wp:heading -->)([\s\S]+)(?:<\/div><\/div>)/, 'im') || [];
				content = content[1] || '';
				content = content.replace(/<!-- (\/)?wp:[A-z0-9\/_\-]+ -->/g, '');	// Remove WP tags -> will be added later when returning the content to WP

				return {
					$element: el,
					blockInfo: blockInfo,
					imgSrc: el.find('img, video').attr('src'),
					title: title,
					content: content
				};
			}

			function getURIComponent(field) {
				let decoded = '';
				try {
					decoded = decodeURIComponent(field);
				} catch(e) {
					decoded = field;
				}
				return decoded;
			}

			function _parseTestimonials(text) {
				text = text || '';
				let testimonials = text.match(/(?:\[sb_testimonials testimonials=|<!-- wp:sb-gutenberg\/testimonials {"testimonials":")([^\]}]+)(?:\]|"} \/-->)/, 'i') || [];

				if (testimonials.length > 1) {
					testimonials = testimonials[1].split(';').reduce((arr, str) => {
						if ((str || '').length > 0) {
							const fields = str.split(',');
							arr.push({
								author: fields[0],
								position: fields[1],
								text: getURIComponent(fields[2])
							});
						}
						return arr;
					}, []);
				}
				else {
					testimonials = [];
				}

				return testimonials || [];
			}
		}

		/**
		 * 	Adds icons to each content block according to the page where they are inserted.
		 * 	Used to add move up/down + add new icons to "My Colleagues" page
		 */
		function _setupIcons() {

			const newMarketingObj = {};

			marketingCardProperties.forEach((value, index) => {
				if(marketingCardProperties[index] === 'links') {
					newMarketingObj[value] = [];
				} else {
					newMarketingObj[value] = '';
				}
			});

			const icons = {
				MY_COLLEAGUES: [
					{
						blockType: CARD_PERSON,
						className: 'fas fa-user-plus',
						popover: $filter('translate')('WORDPRESS.CONTENT.CARD_PERSON.ADD_NEW'),
						action: function(index) {
							self.page.blocks.splice(index, 0, {
								type: CARD_PERSON,
								isNew: true,
								text: '',
								content: {
									bio: '',
									email: '',
									full_name: '',
									image_url: '',
									phone_mobile: '',
									phone_office: '',
									title: ''
								}
							});
							_assignIndexes();

							$timeout(() => {
								self.switchFocus('block-' + index, true);
							})
						},
						isDisabled: angular.noop
					},
					{
						blockType: CARD_PERSON,
						className: 'fas fa-chevron-up',
						popover: $filter('translate')('WORDPRESS.CONTENT.MOVE_UP'),
						action: function(index) {
							_moveBlock(index, UP);
							_assignIndexes();
						},
						isDisabled: function(index) {
							return !_canMoveBlock(index, UP);
						}
					},
					{
						blockType: CARD_PERSON,
						className: 'fas fa-chevron-down',
						popover: $filter('translate')('WORDPRESS.CONTENT.MOVE_DOWN'),
						action: function(index) {
							_moveBlock(index, DOWN);
							_assignIndexes();
						},
						isDisabled: function(index) {
							return !_canMoveBlock(index, DOWN);
						}
					}
				],
				SUPPLIERS: [
					{
						blockType: CARD_SUPPLIER,
						className: 'fas fa-plus-square',
						popover: $filter('translate')('WORDPRESS.CONTENT.BLOCKS.ADD_BLOCK'),
						action: function(index) {
							self.page.blocks.splice(index, 0, {
								type: CARD_SUPPLIER,
								isNew: true,
								content: {
									name: '',
									website: '',
									imageUrl: '',
									email: '',
									category: '',
									address: '',
									city: '',
									province: '',
									country: '',
									phone: '',
									postal: '',
									featured: false
								}
							});
							_assignIndexes();

							$timeout(() => {
								self.switchFocus('block-' + index, true);
							})
						},
						isDisabled: angular.noop
					},
					{
						blockType: CARD_SUPPLIER,
						className: 'fas fa-chevron-up',
						popover: $filter('translate')('WORDPRESS.CONTENT.MOVE_UP'),
						action: function(index) {
							_moveBlock(index, UP);
							_assignIndexes();
						},
						isDisabled: function(index) {
							if(self.supplierSearch && self.supplierSearch !== '') return true;
							return !_canMoveBlock(index, UP);
						}
					},
					{
						blockType: CARD_SUPPLIER,
						className: 'fas fa-chevron-down',
						popover: $filter('translate')('WORDPRESS.CONTENT.MOVE_DOWN'),
						action: function(index) {
							_moveBlock(index, DOWN);
							_assignIndexes();
						},
						isDisabled: function(index) {
							if(self.supplierSearch && self.supplierSearch !== '') return true;
							return !_canMoveBlock(index, DOWN);
						}
					},
				],
				MARKETING: [
					{
						blockType: CARD_MARKETING,
						className: 'fas fa-plus',
						popover: $filter('translate')('WORDPRESS.CONTENT.CARD_MARKETING.ADD_NEW'),
						action: function(index) {
							self.page.blocks.splice(index, 0, {
								type: CARD_MARKETING,
								isNew: true,
								text: '',
								content: newMarketingObj
							});
							_assignIndexes();

							$timeout(() => {
								self.switchFocus('block-' + index, true);
							})
						},
						isDisabled: angular.noop
					},
					{
						blockType: CARD_MARKETING,
						className: 'fas fa-chevron-up',
						popover: $filter('translate')('WORDPRESS.CONTENT.MOVE_UP'),
						action: function(index) {
							_moveBlock(index, UP);
							_assignIndexes();
						},
						isDisabled: function(index) {
							return !_canMoveBlock(index, UP);
						}
					},
					{
						blockType: CARD_MARKETING,
						className: 'fas fa-chevron-down',
						popover: $filter('translate')('WORDPRESS.CONTENT.MOVE_DOWN'),
						action: function(index) {
							_moveBlock(index, DOWN);
							_assignIndexes();
						},
						isDisabled: function(index) {
							return !_canMoveBlock(index, DOWN);
						}
					},
					{
						blockType: CARD_LINKS,
						className: 'fas fa-plus-square',
						popover: $filter('translate')('WORDPRESS.CONTENT.BLOCKS.ADD_BLOCK'),
						action: function(index) {
							self.page.blocks.splice(index, 0, {
								type: CARD_LINKS,
								isNew: true,
								content: {
									title: '',
									links: [
										[ '', '' ],
										[ '', '' ],
										[ '', '' ],
									]
								}
							});
							_assignIndexes();

							$timeout(() => {
								self.switchFocus('block-' + index, true);
							})
						},
						isDisabled: angular.noop
					},
					{
						blockType: CARD_LINKS,
						className: 'fas fa-chevron-up',
						popover: $filter('translate')('WORDPRESS.CONTENT.MOVE_UP'),
						action: function(index) {
							_moveBlock(index, UP);
							_assignIndexes();
						},
						isDisabled: function(index) {
							return !_canMoveBlock(index, UP);
						}
					},
					{
						blockType: CARD_LINKS,
						className: 'fas fa-chevron-down',
						popover: $filter('translate')('WORDPRESS.CONTENT.MOVE_DOWN'),
						action: function(index) {
							_moveBlock(index, DOWN);
							_assignIndexes();
						},
						isDisabled: function(index) {
							return !_canMoveBlock(index, DOWN);
						}
					},
				]
			}


			self.addIcons = icons[pageLabel] || [];

			function _canMoveBlock(index, direction) {
				switch(direction) {
				case UP:
					return canSwitchOne(0, index, index);

				case DOWN:
					return canSwitchOne(index + 1, self.page.blocks.length, index);

				default:				// Invalid direction
					return false;
				}

				function canSwitchOne(start, end, moving) {
					for (let i = start; i < end; i++) {
						if (
							!self.isEmpty(self.page.blocks[i]) &&
							self.page.blocks[i] &&
							self.page.blocks[i].type &&
							!self.isEmpty(self.page.blocks[moving]) &&
							self.page.blocks[i].type == self.page.blocks[moving].type
						) {
							return true;
						}
					}
					return false;
				}
			}

			function _moveBlock(index, direction) {
				let newIndex = index + direction;
				const block = self.page.blocks[index];
				let other = self.page.blocks[newIndex];

				// Ignore empty blocks
				while (self.isEmpty(other) && newIndex < self.page.blocks.length && newIndex >= 0) {
					newIndex += direction;
					other = self.page.blocks[newIndex];
				}

				self.page.blocks[newIndex] = block;
				self.page.blocks[index] = other;

				self.switchFocus('block-' + newIndex, true);
			}
		}

		/**
		 * 	Generates the editor config for the page title input.
		 * 	Has minimal configuration, with no toolbar and only the "past" and "search/replace" plugins
		 */
		function _setupTitleEditor() {
			const plugins = 'paste searchreplace';
			const config = GlobalVars.createEditorConfig(true, false, plugins, false);
			self.editor.title = config;
		}


		function editBlock(testimonial, index, block) {
			/**
			 * 	Sets a testimonial as the one being current edited in a block.
			 * 	Does nothing if either the block or the testimonial are undefined/null/false
			 * 	@param 	{object}	testimonial		Testimonial to be edited
			 * 	@param 	{number}	index			Index number of the testimonial (in the array of testimonials of that block)
			 * 	@param 	{object}	block			Block that owns the testimonial.
			*/
			if (!block || !testimonial)
				return;

			testimonial = testimonial || {};

			block.editTestimonial = {
				text: testimonial.text,
				author: testimonial.author,
				position: testimonial.position,
				index: index
			}
		}

		/**
		 * 	Generates the editor config for full text editing.
		 * 	Has all the supported plugins and toolbar items.
		 */
		function _setupFullEditor() {
			const plugins = 'advlist autolink charmap code ' +
				'colorpicker contextmenu image link ' +
				'lists paste searchreplace textcolor textpattern';

			const toolbar = 'bold italic strikethrough fontsizeselect | bullist numlist | alignleft aligncenter alignright alignjustify | forecolor outdent indent link charmap | cut copy paste | image code';

			const config 			= GlobalVars.createEditorConfig(true, false, plugins, toolbar);
			const imgUploader 	= UploadService.initUserUploader();		// Image uploader

			config.image_advtab = true;

			// Image upload handler.
			// First we upload the image to the user's personal files.
			// Then, we send that image URL to WordPress to it can be uploaded there and added as an attachment.
			// Then, we delete the file we uploaded to our servers and use the URL of the image uploaded to WP
			config.images_upload_handler = function (blobInfo, success, failure) {
				imgUploader.onSuccessItem = function (fileItem, response, status, headers) {
					if (Array.isArray(response) || response.url) {
						WordpressService.sendUpdate(self.wId, 'upload', response).then((res) => {
							UserService.deleteFile(response).then((deleted) => {
								success((res.result || {}).url);
							})
							.catch(failure);
						})
					}
					else {
						failure('FAILED');
					}
				};

				imgUploader.addToQueue(blobInfo.blob());
				imgUploader.uploadAll();
			};

			config.image_list = function(success) {

				ResourceService.getMarketingImages().then((res) => {
					const ret = res.reduce((arr, folder) => {
						folder.files.reduce((imgArr, img) => {
							arr.push({ title: img.originalFilename, value: img.url });
						}, []);
						return arr;
					}, []);
					success(ret);
				});
			};

			self.editor.full = config;
		}

	}) // End of controller
})(); // End of function()
