/**
 *  © copyright 2017, 2818779 Canada Inc.
 *   
 *  Use of this software is only permitted if you have entered into a
 *  license agreement with 2818779 Canada Inc. and is subject to the terms
 *  and conditions of such license agreement.
 *
 */

(function () {

	"use strict";

	angular.module("smartbrokr")

		.controller("ErrorController", function ($state, AccountService) {
			var self = this;

			self.errorCode = $state.params.code;
			var message = 'ALERT_MESSAGES.ERROR.CLIENT.' + self.errorCode;
			self.error = self.errorCode === 401 || self.errorCode === 403 || self.errorCode === 404? message : ($state.params.error || "An error has occurred.");

			self.logout = AccountService.logout;
		})
	// End of main.controller
})(); // End of function()
