(function () {

    "use strict";

    angular.module("smartbrokr")

        .controller("SituationController", function ($state,$stateParams,AccountService) {
            var self = this;
            self.visitedReview = AccountService.getVisitedReview();
            self.prev = function () {
                if ($stateParams.type == 'broker') {
                    var state = $stateParams.system === 'centris' ? 'main.signup.permissions-centris' : 'main.signup.permissions-realtor';
                    $state.go(state);
                } 
                else if ($stateParams.type == 'agency') {
                    $state.go('main.signup.invite-staff');
                }
            }
        });
})();
