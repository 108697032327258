/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.reports', [])

	.service('ReportsService', function (AccountService, ModalService, Agency, Broker, Folder, SbUser, Seller, Task, $rootScope) {
		const self = this;

		self.getFoldersAndReports = function () {
			return SbUser.getFoldersAndReports({ id: AccountService.getUserId() }).$promise;
		}

		self.createFolder = function (folder, type = null, reqWidth, reqHeight) {
			return SbUser.folders.create({ id: AccountService.getUserId() }, { name: folder, type: type, reqWidth: reqWidth, reqHeight: reqHeight }).$promise;
		}
		self.updateFolderName = function (id,name) {
			return Folder.prototype$updateAttributes({ id: id }, { name: name }).$promise;
		}

		self.updateDocument = function (id, fk, newData, fieldToUpdate, doConfirm) {
			const toUpdate = {};
			toUpdate[fieldToUpdate] = newData;

			const del = function() {
				return Folder.files.updateById({ id: id, fk: fk }, toUpdate).$promise;
			}

			if (doConfirm) {
				return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FILE');
			}
			else {
				return del();
			}
		}

		self.getDocument = function (id, fk) {
			return Folder.files.findById({ id: id, fk: fk }).$promise;
		}

		self.deleteFolder = function (fk) {
			const del = function() {
				return SbUser.folders.destroyById({ id: AccountService.getUserId(), fk: fk }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FOLDER');
		}

		self.deleteDocument = function (id, fk, isGenerated,agencyFolder) {
			const del = function() {
				if (isGenerated && ! agencyFolder) {
					return SbUser.files.destroyById({ id: AccountService.getUserId(), fk: fk }).$promise;
				}
				else {
					return Folder.files.destroyById({ id: id, fk: fk }).$promise;
				}
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FILE');
		}

		self.generateLastMonthListingsByBroker = function (start, end) {
			end = getISODate(end, true);
			return Agency.generateLastMonthListingsByBroker({ id: AccountService.getAgencyId(), start: start, end: end, lang: $rootScope.language || 'EN' }).$promise;
		}

		self.generateLastMonthSalesByBroker = function (start, end) {
			end = getISODate(end, true);
			return Agency.generateLastMonthSalesByBroker({ id: AccountService.getAgencyId(), start: start, end: end, lang: $rootScope.language || 'EN' }).$promise;
		}

		self.generateSalesByRegion = function (start, end) {
			end = getISODate(end, true);
			return Agency.generateSalesByRegion({ id: AccountService.getAgencyId(), start: start, end: end, lang: $rootScope.language || 'EN' }).$promise;
		}

		self.generateVisitsByBroker = function(start, end, listingId, brokerId) {
			end = getISODate(end, true);
			return Agency.generateVisitsByBroker({ id: AccountService.getAgencyId(), fk: brokerId, start: start, end: end, listingId: listingId, lang: $rootScope.language || 'EN' }).$promise;
		}

		self.generateListingExpiryReport = function (start, end) {
			end = getISODate(end, true);
			return Agency.generateListingExpiryReport({ id: AccountService.getAgencyId(), start: start, end: end, lang: $rootScope.language || 'EN' }).$promise;
		}

		self.getExpiredBetweenDates = function (start, end, brokerId) {
			end = getISODate(end, true);
			return Agency.getExpiredBetweenDates({ id: AccountService.getAgencyId(), fk: brokerId, start: start, end: end, lang: $rootScope.language || 'EN' }).$promise;
		}

		self.generateNewBuyersByDate = function(start, end) {
			return Broker.generateNewBuyersByDate({ id: AccountService.getBrokerId(), start: start, end: end, lang: $rootScope.language }).$promise;
		}

		self.generateYTDListings = function () {
			return Agency.generateYTDListings({ id: AccountService.getAgencyId(), lang: $rootScope.language || 'EN' }).$promise;
		}

		self.getListingsWithNoSeller = function () {
			return Broker.getListingsWithNoSeller({ id: AccountService.getBrokerId() }).$promise;
		}

		self.getListingsYTD = function () {
			return Agency.getListingsYTD({ id: AccountService.getAgencyId() }).$promise;
		}

		self.getListingsWithMissingDocuments = function () {
			return Broker.getListingsWithMissingDocuments({ id: AccountService.getBrokerId() }).$promise;
		}

		self.getBuyersByPropertyTypes = function(start, end, propertyTypes) {
			if(!Array.isArray(propertyTypes)) propertyTypes = [propertyTypes];
			return Broker.getNewBuyersByPropertyType({ id: AccountService.getBrokerId(), startDate: start, endDate: end, propertyTypes: propertyTypes, lang: $rootScope.language }).$promise;
		}

		/**
         *  Creates a hit stats report based on info we have in the DB.
         *  @param  startDate   Date    Start date for report
         *  @param  endDate     Date    End date for report
         *  @return             Object  Object containing array of results by listing
         */
		self.getHitsReport = function(startDate, endDate) {
			return Broker.getHitsReport({ id: AccountService.getBrokerId(), startDate: startDate, endDate: endDate }).$promise;
		}

		/**
         *  Returns email text language for the hits report sent to a seller.
         *  Should probably move to DB or somewhere else at some point.
         */
		self.getHitsEmailTexts = function() {
			return {
				EN: {
					subject: 'Digital Marketing Statistics',
					salutation: 'Dear',
					chunks: [
						'Included here, for your interest, a report of the activity on your property on the BROKER website for the past month.',
						'To view your listing, please click on the link below.'
					],
					end: 'Sincerely',
					signature: 'and Team',
					unsubscribe: 'If you no longer wish to receive emails please reply to this email with “Unsubscribe” in the subject line. Thank you.'
				},
				FR: {
					subject: 'Statistiques numériques de marketing',
					salutation: 'Cher',
					chunks: [
						'Veuillez trouver ci-dessous, pour votre information, un rapport d\'activité pour votre inscription sur le site Web de BROKER pour le mois passé.',
						'Pour afficher votre inscription, s\'il vous plaît cliquer sur le lien ci-dessous.'
					],
					end: 'Bien à vous',
					signature: 'et son Équipe',
					unsubscribe: 'Si vous ne souhaitez plus recevoir de courriels, s\'il vous plaît répondez à ce message avec la mention "Désabonnement" dans la case objet. Merci.'
				}
			}
		}

		self.sendHitsReportEmail = function(sId, report) {
			return Seller.sendReport({ sellerId: sId, report: report, brokerId: AccountService.getBrokerId() }).$promise;
		}

		function getISODate(date, lastMinute, firstMinute, notUTC) {
			let ret;

			if (date.getFullYear) {
				const str = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
				ret = moment.utc(str, 'YYYY-MM-DD');
			}
			else {
				ret = moment.utc(date);
			}

			if (notUTC) {
				ret.local();
			}

			if (lastMinute) {
				ret.endOf('day');
			}
			else if (firstMinute) {
				ret.startOf('day');
			}

			return ret.toISOString();
		}
	});
})(); // End of function()
