(function() {

	"use strict";

	angular.module("smartbrokr")
	.controller("NotesModalController", function($filter, $scope,$uibModalInstance,$rootScope, AccountService, AlertService, BuyerService,SellerService,SupplierService,ListingService,BrokerService,UserService,note) {

		var self = this;

		// VARIABLES ============================================================================================================================

		var loadBuyers;				// False while reading from DB, true when done
		var loadSellers;
		var loadListings;
		var loadSuppliers;
		var loadBrokers;

		var role;

		var headerLabels;					// Translatable parts of the header
		var filter;							// Used to query the DB

		$scope.loadingHeaders	= true;		// False when loading is done
		self.noteAbout			= null;		// What the current note is about (if user selects more than one person, this is where they know what they are selecting)
		self.headers			= null;		// All possible headers (based on data from DB)
		self.selected			= null;		// Selected item from headers list (is pushed to noteAbout)
		self.lockHeader			= false;	// If note is being edited or has a header already, user cannot select a new one
		self.title				= null;		// Title shown by the modal
		self.note				= null;		// Note that will be created/edited

		// SETUP VARIABLES ======================================================================================================================

		var checkLoading = function() {
			if (loadBuyers && loadSellers && loadListings && loadSuppliers && loadBrokers) {
				$scope.loadingHeaders = false;
			}
		}

		role			= AccountService.getRole();
		loadBuyers 		= false;
		loadSellers 	= false;
		loadListings 	= false;
		loadSuppliers 	= false;
		loadBrokers 	= false;

		$scope.loadingHeaders = true;

		headerLabels = {
			broker: 	$filter('translate')('ROLES.BROKER'),
			buyer: 		$filter('translate')('ROLES.BUYER'),
			listing: 	$filter('translate')('LISTING.LISTING'),
			seller: 	$filter('translate')('ROLES.SELLER'),
			supplier: 	$filter('translate')('ROLES.SUPPLIER')
		}

		filter = {
			include: {
				property: 'address'
			}
		}

		self.noteAbout 	= [];
		self.headers 	= [];
		
		if (note == null) {
			self.note = {
	      		header: "",
	     		text: 	""
	    	};
			self.title = '+ ' + $filter('translate')('NOTES_LOG.NEW_NOTE');
			self.lockHeader = false;
		}
		else {
			self.note = note;
			self.selected = note.header;

			if (note.id) {
				self.title = $filter('translate')('NOTES_LOG.EDIT_NOTE');
			}
			else {
				self.title = '+ ' + $filter('translate')('NOTES_LOG.NEW_NOTE');
			}
			self.lockHeader = true;
		}

		$uibModalInstance.check = {
			original: {
				note: angular.copy(self.note),
				noteAbout: angular.copy(self.noteAbout)
			},
			clone: {
				note: self.note,
				noteAbout: self.noteAbout
			}
		}

		$uibModalInstance.scope = $scope;

		if (!self.lockHeader) {

			if ( role !== 'agencyOwnerProfile' && role !== 'agencyMemberProfile' ) {
				// Get data from DB and setup headers
				BuyerService.getBuyers().then(function(res) {
					var buyers = res;
					var i, length;
					length = buyers.length;

					for (i = 0; i < length; i++) {
						if (buyers[i].user) {
							self.headers.push({
								header: 		headerLabels.buyer + ": " + ((buyers[i].user || {}).fullName || 'N/A'),
								aboutId: 		buyers[i].user.id,
								aboutType: 	'SbUser'
							});
						}
					}
				})
				.catch(function(err){})
				.finally(function(){
					loadBuyers = true;
					checkLoading();
				})

				SellerService.getSellers().then(function(res){
					var sellers = res;
					var i, length;
					length = sellers.length;

					for (i = 0; i < length; i++) {
						if (sellers[i].user) {
							self.headers.push({
								header: 		headerLabels.seller + ": " + ((sellers[i].user || {}).fullName || 'N/A'),
								aboutId: 		sellers[i].user.id,
								aboutType: 	'SbUser'
							});
						}
					}
				})
				.catch(function(err){ console.error(err);})
				.finally(function(){
					loadSellers = true;
					checkLoading();
				})

				ListingService.getMLS(filter).then(function(res){
					ListingService.getSmart(filter).then(function(res1){
						var listings = [].concat(res).concat(res1);
						var i, length;
						length = listings.length;

						for (i = 0; i < length; i++) {
							self.headers.push({
								header: 		headerLabels.listing + ": " + listings[i].id,
								address: 		(((listings[i].property || {})._address || {}).addressString || ""),
								aboutId: 		listings[i].id,
								aboutType: 	'Listing'
							});
						}
					})
					.catch(function(err){})
					.finally(function(){
						loadListings = true;
						checkLoading();
					})
				})
				.catch(function(err){
					loadListings = true;
					checkLoading();
				})
			}
			else {
				loadListings = true;
				loadSellers = true;
				loadBuyers = true;
			}

			SupplierService.getSuppliers().then(function(res){
				var suppliers = res;
				var i, length;
				length = suppliers.length;

				for (i = 0; i < length; i++) {
					self.headers.push({
						header: 	headerLabels.supplier + ": " + suppliers[i].company || 'N/A',
						hidden:		((suppliers[i].industry || {}).labels || {})[$rootScope.language || 'EN'] || ((suppliers[i].industry || {}).labels || {})['EN'],
						aboutId: 	suppliers[i].ownerId,
						aboutType: 	'SbUser'
					});
				}
			})
			.catch(function(err){})
			.finally(function(){
				loadSuppliers = true;
				checkLoading();
			})

			BrokerService.getBrokers().then(function(res) {
				var brokers = res;
				var i, length;
				length = brokers.length;

				for (i = 0; i < length; i++) {
					if (brokers[i].user) {
						self.headers.push({
							header: 		headerLabels.broker + ": " + brokers[i].user.fullName,
							aboutId: 		brokers[i].user.id,
							aboutType: 	'SbUser'
						});
					}
				}
			})
			.catch(function(err){})
			.finally(function(){
				loadBrokers = true;
				checkLoading();
			})
		}
		else {
			loadBuyers = true;
			loadSellers = true;
			loadSuppliers = true;
			loadListings = true;
			loadBrokers = true;
			checkLoading();
		}

		// FUNCTIONS ============================================================================================================================

		self.onSelect = function($item, $model, $label) {
			self.headers.splice(self.headers.indexOf($item),1);
			self.noteAbout.push($item);
			self.selected = null;
		}

		self.deleteAbout = function(about) {
			self.headers.push(about);
			self.noteAbout.splice(self.noteAbout.indexOf(about),1);
		}

		self.close = function () {
			$uibModalInstance.dismiss('cancel');
		};

    	self.saveNote = function() {

	  		var note = self.note;
			var noteAbout = self.noteAbout;

			if (note.text.length > 0) {
				if (note.created == null) {
					note.created = new Date();
				}

				if (noteAbout.length > 0) {

					delete note.aboutId;

					var notesToSave = [];

					for (var i=0; i < noteAbout.length; i++) {
						var newNote = angular.copy(note);
						newNote.aboutId = noteAbout[i].aboutId;
						newNote.aboutType = noteAbout[i].aboutType;
						newNote.header = noteAbout[i].header;

						if (!!noteAbout[i].address) {
							newNote.address = noteAbout[i].address;
						}
						notesToSave.push(newNote);
					}

					UserService.createManyNotes(notesToSave).then(function(res){
						AlertService.success('NOTES_LOG.NOTES','create', true);
						$uibModalInstance.close(res);
					})
					.catch(function(err){})
				}
				else {
					UserService.saveNote(note).then(function(res){
						AlertService.success('NOTES_LOG.NOTE','save');
						$uibModalInstance.close(res);
					})
					.catch(function(err){})
				}
			}
    	};

		self.searchHeader = function(value) {
			var headers = self.headers;
			value = value.toLowerCase();

			function includes(str1, str2) {
				str1 = str1 || '';
				str2 = str2 || '';
				return str1.toLowerCase().includes(str2);
			}

			var ret = headers.reduce(function(arr, item) {
				if (includes(item.header, value) ||
					includes(item.address, value) ||
					includes(item.hidden, value)) {
					arr.push(item);
				}

				return arr;
			}, []);

			return ret.sort();
		}

	}) // End of controller
})(); // End of function()
