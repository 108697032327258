/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/**
 *	main.buyers page
 */
(function () {
	'use strict';
	angular.module('smartbrokr')
	.controller('BuyerController', function ($rootScope, $filter, $sce, $scope, AccountService, AlertService, BuyerService, ModalService, FileService, GlobalVars, SortingService, StorageService, UserService) {

		const self = this;

		// VARIABLES ============================================================================================================================
		self.buyers			= [];		// Buyers that will be shown -> Is modified by filters
		self.filters		= null;		// Possible filters
		self.language		= [];		// Items to shown in the dropdown menu for the language filter
		self.propertyTypes	= [];		// Possible property types
		self.singleConfig	= null;		// Selectize dropdown menu config

		self.columns		= [];		// Columns for list view
		self.cardView		= null;		// Whether to show card view or not
		self.sortInfo		= null;		// Info for sorter

		let dropdowns 		= {};

		// SETUP VARIABLES ======================================================================================================================

		const page = 'buyers';

		dropdowns 	= {
			config: GlobalVars.singleConfig,
			languages: [],
			propertyTypes: []
		}

		self.filters	= SortingService.getPageFilter(page);

		if (!self.filters) {
			self.filters = SortingService.createPageFilter(
				page,
				{
					firstName: { weight: 2, value: '', desc: -1 },
					lastName: { weight: 1, value: '', desc: 1 },
					emailEnabled: { weight: 0, value: '', desc: 1 },
					preApproved: { weight: 0, value: '', desc: 1 },
					seekRental: { weight: 0, value: '', desc: 1 },
					cities: {
						weight: 0,
						value: '',
						desc: 1,
						array: true,
						sub: 'name',
						filterFunction: (item, entry) => {
							if(!entry.buyerCities || entry.buyerCities.length == 0) return false;
							const foundEntry = entry.buyerCities.find((value) => {
								if(value.city.name.includes(item)) {
									return value;
								}
							})
							return foundEntry != null;
						}
					},
					propertyTypeIds: { weight: 0, value: null, desc: 1, array: true },
					minPrice: {
						weight: 1,
						value: null,
						desc: 1,
						num: true,
						filterFunction: (item, entry) => {
							return item == null || entry.minPrice >= item;
						}
					},
					maxPrice: {
						weight: 1,
						value: null,
						desc: 1,
						num: true,
						filterFunction: (item, entry) => {
							return item == null || entry.maxPrice <= item;
						}
					},
					languageIds: { weight: 0, value: null, desc: 1, array: true, sortFunction: _getLangField },
					phones: { weight: 0, value: '', desc: 1, phone: true },
					email: { weight: 0, value: '', desc: 1 },
					city: { weight: 0, value: '', desc: 1, sub: 'name' },
					postalCode: { weight: 0, value: '', desc: 1 },
				}
			);
		}

		self.columns = [];
		self.cardView = StorageService.getView(page) == 'card';

		self.sortInfo = {
			page: page,
			sub: [ 'user', 'user._address' ]
		}

		self.navParams = {
			state: 'main.profile',
			getParams: function(entry) {
				if (entry) {
					return { id: entry.id, role:'buyer' };
				}
				return {};
			}
		}

		getBuyers();

		// FUNCTIONS ============================================================================================================================

		// Export csv
		self.exportBuyersCsv = function () {
			const toExport = $filter('searchMultiFilter')(self.buyers, self.filters);
			FileService.exportBuyers(toExport);
		};

		self.bulkEmail = function() {
			if(!AccountService.limitedAccess()) {
				UserService.processBulkEmails($filter('searchMultiFilter')(self.buyers, self.filters));
			}
		}

		/**
		*	Opens modal to confirm deletion of buyer.
		*	After modal is closed, updates view.
		*	@param 		buyer 		Entry from list view representing the buyer to be deleted
		*/
		self.deleteBuyer = function(buyer) {

			const retFunction = function(res) {
				getBuyers();
			}
			ModalService.openModalWithFunction( 'deleteUser', {
				id: function () { return buyer.id },
				user: function () { return buyer.user; },
				deleteFunction: function () { return BuyerService.deleteBuyer; },
				userType: function () { return 'buyer' }
			},
			'DeleteUserController',
			'deleteUserController',
			retFunction
			);
		}

		function getBuyers() {
			AlertService.loading();
			BuyerService.getBuyers()
			.then((res) => {
				self.buyers = res;

				const aux = $filter('unique')(self.buyers, 'user.languages');
				dropdowns.languages = aux.reduce((arr, curr) => {
					arr.push({ name: $filter('translate')('LANGUAGES.' + curr.id), value: curr.id });
					return arr;
				}, [{ name: '------', value: '' }])

				dropdowns.propertyTypes = GlobalVars.getCodes('propertyTypes');

				self.columns = [
					{
						name: 'PERSON.FIRST_NAME',
						filter: 'firstName',
						cardView: true,
						size: 3,
						field: function(buyer) {
							return buyer.user.firstName;
						}
					},
					{
						name: 'PERSON.LAST_NAME',
						filter: 'lastName',
						cardView: true,
						size: 3,
						field: function(buyer) {
							return buyer.user.lastName;
						}
					},
					{
						name: 'EMAIL.EMAIL',
						filter: 'email',
						cardView: true,
						size: 4,
						isEmail: true
					},
					{
						name: 'PERSON.PHONE',
						filter: 'phones',
						size: 2,
						field: function(buyer) {
							return $filter('getOnePhone')(buyer.user.phones);
						}
					},
					// {
					// 	name: 'ADDRESS.CITY',
					// 	filter: 'city',
					// 	size: 2,
					// 	field: function(buyer) {
					// 		return (((buyer.user || {})._address || {}).city || {}).name;
					// 	}
					// },
					// {
					// 	name: 'ADDRESS.POSTAL_CODE',
					// 	filter: 'postalCode',
					// 	size: 2,
					// 	field: function(buyer) {
					// 		return ((buyer.user || {})._address || {}).postalCode;
					// 	}
					// },
					{
						name: 'ADDRESS.REGION',
						filter: 'cities',
						cardView: true,
						size: 3,
						isHtml: true,
						field: function(buyer) {
							if(buyer.cities) {
								const cities = (buyer.cities || []);
								return cities.reduce((str, curr, i) => {

									str += curr.name;

									if (i < cities.length - 1) {
										str += ',<br>';
									}

									return str;
								}, '');
							} else if (buyer.buyerCities) {
								const cities = (buyer.buyerCities || []);
								return cities.reduce((str, curr, i) => {

									str += curr.city.name;

									if (i < cities.length - 1) {
										str += ',<br>';
									}

									return str;
								}, '');
							}
							return '';
						}
					},
					{
						name: 'COMMON.TYPE',
						filter: 'propertyTypeIds',
						cardView: true,
						select: true,
						size: 3,
						isHtml: true,
						config: dropdowns.config,
						options: dropdowns.propertyTypes,
						field: function(buyer) {
							const types = buyer.propertyTypes || [];
							return types.reduce((str, curr, i) => {
								str += (curr.labels || {})[$rootScope.language || 'EN'] || (curr.labels || {})['EN'];

								if (i < types.length - 1) {
									str += ',<br>';
								}

								return str;
							}, '');
						}
					},
					{
						name: 'COMMON.MIN',
						filter: 'minPrice',
						cardView: true,
						size: 2,
						field: function(buyer) {
							return buyer.minPrice ;
						}
					},
					{
						name: 'COMMON.MAX',
						filter: 'maxPrice',
						cardView: true,
						size: 2,
						field: function(buyer) {
							return buyer.maxPrice;
						}
					},
					{
						name: 'PERSON.LANGUAGE',
						filter: 'languageIds',
						select: true,
						size: 2,
						config: dropdowns.config,
						options: dropdowns.languages,
						isHtml: true,
						cardView: true,
						field: _getLangField,
					},
					{
						nameImgClass: 'btn-list-icon do-not-email',
						nameImgPopover: 'btnDoNotEmail',
						filter: 'emailEnabled',
						noSearch: true,
						size: 1,
						class: 'check',
						style: { 'text-align': 'center' },
						isHtml: true,
						field: function(buyer) {
							return (buyer.user && buyer.user.email && buyer.user.email != '' && buyer.user.emailEnabled)  ? '' : '<div class="icon-checked"></div>';
						}
					},
					{
						nameImgClass: 'btn-list-icon pre-approved',
						nameImgPopover: 'btnPreApproved',
						filter: 'preApproved',
						noSearch: true,
						size: 1,
						class: 'check',
						style: { 'text-align': 'center' },
						isHtml: true,
						field: function(buyer) {
							return buyer.preApproved ? '<div class="icon-checked"></div>' : '';
						}
					},
					{
						nameImgClass: 'btn-list-icon seek-rental',
						nameImgPopover: 'btnSeekRental',
						filter: 'seekRental',
						noSearch: true,
						size: 1,
						class: 'check',
						style: { 'text-align': 'center' },
						isHtml: true,
						field: function(buyer) {
							return buyer.seekRental ? '<div class="icon-checked"></div>' : '';
						}
					},
					{
						name: '',
						noSort: true,
						noSearch: true,
						isClick: true,
						size: 1,
						clickFunction: self.deleteBuyer,
						field: function (buyer) {
							return $sce.trustAsHtml('<button class="btn btn-trash"></button>');
						}
					}
				];

				SortingService.sort(self.buyers, self.filters, null, ['user'], page, true);
			})
			.catch((err) => {
				console.error('ERR: ', err);
			})
			.finally(AlertService.doneLoading)

			// Export csv - Complete
			$scope.exportComplete = function () {
				self.isPopoverOpen = false;
				const exportFilter = self.filters;
				exportFilter.checked = { weight: 0, value: 'true', desc: true };
				const toExport = $filter('searchMultiFilter')(self.buyers, exportFilter);
				if(toExport.length > 0) {
					FileService.exportComplete(toExport, 'buyer');
				} else {
					const modal = ModalService.openModal('nothingToExport', { folder: function () { return {} }, type: function() { return '' }, options: function() { return { } } }, 'FolderController', 'folderController');
					modal.result
					.then((res) => {})
					.catch((err) => {});
				}
			};

			// Export csv - Simplified (first name, last name and email)
			$scope.exportSimple = function() {
				self.isPopoverOpen = false;
				const exportFilter = self.filters;
				exportFilter.checked = { weight: 0, value: 'true', desc: true };
				const toExport = $filter('searchMultiFilter')(self.buyers, exportFilter);
				if(toExport.length > 0) {
					FileService.exportSimple(toExport, 'ROLES.BUYER');
				} else {
					const modal = ModalService.openModal('nothingToExport', { folder: function () { return {} }, type: function() { return '' }, options: function() { return { } } }, 'FolderController', 'folderController');
					modal.result
					.then((res) => {})
					.catch((err) => {});
				}
			}

		}

		function _getLangField(buyer) {
			const langs = (((buyer || {}).user || {}).languages || []).reduce((arr, lang) => {
				arr.push('' + $filter('translate')('LANGUAGES.' + lang.id));
				return arr;
			}, []).sort();

			if (langs.length === 0) {
				return '---';
			}

			return langs.join('<br>');
		}
	})	// End of controller
})(); // End of function()
