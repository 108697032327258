/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/**
 *  Directives to be used as custom form validators,
 *  such as unique broker ID or unique account email.
 */


(function () {

	'use strict';

	angular.module('smartbrokr.directives')

	// Checks if an email is already registered in SmartBrokr
	.directive('uniqueEmail', ($q, AccountService) => {
		return {
			require: 'ngModel',
			link: function(scope, elm, attrs, ctrl) {

				let parent = null;
				if (attrs.parentController) {
					parent = scope.$parent[attrs.parentController];
				}

				ctrl.$asyncValidators.uniqueEmail = function(modelValue, viewValue) {

					if (ctrl.$isEmpty(modelValue) || !attrs.required) {     // Only check on instances where email is required
						return $q.resolve();
					}
					else if (attrs.uniqueEmail) {
						if (!scope.$eval(attrs.uniqueEmail)) {
							return $q.resolve();
						}
					}

					const def = $q.defer();

					AccountService.isEmailRegistered(modelValue).then((res) => {
						let current = null;

						if (attrs.getEmail && parent) {
							current = parent[attrs.getEmail]();
						}

						if (res.response === true && modelValue !== current) {
							def.reject();
						}
						else {
							def.resolve();
						}
					})
					return def.promise;
				};
			}
		};
	})

	// Checks if an agency code is already registered in SmartBrokr
	.directive('uniqueAgency', ($q, AgencyService) => {
		return {
			require: 'ngModel',
			link: function(scope, elm, attrs, ctrl) {

				let prefix = 'CAN_';

				if (attrs.uniqueAgency) {
					prefix += scope.$eval(attrs.uniqueAgency) + '_';
					prefix = prefix.toUpperCase();
				}

				ctrl.$asyncValidators.uniqueAgency = function(modelValue, viewValue) {
					if (ctrl.$isEmpty(modelValue)) {
						return $q.resolve();
					}

					const def = $q.defer();

					AgencyService.agencyExists(prefix + modelValue).then((res) => {
						if (res.isRegistered) {
							def.reject();
						}
						else {
							def.resolve();
						}
					})

					return def.promise;
				};
			}
		};
	})

	// Checks if a broker code is already registered in SmartBrokr
	.directive('uniqueBroker', ($q, BrokerService) => {
		return {
			require: 'ngModel',
			link: function(scope, elm, attrs, ctrl) {

				const isRealtor = scope.$eval(attrs.uniqueBroker) || false;
				const brokerId = scope.$eval(attrs.brokerId);

				ctrl.$asyncValidators.uniqueBroker = function(modelValue, viewValue) {

					if (ctrl.$isEmpty(modelValue)) {
						return $q.resolve();
					}

					const def = $q.defer();

					BrokerService.isBrokerRegistered(modelValue, isRealtor).then((res) => {
						if (res.isRegistered && res.brokerId !== brokerId) {
							def.reject();
						}
						else {
							def.resolve();
						}
					})

					return def.promise;
				};
			}
		};
	})

	.directive('urlField', () => {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function(scope, elm, attrs, ctrl) {
				ctrl.$parsers.push((value) => {

					if ((value || '').length === 0) return value;

					value = value || '';

					let http = 'http://';
					let https = 'https://';

					if (value.length < http.length) {
						http = http.substring(0, value.length - 1);

						if (value.length < https.length) {
							https = https.substring(0, value.length - 1);
						}
					}

					if (!value.startsWith(http) && !value.startsWith(https)) {  // Add http:// to value
						value = 'http://' + value;
						ctrl.$setViewValue(value);
						ctrl.$render();
					}

					return value;
				});
			}
		}
	})

	.directive('textField', () => {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function(scope, elm, attrs, ctrl) {
				ctrl.$parsers.push((value) => {
					value = value || '';
					return value.replace(/\n/g, '<br>');
				});

				ctrl.$formatters.push((value) => {
					value = value || '';
					return value.replace(/<br>/g, '\n');
				})
			}
		}
	})

	.directive('virtualTourUrl', () => {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function(scope, elm, attrs, ctrl) {
				ctrl.$validators.virtualTourUrl = function(modelValue, viewValue) {
					const value = modelValue || viewValue;

					if (!value) return true;

					const youtube = new RegExp(/^((http|https):\/\/){0,1}(((www\.){0,1}youtube\.com\/(watch\?v=(.)+|embed\/(.)+))|(youtu\.be\/(.)+))$/, 'i');
					const immoviewer = new RegExp(/^((http|https):\/\/){0,1}[A-z|0-9|\-\_]+\.immoviewer\.com\/.+$/, 'i');
					const mp4 = new RegExp(/^((http|https):\/\/){0,1}.+\.mp4$/, 'i');

					return value.match(youtube) !== null || value.match(immoviewer) !== null || value.match(mp4) !== null;
				}
			}
		}
	})

})();
