/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.user', [])
	.service('UserService', function (Agency, AgencyMember, AgencyOwner, Auditor, Broker, Buyer, Container, Notary, SbUser, Seller, Supplier, AccountService, ModalService, $rootScope, $q, $filter, $sce) {

		const self = this;

		let roleApi;
		let roleId;

		const setup = function() {
			const role = AccountService.getRole();

			switch(role) {
			case 'agencyMemberProfile':
				roleApi = AgencyMember;
				roleId = AccountService.getAgencyMemberId();
				break;
			case 'agencyOwnerProfile':
				roleApi = AgencyOwner;
				roleId = AccountService.getAgencyOwnerId();
				break;
			default:
				roleApi = Broker;
				roleId = AccountService.getBrokerId();
				break;
			}
		}

		self.upsert = function (user) {
			if (user) {
				user.fullName = user.firstName + ' ' + user.lastName;
				return SbUser.upsert(user).$promise;
			}
			else {
				return $q.when(!user);      // Empty promises D:
			}
		}

		self.getAllMine = function (noSuppliers, isEmail, isMin, where) {
			const options = {
				role: AccountService.getRole(),
				addSuppliers: !noSuppliers,
				isEmail: isEmail || false,
				isMin: isMin || false,
				where: where
			}

			// Current user is acting as another broker -> change role to broker
			if (options.role === 'adminProfile' || options.role === 'managerProfile') {
				options.role = 'brokerProfile';
			}

			return SbUser.getMyPeople({ id: AccountService.getUserId(), options: options }).$promise;
		}

		self.checkManyDuplicates = function(data) {
			return SbUser.checkManyDuplicates({ id: AccountService.getUserId(), data: JSON.stringify(data) }).$promise;
		}

		self.checkDuplicate = function(firstName, lastName) {
			const filter = {
				firstName: {
					like: firstName,
					options: 'i'
				},
				lastName: {
					like: lastName,
					options: 'i'
				}
			}

			return self.getAllMine(false, false, false, JSON.stringify(filter));
		}

		self.searchExistant = function(fullName) {
			const filter = {
				fullName: {
					like: fullName,
					options: 'i'
				}
			}

			return self.getAllMine(false, false, false, JSON.stringify(filter));
		}

		self.getAll = function (onlyActive) {
			return Agency.getPeople({ id: AccountService.getAgencyId(), onlyActive: onlyActive }).$promise;
		}

		self.getFullName = function (id, type) {
			// TODO: Maybe change to Model.findById
			let filter = {
				include: {
					relation: 'user',
					scope: {
						fields: ['id', 'fullName']
					}
				}
			}

			const role = AccountService.getRole();

			switch(type) {
			case 'buyer':
				return Buyer.findById({ id: id, filter: filter }).$promise;
			case 'seller':
				return Seller.findById({ id: id, filter: filter }).$promise;
			case 'supplier':
				filter.include = {};
				return Supplier.findById({ id: id, filter: filter }).$promise;
			case 'broker':
				if (role === 'adminProfile') {
					return Broker.findById({ id: id, filter: filter }).$promise;
				}

				filter = { where: { id: id }, include: 'user' };
				return Agency.brokers({ id: AccountService.getAgencyId(), filter: filter }).$promise;
			case 'mine':
				return new Promise(((resolve, reject) => {
					resolve($rootScope.currentUser);
				}))
			case 'member':
				if (role === 'adminProfile') {
					return AgencyMember.findById({ id: id, filter: filter }).$promise;
				}

				filter = { where: { id: id, disabled:{ neq:true } }, include: 'user' };
				return Agency.members({ id: AccountService.getAgencyId(), filter: filter }).$promise;
			case 'owner':
				if (role === 'adminProfile') {
					return AgencyOwner.findById({ id: id, filter: filter }).$promise;
				}

				filter = { where: { id: id,disabled:{ neq:true } }, include: 'user' };
				return Agency.owners({ id: AccountService.getAgencyId(), filter: filter }).$promise;
			case 'auditor':
				if (role === 'adminProfile') {
					return Auditor.findById({ id: id, filter: filter }).$promise;
				}

				filter = { where: { id: id }, include: 'user' };
				return Agency.auditors({ id: AccountService.getAgencyId(), filter: filter }).$promise;
			case 'notary':
				if (role === 'adminProfile') {
					return Notary.findById({ id: id, filter: filter }).$promise;
				}

				filter = { where: { id: id }, include: 'user' };
				return Agency.notaries({ id: AccountService.getAgencyId(), filter: filter }).$promise;

			default: return $q.when(true);
			}
		}

		self.getPerson = function (id, role) {
			const filter = {
				include: []
			}

			if (role) {
				filter.include.push({
					relation: role + 'Profile',
					scope: {
						fields: [ 'id', 'sbUserId', '_coBuyer' ]
					}
				});
			}

			return SbUser.findById({ id: id, filter: filter }).$promise;
		}

		self.getNotaries = function () {
			return Agency.notaries({ id: AccountService.getAgencyId(), filter: { include: 'user' } }).$promise;
		}
		self.getAuditors = function () {
			return Agency.auditors({ id: AccountService.getAgencyId(), filter: { include: 'user' } }).$promise;
		}

		// Notes
		self.saveNote = function (note) {
			if (note.id) {
				return SbUser.notes.updateById({ id: AccountService.getUserId(), fk: note.id }, note).$promise;
			}
			else {
				return SbUser.notes.create({ id: AccountService.getUserId() }, note).$promise;
			}
		}

		self.createManyNotes = function (notes) {
			return SbUser.notes.createMany({ id: AccountService.getUserId() }, notes).$promise;
		}

		self.deleteNote = function (fk) {

			const delFunction = function() {
				return SbUser.notes.destroyById({ id: AccountService.getUserId(), fk: fk }).$promise
			}

			return ModalService.confirmDelete(delFunction, 'ALERT_MESSAGES.ALERT.DELETE_NOTE');
		}

		self.getUserId = function (rId, role) {
			const relation = role == 'supplier' ? 'owner' : 'user';
			const fields = role == 'supplier' ? ['ownerId', 'company'] : ['sbUserId'];
			const roles = role = (role || '').toLowerCase() + 's';
			const filter = {
				where: {
					or: [
						{ _id: rId },     // Loopback trolling
						{ id: rId }
					]
				},
				include: {
					relation: relation,
					scope: {
						fields: ['fullName']
					}
				},
				fields: fields,
				limit: 1
			};

			setup();

			return roleApi[roles]({ id: roleId, filter: filter }).$promise
			.then((res) => {
				if ((res || []).length == 1) {
					return {
						id: ((res[0] || {})[relation] || {}).id,
						fullName: ((res[0] || {})[relation] || {}).fullName,
						company: (res[0] || {}).company || null
					};
				}
				else {
					return {};
				}
			})
			.catch((err) => {
				return 'ERROR';
			})
		}

		// Logs
		/*self.createLog = function (log) {
            return SbUser.logs.create({ id: AccountService.getUserId() }, log).$promise;
        }*/

		// Languages
		self.getLanguages = function () {
			$rootScope.$emit('loadingStart');
			return SbUser.languages({ id: AccountService.getUserId() }).$promise;
		}

		self.saveLanguages = function (ids, rmIds) {
			const promises = [];
			const rmPromises = [];
			const newPromise = function (fk, bool) {
				return $q((resolve, reject) => {
					SbUser.languages.findById({ id: AccountService.getUserId(), fk: fk }).$promise
					.then((res) => {
						if (bool) {
							resolve(res);
						}
						else {
							SbUser.languages.unlink({ id: AccountService.getUserId(), fk: fk }, null).$promise
							.then((res) => {
								resolve(res);
							})
							.catch((err) => {
								reject(err);
							});
						}

					})
					.catch((err) => {
						if (bool) {
							SbUser.languages.link({ id: AccountService.getUserId(), fk: fk }, null).$promise
							.then((res) => {
								resolve(res);
							})
							.catch((err) => {
								reject(err);
							});
						}
						else {
							reject(err);
						}
					});
				});
			}
			for (const i in ids) {
				promises.push(newPromise(ids[i], true));
			}
			for (const i in rmIds) {
				rmPromises.push(newPromise(rmIds[i], false));
			}
			return $q.all(promises);
		}

		// Roles
		self.getUsersAndRoles = function () {
			$rootScope.$emit('loadingStart');
			return SbUser.find({ filter: { fields: ['fullName', 'id', 'photoUrl'], include: 'roles' } }).$promise;
		}

		self.toggleRole = function (sbUserId, updates) {
			return Agency.toggleUserRole({ sbUserId: sbUserId, updates: updates, id: AccountService.getAgencyId() }).$promise;
			//return SbUser.roles.link({ id: id, fk: fk }, null).$promise;
		}

		// Managers
		self.getManagers = function () {
			return SbUser.managers({ id: AccountService.getUserId(), filter: { include: ['manager', 'clients', { listings: 'property' }] } }).$promise;
		}

		self.createManager = function (manager) {
			manager.managerId = manager.managerId;
			//manager.managingId = AccountService.getUserId();
			return SbUser.managers.create({ id: AccountService.getUserId() }, manager).$promise;
		}

		self.readManager = function (fk) {
			return SbUser.managers.findById({ id: AccountService.getUserId(), fk: fk }).$promise;
		}

		self.updateManager = function (fk, manager) {
			return SbUser.managers.updateById({ id: AccountService.getUserId(), fk: fk }, manager).$promise;
		}

		self.deleteManager = function (fk) {
			const del = function() {
				return SbUser.managers.destroyById({ id: AccountService.getUserId(), fk: fk }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_MANAGER');
		}

		// Teams
		self.getTeams = function () {
			return new Promise(((resolve) => {
				resolve();
			}));
			// return Team.find({ filter: { include: [{ listings: 'property' }, 'clients', 'members'] } }).$promise;
		}

		self.createTeam = function (team) {
			return new Promise(((resolve) => {
				resolve();
			}));
			// return Team.create(team).$promise;
		}
		self.readTeam = function (id) {
			return new Promise(((resolve) => {
				resolve();
			}));
			// return Team.findById({ id: id }).$promise;
		}
		self.updateTeam = function (id, team) {
			return new Promise(((resolve) => {
				resolve();
			}));
			// return Team.prototype$updateAttributes({ id: id }, team).$promise;
		}
		self.deleteTeam = function (id) {
			return new Promise(((resolve) => {
				resolve();
			}));
			// return Team.destroyById({ id: id }).$promise;
		}

		self.deleteFolder = function(folder) {
			return SbUser.folders.destroyById({ id: AccountService.getUserId(), fk: folder }).$promise;
		}

		self.deleteFiles = function(files) {
			const fileIds = [];
			for(let i = 0; i < files.length; i++) {
				fileIds.push(files[i].id);
			}
			return SbUser.files.destroyAll({ id: AccountService.getUserId() }, { where: { id: { inq: fileIds } } }).$promise;
		}

		// Deletes a file -> Used with email modal
		self.deleteFile = function (file) {
			if (!file.id) {
				return Container.removeFile({ container: 'user', file: file.name }).$promise;
			}
			else {
				return SbUser.files.destroyById({ id: AccountService.getUserId(), fk: file.id }).$promise;
			}
		}

		self.sendBulkLogs = function(options, logs) {
			logs = logs || [];
			const logIds = logs.reduce((arr, item) => {
				if (item) {
					arr.push(item.id);
				}
				return arr;
			}, [])

			return SbUser.bulkActionLog({ id: AccountService.getUserId(), options: options, logIds: logIds, lang: ($rootScope.language || 'EN') }).$promise;
		}

		self.sendEmail = function (options) {
			return SbUser.sendEmail({ id: AccountService.getUserId(), options: options }).$promise;
		}

		self.getImagesForVideo = function () {
			return SbUser.resources({ id: AccountService.getUserId(), filter: { where: {} } }).$promise;
		}

		self.deleteUser = function (id) {
			return SbUser.destroyById({ id: id }).$promise;
		}

		self.getSentEmail = function (id = null) {
			const filter = { where: { sent: true }, include: 'toUser', order: 'created DESC' };
			return SbUser.sentEmails({ id: id ? id : AccountService.getUserId(), filter: filter }).$promise;
		}

		self.getLogs = function (id) {
			return SbUser.logsAbout({ id: id, filter: { order: 'created DESC', include: 'user' } }).$promise;
		}

		self.getBuyerLogs = function (id) {
			return Buyer.logsAbout({ id: id, filter: { order: 'created DESC', include: 'user' } }).$promise;
		}

		self.getSellerLogs = function (id) {
			return Seller.logsAbout({ id: id, filter: { order: 'created DESC', include: 'user' } }).$promise;
		}

		self.getSupplierLogs = function (id) {
			return Supplier.logsAbout({ id: id, filter: { order: 'created DESC', include: 'user' } }).$promise;
		}

		self.updatePerson = function (person) {
			return SbUser.prototype$updateAttributes({ id: person.id }, person ).$promise;
		}

		self.getMenuSearch = function(searchTerm, type) {
			setup();
			return roleApi.getMenuSearchCount({ id: roleId, search: searchTerm, type: type }).$promise;
		}

		self.getMenuSearchDetails = function(results) {
			setup();
			return roleApi.getMenuSearchDetails({ id: roleId, results: results }).$promise;
		}

		self.getBasicSearch = function(searchTerm, type, filter) {
			setup();
			return roleApi.getBasicSearch({ id: roleId, search: searchTerm, type: type, filter: filter }).$promise;
		}

		self.assignFileToFolder = function(fk, folderId) {
			return SbUser.assignFileToFolder({ id: AccountService.getUserId(), fk: fk, folderId: folderId }).$promise;
		}

		self.getOffices = function() {
			return SbUser.getOfficesFromAgency({ id: AccountService.getUserId() }).$promise;
		}

		self.sendBulkEmails = function(options) {
			return SbUser.sendEmails({ id: AccountService.getUserId(), options: options }).$promise;
		}

		self.processBulkEmails = function(users, userKey = 'user') {

			const emailNotSent = [];
			const receivers = [];
			const receiverIds = [];
			const receiverEmails = [];

			function doSendEmailModal() {
				const resolve = {
					params: function() {
						return {
							receiverIds: receiverIds,
							receivers: receivers || null,
							emails: receiverEmails,
							isBulk: true
						}
					}
				}
				ModalService.openModal('sendEmail', resolve, 'SendEmailController', 'sendEmailController', null, 'email-modal');
			}

			function doEmailNotEnabledModal(emailNotSent, callback) {
				if(emailNotSent.length > 0) {
					let msg = $filter('translate')('ALERT_MESSAGES.ALERT.APPT_NO_EMAIL');
					msg = $sce.valueOf(msg);
					msg += '<ul style="list-style: disc outside; padding-left: 18px">';
					emailNotSent.forEach((name) => {
						msg += '<li>' + name + '</li>';
					});
					msg += '</ul>';
					const mod = ModalService.prompt(msg, null, null, 'Ok', null, true);
					mod.result.then((res) => {
						if(callback) callback();
					})
					.catch((err) => {})
					.finally(() => {
					});
				} else {
					if(callback) return callback();
				}
			}

			users.forEach(element => {
				if(element.checked && element[userKey]) {
					if(element[userKey].emailEnabled && element[userKey].email && element[userKey].email != '') {
						receivers.push(element);
						receiverIds.push(element[userKey].id);
						receiverEmails.push(element[userKey].email);
					} else {
						emailNotSent.push(element[userKey].fullName);
					}
				}
			});

			doEmailNotEnabledModal(emailNotSent, () => {
				if (receiverIds.length > 0 && receivers.length > 0) {
					doSendEmailModal();
				}
			})
		}

	})  // End of service
})();
