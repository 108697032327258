/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/**
 *	main.brokers
 */

(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('BrokerController', function ($filter, $sce, $scope, AccountService, AlertService, BrokerService, FileService, GlobalVars, NavService, ModalService, SortingService, StorageService, UserService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.mobileSearch	= '';		// Search text for mobile version
		self.filters		= null;		// Possible filters
		self.brokers		= null;		// All brokers to be shown -> Can be changed by filter
		self.role			= null;		// User's current role
		self.cardView		= null;		// Whether to show card of list view

		self.navParams		= null;		// Navigation parameters to pass to list-view directive
		self.sortInfo		= null;		// Sort info to send to list-view directive
		self.checkInfo 		= {};		// Check info to send to list-view directive
		self.legends 		= null;		// Path for broker status legends popover

		let dropdowns 		= {};		// Drodowns options and config

		// SETUP VARIABLES ======================================================================================================================

		const page 	= 'brokers';

		self.role 			= AccountService.getRole();
		// self.filters 		= SortingService.getPageFilter(page);
		self.cardView 		= StorageService.getView(page) == 'card';
		self.legends 		= GlobalVars.popover('brokerStatusLegends');

		dropdowns = {
			config: GlobalVars.singleConfig,
			status: [
				{ name: '------', value: 'xxx' },
				{ name: $filter('translate')('COMMON.SB_USER'), value: 'SB_USER' },
				{ name: $filter('translate')('COMMON.ACTIVE'), value: 'ACTIVE' },
				{ name: $filter('translate')('COMMON.INACTIVE'), value: 'INACTIVE' }
			],
			langs: [],
			cities: []
		}

		if (!self.filters) {
			self.filters = SortingService.createPageFilter(
				page,
				{
					firstName: { weight: 2, value: '', desc: -1 },
					lastName: { weight: 1, value: '', desc: -1 },
					status: { weight: 0, value: '', desc: -1, exact: true },
					email: { weight: 0, value: '', desc: -1 },
					phones: { weight: 0, value: '', desc: -1, phone: true },
					languageIds: { weight: 0, value: null, desc: 1, array: true, sortFunction: _getLangField },
					currentCourses: { weight: 0, value: null, desc: 1, array: true },
					regionsCovered: { weight: 0, value: null, desc: 1, array: true },
					city: { weight: 0, value: '', desc: 1 },
					postalCode: { weight: 0, value: '', desc: 1 },
					office: { weight: 0, value: '', desc: 1 },
					emailEnabled: { weight: 0, value: '', desc: 1 },
				}
			);
		}

		self.sortInfo = {
			page: page,
			sub: ['user']
		}

		self.checkInfo = {
			allChecked: false,
			nSelected: 0
		}

		self.navParams = {
			state: 'main.broker.account',
			getParams: function(entry) {
				if (entry) {
					return { id: entry.id };
				}
				return {};
			}
		}

		self.bulkEmail = function() {
			if(!AccountService.limitedAccess()) {
				UserService.processBulkEmails($filter('searchMultiFilter')(self.brokers, self.filters));
			}
		}

		getBrokers();

		// FUNCTIONS ============================================================================================================================

		/** Opens modal to send a new message through smartbrokr */
		self.messageBroker = function () {
			let brokers, isSelf, toMessage, cantReceive;
			const userId = AccountService.getUserId();

			if (!AccountService.limitedAccess()) {
				brokers 	= $filter('searchMultiFilter')(self.brokers, self.filters);
				isSelf 		= false;
				cantReceive = '';
				toMessage = brokers.reduce((arr,curr) => {
					if (curr.checked) {
						if (curr.sbUserId === userId) {
							isSelf = true;
						}
						else if (curr.status !== 'SB_USER') {
							cantReceive += (curr.user || {}).fullName + '<br>';
						}
						else {
							arr.push(curr.sbUserId);
						}
					}

					return arr;
				}, []);

				cantSelf();
			}

			function cantSelf() {
				if (!isSelf) return wontMessage();

				const m = ModalService.prompt('MESSAGES.ERRORS.SELF_MSG', null, 'Ok', null, null, true);

				m.result.then((res) => {})
				.catch((err) => {})
				.finally(() => {
					wontMessage();
				})
			}

			function wontMessage() {
				if (cantReceive === '' || !cantReceive) return modal();

				const m = ModalService.prompt($filter('translate')('MESSAGES.ERRORS.NOT_SBUSER.TEXT', { names: cantReceive }), 'MESSAGES.ERRORS.NOT_SBUSER.TITLE', 'Ok', null, null, true);
				m.result.then((res) => {})
				.catch((err) => {})
				.finally(() => {
					modal();
				})
			}

			function modal() {
				if (toMessage.length > 0) {
					ModalService.openModal('newMessage', {
						message: function() { return null; },
						sender: function() { return null; },
						receiver: function() { return toMessage; }
					}, 'MessageModalController', 'messageModalController');
				}
			}
		}

		self.createBroker = function () {
			if (!AccountService.limitedAccess()) {
				NavService.navigate('main.broker.edit', { id: null });
			}
		}

		function getBrokers() {
			AlertService.loading();
			BrokerService.getBrokers()
			.then((res) => {
				self.brokers = res;

				let aux = $filter('unique')(self.brokers, 'user.languages');
				dropdowns.langs = aux.reduce((arr, lang) => {
					arr.push({ name: $filter('translate')('LANGUAGES.' + lang.id), value: lang.id });
					return arr;
				}, [{ name: '------', value: 'xxx' }]);

				aux = $filter('unique')(self.brokers, 'user._address.city');
				dropdowns.cities = aux.reduce((arr, city) => {
					arr.push({ name: city.name, value: city.id });
					return arr;
				}, [{ name: '------', value: 'xxx' }]);

				self.columns = [
					{
						name: 'PERSON.FIRST_NAME',
						filter: 'firstName',
						cardView: true,
						size: 2,
						field: function(broker) {
							return broker.user.firstName;
						}
					},
					{
						name: 'PERSON.LAST_NAME',
						filter: 'lastName',
						cardView: true,
						size: 2,
						field: function(broker) {
							return broker.user.lastName;
						}
					},
					{
						name: 'COMMON.STATUS',
						filter: 'status',
						select: true,
						size: 1,
						config: dropdowns.config,
						options: dropdowns.status,
						field: function(broker) {
							return $filter('translate')('COMMON.' + broker.status);
						}
					},
					{
						name: 'PERSON.PHONE',
						filter: 'phones',
						size: 2,
						field: function(broker) {
							return $filter('getOnePhone')(broker.user.phones);
						}
					},
					{
						name: 'EMAIL.EMAIL',
						filter: 'email',
						size: 2,
						isEmail: true
					},
					{
						name: 'PERSON.LANGUAGE',
						filter: 'languageIds',
						select: true,
						size: 1,
						config: dropdowns.config,
						options: dropdowns.langs,
						cardView: true,
						isHtml: true,
						field: _getLangField
					},
					{
						name: 'PERSON.BROKER.COURSES',
						filter: 'currentCourses',
						isHtml: true,
						size: 2,
						field: function(broker) {
							return $filter('multilineArray')(broker.currentCourses);
						}
					},
					{
						name: 'PERSON.BROKER.REGIONS',
						filter: 'regionsCovered',
						isHtml: true,
						size: 2,
						field: function(broker) {
							let regions = '';
							let i;
							const length = (broker.regionsCovered || []).length;
							if (length > 0) {
								for (i = 0; i < length; i++) {
									regions += broker.regionsCovered[i].name;

									if (i < length - 1) {
										regions += '<br>';
									}
								}
							}
							else {
								regions = '---'
							}
							return regions;
						}
					},
					{
						name: 'ADDRESS.CITY',
						filter: 'city',
						size: 2,
						select: true,
						config: dropdowns.config,
						options: dropdowns.cities,
						field: function(broker) {
							return (((broker.user || {})._address || {}).city || {}).name || '';
						}
					},
					{
						name: 'ADDRESS.POSTAL_CODE',
						filter: 'postalCode',
						size: 2,
						field: function(broker) {
							return ((broker.user || {})._address || {}).postalCode || '';
						}
					},
					{
						name: 'ADDRESS.OFFICE_NAME',
						filter: 'office',
						size: 2,
						field: function(broker) {
							return ((broker.user || {})._address || {}).officeName || '';
						}
					},
					{
						nameImgClass: 'btn-list-icon do-not-email',
						nameImgPopover: 'btnDoNotEmail',
						filter: 'emailEnabled',
						noSearch: true,
						size: 1,
						class: 'check',
						style: { 'text-align': 'center' },
						isHtml: true,
						field: function(broker) {
							return broker.user.emailEnabled ? '' : '<div class="icon-checked"></div>';
						}
					},
				]

				SortingService.sort(self.brokers, self.filters, null, ['user'], page, true);
			})
			.catch((err) => {})
			.finally(AlertService.doneLoading);
		}

		function _getLangField(broker) {
			const langs = (((broker || {}).user || {}).languages || []).reduce((arr, lang) => {
				arr.push('' + $filter('translate')('LANGUAGES.' + lang.id));
				return arr;
			}, []).sort();

			if (langs.length === 0) {
				return '---';
			}

			return langs.join('<br>');
		}

		// Export csv - Complete
		$scope.exportComplete = function () {
			self.isPopoverOpen = false;
			const exportFilter = self.filters;
			exportFilter.checked = { weight: 0, value: 'true', desc: true };
			const toExport = $filter('searchMultiFilter')(self.brokers, exportFilter);
			if(toExport.length > 0) {
				FileService.exportComplete(toExport, 'broker');
			} else {
				const modal = ModalService.openModal('nothingToExport', { folder: function () { return {} }, type: function() { return '' }, options: function() { return { } } }, 'FolderController', 'folderController');
				modal.result
				.then((res) => {})
				.catch((err) => {});
			}
		};

		// Export csv - Simplified (first name, last name and email)
		$scope.exportSimple = function() {
			self.isPopoverOpen = false;
			const exportFilter = self.filters;
			exportFilter.checked = { weight: 0, value: 'true', desc: true };
			const toExport = $filter('searchMultiFilter')(self.brokers, exportFilter);
			if(toExport.length > 0) {
				FileService.exportSimple(toExport, 'ROLES.BROKER');
			} else {
				const modal = ModalService.openModal('nothingToExport', { folder: function () { return {} }, type: function() { return '' }, options: function() { return { } } }, 'FolderController', 'folderController');
				modal.result
				.then((res) => {})
				.catch((err) => {});
			}
		}

	}) 	// End of controller
})(); // End of function()
