/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.directives')

	.directive('subMenuArrow', () => {
		return {
			restrict: 'E',
			scope: {
				navItem: '=',
				openSub: '=',
				subName: '='
			},
			link: function(scope, element, attrs) {},
			template:
                '<div class="sub-menu-arrow"' +
                    'ng-click="$event.stopPropagation(); $event.preventDefault(); openSub(subName)">' +
                    '<i class="fa menu-arrow"></i>' +
                '</div>'
		};
	})

})();
