/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('MessagesController', function ($sce, $filter, $location, $scope, $rootScope, $timeout, $window, AlertService, FileService, MessageService, ModalService, BrowserInformation, UploadService, AccountService) {

		const self = this;

		// VARIABLES ============================================================================================================================
		let isMobile;					// Whether this is the mobile view
		let userId;						// Current user's ID

		self.conversations 		= [];	// Contact list (left side of screen)
		self.selectedContact	= null;	// Currently selected contact object
		self.selectedFiles		= null;	// Files that belong to selectedContact

		self.message 			= null;	// New message
		self.newFiles = [];				// Files attached to current message
		self.messageForm		= {}; 	// Form for message object
		self.uploader			= null;	// Uploader object

		self.active 			= 0;	// Which tab is currently active
		self.showConversation	= null;	// Whether to show conversation part (mobile nav)
		self.header 			= '';	// Header for mobile version

		self.searchMessages 	= '';	// Search field for messages
		self.searchContacts 	= '';	// Search field for contacts

		self.index = null;
		$scope.lockMessage 		= true;

		self.searchDisabled = false;

		// SETUP VARIABLES ======================================================================================================================
		isMobile 				= BrowserInformation.isMobile();
		userId 					= AccountService.getUserId();

		if (isMobile) {
			self.showConversation = false;
			self.header = 'MESSAGES.TITLE';
		}

		self.selectedFiles = {
			length: 0
		};

		self.message = {
			text: '',
			read: false
		}					// Object used to send new messages by user

		setupUploader();
		getContacts();

		// FUNCTIONS ============================================================================================================================

		/** Adds loading overlay while item is being uploaded */
		$scope.$watch('messagesController.uploader.isUploading', () => {
			if (!!self.uploader.isUploading) {
				AlertService.loading();
			}
		})

		/** Used for mobile navigation */
		$scope.back = function () {
			if (self.active == 0) {
				self.showConversation = false;
				self.header = 'MESSAGES.TITLE';
			}
			else {
				self.active = 0;
			}
		}

		/**
		 * Determines whether to show message with blue background (sent) or grey (received) -> based on senderId
		 * @param message: Message
		 */
		self.getClass = function (message) {
			return MessageService.getMessageClass(message);
		}

		/**
		 * Used to change tabs
		 * @param x: int (index of tab that will become active)
		 */
		self.makeActive = function (x) {
			if(x === 1) {
				self.searchDisabled = true;
			} else {
				self.searchDisabled = false;
			}
			self.active = x;
		};

		/**
		 *	Selects a contact from the conversation list (left)
		*	and shows its messages/files (right)
		*	@param index: int (index of the contact in the list)
		*/
		self.selectMessage = function (contact) {
			if (self.uploader.queue.length > 0) {
				self.uploader.clearQueue();
			}
			self.newFiles = [];
			self.selectedFiles = { length: 0 };

			MessageService.getContacts(contact.id)
			.then((res) => {
				const conv = res[0];
				self.selectedContact = conv;

				if (conv.type == 'user' || conv.type == 'system') {
					const [recipient, sender] = conv.users;
					const usersSwapped = recipient.id == userId && sender;

					self.selectedContact.name = usersSwapped ? sender.fullName : recipient.fullName;

					if (conv.type == 'user') {
						self.selectedContact.photoUrl = usersSwapped ? sender.photoUrl : recipient.photoUrl;
					} else {
						self.selectedContact.photoUrl = '/public/img/smartBot.png';
					}
				} else {
					self.selectedContact.name = (conv.agency || {}).legalName || 'N/A';
				}
				contact.metadata.notRead = conv.metadata[0].notRead;

				if (self.selectedContact != null) {
					const messages = self.selectedContact.messages || [];

					const hasUnread = messages.find(x => x.senderId !== userId && !x.read);

					if (!!hasUnread) {
						MessageService.markAsRead(self.selectedContact.id).then((res) => {
							messages.forEach((msg) => {
								msg.read = true;
							})

							if (contact.metadata.notRead > 0)
								contact.metadata.notRead = 0;
						})
					}
				}
				else {
					self.selectedContact = {};
				}
				self.selectedContact.notRead = 0;

				// Mobile: changes view to messages
				if (isMobile) {
					self.showConversation = true;
					self.header = self.selectedContact.fullName || 'MESSAGES.TITLE';
				}
			})
			.catch((err) => {
				console.log('err: ', err);
			})
			.finally(() => {
				$scope.lockMessage = isLockMessage();
				AlertService.doneLoading();

				if (!!(self.selectedContact || {}).id) {
					$location.hash(self.selectedContact.id);
					$timeout(() => {
						const anchor = document.getElementById(self.selectedContact.id);
						const container = angular.element(document.getElementById('messages-container'));
						container.scrollToElement(anchor, 20, 100);
					}, 175)
				}


			});
		}

		/**
		 *	Opens modal to start new conversation
			*/
		self.newMessage = function () {

			const retFunction = function (result) {
				getContacts();
			}

			ModalService.openModalWithFunction('newMessage', {
				message: function () { return null; },
				sender: function () { return null; },
				receiver: function () { return null; }
			}, 'MessageModalController', 'messageModalController', retFunction);
		};

		/** Selects file (files tab) */
		self.selectFile = function (file, index) {

			// Not available in mobile version
			if ($rootScope.screenSize === 'xs' || $rootScope.screenSize === 'md')
				return;

			index = index.toString();

			if (self.selectedFiles[index]) {
				delete self.selectedFiles[index];
				file.selected = false;
			}
			else {
				self.selectedFiles[index] = file;
				file.selected = true;
			}
			self.selectedFiles.length = Object.keys(self.selectedFiles).length - 1;
		};

		/** Exports conversation as a .txt file */
		self.exportConversation = function () {
			FileService.exportConversation(self.selectedContact)
		};

		/** Downloads selected files */
		self.downloadSelected = function () {
			if(self.selectedFiles.length > 1) {
				let fileIds = [];
				for (const i in self.selectedFiles) {
					if ((self.selectedFiles[i] || {}).id) {
						fileIds.push(self.selectedFiles[i].id);
					}
				}
				FileService.downloadMultiple('Conversations', self.selectedContact.id, fileIds)
			} else {
				for (const i in self.selectedFiles) {
					if ((self.selectedFiles[i] || {}).id) {
						FileService.download('Conversations', self.selectedContact.id, self.selectedFiles[i].id);
					}
				}
			}
		}

		self.download = function(file) {
			file = file || {};
			if (file.id) {
				FileService.download('Conversations', self.selectedContact.id, file.id);
			}
		}

		self.downloadMsg = function(file, message) {
			file = file || {};
			message = message || {};
			if (file.id && message.id) {
				FileService.download('Messages', message.id, file.id);
			}
		}

		/** Opens modal to view a file */
		self.viewFile = function (file) {
			self.currentFile = file;
			const type = (file || {}).type || '';

			if (type.includes('video') || type.includes('image')) {
				ModalService.openModal('viewImage', {
					media: function () { return file; },
					mediaType: function () { return 'img'; } ,
					sbDownload: function() { return self.download }
				}, 'MediaController', 'mediaController');
			}
			else {
				$window.open((file || {}).url, '_blank');
			}
		};

		function getContacts(id) {
			AlertService.loading();
			MessageService.getContacts(id)
			.then((conversations) => {
				let toSelect = 0;

				self.conversations = conversations.reduce((arr, convo, i) => {
					let other = {};

					if (!convo.agencyId) {
						const myId = AccountService.getUserId();
						other = convo.users.find(x => x.id != myId) || {};
					}

					if (other.id === 'SMARTBROKR' || (!other.id && !convo.agencyId)) {
						convo.name = 'SmartBot';
						convo.type = 'ROLES.SYSTEM';
						convo.photoUrl = '/public/img/smartBot.png';
					}
					else if (convo.agencyId) {
						convo.name = (convo.agency || {}).legalName || 'N/A';
						convo.type = 'ROLES.AGENCY';
					}
					else {
						convo.name = other.fullName || 'N/A';
						convo.type = '';
						convo.photoUrl = other.photoUrl;
					}

					convo.metadata = convo.metadata && convo.metadata[0] ? convo.metadata[0] : {};
					convo.notRead = 10;

					if (convo.id == $location.hash() || convo.id == id) {
						toSelect = i;
					}

					arr.push(convo);
					return arr;
				}, []);

				if (!id) {
					self.conversations = conversations;

					if (conversations.length >= 1 && conversations[toSelect] && !isMobile) {
						self.selectMessage(self.conversations[toSelect]);
					}
				}
				else {
					self.selectedContact = conversations[toSelect];
				}
			})
			.catch((err) => {
				console.error('err: ', err);
			})
			.finally(() => {
				$scope.lockMessage = isLockMessage();
				AlertService.doneLoading();

				if (!!(self.selectedContact || {}).id) {
					$location.hash(self.selectedContact.id);
				}
			})
		}

		function isLockMessage() {
			const contact = self.selectedContact || {};
			return !contact.id || [ 'ROLES.AGENCY', 'ROLES.SYSTEM', 'system' ].indexOf(contact.type) >= 0 || !!contact.agencyId;
		}

		function getSharedLink(id) {
			return window.location.origin + '/listings/shared/' + id + '/details/menu/4';
		}

		self.send = function () {
			const message = self.message;
			message.conversationId = self.selectedContact.id;
			message.text = message.text || '';

			MessageService.createMessage(message).then((res) => {
				if (res.id) {
					self.message.id = res.id;
					self.message.created = new Date();

					self.uploader.url = getUrl(res.id);

					if (self.uploader.queue.length > 0) {
						for (const i in self.uploader.queue) {
							self.uploader.queue[i].url = getUrl(res.id);
						}
						self.uploader.uploadAll();
						AlertService.doneLoading();
					}
					else {
						done();
					}
				}
			})
			.catch((err) => {});
		};

		self.openShareModal = function(ev) {
			if(ev) {
				if(ev.preventDefault) ev.preventDefault();
				if(ev.stopPropagation) ev.stopPropagation();
			}

			let brokerId = AccountService.getBrokerId();
			// find recipient in selected contact user array
			let selectedBroker = self.selectedContact.users.find((user) => {
				if((user.brokerProfile || {}).id !== brokerId) {
					return user;
				}
			});

			const modal = ModalService.openModal('shareListing', {
				selectedListing: function() { return null },
				selectedRecipient: function() { return selectedBroker.brokerProfile.id },
				listingId: function() { return '' },
				brokers: function() { return [] },
				sharedBrokers: function() { return [] },
				showBrokers: function() { return false }
			}, 'ShareListingModalController', 'shareListingModalController');

			modal.result.then((res) => {
				self.isSharing = true;
				self.canCancel = true;

				const message = {
					text: $filter('translate')('MESSAGES.SHARE_MESSAGE') + ' ',
					read: false,
					conversationId: self.selectedContact.id
				}

				for(let i = 0; i < res.listings.length; i++) {
					message.text += res.listings[i].searchText + ' - ' + getSharedLink(res.listings[i].id);

					if(i < (res.listings.length - 1)) {
						message.text += ' | ';
					}
				}

				MessageService.createMessage(message).then((res) => {
					if (res.id) {
						self.message.id = res.id;
						self.message.created = new Date();
						AlertService.doneLoading();
						done();
					}
				})
				.catch((err) => {});
			})
			.catch((err) => {});
		}

		/** Deletes a file from a message (before sending it)
			 * @param i: int (index of the file to be deleted)
			 */
		self.removeFile = function (item, i) {
			if (item) {
				item.remove();
				self.newFiles.splice(i, 1);
			}
		}

		function getUrl(id) {
			return UploadService.initMessageUrl(id || self.message.id);
		}

		function done() {
			self.message = {};
			self.newFiles = [];
			self.messageForm.$setPristine();
			self.selectMessage(self.selectedContact);
			AlertService.doneLoading();
		}

		function setupUploader() {
			self.uploader = UploadService.initMessageUploader();

			self.uploader.onCompleteAll = function () {
				done();
			}

			self.uploader.onAfterAddingAll = function (addedItems) {
				self.newFiles = self.newFiles.concat(addedItems);
				AlertService.success('FILES.FILE', 'add');
			}

			self.uploader.onBeforeUploadItem = function (item) {
				this.url = getUrl();
			}
		}
	}); // End of controller
})(); // End of function()
