(function () {

    "use strict";

    angular.module("smartbrokr.admin")
    .controller("AdminListingsController", function (AdminService, AlertService, GlobalVars, SortingService, $filter, $log, $stateParams) {

        var self = this;

        // VARIABLES ============================================================================================================================

        var page        = 'admin.listings';                         // Page name, used by SortingService to save search filters in memory
        self.listings   = null;                                     // Listings that will be displayed in the page
        self.columns    = null;                                     // Columns for List view 
        self.filters    = SortingService.getPageFilter(page);       // Search filters
        self.dropdowns  = {                                         // Options for filter dropdowns
            cities:     [],
            provinces:  [],
            brokers:    [],
            notaries:   [],
            agency:     [],
            status:     [],
            config:     GlobalVars.createSingleConfig(false, 'id', 'name', 'name')
        };

        self.sortInfo   = {                                         // Sort info for List view directive
            page:   page,
            sub:    [ 'property', 'property._address' ]
        }

        if (!self.filters) {
            self.filters = SortingService.createPageFilter(
                page,
                {
                    id: { weight: 0, value: '', desc: 1, id: true },
                    addressString: { weight: 0, value: '', desc: 1 },
                    city: { weight: 0, value: '', desc: 1 },
                    province: { weight: 0, value: '', desc: 1 },
                    created: { weight: 2, value: '', desc: 1, date: true },
                    agency: { weight: 0, value: '', desc: 1,
                        filterFunction: function(value, entry) {
                            function filterById(item) {
                                return item.agencyId === value;
                            }
                            var brokers = entry.brokers || [];
                            return brokers.findIndex(filterById) >= 0;
                        },
                        sortFunction: function(item) {
                            var brokers = item.brokers || [];
                            return brokers.reduce(function(str, broker) {
                                str += ((broker.agency || {}).legalName || (broker.agency || {}).id) + "\n";
                                return str;
                            }, '');
                        }
                    },
                    brokers: { weight: 0, value: '', desc: 1,
                        filterFunction: function(value, entry) {
                            function filterById(item) {
                                return item.id === value;
                            }
                            var brokers = entry.brokers || [];
                            return brokers.findIndex(filterById) >= 0;
                        },
                        sortFunction: function(item) {
                            var brokers = item.brokers || [];
                            return brokers.reduce(function(str, broker) {
                                str += (broker.user || {}).fullName + "\n";
                                return str;
                            }, '');
                        } 
                    },
                    notaries: { weight: 0, value: '', desc: 1,
                        filterFunction: function(value, entry) {
                            function filterById(item) {
                                return item.id === value;
                            }
                            var notaries = entry.notaries || [];
                            return notaries.findIndex(filterById) >= 0;
                        },
                        sortFunction: function(item) {
                            var notaries = item.notaries || [];
                            return notaries.reduce(function(str, notary) {
                                str += (notary.user || {}).fullName + "\n";
                                return str;
                            }, '');
                        } 
                    },
                    status: { weight: 0, value: '', desc: 1 }
                }
            );
        }

        getListings();

        // FUNCTIONS ============================================================================================================================

        /**
         *  Gets Listing data and defines columns and filter options based on what was returned.
         *  Saves listing data to self.listings variable
         */
        function getListings() {
            AlertService.loading();
            AdminService.getListings($stateParams.type, $stateParams.id, $stateParams.role).then(function(res) {
                self.listings = res;

                var aux = $filter('unique')(self.listings, 'property._address.city');
                self.dropdowns.cities.push({ name: '------', id: '' });
                aux.forEach(function(item) {
                    self.dropdowns.cities.push({ id: item.id, name: item.name });
                })

                aux = $filter('unique')(self.listings, 'property._address.province');
                self.dropdowns.provinces.push({ name: '------', id: '' });
                aux.forEach(function(item) {
                    self.dropdowns.provinces.push({ id: item.id, name: item.name });
                })

                aux = $filter('unique')(self.listings, 'brokers');
                self.dropdowns.brokers.push({ name: '------', id: '' });
                aux.forEach(function(item) {
                    self.dropdowns.brokers.push({ id: item.id, name: (item.user || {}).fullName });
                })

                aux = $filter('unique')(self.listings, 'notaries');
                self.dropdowns.notaries.push({ name: '------', id: '' });
                aux.forEach(function(item) {
                    self.dropdowns.notaries.push({ id: item.id, name: (item.user || {}).fullName });
                })

                aux = $filter('unique')(self.listings, 'brokers.agency');
                self.dropdowns.agency.push({ name: '------', id: '' });
                aux.forEach(function(item) {
                    self.dropdowns.agency.push({ id: item.id, name: item.legalName || item.id });
                })

                aux = $filter('unique')(self.listings, 'status');
                self.dropdowns.status.push({ name: '------', id: '' });
                aux.forEach(function(item) {
                    if (item) {
                        self.dropdowns.status.push({ id: item, name: $filter('translate')('LISTING.STATUS.' + item.toUpperCase()) });
                    }
                })
                
            })
            .catch(function(err) {
                $log.error(err);
            })
            .finally(function() {
                self.columns = [
                    {
                        name: 'Listing #',
                        filter: 'id',
                        size: 2,
                        field: function(listing) {
                            return listing.id;
                        }
                    },
                    {
                        name: 'Address',
                        filter: 'addressString',
                        size: 2,
                        field: function(listing) {
                            return ((listing.property || {})._address || {}).addressString;
                        }
                    },
                    {
                        name: 'Region',
                        filter: 'city',
                        size: 1,
                        select: true,
                        options: self.dropdowns.cities,
                        config: self.dropdowns.config,
                        field: function(listing) {
                            return (((listing.property || {})._address || {}).city || {}).name || 'N/A';
                        }
                    },
                    {
                        name: 'Province',
                        filter: 'province',
                        size: 1,
                        select: true,
                        options: self.dropdowns.provinces,
                        config: self.dropdowns.config,
                        isHtml: true,
                        field: function(listing) {
                            return '<div class="center-text">' + ((((listing.property || {})._address || {}).province || {}).id || 'N/A') + '</div>';
                        }
                    },
                    {
                        name: 'Created',
                        filter: 'created',
                        size: 2,
                        field: function(listing) {
                            return $filter('dateFormat')(listing.created);
                        }
                    },
                    {
                        name: 'Brokers',
                        filter: 'brokers',
                        size: 2,
                        select: true,
                        options: self.dropdowns.brokers,
                        config: self.dropdowns.config,
                        isHtml: true,
                        field: function(listing) {
                            var ret = '';
                            if ((listing.brokers || []).length > 0) {
                                listing.brokers.forEach(function(broker) {
                                    ret += '<div><a ui-sref="main.admin.profile({ id: \'' + broker.id + '\', role: \'broker\', menu: \'brokers\'})">' + 
                                        ((broker.user || {}).fullName || 'N/A') + '</a></div>';
                                })
                            }
                            else {
                                ret += '----';
                            }

                            return ret;
                        }
                    },
                    {
                        name: 'Agency',
                        filter: 'agency',
                        size: 2,
                        select: true,
                        options: self.dropdowns.agency,
                        config: self.dropdowns.config,
                        isHtml: true,
                        field: function(listing) {
                            var ret = '';

                            if ((listing.brokers || []).length > 0) {
                                listing.brokers.forEach(function(broker) {
                                    ret += '<div><a ui-sref="main.admin.agencies.profile({ id: \'' + broker.agencyId + '\'})">' + 
                                        ((broker.agency || {}).legalName || (broker.agency || {}).id) + '</a></div>';
                                })
                            }
                            else {
                                ret += '----';
                            }
                            
                            return ret;
                        }
                    },
                    {
                        name: 'Notaries',
                        filter: 'notaries',
                        size: 2,
                        select: true,
                        options: self.dropdowns.notaries,
                        config: self.dropdowns.config,
                        isHtml: true,
                        field: function(listing) {
                            var ret = '';
                            
                            if ((listing.notaries || []).length > 0) {
                                listing.notaries.forEach(function(notary) {
                                    ret += '<div><a ui-sref="main.admin.profile({ id: \'' + notary.id + '\', role: \'notary\', menu: \'agencies\'})">' + 
                                        ((notary.user || {}).fullName || 'N/A') + '</a></div>';
                                })
                            }
                            else {
                                ret += '----';
                            }


                            return ret;
                        }
                    },
                    {
                        name: 'Status',
                        filter: 'status',
                        size: 2,
                        select: true,
                        options: self.dropdowns.status,
                        config: self.dropdowns.config,
                        field: function(listing) {
                            if (listing.status) {
                                return $filter('translate')('LISTING.STATUS.' + (listing.status || '').toUpperCase());
                            }
                            else {
                                return 'N/A';
                            }
                        }
                    }
                ]

                AlertService.doneLoading();
            })
        }
    });

})();
