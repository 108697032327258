(function () {

    "use strict";

    angular.module("smartbrokr.admin")
    .controller("AdminAgencyAccountsController", function (AlertService, AdminService, GlobalVars, ModalService, $filter, $log, $sce, $scope, $stateParams, $timeout) {

        var self = this;

        // VARIABLES ============================================================================================================================

        self.agency     = null;
        self.columns    = [];

        var BROKER  = 'Broker';
        var MEMBER  = 'Agency Member';
        var OWNER   = 'Agency Owner';
        var NOTARY  = 'Notary';
        var AUDITOR = 'Auditor';

        self.filters    = {
            fullName: { weight: 1, value: '', desc: -1 },
            type: { weight: 0, value: '', desc: 1 },
            email: { weight: 0, value: '', desc: 1 },
            province: { weight: 0, value: '', desc: 1 },
            created: { weight: 0, value: '', desc: 1, date: true },
            status: { weight: 0, value: '', desc: 1,
                filterFunction: function(value, item) {
                    var status = '';
                    var roleActive = isRoleActive(item);
                    var acctStatus = ((item.user || {}).account || {}).status || 'disabled';

                    if (acctStatus === 'disabled' || !roleActive) status = 'I';
                    else if (acctStatus === 'canceled' && roleActive) status = 'C';
                    else if (acctStatus === 'active' && roleActive) status = 'A';

                    return status === value;
                },
                sortFunction: function(item) {
                    var roleActive = isRoleActive(item);
                    var acctStatus = ((item.user || {}).account || {}).status || 'disabled';

                    if (acctStatus === 'disabled' || !roleActive) return 'I';
                    if (acctStatus === 'canceled' && roleActive) return 'C';
                    if (acctStatus === 'active' && roleActive) return 'A';

                    return 'Z';
                }
            },
        };

        self.sortInfo   = {
            page: 'agencyAccounts',
            sub: [ 'user', 'user._address', 'user.account' ]
        }

        var dropdowns = {
            config: GlobalVars.createSingleConfig(false, 'id', 'name', 'name'),       // Selectize config
            provinces: [],
            status: [
                { id: '', name: '------' },
                { id: 'A', name: 'Active' },
                { id: 'C', name: 'Cancelled' },
                { id: 'I', name: 'Inactive' }
            ]
        }

        self.navParams  = {
            state: 'main.admin.profile',
            getParams: function(entry) {
                if (entry) {
                    var role = 'broker';
                    var menu = 'agencies';
                    switch(entry.type) {
                        case MEMBER: role = 'member'; break;
                        case OWNER: role = 'owner'; break;
                        case NOTARY: role = 'notary'; break;
                        case AUDITOR: role = 'auditor'; break;
                        case BROKER: menu = 'brokers'; break;
                    }

                    return { id: entry.id, role: role, menu: menu };
                }
                return {};
            }
        }


        getAgency();
        // FUNCTIONS ============================================================================================================================

        function getAgency() {
            AlertService.loading();
            AdminService.getAgencyAccounts($stateParams.id).then(function(res) {
                self.agency = res;
                var accounts = [];

                function addType(rel, role) {
                    if (!res[rel]) return;

                    res[rel].forEach(function(item) {
                        item.type = role;
                        accounts.push(item);
                    })
                }

                addType('brokers', BROKER);
                addType('members', MEMBER);
                addType('owners', OWNER);
                addType('notaries', NOTARY);
                addType('auditors', AUDITOR);

                var aux = $filter('unique')(accounts, 'user._address.province');
                dropdowns.provinces.push({ name: '------', id: '' });
                aux.forEach(function(item) {
                    dropdowns.provinces.push({ id: item.id, name: item.name });
                })

                self.agency.accounts = $filter('orderBy')(accounts,'user.fullName');
            })
            .finally(function() {

                self.columns = [
                    {
                        name: 'Name',
                        filter: 'fullName',
                        field: function(entry) {
                            return (entry.user || {}).fullName || '----';
                        }
                    },
                    {
                        name: 'Role',
                        filter: 'type',
                        field: function(entry) {
                            return entry.type || '----';
                        }
                    },
                    {
                        name: 'Status',
                        filter: 'status',
                        select: true,
                        options: dropdowns.status,
                        config: dropdowns.config,
                        field: function(entry) {

                            var roleActive = isRoleActive(entry);
                            var acctStatus = ((entry.user || {}).account || {}).status || 'disabled';

                            if (acctStatus === 'disabled' || !roleActive) return 'Inactive';
                            if (acctStatus === 'canceled' && roleActive) return 'Cancelled';
                            if (acctStatus === 'active' && roleActive) return 'Active';

                            return '----';
                        }
                    },
                    {
                        name: 'Account Email',
                        filter: 'email',
                        isHtml: true,
                        field: function(entry) {

                            if ((entry.user || {}).account) {
                                var email = {
                                    id: entry.user.id,
                                    email: entry.user.account.email,
                                    fullName: entry.user.fullName
                                }
                                return '<email person=\'' + JSON.stringify(email) + '\' function="smartbrokrController.sendMessage"></email>'; 
                            }
                            return '----';
                        }
                    },
                    {
                        name: 'Province',
                        filter: 'province',
                        select: true,
                        options: dropdowns.provinces,
                        config: dropdowns.config,
                        field: function(entry) {
                            var prov = ((entry.user || {})._address || {}).province || {};
                            if (prov.id !== 'CUSTOM') 
                                return prov.id || '----';

                            return prov.name;
                        }
                    },
                    {
                        name: 'Date Joined',
                        filter: 'created',
                        field: function(entry) {
                            if ((entry.user || {}).account) {
                                return $filter('dateFormat3')(((entry.user || {}).account || {}).created);
                            }

                            return '----';
                        }
                    },
                ]

                AlertService.doneLoading();
            });
        }

        function isRoleActive(profile) {
            profile = profile || {};

            switch(profile.type) {
                case BROKER: 
                    return profile.status === 'SB_USER';
                case OWNER:
                case MEMBER: 
                    return !profile.disabled;
                case AUDITOR:
                case NOTARY:
                    return !(moment(profile.expires).isBefore(moment()) || profile.disabled);
            }

            return false;
        }
    })
})()