(function () {

  "use strict";

  angular.module("smartbrokr.settings", [])

    .service("SettingsService", function (Account, User, AccountService, $q) {
      var self = this;
      self.updatePassword = function () {
        return Account.updatePassword({ id: getId() }).$promise;
      };

      self.updateAccount = function (changes) {
        return Account.prototype$updateAttributes({ id: getId() }, changes).$promise;
      };

      //Account Language
      self.addLanguage = function (fk) {
        return Account.languages.link({ id: getId(), fk: fk }).$promise;
      };

      self.changeLanguage = function (oldFk, newFk) {
        return $q(function (resolve, reject) {
          Account.languages.unlink({ id: getId(), fk: fk }).$promise
            .then(function (res) {
              return Account.languages.link({ id: getId(), fk: fk }).$promise
                .then(function (res) {
                  resolve(res);
                })
                .catch(function (err) {
                  reject(err);
                })
            })
            .catch(function (err) {
              reject(err);
            })
        });
      };
      self.removeLanguage = function (fk) {
        return Account.languages.unlink({ id: getId(), fk: fk }).$promise;
      };
    });

})(); // End of function()
