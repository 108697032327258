(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('BrokerProfileController', function (AgencyService, AccountService, AlertService, ModalService, NavService, $filter, $log, $sce, $scope, $state) {
		const self 		= this;

		self.broker = {};
		self.role 	= AccountService.getRole();
		self.deleteWarning = $sce.trustAsHtml('' + $filter('translate')('ALERT_MESSAGES.ALERT.BROKER_CANT_DELETE'));

		getAccount();

		self.deleteBroker = function() {
			AgencyService.deleteBroker(self.broker.id).then((res) => {
				if (res) {
					AlertService.doneLoading();
					NavService.navigate('main.broker');
				}
			})
			.catch((err) => {
				$log.error(err);
				AlertService.doneLoading();
			})
		}

		self.toggleActive = function() {
			let message, title, titleClass;

			if (self.broker.status === 'SB_USER') {
				message 	= 'ALERT_MESSAGES.ALERT.BROKER_INACTIVE';
				title 		= 'PERSON.BROKER.MARK_NOT_SB_USER';
				titleClass 	= 'warning';
			}
			else {
				message 	= 'ALERT_MESSAGES.ALERT.BROKER_ACTIVE';
				title 		= 'PERSON.BROKER.MARK_SB_USER';
				titleClass 	= 'normal';
			}
			ModalService.prompt(message,title,'FORMS.CANCEL','OK',toggleBroker,false,titleClass);
		}

		self.toggleBroker = function(down) {
			toggleBroker(down);
		}

		function getAccount() {
			AlertService.loading();
			$scope.loading = true;
			AgencyService.getBroker($state.params.id).then((res) => {
				if (res.length == 1) {
					self.broker = res[0];
				}
				else {
					AlertService.errorMessage('accountDetails');
				}
			})
			.catch($log.error)
			.finally(() => {
				$scope.loading = false;
				self.canDelete = _canDelete();
				AlertService.doneLoading();
			})
		}

		function toggleBroker(down) {
			AlertService.loading();
			down = down || self.broker.status === 'SB_USER';
			AgencyService.toggleBroker(self.broker.id, down).then((res) => {
				self.broker.status = res.status;
			})
			.catch((err) => {
				if (err.status === 402) {
					ModalService.prompt(
						'PERSON.BROKER.CREATE_ERROR.MESSAGE',
						'PERSON.BROKER.CREATE_ERROR.TITLE',
						'FORMS.CANCEL',
						null,
						null,
						false);
				}
			})
			.finally(getAccount)
		}

		self._isInactive = function() {
			return [ 'INACTIVE', 'DISABLED' ].indexOf((self.broker || {}).status) >= 0;
		}

		function _canDelete() {
			return (self.broker || {})._maxStatus !== 'SB_USER' && ((self.broker || {}).listings || []).length === 0 && [ 'INACTIVE', 'DISABLED' ].indexOf((self.broker || {}).status) >= 0;
		}

		self.viewSentEmails = function() {
			NavService.navigate('main.broker.emails', { id: self.broker.id, role: 'broker' });
		}
	});

})(); // End of function()
