/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function() {

	'use strict';

	angular.module('smartbrokr')

	.controller('MigrateController', function(AccountService, AlertService, GlobalVars, ImportCsvService, $filter, $rootScope, $sce, $scope, $state, $timeout) {

		const self = this;
		const role = AccountService.getRole();

		if (role === 'agencyOwnerProfile' || role === 'agencyMemberProfile') {
			self.justSuppliers = true;
		}

		self.helpText = $sce.trustAsHtml('' + $filter('translate')('MIGRATE.FILE_HELP'));

		ImportCsvService.reset();

		// Logic is all delegated to ImportCsvService
		self.buyerUploader 		= ImportCsvService.buyerUploader;
		self.sellerUploader 	= ImportCsvService.sellerUploader;
		self.supplierUploader 	= ImportCsvService.supplierUploader;
		self.modifying 			= null;

		const dropdowns = [];

		let currIndex = 0;
		const LOAD_CHUNK = 50;
		self.allLoaded = false;

		self.cancel = function() {
			ImportCsvService.reset();
			_updateFiles();
			$state.reload();
		}

		self.clearColumn = function(index) {
			self.report.columns[index].selected = null;
			self.disableSelected();
		}

		self.disableSelected = function() {
			self.select.options.forEach((opt) => {
				const i = self.report.columns.findIndex((col) => {
					return col.selected == opt.value;
				})

				opt.disabled = i >= 0;
			})

			dropdowns.forEach((sel) => {
				sel.clearCache();
			})

			self.modifying = null;
		}

		self.loadChunk = function() {
			if (self.allLoaded) return;

			self.report.columns.forEach((col) => {
				if (!col.view) {
					col.view = [];
				}

				if ((currIndex + LOAD_CHUNK) < col.data.length) {
					col.view = col.view.concat(col.data.slice(currIndex, currIndex + LOAD_CHUNK));
				}
				else {
					col.view = col.view.concat(col.data.slice(currIndex));
					self.allLoaded = true;
				}
			})

			currIndex += LOAD_CHUNK;
		}

		self.next = function() {
			ImportCsvService.next();
		}

		self.selectColumn = function(i) {
			if (self.modifying === i) {
				self.modifying = null;
			}
			else {
				self.modifying = i;
			}
		}

		const w1 = $rootScope.$on('update-files', (event, args) => {
			_updateFiles();
		});

		const w2 = $rootScope.$on('update-report', (event, args) => {
			self.report 		= ImportCsvService.report;
			self.disableButton 	= false;

			const config = GlobalVars.createSingleConfig(false, 'value', 'label', 'label', true);
			config.onInitialize = function(selectize) {
				dropdowns.push(selectize);
			}
			config.placeholder = false;

			const options = self.report.columnOptions.reduce((arr, col) => {
				arr.push({ value: col.value, label: $filter('translate')(col.label) });
				return arr;
			}, []);

			self.select = {
				config: config,
				options: options
			}
		});

		const w3 = $rootScope.$on('finish-report', (event, args) => {
			self.cancel();
		})

		// Remove listeners
		$scope.$on('$destroy', () => {
			w1(); w2(); w3();
		})

		function _updateFiles() {
			self.buyerFile 		= ImportCsvService.buyerFile;
			self.sellerFile 	= ImportCsvService.sellerFile;
			self.supplierFile 	= ImportCsvService.supplierFile;
		}

	})	// End of controller
	.directive('migrateColumns', () => {
		return {
			restrict: 'E',
			scope: {
				controller: '='
			},
			templateUrl: './js/src/settings/migrate.columns.html'
		};
	})
})(); // End of function()
