(function () {

    "use strict";

    angular.module("smartbrokr")
    .controller("ListingSummaryController", function (listingId, $uibModalInstance, $log, $rootScope, $sce, $translate, $translatePartialLoader, ListingService) {
        
        var self = this;
        
        // VARIABLES ============================================================================================================================
        self.listing    = null;         // Listing that is being displayed
        
        // SETUP VARIABLES ======================================================================================================================
        ListingService.getListingSummary(listingId).then(function(res){
            self.listing = res;
            var url = null;

            res.listingBrokers.forEach(function(item){
                if (item.broker) {
                    item.broker.primary = item.primary;

                    if (item.primary || !self.listing.url) {
                        url = getUrl(item.broker, res.id); 
                        
                        if (url) {
                            self.listing.url = url; // Only replace if value returned was not null
                        }
                    }
                }
            })

            self.listing.brokers = res.listingBrokers;

            if ($rootScope.language === 'FR') {
                $translatePartialLoader.addPart('provinces');
                $translate.refresh();

                var address = (self.listing.property || {})._address || {};
                if ((address.country || {}).id === 'CAN' && address.province) {
                    $translate('PROVINCE.' + address.province.id).then(function(province) {
                        province = $sce.valueOf(province);
                        address.province.name = province;

                        if (address.province.id !== 'QC' && address.region) {
                            address.region.name = province;
                        }
                    })
                    .catch($log.error);
                }
            }
        })
        .catch($log.error);

        function getUrl(broker, mls) {

            var url = broker.pluginUrl || {};

            if (!url.EN && !url.FR) {   // We don't have a plugin URL in the DB -> return null
                return null;
            }
            
            return (url.EN || url.FR) + '?id=' + mls;    // Return listing URL with our plugin structure
        }


        // FUNCTIONS ============================================================================================================================

        self.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

    })  // End of controller
})();   // End of function()