(function () {

	"use strict";

	angular.module("smartbrokr")

		.controller("TeamsController", function (GlobalVars, ModalService, UserService, $rootScope) {

			var self = this;

			self.columns = ["Team Name", "Members", "Properties", "Clients"];
			self.team = {};

			self.getTeams = function () {
				UserService.getTeams()
					.then(function (res) {
						self.teams = res;
					})
					.catch(function (err) {})
					.finally(function () {
						$rootScope.$emit("loadingEnd");
					})
			}
			self.getTeams();

			self.popover = function (id) {
				return GlobalVars.popover(id);
			}
			self.deleteTeam = function (id) {
				UserService.deleteTeam(id)
					.then(function (res) {
						self.getTeams();
					})
					.catch(function (err) {})

			}
			self.editTeam = function (team) {
				if (team) {
					//open modal with team
					var modal = ModalService.openModal('createTeam', { team: function () { return team }, isNew: function () { return false } }, 'TeamController', 'teamController');
					modal.result
						.then(function (res) {
							if (res.closed) {
								self.getTeams();
							}
						})
						.catch(function (err) {
						});
				}
			}

			self.createTeam = function () {
				var modal = ModalService.openModal('createTeam', { team: function () { return self.team }, isNew: function () { return true } }, 'TeamController', 'teamController');
				modal.result
					.then(function (res) {
						if (res.closed) {
							self.getTeams();
						}
					})
					.catch(function (err) {
					});
			}

			self.filters = {
				name: { weight: 0, value: '', desc: 1 },
			};
			self.sortUsers = function (category) {
				SortingService.sort(self.teams, self.filters, category);
			};

		});

})(); // End of function()
