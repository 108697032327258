/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.agency', [])

	.service('AgencyService', function (AccountService, AlertService, Agency, AgencyMember, AgencyOwner, Auditor, Broker, ModalService, Notary, $q, $state) {
		const self = this;

		const AGENCY_MEMBER = 'AGENCY_MEMBER';
		const AGENCY_OWNER = 'AGENCY_OWNER';
		const BROKER = 'BROKER';

		self.agencyExists = function(id) {
			return Agency.isRegistered({ id: id }).$promise;
		}

		self.assignOffices = function(officeId, people) {
			people = people || [];

			const promises = people.reduce((arr, person) => {

				let method;

				switch(person.type) {
				case AGENCY_MEMBER:
					method = AgencyMember.prototype$updateAttributes; break;
				case AGENCY_OWNER:
					method = AgencyOwner.prototype$updateAttributes; break;
				case BROKER:
					method = Broker.prototype$updateAttributes; break;
				}

				if (method) {
					arr.push(method({ id: person.id }, { officeId: officeId } ).$promise);
				}

				return arr;
			}, []);

			return $q.all(promises);
		}

		self.deleteBroker = function(brokerId) {
			const del = function() {
				AlertService.loading();
				return Agency.brokers.destroyById({ id: AccountService.getAgencyId(), fk: brokerId }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_BROKER');
		}

		self.deleteOffice = function(officeId) {
			const del = function() {
				return Agency.offices.destroyById({ id: AccountService.getAgencyId(), fk: officeId }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_OFFICE');
		}

		self.disableAuditor = function (auditorId) {
			return Auditor.expireAuditor({ id: auditorId }).$promise;
		}

		self.disableMember = function (memberId) {
			return Agency.members.updateById({ id: AccountService.getAgencyId(), fk: memberId }, { disabled: true }).$promise;
		};

		self.disableNotary = function(notaryId) {
			return Notary.expireNotary({ id: notaryId }).$promise;
		}

		self.disableOwner = function (ownerId) {
			return Agency.owners.updateById({ id: AccountService.getAgencyId(), fk: ownerId }, { disabled: true }).$promise;
		};

		self.findPersonByAccount = function(accountId, type) {
			return Agency.findPersonByAccount({ id: AccountService.getAgencyId(), accountId: accountId, type: type }).$promise;
		}

		self.getAgencies = function() {
			return Agency.find().$promise;
		}

		self.getAgency = function (onlyActive = false, excludePersonalBroker = false, brokerId = '') {

			const filter = {
				fields: [ 'id', 'legalName', 'certNo', 'certificateTypeId', 'brokerCode' ],
				include: [
					{
						relation: 'offices',
						scope: {
							fields: ['id', 'code', 'main', 'legalName', 'description', 'email', 'website', 'officeLogo', 'brandName', 'brandUrl', 'featuredListing', 'phones', 'agencyId'],
							include: [
								_include('brokers', true, onlyActive, excludePersonalBroker, brokerId),
								_include('members', false),
								_include('owners', false),
							]
						}
					},
				]
			}

			const def = $q.defer();

			Agency.findById({ id: AccountService.getAgencyId(), filter: filter }, _success, def.reject);

			return def.promise;

			function _success(res) {

				res = res || {};
				res.offices = (res.offices || []).reduce((arr, office) => {
					office.members = office.members || [];

					if (office.owners) {
						office.owners.forEach((owner) => {
							const i = office.members.findIndex(member => member.sbUserId === owner.sbUserId);
							if (i < 0) {
								owner.isOwner = true;
								office.members.push(owner);
							}
							else {
								office.members[i].isOwner = true;
							}
						})
					}

					delete office.owners;
					arr.push(office);

					return arr;
				}, []);

				res.offices.sort((a, b) => {
					if (a.main || (a.id || '').includes('001')) return -1;
					if (b.main || (b.id || '').includes('001')) return 1;
					return (a.legalName || 'zzz').localeCompare(b.legalName);
				})

				res.mainOffice = res.offices.find(item => (item.id || '').includes('001') || item.main) || {};

				if (!res.mainOffice.main) {
					res.mainOffice.main = true;
				}

				if (!res.offices.includes(res.mainOffice)) {
					res.offices.push(res.mainOffice);
				}

				def.resolve(res);
			}

			function _include(rel, isBroker, onlyActive = false, excludePersonalBroker = false, brokerId = '') {
				const fields = [ 'id', 'sbUserId', 'agencyId', 'officeId' ];
				let where;

				if (isBroker) {
					if(onlyActive) {
						where = { status: 'SB_USER' };
					} else {
						where = { status: { neq: 'DISABLED' } };
					}
					if(excludePersonalBroker) {
						where.id = { neq: brokerId }
					}
					fields.push('status');
				}
				else {
					where = { disabled: { neq: true } };
					fields.push('disabled');
				}


				return {
					relation: rel,
					scope: {
						where: where,
						fields: fields,
						include: {
							relation: 'user',
							scope: {
								fields: [ 'id', 'fullName', 'sbUserId' ]
							}
						}
					}
				}
			}
		};

		self.getOffices = function() {
			return $q((resolve, reject) => {
				self.getAgency(true).then((res) => {
					const offices = res.offices.filter((office) => {
						return ((office.brokers && office.brokers.length > 0) || (office.members && office.members.length > 0) || (office.owners && office.owners.length > 0));
					});
					resolve(offices);
				});
			});
		}

		self.getAgencyName = function(id) {
			return Agency.findById({ id: id, filter: { fields: [ 'id', 'legalName' ] } }).$promise
		}

		self.getAuditor = function (fk) {
			return getType('auditors', fk);
		}

		self.getBroker = function (fk) {
			const filter = {
				where: {
					id: fk
				},
				include: [
					{
						relation: 'user',
						scope: {
							fields: [ 'id', 'fullName', 'firstName', 'lastName', '_address', 'phones', 'photoUrl', 'email', 'emailEnabled', 'secondaryEmail', 'secondaryEmailEnabled', 'languageIds' ],
							include: 'languages'
						}
					},
					{
						relation: 'agency',
						scope: {
							fields: [ 'id', 'legalName', 'certNo' ]
						}
					},
					{
						relation: 'listings',
						scope: {
							fields: [ 'id' ]
						}
					}
				]
			}
			return Agency.brokers({ id: AccountService.getAgencyId(), filter: filter }).$promise;
		};

		self.getPeopleOffices = function() {
			const filter = {
				fields: [ 'id', 'sbUserId', 'officeId', 'agencyId', 'status', 'disabled' ],
				where: {
					status: {
						neq: 'DISABLED'
					},
					disabled: {
						neq: true
					}
				},
				include: [
					{
						relation: 'user',
						scope: {
							fields: [ 'id', 'fullName', 'firstName', 'lastName' ]
						}
					},
					{
						relation: 'office',
						scope: {
							fields: [ 'id', 'legalName' ]
						}
					}
				]
			}

			const agencyId = AccountService.getAgencyId() || ($state.params || {}).id;

			const get = [
				Agency.brokers({ id: agencyId, filter: filter }).$promise,
				Agency.members({ id: agencyId, filter: filter }).$promise,
				Agency.owners({ id: agencyId, filter: filter }).$promise
			];

			return $q.all(get).then((res) => {
				let ret = [];
				ret = ret.concat(res[0].map(item => { item.type = BROKER; return item; }));
				ret = ret.concat(res[1].map(item => { item.type = AGENCY_MEMBER; return item; }));
				ret = ret.concat(res[2].map(item => { item.type = AGENCY_OWNER; return item; }));
				return ret;
			});
		}

		self.getMember = function (fk) {
			return getType('members', fk);
		};

		self.getMembers = function () {

			const getIncludes = function() {
				return [
					{
						relation: 'agency',
						scope: {
							fields: [ 'id', 'legalName' ]
						}
					},
					{
						relation: 'user',
						scope: {
							fields: [ 'id', 'firstName', 'lastName', 'fullName', 'languageIds', 'phones', 'email', 'photoUrl', 'emailEnabled', '_address' ],
							include: 'languages'
						}
					}
				]
			}

			const includeOffice = {
				relation: 'office',
				scope: {
					fields: [ 'id', 'legalName', 'code' ]
				}
			}

			const filter = {
				fields: [ 'id', 'legalName' ],
				include: [
					{
						relation: 'members',
						scope: {
							where: { disabled: false },
							fields: [ 'id', 'agencyId', 'sbUserId', 'agencyRole', 'officeId' ],
							include: getIncludes().concat([ includeOffice, 'agencyRoleCode' ])
						}
					},
					{
						relation: 'auditors',
						scope: {
							fields: [ 'id', 'agencyId', 'sbUserId', 'expires' ],
							where: { disabled: false },
							include: getIncludes()
						},
					},
					{
						relation: 'notaries',
						scope: {
							fields: [ 'id', 'agencyId', 'sbUserId', 'expires' ],
							where: { disabled: false },
							include: getIncludes()
						},
					}
				]
			};
			return Agency.findById({ id: AccountService.getAgencyId(), filter: filter }).$promise;
		};

		self.getOwner = function (fk) {
			return getType('owners', fk);
		};

		self.getNotary = function (fk) {
			return getType('notaries', fk);
		};

		self.getUsersAndRoles = function() {
			return Agency.getUsersAndRoles({ id: AccountService.getAgencyId() }).$promise;
		}

		self.saveAgency = function(changes, type) {
			changes = changes || {};

			if (type === 'office') {
				if (changes.id) {
					return Agency.offices.updateById({ id: AccountService.getAgencyId(), fk: changes.id }, changes).$promise;
				}
				return Agency.offices.create({ id: AccountService.getAgencyId() }, changes).$promise;
			}
			else {
				return Agency.prototype$updateAttributes({ id: AccountService.getAgencyId() }, changes).$promise;
			}
		}

		self.getSentEmails = function(id, relation, fk) {
			return Agency.getSentEmails({ id: id, relation: relation, fk: fk }).$promise;
		}

		self.toggleBroker = function(brokerId, down) {
			return Agency.toggleBroker({ id: AccountService.getAgencyId(), brokerId: brokerId, down: down }).$promise;
		}

		function getType(rel, fk) {
			const filter = {
				where: {
					id: fk,
					disabled: { neq: true }
				},
				include: [
					{
						relation: 'user',
						scope: {
							fields: [ 'id', 'firstName', 'lastName', 'fullName', 'languageIds', 'phones', '_address', 'email', 'photoUrl', 'emailEnabled' ],
							include: 'languages'
						}
					},
					{
						relation: 'agency',
						scope: {
							fields: [ 'id', 'certNo', 'legalName' ]
						}
					}
				],
				fields: [ 'id', 'agencyId', 'sbUserId', 'agencyRoleId', 'officeId' ]
			}

			if (rel === 'auditors' || rel === 'notaries') {
				filter.fields.push('expires');
			}

			if (rel === 'members' || rel === 'owners') {
				filter.include.push({
					relation: 'office',
					scope: {
						fields: [ 'id', 'legalName', 'code' ]
					}
				})
			}

			if (rel === 'members') {
				filter.include.push('agencyRoleCode');
			}

			return Agency[rel]({ id: AccountService.getAgencyId(), filter: filter }).$promise;
		}
	})
})(); // End of function()
