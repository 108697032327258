/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.directive('mediaManagerNew', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/resources/media-manager/media-manager-new.html'
		};
	})

	.directive('mediaManagerSaved', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/resources/media-manager/media-manager-saved.html'
		};
	})

})(); // End of function()
