/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.property',[])

	.service('PropertyService', function (Property, ModalService) {
		const self = this;
		self.updateFeature = function (pId, fk, feature) {
			return Property.features.updateById({ id: pId, fk: fk }, feature).$promise;
		}
		self.deleteFeature = function (pId, fk) {
			const del = function() {
				return Property.features.destroyById({ id: pId, fk: fk }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FEATURE');
		}

		self.updateRoom = function (pId, fk, newRoom) {
			return Property.rooms.updateById({ id: pId, fk: fk }, newRoom).$promise;
		}

		self.deleteRoom = function (pId, fk) {
			const del = function() {
				return Property.rooms.destroyById({ id: pId, fk: fk }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_ROOM');
		}

		self.createCost = function (pId, cost) {
			return Property.costs.create({ id: pId }, cost).$promise;
		}

		self.getCosts = function (pId) {
			return Property.costs({ id: pId }).$promise;
		}

		self.getCost = function (pId, fk) {
			return Property.costs.findById({ id: pId, fk:fk }).$promise;
		}

		self.updateCost = function (pId, fk, newCost) {
			return Property.costs.updateById({ id: pId , fk:fk }, newCost).$promise;
		}

		self.deleteCost = function (pId, fk) {
			const del = function() {
				return Property.costs.destroyById({ id: pId, fk:fk }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_COST');
		}

		self.getAdditionalBuildings = function(pId) {
			const filter = {
				include: ['characteristics'],
				order: 'created ASC'
			};
			return Property.additionalBuildings({ id: pId, filter: filter }).$promise;
		}

		self.createAdditionalBuilding = function(pId, additionalBuilding) {
			return Property.additionalBuildings.create({ id: pId }, additionalBuilding).$promise;
		}

		self.updateAdditionalBuilding = function(pId, aId, additionalBuilding) {
			delete additionalBuilding.id;
			return Property.additionalBuildings.updateById({ id: pId, fk: aId }, additionalBuilding).$promise;
		}

		self.deleteAdditionalBuilding = function(pId, aId) {
			const del = function() {
				if(aId) return Property.additionalBuildings.destroyById({ id: pId, fk: aId }).$promise;
				return new Promise((resolve) => {
					resolve();
				});
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_ADDITIONAL_BUILDING');
		}

	});

})(); // End of function()
