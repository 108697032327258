/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('ListingDocumentsController', function ($filter, $localStorage, $log, $rootScope, $scope, $stateParams, $translate, AlertService, DocusignService, FileService, ListingService, ModalService, RoleService, UploadService) {

		const self = this;
		self.resources      = [];
		self.deleting       = [];
		self.i              = null;
		self.canModify      = null;
		self.hide           = false;
		self.showDocusignTooltips = $localStorage.showDocusignTooltips;

		self.uploader       = UploadService.initDocumentUploader();
		self.uploader.scope = $scope;


		self.folderToExport = '';
		self.selectedForExport = [];

		self.docusignConnected = false;

		self.folderNames = [
			'APPRAISAL',
			'DEED_OF_SALE',
			'APPENDIX-DRINKING_WATER_AND_SEPTIC_INSTALLATION',
			'APPENDIX_DR-OUTLAY_AND_RETRIBUTIONS',
			'APPENDIX_F-FINANCING',
			'APPENDIX_G-GENERAL',
			'APPENDIX_R-RESIDENTIAL_BUILDING',
			'BANK_STATEMENT',
			'BILLS_ELECTRICITY_OIL_GAS',
			'BROKERAGE_CONTRACT',
			'BUYER_INFORMATION',
			'CERTIFICATE_OF_LOCATION',
			'CONSENT_OF_COMMUNICATION',
			'CONVENTIONS_AND_RESTRICTIONS',
			'DETAILED_LISTING',
			'INFORMATION_REQUEST-MORTGAGE_LOAN',
			'INSTALLATIONS_DESCRIPTION',
			'INSURANCE',
			'LOCATION_CONTRACT_OF_INCLUDED_APPLIANCES',
			'MARKETING_PLAN',
			'MEASUREMENTS',
			'MODIFICATIONS',
			'MUNICIPAL_TAXES',
			'NOTICE_OF_EXERCISE',
			'OFFER-COUNTER_OFFER',
			'OTHER',
			'PROPOSAL_TO_CLIENT',
			'RENOVATION_BILLS',
			'SCHOOL_TAXES',
			'SELLERS_DECLARATIONS',
			'SERVITUDES',
			'SURVEY/PLAN',
			'WARRANTIES_OF_INCLUDED_APPLIANCES'
		];

		DocusignService.getStatus().then((res) => {
			self.docusignConnected = res.connected;
		})
		.catch((err) => {
			$log.error(err);
		});

		self.type = 'listing';

		self.uploader.onSuccessItem = function (fileItem, response, status, headers) {
			load();
		}

		load();

		self.toggle = function () {
			self.hide = !self.hide;
		}

		self.setUrl = function (id) {
			const this_url = $rootScope.baseURL + '/Listings/' + $stateParams.id + '/document/' + id + '/uploadDocument';
			self.uploader.url = this_url;
		}

		self.docusign = function(file) {
			AlertService.loading();
			DocusignService.sendDocument(file.id).then((res) => {
				AlertService.doneLoading();
				AlertService.successMessage('envelope');
				return DocusignService.popup(res.data.url);
			})
			.catch((err) => {
				AlertService.doneLoading();
				const message = err.data.error.message;
				if(message.indexOf('sent') > -1) {
					AlertService.errorMessage('envelopeExists');
				} else if(message.indexOf('integrated') > -1) {
					AlertService.errorMessage('docusignDisconnected');
				} else {
					AlertService.errorMessage('envelopeCreate');
				}
				$log.error(err);
			});
		}

		self.deleteListingDocument = function (id, fk, parent, i) {
			if (!self.deleting[id]) {
				self.deleting[id] = {};
			}
			self.deleting[id][fk] = {
				deleting: true
			}
			ListingService.deleteListingDocument($stateParams.id, fk)
			.then((res) => {
				if (res) {
					load();
				}
			})
			.catch((err) => {
				self.deleting[id][fk] = {
					deleting: false
				}
			})
		}

		self.download = function(file, folder) {
			file = file || {};
			folder = folder || {};

			if (file.id && folder.id) {
				FileService.downloadDocument($stateParams.id, folder.id, file.id);
			}
		}

		function load() {
			let call;
			if ($stateParams.role) {
				call = RoleService.getDocuments;
				self.canModify = false;
			}
			else {
				call = ListingService.getDocumentMetadata;
				self.canModify = true;
			}

			AlertService.loading();
			call($stateParams.id).then((res) => {
				self.resources = $filter('orderBy')(res, 'documentTypeId');
			})
			.catch((err) => {
				console.log('err: ', err);
			})
			.finally(() => {
				AlertService.doneLoading();
			})

		}

		self.createNewFolder = function() {
			const modal = ModalService.openModal('createFolder', { folder: function () { return {} }, type: function() { return self.type }, options: function() { return { includeRequired: true, listingId: $stateParams.id } } }, 'FolderController', 'folderController');
			modal.result
			.then((res) => {
				load();
			})
			.catch((err) => {
			});
		}

		self.deleteFolder = function(folderId) {
			ListingService.deleteDocumentFolder($stateParams.id, folderId).then((res) => {
				if (res) {
					load();
				}
			})
		}

		self.downloadFolder = function(event, folder) {
			event.stopPropagation();
			event.preventDefault();
			ListingService.downloadFolder($stateParams.id, folder.id);
		}

		self.editFolder = function(event, folder) {
			event.stopPropagation();
			event.preventDefault();
			self.saveFolder(folder);
		}

		self.export = function(event, folder) {
			event.stopPropagation();
			event.preventDefault();
			if(self.folderToExport === folder.id) {
				self.selectedForExport = [];
			} else {
				self.folderToExport = folder.id;
				self.selectedForExport = [];
			}
		}

		self.exportSelected = function(event, folderId) {
			if(self.folderToExport === folderId) {
				ListingService.exportFolder($stateParams.id, folderId, self.selectedForExport);
			}
		}

		self.checkExportBox = function(event, folderId, id) {
			if(self.folderToExport === folderId) {
				if(self.selectedForExport.indexOf(id) > -1) {
					self.selectedForExport.splice(self.selectedForExport.indexOf(id), 1);
				} else {
					self.selectedForExport.push(id);
				}
			}
		}

		self.saveFolder = function(folder) {
			const fields = [
				{
					label: 'PERSON.NAME',
					model: 'documentTypeId',
					required: true,
					inputType: 'text'
				}
			]

			folder = folder || {};

			const m = ModalService.openForm(fields, 'FILES.NEW_FOLDER', true, folder);
			m.then((res) => {
				if (res) {
					ListingService.saveDocumentFolder($stateParams.id, res).then((res) => {
						load();
					})
				}
			})
			.catch((err) => {})
		}

	});

})(); // End of function()
