/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('RoleController', function (AccountService, AgencyService, AlertService, BrokerService, GlobalVars, ModalService, SortingService, UserService, $filter, $log, $q, $sce, $scope, $timeout) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.filters 		= null;		// Filters to sort list
		self.sortInfo		= null;		// Extra data to send to sorting service
		self.inject 		= null;		// Will be injected in list-view directive
		self.columns 		= null;		// Column information for list-view direstive

		self.users 			= null; 	// Users that belong to current user's agency
		let allItems		= null;
		self.search			= '';

		// SETUP VARIABLES ======================================================================================================================

		const page 			= 'roles';
		self.currentUserId 	= AccountService.getUserId(true);
		self.filters 		= SortingService.getPageFilter(page);

		if (!self.filters) {
			self.filters = SortingService.createPageFilter(
				page,
				{
					fullName: { weight: 1, value: '', desc: -1 },
					isOwner: { weight: 0, value: '', desc: -1 },
					isMember: { weight: 0, value: '', desc: -1 },
					isBroker: { weight: 0, value: '', desc: -1 },
					isNotary: { weight: 0, value: '', desc: -1 },
					isAuditor: { weight: 0, value: '', desc: -1 },
				}
			);
		}

		self.sortInfo = {
			page: page,
			sub: []
		}

		self.inject = {
			name: 'roleController',
			inject: self
		}

		self.columns = [
			{
				name: 'PERSON.NAME',
				filter: 'fullName',
				size: 2,
				field: function(user) {
					return user.fullName;
				}
			},
			{
				name: 'ROLES.AGENCY_OWNER',
				noSearch: true,
				filter: 'isOwner',
				isClick: true,
				style: { 'text-align': 'center' },
				clickFunction: function(user,i) {
					const isDisabled = AccountService.limitedAccess() || user.id === self.currentUserId || user.isAuditor || user.isNotary;
					if (!isDisabled) {
						self.changeRole(user.id, { role: 'SB_AGENCY_OWNER', disabled: true }, !user.isOwner, user, 'isOwner');
						user.isOwner = !user.isOwner || false;
					}
				},
				field: function(user) {
					return $sce.trustAsHtml(
						'<div ' + _roleWarning('isOwner', false) + '>' + 		// Add hover warning if user is notary or auditor
						'<input class="regular-checkbox grey-disabled" type="checkbox" readonly="true" ng-model="entry.isOwner" ' +
						'ng-disabled="limitedAccess || ((entry.isAuditor || entry.isNotary) && !entry.isOwner) || ' + (user.id === self.currentUserId) + '">' +
						'</div>');
				}
			},
			{
				name: 'ROLES.AGENCY_MEMBER',
				noSearch: true,
				filter: 'isMember',
				isClick: true,
				style: { 'text-align': 'center' },
				clickFunction: function(user,i) {
					const isDisabled = AccountService.limitedAccess() || ((user.isAuditor || user.isNotary) && !user.isMember);
					if (!isDisabled) {
						self.changeRole(user.id, { role: 'SB_AGENCY_MEMBER', disabled: true }, !user.isMember, user, 'isMember');
						user.isMember = !user.isMember || false;
					}
				},
				field: function(user) {
					return $sce.trustAsHtml(
						'<div ' + _roleWarning('isMember', false) + '>' + 		// Add hover warning if user is notary or auditor
						'<input class="regular-checkbox grey-disabled" type="checkbox" readonly="true" ' +
						'ng-model="entry.isMember" ng-disabled="limitedAccess || ((entry.isAuditor || entry.isNotary) && !entry.isMember)">' +
						'</div>');
				}
			},
			{
				name: 'ROLES.BROKER',
				noSearch: true,
				filter: 'isBroker',
				isClick: true,
				style: { 'text-align': 'center' },
				onChange: function(user) {
					if (!AccountService.limitedAccess()) {
						if (!user.isBroker) {
							toggleBroker().then(
								(res) => {
									res.role = 'SB_BROKER';
									self.changeRole(user.id, res, true, user, 'isBroker');
								},
								(reason) => {
									user.isBroker = false;
								}
							)
							.catch($log.error)
						}
						else {
							self.changeRole(user.id, { role: 'SB_BROKER', status: 'DISABLED' }, false);
							user.isBroker = false;
							user.status = 'DISABLED';
						}
					}
				},
				clickFunction: function(user,i) {},
				field: function(user) {
					return $sce.trustAsHtml(
						'<div ' + _roleWarning('isBroker', false) + '>' + 		// Add hover warning if user is notary or auditor
						'<input class="regular-checkbox grey-disabled" type="checkbox" ng-click="column.onChange(entry)" ' +
						'ng-model="entry.isBroker" ng-model-options="{ updateOn: \'click\' }" ' +
						'ng-disabled="limitedAccess || ((entry.isAuditor || entry.isNotary) && !entry.isBroker)">' +
						'</div>');
				}
			},
			{
				name: 'ROLES.NOTARY',
				noSearch: true,
				filter: 'isNotary',
				isClick: true,
				style: { 'text-align': 'center' },
				onChange: function(user) {
					if (!AccountService.limitedAccess()) {
						if (!user.isNotary) {
							toggleNotary().then(
								(res) => {
									res.role = 'SB_NOTARY';
									self.changeRole(user.id, res, true, user, 'isNotary');
									user.isNotary = true;
								},
								(reason) => {
									user.isNotary = false;
								}
							)
							.catch($log.error)
						}
						else {
							self.changeRole(user.id, { role: 'SB_NOTARY', disabled: true, expires: new Date() }, false);
							user.isNotary = false;
						}
					}
				},
				clickFunction: function(user,i) {},
				field: function(user) {
					return $sce.trustAsHtml(
						'<div ' + _roleWarning('isNotary', true) + '>' + // Add hover warning if user is owner, member or broker
						'<input class="regular-checkbox grey-disabled" type="checkbox" ng-click="column.onChange(entry)" ' +
						'ng-model="entry.isNotary" ng-model-options="{ updateOn: \'click\' }" ' +
						'ng-disabled="limitedAccess || ((entry.isBroker || entry.isMember || entry.isOwner) && !entry.isNotary)">' +
						'</div>');
				}
			},
			{
				name: 'ROLES.AUDITOR',
				noSearch: true,
				filter: 'isAuditor',
				isClick: true,
				style: { 'text-align': 'center' },
				onChange: function(user){
					if (!AccountService.limitedAccess()) {
						if (!user.isAuditor) {
							toggleAuditor().then(
								(res) => {
									res.role = 'SB_AUDITOR';
									self.changeRole(user.id, res, true, user, 'isAuditor');
									user.isAuditor = true;
								},
								(reason) => {
									user.isAuditor = false;
								}
							)
							.catch($log.error)
						}
						else {
							self.changeRole(user.id, { role: 'SB_AUDITOR', disabled: true, expires: new Date() }, false);
							user.isAuditor = false;
						}
					}
				},
				clickFunction: function(user,i) {},
				field: function(user) {
					return $sce.trustAsHtml(
						'<div ' + _roleWarning('isAuditor', true) + '>' + // Add hover warning if user is owner, member or broker
						'<input class="regular-checkbox grey-disabled" type="checkbox" ng-click="column.onChange(entry)" ' +
						'ng-model="entry.isAuditor" ng-model-options="{ updateOn: \'click\' }" ' +
						'ng-disabled="limitedAccess || ((entry.isBroker || entry.isMember || entry.isOwner) && !entry.isAuditor)">' +
						'</div>');
				}
			},
			{
				name: 'COMMON.INACTIVE',
				noSearch: true,
				filter: '',
				isClick: true,
				onChange: function(user) {
					if (!AccountService.limitedAccess()) {
						if(user.isBroker) {
							const newStatus = (user.status === 'INACTIVE') ? 'ACTIVE' : 'INACTIVE';
							user.status = newStatus;
							self.toggleStatus(user.brokerProfile.id, newStatus);
						}
					}
				},
				clickFunction: function(user, i) {

				},
				field: function() {
					return $sce.trustAsHtml(
						'<div>' + // Add hover warning if user is owner, member or broker
							'<input class="regular-checkbox grey-disabled" type="checkbox" ng-click="column.onChange(entry)" ' +
							'ng-checked="entry.status === \'INACTIVE\'" ng-model-options="{ updateOn: \'click\' }" ' +
							'ng-disabled="limitedAccess || !entry.isBroker">' +
						'</div>');
				}
			},
			{
				name: '',
				noSearch: true,
				noSort: true,
				isHtml: true,
				field: function(user) {
					return '<a tabindex="0" class="btn btn-dots pull-right" uib-popover-template="\'' +
						GlobalVars.popover('roleMenu') + '\'" popover-placement="auto bottom" popover-trigger="\'focus\'"></a>';
				}
			}
		];

		self.toggleStatus = function(id, status) {
			BrokerService.updateBroker(id, { status: status }).then((result) => {
				AlertService.successMessage( 'changedStatus', { status: status } );
			})
			.catch((err) => {
				AlertService.errorMessage( 'changedStatus', { status: status } )
			})
		}

		load();

		// FUNCTIONS ============================================================================================================================
		self.filter = function() {
			if (!allItems) {
				allItems = angular.copy(self.users);
			}
			self.users = $filter('searchSingleFilter')(allItems,self.search);
		}

		$scope.trustAsHtml = function(content) {
			return $sce.trustAsHtml(content);
		}

		self.changeRole = function (userId, updates, activate, object, field) {
			UserService.toggleRole(userId, updates)
			.then((res) => {
				AlertService.success( 'ROLES.ROLE', ( activate ? 'activate' : 'deactivate' ) );
				if (object && field) {
					$timeout(() => {
						object[field] = activate;
						if(field === 'isBroker' && activate && updates.status) {
							object['status'] = updates.status;
						}
					});
				}
			})
			.catch((err) => {
				$log.error(err);
				if (object && field) {
					$timeout(() => {
						object[field] = !activate
					});
				}
			})
		}

		self.openModal = function () {
			const modal = ModalService.openModal('createUser', { type: function(){ return null; }, agencyId:function(){return null} }, 'NewUserController', 'newUserController');
			modal.result.then((res) => {
				if (res.closed) {
					return
				}
				else {
					load();
				}
			})
			.catch((err) => {
			});
		}

		function load() {
			AlertService.loading();
			AgencyService.getUsersAndRoles()
			.then((res) => {
				self.users = res;
				self.users.forEach((item) => {
					if (item.roles) {
						item.roles.forEach((role) => {
							switch(role.id) {
							case 'SB_AGENCY_OWNER': item.isOwner = true; break;
							case 'SB_AGENCY_MEMBER': item.isMember = true; break;
							case 'SB_BROKER': item.isBroker = true; break;
							case 'SB_NOTARY': item.isNotary = true; break;
							case 'SB_AUDITOR': item.isAuditor = true; break;
							}
						})
					}
				})

				SortingService.sort(self.users,self.filters,'fullName',null,page,true);
			})
			.catch($log.error)
			.finally(AlertService.doneLoading)
		}

		function toggleBroker() {
			const fields = [
				{
					label: 'COMMON.STATUS',
					isSelect: true,
					select: {
						options: [
							{ name: $filter('translate')('COMMON.ACTIVE'), value: 'ACTIVE' },
							{ name: $filter('translate')('COMMON.INACTIVE'), value: 'INACTIVE' }
						],
						config: GlobalVars.singleConfig
					},
					model: 'status',
					required: true
				}
			]

			return getModalController(fields, 'ROLES.BROKER');
		}

		function toggleAuditor() {
			const fields = [
				{
					label: 'DATES.EXPIRY_DATE',
					isCalendar: true,
					calendar: GlobalVars.createCalendarConfig(new Date(),null),
					model: 'expires',
					required: true
				}
			]

			return getModalController(fields, 'ROLES.AUDITOR');
		}

		function toggleNotary() {
			const fields = [
				{
					label: 'DATES.EXPIRY_DATE',
					isCalendar: true,
					calendar: GlobalVars.createCalendarConfig(new Date(),null),
					model: 'expires',
					required: true
				}
			]

			return getModalController(fields, 'ROLES.NOTARY');
		}

		function getModalController(fields, title) {

			const modalController = function($uibModalInstance, $scope, $filter, GlobalVars) {
				$scope.model = {};
				$scope.form = fields;
				$scope.submit = function() {
					$uibModalInstance.close($scope.model);
				}
				$scope.title = $filter('translate')('ROLES.ADD_ROLE') + ': ' + $filter('translate')(title);
				$scope.close = function() {
					$uibModalInstance.dismiss('cancel');
				}

			}

			if (!!fields) {
				const modal = ModalService.openModal('generalForm',{},modalController,'controller');
				return modal.result;
			}
			else {
				return $q.when(true);
			}
		}

		function _roleWarning(role, isNotary) {

			role = role || 'isBroker';

			const translate = isNotary ? 'ROLES.CONFLICT_WARNING_NOTARY' : 'ROLES.CONFLICT_WARNING';
			const condition = isNotary ? '(entry.isOwner || entry.isMember || entry.isBroker)' : '(entry.isAuditor || entry.isNotary)';

			return 'uib-popover="' + $filter('translate')(translate) + '" ' +
			'popover-placement="auto top" ' +
			'popover-trigger="\'mouseenter\'" ' +
			'popover-class="popover-warning" ' +
			'popover-enable="(' + condition +' && !entry.' + role + ')"';
		}

		function _notaryWarning(role) {
			role = role || 'isNotary';

			return 'uib-popover="' + $filter('translate')('ROLES.CONFLICT_WARNING') + ' ' +
				$filter('translate')('ROLES.CONFLICT_WARNING_NOTARY') + '" ' +
				'popover-placement="auto top" ' +
				'popover-trigger="\'{{ screenSize === \'xs\' ? \'outsideClick\' : \'mouseenter\'}}\'" ' +
				'popover-class="popover-warning" ' +
				'popover-enable="(entry.isOwner || entry.isMember || entry.isBroker) && !entry.' + role + '"';
		}

	});

})(); // End of function()
