/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('AgencyEditController', function (AccountService, AgencyService, AlertService, GlobalVars, NavService, UploadService, UserService, $filter, $sce, $stateParams, $translate, check) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.agency         = {};       // 'Agency' part of the profile -> Auditor, AgencyMember, AgencyOwner or Notary
		self.member         = {};       // SbUser part of the profile

		self.multiConfig    = null;     // Configuration for selectize dropdown (used with languages)
		self.canUpload      = true;     // Whether user can currently upload an image
		self.canChangeRole  = false;    // Whether role can be changed -> If false, role input is populated with a role and is disabled
		self.uploader       = null;     // Uploader object for profile image
		self.isNew          = true;     // Whether this is a new profile or not
		self.roleOther      = null;

		// SETUP VARIABLES ======================================================================================================================

		self.multiConfig    = GlobalVars.multiConfig;
		self.canChangeRole  = $stateParams.type === 'member';
		self.uploader       = UploadService.initUserUploader(true, null, { w: 640, h: 640 });
		self.isNew          = !$stateParams.id;
		self.calendar       = null;

		self.agencyRole = {
			config: GlobalVars.createSingleConfig(true, 'value', 'name', 'name'),
			options: GlobalVars.agencyMemberRoles
		}

		self.photoOptions = {
			classes: {
				upload: 'btn btn-green no-size upload',
				confirm: 'btn btn-green no-size upload',
				current: 'round avatar',
				new: 'round avatar'
			},
			dimensions: {
				init_w: 640,
				init_h: 640,
				result_w: 640,
				result_h: 640
			},
			checkSize: true
		}

		if ($stateParams.id) {
			getAccount();
		}
		else {
			self.member = {
				languageIds: []
			};
			self.agency = self.member;

			if (!self.canChangeRole) {
				self.agency.agencyRoleId = $filter('translate')('ROLES.' + $stateParams.type.toUpperCase());

				if ($stateParams.type === 'notary' || $stateParams.type === 'auditor') {
					self.calendar = GlobalVars.createCalendarConfig(false);
				}
			}

			check.original = angular.copy(self.member);
			check.clone = self.member;
		}

		// FUNCTIONS ============================================================================================================================

		/**
         *  Saves the current Agency profile
         */
		self.save = function() {
			AlertService.loading();
			check.isSaved = true;

			if (self.agency.agencyRoleId === 'OTHER') {
				self.agency.agencyRoleId = self.roleOther;
			}

			self.member[$stateParams.type] = angular.copy(self.agency);
			self.member.type = $stateParams.type;

			const done = function() {
				const role = 'ROLES.' + $stateParams.type.toUpperCase();
				return $translate(role).then((what) => {
					AlertService.success($sce.valueOf(what),'save');
				}, () => {
					AlertService.success('ROLES.AGENCY', 'save');
				})
				.finally(() => {
					AlertService.doneLoading();
				})
			}

			if (!self.isNew) {
				UserService.updatePerson(self.member)
				.then((res) => {
					done().then(() => {
						if (!self.agency.disabled) {
							NavService.navigateBack();
						}
						else {
							NavService.navigate('main.agency');
						}
					});
				})
				.catch((err) => {
					AlertService.doneLoading();
					AlertService.errorMessage('basic');
					NavService.navigate('main.agency');
				})
			}
			else {
				const account = angular.copy(self.member);
				account.type = $stateParams.type;
				account.agencyId = AccountService.getAgencyId();
				account.mainLanguage = (account.languageIds || [])[0] || 'EN';

				AccountService.createAccount(account).then((acct) => {
					done().then(() => {
						AgencyService.findPersonByAccount(acct.id, account.type).then((res) => {
							if (res.profileId) {
								NavService.navigate('main.agency.account', { id: res.profileId, type: account.type });
							}
							else {
								NavService.navigate('main.agency');
							}
						})
					});
				})
				.catch((err) => {
					console.log('error: ', err);
					AlertService.doneLoading();
				})
			}
		}

		/**
         *  Loads profile information based on $stateParams.type
         */
		function getAccount() {

			let apiCall;
			switch($stateParams.type) {
			case 'member':  apiCall = AgencyService.getMember; break;
			case 'auditor': apiCall = AgencyService.getAuditor; break;
			case 'notary':  apiCall = AgencyService.getNotary; break;
			case 'owner':   apiCall = AgencyService.getOwner; break;
			default: AlertService.errorMessage('accountDetails'); return;
			}

			AlertService.loading();

			apiCall($stateParams.id).then((res) => {
				if (res.length === 1) {
					self.agency = angular.copy(res[0]);
					self.member = angular.copy(res[0].user);
					delete self.agency.user;

					if (!self.canChangeRole) {
						self.agency.agencyRoleId = $filter('translate')('ROLES.' + $stateParams.type.toUpperCase());

						if ($stateParams.type === 'notary' || $stateParams.type === 'auditor') {
							self.calendar = GlobalVars.createCalendarConfig(null, null);

							if (self.agency.expiry) {
								self.agency.expires = angular.copy(self.agency.expiry);
								delete self.agency.expiry;
							}

							self.agency.expires = new Date(self.agency.expires);
						}
					}
					else if (!!self.agency.agencyRoleId) {
						const i = self.agencyRole.options.findIndex(x => x.value == self.agency.agencyRoleId);
						if (i < 0) {
							self.roleOther = self.agency.agencyRoleId;
							self.agency.agencyRoleId = 'OTHER';
						}
					}
				}
				else {
					AlertService.errorMessage('accountDetails');
				}
			})
			.catch((err) => {})
			.finally(() => {
				check.original = {
					member: angular.copy(self.member),
					agency: angular.copy(self.agency)
				}

				check.clone = {
					member: self.member,
					agency: self.agency
				}

				AlertService.doneLoading();
			})
		}
	});
})(); // End of function()
