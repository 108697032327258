/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

(function () {

	'use strict';

	angular.module('smartbrokr.modal', [])
	.service('ModalService', function ($uibModal, $uibModalStack, GlobalVars, $rootScope) {
		const self = this;

		/**
     *  Opens a modal and returns the instance
     *
     *  @param {string}   id            Modal id (as registered in GlobalVars)
     *  @param {object}   resolve       Variables to pass to controller
     *  @param {string}   controller    Name of controller
     *  @param {string}   controllerAs  Name of controller as referenced in html template
     *  @param {object=}  scope         Scope to which modal should be attached (optional)
     *  @param {string=}  windowClass   Name of window class (optional)
     *  @param {string=}  backdropClass Name of backdrop class (optional)
     *  @param {boolean=} noBackdrop    If true, no backdrop is added (optional)
     */
		self.openModal = function(id, resolve, controller, controllerAs, scope, windowClass, backdropClass, noBackdrop) {
			const modalInstance = $uibModal.open({
				animation: true,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: GlobalVars.popover(id) || id,
				controller: controller,
				controllerAs: controllerAs,
				appendTo: angular.element(document.querySelector('#content')),
				scope: (scope || $rootScope),
				resolve: resolve,
				backdrop: !noBackdrop,
				keyboard: false,
				windowClass: windowClass || null,
				backdropClass: backdropClass || null
			});

			modalInstance.result.then(
				(result) => {},
				(reason) => {}
			);

			return modalInstance;
		};

		self.openModalWithFunction = function(id,resolve,controller,controllerAs,retFunction,scope, windowClass, retOnError) {

			const modalInstance = self.openModal(id,resolve,controller,controllerAs,scope, windowClass);

			modalInstance.result.then(
				(result) => { retFunction(result); },
				(reason) => {
					if (retOnError) {
						retFunction();
					}
				}
			)
			.catch((err) => {
				if (retOnError) {
					retFunction();
				}
			});

			return modalInstance;
		};

		/**
    * Opens a generic alert modal.
    * Can display one or two buttons.
    * 'Ok' button executes a function on closing and is green.
    * 'Cancel' button just closes the modal and is white.
    *
    * @param {string}   message     Message to display or label code for translation
    * @param {string}   title       String to display in the modal title area (or label code for translation)
    * @param {string}   cancelBtn   String to display in the cancel button (or label code for translation)
    * @param {string}   okBtn       String to display in the cancel button (or label code for translation)
    * @param {function} okFunction  Function to execute when 'Ok' button is clicked
    * @param {boolean}  isAlert     Whether it's an alert modal. If true, backdrop clicking is disabled
    * @param {string}   titleClass  Class of the title area. Defaults to 'warning'.
    */
		self.prompt = function(message, title, cancelBtn, okBtn, okFunction, isAlert, titleClass) {

			const controller = function($uibModalInstance, $scope) {

				$scope.message = message;
				$scope.title = title || 'ALERT_MESSAGES.ALERT.WARNING';
				$scope.isAlert = isAlert;
				$scope.cancelBtn = cancelBtn;
				$scope.okBtn = okBtn;
				$scope.titleClass = titleClass || 'warning';

				$scope.close = function() {
					$uibModalInstance.dismiss('cancel');
				}

				$scope.ok = function() {
					$uibModalInstance.close(true);
				}
			}

			const modalInstance = $uibModal.open({
				animation: true,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'js/src/templates/modal/prompt.html',
				controller: controller,
				controllerAs: 'controller',
				appendTo: angular.element(document.querySelector('#content')),
				scope: $rootScope,
				resolve: {},
				backdrop: isAlert ? 'static' : true,
				keyboard: false,
				windowTopClass: 'prompt-window'
			});

			modalInstance.result.then(
				(result) => {
					if (okFunction) {
						okFunction();
					}
				},
				() => {})
			.catch(angular.noop)

			return modalInstance;
		}

		self.confirmDelete = function(delFunction, message, title) {
			const modal = self.prompt(
				message,
				(title || 'COMMON.DELETE'),
				'FORMS.CANCEL',
				'FORMS.CONFIRM',
				null, false, 'modal-header'
			);

			return modal.result.then(
				(result) => {
					return delFunction();
				},
				(reason) => {});
		}

		self.listingSummary = function(id) {
			self.openModal(
				'listingSummary',
				{
					listingId: function() { return id; }
				},
				'ListingSummaryController',
				'summaryController' );
		}

		/**
     *  Opens a modal with a general form.
     *  Returns value submitted in the form.
     *  Field format:
     *    - label:      string  - translate code for field label
     *    - class:      string= - CSS class for this field group (added to form-group div)
     *    - isCalendar: boolean - if true, will add a calendar input
     *    - calendar:   object  - config for calendar
     *    - model:      string  - name of field model (ngModel)
     *    - required:   boolean - whether field is required
     *    - isSelect:   boolean - if true, will add Selectize input
     *    - select:     object
     *      - options:  object[]  - array of options for select input
     *      - config:   object  - selectize config
     *    - justText:   boolean - if current field is just for text
     *    - labels:     string[] - text for field (used if justText = true)
     *    - inputType:  string  - If not isSelect and not isCalendar, a regular input is created with type = inputType,
     *    - step:       number  - ngStep - for inputs of type number
     *    - min:        number  - ngMin - for inputs of type number
     *    - onChange:   function - Function to trigger on ngChange (signature: function(value) )
     *
     *  @param    {Object[]}          fields      Array of fields to be used in the form
     *  @param    {(string|Object)}   title       Title displayed in the modal. If object, must have format { label: string, vars: object } to be used with translate filter
     *  @param    {boolean}           canCancel   If true, adds a cancel button to form
     *  @param    {object=}           model       Object to be populated in the form (or null)
     *  @param    {string=}           templateId  Template ID to use for modal - from GlobalVars.templates (default: 'generalForm')
     *  @param    {string=}           windowClass Name of class to apply to modal window
     *  @returns  {Promise}
     */
		self.openForm = function(fields, title, canCancel, model, templateId, windowClass) {
			const modalController = function($uibModalInstance, $scope, $filter) {
				$scope.model =  model || {};
				$scope.form = fields;
				$scope.canCancel = canCancel;
				$scope.submit = function() {
					$uibModalInstance.close($scope.model);
				}

				if (typeof title === 'string') {
					$scope.title = title;
				}
				else if (!!title && typeof title === 'object' && title.label) {
					$scope.title = $filter('translate')(title.label, title.vars);
				}
				else {
					$scope.title = '';
				}

				$scope.close = function() {
					$uibModalInstance.dismiss('cancel');
				}
			}

			templateId = templateId || 'generalForm';

			if (!!fields) {
				const modal = self.openModal(templateId,{},modalController,'controller',null, windowClass);
				return modal.result;
			}
			else {
				return $q.when(true);
			}
		}


		function isDirtyCheck(check) {

			if (!check) return false;

			check = check || {};

			if (check.isConfirmed) return false;

			if (check.original && check.clone) {
				const original = angular.copy(check.original);
				const clone = angular.copy(check.clone);
				const ignore = check.ignore || [];

				ignore.forEach((toIgnore) => {
					if (typeof toIgnore === 'object') {
						const keys = _.keys(toIgnore);
						delIgnored(clone,toIgnore,keys[0]);
						delIgnored(original,toIgnore,keys[0]);
					}
					else if (typeof toIgnore === 'string') {
						delete original[toIgnore];
						delete clone[toIgnore];
					}
				})

				return !_.isEqual(original,clone);
			}
			else {
				return false;
			}

			/* Deletes fields that should be ignored in an object
        * Only works for one property for each object
        * Ex: ignore: { listing: { property: '_address' } }
        *      ->  Will delete listing.property._address from clone and original
        *          so this field is not used when comparing them */
			function delIgnored(obj, curr, key) {
				if (!!curr[key] && typeof curr[key] === 'object') {
					const keys = _.keys(curr[key]);
					if (keys.length > 0) {
						delIgnored(obj[key],curr[key], keys[0]);
					}
					else {
						delete obj[key];
						return;
					}
				}
				else if (!Array.isArray(obj[key]) && typeof obj[key] === 'object'){
					delete obj[key][curr[key]];
					return;
				}
				else {
					console.warn('Ignore from array not implemented yet.');
				}
			}

		}

		$rootScope.checkClosingModal = function(event, check) {
			if (check && isDirtyCheck(check)) {
				event.preventDefault();
				const ok = function() {
					check.isConfirmed = true;
					$uibModalStack.dismissAll('confirmed');
				}

				self.prompt('ALERT_MESSAGES.ALERT.CHANGES_NOT_SAVED',
					'COMMON.ARE_YOU_SURE',
					'FORMS.CANCEL', 'Ok', ok, true);
			}
		}


	});   // End of service
})();  // End of function()
