/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function() {

	'use strict';

	angular.module('smartbrokr.directives')
	.controller('MobileListController', ['$filter', '$scope', function($filter, $scope) {

		const self = this;

		self.search     = '';
		let allItems    = $scope.entries;
		self.show       = allItems;

		if ($scope.inject) {
			$scope[$scope.inject.name] = $scope.inject.inject;
		}

		self.filter = function() {
			self.show = $filter('searchSingleFilter')(allItems,self.search,$scope.sortInfo || null);
		}

		$scope.$watch('entries',() => {
			allItems    = $scope.entries || [];
			self.show   = allItems;
			self.filter();
		});
	}])

	/* DIRECTIVES =========================================================================================  */

	.directive('sbMobileList', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/directives/mobileList/mobile-list-cards.html',
			controller: 'MobileListController',
			controllerAs: 'mobileListController',
			scope: {
				entries: '=',
				model: '=',
				sortInfo: '=?',
				addLegends: '=',
				inject: '=?',
				isListing: '='
			},
			link: function (scope, element, attrs) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
				scope.perPage = 20;
				scope.language = scope.$root.language;
			}
		};
	})

	.directive('sbMobileLines', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/directives/mobileList/mobile-list-lines.html',
			controller: 'MobileListController',
			controllerAs: 'mobileListController',
			scope: {
				entries: '=',
				sortInfo: '=?',
				addLegends: '=',
				inject: '=?',
				columns: '=',
				filterCats: '='
			},
			link: function (scope, element, attrs) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
				scope.perPage = 20;
				scope.language = scope.$root.language;
			}
		};
	})
})();
