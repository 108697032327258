(function () {

	"use strict";

	angular.module("smartbrokr")

	.controller("LanguageController", function (LanguageService, AlertService, AccountService, GlobalVars, NavService, $filter, $rootScope, $timeout, check, ModalService) {

		var self = this;

		// VARIABLES ============================================================================================================================

		self.main			= null;		// User's main language
		self.userLanguages 	= [];		// Languages that the user has (excluding main language)

		self.languages 		= null;		// Options for dropdowns
		self.languagesMain 	= null;		// Options for main languages (only include English and French)
		self.config			= null;		// Config for selectize
		self.config2		= null;		// Config for selectize (secondary languages)
		var oldMain 		= null;		// Main language that was selected on first load
		

		// SETUP VARIABLES ======================================================================================================================
		self.config 		= GlobalVars.createSingleConfig(false, 'id', 'nameNative', 'nameNative', false, null, 'nativeRoman');
		self.config2 		= GlobalVars.createSingleConfig(false, 'id', 'nameNative', 'nameNative', false, null, 'nativeRoman');

		self.config2.onChange = function(value) {
			if (value === self.main) {
				self.userLanguages.splice(0, 1);
			}
		}

		var loadingLangs 	= true;
		var loadingAcct 	= true;

		AlertService.loading();
		loadLangs();
		loadAccount();

		// FUNCTIONS ============================================================================================================================

		self.removeLanguage = function (i) {
			var msg = $filter('translate')('ALERT_MESSAGES.ALERT.DELETE_LANG');
			var modal = ModalService.openModal('confirm', { message: function () { return msg; } }, 'ConfirmController', 'confirmController');
			
			modal.result
			.then(function (res) {
				self.userLanguages.splice(i, 1);
			})
			.catch(function (err) {});
		}

		self.checkLanguages = function(old) {
			var index = self.userLanguages.findIndex(function(item){
				return item.id === self.main;
			})

			if (index >= 0) {
				self.userLanguages.splice(index, 1);
			}

			index = self.userLanguages.findIndex(function(item) {
				return item.id === old;
			})

			if (index < 0) {
				var lang = self.languages.findIndex(function(item) {
					return item.id === old;
				})

				if (lang >= 0) {
					var toAdd = angular.copy(self.languages[lang]);
					delete toAdd.$order;
					self.userLanguages.push(toAdd);
				}
			}

			check.clone.main = self.main;
		}

		self.saveLanguages = function () {
			check.isSaved = true;
			AccountService.saveMainLanguage(self.main).then(function(res){

				var languageIds = [];
				var i, length = self.userLanguages.length;
				var found = false;

				for (i = 0; i < length; i++) {
					if (self.userLanguages[i].id) {

						languageIds.push(self.userLanguages[i].id);
						
						if (self.userLanguages[i].id == self.main) {
							found = true;
						}
					}
				}

				if (!found) {
					languageIds.unshift(self.main);
				}

				AccountService.saveLanguages(languageIds).then(function(res2){		
					$rootScope.$emit('updateAccount');
					
					if (oldMain !== self.main) {
						$timeout(function(){
							NavService.changeLanguage(self.main);
						}, 100);
					}
					else {
						AlertService.saved();
						loadAccount(true)
					}
				})
				.catch(function (err) {
					console.log("err SaveLanguages: ", err);
				})
			})
			.catch(function (err) {
				console.log("err SaveMain: ", err);
			})
		}

		function checkLoading() {
			if (!loadingLangs && !loadingAcct) {
				AlertService.doneLoading();
			}
		}

		function loadLangs() {
			LanguageService.getLanguages().then(function (res) {

				var langs = res.reduce(function(arr, curr) {

					if (curr.nativeRoman) {
						curr.nameNative += ' (' + curr.nativeRoman + ')';
					}
					else {
						curr.nativeRoman = curr.nameNative;
					}
					arr.push(curr);

					return arr;
				}, [])

				self.languages = langs;
				self.languagesMain = langs.reduce(function(arr, curr) {

					if (($rootScope.TRANSLATABLE || []).indexOf(curr.id) >= 0) {
						arr.push(curr);
					}

					return arr;
				}, []);
			})
			.catch(function (err) {})
			.finally(function() {
				loadingLangs = false; checkLoading();
			})
		}

		function loadAccount(skipCheckLoading) {
			AccountService.getLanguages().then(function(langs){
				
				self.main = AccountService.getMainLanguage();
	
				if (!self.main) {
					self.main = ((langs || [])[0] || {}).id;
					AccountService.saveMainLanguage(self.main).then(function(res){
						$rootScope.$emit('updateAccount');
					});
				}
	
				oldMain = angular.copy(self.main);

				self.userLanguages = langs.reduce(function(arr,lang){
					if (lang.id != self.main) {
						arr.push(lang);
					}
					return arr;
				}, []);
			})
			.catch(function (err) {})
			.finally(function () {

				check.clone = {
					main: self.main,
					userLanguages: self.userLanguages
				}

				check.original = angular.copy(check.clone);

				if (!skipCheckLoading) {
					loadingAcct = false; 
					checkLoading();
				}
			})
		}
	});
})(); // End of function()
