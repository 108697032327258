(function () {

    "use strict";

    angular.module("smartbrokr")

    .controller("InviteStaffController", function ($state, AccountService, GlobalVars, $translate,$document, $timeout) {

        var self = this;

        self.visitedReview  = AccountService.getVisitedReview();
        self.ownerEmail     = AccountService.getOwnerEmail() || "";
        self.data           = AccountService.getInviteStaff() || "";
        self.staffEmails    = [];
        self.warning        = '';
        
        $translate('PAGES.SIGNUP.INVITE_STAFF.PLACEHOLDER')
        .then(function(res){
            self.placeholder = res;
        })
        .catch(function(err){})

        self.next = function () {
            self.update(true);
            var toCheck = angular.copy(self.staffEmails);
            toCheck.push(self.ownerEmail);

            // Check if any email is already registered with us
            GlobalVars.runPromises(AccountService.isEmailRegistered, toCheck).then(function(res){
                self.emailError = {
                    isError: false,
                    emails: []
                };
                self.errorEmails = [];

                for (var email in res) {
                    if (res[email].response === true) {
                        if (!self.emailError.isError) {
                            self.emailError.isError = true;
                        }
                        if (self.staffEmails.indexOf(email) >= 0) {
                            self.errorEmails.push(email);
                        }
                        self.emailError.emails.push(email);
                    }
                }

                if (!self.emailError.isError) {
                    AccountService.saveInviteStaff(self.data);
                    AccountService.saveOwnerEmail(self.ownerEmail);
                    $state.go('main.signup.situation');
                }
            })
        }
        
        self.prev = function () {
            self.update(true);
            AccountService.saveInviteStaff(self.data);
            AccountService.saveOwnerEmail(self.ownerEmail);
            $state.go('main.signup.invite-brokers');
        }

        self.checkOwner = function() {
            if (self.emailError) {
                self.emailError.isError = self.emailError.isError || self.emailError.emails.indexOf(self.ownerEmail) > 0;
            }
        }

        self.update = function(updateData){
            var emails = self.data.split(',');
            self.staffEmails = [];

            emails.forEach(function(email,i) {
                email = (email || '').trim();
                if (email !== '' && self.staffEmails.indexOf(email) < 0) {
                    self.staffEmails.push(email);
                }
                else if (email !== '') {
                    self.data = self.data.replace(email + ',','');
                    self.warning = 'Removed duplicated email: ' + email;
                }
            })

            $timeout(function(){
                self.warning = '';
            }, 10000);

            if (updateData) {
                self.data = self.staffEmails.toString();
            }
            else {
                // Update emails with error
                if (((self.emailError || {}).emails || []).length > 0) {
                    var isStillError = false;
                    self.emailError.emails.forEach(function(email){
                        if (self.staffEmails.indexOf(email) >= 0) {
                            isStillError = isStillError || true;

                            if (self.errorEmails.indexOf(email) < 0) {
                                self.errorEmails.push(email);
                            }
                        }
                        else if (self.errorEmails.indexOf(email) >= 0){
                            self.errorEmails.splice(self.errorEmails.indexOf(email),1);
                        }
                    });
                    self.emailError.isError = isStillError;
                }
            }
        }
        
        var inputs = $document.find('input.field');
        Array.prototype.forEach.call(inputs, function (input) {
            input.addEventListener('focus', function () {
                input.classList.add('is-focused');
            });
            input.addEventListener('blur', function () {
                input.classList.remove('is-focused');
            });
        });

        self.update();

    });
})();
