(function () {

	'use strict';

	angular.module('smartbrokr.static', [])

	.service('StaticService', function (Code, CacheFactory, LocationService, AlertService, $filter, $rootScope) {

		const self = this;

		const realtorFeatures = [
			'AMENITIES',
			'AMENITIES_NEARBY',
			'AMPERAGE',
			'BASEMENT_DEVELOPMENT',
			'BASEMENT_FEATURES',
			'BASEMENT_TYPE',
			'CEILING_TYPE',
			'CLEAR_CEILING_HEIGHT',
			'COMMUNICATION_TYPE',
			'COMMUNITY_FEATURES',
			'CONSTRUCTION_MATERIAL',
			'COOLING_TYPE',
			'CROP',
			'EASEMENT',
			'EQUIPMENT_TYPE',
			'EXTERIOR_FINISH',
			'FARM_TYPE',
			'FEATURES',
			'FIREPLACE_FUEL',
			'FIREPLACE_TYPE',
			'FIRE_PROTECTION',
			'FIXTURE',
			'FLOORING_TYPE',
			'FOUNDATION_TYPE',
			'HEATING_FUEL',
			'HEATING_TYPE',
			'IRRIGATION_TYPE',
			'LIVE_STOCK_TYPE',
			'LOADING_TYPE',
			'MACHINERY',
			'POOL_FEATURES',
			'POOL_TYPE',
			'RENTAL_EQUIPMENT_TYPE',
			'RIGHT_TYPE',
			'ROAD_TYPE',
			'ROOF_MATERIAL',
			'ROOF_STYLE',
			'SIGN_TYPE',
			'STORAGE_TYPE',
			'STRUCTURE_TYPE',
			'STORE_FRONT',
			'UTILITY_POWER',
			'UTILITY_WATER',
			'ACCESS_TYPE',
			'CURRENT_USE',
			'FENCE_TYPE',
			'LAND_DISPOSITION_TYPE',
			'LANDSCAPE_FEATURES',
			'SEWER',
			'SOIL_TYPE',
			'SURFACE_WATER',
			'TOPOGRAPHY_TYPE',
			'VIEW_TYPE',
			'WATER_FRONT_TYPE',
			'ZONING_TYPE'
		];

		const domains = {
			centris: {
				propertyTypes: { and: [{ type: 'PROPERTY_TYPE' }, { centris: true }] },
				buildingTypes: { and: [{ type: 'BUILDING_TYPE' }, { centris: true }] },
				featureTypes: { and: [{ type: 'FEATURE_TYPE' }, { centris: true }] },
				featureValues: { and: [{ type: 'FEATURE_VALUE' }, { centris: true }] },
				suppIndustryTypes: { type: 'SUPPLIER_TYPE' },
				roomTypes: { and: [{ type: 'ROOM_TYPE' }, { centris: true }] },
				floorTypes: { and: [{ type: 'ROOM_LEVEL' }, { centris: true }] },
				floorCover: { and: [{ type: 'FLOORING_TYPE' }, { centris: true }] },
				measureUnits: { and: [{ type: 'MEASURE_UNIT' }, { centris: true }] },
				costTypes: { and: [{ type: 'COST_TYPE' }, { centris: true }] },
				propertyStyles: { and: [{ type: 'ARCHITECTURAL_STYLE' }, { centris: true }] },
				licenseTypes: { type: 'BROKER_CERT' },
				agencyMemberRoles: { type: 'AGENCY_MEMBER_ROLE' }
			},
			realtor: {
				propertyTypes: { and: [{ type: 'PROPERTY_TYPE' }, { realtor: true }, { id: { nlike: '.*_MAIN' } }] },
				buildingTypes: { and: [{ type: 'BUILDING_TYPE' }, { realtor: true }, { id: { nlike: '.*_MAIN' } }] },
				featureTypes: { and: [{ type: { inq: realtorFeatures } }, { realtor: true }, { id: { like: '.*_MAIN' } }] },
				featureValues: { and: [{ type: { inq: realtorFeatures } }, { realtor: true }, { id: { nlike: '.*_MAIN' } }] },
				suppIndustryTypes: { type: 'SUPPLIER_TYPE' },
				roomTypes: { and: [{ type: 'ROOM_TYPE' }, { realtor: true }, { id: { nlike: '.*_MAIN' } }] },
				floorTypes: { and: [{ type: 'ROOM_LEVEL' }, { realtor: true }, { id: { nlike: '.*_MAIN' } }] },
				floorCover: { and: [{ type: 'FLOORING_TYPE' }, { realtor: true }, { id: { nlike: '.*_MAIN' } }] },
				measureUnits: { and: [{ type: 'MEASURE_UNIT' }, { realtor: true }, { id: { nlike: '.*_MAIN' } }] },
				costTypes: { and: [{ type: 'COST_TYPE' }, { realtor: true }, { id: { nlike: '.*_MAIN' } }] },
				propertyStyles: { and: [{ type: 'ARCHITECTURAL_STYLE' }, { realtor: true }, { id: { nlike: '.*_MAIN' } }] },
				licenseTypes: { type: 'BROKER_CERT' },
				agencyMemberRoles: { type: 'AGENCY_MEMBER_ROLE' }
			}
		}

		const retrieveCodes = function (type, schema) {
			const filter = {
				where: domains[schema][type]
			};
			return Code.find({ filter: filter }).$promise;
		}

		const retrieveCities = function () {
			return LocationService.getCountries();
		}

		//self.check = function(type, schema){}
		const cache_name = 'smartbrokr';

		const cache_options = {
			deleteOnExpire: 'none',
			recycleFreq: 1000,
			storageMode: 'localStorage'
		}

		let cache = null;
		let memStorage = {};    // Used in case we use up all cache memory (safari has a smaller cache capacity than chrome)

		self.initCodes = function (type, schema) {
			const key     = '' + type + '%' + schema;
			const cached  = cache.get(key);
			let retrieved = null;
			if ((cached || []).length == 0) {
				retrieveCodes(type, schema)
				.then((res) => {
					retrieved = res;
					cache.put(key, res);
				})
				.catch((err) => {
					if (retrieved) {
						memStorage[key] = retrieved;
					}
				});
			}
		}

		self.initCache = function () {
			memStorage = {};
			if (!CacheFactory.get(cache_name)) {
				cache = CacheFactory.createCache(cache_name, cache_options);
			}
			else {
				cache = CacheFactory.get(cache_name);
			}
		}

		self.getCodes = function (type, schema) {
			const key = '' + type + '%' + (schema || 'centris');
			return (cache ? cache.get(key) : memStorage[key]) || memStorage[key];
		}

		self.queryCodes = function(type, schema) {
			const filter = {
				where: {
					type: type
				}
			}

			if (schema) {
				filter.where[schema] = true;
			}
			return Code.find({ filter: filter }).$promise;
		}

		self.init = function () {
			self.initCache();
			const schemas = ['centris', 'realtor'];
			const codes = [
				'propertyTypes',
				'buildingTypes',
				'featureTypes',
				'featureValues',
				'suppIndustryTypes',
				'roomTypes',
				'floorTypes',
				'floorCover',
				'measureUnits',
				'costTypes',
				'propertyStyles',
				'licenseTypes',
				'agencyMemberRoles'
			]

			for (const h in schemas) {
				for (const i in codes) {
					self.initCodes(codes[i], schemas[h]);
				}
			}
			self.initCities();
		}

		self.initCities = function () {
			const key     = 'countries';
			const cached  = cache.get(key);
			let retrieved = null;

			if ((cached || []).length == 0) {

				if ($rootScope.loggedIn) {
					AlertService.loading();
				}

				LocationService.getCountries()
				.then((res) => {
					retrieved = res;
					cache.put(key, res);
				})
				.catch((err) => {
					if (retrieved) {
						memStorage[key] = retrieved;
					}
				})
				.finally(() => {
					AlertService.doneLoading();
				})
			}
		}

		self.getCountries = function (noOther, addEmpty) {
			let countries  = cache.get('countries') || memStorage.countries;

			if (!countries) {
				return null;
			}

			countries = countries.reduce((arr, country) => {
				if ((noOther && country.id !== 'OTHER') || !noOther) {
					country.nameEn = $filter('translate')('COUNTRIES.' + country.id);
					arr.push(country);
				}

				return arr;
			}, []);

			if (addEmpty) {
				countries.unshift({ id: '', nameEn: '------' })
			}

			return countries;
		}

		self.storeAdvancedSearch = function(params) {
			try {
				cache.put('advSearch', params);
			}
			catch(err) {
				memStorage.advSearch = params;
			}
		}

		self.getAdvancedSearch = function() {
			return cache.get('advSearch') || memStorage.advSearch;
		}

		self.resetAdvancedSearch = function() {
			cache.remove('advSearch');
			memStorage.advSearch = null;
		}

		self.clearCache = function() {
			CacheFactory.destroyAll();
			localStorage.clear();
		}
	}); // End of controller
})(); // End of function()
