 (function() {

 	"use strict";

 	angular.module("smartbrokr")

 	.controller("AddSupplierController", function($log, $rootScope, $scope, $stateParams, check, AccountService, AlertService, GlobalVars, ModalService, NavService, OwnerService, SupplierService, UploadService, UserService) {
 		var self = this;

    // VARIABLES ============================================================================================================================

    self.newSupplier      = null;   // Supplier that will be added
    self.owner            = null;   // newSupplier's owner (SbUser object)
    self.selected         = {};
    self.note             = {};     // Note that will be created along with supplier

    self.industries       = null;   // Options for supplier industry dropdown
    self.singleConfig     = null;   // Selectize config
    self.isSeller         = AccountService.getRole() === 'sellerProfile';

    var next, listingId;

    // SETUP VARIABLES ======================================================================================================================

    self.singleConfig = GlobalVars.singleConfig;
    self.industries   = GlobalVars.suppIndustryTypes;
    self.uploader     = UploadService.initUserUploader(true, null, {});
    listingId         = $stateParams.listingId;

    next = {
      state: 'main.suppliers.profile',
      params: function(res) {
        return { id: res.supplierId || res.id }
      },
      wipe: true
    }

    self.photoOptions = {
      classes: {
        upload: 'btn btn-green no-size upload',
        confirm: 'btn btn-green no-size upload',
        current: 'round avatar',
        new: 'round avatar'
      },
      dimensions: {
        init_w: 60,
        init_h: 60,
        result_w: 480,
        result_h: 480
      }
    }

    if (!$stateParams.id) {
      self.newSupplier = {
        rate: '',
        info: ''
      };

      if (listingId) {
        self.newSupplier.listingId = listingId;

        if ($stateParams.listingId) {
          next.state = 'main.listings.detail.suppliers';
          next.params = function(res) {
            return { id: $stateParams.listingId }
          }
        }
        else {
          next.state = 'main.suppliers';
          next.params = function(res) {
            return {};
          }
        }

        next.wipe = false;
      }

      self.owner = {
        languageIds: [],
        emailEnabled: false
      }

      AccountService.getAccountDetails().then(function(res) {
        res = res || {};
        self.owner._address = {
          country: (((res.user || {})._address || {}).country || null),
          province: (((res.user || {})._address || {}).province || null),
          useString: true,
        };
      })
      .finally(function(){
        check.clone = {
          supplier: self.newSupplier,
          owner: self.owner
        };

        check.original = angular.copy(check.clone);

        var listener = $rootScope.$on('doneAddress', function(){
          var addr = self.owner._address;
          check.original.owner._address = angular.copy(addr);
          check.clone.owner._address = addr;
        })

        $scope.$on('$destroy', function(){
          listener();
        })
      })
    }
    else {
      getSupplier();
    }

    // FUNCTIONS ============================================================================================================================
    // Open modal to use existing user
  	self.openModal = function (template, newPerson) {

      var retFunction = function(response) {
        if (response.person != null) {
          self.owner = response.person;
          check.clone.owner = self.owner;
        }
        self.useExisting = response.useExisting;
      }
      ModalService.openModalWithFunction(template, {newPerson: function() { return newPerson; }},'DuplicateUserController','duplicateUserController',retFunction);
    };


    self.save = function() {
      AlertService.loading();
      check.isSaved = true;

      if (!!self.owner.languageIds) {
        self.newSupplier.languageId = self.owner.languageIds[0];
      }


      if(self.owner._address) {
        self.owner._address.useString = true;
      }
      self.newSupplier.person = angular.copy(self.owner);
        SupplierService.createSupplier(self.newSupplier).then(function(res){
          if (self.note.text != undefined && self.note.text != "") {
            var note = self.note;
            note.header = "Supplier: " + res.company;
            note.aboutId = res.ownerId;
            note.aboutType = "SbUser";

            UserService.saveNote(note).then(function(resNote){}).catch($log.error);
          }
          AlertService.success('ROLES.SUPPLIER','save');
          AlertService.doneLoading();
          NavService.navigate(next.state,next.params(res),next.wipe);
        })
        .catch(function(err) {
          $log.error(err);
          AlertService.doneLoading();
        })
    }

    function getSupplier() {
      AlertService.loading();
      var api = SupplierService.getMySupplier;
      var id  = $stateParams.id;

      if (self.isSeller) {
        api = OwnerService.getSuppliers;
      }

      api(id).then(function (res) {
        if (Array.isArray(res)) {
          res = res[0] || {};
        }

        self.newSupplier = res;
        self.owner = self.newSupplier.owner;

        check.clone = self.newSupplier;
        check.original = angular.copy(self.newSupplier);
      })
      .catch(function (err) {})
      .finally(function () {

        var listener = $rootScope.$on('doneAddress', function(){
          var addr = self.newSupplier.owner._address;
          check.original.owner._address = angular.copy(addr);
          check.clone.owner._address = addr;
        })

        $scope.$on('$destroy', function(){
          listener();
        })

        if (!self.newSupplier.isMine) {
          NavService.navigateStack(-1);
        }

        AlertService.doneLoading();
      })
    }

 	}) // End of controller
 })(); // End of function()
