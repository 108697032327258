/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('ReviewController', function ($state, $rootScope, $anchorScroll, $location, AccountService, LocationService, AlertService) {

		const self = this;

		// VARIABLES ============================================================================================================================
		self.isAgency;          // Whether we are signing up an agency
		self.start;             // Information entered in the 'start' page ('main.signup.start-signup')
		self.you;               // Information entered in the 'Tell us about yourself' page ('main.signup.tell-us-about-yourself')
		self.permissions;       // Information entered in 'main.signup.permissions'
		self.brokers;           // Information entered in 'main.signup.invite-brokers'    ** Agency only **
		self.staff;             // Information entered in 'main.signup.invite-staff'      ** Agency only **
		self.billing;           // Information entered in 'main.signup.finish' (credit card information)

		self.token;             // Stripe token created from credit card information

		// SETUP VARIABLES ======================================================================================================================

		$rootScope.$emit('loadingStart');

		AccountService.setVisitedReview();

		const websiteOptions = [
			'PAGES.SIGNUP.GENERAL.NEED_WEBSITE',
			'PAGES.SIGNUP.GENERAL.HAVE_WORDPRESS',
			'PAGES.SIGNUP.GENERAL.HAVE_CUSTOM_WEBSITE'
		]; // Options from 'Website' page ('main.signup.situation')

		self.isAgency   = $state.params.type == 'agency';
		self.start      = AccountService.getStart();
		self.you        = AccountService.getTellUs();
		self.brokers    = AccountService.getInviteBrokers().split(',');
		self.staff      = AccountService.getInviteStaff().split(',');
		self.card       = AccountService.getCreditCard();
		self.token      = AccountService.getStripeToken();
		self.billing    = AccountService.getBillingAddress();
		self.plan       = AccountService.getPlan();
		self.startDate  = new Date(+new Date + 12096e5).toDateString();

		self.website    = {
			option:   AccountService.getWebsite(),
			text:     AccountService.getWebsite() ? websiteOptions[AccountService.getWebsite() - 1] : 'PAGES.SIGNUP.REVIEW.NO_SITUATION'
		}

		self.permissions = {
			result:   !!AccountService.getPermissions(),
			text:     !!AccountService.getPermissions() ? 'PAGES.SIGNUP.PERMISSIONS.AGENCY_COPY' : 'PAGES.SIGNUP.PERMISSIONS.BROKER_COPY'
		}

		let i, j, length = self.brokers.length;
		for (i = 0; i < length; i++) {
			const broker = self.brokers[i];
			if (broker === '') {
				self.brokers.splice(i, 1);
			}
		}

		length = self.staff.length;
		for (j = 0; j < length; j++) {
			const agency = self.staff[j];
			if (agency === '') {
				self.staff.splice(j, 1);
			}
		}

		self.loading  = {
			country:    false,
			province:   false,
			region:     false,
			city:       false
		}

		setupAddress();

		// FUNCTIONS ============================================================================================================================

		/**
     *  Submits form and calls signup function in the backend.
     *  If successful, navigates to next page ('main.signup.complete')
     */
		self.submit = function () {
			$rootScope.$emit('loadingStart');

			AccountService.signup($state.params.type, { token: self.token, plan: self.plan, metadata: self.card })
			.then((res) => {
				AccountService.clear();
				$state.go('main.signup.complete');
			})
			.catch((err) => {
				console.error(err);
				$location.hash('error');
				$anchorScroll.yOffset = 300;
				$anchorScroll();
				if (self.token){
					delete self.token;
					AccountService.saveStripeToken(null);
				}
			})
			.finally(() => {
				$rootScope.$emit('loadingEnd');
			});
		}

		/**
     *  Checks if loading is done.
     */
		function checkLoading() {
			if (self.loading.country && self.loading.province && self.loading.region && self.loading.city) {
				$rootScope.$emit('loadingEnd');
			}
		}

		/**
     *  Gets address information based on ids setup in the billing address
     */
		function setupAddress() {
			if (self.billing.address) {
				if (self.billing.address.countryId) {
					LocationService.getCountry(self.billing.address.countryId)
					.then((country) => {
						self.billing.country = country
					})
					.catch((err) => {})
					.finally(() => {
						self.loading.country = true;
						checkLoading();
					})
				}
				else {
					self.loading.country = true;
					checkLoading();
				}

				if (self.billing.address.provinceId) {
					LocationService.getProvince(self.billing.address.provinceId)
					.then((province) => {
						self.billing.province = province;
					})
					.catch((err) => {})
					.finally(() => {
						self.loading.province = true;
						checkLoading();
					})
				}
				else {
					self.loading.province = true;
					checkLoading();
				}

				if (self.billing.address.regionId) {
					LocationService.getRegion(self.billing.address.regionId)
					.then((region) => {
						self.billing.region = region;
					})
					.catch((err) => {})
					.finally(() => {
						self.loading.region = true;
						checkLoading();
					})
				}
				else {
					self.loading.region = true;
					checkLoading();
				}
				if (self.billing.address.cityId) {
					LocationService.getCity(self.billing.address.cityId)
					.then((city) => {
						self.billing.city = city;
					})
					.catch((err) => {})
					.finally(() => {
						self.loading.city = true;
						checkLoading();
					})
				}
				else {
					self.loading.city = true;
					checkLoading();
				}
			}
			else {
				self.loading = {
					country:  true,
					province: true,
					region:   true,
					city:     true
				}
				checkLoading();
			}
		}

	});
})();
