/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('SendEmailController', function ($filter, $log, $q, $rootScope, $scope, $uibModalInstance, AccountService, AdminService, AlertService, FileService, GlobalVars, ResourceService, UploadService, UserService, params) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.email          = null;   // Email that will be sent
		self.loading        = true;  // False when data loading is finished
		self.users          = [];     // All users we can send an email to
		self.ccUsers        = [];     // List of people to CC email to
		self.logs           = null;   // Logs to show in window
		self.uploader       = null;   // Used to upload files to add as attachment
		self.editorConfig   = null;   // TinyMCE options (text editor)
		self.emails         = [];
		const templateList    = [];     // Templates to use with email (from Resources/Email Templates)
		const listingList     = [];     // List of listings belonging to receiver (if seller)
		let isAdmin         = false;  // Whether this is an admin user sending an email
		let receiver        = {};
		let innerUploader = false;

		params = params || {};

		self.isBulk = params.isBulk;

		// SETUP VARIABLES ======================================================================================================================

		self.uploader = UploadService.initAnotherUserUploader(false);

		isAdmin = AccountService.getRole() === 'adminProfile';

		$uibModalInstance.scope = $scope;
		$uibModalInstance.check = {
			original: angular.copy(self.email),
			clone: self.email
		}

		if (!isAdmin) {
			$q.all([ getAgency(), getMine() ]).then((res) => {
				self.loading = false;
				setupEmail();
			})
		}
		else {
			getAdmin();
		}

		$scope.$watch('sendEmailController.email.receiver', (neu, old) => {
			if (!neu || typeof neu === 'string' || (!!(neu || {}).email && !!(neu || {}).emailEnabled)) {
				$scope.validEmail = true;
			}
			else {
				$scope.validEmail = false;
			}
		});

		// FUNCTIONS ============================================================================================================================

		function setupEmail() {
			let receiverId  = params.receiverId || null;
			const email       = params.email || null;
			const logs        = params.logs || null;
			const previousEmail = params.previousEmail || null;

			self.email = {
				receiver: null,
				receivers: [],
				subject: '',
				text: '',
				files: params.files || []
			}

			if(self.isBulk) {
				self.email.receivers = params.emails;
				$scope.lockReceiver = true;
			}

			if (logs) {
				self.logs = logs;
				$scope.totalLogs = logs.length;
				self.email.subject = 'Smartbrokr Action Logs from ' + ((AccountService.getAccount() || {}).user || {}).fullName || 'N/A';
			}

			if (email) {
				self.email.subject = email.subject;
				self.email.text = email.text;
			}

			if (previousEmail) {
				if (previousEmail.toUser) {
					receiverId = previousEmail.toUserId;
				}
				else {
					self.email.receiver = previousEmail.recipient;
				}
				self.email.subject = previousEmail.subject;
				self.email.text = previousEmail.html;
				self.email.files = previousEmail.attachments;
				self.email.cc = (previousEmail.cc || '').toString();
			}

			self.uploader.onSuccessItem = function (fileItem, response, status, headers) {
				if (!self.email.files) {
					self.email.files = [];
				}

				self.email.files.push(response);
			}

			if (receiverId) {
				receiver = self.users.find(x => x.id == receiverId ) || params.receiver || {};
				self.email.receiver = receiver;

				listingList.push({ text: '-------', value: {} });

				if (((receiver.sellerProfile || {}).listings || []).length > 0) {
					receiver.sellerProfile.listings.forEach((listing) => {
						listingList.push({ text: ((listing.property || {})._address || {}).addressString || listing.id, value: listing });
					})
				}
			}

			setupEditor();
		}

		function getAgency() {
			return UserService.getAll().then((res) => {
				self.users = self.users.concat(res.people);
				self.ccUsers = angular.copy(self.users);
				return true;
			});
		}

		function getMine() {
			return UserService.getAllMine(false,true).then((res) => {

				res = res.reduce((arr, curr) => {
					if (!!curr.email && !!curr.emailEnabled) {
						arr.push(curr);
					}
					return arr;
				}, []);

				self.users = self.users.concat(res);
				self.ccUsers = angular.copy(self.users);
				return true;
			});
		}

		function getAdmin() {
			AdminService.getEmails().then((res) => {
				self.users = res;
				self.ccUsers = angular.copy(self.users);
			})
			.catch((err) => {
				$log.error(err);
			})
			.finally(() => {
				self.loading = false;
				setupEmail();
			})
		}

		self.searchUsers = function (value) {
			let ret = [];

			if (!value || value === '') return ret;

			ret = $filter('searchSingleFilter')(self.ccUsers,value,{ email:'',fullName:'' });
			return ret.sort((a, b) => { return a.fullName.localeCompare(b.fullName) - b.fullName.localeCompare(a.fullName); });
		}

		self.selectOrNot = function(viewValue, item, model, label, e) {
			if ((!item.emailEnabled || !item.email) && !!e) {
				self.email.receiver = null;
			}
		}

		self.checkLog = function(log) {
			log.isChecked = !log.isChecked;

			if (log.isChecked) {
				$scope.totalLogs += 1;
			}
			else {
				$scope.totalLogs -= 1;
			}
		}

		self.hideLogs = function() {
			$scope.hideLogs = !$scope.hideLogs;
		}

		self.close = function () {
			$uibModalInstance.dismiss('cancel');
		};

		self.send = function () {
			const email = self.email;

			const attachments = (email.files || []).reduce((arr, file) => {
				arr.push({
					name: file.originalFilename || file.name,
					id: file.id,
					path: file.url
				})

				return arr;
			}, []);

			const options = {
				subject: self.email.subject,
				html: self.email.text,
				cc: [],
				attachments: attachments,
				isAdmin: isAdmin
			}

			if (email.cc) {
				options.cc = email.cc.id ? [email.cc.email] : email.cc.split(',') || [];
			}

			if (typeof email.receiver == 'string') {
				options.to = email.receiver;
			}
			else if(self.isBulk) {
				options.to = email.receivers;
				options.userId = params.receiverIds;
			}
			else {
				options.userId = email.receiver.id;
				options.to = email.receiver.email;
			}

			let apiCall;
			if (params.logs) {
				const logs = params.logs.reduce((arr,log) => {
					if (log.isChecked) {
						arr.push(log);
					}

					return arr;
				}, []);

				apiCall = UserService.sendBulkLogs(options, logs);
			}
			else if(self.isBulk) {
				apiCall = UserService.sendBulkEmails(options);
			}
			else {
				apiCall = UserService.sendEmail(options);
			}

			AlertService.loading();

			apiCall.then((res) => {
				$rootScope.$broadcast('reloadLogs');
				AlertService.success('EMAIL.EMAIL', 'send');
			})
			.catch((err) => {})
			.finally(() => {
				AlertService.doneLoading();
				$uibModalInstance.close({ result: true });
			})
		};

		/** Deletes a file from the task (before sending it)
			* @param i: int (index of the file to be deleted)
			*/
		self.deleteFile = function (i) {
			self.email.files.splice(i, 1);
		}

		function setupEditor() {
			const plugins   = 'advlist anchor autolink colorpicker charmap directionality help hr image imagetools link lists print preview searchreplace table textcolor template textpattern toc visualblocks visualchars wordcount';
			const toolbar   = 'formatselect | bold italic strikethrough forecolor backcolor | link image | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | template';
			const menubar   = 'file edit view insert format table tools help';
			innerUploader = UploadService.initEmailTemplateUploader(true, 'TEMP');

			const supplier  = params.supplier || {};
			let listing   = params.listing || {};
			const innerImages = [];

			const config    = GlobalVars.createEditorConfig(false, menubar, plugins, toolbar);

			config.templates = function(success) {
				if (isAdmin) return success([]);
				ResourceService.getAllEmailTemplates().then((res) => {
					res.forEach((template) => {
						template.text = FileService.replaceUrlsInText(template.text);
						templateList.push({
							title: template.name,
							description: template.purpose,
							content: template.text,
							files: template.files,
							id: template.id
						})
					});
					success(templateList);
				})
			}

			config.template_replace_values = {
				'Full Name': (receiver.fullName || ''),
				'First Name': (receiver.firstName || ''),
				'Last Name': (receiver.lastName || ''),
				Company: (supplier.company || ''),
				'Time Module': $filter('dateFormatComplete')(new Date().toString()),
				Salutation: (receiver.salutation || ''),
				'Property Address': (((listing.property || {})._address || {}).addressString || ''),
				'Listing URL': (listing.id || ''),
				'Expiry Date': $filter('dateFormat3')(listing.expiry || '')
			}

			config.setup = _setup;
			config.min_height = 375;
			config.image_list = function(success) {
				ResourceService.getMarketingImages().then((res) => {
					const ret = res.reduce((arr, folder) => {
						folder.files.reduce((imgArr, img) => {
							arr.push({ title: img.originalFilename, value: img.url });
						}, []);
						return arr;
					}, []);
					success(ret);
				});
			}

			config.images_upload_handler = function (blobInfo, success, failure) {
				innerUploader.onSuccessItem = function (fileItem, response, status, headers) {
					if(response.url) {
						innerImages.push({ id: response.id, url: FileService.getImageUrl(response.url) });
						success(FileService.getImageUrl(response.url));
					} else {
						failure('FAILED');
					}
				}

				innerUploader.onErrorItem = function (fileItem, response, status, headers) {
					failure(status);
				}

				innerUploader.addToQueue(blobInfo.blob());
				innerUploader.uploadAll();
			};

			if ($rootScope.screenSize === 'xs') {
				config.image_dimensions = false;
				config.image_description = false;
				config.target_list = false;
				config.template_popup_width = 320;
				config.toolbar = toolbar.replace(' link', '');
				config.plugins = plugins.replace(' link', '');
			}
			else {
				config.link_context_toolbar = true;
			}

			self.editorConfig = config;

			function _setup(editor) {

				editor.on('init', (e) => {

					// Setup insert template function to add the files we want
					editor.windowManager.oldOpen = editor.windowManager.open;  // save for later
					editor.windowManager.open = function (t, r) {    // replace with our own function

						// Insert template modal
						if (t.title == 'Insert template') {
							const oldSubmit = t.onsubmit;
							t.onsubmit = function (e) {
								oldSubmit.apply(t, [e]);
								// Submit normally + add files
								for (let i = 0; i < templateList.length; i++) {
									if (e.data.template.content == templateList[i].content) {
										self.email.files = templateList[i].files;
										self.email.templateId = templateList[i].id;
										break;
									}
								}
							}

							t.items[0].items.push({
								type: 'container',
								label: 'Listing',
								items: {
									type: 'listbox',
									label: $filter('translate')('LISTING.LISTING'),
									name: 'listing',
									values: listingList,
									onselect: function(e) {
										listing = e.control.value();
										self.editorConfig.template_replace_values['Property Address'] = ((((listing || {}).property || {})._address || {}).addressString || '');
										self.editorConfig.template_replace_values['Listing URL'] = ((listing || {}).id || '');
										self.editorConfig.template_replace_values['Expiry Date'] = $filter('dateFormat3')((listing || {}).expiry || '');
									}
								}
							})
						}
						return this.oldOpen.apply(this, [t, r]);
					}
				})
			}
		}

	}) // End of controller
})(); // End of function()
