(function () {

    "use strict";

    angular.module("smartbrokr")

      .controller("LinkListingController", function ($uibModalInstance, $scope, ListingService) {
        var self = this;

        // VARIABLES ============================================================================================================================

        $scope.loadingData  = true;   // False when loading is done
        self.listings       = [];     // All possible listings
        self.listing        = null;   // Selected listing

        // SETUP VARIABLES ======================================================================================================================

        ListingService.getAllListings().then(function(res){
          self.listings = res;
        })
        .catch(function (err) {})
        .finally(function() { 
          $scope.loadingData = false; 
        })

        // FUNCTIONS ============================================================================================================================

        self.close = function () {
          $uibModalInstance.dismiss('cancel');
        };

        self.select = function() {
          $uibModalInstance.close({listingId: self.listing.id});
        }

        self.searchListing = function(value) {

          value = value.toLowerCase();

          var ret = [];
          var i, listingLength = self.listings.length;

          for (i=0; i<listingLength; i++) {
            if ((self.listings[i].id || "").toLowerCase().includes(value) ||
                (((self.listings[i].property || {})._address || {}).addressString || "").toLowerCase().includes(value)) {
                  ret.push({id:self.listings[i].id, _address: (((self.listings[i].property || {})._address || {}).addressString || "") + " ("+self.listings[i].id+")"});
            }
          }
          return ret;
        }

      }) // End of controller
})(); // End of function()
