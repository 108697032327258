(function () {

    "use strict";
    angular.module("smartbrokr.owner", [])
    .service("OwnerService", function (ListingSeller, AccountService, $log, $rootScope, $state, $q) {
        var self = this;
        
        /**
         *  Gets listingId for current session.
         *  @return     {string}        ID of listing related to currently logged in owner
         */
        self.getListingId = function() {
            return AccountService._get('listingId');
        }

        /**
         *  Gets suppliers related to the currently logged in Seller.
         *  @param      {string=}   id      If provided, will return only the one supplier.
         *  @return     {object[]}          Array of suppliers created by the currently logged in seller
         */
        self.getSuppliers = function(id) {
            return ListingSeller.getSuppliers({ supplierId: id || null }).$promise;
        }

        /**
         *  Login function for owner page.
         *  Creates an access token in the backend and returns the  
         *  id of the listing related to this token.
         *  @param      {string}    token   Token assigned to the seller to get access to seller section
         *  @return     {string}            ID of listing related to this access token
         */
        self.login = function(token) {

            var def = $q.defer();

            ListingSeller.loginSeller({ token: token }).$promise
            .then(function(res) {

                var newToken    = res.token || {};
                var seller      = res.seller || {};

                AccountService.clear();
                AccountService.setAccessToken(newToken.id, newToken);
                AccountService._set('sellerId', newToken.sellerId);
                AccountService._set('role', newToken.role);
                AccountService._set('userId', newToken.sbUserId);
                AccountService._set('language', seller.languageIds[0] || "EN");
                AccountService._set('listingId', newToken.listingId);
                AccountService._set('currentUser', {
                    fullName: res.seller.fullName,
                    photoUrl: res.seller.photoUrl
                })

                $rootScope.currentUser = AccountService._get('currentUser');

                def.resolve(newToken.listingId);
            })
            .catch(def.reject)

            return def.promise;
        }

        /**
         *  Clears user session and goes back to login page. 
         */
        self.logout = function() {
            ListingSeller.logoutSeller().$promise
            .then(function(res) {
                //console.log("res: ", res);
            })
            .catch($log.error)
            .finally(function() {
                AccountService.clearUser();
                AccountService.clear();
                $state.go('main.owner.login');
            })
        }

        self.saveSupplier = function(supplier) {
            supplier = supplier || {};
            return ListingSeller.saveSupplier({ supplier: supplier }).$promise;
        }

    });
  })(); // End of function()
  