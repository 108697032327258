/* eslint-disable no-undef */

(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('DashboardController', function ($filter, $scope, AccountService, AlertService, DashboardService, ListingService, ModalService, NavService, SupplierService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		let loadBuyers 		= false;	// False while reading from DB, true when done
		let loadSellers 	= false;
		let loadListings 	= false;
		let loadSuppliers 	= false;

		self.buyers 		= [];		// Buyers shown in Buyers tab
		self.sellers 		= [];		// Sellers shown in Sellers tab
		self.listings 		= [];		// Listings shown in Listings tab
		self.suppliers 		= [];		// Suppliers shown in Suppliers tab

		self.filters		= null;		// Filters for Buyer/Seller/Listings/Supplier tabs
		self.selectedFilter	= null;		// Currently selected filter in each tab

		self.activeTab		= 0;		// Index of active tab

		// SETUP VARIABLES ======================================================================================================================
		$scope.broker 			= isBroker();

		self.filters = {
			recentlyUpdated: {
				name: $filter('translate')('PAGES.DASHBOARD.FILTERS.RECENTLY_UPDATED'),
				type: 'recentlyUpdated'
			},
			newest: {
				name: $filter('translate')('PAGES.DASHBOARD.FILTERS.NEWEST'),
				type: 'newest'
			},
			oldest: {
				name: $filter('translate')('PAGES.DASHBOARD.FILTERS.OLDEST'),
				type: 'oldest'
			}
		};

		self.selectedFilter = {
			buyers: self.filters.recentlyUpdated,
			sellers: self.filters.recentlyUpdated,
			listings: self.filters.recentlyUpdated,
			suppliers: self.filters.recentlyUpdated
		}

		AlertService.loading();

		if (AccountService.getRole() === 'agencyOwnerProfile' || AccountService.getRole() === 'agencyMemberProfile') {
			loadBuyers 	= true;
			loadSellers = true;
			getAgencySuppliers();
			getAgencyListings();
			self.activeTab = 2;
		}
		else {
			getBuyers();
			getSellers();
			getListings();
			getSuppliers();
		}

		// FUNCTIONS ============================================================================================================================

		/** Creates a new Listing then navigates to Listing Details state */
		self.newListing = function () {
			AlertService.loading();
			const listing = {
				property: {},
				status: 'Smart',
				isDraft: true
			};

			ListingService.createListing(listing)
			.then((res) => {
				return ListingService.addProperty(res.id, {});
			})
			.then((property) => {
				AlertService.doneLoading();
				NavService.navigate('main.listings.detail.submenu', { id: property.listingId });
			})
			.catch((err) => {
				console.error(err);
				AlertService.doneLoading();
			})
		}

		/**
		 * Changes the selected filter for a page.
		 * @param filter: Object (filter that was selected)
		 * @param page: String (page that filter will be applied to)
		 */
		self.selectFilter = function (filter, page) {
			const filters = self.filters;
			self.selectedFilter[page] = filter;
			if ($scope.broker) {
				switch (page) {
				case ('buyers'): 	getBuyers(); break;
				case ('sellers'): 	getSellers(); break;
				case ('suppliers'): getSuppliers(); break;
				case ('listings'): 	getListings(); break;
				}
			}
			else {
				switch (page) {
				case ('suppliers'): getAgencySuppliers(); break;
				case ('listings'): 	getAgencyListings(); break;
				}
			}
		};

		self.switchTab = function (x) {
			self.activeTab = x;
			if (x === 4) {
				$scope.$broadcast('rzSliderForceRender');
			}
		}

		function isBroker() {
			const role = AccountService.getRole();
			return role === 'brokerProfile' || role === 'managerProfile';
		}

		function checkLoading() {
			// REMOVE
			if (loadBuyers && loadSellers && loadListings && loadSuppliers) {

				if (DashboardService.getStoredSearch() != null) {
					self.activeTab = 4;
				}
				AlertService.doneLoading();
			}
		}

		function getBuyers() {
			DashboardService.getBuyers(self.selectedFilter.buyers.type)
			.then((res) => {
				self.buyers = res;
			})
			.catch((err) => {})
			.finally(() => { loadBuyers = true; checkLoading(); })
		}

		function getSellers() {
			DashboardService.getSellers(self.selectedFilter.sellers.type)
			.then((res) => {
				self.sellers = res;
			})
			.catch((err) => {})
			.finally(() => { loadSellers = true; checkLoading(); })
		}

		function getListings() {
			DashboardService.getListings(self.selectedFilter.listings.type)
			.then((res) => {
				self.listings = res;
			})
			.catch((err) => {
				console.error('ERR: ', err);
			})
			.finally(() => { loadListings = true; checkLoading(); })
		}
		function getAgencyListings() {
			const order = DashboardService.getFilterOrder(self.selectedFilter.listings.type);
			ListingService.getSortedAgencyListings(order)
			.then((res) => {
				self.listings = res;
			})
			.catch((err) => {
				console.error('ERR: ', err);
			})
			.finally(() => { loadListings = true; checkLoading(); })
		}

		function getAgencySuppliers() {
			const order = DashboardService.getFilterOrder(self.selectedFilter.suppliers.type);
			SupplierService.getSuppliers(null, null, order)
			.then((res) => {
				self.suppliers = res;
			})
			.catch((err) => {})
			.finally(() => { loadSuppliers = true; checkLoading(); })
		}
		function getSuppliers() {
			DashboardService.getSuppliers(self.selectedFilter.suppliers.type)
			.then((res) => {
				self.suppliers = res;
			})
			.catch((err) => {})
			.finally(() => { loadSuppliers = true; checkLoading(); })
		}

	}) // End controller
})();
