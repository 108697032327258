(function () {

    "use strict";

    angular.module("smartbrokr.storage", [])

        .service("StorageService", function ($localStorage, $rootScope) {
            var self = this;
            self.get = function (key) {
                try {
                    var result = JSON.parse($localStorage[key]);
                    return result;
                }
                catch (error) {
                    return null;
                }
            }

            self.destroy = function (key) {
                delete $localStorage[key];
            }

            self.getBuyer = function () {
                return self.get('buyer');
            }

            self.getSeller = function () {
                return self.get('seller');
            }

            self.setBuyer = function (buyer) {
                $localStorage.buyer = JSON.stringify(buyer);
            }
            self.setSeller = function (seller) {
                $localStorage.seller = JSON.stringify(seller);
            }

            self.destroyBuyer = function () {
                self.destroy('buyer');
            }

            self.destroySeller = function () {
                self.destroy('seller');
            }

            self.setLanguage = function(lang) {
              $localStorage.language = (lang || 'EN').toUpperCase();
            }

            self.getLanguage = function() {
              var lang = $localStorage['language'];

              if (!lang) {
                var user = self.get('account');
                self.setLanguage((user || {}).mainLanguageId || 'EN');
                return self.getLanguage();
              }
              return lang;
            }

            self.setView = function(page, type) {
              var views = $localStorage['views'];

              if (!views) {
                views = {};
              }

              views[page] = type;
              $localStorage['views'] = views;
            }

            self.getView = function(page) {
              var views = $localStorage['views'];
              if (!views) {
                return null;
              }
              return views[page];
            }

        });
})();
