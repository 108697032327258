/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.seller', [])

	.service('SellerService', function (Seller, Broker, AccountService, SellerBroker, SbUser, ModalService) {
		const self = this;

		const supplierRel = {
			include: [
				{
					relation: 'owner',
					scope: {
						fields: [ 'id', 'firstName', 'lastName', 'email', 'emailEnabled', 'fullName', 'phones', 'languageIds', 'photoUrl', '_address' ],
						include: [
							'languages',
							{
								relation: 'buyerProfile',
								scope: {
									fields: [ 'id', 'sbUserId', 'preApproved', 'minPrice', 'maxPrice', 'cityIds', 'propertyTypeIds', '_coBuyer' ],
									include: [
										'propertyTypes',
										{
											relation: 'cities',
											scope: {
												fields: [ 'name' ]
											}
										}
									]
								}
							}
						]
					}
				},
				{
					relation: 'industry',
					scope: {
						fields: [
							'supplierId',
							'id',
							'type',
							'labels',
							'centris',
							'realtor'
						]
					}
				},
			]
		}

		self.getSellersNames = function () {
			const filter = {
				include: {
					relation: 'user',
					scope: {
						fields: {
							fullName: true
						}
					}
				}
			}
			return Broker.sellers({ id: AccountService.getBrokerId(), filter: filter }).$promise;
		}

		self.getSellerPerson = function (id) {
			return Seller.user({ id: id }).$promise;
		}
		self.getUserAndSeller = function(id){
			return Seller.findById({ id: id ,filter:{ include:['listings','user'] } }).$promise;
		}
		self.getPerson = function (id) {
			const filter = {
				where: {
					brokerId: AccountService.getBrokerId()
				},
				include: {
					relation: 'seller',
					scope: {
						fields: [ 'id', 'sbUserId', 'modified' ],
						include: [
							{
								relation: 'user',
								scope: {
									fields: [ 'id', 'firstName', 'lastName', 'email', 'emailEnabled', 'fullName', 'phones', 'languageIds', 'photoUrl', '_address', 'sbUserId' ],
									include: [
										'languages',
										{
											relation: 'buyerProfile',
											scope: {
												fields: [ 'id', 'sbUserId', 'preApproved', 'minPrice', 'maxPrice', 'cityIds', 'propertyTypeIds', '_coBuyer' ],
												include: [
													'propertyTypes',
													{
														relation: 'cities',
														scope: {
															fields: [ 'name' ]
														}
													}
												]
											}
										}
									]
								}
							},
							{
								relation: 'listings',
								scope: {
									fields: [ 'id', 'status', 'askingPrice', 'rentalPrice', 'mainPhotoId' ],
									include: [
										{
											relation: 'property',
											scope: {
												fields: [ '_address', 'listingId', 'id' ]
											}
										},
										{
											relation: 'files',
											scope: {
												fields: ['listingId'],
												where: {
													bucket: 'listing'
												},
												order: 'seq ASC',
												limit: 1
											}
										},
										{
											relation: 'mainPhoto',
											scope: {}
										}
									]
								}
							},
							{
								relation: 'suppliers',
								scope: supplierRel
							}
						]
					}
				}
			}

			return Seller.sellerBrokers({ id:id, filter: filter }).$promise;
		}
		self.getThrough = function(id){
			return Seller.sellerBrokers({ id:id, filter: { where: { brokerId: AccountService.getBrokerId() }, fields: { id: true } } }).$promise;
		}
		self.createFolder = function (id, folder) {
			return SellerBroker.folders.create({ id: id }, { name: folder }).$promise;
		}
		self.getFolders = function(id){
			return Seller.sellerBrokers({ id:id, filter: { where: { brokerId: AccountService.getBrokerId() }, include:{ folders:'files' } } }).$promise;
		}
		self.deleteFolder = function(id, fk){
			const del = function() {
				return SellerBroker.folders.destroyById({ id: id, fk:fk }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FOLDER');
		}
		self.getSellers = function () {
			return Broker.getRelatedList({ id: AccountService.getBrokerId(), rel: 'sellers' }).$promise;
		}

		self.getSeller = function (fk) {
			//May need to switch to Agency.sellers or Broker.sellers
			return Broker.sellers.findById({ id: AccountService.getBrokerId(), fk: fk, filter: filter }).$promise;
		}
		self.getSellerId = function(id){
			return SbUser.findById({ id:id,filter: { include:'sellerProfile' } }).$promise;
		}

		self.saveSeller = function (seller) {
			if (!seller.id) {
				return Broker.sellers.create({ id: AccountService.getBrokerId() }, seller).$promise;
			}
			else {
				return Broker.sellers.updateById({ id: AccountService.getBrokerId(), fk: seller.id }, seller).$promise;
			}

		}
		self.addExistingSeller = function (fk) {
			return Broker.sellers.link({ id: AccountService.getBrokerId(), fk: fk }).$promise
		}
		self.createSellerListing = function (id, listing) {
			return Seller.listings.create({ id: id }, listing).$promise;
		}

		self.linkListing = function (id, fk) {
			return Seller.listings.link({ id: id, fk: fk }, null).$promise;
		}

		self.deleteSeller = function(fk) {
			return Broker.sellers.destroyById({ id: AccountService.getBrokerId(), fk: fk }).$promise;
		}

		self.getSentEmails = function(id) {
			return Seller.sentEmails({ id: id }).$promise;
		}

		self.getVisits = function(lId) {
			return Seller.getVisits({ id: lId }).$promise;
		}

		self.getAppointments = function (lId) {
			return Seller.getAppointments({ id: lId }).$promise;
		}

		self.getSuppliers = function(id) {
			return Seller.suppliers({ id: id, filter: supplierRel }).$promise;
		}

		self.linkSupplier = function(id, fk) {
			return Seller.suppliers.link({ id: id, fk: fk }, null).$promise;
		}

		self.unlinkSupplier = function(id, fk) {
			return Seller.suppliers.unlink({ id: id, fk: fk }).$promise;
		}
	});

})(); // End of function()
