(function () {
    
    "use strict";
    
    angular.module("smartbrokr.role", [])
    .service("RoleService", function (Agency, Auditor, Notary, AccountService, $q) {
        var self = this;

        var isNotary = function() {
            return AccountService.getRole() == 'notaryProfile';
        }

        var isAuditor = function() {
            return AccountService.getRole() == 'auditorProfile';
        }

        self.getListings = function(){
            if (isNotary()) {
                var filter = {
                    include: [
                        {
                            relation: 'property',
                            scope: {
                                fields: [ 'listingId', '_address' ]
                            }
                        },
                        {
                            relation: 'brokers',
                            scope: {
                                fields: [ 'id', 'agencyId', 'sbUserId' ],
                                include: {
                                    relation: 'user',
                                    scope: {
                                        fields: [ 'fullName' ]
                                    }
                                }
                            }
                        }
                    ],
                    fields: [ 'id', 'status' ],
                    order: 'id ASC'
                }
                return Notary.listings({ id: AccountService.getNotaryId(), filter:filter }).$promise;
            }
            else if (isAuditor()) {
                return Auditor.getListings({ id: AccountService.getAuditorId(), agencyId: AccountService.getAgencyId() }).$promise;
            }
            else {
                return $q.when(true);
            }
        };

        self.getAgencies = function(ids) {
            return Agency.getListOfNames({ ids: JSON.stringify(ids) }).$promise;
        }

        self.getDocuments = function(listingId) {
            if (isAuditor()) {
                return Auditor.getListingDocuments({ id: AccountService.getAuditorId(), listingId: listingId, agencyId: AccountService.getAgencyId() }).$promise;
            }
            else {
                return Notary.getListingDocuments({ id: AccountService.getNotaryId(), listingId: listingId }).$promise;
            }
        }
    });
})();