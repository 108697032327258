/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.wordpress', [])
	.service('WordpressService', function (AccountService, Account, AccountWordpress, AlertService, ModalService, UserService, $log) {
		const self = this;

		self.createWordpress = function(wp) {
			wp.profileId = AccountService.getProfileId();
			return Account.wordpress.create({ id: AccountService.getAccountId(true) }, wp).$promise;
		}

		self.getOptions = function(wId) {
			return AccountWordpress.getRemoteOptions({ id: wId }).$promise;
		}

		self.getWordpress = function() {
			return Account.getWordpress({ id: AccountService.getAccountId(true) }).$promise;
		}

		self.deleteWordpress = function(id) {
			return AccountWordpress.deleteById({ id: id }).$promise;
		}

		self.sendUpdate = function(wId, type, data) {
			return AccountWordpress.remoteUpdate({ id: wId, type: type, data: data }).$promise;
		}

		self.restoreDefault = function(wId, page, lang) {
			return AccountWordpress.RestoreDefault({ id: wId, page: page, lang: lang }).$promise;
		}

		self.rollbackLatestChange = function(wId, page, lang) {
			return AccountWordpress.RollbackLatestChange({ id: wId, page: page, lang: lang }).$promise;
		}

		self.getContentBlocks = function(content, pageType) {
			content = content || '';

			switch(pageType) {
			case 'home':
				return getHomeContents(content);
			case 'my_colleagues':
			case 'marketing':
			case 'agency':
				return content;
			}
		}

		self.deleteWordpress = function(wId) {
			return AccountWordpress.destroyById({ id: wId }).$promise;
		}

		self.uploadPhoto = function(dimensions, type, wId, cb, subtype = null) {
			const options = {
				subtype: subtype
			};
			const m = ModalService.openModal('wpImageUpload', {
				dimensions: () => dimensions,
				type: () => type,
				options: () => options
			}, 'WPImageUploadController', 'uploadController', null, 'wp-upload-modal wp-' + type);

			m.result.then((data) => {
				data = data || {};
				AlertService.loading();

				self.sendUpdate(wId, 'upload', data).then((wpImage) => {
					return UserService.deleteFile(data).then((res) => {})
					.catch($log.error)
					.finally(() => {
						cb(wpImage.result || {});
					})
				})
				.catch($log.error)
				.finally(AlertService.doneLoading)
			})
			.catch(angular.noop);
		}

		function getHomeContents(original) {
			const ret = [];

			const tests = {
				featuredListing: [
					new RegExp(/<!-- wp:sb-gutenberg\/featured-listing ({"listing_id":"[A-z0-9]*"} ){0,1}\/-->/, 'gi'),
					new RegExp(/\[sb_featured_listing( listing_id=[A-z0-9]*){0,1}\]/, 'gi'),
				],
				aboutUs: [
					new RegExp(/<!-- wp:media-text {.+} -->((?!<!-- \/wp:media-text -->)[\s\S])*<!-- \/wp:media-text -->/, 'gi'),
				],
				testimonial: [
					new RegExp(/<!-- wp:sb-gutenberg\/testimonials( {"testimonials":".*"}){0,1} \/-->/, 'gi'),
					new RegExp(/\[sb_testimonials( testimonials\=[^\]]*){0,1}\]/, 'gi'),
				]
			};

			for (const i in tests) {
				const tested = _doTests(tests[i]);
				_pushBlocks(tested, i);
			}

			return ret;

			function _doTests(regs) {
				return regs.reduce((arr, test) => {
					const matches = original.match(test) || [];
					return arr.concat(matches);
				}, []);
			}

			function _pushBlocks(tested, type) {
				tested.forEach((instance) => {

					const toAdd = {
						type: type,
						content: ''
					}

					switch(type) {
					case 'testimonial':
						toAdd.content = _doTestimonials(instance); break;
					case 'featuredListing':
						toAdd.content = _doFeaturedListing(instance); break;
					case 'aboutUs':
						toAdd.content = _doAboutUs(instance); break;

					}

					ret.push(toAdd);
				});
			}

			function _doTestimonials(text) {
				text = text || '';
				let testimonials = text.match(/(?:\[sb_testimonials testimonials=|<!-- wp:sb-gutenberg\/testimonials {"testimonials":")([^\]}]+)(?:\]|"} \/-->)/, 'i') || [];

				if (testimonials.length > 1) {
					testimonials = testimonials[1].split(';').reduce((arr, str) => {
						if ((str || '').length > 0) {
							const fields = str.split(',');
							arr.push({
								author: fields[0],
								position: fields[1],
								text: fields[2]
							});
						}
						return arr;
					}, []);
				}
				else {
					testimonials = [];
				}

				return { testimonials: testimonials || [] };
			}

			function _doFeaturedListing(text) {
				text = text || '';
				const listingId = text.match(/(?:\[sb_featured_listing listing_id=|<!-- wp:sb-gutenberg\/featured-listing {"listing_id":")([A-z0-9]+){1}(?:\]|"} \/-->)/) || [];
				return {
					listingId: listingId[1] || ''
				}
			}

			function _doAboutUs(text) {
				text = text || '';

				const block = text.match(/(?:<!-- wp:media-text ){1}({.+}){1}(?: -->){1}([\s\S]+)(?:<!-- \/wp:media-text -->){1}/, 'im') || [];
				let blockInfo = {};

				try {
					blockInfo = JSON.parse(block[1]);
				}
				catch(e) {
					blockInfo = {};
				}

				const html = block[2] || '';

				let imgSrc = html.match(/(?:<img.* src="){1}(.*(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+){1}(?:".*\/>){1}/, 'i') || [];
				imgSrc = imgSrc[1] || '';

				let title = html.match(/(?:<!-- wp:heading -->)([\s\S]+)(?:<!-- \/wp:heading -->)/, 'i') || [];
				title = title[1] || '';
				title = title.replace('<h2>', '').replace('</h2>', '');

				let content = html.match(/(?:<!-- \/wp:heading -->)([\s\S]+)(?:<\/div><\/div>)/, 'i') || [];
				content = content[1] || '';

				return {
					blockInfo: blockInfo,
					html: html,
					imgSrc: imgSrc,
					title: title,
					content: content
				};
			}
		}
	});

})(); // End of function()
