(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('EditPropertyController', function (check, $rootScope, $stateParams, AccountService, AlertService, GlobalVars, ListingService, ModalService, NavService, SellerService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.person;      // Property owner
		self.property;    // Property that will be added

		self.propertyTypes; // Options for property type
		self.singleConfig;  // Dropdown config

		// SETUP VARIABLES ======================================================================================================================

		self.property = {};

		AlertService.loading();

		self.propertyTypes = GlobalVars.getCodes('propertyTypes');
		self.singleConfig = GlobalVars.singleConfig;

		SellerService.getUserAndSeller($stateParams.id).then((res) => {
			self.seller = res;
			self.person = res.user;
		})
		.catch((err) => {
			console.log('ERROR: ', err);
		})
		.finally(() => { AlertService.doneLoading();  })

		AccountService.getAccountDetails().then((res) =>{
			self.property._address = {
				country: (((res.user || {})._address || {}).country || null),
				province: (((res.user || {})._address || {}).province || null)
			};

			check.original = angular.copy(self.property);
			check.clone = self.property;
		})

		// FUNCTIONS ============================================================================================================================

		self.existingListing = function() {
			const retFunction = function(result) {
				AlertService.loading();
				check.isSaved = true;

				const link = function(sellerId) {
					ListingService.linkSeller(result.listingId,sellerId).then((res) =>{
						AlertService.success('ROLES.SELLER','addListing');
					})
					.catch((err) => {})
					.finally(() => {
						AlertService.doneLoading();
						NavService.popStack();
						NavService.navigateStack(-1, null);
					})
				}

				if (!self.seller) {
					SellerService.saveSeller({ sbUserId: self.person.user.id }).then((res) =>{
						link(self.seller.id);
					})
					.catch((err) => {})
				}
				else {
					link(self.seller.id);
				}
			}
			ModalService.openModalWithFunction('linkListing', {}, 'LinkListingController', 'linkListingController', retFunction);
		}

		// Saves/creates property and goes back to profile
		self.save = function () {

			const property = self.property;

			if (!(property._address || {}).addressString) {
				$rootScope.$broadcast('showWarnings', true);
			}
			else {
				check.isSaved = true;
				AlertService.loading();

				const goBack = function () {
					AlertService.doneLoading();
					AlertService.success('LISTING.LISTING','create');
					NavService.popStack();
					NavService.navigateStack(-1, null);
				}

				// New Listing
				const listing = {};
				listing.property = {};
				listing.status = 'Smart';
				listing.askingPrice = property.evalBuilding;
				listing.exclusive = true;

				ListingService.createListing(listing).then((list) => {
					ListingService.addProperty(list.id, property).then((res) => {

						const next = function () {
							ListingService.linkSeller(list.id, self.seller.id).then((linkSeller) => {
								goBack();
							})
							.catch((err) => {})
						}

						if (self.seller) {
							next();
						}
						else {

							const seller = {
								person: angular.copy(self.person)
							}
							SellerService.saveSeller(seller).then((newSeller) => {
								self.seller = newSeller;
								next();
							})
							.catch((err) => {})
						}
					})
					.catch((err) => {})
				})
				.catch((err) => {})
			}

		};
	});

})(); // End of function()
