(function () {

    "use strict";

    angular.module("smartbrokr")

        .controller("WebsiteController", function ($state, AccountService) {
            var self = this;
            self.option = $state.params.option || 1;
            self.visitedReview = !!AccountService.getVisitedReview();
            AccountService.saveWebsite(self.option);
            self.next = function () {
                $state.go('main.signup.permissions');
            }
            self.prev = function(){
                $state.go('main.signup.situation');
            }
            self.goToReview=function(){
                $state.go('main.signup.review');
            }
        });
})();
