/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.directives')
	.controller('DropdownController', ['$rootScope', '$sce', '$scope', '$translate', '$translatePartialLoader', 'GlobalVars', function ($rootScope, $sce, $scope, $translate, $translatePartialLoader, GlobalVars) {

		const self = this;
		let prefix      = '',           // Translation prefix (if provinces -> 'PROVINCES.')
			part        = '',           // Name of part to be added through $translatePartialLoader
			nameField   = 'name',       // Name of label field for selectize config
			inMemory    = {};           // Memory cache of items that have already been translated

		const typeWatcher     = $scope.$watch('type', setup);
		const optionsWatcher  = $scope.$watch('sbOptions', doTranslate);

		$scope.$on('$destroy', optionsWatcher);     // Remove watcher on scope destroy

		function setup() {
			switch($scope.type) {
			case 'province':
				prefix = 'PROVINCE.';
				part = 'provinces';
				break;
			}

			if ($rootScope.language === 'FR') {
				nameField = 'nameFr';
				addPart();
			}

			if ($scope.sbConfig) {
				$scope.config = $scope.sbConfig;
				$scope.config.labelField = nameField;
				$scope.config.sortField = nameField;
				$scope.config.searchField = nameField;
			}
			else {
				$scope.config = GlobalVars.createSingleConfig(false, 'id', nameField, nameField);
			}

			doTranslate();
			typeWatcher();
		}

		function addPart() {
			$translatePartialLoader.addPart(part);
			$translate.refresh();
		}

		function doTranslate() {
			const ids = $scope.sbOptions.reduce((arr,item) => {
				if ((item || {}).id && item.id !== '' && item.id !== 'xxx' && !inMemory[prefix + item.id]) {
					arr.push(prefix + item.id);
				}
				return arr;
			}, []);

			if (ids.length > 0) {
				$translate(ids).then((translations) => {
					for (const i in translations) {
						inMemory[i] = $sce.valueOf(translations[i]);
					}
					doAssign();
				}, (translationIds) => {
					doAssign();
				})
			}
			else {
				doAssign();
			}
		}

		function doAssign() {
			for (let i = 0; i < $scope.sbOptions.length; i++) {
				const item = angular.copy($scope.sbOptions[i]);         // Make change to copied object
				if (inMemory[prefix + item.id] && inMemory[prefix + item.id] !== (prefix + item.id)) {
					item[nameField] = inMemory[prefix + item.id];
				}
				else {
					item[nameField] = item.name;
				}
				$scope.sbOptions[i] = item;                      // Reassign value to update scope
			}
		}
	}])

	.directive('provinceDropdown', () => {
		return {
			restrict: 'E',
			controller: 'DropdownController',
			controllerAs: 'dropdownController',
			scope: {
				sbOptions: '=',
				sbFilter: '=',
				sbFilterField: '@',
				sbChange: '=?',
				sbConfig: '=?'
			},
			template: '<selectize ng-model="sbFilter[sbFilterField]" options="sbOptions" config="config" ng-if="config" ng-change="sbChange()"></selectize>',
			link: function (scope, element, attrs) {
				scope.type = 'province';

				if (!scope.sbFilterField) {
					scope.sbFilterField = 'value';
				}

				if (!scope.sbChange) {
					scope.sbChange = angular.noop;
				}
			}
		};
	})
})()
