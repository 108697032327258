(function () {

    "use strict";

    angular.module("smartbrokr")

        .controller("SendFilesController", function ($uibModalInstance, AccountService, AlertService, Agency, GlobalVars, UploadService, BrokerService, url) {
            var self = this;
            var url = url;
            BrokerService.getBrokers()
                .then(function (res) {
                    self.filteredBrokers = res;
                    for (var i = 0; i < self.filteredBrokers.length; i++) {
                        self.filteredBrokers[i].fullName = self.filteredBrokers[i].user ? self.filteredBrokers[i].user.fullName : "No Name";
                    }
                })
                .catch(function (err) { })

            self.allBrokers = false;
            self.brokerIds = [];
            self.brokerConfig = GlobalVars.createMultiConfig(false, "id", "fullName", "fullName");

            self.close = function () {
                $uibModalInstance.dismiss('cancel');
            }

            self.uploader = UploadService.initAgencyFolderUploader(AccountService.getAgencyId());
            self.uploader.url = url;
            self.uploader.onSuccessItem = function (fileItem, response, status, headers) { };
            self.uploader.onBeforeUploadItem = function (item) {
                if (!!self.allBrokers) {
                    item.formData.push({ allBrokers: true });
                }
                else {
                    item.formData.push({ brokerIds: self.brokerIds });
                }
            };
            self.uploader.onCompleteAll = function () {
                $uibModalInstance.close();
                AlertService.success('FILES.FILES','send',true);
            }

            self.check = function () {
                if (self.brokerIds.length == self.filteredBrokers.length && self.brokerIds.length > 0) {
                    self.allBrokers = true;
                    self.brokerIds = [];
                }
            }
            self.upload = function () {
                self.uploader.uploadAll();
            }



        }) // End of controller
})(); // End of function()
