/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('MainController', function ($location, $log, $rootScope, $scope, $state, $timeout, $window, AccountService, AlertService, DashboardService, GlobalVars, MenuService, ModalService, OwnerService) {

		// VARIABLES ========================================================================================================================

		const self    = this;
		const _       = $window._;  // Underscore

		self.modalOpen          = false;
		self.lockBookmarks      = true;
		self.searchValue        = null;		// Variable to keep what is being searched in the menu search
		self.managing           = null;     // If current user is a manager, this array holds managed users
		self.managingId         = null;     // If current user is a manager, this is the ID of the managed broker

		// SETUP VARIABLES =====================================================================================================================

		self.menu               = MenuService.menu = MenuService.initMenu();
		self.auditorDropdown    = null;

		let activeState         = null;
		let taskWatcher         = null;
		let dropdownOpen        = false;        // If true, disable logout function (in case user clicks by accident)
		let managingOptions     = [];

		self.dropdowns = {
			config: GlobalVars.createSingleConfig(false, 'id', 'label', 'label')
		}

		self.dropdowns.config.onInitialize = function(selectize) {
			const input = (selectize.$control_input || [])[0] || null;
			if (input) {
				input.readOnly = true;
			}
		};

		self.dropdowns.config.onDropdownOpen = function($dropdown) {
			dropdownOpen = true;
		};

		self.dropdowns.config.onDropdownClose = function($dropdown) {
			$timeout(() => {
				dropdownOpen = false;   // Wait before enabling logout again so user doesn't double click by accident
			}, 500);
		}

		getSubSub($state.params, $state.current.data);
		getActive($state.params, $state.current.data);
		getTaskWatcher($state.current.name, $state.params);

		_setRoleAndLandingPage();
		_setupRoles(true);

		// FUNCTIONS ===========================================================================================================================

		// https://underscorejs.org/#isEmpty
		$scope.isEmpty = function(item) {
			return _.isEmpty(item);
		}

		/**
         *  Determines which menu item is currently active in this state.
         *  An active menu item has the same styling as a hovered one
         *
         *  @param {object} params   Parameters passed on state change
         *  @param {object} data     Data field (defined with state)
         */
		function getActive(params, data) {
			let active  = (params || {}).menu || (params || {}).role || '';
			activeState = self.menu[active] || {};      // Keep this so if a submenu is added (profile,listings), it can be deleted on state change

			if ((self.menu[active] || {}).submenu) {
				self.activeSub = active;
				activeState = ((activeState.submenu || {}).content || {})[active];

				if (!activeState && params.subsubmenu) {
					activeState = (((self.menu[active] || {}).submenu || {}).content || {})[params.submenu];
				}
				active += '.' + (params || {}).submenu;
			}

			if (params.subsubmenu) {
				const subsub = params.subsubmenu;
				self.activeSubSub = active;

				if (data[subsub + 'Menu']) {
					active += '.' + data[subsub + 'Menu'];
				}
			}

			self.active = active;
		}

		/**
         *  Gets the 'subsubmenu' of a menu item
         *  Used with profile and listing states
         *
         *  @param {object} params  Parameters passed on state change
         */
		function getSubSub(params) {
			params = params || {};
			const subsub = params.subsubmenu;
			if (subsub) {
				let active = self.menu[params.menu]  || self.menu[params.role] || {};

				if (active === {}) {
					$log.warning('Bad logic: params: ', params);
				}

				if (params.submenu) {
					active = ((active.submenu || {}).content || {})[params.submenu];
				}

				if (active && params.submenu != 'shared') {
					active.subsub = MenuService.getSubSub(subsub, params.role);
				}
			}
		}

		/**
         *  Creates a hash watcher if user is currently in the Tasks page
         *  Disables it when the state is changed
         *  @param  {string}    stateName   Name of current state or state getting transitioned to
         *  @param  {object}    toParams    Object with state's params
         */
		function getTaskWatcher(stateName, toParams) {
			toParams = toParams || {};

			if (stateName === 'main.tasks' && !taskWatcher) {
				taskWatcher = $scope.$watch(() => { return $location.hash(); }, getTaskHash);
			}
			else if (stateName !== 'main.tasks' && !!taskWatcher) {
				taskWatcher();
				taskWatcher = null;
			}

			if (toParams.hash) {
				getTaskHash(toParams.hash);
			}

			function getTaskHash(hash) {
				let active = 'tasks';
				self.activeSub = active;
				if (hash === 'Personal-0') {
					active += '.personal';
				}
				else if (hash) {
					hash = hash.split('-');
					if (hash.length > 1) {
						active += '.' + hash[0];
						self.activeSubSub = active;
						active += '.' + hash[1]
					}
				}

				self.active = active;
			}
		}

		/**
         *  Opens or closes a menu item's submenu
         *
         *  @param {string} name    Name of the menu item
         */
		self.openSub = function(name) {

			if (name === 'search') return;

			const menu    = $state.params.menu;
			const submenu = $state.params.submenu;
			const subsub  = $state.params.subsubmenu;
			const current = $state.current.name;

			if (self.activeSub !== name) {      // Will open a different submenu
				self.activeSub = name;

				if (menu === name && !!submenu) {
					self.active = name + '.' + submenu;
				}
				else {
					self.active = name;
				}

				if (!(self.menu[name] || {}).submenu) {
					DashboardService.resetStoredSearch();   // Navigation was made using the main menu
				}
			}
			else if (name === menu) {           // Current state belongs to submenu that will be closed
				self.activeSub = null;
			}
			else {                              // Will close currently opened submenu and set 'active' to menu item that corresponds to current state
				self.active = menu;

				if (submenu) {
					self.active += '.' + submenu;
					self.activeSub = menu;
				}
				else if (current.includes('main.tasks')) {
					let active = 'tasks';
					let hash = $location.hash();
					self.activeSub = active;

					if (hash) {
						hash = hash.split('-');
						if (hash.length > 1) {
							active += '.' + hash[0];
							self.activeSubSub = active;
							active += '.' + hash[1]
						}
					}

					self.active = active;
				}
				else {
					self.activeSub = null;
				}

				if (subsub) {
					const subsubItem = ($state.current.data || {})[subsub + 'Menu'] || '';
					self.active += '.' + subsubItem;
				}
			}
		}

		/**
         *  Opens/closes a menu item's 'subsubmenu' (listings,profile)
         *
         *  @param {string} name    Name of the menu item
         */

		self.openSubSub = function(name) {

			if (self.activeSubSub === name) {
				if ($state.current.name.includes('main.tasks')) {
					let hash = $location.hash() || $state.params.hash;

					if (hash) {
						hash = hash.split('-');
						const subsub = 'tasks.' + hash[0];
						if (self.activeSubSub !== subsub) {
							self.activeSubSub = subsub;
						}
						else {
							self.activeSubSub = null;
						}
					}
					else {
						self.activeSubSub = null;
					}
				}
				else {
					self.activeSubSub = null;
				}
			}
			else {
				self.activeSubSub = name;
			}
		}

		/**
         *  Read an item from the menu and determines its proper link
         *  using $state.href(state,params)
         *
         *  @param {object} navItem  An item from the menu
         */
		self.getLink = function(navItem) {
			navItem = navItem || {};
			if (navItem.link) {
				let params = null;
				let state = navItem.link;

				if (state.includes('#')) {
					return state;
				}

				if (state.includes('\(')) {
					params = state.substring(state.indexOf('\(') + 1, state.indexOf('\)'));
					state = state.split('\(')[0];
					try {
						params = JSON.parse(params);
					}
					catch(e) {
						$log.debug('Had error. MainController.getLink. State: ', state);
						$log.error(e);
					}
				}

				return $state.href(state, params);
			}
			else if (navItem.url) {
				return navItem.url;
			}
			else {
				return null;
			}
		}

		$rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
			if ((activeState || {}).subsub) {
				activeState.subsub = null;
			}

			self.active = null;
			self.activeSub = null;
			self.activeSubSub = null;
			getSubSub(toParams);
			getActive(toParams, toState.data);
			getTaskWatcher(toState.name, toParams);
		})

		/**
         *  Opens modal to make a search
         */
		self.search = function (event) {
			const searchValue = self.searchValue;

			if (!self.modalOpen) {
				event.target.blur();
				self.modal = ModalService.openModal('menuSearch', {
					searchValue: function () { return searchValue; }
				}, 'MenuSearchController', 'menuSearchController', $scope, 'menu-search');

				self.modal.opened.then(() => {
					self.modalOpen = true;
				});
				self.modal.result.finally((selectedItem) => {
					self.modalOpen = false;
					self.searchValue = '';
				});
			}
		}

		/**
         *  Initializes selectors with either manager data and sets active role.
         *  Will refresh state if new role.
         */
		self.setupRoles = function (bool) {
			_setupRoles(bool);
		}

		/**
         *  Resets dashboard's advanced search when
         *  navigation is performed through the menu
         */
		self.resetAdvSearch = function() {
			DashboardService.resetStoredSearch();
		}

		/** Bookmarks stuff ----------------------------------------------------   **/

		//Opens modal
		self.bookmarkModal = function (bookmark) {
			ModalService.openModal('addBookmark', { bookmark: function () { return bookmark; } }, 'BookmarksController', 'bookmarksController');
		}

		self.editBookmarks = function () {
			self.lockBookmarks = false;
		};

		self.finishEditBookmarks = function () {
			self.lockBookmarks = true;
			MenuService.editBookmarks();
		};

		self.deleteBookmark = function (i) {
			MenuService.deleteBookmark(i);
		};

		self.doReorder = function(srcIndex, targetIndex, list) {
			targetIndex -= 1;
			list.splice(targetIndex, 0, list[srcIndex]);

			// Remove the item from the source, possibly correcting the index first.
			// We must do this immediately, otherwise ng-repeat complains about duplicates.
			if (targetIndex <= srcIndex) srcIndex++;

			list.splice(srcIndex, 1);
			return true;
		}

		self.logout = function() {
			const role = AccountService.getRole();

			if (!dropdownOpen) {
				if (role === 'sellerProfile') {
					OwnerService.logout();
				}
				else {
					AccountService.logout();
				}
			}
		};

		/*
            Called when role is switched.(Eg. Admin -> Broker)
            If role requires no more data it will call API and save required info to localstorage.
            Otherwise it will call to setup selectors
        */
		self.switchRole = function (role) {
			if (role.type === self.nextRole) {
				self.nextRole = null;
				self.isAuditorLike = false;
				return;
			}

			if (self.isManager && role.type === 'managerProfile') {
				self.isManager = false;
				return;
			}

			self.isAdmin = role === 'adminProfile';
			self.isManager = false;
			if (role.type == 'managerProfile') {
				self.isManager = true;
				_setupManager();
			}
			else if ((role.profiles || []).length > 0) {
				self.isAuditorLike = true;
				self.nextRole = role.type;

				if (role.profiles.length === 1) {
					self.agencyId = (role.profiles[0] || {}).agencyId;
					return self.updateAuditor();
				}

				_setupAuditor(role.profiles);
			}
			else {
				AccountService.chooseRole(role.role).then((res) => {
					AccountService.clearIds();
					AccountService.setAccessToken(res.id, res.token);
					AccountService.setupRole(res, null, res.roles, role.sbUserId);
					AccountService.switchRole(role.type);
					AlertService.changedRoles(role.type);
					self.setupRoles();
				})
				.catch($log.error);
			}
		}

		self.backToAdmin = function() {
			self.switchRole({ type: 'adminProfile', role: 'SB_ADMIN', sbUserId: AccountService.getUserId(true) });
		}

		/*
            Called when the broker changes for a manager or admin and calls API to update token and local storage.
        */
		self.updateRole = function () {
			let userId = null;
			let broker = null;
			if (self.managingId) {
				const profile = managingOptions.find((man) => {
					return man.id === self.managingId;
				})

				if (!profile) return;

				broker = (profile.managing || {}).brokerProfile || {};
				userId = (profile.managing || {}).id;

				AccountService.chooseRole('SB_MANAGER', userId).then((res) => {
					AccountService.setAccessToken(res.id);
					const user = {
						id: userId,
						brokerProfile: {
							id: broker.id,
							agencyId: broker.agencyId
						}
					}

					AccountService.setAvailableRoles(res.roles);
					AccountService.setupManager(res, null, user, null, 'managerProfile');
					AlertService.changedRoles('managerProfile');
					self.setupRoles();
				})
				.catch($log.error);
			}
		}

		self.updateAuditor = function() {
			if (self.agencyId) {
				const role = self.nextRole === 'auditorProfile' ? 'SB_AUDITOR' : 'SB_NOTARY';

				AccountService.chooseRole(role).then((res) => {
					AccountService.setupAuditor(res, null, self.roles, null, self.agencyId, self.nextRole);
					AlertService.changedRoles(self.nextRole);
					self.setupRoles();
				})
				.catch($log.error);
			}
		}

		function _setRoleAndLandingPage(){
			if (!self.currentRole) {
				self.currentRole = AccountService.getRole(true);
			}

			self.isAdmin = AccountService.isAdmin();

			switch(self.currentRole) {
			case 'adminProfile':
				self.landingPage = 'main.admin.dashboard'; break;
			case 'managerProfile':
			case 'brokerProfile':
			case 'agencyMemberProfile':
			case 'agencyOwnerProfile':
				self.landingPage = 'main.dashboard'; break;
			case 'notaryProfile':
			case 'auditorProfile':
				self.landingPage = 'main.role-listings'; break;
			case 'sellerProfile':
				self.landingPage = 'main.owner.listing'; break;
			default:
				self.landingPage = 'main.login'; break;
			}
		}

		function _setupAuditor(profiles) {

			if (!profiles) {
				profiles = (AccountService.getCurrentRoleProfile(self.currentRole) || {}).profiles;
			}

			profiles = profiles || [];

			if (self.currentRole === self.nextRole) {
				self.agencyId = AccountService.getAgencyId();
			}

			const options = profiles.reduce((arr, profile) => {
				arr.push({
					id: profile.agencyId,
					label: (profile.agency || {}).legalName || profile.agencyId
				})

				return arr;
			}, []);

			self.dropdowns.auditor = options;
		}

		function _setupManager() {
			const currentProfile = AccountService.getCurrentRoleProfile('managerProfile');
			managingOptions = currentProfile.profiles;
			for (const i in managingOptions) {
				if ((typeof managingOptions[i]) != 'object') {
					delete managingOptions[i];
				}

				managingOptions[i].label = managingOptions[i].managing.fullName || 'N/A';

				if (managingOptions[i].managing.brokerProfile.id == AccountService.getManagingProfileId()) {
					self.managingId = managingOptions[i].id;
				}
			}

			self.dropdowns.managing = managingOptions;
		}

		function _setupRoles(bool) {
			self.roles = AccountService.getAvailableRoles();
			self.currentRole = AccountService.getRole(true);
			self.isManager = (self.currentRole === 'managerProfile');
			self.isAdmin = (self.currentRole === 'adminProfile');
			self.isAuditorLike = (self.currentRole === 'notaryProfile' || self.currentRole === 'auditorProfile');

			if (self.isManager) {
				_setupManager();
			}

			if (self.isAdmin) {
				self.managing = AccountService.getManaging();
			}

			if (self.isAuditorLike) {
				self.nextRole = self.currentRole;
				_setupAuditor();
			}

			if (!bool) {
				AccountService.goToRoleLandingPage();
			}
		}





	}) // End of main.controller

	.filter('menuSection', () => {
		return function (obj, isTop) {
			const ret = {};

			if (obj) {
				for (const i in obj) {
					if ((obj[i].bottom && !isTop) || (isTop && !obj[i].bottom)) {
						ret[i] = obj[i];
					}
				}
			}

			return ret;
		};
	})
})(); // End of function()
