(function () {
	'use strict';
	angular.module('smartbrokr.broker', [])
	.service('BrokerService', function (Broker, AccountService, Agency, $rootScope) {
		const self = this;
		self.brokers = [];

		self.updateBroker = function(id, updates) {
			return Broker.prototype$updateAttributes({ id: id }, updates).$promise;
		}

		self.getBrokers = function (order) {
			const filter = {
				fields: [ 'id', 'agencyId', 'sbUserId', 'status', 'currentCourses', 'regionsCoveredIds' ],
				include: [
					'regionsCovered',
					{
						relation: 'user',
						scope: {
							fields: [ 'id', 'firstName', 'lastName', 'fullName', 'phones', 'email', 'emailEnabled', 'languageIds', 'photoUrl', 'accountId', '_address' ],
							include: [ 'languages' ]
						}
					}
				],
				where: {
					status: {
						neq: 'DISABLED'
					}
				}
			}

			if (order) {
				filter.order = order;
				filter.limit = 8;
			}

			return Agency.brokers({ id: AccountService.getAgencyId(), filter: filter }).$promise;
		}

		self.getActiveBrokers = function (order) {
			const filter = {
				fields: [ 'id', 'agencyId', 'sbUserId', 'status', 'currentCourses', 'regionsCoveredIds' ],
				include: [
					'regionsCovered',
					{
						relation: 'user',
						scope: {
							fields: [ 'id', 'firstName', 'lastName', 'fullName', 'phones', 'email', 'emailEnabled', 'languageIds', 'photoUrl', 'accountId', '_address' ],
							include: [ 'languages' ]
						}
					}
				],
				where: {
					status: 'SB_USER'
				}
			}

			if (order) {
				filter.order = order;
				filter.limit = 8;
			}

			return Agency.brokers({ id: AccountService.getAgencyId(), filter: filter }).$promise;
		}

		self.getAppointment = function (id) {
			return Broker.visits({ id: AccountService.getBrokerId(), filter: { where: { id: id }, include: 'users' } }).$promise;
		}

		self.getWebsiteUrl = function () {
			const filter = {
				fields: [ 'id', 'url', 'pluginUri', 'adminLogin' ]
			}
			return Broker.findById({ id: AccountService.getBrokerId(), filter: filter }).$promise;
		}

		self.saveWebsiteUrl = function (url, uri, adminLogin) {
			return Broker.prototype$updateAttributes({ id: AccountService.getBrokerId() }, { url: url, pluginUri: uri, adminLogin: adminLogin }).$promise;
		}

		self.isBrokerRegistered = function(code, isRealtor) {
			return Broker.isBrokerRegistered({ code: code, isRealtor: isRealtor }).$promise;
		}

		self.getSentEmails = function(id) {
			return Broker.sentEmails({ id: id }).$promise;
		}

		self.approveSharedListing = function(fk) {
			return Broker.acceptSharedListing({ id: AccountService.getBrokerId(), fk: fk }).$promise;
		}

		self.cancelSharedListing = function(fk) {
			return Broker.cancelSharedListing({ id: AccountService.getBrokerId(), fk: fk }).$promise;
		}

		self.rejectSharedListing = function(fk) {
			return Broker.rejectSharedListing({ id: AccountService.getBrokerId(), fk: fk }).$promise;
		}
	});
})(); // End of function()
