/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/**
 *	main.agency
 */

(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('AgencyController', function ($filter, $rootScope, $sce, $scope, AccountService, AgencyService, AlertService, FileService, GlobalVars, ModalService, NavService, SortingService, StorageService, UserService) {

		const self = this;
		// VARIABLES ============================================================================================================================

		const page			= 'agency';	// page name - used for filters and list-view/card-view directives
		let account			= null;		// Current user's account
		self.role			= null;		// Current user's role
		self.persons		= [];		// Array with agency people that will be displayed

		self.columns		= [];		// Columns for list view
		self.special		= null;		// Special parameters for data display
		self.cardView		= null;		// Whether to show card view or not
		self.filters		= null;		// Parameters for sorting and filtering
		self.mobileSearch	= '';		// Mobile search input value
		self.singleConfig	= null;		// Selectize config (dropdowns)
		self.languages		= null;		// Options for language dropdown
		self.sortInfo		= null;		// Information to pass to sorter
		self.navParams		= null;		// Parameters for navigation when clicking on a person's profile

		// SETUP VARIABLES ======================================================================================================================

		account 			= AccountService.getAccount();

		// self.filters 		= SortingService.getPageFilter(page);
		self.singleConfig 	= GlobalVars.createSingleConfig(false, 'id', 'name', 'name');
		self.cardView 		= StorageService.getView(page) == 'card';
		self.role 			= AccountService.getRole();

		AlertService.loading();

		if (!self.filters) {
			self.filters = SortingService.createPageFilter(
				page,
				{
					fullName: { weight: 1, value: '', desc: -1 },
					phones: { weight: 0, value: '', desc: 1, phone: true },
					email: { weight: 0, value: '', desc: 1 },
					agencyRole: { weight: 0, value: '', desc: 1,
						filterFunction: function(value, item) {
							const val = self.columns[4].field(item); 	// Get field
							return val.match(new RegExp(value, 'i')) !== null;
						},
						sortFunction(item) {
							return self.columns[4].field(item);		// Agency role field
						}
					},
					office: { weight: 0, value: '', desc: 1,
						filterFunction: function(value, item) {
							const val = self.columns[5].field(item); 	// Get field
							return val.match(new RegExp(value, 'i')) !== null;
						},
						sortFunction: function(item) {
							return self.columns[5].field(item);
						}
					},
					languageIds: { weight: 0, value: null, desc: 1, array: true, sortFunction: _getLangField },
					officeName: { weight: 0, value: '', desc: 1 },
					emailEnabled: { weight: 0, value: '', desc: 1 },
				}
			);
		}

		self.sortInfo = {
			page: page,
			sub: ['user']
		}

		self.checkInfo = {
			allChecked: false,
			nSelected: 0
		};

		self.navParams = {
			state: 'main.agency.account',
			getParams: function (entry) {
				if (entry) {
					return { id: entry.id, type: entry.type };
				}
				return {};
			}
		}

		let loadingMembers 	= true;
		let loadingOwners 	= true;

		self.bulkEmail = function() {
			if(!AccountService.limitedAccess()) {
				UserService.processBulkEmails($filter('searchMultiFilter')(self.persons, self.filters));
			}
		}

		getMembers();
		getOwner();

		// FUNCTIONS ============================================================================================================================

		/**
		 *	Checks if loading is finished.
			*	If it is, creates columns to be used in the frontend.
			*/
		function checkLoading() {
			if (!loadingMembers && !loadingOwners) {

				if (self.persons.length === 0) {
					self.independentBroker = true;
				}
				else {
					getLanguages();

					self.columns = [
						{
							name: 'PERSON.NAME',
							filter: 'fullName',
							special: true,
							cardView: true,
							size: 2,
							field: function (person) {
								return person.fullName;
							}
						},
						{
							name: 'PERSON.LANGUAGE',
							filter: 'languageIds',
							select: true,
							size: 1,
							config: self.singleConfig,
							options: self.languages,
							cardView: true,
							field: _getLangField
						},
						{
							name: 'PERSON.PHONE',
							filter: 'phones',
							size: 2,
							field: function(person) {
								return $filter('getOnePhone')(person.user.phones);
							}
						},
						{
							name: 'EMAIL.EMAIL',
							filter: 'email',
							size: 2,
							isEmail: true
						},
						{
							name: 'PERSON.AGENCY_ROLE',
							filter: 'agencyRole',
							size: 2,
							config: self.singleConfig,
							cardView: true,
							field: function(person) {
								if ((person.agencyRoleCode || {}).labels) {
									return person.agencyRoleCode.labels[$rootScope.language || 'EN'];
								}
								else if (person.agencyRoleId) {
									return person.agencyRoleId;
								}
								else {
									return '---';
								}
							}
						},
						{
							name: 'ADDRESS.OFFICE',
							filter: 'office',
							size: 2,
							field: function (person) {
								let ret = (person.office || {}).legalName || '----';
								if ((person.office || {}).code) {
									ret += ' (' + person.office.code + ')';
								}
								return ret;
							}
						},
						{
							name: 'ADDRESS.OFFICE_NAME',
							filter: 'officeName',
							size: 2,
							field: function(person) {
								if(person.user && person.user._address && person.user._address.officeName) {
									return person.user._address.officeName;
								}
								return 'N/A';
							}
						},
						{
							nameImgClass: 'btn-list-icon do-not-email',
							nameImgPopover: 'btnDoNotEmail',
							filter: 'emailEnabled',
							noSearch: true,
							size: 1,
							class: 'check',
							style: { 'text-align': 'center' },
							isHtml: true,
							field: function(supplier) {
								return supplier.user.emailEnabled ? '' : '<div class="icon-checked"></div>';
							}
						},
					];
					if(self.role == 'agencyOwnerProfile'){
						self.columns.push({
							name: 'COMMON.DELETE',
							noSort: true,
							noSearch: true,
							isClick: true,
							clickFunction: self.deleteAgencyPerson,
							field: function (person) {
								if (person.sbUserId != (account.user || {}).id) {
									return $sce.trustAsHtml('<button class="btn btn-trash" ' + ($rootScope.limitedAccess ? 'disabled' : '') + '></button>');
								}
							}
						})
					}

					self.special = {
						check: function (person) {
							return person.type === 'owner';
						},
						print: ' <strong>(' + $filter('translate')('PERSON.OWNER') + ')</strong>'
					}
				}
				AlertService.doneLoading();
			}
		}

		/**
		 *	Retrieves agency member profiles from the backend
			*	and adds them to self.persons
			*/
		function getMembers() {
			AgencyService.getMembers()
			.then((res) => {
				const auditors = res.auditors || [];
				const notaries = res.notaries || [];

				const notaryRole = $sce.valueOf($filter('translate')('ROLES.NOTARY'));
				const auditorRole = $sce.valueOf($filter('translate')('ROLES.AUDITOR'));

				const agencyMembers = angular.copy(res.members)
				.concat(auditors.reduce((arr,item) => {
					item.type = 'auditor';
					item.agencyRoleId = auditorRole;
					arr.push(item);
					return arr;
				},[]))
				.concat(notaries.reduce((arr,item) => {
					item.type = 'notary';
					item.agencyRoleId = notaryRole;
					arr.push(item);
					return arr;
				}, []));

				agencyMembers.forEach((item) => {
					item.languages = (item.user || {}).languages;
					item.fullName = (item.user || {}).fullName;

					if (!item.type) {
						item.type = 'member';
					}
				})

				self.persons = self.persons.concat(agencyMembers);
			})
			.catch((err) => { })
			.finally(() => {
				loadingMembers = false;
				checkLoading();
			})
		}

		/**
		 *	Retrieves agency owner profiles from the backend
		 *	and adds them to self.persons
		 */
		function getOwner() {
			AgencyService.getOwner()
			.then((res) => {
				let i;
				const length = res.length;
				for (i = 0; i < length; i++) {
					res[i].agencyName = (res[i].agency || {}).legalName || (res[i].agency || {}).legal_name;
					res[i].languages = (res[i].user || {}).languages;
					res[i].fullName = (res[i].user || {}).fullName;
					res[i].type = 'owner';
					res[i].agencyRoleId = '' + $filter('translate')('ROLES.AGENCY_OWNER');
				}
				self.persons = self.persons.concat(res);
			})
			.catch((err) => { })
			.finally(() => {
				loadingOwners = false;
				checkLoading();
			})
		}

		/**
		 *	Gets language options for filters
		 */
		function getLanguages() {
			const aux = $filter('unique')(self.persons, 'languages');
			let i;
			const length = aux.length;

			self.languages = [];

			for (i = 0; i < length; i++) {
				self.languages.push({ id: aux[i].id, name: $filter('translate')('LANGUAGES.' + aux[i].id) });
			}

			self.languages.unshift({ id: '', name: '------' });
			SortingService.sort(self.persons, self.filters, null, [], 'agency', true);
		}

		/**
		 * 	$event.stopPropagation
		 */
		self.stopPropagation = function ($event) {
			$event.stopPropagation();
		}

		/**
		 *	Opens modal to create a new agency profile.
		 */
		self.createPerson = function (type) {
			if (!AccountService.limitedAccess()) {
				NavService.navigate('main.agency.edit', { type: type });
			}
		}

		/**
		 *	Opens modal to start a conversation with an agency member/owner
			*/
		self.message = function () {

			let persons, isSelf, cantReceive, toMessage;

			if (!AccountService.limitedAccess()) {
				persons = $filter('searchMultiFilter')(self.persons, self.filters);
				isSelf 	= false;
				cantReceive = '';

				toMessage = persons.reduce((arr,curr) => {
					if (curr.checked) {
						if (curr.disabled || [ 'auditor', 'notary' ].indexOf(curr.type) >= 0) {
							cantReceive += (curr.user || {}).fullName + '<br>';
						}
						else if (curr.sbUserId == account.user.id) {
							isSelf = true;
						}
						else {
							arr.push(curr.sbUserId);
						}
					}

					return arr;
				}, []);

				cantSelf();
			}

			function cantSelf() {
				if (!isSelf) return wontMessage();

				const m = ModalService.prompt('MESSAGES.ERRORS.SELF_MSG', null, 'Ok', null, null, true);

				m.result.then((res) => {})
				.catch((err) => {})
				.finally(() => {
					wontMessage();
				})
			}

			function wontMessage() {
				if (cantReceive === '' || !cantReceive) return modal();

				const m = ModalService.prompt($filter('translate')('MESSAGES.ERRORS.NOT_SBUSER.TEXT_AGENCY', { names: cantReceive }), 'MESSAGES.ERRORS.NOT_SBUSER.TITLE', 'Ok', null, null, true);
				m.result.then((res) => {})
				.catch((err) => {})
				.finally(() => {
					modal();
				})
			}

			function modal() {
				if (toMessage.length > 0) {
					ModalService.openModal('newMessage', {
						message: function() { return null; },
						sender: function() { return null; },
						receiver: function() { return toMessage; }
					}, 'MessageModalController', 'messageModalController');
				}
			}
		};

		/**
		 *	Opens modal to confirm deletion of agency member/owner.
			*	@param 		person 			object 		Person that will be deleted
			*	@param 		index 			int 			Index of this person in self.persons
			*/
		self.deleteAgencyPerson = function (person, index) {
			if (!AccountService.limitedAccess()) {
				let deleteFunction;
				if (person.type == 'member' && person.id) {
					deleteFunction = AgencyService.disableMember;
				}
				else if (person.type == 'owner' && person.id) {
					deleteFunction = AgencyService.disableOwner;
				}
				else if (person.type == 'auditor' && person.id){
					deleteFunction = AgencyService.disableAuditor;
				}
				else if (person.type == 'notary' && person.id) {
					deleteFunction = AgencyService.disableNotary;
				}
				else {
					return;
				}
				const retFunction = function(res) {
					self.persons = [];
					getMembers();
					getOwner();
				}

				ModalService.openModalWithFunction(
					'deleteUser', {
						id: function () { return person.id },
						user: function () { return person; },
						deleteFunction: function () { return deleteFunction; },
						userType: function () { return person.type }
					},
					'DeleteUserController',
					'deleteUserController',
					retFunction
				);
			}
		}

		function _getLangField(person) {
			const langs = (person.languages || []).reduce((arr, lang) => {
				arr.push('' + $filter('translate')('LANGUAGES.' + lang.id));
				return arr;
			}, []).sort();

			if (langs.length === 0) {
				return '---';
			}

			return langs.join(', ');
		}

		// Export csv - Complete
		$scope.exportComplete = function () {
			self.isPopoverOpen = false;
			const exportFilter = self.filters;
			exportFilter.checked = { weight: 0, value: 'true', desc: true };
			const toExport = $filter('searchMultiFilter')(self.persons, exportFilter);
			if(toExport.length > 0) {
				FileService.exportComplete(toExport, 'agency');
			} else {
				const modal = ModalService.openModal('nothingToExport', { folder: function () { return {} }, type: function() { return '' }, options: function() { return { } } }, 'FolderController', 'folderController');
				modal.result
				.then((res) => {})
				.catch((err) => {});
			}
		};

		// Export csv - Simplified (first name, last name and email)
		$scope.exportSimple = function() {
			self.isPopoverOpen = false;
			const exportFilter = self.filters;
			exportFilter.checked = { weight: 0, value: 'true', desc: true };
			const toExport = $filter('searchMultiFilter')(self.persons, exportFilter);
			if(toExport.length > 0) {
				FileService.exportSimple(toExport, 'ROLES.AGENCY');
			} else {
				const modal = ModalService.openModal('nothingToExport', { folder: function () { return {} }, type: function() { return '' }, options: function() { return { } } }, 'FolderController', 'folderController');
				modal.result
				.then((res) => {})
				.catch((err) => {});
			}
		}

	}) // End of controller
})(); // End of function()
