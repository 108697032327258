/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('EditTaskTemplateController', function ($filter, $stateParams, $timeout, AccountService, AlertService, ModalService, SortingService, ResourceService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.template   = null;   // Task template that is being edited
		self.canShare   = false;
		self.filters    = null;   // Parameters for sorting/filtering

		self.sortInfo   = null;   // Sort info to send to listView directive
		self.inject     = null;   // Object with self to be injected into listView directive

		self.shared = $stateParams.submenu.indexOf('shared') > -1;

		// SETUP VARIABLES ======================================================================================================================

		const page = 'task-templates';

		self.filters = {
			seq: { weight: 0, value: '', desc: -1 },
			name: { weight: 0, value: '', desc: 1 },
			description: { weight: 0, value: '', desc: 1 },
			fullName: { weight: 0, value: '', desc: 1 },
			due: { weight: 0, value: '', desc: 1 }
		};

		self.sortInfo = {
			page: page,
			sub: ['assignedTo.user', 'supplier']
		}

		self.inject = {
			name: 'editTaskTemplateController',
			inject: self
		}

		load();

		// FUNCTIONS ============================================================================================================================

		self.openModal = function (editTask) {
			const template = self.template;

			const retFunction = function (result) {
				$timeout(() => {
					load();
				}, 100);
			}

			ModalService.openModalWithFunction(
				'taskTemplateAddTask',    // Template id
				{ template: function () { return template; }, editTask: function () { return editTask; } }, // Resolve
				'TaskTemplateModalController',  // Controller
				'taskTemplateModalController',  // ControllerAs
				retFunction
			);
		}

		self.doReorder = function() {

			self.template.taskList.forEach((task,i) => {
				task.seq = i + 1;
			})

			self.template.tasks = angular.copy(self.template.taskList);

			ResourceService.saveTaskTemplate(self.template).then((res) => {
				AlertService.saved();
			})
			.catch((err) => {})
			.finally(() => {
				load();
			})
		}

		self.deleteTask = function (i) {

			const del = function() {
				self.template.taskList.splice(i, 1);
				self.doReorder();
			}

			ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_TASK');
		}

		self.sort = function (category) {
			SortingService.sort(self.template.taskList, self.filters, category, ['assignedTo.user', 'supplier']);
		};

		function load() {
			AlertService.loading();
			ResourceService.getTaskTemplate($stateParams.id).then((res) => {
				if (res.length == 1 || !Array.isArray(res)) {
					self.template = Array.isArray(res) ? res[0] : res;
					self.columns = [
						{
							name: '#',
							noSort: true,
							noSearch: true,
							isHtml: true,
							class: 'has-handle',
							field: function (task) {
								return '<button class="btn handle"></button>' + task.seq;
							}
						},
						{
							name: 'TASK.TASK_NAME',
							noSearch: true,
							noSort: true,
							field: function (task) {
								if ((task.name || '').includes('TASK_LIST'))
									return '' + $filter('translate')(task.name);

								return task.name;
							}
						},
						{
							name: 'COMMON.DESCRIPTION',
							noSearch: true,
							noSort: true,
							isHtml: true,
							internalClass: 'preview-email',
							field: function (task) {
								return $filter('addNewLines')(task.description);
							}
						},
						{
							name: 'TASK.ASSIGNED_TO',
							noSort: true,
							noSearch: true,
							field: function (task) {
								return ((task.assignedTo || {}).user || {}).fullName || '';
							}
						},
						{
							name: 'FILES.FILES',
							noSearch: true,
							noSort: true,
							field: function (task) {
								return task.files;
							}
						},
						{
							name: '',
							noSearch: true,
							noSort: true,
							isHtml: true,
							class: 'buttons',
							field: function (task) {
								if(self.shared) return '';
								const buttons = '<button class="btn btn-green medium"' +
                    'ng-disabled="limitedAccess"' +
                    'ng-click="editTaskTemplateController.openModal(entry)">' +
                    $filter('translate')('COMMON.UPDATE') + '</button>' +
                    '<button class="btn btn-link"' +
                    'ng-disabled="limitedAccess"' +
                    'ng-click="editTaskTemplateController.deleteTask(i)">' +
                    '<img width="16" height="16" src="/public/img/iconTrash.svg"></button>';

								return buttons;
							}
						}
					]

					SortingService.sort(self.template.taskList, self.filters, 'seq', self.sortInfo.sub, null, true);
				}
			})
			.catch((err) => {})
			.finally(() => { AlertService.doneLoading() })
		}
	}); // End of controller
})(); // End of function()
