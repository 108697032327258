(function () {

    "use strict";

    angular.module("smartbrokr")

    .controller("AdminSearchController", function (AdminService, $log, $scope, $uibModalInstance, searchValue) {

        var self = this;

        self.searchValue = searchValue || '';
        self.data = {};

        self.search = function() {
            $scope.loading  = true;

            if (self.searchValue.length > 0) {
                AdminService.menuSearch(self.searchValue).then(function(res) {
                    self.data = res;
                })
                .catch(function(err) {
                    $log.error(err);
                })
                .finally(function() {
                    $scope.loading = false;
                })
            }
            else {
                self.data = [];
                $scope.loading = false;
            }
        }

        self.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }) // End of controller

    /** Directives to show menu search results */
    .directive('adminPerson', function () {
        return {
            restrict: 'E',
            scope: {
                person: '=',
                userType: '@'
            },
            link: function (scope, element, attrs) {
                scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;

                scope.getRole = function(role) {
                    switch(role) {
                        case 'broker': return 'Broker';
                        case 'member': return 'Agency Member';
                        case 'owner': return 'Agency Owner';
                        case 'notary': return 'Notary';
                        case 'auditor': return 'Auditor';
                        default: return null;
                    }
                }

                var person = scope.person || {};

                if (scope.userType === 'broker') {
                    scope.active = person.status === 'SB_USER';
                }
                else {
                    scope.active = !person.disabled;

                    if (!!person.expires) {
                        scope.active = scope.active && moment(person.expires).isAfter(moment());
                    }
                }

            },
            templateUrl: "/js/src/admin/templates/partial.person.html"
        };
    })

    .directive('adminAgency', function () {
        return {
            restrict: 'E',
            scope: {
                agency: '='
            },
            link: function (scope, element, attrs) {
                scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
                scope.office = ((scope.agency || {}).offices || [])[0] || null;
                scope.active = (scope.agency || {}).id === 'CAN_CENTRIS_ROA' || (!!(scope.agency || {}).subscriptions && (((scope.agency || {})).subscriptions || {}).status !== 'canceled');
            },
            templateUrl: "/js/src/admin/templates/partial.agency.html"
        };
    })
})(); // End of function()
  