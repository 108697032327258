

(function () {

    "use strict";

    angular.module("smartbrokr")
    .controller("ConfirmAccountController", function ($stateParams, AccountService, AlertService, $rootScope, $state, $timeout) {
        var self = this;
        var accessToken     = $stateParams.accessToken;
        var accountId       = $stateParams.accountId;
        self.allowChangePassword = $stateParams.confirmState == 'change-password';

        self.done       = false;
        self.success    = false;
        self.message    = null;

        $rootScope.inLoginPage = true;

        AccountService.clearUser();

        if(accessToken && accountId != "0") {
            AccountService.confirmAccount(accountId, accessToken).then(function(res) {
                _success(true);
            })
            .catch(function(err) {
                self.message = 'ALERT_MESSAGES.ERROR.CLIENT.ERROR_BASIC';
            })
            .finally(function() {
                self.done = true;
            })
        }

        self.setPassword = function() {
            if (!accessToken) {
                AlertService.errorMessage('wrongUrl');
                return;
            }
            AccountService.changePassword(accessToken, self.password).then(function(res) {
                AlertService.successMessage('setPassword');
                _success();
            })
            .catch(function(err) {})
            .finally(function() {
                self.done = true;
            })
        }

        function _success(addMessage) {
            self.success = true;

            if (addMessage) {
                self.message = 'PAGES.LOGIN.CONFIRM.CONFIRMED_MESSAGE';
            }

            // Go to login page after 15s
            $timeout(function() {
                $state.go('main.login', { internal: true });
            }, 15000);
        }
    });
})();
