/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.account', [])
	.service('AccountService', function (Account, Agency, $q, $filter, LoopBackAuth, $rootScope, $localStorage, $sessionStorage, SbUser, $state) {
		const self = this;

		self.account = {};

		self.loggedIn = function () {
			return $localStorage.loggedIn;
		};

		self.limitedAccess = function() {
			return $sessionStorage.limitedAccess || $rootScope.limitedAccess || $localStorage.limitedAccess;
		}

		self.clearIds = function () {
			const ids = [
				'agencyId', 'agencyMemberId', 'agencyOwnerId', 'auditorId',
				'brokerId', 'managerProfileId', 'managingAgencyId', 'managingUserId',
				'managingRole', 'managingProfileId', 'notaryId', 'sellerId', 'listingId', 'currentUser' ];

			ids.forEach((id) => {
				delete $localStorage[id];
			})
		}

		self.clearUser = function() {
			clearOnAccountChange();
			LoopBackAuth.clearUser();
		}

		function clearOnAccountChange(){
			//self.clearIds();
			//LoopBackAuth.clearUser();
			//LoopBackAuth.clearStorage();
			$rootScope.success = {};
			$rootScope.danger = {};
			$rootScope.currentUser = {};
			$rootScope.loggedIn = false;
			$localStorage.loggedIn = false;
			$rootScope.cancelLoop();
		}

		/* Setters ----------------------------------------------------------- */

		self._set = function(key, value) {
			$localStorage[key] = value;
		}

		self.setAccessToken = function (id, token) {
			if (token)
				$localStorage.token = token;

			LoopBackAuth.clearStorage();
			LoopBackAuth.accessTokenId = id;
			LoopBackAuth.save();
			$rootScope.loggedIn = true;
			self._set('loggedIn', true);
		}

		self.setManagingIds = function (userId, profileId, agencyId, profileType) {
			self._set('managingUserId', userId);
			self._set('managingProfileId', profileId);
			self._set('managingAgencyId', agencyId);
			self._set('managingRole', profileType || 'brokerProfile');
		}

		self.updateCurrentRole = function (type, res) {
			const currentRoles = self.getAvailableRoles();
			for (const i in currentRoles) {
				if (currentRoles[i].type == type) {
					currentRoles[i] = res;
				}
			}
			self.setAvailableRoles(currentRoles);
		}

		self.setAvailableRoles = function (roles) {
			self._set('availableRoles', JSON.stringify(roles));
		}

		self.finishLogin = function (res) {
			self._set('account', JSON.stringify(res));

			self._set('loggedIn', true);
			$rootScope.loggedIn = true;

			const limited = (res.status === 'canceled');
			$sessionStorage.limitedAccess = limited;
			$rootScope.limitedAccess = limited;
			self._set('limitedAccess', limited);
		}

		self.switchRole = function (role) {
			//return Account.switchRole({ id: getId(), role: role }).$promise;
			const roles = self.getAvailableRoles();
			for (const i in roles) {
				if (roles[i].type == role) {
					self._set('role', role);
					break;
				}
			}
		}

		self._get = function(key) {
			return $localStorage[key];
		}

		self.getAdminId = function() {
			return self._get('adminId');
		}

		self.getAccountId = function (checkManaging) {

			if (checkManaging && (self._get('managing') || self.isAdmin())) {
				return self._get('managing').accountId || (self._get('managing').managing || {}).accountId || LoopBackAuth.currentUserId;
			}

			return LoopBackAuth.currentUserId;
		}

		self.getAccessToken = function () {
			return LoopBackAuth.accessTokenId;
		}

		self.getBrokerId = function () {

			let managingId, brokerId;
			if ($localStorage.managingProfileId && $localStorage.managingRole === 'brokerProfile')
				managingId = $localStorage.managingProfileId;

			if ($localStorage.brokerId)
				brokerId = $localStorage.brokerId;
			return managingId || brokerId;
		}

		self.getAgencyOwnerId = function() {
			const managingRole = self._get('managingRole');
			return managingRole === 'agencyOwnerProfile' ? self._get('managingProfileId') : self._get('agencyOwnerId');
		}

		self.getAgencyMemberId = function() {
			const managingRole = self._get('managingRole');
			return managingRole === 'agencyMemberProfile' ? self._get('managingProfileId') : self._get('agencyMemberId');
		}

		self.getAuditorId = function () {

			const id = self._get('auditorId');

			if (!id) {
				if (self._get('managingProfileId') && self._get('managingRole') === 'auditorProfile') {
					self._set('auditorId', self._get('managingProfileId'));
					return self._get('auditorId');
				}

				const available = self.getAvailableRoles();
				const auditor = available.find((item) => { return item.role === 'SB_AUDITOR'; });
				if (((auditor || {}).profiles || []).length > 0) {
					self._set('auditorId', auditor.profiles[0].id);
					return self._get('auditorId');
				}
				else {
					return null;
				}
			}

			return id;
		}

		self.getNotaryId = function () {
			const id = self._get('notaryId');

			if (!id) {
				if (self._get('managingProfileId') && self._get('managingRole') === 'notaryProfile') {
					self._set('notaryId', self._get('managingProfileId'));
					return self._get('notaryId');
				}

				const available = self.getAvailableRoles();
				const notary = available.find((item) => { return item.role === 'SB_NOTARY'; });
				if (((notary || {}).profiles || []).length > 0) {
					self._set('notaryId', notary.profiles[0].id);
					return self._get('notaryId');
				}
				else {
					return null;
				}
			}

			return id;
		}

		self.getManagingUserId = function () {
			return self._get('managingUserId');
		}
		self.getManagingProfileId = function () {
			return self._get('managingProfileId');
		}

		self.getAccount = function () {
			if ($localStorage.account) {
				self.account = JSON.parse($localStorage.account);
				return JSON.parse($localStorage.account);
			}
			return self.account;
		}

		self.getAgencyId = function () {
			let managingAgencyId, agencyId;
			if ($localStorage.managingAgencyId)
				managingAgencyId = $localStorage.managingAgencyId;
			if ($localStorage.agencyId)
				agencyId = $localStorage.agencyId;
			return managingAgencyId || agencyId;
		}

		self.getUserId = function (actualUser) {
			let managingUserId, userId;
			if ($localStorage.managingUserId && !actualUser)    // Gets Managee
				managingUserId = $localStorage.managingUserId;
			if ($localStorage.userId)                           // Gets Manager
				userId = $localStorage.userId;
			return managingUserId || userId;
		}

		self.getRole = function (real) {
			const managing = self._get('managingRole');
			const userRole = self._get('role');

			return (real || !managing ? userRole : managing);
		}

		self.getAvailableRoles = function () {
			const roles = self._get('availableRoles');
			if (roles) return JSON.parse(roles);
			return [];
		}

		self.getProfileId = function() {
			const role = self.getRole();
			const profile = self.getCurrentRoleProfile(role);
			return profile.id;
		}

		self.getCurrentRoleProfile = function (thisRole) {
			const roles = self.getAvailableRoles();
			const role = thisRole ? thisRole : self.getRole(true);
			for (let i = 0; i < roles.length; i++) {
				if (roles[i].type == role) {
					return roles[i];
				}
			}
			return {};
		}

		self.getActualProfile = function (thisRole) {
			const roles = self.getAvailableRoles();
			const role = thisRole ? thisRole : self.getRole(true);
			for (let i = 0; i < roles.length; i++) {
				if (roles[i].type == role) {
					if (role == 'managerProfile') {
						for (let j = 0; j < roles[i].profiles.length; j++) {
							if (roles[i].profiles[j].managingId == self.getManagingUserId()) {
								const retRole = roles[i].profiles[j];
								retRole.role = roles[i].role;
								retRole.type = roles[i].type;
								return retRole;
							}
						}
					}
					return roles[i];
				}
			}
			return {};
		}

		self.getCurrentAccount = function () {
			return Account.findById({ id: self.getAccountId(true), filter: { include: 'user' } }).$promise;
		}

		self.getIntercomInfo = function() {
			const token       = self._get('token');
			const account     = self.getAccount();
			const agencyId    = self.getAgencyId();
			const role        = self.getRole(true);   // Current role
			const denied      = [ 'adminProfile', 'notaryProfile', 'auditorProfile' ];    // Roles that can't access Agency.findById
			const def         = $q.defer();
			let ret         = false;

			if ((token || {}).intercom && (account || {}).email) {
				ret = {
					email: account.email,
					user_hash: token.intercom,
					name: (account.user || {}).fullName,
					phone: '' + $filter('getOnePhone')((account.user || {}).phones),
					avatar: {
						type: 'avatar',
						image_url: (account.user || {}).photoUrl
					}
				}

				if (!agencyId || denied.indexOf(role) >= 0) {   // No agency ID or current role can't query agency
					def.resolve(ret);
				}
				else {
					Agency.findById({ id: agencyId, filter: { fields: [ 'id', 'legalName' ] } }, (res) => {
						res = res || {};
						ret.company = {
							company_id: res.id,
							name: res.legalName
						}

						def.resolve(ret);
					}, def.reject);
				}
			}

			return def.promise;
		}


		self.setSchema = function (isRealtor) {
			$localStorage.schema = isRealtor ? 'realtor' : 'centris';
		}

		self.getLanguage = function () {
			return $localStorage.language || 'EN';
		}

		self.getSchema = function () {
			return $localStorage.schema;
		}

		self.setLanguage = function (language) {
			if ($rootScope.TRANSLATABLE.indexOf(language) < 0) {
				language = 'EN';
			}
			$localStorage.language = language;
		}

		self.getAccountNow = function () {
			const role = self.getRole(true);

			if ($rootScope.loggedIn && role !== 'sellerProfile') {
				self.getAccountDetails(null, true).then((res) => {
					if (self.isAdmin() && self._get('managing')) {
						const managing = self._get('managing');
						managing.fullName = res.user.fullName;
						managing.googleCalendarId = res.user.googleCalendarId;
						managing.isEmbeddedGoogleCalendarEnabled = res.user.isEmbeddedGoogleCalendarEnabled;
						managing.photoUrl = res.user.photoUrl;
						managing.mainLanguageId = res.mainLanguageId;
						self._set('managing', managing);
					}
					else {
						$rootScope.currentUser = {
							fullName: res.user.fullName,
							photoUrl: res.user.photoUrl,
							googleCalendarId: res.user.googleCalendarId,
							isEmbeddedGoogleCalendarEnabled: res.user.isEmbeddedGoogleCalendarEnabled
						};
						const account = res.account;
						account.user = res.user;
						$localStorage.account = JSON.stringify(account);
						self.setLanguage(account.mainLanguageId);
					}
				})
				.catch((err) => {
					console.log('err: ', err);
				})
			}
			else {
				$rootScope.currentUser = self._get('currentUser');
			}
		}

		/***
            API Calls
        ***/
		self.clear = function () {
			$localStorage.$reset();
			$sessionStorage.$reset();
		}

		self.login = function (email, password) {
			clearOnAccountChange();
			self.clear();
			saveManaging({});
			return Account.login({ rememberMe: true }, { email: email, password: password }).$promise;
		};

		self.logout = function (skipState) {
			Account.logout().$promise
			.then((res) => {
				clearOnAccountChange();
				self.clear();
				if (!skipState) {
					$state.go('main.login');
				}
				else {
					$state.reload();
				}
			})
			.catch((err) => {
				console.error('error logging out', err);
			});
		}

		self.resetPassword = function (email) {
			const options = {
				email: email
			}
			return Account.resetPassword(options).$promise;
		}

		self.changePassword = function (accessToken, password) {
			self.setAccessToken(accessToken);
			return Account.changePassword({ password: password }).$promise;
		}

		self.confirmAccount = function (uid, token) {
			return Account.confirm({ uid: uid, token: token }).$promise
		}

		self.findById = function(id) {
			return Account.findById({ id: id }).$promise;
		}

		self.saveAccount = function (account, user, agency, checkManaging) {
			return Account.saveAccount({ id: self.getAccountId(checkManaging), account: account, user: user, agency: agency }).$promise;
		}

		self.getAccountDetails = function (id, checkManaging) {
			id = id || self.getAccountId(checkManaging);
			if (!id) return $q.resolve();
			return Account.getAccount({ id: id }).$promise;
		}

		self.savePreferences = function (automatedEmails, minNumberLogs, confirmationEmail) {
			const changes = {
				automatedEmails: automatedEmails || false,
				minNumberLogs: minNumberLogs || 25,
				confirmationEmail: confirmationEmail || false
			};
			return Account.savePreferences({ id: self.getAccountId(), changes: changes }).$promise;
		}

		self.createAccount = function (account) {
			return Account.createUser({}, account).$promise;
		}

		self.getManager = function (managerId) {
			return SbUser.managers.findById({ id: self.getUserId(true), fk: managerId, filter: { include: 'manager' } }).$promise;
		}

		self.getManagers = function () {
			return SbUser.managers({ id: self.getUserId(true), filter: { include: 'manager' } }).$promise;
		}

		self.getGoogleAuthUrl = function() {
			return SbUser.getOAuthUrl({ id: self.getUserId(false) }).$promise;
		}

		self.disableGoogleIntegration = function() {
			const accountId = self.getAccountId(true);
			return Account.disableGoogleIntegration({ id: accountId }).$promise;
		}

		self.detachGoogleAccount = function() {
			const accountId = self.getAccountId(true);
			return Account.detachGoogleAccount({ id: accountId }).$promise;
		}

		self.syncGoogle = function() {
			const accountId = self.getAccountId(true);
			return Account.syncGoogleEvents({ id: accountId }).$promise;
		}

		self.chooseRole = function (role, managingId, managingType) {
			const accountId = self.getAccountId();
			clearOnAccountChange();
			saveManaging({});
			return Account.chooseRole({ id:  accountId }, { role: role, managingId: managingId, managingType: managingType }).$promise;
		}

		self.setupRole = function (res, account, roles, user) {

			/***
                Finish role setup
            ***/

			let roleIdKey = null;
			let setAgency = true;

			switch(res.type) {
			case 'adminProfile':
				setAgency = false;
				roleIdKey = 'adminId'; break;
			case 'agencyOwnerProfile':
				roleIdKey = 'agencyOwnerId'; break;
			case 'agencyMemberProfile':
				roleIdKey = 'agencyMemberId'; break;
			case 'brokerProfile':
				roleIdKey = 'brokerId';
				break;
			case 'notaryProfile':
				roleIdKey = 'notaryId';
				setAgency = false; break;
			case 'auditorProfile':
				roleIdKey = 'auditorId';
				setAgency = false;
			}

			if (roleIdKey) {
				self._set(roleIdKey, res.role.id);
			}

			if (setAgency) {
				self._set('agencyId', res.role.agencyId);
				self.setSchema(res.role.realtor || (res.role.agency || {}).realtor);
			}

			self.setAccessToken(res.id, res.token);
			self._set('role', res.type);

			if (roles) {
				self.setAvailableRoles(roles);
			}
			if (user) {
				if (typeof user === 'string') {
					self._set('userId', user);
				}
				else {
					self._set('userId', user.id);
					self._set('language', (account || {}).mainLanguageId || user.languageIds[0] || 'EN')
				}
			}
			if (account)
				self.finishLogin(account);

		}
		self.setManagerProfileId = function (id) {
			self._set('managerProfileId', id);
		}

		self.setupAuditor = function(res, account, roles, user, agencyId, type) {
			self.setAccessToken(res.id, res.token);
			if (roles) {
				self.setAvailableRoles(roles);
			}

			self._set('currentAgencyId', agencyId);
			self._set('agencyId', agencyId);
			self._set('role', res.role.type);

			self.updateCurrentRole(type, res.role);

			if (user) {
				if (typeof user === 'string') {
					self._set('userId', user);
				}
				else {
					self._set('userId', user.id);
					self._set('language', user.languageIds[0] || 'EN');
				}
			}

			const profile = res.role.profiles.find((item) => { return item.agencyId === agencyId });

			if (!profile) {
				return false;
			}

			if (type === 'notaryProfile') {
				self._set('notaryId', profile.id);
			}
			else {
				self._set('auditorId', profile.id);
			}

			self.setSchema((profile.agency || {}).realtor);

			if (account) {
				self.finishLogin(account);
				return true;
			}
		}

		self.unlock = function(token) {
			return Account.unlock({ token: token }).$promise;
		}

		self.setupManager = function (res, roles, user, account, type) {
			self.setAccessToken(res.id, res.token);
			if (roles) {
				self.setAvailableRoles(roles);
			}
			self._set('role', type);
			self.updateCurrentRole(type, res.role);
			if (res.role && res.role.profiles) {
				for (let i = 0; i < res.role.profiles.length; i++) {
					if (res.role.profiles[i].managingId == user.id) {
						self._set('managerProfileId', res.role.profiles[i].id);
						self.setManagingIds(res.role.profiles[i].managing.id, res.role.profiles[i].managing.brokerProfile.id, res.role.profiles[i].managing.brokerProfile.agencyId);
						self.setSchema(res.role.profiles[i].managing.brokerProfile.realtor);
						saveManaging(res.role.profiles[i]);
					}
				}
			}
			if (account) {
				self.finishLogin(account);
				self._set('userId', (account.user || {}).id);
				self._set('agencyId', ((account.user || {}).brokerProfile || {}).agencyId);
			}

		}

		self.setupAdmin = function (res, roles, profileId, agencyId, userId, account, type, profileType) {

			self.setAccessToken(res.id, res.token);

			if (roles) self.setAvailableRoles(roles);

			self._set('role', type);
			self.setManagingIds(userId, profileId, agencyId, profileType);
			self.updateCurrentRole(type, res.role);

			const filter = {
				fields: [ 'id', 'fullName', 'accountId', 'photoUrl', 'sbUserId', 'isGoogleIntegrated', 'googleCalendarId' ],
				include: [
					profileType,
					{
						relation: 'account',
						scope: {
							fields: [ 'id', 'mainLanguageId' ]
						}
					}
				]
			}

			SbUser.findById({ id: userId, filter: filter }).$promise.then((res) => {

				if (!res) return;

				res.mainLanguageId = (res.account || {}).mainLanguageId;
				delete res.account;

				saveManaging(res);
				if (profileType === 'brokerProfile')
					self.setSchema((res.brokerProfile || {}).realtor);

				if (account)
					self.finishLogin(account);

				if (profileType === 'auditorProfile' || profileType === 'notaryProfile') {
					$state.go('main.role-listings.listings', {}, { reload: true });
				}
				else {
					$state.go('main.dashboard', {}, { reload: true });
				}
			})
			.catch((err) => {
				console.log('err: ', err);
			})
		}

		self.getLocation = function () {
			return $q((resolve, reject) => {
				self.getAccountDetails(null, true)
				.then((res) => {
					const value = {};
					if (res.user._address && res.user._address.country) {
						value.country = res.user._address.country;
					}
					if (res.user._address && res.user._address.province) {
						value.province = res.user._address.province;
					}
					resolve(value);
				})
				.catch((err) => {
					reject(err);
				});
			});
		}

		self.getCountry = function () {
			return $q((resolve, reject) => {
				self.getAccountDetails(null, true)
				.then((res) => {
					let value = {};
					if (res.user._address && res.user._address.country) {
						value = res.user._address.country;
					}
					resolve(value);
				})
				.catch((err) => {
					reject(err);
				})
			});
		}

		self.getProvince = function () {
			return $q((resolve, reject) => {
				self.getAccountDetails(null, true)
				.then((res) => {
					let value = {};
					if (res.user._address && res.user._address.province) {
						value = res.user._address.province;
					}
					resolve(value);
				})
				.catch((err) => {
					reject(err);
				})
			});
		}

		self.signup = function (type, data) {
			//check what type of account

			const tellUs = self.getTellUs(); //business name
			const inviteStaff = self.getInviteStaff().split(',');
			const inviteBrokers = self.getInviteBrokers().split(',');
			const ownerEmail = self.getOwnerEmail();
			const account = {
				email: tellUs.email,
				password: tellUs.password
			}
			const user = {
				firstName: tellUs.firstName.trim(),
				lastName: tellUs.lastName.trim(),
				emailEnabled: true
			}
			const metadata = {
				address_1: data.metadata.address_1,
				address_2: data.metadata.address_2,
				city: data.metadata.city,
				postalCode: data.metadata.postalCode,
				province: data.metadata.province,
				country: data.metadata.country
			}
			data.plan.token = data.token;
			if (type == 'broker') {
				user.email = account.email;
				const broker = {
					brokerCode: tellUs.brokerCode,
					realtor: !!tellUs.realtorCode
				}

				const agency = {
					id: tellUs.realtorCode || tellUs.centrisCode,
					legalName: tellUs.businessName,
					realtor: !!tellUs.realtorCode
				}
				const body = {
					account: account,
					user: user,
					agency: agency,
					broker: broker,
					subscription: data.plan,
					metadata: data.metadata
				}
				return Account.signupBroker(body).$promise;
			}
			else if (type == 'agency') {
				const agency = {
					id: tellUs.realtorCode || tellUs.centrisCode,
					legalName: tellUs.agencyName,
					realtor: !!tellUs.realtorCode
				}
				const brokerEmails = inviteBrokers.length > 0 ? inviteBrokers : [];
				const agencyEmails = inviteStaff.length > 0 ? inviteStaff : [];
				user.email = ownerEmail;
				const body = {
					account: account,
					user: user,
					agency: agency,
					brokerEmails: brokerEmails,
					agencyEmails: agencyEmails,
					subscription: data.plan,
					metadata: data.metadata
				}
				return Account.signupAgency(body).$promise;
			}
			else {
				//error
				console.error('Did not recognize signup type.')
			}
		}

		self.getMainLanguage = function () {

			if (self.isAdmin() && self._get('managing')) {
				return self._get('managing').mainLanguageId;
			}

			const account = self.getAccount();
			return account.mainLanguageId || null;
		}

		self.saveMainLanguage = function (lang) {
			return Account.prototype$updateAttributes({ id: self.getAccountId(true) }, { mainLanguageId: lang }).$promise;
		}

		self.getLanguages = function () {
			return SbUser.languages({ id: self.getUserId() }).$promise;
		}

		self.saveLanguages = function (langs) {
			return Account.user.update({ id: self.getAccountId(true) }, { languageIds: langs }).$promise;
		}

		self.goToRoleLandingPage = function () {
			$rootScope.$emit('updateAccount');
			$rootScope.inLoginPage = false;
			const role = self.getRole(true);

			switch (role) {
			case 'adminProfile':
				$state.go('main.admin.dashboard', {}, { reload: true }); break;
			case 'managerProfile':
			case 'brokerProfile':
			case 'agencyMemberProfile':
			case 'agencyOwnerProfile': $state.go('main.dashboard', {}, { reload: true }); break;
			case 'notaryProfile':
			case 'auditorProfile': $state.go('main.role-listings.listings', {}, { reload: true }); break;
			default: $state.go('main.login', {}, { reload: true });
			}
		}

		self.isEmailRegistered = function (email) {
			return Account.isEmailRegistered({}, { email: email }).$promise;
		}

		function saveCurrentUser(user) {
			$rootScope.currentUser = user;
		}

		function saveManaging(user) {
			$rootScope.managing = user;
			$localStorage.managing = user;
		}

		self.getManaging = function () {
			return $localStorage.managing;
		}

		self.updateManaging = function () {
			$rootScope.managing = $localStorage.managing;
		}

		self.isLikeBroker = function () {
			const role = self.getRole();
			return (role == 'brokerProfile' || role == 'managerProfile');
		}

		self.isAdmin = function() {
			const role = self.getRole(true);
			return role === 'adminProfile';
		}

		// SIGNUP FLOW -----------------------------------------------------------------------------------------------------------

		self.getStart = function () {
			try {
				return JSON.parse($localStorage['signup-start']) || {};
			} catch (error) {
				return '';
			}
		}

		self.getTellUs = function () {
			try {
				return JSON.parse($localStorage['signup-tell-us']) || {};
			} catch (error) {
				return {};
			}
		}

		self.getOwnerEmail = function () {
			try {
				const data = JSON.parse($localStorage['signup-owner-email']).email || JSON.parse($localStorage['signup-tell-us']).email;
				return data;
			} catch (error) {
				try {
					const data = JSON.parse($localStorage['signup-tell-us']).email
					return data;
				}
				catch (e) {
					return ''
				}
			}
		}

		self.getInviteStaff = function () {
			try {
				return JSON.parse($localStorage['signup-invite-staff']);
			} catch (error) {
				return '';
			}
		}

		self.getInviteBrokers = function () {
			try {
				return JSON.parse($localStorage['signup-invite-brokers']);
			} catch (error) {
				return '';
			}
		}

		self.getSituation = function () {
			try {
				return JSON.parse($localStorage['signup-situation']);
			} catch (error) {
				return '';
			}
		}

		self.getWebsite = function () {
			try {
				return JSON.parse($localStorage['signup-website']);
			} catch (error) {
				return '';
			}
		}

		self.getPermissions = function () {
			try {
				return JSON.parse($localStorage['signup-permissions']);
			} catch (error) {
				return '';
			}
		}

		self.getBillingAddress = function () {
			try {
				return JSON.parse($localStorage['signup-billing-address']);
			} catch (error) {
				return '';
			}
		}

		self.getCreditCard = function () {
			try {
				return JSON.parse($localStorage['signup-credit-card']);
			} catch (error) {
				return '';
			}
		}

		self.getPlan = function () {
			try {
				return JSON.parse($localStorage['signup-plan']);
			} catch (error) {
				return '';
			}
		}

		self.getStripeToken = function () {
			try {
				return JSON.parse($localStorage['signup-stripe-token']);
			} catch (error) {
				return '';
			}
		}

		self.getVisitedReview = function () {
			return $localStorage['visited-review'];
		}

		self.setVisitedReview = function () {
			$localStorage['visited-review'] = true;
		}

		self.saveStart = function (data) {
			$localStorage['signup-start'] = JSON.stringify(data);
		}

		self.saveTellUs = function (data) {
			$localStorage['signup-tell-us'] = JSON.stringify(data);
		}

		self.saveInviteStaff = function (data) {
			$localStorage['signup-invite-staff'] = JSON.stringify(data);
		}

		self.saveOwnerEmail = function (data) {
			$localStorage['signup-owner-email'] = JSON.stringify({ email: data });
		}

		self.saveInviteBrokers = function (data) {
			$localStorage['signup-invite-brokers'] = JSON.stringify(data);
		}

		self.saveSituation = function (data) {
			$localStorage['signup-situation'] = JSON.stringify(data);
		}

		self.saveWebsite = function (data) {
			$localStorage['signup-website'] = JSON.stringify(data);
		}

		self.savePermissions = function (data) {
			$localStorage['signup-permissions'] = JSON.stringify(data);
		}

		self.saveBillingAddress = function (data) {
			$localStorage['signup-billing-address'] = JSON.stringify(data);
		}

		self.saveCreditCard = function (data) {
			$localStorage['signup-credit-card'] = JSON.stringify(data);
		}

		self.savePlan = function (data) {
			$localStorage['signup-plan'] = JSON.stringify(data);
		}

		self.saveStripeToken = function (data) {
			if(data === null){
				delete $localStorage['signup-stripe-token'];
			} else {
				$localStorage['signup-stripe-token'] = JSON.stringify(data);
			}
		}
	});
})(); // End of function()
