/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('PublicVisitsController', function ($stateParams, $translate, ModalService, PublicService) {
		const self = this;

		self.visit          = null;
		self.users          = [];
		self.action         = toVerb($stateParams.action);
		self.isConfirming   = $stateParams.action == 'confirm';
		self.isCancelling   = $stateParams.action == 'cancel';
		self.isRescheduling = $stateParams.action == 'reschedule';

		getVisit();

		// FUNCTIONS ============================================================================================================================

		function toVerb(word) {
			switch(word){
			case 'confirm': return 'confirmed';
			case 'cancel': return 'cancelled';
			case 'reschedule': return 'rescheduled';
			}
		}

		function getVisit() {
			let user, visit, isOwner, confirmed, isCoBuyer;

			PublicService.getVisit($stateParams.secret)
			.then((res) => {
				user    = res.user;
				isCoBuyer = user.isCoBuyer;
				visit   = res.visit;
				isOwner = res.isOwner;
				confirmed = res.isConfirmed;

				self.visit = res.visit;

				if (!user && !isOwner) {
					self.title = 'User not found';
				}
				else {
					if (self.action == 'cancelled') {
						$translate('PAGES.CALENDAR.ALERTS.' + ( isOwner ? 'CANCEL_OWNER' : 'CANCEL_USER' ) )
						.then((msg) => {

							const fields = [
								{
									labels: [ msg ],
									justText: true,
									class: 'just-text'
								},
								{
									label: 'COMMON.REASON',
									model: 'reason',
									inputType: 'text',
									required: false
								}
							];

							ModalService.openForm(fields, 'PAGES.CALENDAR.ALERTS.TITLE', true).then((res) => {
								changeVisit($stateParams.secret, isOwner, res.reason, isCoBuyer).then(() => {
									confirmed = true;
									cont();
								});
							},
							(reason) => {
								self.title = 'COMMON.NOT_CONFIRMED';
								cont();
							})
						})
					}
					else {
						confirmed = true;
						changeVisit($stateParams.secret, isOwner, null, isCoBuyer).then(cont);
					}
				}

				function cont() {
					const date = moment(visit.scheduled);
					self.time = date.format('LT');
					self.users = res.users || [];

					if (self.action == 'cancelled' && confirmed && !isOwner) {
						self.users.push({ fullName: (res.user || {}).fullName, isCancelled: true });
					}
					else if(!isOwner) {
						self.users.push({ fullName: (res.user || {}).fullName, isConfirmed: confirmed });
					}
				}
			})
			.catch((err) => {})
		}

		function changeVisit(secret, isOwner, reason, isCoBuyer){
			let api = null;
			switch(self.action){
			case 'confirmed': api = PublicService.confirmVisit;break;
			case 'cancelled': api = PublicService.cancelVisit;break;
			}

			return api(secret, isOwner, reason, isCoBuyer).then((res) => {
				switch(self.action) {
				case 'confirmed': self.title = 'PAGES.CALENDAR.ALERTS.CONFIRMED'; break;
				case 'cancelled': self.title = 'PAGES.CALENDAR.ALERTS.CANCELLED'; break;
				}
			})
			.catch((err) => {
				self.title = 'There was an error.';
				console.error(err);
			});
		}

	}) // End of controller
})(); // End of function()
