(function () {

  "use strict";

  angular.module("smartbrokr")




    .controller("TeamController", function ($uibModalInstance, team, isNew, UserService, BrokerService, ListingService, GlobalVars,  $rootScope) {
      //FeatureService
      var self = this;
      self.team = angular.copy(team);
      self.isNew = isNew || false;
      self.memberConfig = GlobalVars.createMultiConfig(false, "id", "fullName", true);
      self.propertyConfig = GlobalVars.createMultiConfig(false, "id", "address", true);
      self.clientConfig = GlobalVars.createMultiConfig(false, "id", "fullName", true);
      self.filterMembers = function () {
        self.filteredMembers = [];
        UserService.getAllMine()
          .then(function (res) {
            for (var i = 0; i < res.length; i++) {
              if (res[i].user) {
                res[i].fullName = res[i].fullName;
              }
            }
            self.filteredMembers = res;
          })
          .catch(function (err) {

          })
      }

      self.filterProperties = function () {
        self.filteredProperties = [];
        ListingService.getInventory()
          .then(function (res) {
            for (var i = 0; i < res.length; i++) {
              if (res[i].property && res[i].property._address) {
                res[i].address = res[i].property._address.addressString;
              }
            }
            self.filteredProperties = res;
          })
          .catch(function (err) {

          })
          .finally(function(){
            $rootScope.$emit("loadingEnd");
          })
      }
      self.filterMembers();
      self.filterProperties();
      self.close = function () {
        $uibModalInstance.dismiss('cancel');
      };

      self.saveTeam = function () {
        var team = {
          name: self.team.name || null,
          managerId: self.team.managerId || null,
          memberIds: self.team.memberIds || [],
          message: self.team.message || "",
          listingIds: self.team.listingIds || [],
          clientIds: self.team.clientIds || []
        }
        if (!!isNew) {
          UserService.createTeam(team)
            .then(function (res) {
              $uibModalInstance.close({ closed: true });
            })
            .catch(function (err) {})
        }
        else {
          UserService.updateTeam(self.team.id, team)
            .then(function (res) {
              $uibModalInstance.close({ closed: true });
            })
            .catch(function (err) {})
        }
      }

    }) // End of controller
})(); // End of function()
