/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.listing', [])

	.service('ListingService', function (Agency, Auditor, Broker, Folder, Listing, Notary, Offer, Property, $window, $log, $rootScope, $q, AccountService, AlertService, ModalService) {
		const self = this;
		const user = {
			relation: 'user',
			scope: {
				fields: ['id', 'fullName', 'phones', 'email', 'photoUrl', 'emailEnabled', 'companyName']
			}
		};

		const fileRelation = {
			relation: 'file',
			scope: {
				fields: ['id', 'name', 'originalFilename', 'seq', 'url', 'type', 'listingId'],
			}
		};

		const imageQuery = {
			fields: ['id', 'seq', 'fileId', 'listingId'],
			include: fileRelation,
			order: 'seq ASC'
		};

		function getSharedRelation(brokerId) {
			const query = {
				relation: 'shared',
				scope: {
					where: {},
					fields: ['id', 'listingId', 'brokerId'],
					include: [
						{
							relation: 'broker',
							scope: {
								fields: [ 'id', 'agencyId', 'sbUserId', 'status' ],
								include: user
							}
						}
					]
				}
			}

			if(brokerId) {
				scope.where = {
					brokerId: brokerId
				}
			}

			return query;
		}

		self.includeRequired = true;

		self.saveFolder = function(listingId, folder) {
			const fId = (folder || {}).id;
			if (!fId) {
				return Listing.folders.create({ id: listingId }, folder).$promise;
			}

			delete folder.id;
			return Listing.folders.updateById({ id: listingId, fk: fId }, folder).$promise;
		}

		self.saveDocumentFolder = function(listingId, documentFolder) {
			const fId = (documentFolder || {}).id;
			if (!fId) {
				return Listing.documentMetadata.create({ id: listingId }, documentFolder).$promise;
			}

			delete documentFolder.id;
			return Listing.documentMetadata.updateById({ id: listingId, fk: fId }, documentFolder).$promise;
		}

		/***
              Header Buttons for Listing Tabs
            ***/
		self.headerButtons = function () {
		    return {
		        description: [
		            {
		                title: 'FORMS.SAVE',
		                link: '',
		                type: 'submit',
		                basicButton: true
		            },
		            {
		                title: 'PERSON.NEW_VISIT',
		                link: '',
		                function: 'goToVisits',
		                basicButton: true,
		                hideOnNew: true
		            },
		            {
		                title: 'PERSON.BUYER.BUYER_SEARCH',
		                link: 'main.buyers',
		                function: '',
		                basicButton: true,
		                hideOnNew: true
		            },
		            {
		                title: 'FILES.VIEW',
		                link: '',
		                function: '',
		                basicButton: true,
		                hideOnNew: true
		            }
		        ],
		        addenda: [
		            {
		                title: 'FORMS.SAVE',
		                link: '',
		                type: 'submit',
		                basicButton: true
		            }
		        ],
		        features: [
		            {
		                title: 'PROPERTY.ADD_FEATURE',
		                link: '',
		                function: 'addFeature',
		                basicButton: true
		            }
		        ],
		        rooms: [
		            {
		                title: 'PROPERTY.ADD_ROOM',
		                link: '',
		                function: 'addRoom',
		                basicButton: true
		            }
		        ],
		        costs: [
		            {
		                title: 'PROPERTY.ADD_COST',
		                link: '',
		                function: 'addCost',
		                basicButton: true
		            }
		        ],
		        lot: [
		            {
		                title: 'FORMS.SAVE',
		                link: '',
		                type: 'submit',
		                basicButton: true
		            }
		        ],
		        evaluation: [
		            {
		                title: 'FORMS.SAVE',
		                link: '',
		                type: 'submit',
		                basicButton: true
		            }
		        ],
		        building: [
		            {
		                title: 'FORMS.SAVE',
		                link: '',
		                type: 'submit',
		                basicButton: true
		            }
		        ]
		    };
		}

		/***
		  Listing's Create Smart Listing
		***/
		self.createListing = function (listing) {
		    return Broker.listings.create({ id: AccountService.getBrokerId() }, listing).$promise;
		}

		self.getFolders = function(lId) {
		    return Listing.folders({ id: lId, filter: { include: 'files' } }).$promise;
		}

		self.createFolder = function(lId, name, required = false) {
			return Listing.documentMetadata.create({ id: lId }, { documentTypeId: name, required: required } ).$promise;
		}

		/***
		  Listing Getters
		***/
		self.deleteListing = function (fk) {
		    const del = function() {
		        AlertService.loading();
		        return Broker.listings.destroyById({ id: AccountService.getBrokerId(), fk: fk }).$promise;
		    }
		    return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_DRAFT');
		}

		self.getBrokers = function (id) {
		    const filter = {
				fields: [ 'id', 'listingId', 'brokerId', 'primary' ],
				include: {
					relation: 'broker',
					scope: {
						fields: [ 'id', 'agencyId', 'sbUserId', 'status' ],
						include: user
					}
				},
		        order: 'primary DESC'
		    }
		    return Listing.listingBrokers({ id: id, filter: filter }).$promise;
		}

		self.getAllListings = function (dontBroadcast) {
		    const filter = {
		        fields: [ 'id' ],
		        include: [
		            {
		                relation: 'property',
		                scope: {
		                    fields: [ 'id', 'listingId', '_address' ]
		                }
		            }
		        ]
		    };

		    return Broker.listings({ id: AccountService.getBrokerId(), filter: filter }).$promise;
		}

		self.getLogs = function (id) {
		    return Listing.logsAbout({ id: id, filter: { order: 'created DESC', include: 'user' } }).$promise;
		}

		// Unified getListings functions to one that takes 'where' as parameter
		const getListings = function (where, pageNum, sortBy) {
		    const skip = pageNum * 10;
		    const filter = {
		        include: [
		            {
		                relation: 'property',
		                scope: {
		                    fields: [ 'id', '_address', 'typeId', 'listingId', 'numBedrooms', 'numBathrooms', 'buildingLivingArea' ],
		                    include: ['type', 'codes']
		                }
		            },
		            {
		                relation: 'sellers',
		                scope: {
		                    fields: [ 'id', 'sbUserId' ],
		                    include: user
		                }
		            },
		            {
		                relation: 'mainPhoto',
		                scope: {
		                    fields: [ 'id', 'listingId', 'url', 'type', 'name' ]
		                }
		            },
					{
						relation: 'mlsPhotos',
						scope: {
							include: 'file',
							order: 'seq ASC',
							limit: 1
						}
					},
					{
						relation: 'files',
						scope: {
							fields: ['listingId'],
							where: { bucket: 'listing' },
							order: 'seq ASC',
							limit: 1
						}
					},
		            {
						relation: 'clicks',
						scope: {
							fields: [ 'total' ]
						}
					},
					{
						relation: 'shared',
						scope: {
							fields: ['id']
						}
					}
		        ],
		        where: where,
		        order: 'id ASC',
			};

		    if (AccountService.isLikeBroker()) {
		        return Broker.listings({ id: AccountService.getBrokerId(), filter: filter }).$promise;
		    }
		    else if (AccountService.getRole() == 'notaryProfile') {
		        return Notary.listings({ id: AccountService.getNotaryId(), filter: filter }).$promise;
		    }
		    else if (AccountService.getRole() == 'auditorProfile') {
		        return Auditor.listings({ id: AccountService.getAuditorId(), filter: filter }).$promise;
		    } else {
		        filter.include.splice(5);
		        if (sortBy) {
		            filter.order = sortBy
		            filter.limit = 8;
		        }
		        return Agency.getListings({ id: AccountService.getAgencyId(), filter: filter, brokerId: null }).$promise;
		    }
		}

		self.getMLS = function () {
		    const expression = '/^M\\d{7,}\\b/';
		    const where = {
		        and: [
		            { id: { regexp: expression } },
		            { status: 'Active' }
		        ]
		    };
		    return getListings(where);
		}

		self.getSmart = function () {
		    const expression = '/^S\\d{6,}\\b/';
		    const where = {
		        and: [
		            { id: { regexp: expression } },
		            { isDraft: { neq: true } },
					{ status: { neq: 'Converted' } },
					{ status: { neq: 'Expired' } },
		        ]
		    };
		    return getListings(where);
		}

		self.getInventory = function (pageNum) {
		    const where = {
		        status: 'Active'
		    };
		    return getListings(where, pageNum);
		}

		self.getSortedAgencyListings = function (sortBy) {
		    const where = {
		        status: 'Active'
		    }
		    return getListings(where, null, sortBy);
		}

		self.getArchive = function () {
		    const where = {
		        and: [
		            { status: { neq: 'Active' } },
		            { status: { neq: 'Smart' } },
		            { status: { neq: 'Converted' } }
		        ]
		    };
		    return getListings(where);
		}

		self.getConverted = function () {
		    const where = {
		        status: 'Converted'
		    };
		    return getListings(where);
		}

		self.getDrafts = function () {
		    const where = {
		        and: [
		            { status: 'Smart' },
		            { isDraft: true }
		        ]
		    };
		    return getListings(where);
		}

		self.getActive = function() {
			const filter = {
				fields: [ 'id' ],
				where: {
					status: 'Active'
				},
		        include: [
		            {
		                relation: 'property',
		                scope: {
		                    fields: [ 'id', 'listingId', '_address' ]
		                }
		            }
		        ]
		    };

		    return Broker.listings({ id: AccountService.getBrokerId(), filter: filter }).$promise;
		}

		self.getActiveAndSmart = function () {
		    const where = {
		        or: [
		            { status: 'Smart' },
		            { status: 'Active' }
		        ]
		    };
		    return getListings(where);
		}

		self.getSharedSmartListings = function() {
			const where = { status: 'Smart' };
			return getListings(where);
		}

		/***
		  Listing's Link Users
		***/
		self.linkBuyer = function (lId, fk) {
		    return Listing.buyers.link({ id: lId, fk: fk }, null).$promise;
		}
		self.unlinkBuyer = function (lId, fk) {
		    return Listing.buyers.unlink({ id: lId, fk: fk }).$promise;
		}

		self.linkSeller = function (lId, fk) {
		    return Listing.sellers.link({ id: lId, fk: fk }, null).$promise;
		}
		self.unlinkSeller = function (lId, fk) {
		    return Listing.sellers.unlink({ id: lId, fk: fk }).$promise;
		}

		self.linkBroker = function (lId, fk) {
		    return Listing.brokers.link({ id: lId, fk: fk }, null).$promise;
		}
		self.unlinkBroker = function (lId, fk) {
		    return Listing.brokers.unlink({ id: lId, fk: fk }).$promise;
		}

		self.linkNotary = function (lId, fk) {
		    return Listing.notaries.link({ id: lId, fk: fk }, null).$promise;
		}
		self.unlinkNotary = function (lId, fk) {
		    return Listing.notaries.unlink({ id: lId, fk: fk }).$promise;
		}

		self.linkSupplier = function (lId, fk) {
		    return Listing.suppliers.link({ id: lId, fk: fk }, null).$promise;
		}

		self.unlinkSupplier = function (lId, fk) {
		    const del = function() {
		        return Listing.suppliers.unlink({ id: lId, fk: fk }).$promise;
		    }
		    return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_PERSON');
		}

		self.reorderSuppliers = function(id, suppliers) {
			return Listing.reorderSuppliers({ id: id }, { suppliers: suppliers }).$promise;
		}

		/***
		 Listing's Tabs Updates
		***/
		self.updateDescription = function (fk, listing) {
			delete listing.isDraft;
			delete listing.soldDuplicate;
		    return Broker.updateDescription({ id: AccountService.getBrokerId(), fk: fk }, listing).$promise;
		}

		self.createDescription = function (listing) {
		    listing = listing || {};
		    return Broker.createDescription({ id: AccountService.getBrokerId() }, { listing: listing }).$promise;
		}

		self.saveLot = function (id, property, address) {
		    return Listing.saveLot({ id: id }, { property: property, address: address }).$promise;
		}

		self.saveBuilding = function (id, fk, building) {
		    return Listing.property.update({ id: id, fk: fk }, building).$promise;
		}

		self.saveEvaluation = function (id, fk, evaluation) {
		    return Listing.property.update({ id: id, fk: fk }, evaluation).$promise;
		}

		/***
		        Listing's Tabs Visits
		***/
		self.createVisit = function (lId, visit) {
		    return Listing.visits.create({ id: lId }, visit).$promise;
		}
		self.getVisits = function (lId) {
		    return Listing.visits(
				{
					id: lId,
					filter: {
						where: {
							type: {
								nlike: 'A'
							}
						},
						include: [
							{
								users: ['buyerProfile', 'sellerProfile', 'ownedSuppliers']
							},
							{
								relation: 'coBuyers',
								scope: {}
							}
						]
					},
					order: 'scheduled DESC'
				}
			).$promise;
		}

		self.getAppointments = function (lId) {
		    return Listing.visits({ id: lId, filter: { where: { type: 'A' }, include: [ { users: ['buyerProfile', 'sellerProfile', 'ownedSuppliers'] }, { relation: 'coBuyers', scope: {} } ] } } ).$promise;
		}

		self.getVisit = function (lId, fk) {
		    return Listing.visits.findById({ id: lId, fk: fk }).$promise;
		}

		self.updateVisit = function (lId, fk, visit) {
		    return Listing.visits.updateById({ id: lId, fk: fk }, visit).$promise;
		}

		self.deleteVisit = function (lId, fk) {
		    const del = function() {
		        return Listing.visits.destroyById({ id: lId, fk: fk }).$promise;
		    }
		    return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_APPOINTMENT');
		}

		self.addProperty = function (lId, property) {
		    if (!property.id) {
		        return Listing.property.create({ id: lId }, property).$promise;
		    }
		    else {
		        property.listingId = lId;
		        return Listing.property.update({ id: lId }, property).$promise;
		    }

		}

		/***
		 Listing's Features
		***/
		self.createFeature = function (pId, feature) {
		    return Property.features.create({ id: pId }, feature).$promise;
		}

		self.getFeature = function (pId, fk) {
		    return Property.features.findById({ id: pId, fk: fk }).$promise;
		}

		self.updateFeature = function (pId, fk, feature) {
		    return Property.features.updateById({ id: pId, fk: fk }, feature).$promise;
		}

		self.deleteFeature = function (pId, fk) {
		    const del = function() {
		        return Property.features.destroyById({ id: pId, fk: fk }).$promise;
		    }
		    return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FEATURE');
		}

		/***
		  Listing's Rooms
		***/
		self.createRoom = function (pId, room) {
		    return Property.rooms.create({ id: pId }, room).$promise;
		}

		self.getRooms = function (pId) {
		    return Property.rooms({ id: pId }).$promise;
		}

		self.getRoom = function (pId, fk) {
		    return Property.rooms.findById({ id: pId, fk: fk }).$promise;
		}

		self.updateRoom = function (pId, fk, newRoom) {
		    if (!!pId && typeof pId === 'object' && !fk && !newRoom) {
		        const room = angular.copy(pId);
		        pId = room.propertyId;
		        fk = room.id;
		        newRoom = room;
		    }

		    return Property.rooms.updateById({ id: pId, fk: fk }, newRoom).$promise;
		}

		self.deleteRoom = function (pId, fk) {
		    const del = function() {
		        return Property.rooms.destroyById({ id: pId, fk: fk }).$promise;
		    }
		    return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_ROOM');
		}

		/***
		  Listing's Photos
		***/
		self.getPhotos = function (id) {
			const query = {
				where: { bucket: 'listing' },
				order: 'seq ASC',
				fields: ['id', 'name', 'url', 'originalFilename', 'created', 'listingId', 'seq'],
			};

		    return Listing.files({ id: id, filter: query }).$promise;
		}

		self.deletePhoto = function (id, fk) {
		    return Listing.files.destroyById({ id: id, fk: fk }).$promise;
		}

		self.getMlsImages = function (id) {
		    return Listing.mlsPhotos({ id: id, filter: imageQuery }).$promise;
		}

		self.getCaravanImages = function (id) {
		    return Listing.caravanPhotos({ id: id, filter: imageQuery }).$promise;
		}
		self.linkCaravanImage = function (id, fk, secondId) {
			return Listing.linkImageWithSequence({ id: id, relation: 'caravanPhotos', fk: fk }, { secondId: secondId }).$promise;
		}

		self.unlinkCaravanImage = function (id, fk) {
		    return Listing.caravanImages.unlink({ id: id, fk: fk }).$promise;
		}

		self.getVideoImages = function (id) {
		    return Listing.videoPhotos({ id: id, filter: imageQuery }).$promise;
		}

		self.linkVideoImage = function (id, fk, secondId) {
			return Listing.linkImageWithSequence({ id: id, relation: 'videoPhotos', fk: fk }, { secondId: secondId }).$promise;
		}

		self.unlinkVideoImage = function (id, fk, index) {
			return Listing.videoImages.unlink({ id: id, fk: fk, index: index }).$promise;
		}

		self.getFeatureImages = function (id) {
			return Listing.featurePhotos({ id: id, filter: imageQuery }).$promise;
		}

		self.linkFeatureImage = function (id, fk, secondId) {
			return Listing.linkImageWithSequence({ id: id, relation: 'featurePhotos', fk: fk }, { secondId: secondId }).$promise;
		}

		self.unlinkFeatureImage = function (id, fk) {
			return Listing.featureImages.unlink({ id: id, fk: fk }).$promise;
		}

		self.getWebsiteImages = function (id) {
			return Listing.websitePhotos({ id: id, filter: { include: 'file', order: 'seq ASC' } }).$promise;
		}

		self.linkWebsiteImage = function (id, fk, secondId) {
			return Listing.linkImageWithSequence({ id: id, relation: 'websitePhotos', fk: fk }, { secondId: secondId }).$promise;
		}

		self.unlinkWebsiteImage = function (id, fk) {
			return Listing.websiteImages.unlink({ id: id, fk: fk }).$promise;
		}

		self.savePhotos = function (id, photos, type) {
			const data = photos.reduce((arr, photo) => {
				arr.push({ id: photo.id, seq: photo.seq });
				return arr;
			}, []);

			if (type == 'file') {
				type = 'SmartFile';
			}
			else {
				type = type.charAt(0).toUpperCase() + type.substring(1);
			}
			return Listing.savePhotos({ id: id, type: type, data: data }).$promise;
		}

		self.downloadPhotos = function (id, photos) {
			if (id && photos) {
				const baseUrl = $rootScope.baseURL;
				const params = {
					access_token: AccountService.getAccessToken(),
					photoIds: photos
				};

				//Add authentication headers in URL
				const apiURL = baseUrl + '/Listings/' + id + '/downloadPhotos';
				const url = [apiURL, $.param(params)].join('?');
				$window.open(url);
			}
		}

		self.updateFile = function (id, fk, file) {
			return Listing.files.updateById({ id: id, fk: fk }, file).$promise;
		}

		/***
              Generate Marketing Materials
            ***/
		self.generateCaravanBulletin = function (lId, isNewPrice, isNewListing, headerId) {
			return Listing.generateCaravanBulletin({ id: lId }, { isNewListing: isNewListing, isNewPrice: isNewPrice, headerId: headerId, brokerId: AccountService.getBrokerId() }).$promise;
		}

		self.generateFeatureSheet = function (lId, headerId) {
			return Listing.generateFeatureSheet({ id: lId }, { headerId: headerId, brokerId: AccountService.getBrokerId() }).$promise;
		}

		self.generateVideo = function (id, options) {
			const name = 'video_' + Date.now() + '.mp4';
			options = options || {};
			options.name = name;
			options.lang = $rootScope.language;

			Listing.generateVideo({ id: id }, options).$promise
			.then((res) => {
				AlertService.startVideo(name, id);
			})
		}

		self.deleteListingDocument = function (id, fk) {
			const del = function() {
				return Listing.files.destroyById({ id: id, fk: fk }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FILE');
		}
		self.getDocumentMetadata = function (id) {
			return Listing.getDocuments({ id: id }).$promise
		}

		/***
              Listing's Marketing
            ***/
		self.getMarketing = function (id) {
			return Listing.files({ id: id, filter: { where: { bucket: 'marketing' }, order: 'created DESC' } }).$promise;
		}

		self.deleteMarketing = function (id, fk) {
			const del = function() {
				return Listing.files.destroyById({ id: id, fk: fk }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FILE');
		}

		/***
              Listing's Resources
            ***/
		self.getResources = function (id) {
			return Listing.files({ id: id, filter: { where: { bucket: 'resources' }, order: 'created DESC' } }).$promise;
		}

		self.deleteFolder = function(listingId, folderId) {
			const del = function() {
				return Listing.folders.destroyById({ id: listingId, fk: folderId }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FOLDER');
		}

		self.deleteDocumentFolder = function(listingId, folderId) {
			const del = function() {
				return Listing.documentMetadata.destroyById({ id: listingId, fk: folderId }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FOLDER');
		}

		self.deleteFromFolder = function(folderId, fileId) {
			const del = function() {
				return Folder.files.destroyById({ id: folderId, fk: fileId }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FILE');
		}

		self.deleteResource = function (id, fk) {
			const del = function() {
				return Listing.files.destroyById({ id: id, fk: fk }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FILE');
		}

		self.updateCharacteristic = function (propId, char) {
			if (char.id) return Property.characteristics.updateById({ id: propId, fk: char.id }, char).$promise;
			return Property.characteristics.create({ id: propId }, char).$promise;
		}

		self.convert = function (smart, mls) {
			const body = { id: mls };
			return Listing.convert({ id: smart }, body).$promise;
		}

		self.unarchive = function(id) {
			return Listing.unarchive({ id: id }).$promise;
		}

		self.archive = function(id) {
			return Listing.archive({ id: id }).$promise;
		}

		self.getListingAddress = function (id) {
			if (!id) {
				id = AccountService._get('listingId');
			}
			return Listing.getAddress({ id: id }).$promise;
		}

		self.getCosts = function (id, brokerId, checkForShared = false, sharer = false) {
			const filter = {
				fields: [ 'id' ],
				include: [
					{
						relation: 'property',
						scope: {
							fields: [ 'id', 'listingId' ],
							include: {
								relation: 'costs',
								scope: {
									fields: ['id', 'value', 'year', 'provider', 'frequency', 'propertyId', 'typeId'],
									include: {
										relation: 'type',
										scope: {
											fields: ['id', 'type', 'labels', 'centris', 'realtor']
										}
									}
								}
							}
						}
					},
					{
						relation: 'listingBrokers',
						scope: {
							fields: [ 'id', 'listingId', 'brokerId', 'primary' ],
							include: {
								relation: 'broker',
								scope: {
									fields: [ 'id', 'agencyId', 'sbUserId', 'status' ],
									include: [
										user,
										{
											relation: 'agency',
											scope: {
												fields: ['id', 'legalName']
											}
										}
									]
								}
							}
						},
						order: 'primary DESC'
					}
				]
			}

			if(checkForShared) {
				filter.include.push(getSharedRelation(brokerId));
			} else if(sharer) {
				filter.include.push(getSharedRelation());
			}

			return Listing.findById({ id: id, filter: filter }).$promise;
		}

		self.getLot = function (id, brokerId, checkForShared = false, sharer = false) {
			const filter = {
				fields: [ 'id' ],
				include: [
					{
						relation: 'property',
						scope: {
							fields: [ 'id', 'listingId', 'lotFrontage', 'waterAreaName', 'lotArea', 'lotAreaMUId', '_address' ]
						}
					},
					{
						relation: 'listingBrokers',
						scope: {
							fields: [ 'id', 'listingId', 'brokerId', 'primary' ],
							include: {
								relation: 'broker',
								scope: {
									fields: [ 'id', 'agencyId', 'sbUserId', 'status' ],
									include: [
										user,
										{
											relation: 'agency',
											scope: {
												fields: ['id', 'legalName']
											}
										}
									]
								}
							}
						},
						order: 'primary DESC'
					}
				]
			}

			if(checkForShared) {
				filter.include.push(getSharedRelation(brokerId));
			} else if(sharer) {
				filter.include.push(getSharedRelation());
			}

			return Listing.findById({ id: id, filter: filter }).$promise;
		}

		self.getEvaluation = function (id, brokerId, checkForShared = false, sharer = false) {
			const filter = {
				fields: [ 'id' ],
				include: [
					{
						relation: 'property',
						scope: {
							fields: [ 'id', 'listingId', 'evalYear', 'evalLot', 'evalBuilding' ]
						}
					},
					{
						relation: 'listingBrokers',
						scope: {
							fields: [ 'id', 'listingId', 'brokerId', 'primary' ],
							include: {
								relation: 'broker',
								scope: {
									fields: [ 'id', 'agencyId', 'sbUserId', 'status' ],
									include: [
										user,
										{
											relation: 'agency',
											scope: {
												fields: ['legalName']
											}
										}
									]
								}
							}
						},
						order: 'primary DESC'
					}
				]
			}

			if(checkForShared) {
				filter.include.push(getSharedRelation(brokerId));
			} else if(sharer) {
				filter.include.push(getSharedRelation());
			}

			return Listing.findById({ id: id, filter: filter }).$promise;
		}

		self.getBuilding = function (id, brokerId, checkForShared = false, sharer = false) {
			const filter = {
				fields: [ 'id' ],
				include: [
					{
						relation: 'property',
						scope: {
							fields: [ 'id', 'listingId', 'constructed', 'buildingFrontage', 'buildingArea', 'buildingLivingArea', 'areasMUId' ],
							include: [
								{
									relation: 'additionalBuildings',
									scope: {
										fields: ['id', 'propertyId', 'numBedrooms',' numBathrooms', 'numPowderRooms', 'numFloors', 'buildingLivingArea', 'created'],
										include: [
											{
												relation: 'characteristics',
												scope: {
													fields: ['id', 'additionalPropertyId', 'description', 'addenda', 'inclusions', 'exclusions', 'marketingVideoURL', 'virtualTourURL'],
												},
											}
										],
										order: 'created ASC',
									},
								}
							],
						}
					},
					{
						relation: 'listingBrokers',
						scope: {
							fields: [ 'id', 'listingId', 'brokerId', 'primary' ],
							include: {
								relation: 'broker',
								scope: {
									fields: [ 'id', 'agencyId', 'sbUserId', 'status' ],
									include: [
										user,
										{
											relation: 'agency',
											scope: {
												fields: ['id', 'legalName']
											}
										}
									]
								}
							}
						},
						order: 'primary DESC'
					}
				]
			}

			if(checkForShared) {
				filter.include.push(getSharedRelation(brokerId));
			} else if(sharer) {
				filter.include.push(getSharedRelation());
			}

			return Listing.findById({ id: id, filter: filter }).$promise;
		}

		self.getAddenda = function (id, brokerId, checkForShared = false, sharer = false) {
			const filter = {
				fields: [ 'id' ],
				include: [
					{
						relation: 'property',
						scope: {
							fields: [ 'id', 'listingId' ],
							include: {
								relation: 'characteristics',
								scope: {
									fields: [ 'id', 'propertyId', 'addenda', 'languageId' ]
								}
							}
						}
					},
					{
						relation: 'listingBrokers',
						scope: {
							fields: [ 'id', 'listingId', 'brokerId', 'primary' ],
							include: {
								relation: 'broker',
								scope: {
									fields: [ 'id', 'agencyId', 'sbUserId', 'status' ],
									include: [
										user,
										{
											relation: 'agency',
											scope: {
												fields: ['legalName']
											}
										}
									]
								}
							}
						},
						order: 'primary DESC'
					}
				]
			}

			if(checkForShared) {
				filter.include.push(getSharedRelation(brokerId));
			} else if(sharer) {
				filter.include.push(getSharedRelation());
			}

			return Listing.findById({ id: id, filter: filter }).$promise;
		}

		self.getFeatures = function (id, brokerId, checkForShared = false, sharer = false) {
			const filter = {
				fields: [ 'id' ],
				include:
				[
					{
						relation: 'property',
						scope: {
							fields: ['id', 'num', 'description', 'propertyId', 'characteristicTypeId', 'valueId'],
							include: {
								relation: 'features',
								scope: {
									fields: ['id', 'num', 'description', 'propertyId', 'characteristicTypeId', 'valueId'],
									include: [
										{
											relation: 'type',
											scope: {
												fields: ['id', 'type', 'labels', 'centris', 'realtor', 'characteristicTypeId'],
											},
										},
										{
											relation: 'value',
											scope: {
												fields: ['id', 'type', 'labels', 'centris', 'realtor', 'valueId'],
											}
										}
									],
								}
							}
						}
					},
					{
						relation: 'listingBrokers',
						scope: {
							fields: [ 'id', 'listingId', 'brokerId', 'primary' ],
							include: {
								relation: 'broker',
								scope: {
									fields: [ 'id', 'agencyId', 'sbUserId', 'status' ],
									include: [
										user,
										{
											relation: 'agency',
											scope: {
												fields: ['id', 'legalName']
											}
										}
									]
								}
							}
						},
						order: 'primary DESC'
					}
				]
			}

			if(checkForShared) {
				filter.include.push(getSharedRelation(brokerId));
			} else if(sharer) {
				filter.include.push(getSharedRelation());
			}

			return Listing.findById({ id: id, filter: filter }).$promise;
		}

		self.getRooms = function (id, brokerId, checkForShared = false, sharer = false) {
			const filter = {
				fields: [ 'id' ],
				include: [
					{
						relation: 'property',
						scope: {
							fields: [ 'id', 'listingId' ],
							include: {
								relation: 'rooms',
								scope: {
									fields: ['id', 'propertyId', 'floorCoverId', 'floorId', 'typeId', 'irregular', 'dimensions', 'extraInfo', 'order'],
									include: [
										{
											relation: 'floorCover',
											scope: {
												fields: ['id', 'type', 'labels', 'centris', 'realtor'],
											}
										},
										{
											relation: 'floor',
											scope: {
												fields: ['id', 'type', 'labels', 'centris', 'realtor'],
											}
										},
										{
											relation: 'type',
											scope: {
												fields: ['id', 'type', 'labels', 'centris', 'realtor'],
											}
										}
									],
									order: 'order ASC'
								}
							},
						}
					},
					{
						relation: 'listingBrokers',
						scope: {
							fields: [ 'id', 'listingId', 'brokerId', 'primary' ],
							include: {
								relation: 'broker',
								scope: {
									fields: [ 'id', 'agencyId', 'sbUserId', 'status' ],
									include: [
										user,
										{
											relation: 'agency',
											scope: {
												fields: ['legalName']
											}
										}
									]
								}
							}
						},
						order: 'primary DESC'
					}
				]
			}

			if(checkForShared) {
				filter.include.push(getSharedRelation(brokerId));
			} else if(sharer) {
				filter.include.push(getSharedRelation());
			}

			return Listing.findById({ id: id, filter: filter }).$promise;
		}

		self.getSharedRecipients = function(id) {
			const filter = {
				fields: [ 'id' ],
				include: {
					relation: 'sharedBrokers',
					scope: {
						fields: [ 'id' ]
					}
				}
			};

			return Listing.findById({ id: id, filter: filter }).$promise;
		}

		self.getDescription = function (id, brokerId, checkForShared = false, sharer = false) {
			const filter = {
				include: [
					{
						relation: 'buyers',
						scope: {
							fields: [ 'id', 'sbUserId' ],
							include: user
						}
					},
					{
						relation: 'sellers',
						scope: {
							fields: [ 'id', 'sbUserId' ],
							include: user
						}
					},
					{
						relation: 'suppliers',
						scope: {
							fields: [ 'id', 'ownerId', 'photoUrl' ],
							include: [
								{
									relation: 'owner',
									scope: {
										fields: [ 'id', 'fullName', 'email', 'phones', 'emailEnabled', '_address', 'photoUrl', 'ownerId' ]
									}
								},
								{
									relation: 'listingSuppliers',
									scope: {
										fields: ['id', 'assets', 'seq', 'supplierId'],
										where: {
											listingId: id
										}
									}
								}
							]
						}
					},
					{
						relation: 'notaries',
						scope: {
							fields: [ 'id', 'sbUserId' ],
							include: user
						}
					},
					{
						relation: 'property',
						scope: {
							include: [
								'characteristics',
								'styles',
								'type',
								'codes',
								'buildingType'
							]
						}
					},
					{
						relation: 'mainPhoto',
						scope: {
							fields: [ 'id', 'url', 'name', 'type' ]
						}
					},
					{
						relation: 'listingBrokers',
						scope: {
							fields: [ 'id', 'listingId', 'brokerId', 'primary' ],
							include: {
								relation: 'broker',
								scope: {
									fields: [ 'id', 'agencyId', 'sbUserId', 'status' ],
									include: [
										user,
										{
											relation: 'agency',
											scope: {
												fields: ['legalName']
											}
										}
									]
								}
							}
						},
						order: 'primary DESC'
					}
				]
			}

			if(checkForShared) {
				filter.include.push(getSharedRelation(brokerId));
			} else if(sharer) {
				filter.include.push(getSharedRelation());
			}

			return Listing.findById({ id: id, filter: filter }).$promise;
		}

		self.getSellerAccess = function(listingId, sellerId) {
			return Listing.getSellerPassword({ id: listingId, sellerId: sellerId }).$promise;
		}

		self.emailSellerPassword = function(listingId, sellerId) {
			return Listing.emailSellerPassword({ id: listingId, sellerId: sellerId }).$promise;
		}

		self.getSellers = function (id) {
			const filter = {
				fields: [ 'id', 'sbUserId' ],
				include: user
			}
			return Listing.sellers({ id: id, filter: filter }).$promise;
		}

		self.getBuyers = function (id) {
			const filter = {
				fields: [ 'id', 'sbUserId' ],
				include: user
			}
			return Listing.buyers({ id: id, filter: filter }).$promise;
		}

		self.getNotaries = function (id) {
			const filter = {
				fields: [ 'id', 'sbUserId' ],
				include: user
			}
			return Listing.notaries({ id: id, filter: filter }).$promise;
		}

		self.getListingSummary = function(id) {
			return Listing.getSummary({ id: id }).$promise;
		}

		self.getSuppliers = function(id) {
			const filter = {
				include: {
					supplier: [
						{
							relation: 'industry',
							scope: {
								fields: [
									'supplierId',
									'id',
									'type',
									'labels',
									'centris',
									'realtor'
								]
							}
						},
						{
							relation: 'owner',
							scope: {
								fields: [
									'id',
									'fullName',
									'email',
									'phones',
									'emailEnabled',
									'_address',
									'photoUrl',
									'ownerId'
								]
							}
						}
					]
				}
			}

			const myId = AccountService.getUserId();
			let manager = false;
			if (AccountService.getRole(true) === 'managerProfile') {
				manager = AccountService.getManaging() || {};
			}

			return Listing.listingSuppliers({ id: id, filter: filter }).$promise
			.then((res) => {
				return res.reduce((arr, curr) => {
					if (curr.supplier) {
						if (curr.createdBy === myId) {
							curr.supplier.isMine = !manager || (manager || {}).allClients || ((manager || {}).clientIds || []).indexOf((curr.supplier || {}).ownerId) >= 0;
						}
						else {
							curr.supplier.owner.emailEnabled = false;
						}

						arr.push(curr.supplier);
					}
					return arr;
				}, [])
			})
		}

		self.exportSuppliersPdf = function(id) {
			const baseUrl = $rootScope.baseURL;
			const params = {
				access_token: AccountService.getAccessToken(),
				lang: $rootScope.language || 'EN'
			};

			const apiURL = baseUrl + '/Listings/' + id + '/exportSuppliers';
			const url = [ apiURL, $.param(params) ].join('?');
			$window.open(url);
		}

		self.searchListing = function(search) {
			search = (search || '').toUpperCase();
			const filter = {
				where: {
					_id: {
						regexp: '.*' + search + '.*'
					},
					status: {
						inq: [ 'Active', 'Sold' ]
					},
					visible: true
				},
				include: {
					relation: 'property',
					scope: {
						fields: [ 'id', 'listingId', '_address' ]
					}
				},
				fields: [ 'id', 'status', 'modified', 'visible' ],
				limit: 5,
				order: 'modified DESC'
			}

			if (AccountService.isLikeBroker()) {
				return Broker.listings({ id: AccountService.getBrokerId(), filter: filter }).$promise;
			}
			else {
				return Agency.getListings({ id: AccountService.getAgencyId(), filter: filter, brokerId: null }).$promise;
			}
		}

		self.shareListing = function(fk, recipients, externalRecipients) {
			return Broker.shareListing({ id: AccountService.getBrokerId(), fk: fk }, { recipients: recipients, externalRecipients: externalRecipients }).$promise;
		}

		self.shareListings = function(listingIds, recipient) {
			return Broker.shareListings({ id: AccountService.getBrokerId() }, { recipient: recipient, listingIds: listingIds } ).$promise;
		}

		self.downloadFolder = function(listingId, id) {
			const baseUrl = $rootScope.baseURL;
			const params = {
				access_token: AccountService.getAccessToken()
			};
			const apiURL = baseUrl + '/Listings/' + listingId + '/documentMetadata/' + id  + '/download';
			const url = [apiURL, $.param(params)].join('?');
			$window.open(url);
		}

		self.exportFolder = function(listingId, id, ids) {
			const baseUrl = $rootScope.baseURL;
			const params = {
				access_token: AccountService.getAccessToken(),
				ids: ids
			};
			const apiURL = baseUrl + '/Listings/' + listingId + '/documentMetadata/' + id + '/export';
			const url = [apiURL, $.param(params)].join('?');
			$window.open(url);
		}

		self.duplicateListing = function(id) {
			return Listing.duplicateToSmartlisting({ id: id }).$promise;
		}

		self.getShared = function(pageNum, sortBy) {
			const where = {
		        status: 'Active'
			};
			const skip = pageNum * 10;
		    const filter = {
		        include: [
		            {
		                relation: 'property',
		                scope: {
		                    fields: [ 'id', '_address', 'typeId', 'listingId', 'numBedrooms', 'numBathrooms', 'buildingLivingArea' ],
		                    include: ['type', 'codes']
		                }
		            },
		            {
		                relation: 'sellers',
		                scope: {
		                    fields: [ 'id', 'sbUserId' ],
		                    include: user
		                }
		            },
		            {
		                relation: 'mainPhoto',
		                scope: {
		                    fields: [ 'id', 'listingId', 'url', 'type', 'name' ]
		                }
		            },
		            {
		              relation: 'clicks',
		              scope: {
		                fields: [ 'total' ]
		              }
					},
					{
						relation: 'shared',
						scope: {
							where: {
								brokerId: AccountService.getBrokerId()
							}
						}
					}
		        ],
		        where: where,
		        order: 'id ASC',
		    };
			filter.include.splice(3, 1);
			if (sortBy) {
				filter.order = sortBy
				filter.limit = 8;
			}
			return Broker.getSharedListings({ id: AccountService.getBrokerId(), filter: filter }).$promise;
		}
	})
})(); // End of function()
