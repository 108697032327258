/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('MessageModalController', function (message, receiver, $filter, $log, $scope, $timeout, $translate, $uibModalInstance, AccountService, AgencyService, AlertService, GlobalVars, MessageService, UploadService, UserService) {
		const self = this;

		// VARIABLES ============================================================================================================================

		self.newFiles     = [];     // Message files
		self.users        = [];     // All options for recipients

		self.receivers    = [];     // Selected receivers for message
		self.message      = null;   // Message object that will be saved

		self.uploader     = null;   // Uploader object
		self.config       = null;   // Config for selectize dropdown

		self.offices = [];
		self.selectedOffice = null;
		self.singleConfig = GlobalVars.createSingleConfig(false, 'id', 'legalName', 'legalName');

		let userId        = null;   // Current user's id - used to get senderId

		// SETUP VARIABLES ======================================================================================================================

		userId        = AccountService.getUserId();


		const brokerId = AccountService.getBrokerId();
		self.uploader = angular.copy(UploadService.initMessageUploader());
		self.config   = GlobalVars.createMultiConfig(false, 'id', 'fullName', true, 'hidden', 'search');

		self.message = {
			text: message || '',
			read: false,
			receiver: null,
			files: [],
		}

		self.getOptionLabel = function(office) {
			const count = office.brokers.length + office.members.length;
			const label = $filter('translate')('MESSAGES.SELECT_ALL') + ' ' + office.legalName + ' (' + count + ')';

			const noUsersLabel = $filter('translate')('MESSAGES.NO_USERS');
			if(count === 0) return office.legalName + ' (' + noUsersLabel + ')';
			return label;
		}

		if (receiver) {
			if (Array.isArray(receiver)) {
				self.receivers = [].concat(receiver);
			}
			else if (receiver.id) {
				self.receivers.push(receiver.id);
			}
			else if (typeof receiver === 'string') {
				self.receivers.push(receiver);
			}
		}

		$uibModalInstance.scope = $scope;
		$uibModalInstance.check = {
			original: {
				message: angular.copy(self.message),
				receivers: angular.copy(self.receivers)
			},
			clone: {
				message: self.message,
				receivers: self.receivers
			}
		}

		setupUploader();
		getUsers();
		getOffices();

		// FUNCTIONS ============================================================================================================================

		/**
     *  Closes the modal window
     */
		self.close = function () {
			$uibModalInstance.dismiss('cancel');
		}

		/**
     *  Deletes a file from a message (before sending it)
     *  @param  {object}    item    File item from the upload queue
     *  @param  {int}       i       Index of the file to be deleted
     */
		self.removeFile = function (item, i) {
			if (item) {
				item.remove();
				self.newFiles.splice(i, 1);
			}
		}


		self.selectAll = function(ev) {
			if(ev) {
				if(ev.preventDefault) ev.preventDefault();
				if(ev.stopPropagation) ev.stopPropagation();
			}
			if(self.selectedOffice) {
				const selectedOffice = self.offices.find((office) => {
					if(office.id === self.selectedOffice) return office;
				})
				if(selectedOffice) {
					for(let i = 0; i < selectedOffice.brokers.length; i++) {
						if(!self.receivers.includes(selectedOffice.brokers[i].user.id) && selectedOffice.brokers[i].status === 'SB_USER') {
							self.receivers.push(selectedOffice.brokers[i].user.id);
						}
					}
					for(let i = 0; i < selectedOffice.members.length; i++) {
						if(!self.receivers.includes(selectedOffice.members[i].user.id)) {
							self.receivers.push(selectedOffice.members[i].user.id);
						}
					}
				}
			} else {
				for(let i = 0; i < self.users.length; i++) {
					if(!self.receivers.includes(self.users[i].id)) {
						self.receivers.push(self.users[i].id);
					}
				}
			}

			self.receivers = JSON.parse(JSON.stringify(self.receivers));
		}

		self.deselectAll = function(ev) {
			if(ev) {
				if(ev.preventDefault) ev.preventDefault();
				if(ev.stopPropagation) ev.stopPropagation();
			}
			if(self.selectedOffice) {
				const selectedOffice = self.offices.find((office) => {
					if(office.id === self.selectedOffice) return office;
				});
				if(selectedOffice) {
					for(let i = 0; i < selectedOffice.brokers.length; i++) {
						if(self.receivers.indexOf(selectedOffice.brokers[i].user.id) > -1) {
							const position = self.receivers.indexOf(selectedOffice.brokers[i].user.id);
							self.receivers.splice(position, 1);
						}
					}
					for(let i = 0; i < selectedOffice.members.length; i++) {
						if(self.receivers.indexOf(selectedOffice.members[i].user.id) > -1) {
							const position = self.receivers.indexOf(selectedOffice.members[i].user.id);
							self.receivers.splice(position, 1);
						}
					}
				}
			} else {
				self.receivers = [];
			}
			self.receivers = JSON.parse(JSON.stringify(self.receivers));
		}

		/**
     *  Creates a new conversation with each recipient selected
     *  and adds all files to that conversation (if required)
     */
		self.send = function () {
			const message = angular.copy(self.message);
			AlertService.loading();

			MessageService.newConversation(message, self.receivers).then((res) => {
				let count = res.length;
				res.forEach((msg) => {
					const newUploader = UploadService.initMessageUploader();
					newUploader.url = getUrl(msg.id);
					newUploader.removeAfterUpload = false;
					newUploader.onSuccessItem = function (fileItem, response, status, headers) {};

					if (self.uploader.queue.length > 0) {
						for (const i in self.uploader.queue) {
							newUploader.addToQueue(self.uploader.queue[i]._file, { url: getUrl(msg.id) });
						}

						newUploader.onCompleteAll = function() {
							count --;
							if (count === 0) done();
						}

						newUploader.uploadAll();
					}
					else {
						count --;
					}
				})

				if (count === 0) done();
			})
			.catch($log.error)
		}

		/**
     *  Callback function for uploader.onWhenAddingFileFailed
     *  Shows a message to the user explaining why the file was not added to the message.
     *  It assumes that it will only fail due to the file format not being allowed.
     *  @param {object}  item  Item that could not be added to the uploader
     */
		function doError(item) {
			$translate('ALERT_MESSAGES.ALERT.FILE_FORMAT', { format: item.name.substring(item.name.lastIndexOf('.')) }).then((err) => {
				self.error = err;
				self.showError = true;

				$timeout(() => {
					self.showError = false;
				}, 5000);
			})
		}

		/**
     *  Called after the message is sent and all files have been uploaded.
     *  Closes the modal.
     */
		function done() {
			AlertService.doneLoading();
			AlertService.success('MESSAGES.MESSAGE', 'send');
			self.newMessageForm.$setPristine();
			$uibModalInstance.close({ message: self.message });
		}

		/**
     *  Generates the proper URL for the message uploader.
     *  @param    {string=}   messageId   ID of the message to which a file will be uploaded. If not provided, will use self.message.id
     *  @return   {string}                URL for uploading a file to that message
     */
		function getUrl(messageId) {
			return UploadService.initMessageUrl(messageId || self.message.id);
		}

		/**
     *  Gets users from the DB to be used a message receivers.
     */
		function getUsers() {
			$scope.loading = true;

			UserService.getAll(true).then((res) => {
				self.users = res.people.reduce((arr,item) => {
					if (item.id !== userId) {
						item.hidden = $filter('translate')(item.dataType);
						item.search = item.fullName + '|' + item.hidden;
						arr.push(item);
					}
					return arr;
				},[]);

			})
			.catch($log.error)
			.finally(() => {
				$scope.loading = false;
			})
		}


		function getOffices() {
			$scope.loading = true;

			AgencyService.getAgency(true, true, brokerId).then((res) => {
				self.offices = res.offices;
			});
		}

		/**
     *  Sets up the file uploader.
     *  Adds callback for onAfterAddingFile and onWhenAddingFileFailed
     */
		function setupUploader() {
			self.uploader.removeAfterUpload = false;

			self.uploader.onCompleteAll = function () {}
			self.uploader.onSuccessItem = function (fileItem, response, status, headers) {};
			self.uploader.onAfterAddingFile = function (item) {
				self.newFiles.push(item);
			};

			self.uploader.onWhenAddingFileFailed = function (item, filter, options) {
				doError(item);
			};
		}

	}) // End of controller
})(); // End of function()
