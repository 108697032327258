/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.importCsv', [])

	.service('ImportCsvService', function ($filter, $http, $rootScope, $q, $sce, $timeout, $translate, $window, AlertService, BuyerService, FileService, GlobalVars, LocationService, ModalService, SellerService, SupplierService, UploadService, UserService) {
		const self = this;

		// VARIABLES ============================================================================================================================

		let uploader;						// Base uploader object
		self.buyerUploader		= null;		// Uploader for buyer file
		self.sellerUploader		= null;		// Uploader for seller file
		self.supplierUploader	= null;		// Uploader for supplier file

		self.buyerFile			= null;		// .csv file with buyer data
		self.sellerFile			= null;		// .csv file with seller data
		self.supplierFile		= null;		// .csv file with supplier data

		self.report				= null;		// Report showing to user what was imported

		let buyerSchema;					// Schema for buyer data
		let sellerSchema;					// Schema for seller data
		let supplierSchema;					// Schema for supplier data

		let convertToArray;					// Array of properties that should be listed as arrays

		let countries;						// Country/Province/Region/City data from DB
		const codes = {
			suppIndustryTypes: 		[],			// Array of codes for industry id
			propertyTypes: 		[],			// Array of codes for property types
			languageOptions: 	[]			// Array of codes for languages
		};

		const usedCities 			= {};

		let EMAIL, BOOLEAN, BOOL_FALSE, BOOL_TRUE, NUMBER;		// Regular expressions to check column values

		// Underscore library
		const _ = $window._;

		// SETUP VARIABLES ======================================================================================================================

		// Doesn't start with ., only some special characters allowed, one @, at least one dot in the domain part
		EMAIL = new RegExp(/^[^\.]{1}([A-z]|\d|!|#|\$|%|&|'|\*|\+|-|\/|=|\?|\^|_|`|{|\||}|~|\.)+@{1}(\d|[A-z]){1}((\d|[A-z]|-)+\.{1})+(\d|[A-z]|-)+$/, 'gi');

		// True, false, yes, no, oui or non
		BOOL_FALSE = '(false|f|n|non|0|no)';
		BOOL_TRUE = '(true|t|o|oui|1|yes|y)';
		BOOLEAN = new RegExp('^' + BOOL_FALSE + '|' + BOOL_TRUE + '{1}$', 'gi');

		// Start and end with digits, may contain special characters '.', ',' or ' '
		NUMBER = new RegExp(/^(\d+((\.|,| )*\d+)*)+$/, 'gi');

		convertToArray = [
			'languageIds',
			'propertyTypeIds',
			'cityIds'
		];

		const labels = {
			salutation: 'PERSON.SALUTATION',
			firstName: 'PERSON.FIRST_NAME',
			lastName: 'PERSON.LAST_NAME',
			email: 'EMAIL.EMAIL',
			'phones.home': 'PERSON.PHONE_TYPES.HOME',
			'phones.work': 'PERSON.PHONE_TYPES.WORK',
			'phones.mobile': 'PERSON.PHONE_TYPES.MOBILE',
			'phones.fax': 'PERSON.PHONE_TYPES.FAX',
			languageIds: 'PERSON.LANGUAGE',
			'_address.addressString': 'ADDRESS.ADDRESS',
			'_address.cityId': 'ADDRESS.CITY',
			'_address.provinceId': 'ADDRESS.PROVINCE',
			'_address.countryId': 'ADDRESS.COUNTRY',
			'_address.postalCode': 'ADDRESS.POSTAL_CODE',
			preApproved: 'PERSON.BUYER.PRE_APPROVED',
			seekRental: 'PERSON.BUYER.SEEK_RENTAL',
			minPrice: 'COMMON.MIN',
			maxPrice: 'COMMON.MAX',
			propertyTypeIds: 'PROPERTY.PROPERTY_TYPE',
			cityIds: 'PERSON.BUYER.PREFERRED_CITIES',
			company: 'PERSON.SUPPLIER.COMPANY',
			industryId: 'PERSON.SUPPLIER.INDUSTRY',
			rate: 'PERSON.SUPPLIER.RATE',
			info: 'COMMON.INFO',
			photoUrl: 'PERSON.PHOTO_URL'
		}

		// Get all countries from the DB and create a regexp for each country/province/city name
		LocationService.getCountries().then((res) => {
			countries = res.reduce((arr, country) => {
				country.reg = _getCountryReg(country);
				country.provinces = country.provinces.reduce((arr2, province) => {
					province.reg = _getProvinceReg(province);
					province.cities = province.regions.reduce((arr3, region) => {
						const cities = region.cities.reduce((arr4, city) => {
							city.provinceId = province.id;
							city.countryId = country.id;
							arr4.push(city);
							return arr4;
						}, []);

						return arr3.concat(cities);
					}, []);

					arr2.push(province);
					return arr2;
				}, []);

				arr.push(country);
				return arr;
			}, []);
		})

		_setCodes('languageOptions', 0);
		_setCodes('propertyTypes', 0);
		_setCodes('suppIndustryTypes', 0);

		_setVars();

		// FUNCTIONS ============================================================================================================================

		self.next = function() {
			AlertService.loading();
			AlertService.loadingMessage('MIGRATE.LOADING.READING', true);
			$timeout(_processData, 500);

			function _processData() {
				let data = [];

				self.report.columns.forEach((curr) => {
					if (curr.selected && self.report.columnOptions[curr.selected]) {
						const col = self.report.columnOptions[curr.selected];

						curr.data.forEach((entry, i) => {
							if (!data[i]) {
								data[i] = {
									main: {},
									sub: {}
								};
							}

							const created = _create(entry, col, self.report.required, data[i].main, data[i].sub);
							data[i].main = created.main;
							data[i].sub = created.sub;
						})
					}
				})

				data.forEach((entry) => {
					if (entry.sub._address) {
						entry.sub._address = _getAddress(entry.sub._address);
					}

					// Get industry code for supplier
					if (!!entry.main.industryId) {
						entry.main.industryId = _findCode(entry.main.industryId,codes.suppIndustryTypes,'SUP_OTHER');
					}

					// Get property types for buyer
					if (!!entry.main.propertyTypeIds) {
						for (let i = 0; i < entry.main.propertyTypeIds.length; i++) {
							entry.main.propertyTypeIds[i] = _findCode(entry.main.propertyTypeIds[i],codes.propertyTypes);
						}
					}

					// Get city codes for buyer
					if (!!entry.main.cityIds) {
						for (let i = 0; i < entry.main.cityIds.length; i++) {
							entry.main.cityIds[i] = _getCityCode(null,null,entry.main.cityIds[i]);
						}
					}

					if (!!entry.sub.languageIds) {
						for (let i = 0; i < entry.sub.languageIds.length; i++) {
							entry.sub.languageIds[i] = _findCode(entry.sub.languageIds[i], codes.languageOptions);
						}
					}
				})

				data = data.filter(entry => !_isEmpty(entry));
				_checkDuplicates(data);
			}
		}

		self.reset = function() {
			_setVars();
		}

		function _checkDuplicates(data) {
			AlertService.loadingMessage('MIGRATE.LOADING.DUPLICATES', true);

			const args = data.reduce((arr, row) => {
				const sub = row.sub || {};
				if (!!sub.firstName || !!sub.lastName) {
					arr.push((sub.firstName || 'N/A') + ' ' + (sub.lastName || 'N/A'));
				}
				return arr;
			}, []);

			UserService.checkManyDuplicates(args).then((res) => {
				res.forEach((item, i) => {
					if (!item.duplicates) {
						_doAccept(data[i]);
					}
					else {
						self.report.duplicates.push({
							old: item.duplicates,
							neu: data[i],
							i: i
						});
					}
				})

				AlertService.doneLoading();

				if (self.report.duplicates.length > 0) {
					_detected();
				}
				else {
					_finish();
				}
			})
			.catch((err) => {
				AlertService.doneLoading();
			})
		}

		function _create(entry, col, required, main, sub) {

			entry = entry.replace(/\"/g,''); // Remove any remaining '"' characters
			entry = entry.replace(/�/g,'è');	// Fix issue with 'è' becoming �

			const fieldName = col.name;

			if (!!required[fieldName] && (entry == null || entry == undefined || entry == '')) {
				entry = required[fieldName].default;
			}

			if (col.reg) {
				if (col.reg == BOOLEAN) {
					entry = entry.replace(new RegExp(BOOL_FALSE, 'gi'), 'false');
					entry = entry.replace(new RegExp(BOOL_TRUE, 'gi'), 'true');
					entry = (entry === 'true' ? true : false);
				}
				else if (!col.reg.test(entry)) {
					entry = (col.reg == NUMBER ? 0 : null);
				}
			}

			// Transform required properties into array
			if (convertToArray.indexOf(fieldName) >= 0) {
				entry = (entry || '').split(',');
			}

			if (entry == '') {
				entry = null;
			}

			if (!col.sub) {
				main = _doObj(main, fieldName, entry);
			}
			else {
				sub = _doObj(sub, fieldName, entry);
			}

			return { main: main, sub: sub };
		}

		/**
		 *	Creates nested properties as necessary (used with _address and phones)
		 *	@param object: Object (object that will have properties added)
		 *	@param columns: Array<String> (nested properties to be created - outer to inner)
		 *	@param final: * (value that will be added to last nested property)
		 */
		function _createNested(object,columns,final) {

			// There are more nested properties to create -> call function again
			if (columns.length > 1) {
				var prop = columns.shift();

				if (!object[prop]) {
					object[prop] = {};
				}
				object[prop] = _createNested(object[prop],columns,final);
				return object;
			}
			// Last nested property -> populate with final value
			else if (columns.length == 1) {
				var prop = columns.shift();
				object[prop] = final;
				return object;
			}
			// Shouldn't happen
			else {
				return object;
			}
		}

		function _doObj(obj, fieldName, entry) {
			obj = obj || {};
			if (fieldName.includes('.')) {
				const subcolumns = fieldName.split('.');
				obj = _createNested(obj, subcolumns, entry);
			}
			else {
				obj[fieldName] = entry;
			}

			return obj;
		}

		/**
		 *  Searches for the right code id according to data value
		 *  @param original: String (original value as it came in the .csv file)
		 *  @param codes: Array<String> (array of codes to search in)
		 *  @param deflt: String (default value in case no code is found - usually 'OTHER')
		 */
		function _findCode(original,codes,deflt) {
			original = (original || '').toLowerCase();

			if (original != '') {

				let i, length = codes.length;

				for (i = 0; i < length; i++) {

					for (const lang in codes[i].labels) {
						if (codes[i].labels[lang].toLowerCase().includes(original) ||
							original.includes(codes[i].labels[lang].toLowerCase())) {
							return codes[i].value;
						}
					}
				}
			}

			return deflt || original;
		}

		function _finish() {
			AlertService.loading();
			AlertService.loadingMessage('MIGRATE.LOADING.SAVING', true);

			let tasks = self.report.added.reduce(_reduce, []);
			tasks = self.report.merged.reduce(_reduce, tasks);

			$q.all(tasks).then((res) => {
				AlertService.doneLoading();
				AlertService.successMessage('MIGRATE.SUCCESS');

				self.buyerFile = null;
				self.sellerFile = null;
				self.supplierFile = null;

				_stats();
			})

			function _reduce(arr, entry) {
				arr.push(self.report.insert(entry));
				return arr;
			}
		}

		function _stats() {
			const controller = function($uibModalInstance, $scope) {
				$scope.report = self.report;
				$scope.ok = function() {
					$uibModalInstance.dismiss('cancel');
				}
			}

			const m = ModalService.openModal('./js/src/templates/modal/migrate/report-finish.html',
				{}, controller, 'controller');

			m.result.finally(() => {
				$timeout(() => {
					$rootScope.$emit('finish-report');
				}, 150);
			});
		}

		/**
		 *	Gets proper data for city,province and country in an _address object
		 *	@param address: Object (_address)
		 */
		function _getAddress(address) {

			if (!!address) {

				let countryIndex = null;

				if (!!address.countryId && address.countryId != '') {

					const country = address.countryId.trim();

					const i = countries.findIndex((item) => {
						if (!item.reg) {
							item.reg = _getCountryReg(item);
						}
						return item.reg.test(country);
					})

					if (i >= 0) {
						countryIndex = i;
						address.countryId = countries[i].id;
					}
				}

				if (!!address.provinceId && address.provinceId != '') {
					const province = address.provinceId.trim();
					let provinces = [];

					if (countryIndex !== null) {
						provinces = countries[countryIndex].provinces;
					}
					else {
						provinces = countries.reduce((arr, country) => {
							return arr.concat(country.provinces);
						}, []);
					}

					const j = provinces.findIndex((item) => {
						if (!item.reg) {
							item.reg = _getProvinceReg(item);
						}
						return item.reg.test(province);
					})

					if (j >= 0) {
						address.provinceId = provinces[j].id;

						if (!countryIndex) {
							address.countryId = provinces[j].countryId;
						}
					}
				}

				if (!!address.cityId && address.cityId != '') {
					address.cityId = _getCityCode(address.countryId, address.provinceId, address.cityId);

					if (!address.provinceId && !!usedCities[address.cityId]) {
						address.provinceId = usedCities[address.cityId].provinceId;
					}

					if (!address.countryId && !!usedCities[address.cityId]) {
						address.countryId = usedCities[address.cityId].countryId;
					}
				}
			}

			return address;
		}

		/**
		 * 	Gets proper city codes for buyer
		 * 	@param 	{string}	countryId	Buyer's country -> from _address
		 * 	@param	{string}	original	Original value
		 */
		function _getCityCode(countryId, provinceId, original) {

			original = original.toLowerCase().trim();

			// Knowlton is not listed as a city, it's a subdivision of Lac-Brome
			if (original == 'knowlton') {
				original = 'lac-brome';
			}

			let provinces, regions, cities;
			const reg = _getRegex([original]);

			provinces = _getArr(countryId, countries, 'provinces');
			regions = _getArr(provinceId, provinces, 'regions');
			cities = regions.reduce((arr, region) => {
				return arr.concat(region.cities);
			}, []);

			const i = cities.findIndex((city) => {
				return reg.test(city.name);
			})

			if (i >= 0) {
				usedCities[cities[i].id] = angular.copy(cities[i]);
				return cities[i].id;
			}

			return original;

			function _getArr(id, array, attribute) {
				if (!!id) {
					const i = array.findIndex((entry) => {
						return entry.id === id;
					})

					if (i >= 0) {
						return array[i][attribute];
					}
				}

				return array.reduce((ret, entry) => {
					return ret.concat(entry[attribute]);
				}, []);
			}
		}

		function _getCountryReg(country) {
			country = country || {};
			return _getRegex([ country.id, country.nameEn, country.nameNative, '' + $filter('translate')('COUNTRIES.' + country.id) ]);
		}

		function _getRegex(values) {
			values = values || [];

			let str = '^(';

			values = values.reduce((arr, val) => {
				val = val.replace(/[\- ]/g, '[\\- ]');	// Accept hifens or spaces
				val = val.replace(/(st[\.e]*|saint[e]*)/ig, '(st[\\.e]*|saint[e]*)'); // Accept st, st., saint, ste, ste. or sainte
				val = val.replace(/([aà-ä])/gi, '[aà-ä]');	// Ignore accents
				val = val.replace(/(?!\[.+)([eè-ë])(?!\])/gi, '[eè-ë]'); // Ignore accents (except on ste)
				val = val.replace(/([iì-ï])/gi, '[iì-ï]');	// Ignore accents
				val = val.replace(/([oò-ö])/gi, '[oò-ö]'); 	// Ignore accents
				val = val.replace(/([uù-ü])/gi, '[uù-ü]');	// Ignore accents
				arr.push(val);
				return arr;
			}, []);

			str += values.join('|') +')$';
			return new RegExp(str, 'i');
		}

		function _getRow(split) {

			const len = split.length;

			// If an entry had a comma, the CSV adds a ", but when it was split, this wasn't taken into account
			// So if there's a ", we will concat until the entry is encompassed by ""
			for (let i = 0; i < len; i++) {
				if (split[i] != null) {
					if (split[i].charAt(0) == '\"') {
						let j = 1;
						while(split[i].charAt(split[i].length-1) != '\"' && i+j < split.length) {
							split[i] += ',' + split[i+j];
							split[i+j] = null;
							j += 1;
						}
					}
				}
			}

			// New String array with correct entries and length
			const newSplit = [];
			let k = 0;

			for (let i = 0; i < len; i++) {
				if (split[i] != null) {
					newSplit[k] = split[i];
					k += 1;
				}
			}

			return newSplit;
		}

		function _getProvinceReg(province) {
			province = province || {};
			const vals = [ province.id, province.name ];
			if (province.nameFr)
				vals.push(province.nameFr);

			return _getRegex(vals);
		}

		/**
		 * 	Checks if an item contains any value evaluated to true and different from N/A (or boolean evaluated to false)
		 * 	If the item is an array or an object, it will be called recursively for each item in the object/array
		 * 	If the object/array contains only empty values, the object/array is considered empty
		 *
		 * 	@param 		{*}			item	Item to check
		 * 	@returns	{boolean}			Whether the item is empty
		 */
		function _isEmpty(item) {

			if (typeof item !== 'object') {
				return (!item && typeof item !== 'boolean') || item == 'N/A';
			}

			let isEmpty = true;

			for (const i in item) {
				isEmpty = isEmpty && _isEmpty(item[i]);

				if (!isEmpty) break;
			}
			return isEmpty;
		}

		function _readCsv(schema, file) {

			AlertService.loading();

			$http.get(FileService.getImageUrl(file.url), { method: 'GET', url: FileService.getImageUrl(file.url) })
			.then((response) => {

				let csv = response.data || '';
				csv = csv.substring(csv.indexOf('\n' + 1));

				const edges = csv.match(/"{1}[^"]*"{1}/g);		// Find all cells that are enclosed within "

				// Replace new line characters with a space
				if ((edges || []).length > 0) {
					edges.forEach((edge) => {
						const original = edge || '';
						const replace = edge.replace(/\n/g, ' ');
						csv = csv.replace(original, replace);
					})
				}

				const data = csv.split('\n');
				self.report.badColumns = 0;
				self.report.columns = _getHeaders(data.shift(), schema.columns || []);
				self.report.columnOptions = schema.columns || [];
				self.report.required = schema.required || {};

				let maxLength = schema.columns.length;

				data.forEach((entry, i) => {
					const row = _getRow(entry.split(','));

					if (row.length === 1 && row[0] === '') return;

					if (row.length > maxLength) {
						maxLength = row.length;
					}

					for (let i = 0; i < maxLength; i++) {
						if (self.report.columns[i]) {
							self.report.columns[i].data.push(row[i] || '');
						}
						else {
							const newColumn = {
								selected: '',
								data: Array(self.report.columns[0].data.length - 1).fill('')
							}

							newColumn.data.push(row[i] || '');
							self.report.columns.push(newColumn);
						}
					}
				})

				self.report.step = 2;

			},(response) => {
				console.error('ERROR: ',response);
			})
			.finally(() => {
				// Delete file after being used
				UserService.deleteFile(file)
				.then((res) => {})
				.catch((err) => {
					console.log('error deleting: ', err);
				})
				.finally(() => {
					$rootScope.$emit('update-report');
					AlertService.doneLoading();

					if (self.report.badColumns > 0) {
						const msg = '' + $filter('translate')('MIGRATE.BAD_COLUMNS', { num: self.report.badColumns });
						ModalService.prompt(msg, null, null, 'Ok', null, true);
					}
				})
			})

			function _getHeaders(row, columns) {
				row = _getRow((row || '').split(','));

				const ret = row.reduce((arr, header) => {

					const val = _searchCol(header, arr);

					if (!val && val !== 0 && header.length > 0) {
						self.report.badColumns++;
					}

					arr.push({
						selected: val,
						original: header,
						data: []
					});

					return arr;
				}, []);

				return ret;

				function _searchCol(header, arr) {
					if (!header) return '';

					const reg = new RegExp(header, 'i');
					for (let i = 0; i < columns.length; i++) {
						const translated = '' + $filter('translate')(columns[i].label);
						const colReg = new RegExp(translated, 'i');
						if ((colReg.test(header) || reg.test(translated)) && !_find(arr, columns[i].value)) {
							return columns[i].value;
						}
					}
					return '';
				}

				function _find(arr, val) {
					return arr.find(x => x.selected == val);
				}
			}
		}

		function _setCodes(name, attempts) {
			/* GlobalVars has not finished populating the array yet:
			 * - Try again after 100ms
			 * - Up to 50 attempts */
			if ((GlobalVars[name] || []).length == 0) {
				if (attempts < 50) {
					$timeout(() => {
						_setCodes(name, attempts+1);
					}, 100);
				}
			}

			codes[name] = angular.copy(GlobalVars[name]);
		}

		function _setVars() {
			self.report 		= {};
			self.buyerFile		= {};
			self.sellerFile		= {};
			self.supplierFile	= {};

			buyerSchema = {
				columns: [
					{ name: 'salutation', sub: true },
					{ name: 'firstName', sub: true },
					{ name: 'lastName', sub: true },
					{ name: 'email', sub: true, reg: EMAIL },
					{ name: 'phones.home', sub: true },
					{ name: 'phones.work', sub: true },
					{ name: 'phones.mobile', sub: true },
					{ name: 'phones.fax', sub: true },
					{ name: 'languageIds', sub: true },
					{ name: '_address.addressString', sub: true },
					{ name: '_address.cityId', sub: true },
					{ name: '_address.provinceId', sub: true },
					{ name: '_address.countryId', sub: true },
					{ name: '_address.postalCode', sub: true },
					{ name: 'preApproved', sub: false, reg: BOOLEAN },
					{ name: 'seekRental', sub: false, reg: BOOLEAN },
					{ name: 'minPrice', sub: false, reg: NUMBER },
					{ name: 'maxPrice', sub: false, reg: NUMBER },
					{ name: 'propertyTypeIds', sub: false },
					{ name: 'cityIds', sub: false }
				],
				required: {
					firstName: {
						type: 'string',
						default: 'N/A'
					},
					lastName: {
						type: 'string',
						default: 'N/A'
					},
					minPrice: {
						type: 'number',
						default: '0'
					},
					maxPrice: {
						type: 'number',
						default: '0'
					}
				},
				insert: BuyerService.saveBuyer
			}

			buyerSchema.columns.forEach((col, i) => {
				col.label = labels[col.name];
				col.value = i;
			})

			sellerSchema = {
				columns: [
					{ name: 'salutation', sub: true },
					{ name: 'firstName', sub: true },
					{ name: 'lastName', sub: true },
					{ name: 'email', sub: true, reg: EMAIL },
					{ name: 'phones.home', sub: true },
					{ name: 'phones.work', sub: true },
					{ name: 'phones.mobile', sub: true },
					{ name: 'phones.fax', sub: true },
					{ name: 'languageIds', sub: true },
					{ name: '_address.addressString', sub: true },
					{ name: '_address.cityId', sub: true },
					{ name: '_address.provinceId', sub: true },
					{ name: '_address.countryId', sub: true },
					{ name: '_address.postalCode', sub: true }
				],
				required: {
					firstName: {
						type: 'string',
						default: 'N/A'
					},
					lastName: {
						type: 'string',
						default: 'N/A'
					}
				},
				insert: SellerService.saveSeller
			}

			sellerSchema.columns.forEach((col, i) => {
				col.label = labels[col.name];
				col.value = i;
			})

			supplierSchema = {
				columns: [
					{ name: 'company', sub: false },
					{ name: 'industryId', sub: false },
					{ name: 'rate', sub: false },
					{ name: 'info', sub: false },
					{ name: 'firstName', sub: true },
					{ name: 'lastName', sub: true },
					{ name: 'phones.home', sub: true },
					{ name: 'phones.work', sub: true },
					{ name: 'phones.fax', sub: true },
					{ name: 'phones.mobile', sub: true },
					{ name: 'email', sub: true, reg: EMAIL },
					{ name: 'languageIds', sub: true },
					{ name: '_address.addressString', sub: true },
					{ name: '_address.cityId', sub: true },
					{ name: '_address.provinceId', sub: true },
					{ name: '_address.countryId', sub: true },
					{ name: '_address.postalCode', sub: true },
					{ name: 'photoUrl', sub: false }
				],
				required: {
					firstName: {
						type: 'string',
						default: 'N/A'
					},
					lastName: {
						type: 'string',
						default: 'N/A'
					},
					company: {
						type: 'string',
						default: 'N/A'
					}
				},
				insert: SupplierService.createSupplier
			};

			supplierSchema.columns.forEach((col, i) => {
				col.label = labels[col.name];
				col.value = i;
			})

			uploader = UploadService.initAnotherUserUploader();
			uploader.filters = [{
				name: 'csvFilter',
				fn: function (item /*{File|FileLikeObject}*/, options) {
					const type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
					return '|csv|comma-separated-values|excel|vnd.ms-excel|vnd.msexcel|'.indexOf(type) !== -1 && item.name.endsWith('.csv');
				}
			}];

			self.buyerUploader    = angular.copy(uploader);
			self.sellerUploader   = angular.copy(uploader);
			self.supplierUploader = angular.copy(uploader);

			self.buyerUploader.onSuccessItem = function (fileItem, response, status, headers) {
				self.report = {
					errors: [],
					added: [],
					merged: [],
					rejected: [],
					duplicates: [],
					type: 'buyer',
					insert: buyerSchema.insert
				}

				self.buyerFile = response;
				$rootScope.$emit('update-files');
				_readCsv(buyerSchema, self.buyerFile);
			};

			self.sellerUploader.onSuccessItem = function (fileItem, response, status, headers) {
				self.report = {
					errors: [],
					added: [],
					merged: [],
					rejected: [],
					duplicates: [],
					type: 'seller',
					insert: sellerSchema.insert
				}

				self.sellerFile = response;
				$rootScope.$emit('update-files');
				_readCsv(sellerSchema,self.sellerFile);
			};

			self.supplierUploader.onSuccessItem = function (fileItem, response, status, headers) {
				self.report = {
					errors: [],
					added: [],
					merged: [],
					rejected: [],
					duplicates: [],
					type: 'supplier',
					insert: supplierSchema.insert
				}

				self.supplierFile = response;
				$rootScope.$emit('update-files');
				_readCsv(supplierSchema,self.supplierFile);
			};
		}

		// Functions used during the process to review duplicates
		function _confirmSkip() {
			const m = ModalService.openModal(
				'./js/src/templates/modal/migrate/duplicate-confirm.html',
				{},
				_getModalController(),
				'controller',
				null,
				null,
				null,
				true
			);

			m.result.then((choice) => {
				switch(choice) {
				case 'review':
					_reviewDuplicates(); break;
				case 'skip':
					_finish(); break;
				default:
					self.report.duplicates = [];
				}
			});
		}

		function _detected() {
			const m = ModalService.openModal(
				'./js/src/templates/modal/migrate/duplicate-detected.html',
				{},
				_getModalController(),
				'controller',
				null,
				null,
				null,
				true
			);

			m.result.then((choice) => {
				switch(choice) {
				case 'review':
					_reviewDuplicates(); break;
				case 'skip':
					_confirmSkip(); break;
				default:
					self.report.duplicates = [];
				}
			});
		}

		function _doAccept(entry) {
			const toAdd = entry.main || {};
			toAdd.person = entry.sub;
			self.report.added.push(toAdd);
		}

		function _doMerge(entry) {

			entry = entry || {};
			const neu = (entry.neu || {}).main || {};
			neu.person = (entry.neu || {}).sub || {};
			const old = (entry.old || [])[0] || {};

			let toAdd;

			if (old.type !== ('ROLES.' + self.report.type.toUpperCase())) {
				// We will not use any of the fields from the "main" part of the profile because it's not the same type
				toAdd = angular.copy(neu);
			}
			else {
				toAdd = angular.copy(old);
				delete toAdd.user;
				delete toAdd.type;
			}

			toAdd.person = old.user;

			toAdd = _mergeField(toAdd, neu);
			self.report.merged.push(toAdd);

			function _mergeField(old, neu) {
				if (typeof old !== typeof neu) {
					return neu;
				}

				if (neu === null || neu === undefined) return old;
				if (old === null || old === undefined) return neu;

				if (Array.isArray(old) && Array.isArray(neu)) {
					neu.forEach((item) => {
						const i = old.findIndex((val) => {
							return val === item;
						})

						if (i < 0 && item !== null && item !== undefined) old.push(item);
					})

					return old;
				}

				if (typeof old === 'object' && typeof neu === 'object') {
					for (const i in neu) {
						old[i] = _mergeField(old[i], neu[i]);
					}
					return old;
				}

				return neu;
			}

		}

		function _getModalController() {
			return function($uibModalInstance, $scope) {
				$scope.num = self.report.duplicates.length;

				$scope.close = function(isReview) {
					$uibModalInstance.close(isReview);
				}
			}
		}

		function _reviewDuplicates() {
			const controller = function($uibModalInstance, $scope, $filter) {

				$scope.curr 		= 0;
				$scope.total 		= self.report.duplicates.length;

				$scope.entry 		= self.report.duplicates[$scope.curr].neu;
				$scope.duplicate 	= self.report.duplicates[$scope.curr].old[0];
				$scope.allDone 		= false;
				$scope.isViewMore 	= false;
				$scope.viewLabel 	= 'MIGRATE.VIEW_MORE_INFO';

				$scope.isSeller 	= self.report.type === 'seller';

				const results = [];
				const lang = $rootScope.language || 'EN';
				const yes = $filter('translate')('COMMON.YES');
				const no = $filter('translate')('COMMON.NO');

				$scope.cancel = function() {
					$uibModalInstance.dismiss('cancel');
				}

				let spliced = [];

				const typeFields = {
					buyer: [
						{
							title: 'PERSON.BUYER.PRE_APPROVED',
							getValueSb: function(obj) {
								obj = obj || {};
								return obj.preApproved ? yes : no;
							},
							getValueCsv: function(obj) {
								return (obj.main || {}).preApproved ? yes : no;
							},
							isSame: function(sb, csv) {
								return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
							}
						},
						{
							title: 'PERSON.BUYER.SEEK_RENTAL',
							getValueSb: function(obj) {
								obj = obj || {};
								return obj.seekRental ? yes : no;
							},
							getValueCsv: function(obj) {
								return (obj.main || {}).seekRental ? yes : no;
							},
							isSame: function(sb, csv) {
								return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
							}

						},
						{
							title: 'COMMON.MIN',
							getValueSb: function(obj) {
								obj = obj || {};
								return obj.minPrice || 0;
							},
							getValueCsv: function(obj) {
								return (obj.main || {}).minPrice || 0;
							},
							isSame: function(sb, csv) {
								return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'number');
							}
						},
						{
							title: 'COMMON.MAX',
							getValueSb: function(obj) {
								obj = obj || {};
								return obj.maxPrice || 0;
							},
							getValueCsv: function(obj) {
								return (obj.main || {}).maxPrice || 0;
							},
							isSame: function(sb, csv) {
								return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'number');
							}
						},
						{
							title: 'PROPERTY.PROPERTY_TYPE',
							getValueSb: function(obj) {
								obj = obj || {};
								const ret = (obj.propertyTypes || []).reduce((str, curr) => {
									if (str.length > 0) str += ', ';

									str += ((curr || {}).labels || {})[lang] || '';
									return str;
								}, '');

								return ret || '---';
							},
							getValueCsv: function(obj) {
								obj = obj || {};
								const ret = ((obj.main || {}).propertyTypeIds || []).reduce((str, curr) => {
									const i = codes.propertyTypes.findIndex(x => x.value == curr);
									if (i >= 0) {
										if (str.length > 0) str += ', ';
										str += ((codes.propertyTypes[i] || {}).labels || {})[lang] || '';
									}

									return str;
								}, '');

								return ret || '---';
							},
							isSame: function(sb, csv) {
								return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
							}
						},
						{
							title: 'PERSON.BUYER.PREFERRED_CITIES',
							getValueSb: function(obj) {
								obj = obj || {};
								const ret = (obj.cities || []).reduce((str, curr) => {
									if (str.length > 0) str += ', ';
									str += curr.name;
									return str;
								}, '');

								return ret || '---';
							},
							getValueCsv: function(obj) {
								obj = obj || {};

								const ret = ((obj.main || {}).cityIds || []).reduce((str, curr) => {
									if (str.length > 0) str += ', ';
									str += (usedCities[curr] || {}).name || '';
									return str;
								}, '');

								return ret || '---';
							},
							isSame: function(sb, csv) {
								return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
							}
						}
					],
					supplier: [
						{
							title: 'PERSON.SUPPLIER.COMPANY',
							getValueSb: function(obj) {
								return (obj || {}).company || '---';
							},
							getValueCsv: function(obj) {
								return (obj.main || {}).company || '---';
							},
							isSame: function(sb, csv) {
								return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
							}
						},
						{
							title: 'PERSON.SUPPLIER.INDUSTRY',
							getValueSb: function(obj) {
								return (((obj || {}).industry || {}).labels || {})[lang] || '---';
							},
							getValueCsv: function(obj) {
								obj = obj || {};
								const i = codes.suppIndustryTypes.findIndex(x => x.value == (obj.main || {}).industryId);
								if (i >= 0) {
									return ((codes.suppIndustryTypes[i] || {}).labels || {})[lang] || '---';
								}
								return '---';
							},
							isSame: function(sb, csv) {
								return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
							}
						},
						{
							title: 'PERSON.SUPPLIER.RATE',
							getValueSb: function(obj) {
								return (obj || {}).rate || '---';
							},
							getValueCsv: function(obj) {
								return (obj.main || {}).rate || '---';
							},
							isSame: function(sb, csv) {
								return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
							}
						},
						{
							title: 'COMMON.INFO',
							getValueSb: function(obj) {
								return (obj || {}).info || '---';
							},
							getValueCsv: function(obj) {
								return (obj.main || {}).info || '---';
							},
							isSame: function(sb, csv) {
								return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
							}
						},
					]

				}

				$scope.viewMore = function(isChange) {
					$scope.isViewMore = isChange ? false : !$scope.isViewMore;

					let num = 0;

					if ($scope.isViewMore) {
						num = (typeFields[self.report.type] || []).length || 0;
						spliced = $scope.fields.splice(num * -1, num);
						$scope.fields = $scope.fields.concat(typeFields[self.report.type] || []);
						$scope.viewLabel = 'MIGRATE.VIEW_LESS_INFO';
					}
					else {
						num = spliced.length;
						$scope.fields.splice(num * -1, num);
						$scope.fields = $scope.fields.concat(spliced);
						spliced = [];
						$scope.viewLabel = 'MIGRATE.VIEW_MORE_INFO';
					}
				}

				$scope.select = function(choice) {
					results.push({
						index: $scope.curr,
						choice: choice
					})

					$scope.curr += 1;

					if ($scope.curr >= $scope.total) {
						$scope.fields = [];
						$uibModalInstance.close(results);
					}
					else {
						$scope.entry = self.report.duplicates[$scope.curr].neu;
						$scope.duplicate = self.report.duplicates[$scope.curr].old[0];
						$scope.allDone = false;
						$scope.viewMore(true);
					}
				}

				$scope.fields = [
					{
						title: 'ROLES.ROLE',
						getValueSb: function(obj) {
							if (!obj) obj = {};

							if (obj.type) {
								return $filter('translate')(obj.type);
							}

							return '---';
						},
						getValueCsv: function(obj) {
							return $filter('translate')('ROLES.' + self.report.type.toUpperCase());
						},
						isSame: function(sb, csv) {
							return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
						}
					},
					{
						title: 'PERSON.FIRST_NAME',
						getValueSb: function(obj) {
							obj = obj || {};
							return (obj.user || obj.owner || {}).firstName || '---';
						},
						getValueCsv: function(obj) {
							return ((obj || {}).sub || {}).firstName || '---';
						},
						isSame: function(sb, csv) {
							return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
						}
					},
					{
						title: 'PERSON.LAST_NAME',
						getValueSb: function(obj) {
							obj = obj || {};
							return (obj.user || obj.owner || {}).lastName;
						},
						getValueCsv: function(obj) {
							return ((obj || {}).sub || {}).lastName || '---';
						},
						isSame: function(sb, csv) {
							return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
						}
					},
					{
						title: 'EMAIL.EMAIL',
						getValueSb: function(obj) {
							obj = obj || {};
							return (obj.user || obj.owner || {}).email || '---';
						},
						getValueCsv: function(obj) {
							return ((obj || {}).sub || {}).email || '---';
						},
						isSame: function(sb, csv) {
							return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
						}
					},
					{
						title: 'PERSON.LANGUAGE',
						getValueSb: function(obj) {
							obj = obj || {};
							const langs = (obj.user || obj.owner || {}).languageIds || [];
							if (langs.length === 0) return '---';

							return langs.reduce((str, lang, i) => {
								if (i > 0) str += ' & ';
								str += $filter('translate')('LANGUAGES.' + lang.toUpperCase());
								return str;
							}, '');
						},
						getValueCsv: function(obj) {
							const langs = ((obj || {}).sub || {}).languageIds || [];
							if (langs.length === 0) return '---';

							return langs.reduce((str, lang, i) => {
								if (i > 0) str += ' & ';
								str += $filter('translate')('LANGUAGES.' + lang.toUpperCase());
								return str;
							}, '');
						},
						isSame: function(sb, csv) {
							return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
						}
					},
					{
						title: $filter('translate')('PERSON.PHONES') + ' - ' + $filter('translate')('PERSON.PHONE_TYPES.HOME'),
						getValueSb: function(obj) {
							obj = obj || {};
							return ((obj.user || obj.owner || {}).phones || {}).home || '---';
						},
						getValueCsv: function(obj) {
							return (((obj || {}).sub || {}).phones || {}).home || '---';
						},
						isSame: function(sb, csv) {
							return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
						}
					},
					{
						title: $filter('translate')('PERSON.PHONES') + ' - ' + $filter('translate')('PERSON.PHONE_TYPES.WORK'),
						getValueSb: function(obj) {
							obj = obj || {};
							return ((obj.user || obj.owner || {}).phones || {}).work || '---';
						},
						getValueCsv: function(obj) {
							return (((obj || {}).sub || {}).phones || {}).work || '---';
						},
						isSame: function(sb, csv) {
							return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
						}
					},
					{
						title: $filter('translate')('PERSON.PHONES') + ' - ' + $filter('translate')('PERSON.PHONE_TYPES.FAX'),
						getValueSb: function(obj) {
							obj = obj || {};
							return ((obj.user || obj.owner || {}).phones || {}).fax || '---';
						},
						getValueCsv: function(obj) {
							return (((obj || {}).sub || {}).phones || {}).fax || '---';
						},
						isSame: function(sb, csv) {
							return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
						}
					},
					{
						title: $filter('translate')('PERSON.PHONES') + ' - ' + $filter('translate')('PERSON.PHONE_TYPES.MOBILE'),
						getValueSb: function(obj) {
							obj = obj || {};
							return ((obj.user || obj.owner || {}).phones || {}).mobile || '---';
						},
						getValueCsv: function(obj) {
							return (((obj || {}).sub || {}).phones || {}).mobile || '---';
						},
						isSame: function(sb, csv) {
							return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
						}
					},
					{
						title: 'ADDRESS.ADDRESS',
						getValueSb: function(obj) {
							obj = obj || {};
							const address = (obj.user || obj.owner || {})._address || {};
							let ret = (address.addressString || '---') + '<br>';

							if (address.postalCode)
								ret += address.postalCode + '<br>';

							if (address.city) {
								ret += address.city.name;
								if (address.province || address.country) ret += ', ';
							}

							if (address.province) {
								ret += address.province.id;

								if (address.country) ret += ', ';
							}

							if (address.country) {
								ret += address.country.name;
							}

							return $sce.trustAsHtml(ret);
						},
						getValueCsv: function(obj) {
							const address = ((obj || {}).sub || {})._address || {};
							let ret = (address.addressString || '---') + '<br>';

							if (address.postalCode)
								ret += address.postalCode + '<br>';

							if (address.cityId && usedCities[address.cityId]) {
								ret += usedCities[address.cityId].name;

								if (address.provinceId || address.countryId) ret += ', ';
							}

							if (address.provinceId) {
								ret += address.provinceId;

								if (address.countryId) ret += ', ';
							}

							if (address.countryId) {
								ret += address.countryId === 'USA' ? 'United States' : 'Canada';
							}

							return $sce.trustAsHtml(ret);
						},
						isSame: function(sb, csv) {
							return _isSame(this.getValueSb(sb), this.getValueCsv(csv), 'string');
						}
					},
				]

				function _isSame(val1, val2, type) {
					switch(type) {
					case 'string':
						return (val1 + '').localeCompare(val2 + '') == 0;
					case 'array':
						return _.without(val1 || [], val2 || []).length == 0;
					case 'boolean':
						return Boolean(val1) == Boolean(val2);
					case 'number':
						return parseInt(val1 || 0) - parseInt(val2 || 0) == 0;
					}

					return false;
				}
			}

			const m = ModalService.openModal(
				'./js/src/templates/modal/migrate/duplicate-review.html',
				{},
				controller,
				'controller',
				null,
				'duplicate-review-modal',
				null,
				true
			);

			m.result.then((res) => {
				if (!res) {
					return;
				}

				res.forEach((entry) => {
					switch(entry.choice) {
					case 'new':
						_doAccept(self.report.duplicates[entry.index].neu);
						break;
					case 'reject':
						self.report.rejected.push(self.report.duplicates[entry.index].neu);
						break;
					case 'merge':
						_doMerge(self.report.duplicates[entry.index]);
						break;
					}
				})

				_finish();
			})
			.catch((err) => {
				self.report.rejected = [];
				self.report.merged = [];
				self.report.duplicates = [];
			})
		}


	}); // End of service
})(); // End of function()
