/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('TasksController', function ($filter, $location, $log, $rootScope, $sce, $scope, $state, $stateParams, AccountService, AlertService, GlobalVars, ModalService, NavService, TaskService, UserService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.allItems				= null;		// All items (active/smart listings/buyers/sellers/suppliers) that belong to this user

		self.viewCompletedTasks		= false;	// Whether to show all completed tasks
		self.taskLegends			= null;		// Template for task legends popover

		$scope.curr				 	= null;		// Current item (listing or person) being shown

		self.hideTasks			 	= false;	// Used only in mobile version (determines view)
		self.tasksMenu				= null;		// Menu is shown by tasks page in mobile version
		self.originalTasks			= null;		// Unfiltered tasks

		self.detailsState			= null;		// State to use for task details
		self.detailsParams			= null;		// Params to use for task details state

		self.id 					= null;		// ID state param - if exists, will hide 'back' button in mobile version

		const PERSONAL 				= 'PERSON.PERSONAL';
		let currentIndex 			= 0;

		// SETUP VARIABLES ======================================================================================================================

		const personalSection = $sce.valueOf($filter('translate')(PERSONAL));
		self.id 			= $stateParams.id;

		if ($stateParams.hash && !$location.hash()) {
			$location.hash($stateParams.hash);
		}

		if (!$stateParams.id) {
			// Changes item currently shown based on hash
			const wHash = $scope.$watch(() => { return $location.hash(); }, () => {
				let hash = $location.hash();

				if (hash) {
					hash = hash.split('-');

					if (self.tasks && hash[1]) {
						$scope.curr = ((self.tasks[hash[0]] || {}).content || [])[hash[1]];
						currentIndex = hash[1];

						if (!$scope.curr) {
							hash[0] = personalSection;
							$scope.curr = self.tasks[hash[0]].content[0];
							currentIndex = 0;
						}
						self.hideTasks = false;
					}
				}
			});

			// Adds task type to crumb
			const wType = $scope.$watch('curr.type', (type, oldType) => {
				if (type) {
					if (oldType) {
						NavService.popStack();
					}
					NavService.addCrumb(null, {}, type);
				}
			})

			$scope.$on('$destroy', () => {
				wHash();
				wType();
			})
		}

		AlertService.loading();

		self.viewCompletedTasks = false;

		self.taskLegends 	= GlobalVars.popover('taskLegends');
		self.hideTasks 		= !$location.hash() || !$rootScope.isMobile;

		// Master tasks page
		if (!$stateParams.id) {
			self.detailsState = 'main.tasks.view';
		}
		else {
			// Listing -> tasks
			if (!$state.current.data.profileMenu) {
				self.detailsState = 'main.listings.detail.tasks.view';
			}

			// Supplier -> tasks
			else if ($state.current.name.includes('suppliers')) {
				self.detailsState = 'main.suppliers.profile.tasks.view';
			}

			// Buyer/Seller -> tasks
			else {
				self.detailsState = 'main.profile.tasks.view';
			}
		}

		let idFilter 	= $stateParams.id || null;
		const currentRole = AccountService.getRole();

		self.hideTasks 	= !idFilter;
		self.isBroker 	= (currentRole == 'brokerProfile' || currentRole == 'adminProfile' || currentRole == 'managerProfile');

		// Buyer or seller profile
		if (!!$stateParams.role && $stateParams.role !== 'supplier' && idFilter) {
			UserService.getUserId(idFilter, $stateParams.role).then((res) => {
				idFilter = res.id;
				getData();
			})
		}
		else {
			getData();
		}

		// FUNCTIONS ============================================================================================================================

		function getData() {

			let call = TaskService.createLists(true);

			if (!!idFilter) {
				switch($stateParams.role) {
				case 'buyer':
					call = TaskService.getBuyersList(idFilter, false); break;
				case 'seller':
					call = TaskService.getSellersList(idFilter, false); break;
				case 'supplier':
					call = TaskService.getSuppliersList(idFilter, false); break;
				default:
					call = TaskService.getListingList(idFilter, false);
				}
			}

			call.then((res) => {
				self.tasks = angular.copy(res);
			})
			.catch((err) => {
				console.log('err: ', err);
				self.tasks = {};
			})
			.finally(() => {
				self.allItems = angular.copy(self.tasks);
				if (idFilter) {
					$scope.curr = self.tasks[0] || self.tasks;
					currentIndex = 0;
				}
				else if ($location.hash())  {
					let hash = $location.hash();
					hash = hash.split('-');
					$scope.curr = ((self.tasks[hash[0]] || {}).content || {})[hash[1]];
					currentIndex = hash[1];

					if (!$scope.curr) {
						$scope.curr = ((self.tasks[personalSection] || {}).content || {})[0];
						currentIndex = 0;
					}
					else {
						self.hideTasks = false;
					}
				}
				else {
					$scope.curr = ((self.tasks[personalSection] || {}).content || {})[0];
					currentIndex = 0;
				}

				AlertService.doneLoading();
			})
		}

		/**
		 * 	Deletes completed tasks from an array.
		 * 	@param 	{object[]}	tasks 	The array of tasks to be deleted. Only completed tasks are deleted.
		 * 	@param 	{string}	type	The type of entry that the tasks belong to
		 */
		self.deleteAllTasks = function(tasks, type) {
			const task = (tasks || [])[0] || {};

			if ((!task.aboutId || !task.aboutType) && !task.listingId) return;

			TaskService.deleteTasks(task.aboutId || task.listingId, task.aboutType || 'Listing', tasks.length).then((res) => {
				if (res) {
					const lbl = tasks.length > 1 ? 'TASK.TASKS' : 'TASK.TASK';
					AlertService.success(lbl, 'delete', tasks.length > 1);
					if (type !== PERSONAL && !$stateParams.id) {
						$location.hash('Personal-0');
						$rootScope.$emit('updateTasks');
					}
					getData();
				}
			})
			.finally(AlertService.doneLoading);
		}

		/** Filters tasks menu based on user search */
		self.filter = function () {
			if (!self.allTasks) {
				self.allTasks = angular.copy(self.tasks);
			}

			function highlight(item) {
				item.title = $filter('highlightSearch')(item.title, self.searchValue);
			}

			for (const section in self.allTasks) {
				self.tasks[section] = angular.copy($filter('searchSingleFilter')(self.allTasks[section], self.searchValue, { title: 'title' }));
				self.tasks[section].forEach(highlight);
			}
		}

		/**
		 * 	Completes a task and saves change to DB. If task was completed, reopens it instead.
		 *	@param	{object}	task		Task that will be completed/reopened
		 *	@param	{object[]}	allTasks	Item's tasks
		 */
		self.completeTask = function (task, allTasks, type) {
			AlertService.loading();

			TaskService.completeTask(task).then((res) => {
				AlertService.saved();
			})
			.catch((err) => {
				$log.debug('Error: ', err);
			})
			.finally(AlertService.doneLoading)
		}

		/**
		 *	Gets color code for a task.
		 *	@param 		{object}	task	Task that this refers to
		 *	@returns	{string}			The name of the class for this Task (depending on whether it's completed and who it is assigned to)
		 */
		self.getClass = function (task) {
			return TaskService.getClass(task);
		};

		/**
		 * 	Mobile only.
		 * 	Switches view from list of tasks to list of entries
		 */
		self.back = function() {
			self.hideTasks = true;
			$location.hash('');
		}

		/**
		 *	Opens modal to add new task to an item.
		 *	@param 	{string}	itemId			Item ID
		 *	@param	{object[]}	tasks			Item's current tasks
		 *	@param	{string}	type 			Item type (Listing,Buyer,Seller,Supplier)
		 *	@param 	{object[]}	taskSuppliers	Array of suppliers already used in this item's tasks
		 */
		self.openTaskModal = function (itemId, tasks, type, taskSuppliers) {
			const retFunction = function (result) {
				$rootScope.$emit('updateTasks');
				TaskService.getTask(result.task.id, type).then((res) => {
					if (!tasks) {
						tasks = [];
					}
					tasks.push(res);
					tasks.sort((a, b) => {
						if ((a.due == null || a.due == undefined || a.due === '') && (b.due == null || b.due == undefined || b.due === '')) {
							return 0;
						}
						else if (a.due == null || a.due == undefined || a.due === '') {
							return 1;
						}
						else if (b.due == null || b.due == undefined || b.due === '') {
							return -1;
						}
						else {
							return Date.parse(a.due) - Date.parse(b.due);
						}
					});
				})
			};
			ModalService.openModalWithFunction('newTask',
				{
					itemId: function () { return itemId; },
					itemType: function () { return type; },
					task: function () { return null; },
					taskSuppliers: function() { return taskSuppliers || []; }
				}, 'NewTaskModalController', 'newTaskModalController', retFunction);
		};

		/**
		 *	Opens modal to import a task template.
		 *	@param 	{string}	itemId		Item ID
		 *	@param	{object[]}	tasks		Item's current tasks
		 *	@param	{string}	type		Item type (Listing,Buyer,Seller,Supplier)
		 */
		self.openTaskTemplateModal = function (itemId, tasks, type) {

			const retFunction = function (result) {

				if ((result.templates || []).length > 0) {
					AlertService.loading();

					const i = 0;
					const j = 0;

					const createTask = function (i, j, startDate) {
						const taskTemp = result.templates[i].taskList[j];

						const due = new Date(startDate.getTime() + ((taskTemp.due || 0) * 86400000) + taskTemp.seq);	// If they have the same due date, sequence will count to make it show below the previous task

						const toAdd = {
							assignedToId: taskTemp.assignedToId,
							assignedToType: taskTemp.assignedToType,
							name: taskTemp.name,
							description: taskTemp.description,
							due: due,
							created: new Date()
						}

						if (type == 'Listing') {
							toAdd.listingId = itemId;
						}
						else {
							toAdd.aboutId = itemId;
							toAdd.aboutType = type;
						}

						return TaskService.saveTask(toAdd).then((res) => {
							res.assignedTo = taskTemp.assignedTo;
							tasks.push(res);

							// Next task in the template -> Start date = due date of previous task
							if (j < result.templates[i].tasks.length - 1) {
								j += 1;
								return createTask(i, j, due);
							}

							// New Template -> Reset start date
							else if (i < result.templates.length - 1) {
								i += 1;
								j = 0;
								return createTask(i, j, result.startDate);
							}

							// Finished creating tasks
							else {
								return tasks.sort((a, b) => {
									if ((a.due == null || a.due == undefined || a.due === '') && (b.due == null || b.due == undefined || b.due === '')) {
										return 0;
									}
									else if (a.due == null || a.due == undefined || a.due === '') {
										return 1;
									}
									else if (b.due == null || b.due == undefined || b.due === '') {
										return -1;
									}
									else {
										return Date.parse(a.due) - Date.parse(b.due);
									}
								});
							}
						})
						.catch((err) => {})
					}

					createTask(0, 0, result.startDate).then((res) => {
						$rootScope.$emit('updateTasks');
						AlertService.doneLoading();
					});
				}
			};
			ModalService.openModalWithFunction('importTaskTemplate', {
				type: function() { return type; }
			}, 'ImportTaskTemplateModalController', 'importTaskTemplateModalController', retFunction);
		};

		/**
		 *	Gets parameters for a task -> Used to add parameters to task details state
		 *	@param 	{object}	task		Task that params refer to
		 *	@return {object}				Parameters for task details state
		 */
		self.getParams = function (task) {
			if (!$stateParams.id) {
				return { taskId: task.id, hash: $location.hash() };
			}
			else {
				return { taskId: task.id };
			}
		}

		self.getTaskParent = function (title) {
			if (!$stateParams.id) {
				return { link: 'main.tasks', title: title };
			}
			else {
				return null;
			}
		}
	}) // End controller

	.filter('completed', () => {
		return function (array) {
			if (array == undefined || array == null) return 0;
			let ret = 0;

			const arrayLength = array.length;
			for (let i = 0; i < arrayLength; i++) {
				if (array[i].completed) {
					ret += 1;
				}
			}
			return ret;
		};
	})

})(); // End of function()
