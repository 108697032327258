/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('NewPersonController', function (check, $scope, $state, $stateParams, AccountService, AlertService, BuyerService, GlobalVars, ModalService, NavService, SellerService, StorageService, UploadService, UserService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		let nextState;                                                // Where to navigate after saving

		self.newPerson    = null;                                     // Profile that will be added/edited
		self.isBuyer      = null;                                     // If we are adding/editing a buyer, some fields are different
		self.edit         = !!$stateParams.id;                        // Whether we are adding a new person or editing an existing profile
		self.uploader     = UploadService.initUserUploader(true, null, {});     // Uploader object (used to upload person's photo)
		self.allUsers     = [];                                       // All Users in the DB (used to check for duplicates)
		self.multiConfig  = GlobalVars.multiConfig;                   // Selectize config
		self.addListing   = $stateParams.addListing;                  // Whether we will link this person as a Seller in a Listing after creating it
		self.check        = check;                                    // Check object - For warning the user when leaving the page
		self.role = null;
		self.isNew = !$stateParams.id;

		// SETUP VARIABLES =====================================================================================================================

		if ($stateParams.role != null) {
			self.role = $stateParams.role;
			if ($stateParams.role === 'buyer') {
				nextState = 'main.buyer.buyer';
				self.isBuyer = true;
			}
			else {
				nextState = 'main.seller.seller';
				self.isBuyer = false;
			}
		}

		if (self.edit) {
			self.mobileTitle = 'COMMON.EDIT';
		}
		else if (self.isBuyer) {
			self.mobileTitle = 'PERSON.BUYER.NEW_BUYER_TITLE_MOBILE';
		}
		else {
			self.mobileTitle = 'PERSON.SELLER.NEW_SELLER_TITLE_MOBILE';
		}

		self.photoOptions = {
			classes: {
				upload: 'btn btn-green no-size upload',
				confirm: 'btn btn-green no-size upload',
				current: 'round avatar',
				new: 'round avatar'
			},
			dimensions: {
				init_w: 60,
				init_h: 60,
				result_w: 480,
				result_h: 480
			},
			texts: {
				upload: 'FILES.UPLOAD_PHOTO',
			}
		}

		// Empty model representing buyer/seller to add
		if (!$stateParams.newPerson && !$stateParams.id) {
			AccountService.getAccountDetails().then((res) => {
				const person = {
					firstName: '',
					lastName: '',
					languageIds: [],
					email: '',
					emailEnabled: true,
					phones: {
						home: '',
						work: '',
						mobile: '',
						fax: ''
					},
					_address: {
						addressString: '',
						postalCode: '',
						useString: true,
						country: (((res.user || {})._address || {}).country || null),
						province: (((res.user || {})._address || {}).province || null)
					},
					rent: false,
					buy: false,
				};

				if (!!self.isBuyer) {
					self.newPerson = !!StorageService.getBuyer() ? StorageService.getBuyer() : person;
				}
				else {
					self.newPerson = !!StorageService.getSeller() ? StorageService.getSeller() : person;
				}
			})
			.finally(() => {
				check.original = angular.copy(self.newPerson);
				check.clone = self.newPerson;
			})
			self.edit = false;
		}
		else if (!!$stateParams.id) {
			AlertService.loading();
			UserService.getPerson($stateParams.id, $stateParams.role).then((res) => {
				self.newPerson = res;
				if(self.newPerson._address) {
					self.newPerson._address.useString = true;
				}
				if (self.isBuyer) {
					self.newPerson._coBuyer = (self.newPerson.buyerProfile || {})._coBuyer;
					if((self.newPerson._coBuyer || {}).address) {
						self.newPerson._coBuyer.address.useString = true;
					}
				}

				self.edit = true;
			})
			.catch((err) => {})
			.finally(() => {
				check.original = angular.copy(self.newPerson);
				check.clone = self.newPerson;
				AlertService.doneLoading();
			})
		}
		else {
			self.newPerson = $stateParams.newPerson;
			if(self.newPerson._address) {
				self.newPerson._address.useString = true;
			}
			check.original = angular.copy(self.newPerson);
			check.clone = self.newPerson;
		}

		// FUNCTIONS ============================================================================================================================

		self.addCoBuyer = function() {
			self.newPerson._coBuyer = {
				phones: {}
			};
		}

		self.checkDuplicate = function (skipCompare) {
			if (!skipCompare) {
				AlertService.loading();
				UserService.checkDuplicate(self.newPerson.firstName, self.newPerson.lastName).then((res) => {
					if ((res || []).length > 0) {
						self.openModal('duplicateUser', res[0]);
					}
					else {
						_cont();
					}
				})
				.catch((err) => {
					console.log('err: ', err);
				})
				.finally(AlertService.doneLoading)
			}
			else {
				_cont();
			}

			function _cont() {
				check.isSaved = true;
				const nextParams = {
					newPerson: self.newPerson
				};

				NavService.addParam(NavService.getCurrStackItem($state.current), 'newPerson', self.newPerson);
				NavService.navigate(nextState, nextParams);
			}
		};

		self.save = function () {
			check.isSaved = true;
			AlertService.loading();

			if(self.newPerson._address) {
				self.newPerson._address.useString = true;
			}

			if( (self.newPerson._coBuyer || {})._address) {
				self.newPerson._coBuyer._address.useString = true;
			}

			UserService.updatePerson(self.newPerson).then((resUser) => {
				let nextRole, api;

				if ($state.params.role == 'buyer') {
					nextRole = 'buyer';
					api = BuyerService.getBuyerId(resUser.id)
				}
				else {
					nextRole = 'seller';
					api = SellerService.getSellerId(resUser.id)
				}
				api.then((res) => {
					if (res.buyerProfile) {
						resUser.roleId = (res.buyerProfile || {}).id || null;
					}
					else if (res.sellerProfile) {
						resUser.roleId = (res.sellerProfile || {}).id || null;
					}

					AlertService.success('PERSON.PROFILE','save');
					AlertService.doneLoading();
					NavService.navigate('main.profile', { id: resUser.roleId, role: nextRole }, true);
				})
			})
			.catch((err) => {})
		}

		self.saveListing = function() {
			const seller = {};
			seller.person = angular.copy(self.newPerson);
			SellerService.saveSeller(seller).then((resSeller) => {
				check.isSaved = true;
				StorageService.destroySeller();
				SellerService.linkListing(resSeller.id, $stateParams.listingId).then((resLink) => {
					NavService.popStack();
					NavService.navigateStack(NavService.navStack.length - 1);
				})
			})
			.catch((err) => {
				console.error(err);
			})
		}

		// Open modal to add new buyer
		self.openModal = function (template, newPerson) {
			const retFunction = function (response) {
				if (response.person != null) {
					self.newPerson = response.person;
				}
				self.checkDuplicate(true);
			}
			ModalService.openModalWithFunction(template, { newPerson: function () { return newPerson; } }, 'DuplicateUserController', 'duplicateUserController', retFunction);
		};

		self.checkForm = function () {
			const form = $scope.newPersonForm || {};
			if (form.$invalid && form.firstName.$invalid) {
				form.firstName.$$element.focus();
			}
			else if (form.$invalid && form.lastName.$invalid) {
				form.lastName.$$element.focus();
			}
			return !form.$invalid;
		}

		self.submit = function () {
			if (self.edit)
				return self.save();

			if (self.addListing)
				return self.saveListing();

			self.checkDuplicate();
			const store = self.isBuyer ? 'setBuyer' : 'setSeller';
			StorageService[store](self.newPerson);
		}

	}) // End of controller
})(); // End of function()
