(function () {

    "use strict";

    angular.module("smartbrokr.admin")
    .controller("AdminHelpController", function (AlertService, AdminService, ModalService, $filter) {

        var self = this;

        // VARIABLES ============================================================================================================================

        self.topics = [];       // Help topics. Each topic has an array of articles.

        _load();

        // FUNCTIONS ============================================================================================================================

        self.deleteArticle = function(topic, article, index) {
            if (article.id) {
                var del = function() {
                    topic.articles.splice(index, 1);
                    self.saveTopic(topic);
                }
                ModalService.confirmDelete(del, 'Are you sure you want to delete this Article?');
            }
            else {
                topic.articles.splice(index, 1);
            }
        }

        self.deleteFile = function(topicId, fileId, topicIndex) {
            AdminService.deleteHelpFile(topicId, fileId).then(function(res) {
                _load(topicIndex);
            })
        }

        self.deleteTopic = function(topic, index) {
            if (topic.id) {
                AdminService.deleteItem('HelpTopic', topic.id).then(function(res) {
                    if (res) {
                        self.topics.splice(index, 1);
                        AlertService.saved();
                    }
                })
                .finally(AlertService.doneLoading)
            }
            else {
                self.topics.splice(index, 1);
            }
        }

        self.editArticle = function(topic, article, lang) {
            var toEdit = (article || {})[lang] || {};

            if (toEdit.fileId) {
                toEdit.file = ((topic.folder || {}).files || []).find(x => x.id == toEdit.fileId);
            }

            var m = ModalService.openModal(
                '/js/src/admin/help/admin-help-modal.html',
                {
                    article:    () => toEdit || {},
                    lang:       () => lang,
                    folderId:   () => (topic.folder || {}).id
                },
                'AdminHelpModalController',
                'modalController',
                null,
                'large-modal'
            )

            m.result.then(function(res) { 
                res = res || {};
                if (res.article) {
                    toEdit.title    = res.article.title;
                    toEdit.text     = res.article.text;
                    toEdit.fileId   = res.article.fileId;
                    article[lang] = toEdit;

                    self.saveTopic(topic);
                }
                else if (res.uploaded) {
                    var i = self.topics.indexOf(x => x.id == topic.id);
                    _load(i);
                }
            })
        }

        self.getFileArticle = function(topic, file) {
            for (var i in topic.articles) {
                var article = topic.articles[i] || {};

                for (var lang in article) {
                    if (article[lang].fileId == file.id || (article[lang].text || '').includes(file.url))
                        return article[lang].title + ' (' + $filter('translate')('LANGUAGES.' + lang) + ')';
                }
            }

            return null;
        }

        self.newArticle = function(topic) {
            topic.articles.push({
                'EN': {
                    title: 'New Article',
                    text: ''
                },
                'FR': {
                    title: 'Nouvel article',
                    text: ''
                }
            })
        }

        self.newTopic = function() {
            var toAdd = {
                title: {
                    'EN': 'New Topic',
                    'FR': 'Nouveau sujet'
                },
                articles: []
            }
            self.saveTopic(toAdd);
        }

        self.saveTopic = function(topic, index) {
            delete topic.isOpen;

            index = index || self.topics.findIndex(x => x.id == topic.id);

            AdminService.saveHelpTopic(topic).then(function() {
                AlertService.saved();
                _load(index);
            })
        }

        function _load(index) {
            AlertService.loading();
            AdminService.getHelpTopics().then(function(res) {
                self.topics = res;

                if ((!!index || index === 0) && !!self.topics[index]) {
                    self.topics[index].isOpen = true;
                }
            })
            .finally(AlertService.doneLoading);
        }
    })

})();