/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
(function() {

	'use strict';

	angular.module('smartbrokr.citySelector',[])
	.controller('CitySelectorController', function($filter, $scope, $rootScope, $timeout, $window, AccountService, GlobalVars, LocationService, StaticService) {

		const self = this;
		const _ = $window._;

		self.showWarnings   = false;
		self.hideSublevels  = false;

		const instances = {};
		const watchers  = [];
		let brokerRegionsInstance = null;
		const isFrench = $rootScope.language === 'FR';

		self.locationConfig = GlobalVars.createSingleConfig(false,'id','name','name');
		self.multiConfig = GlobalVars.createMultiConfig(false, 'id', 'name', true, null, 'name');
		self.regionConfig   = GlobalVars.createLocationConfig('id', 'name', isFrench, 'nameFr');
		self.multiRegionConfig   = GlobalVars.createLocationMultiConfig('id', 'name', isFrench, 'nameFr');
		self.provinceConfig = GlobalVars.createLocationConfig('id', 'name', isFrench, 'nameFr');
		self.countryConfig  = GlobalVars.createSingleConfig(false,'id','nameEn','nameEn');

		self.brokerRegionsConfig = GlobalVars.createMultiConfig(false, 'id', 'name', true, null, 'name');
		self.brokerRegionsConfig.onInitialize = function(selectize){
			brokerRegionsInstance = selectize;
		}

		self.countryOptions     = StaticService.getCountries($scope.noOther);
		self.filteredProvinces  = [];
		self.filteredRegions    = [];
		self.filteredCities     = [];

		self.schema = AccountService.getSchema();

		// Keeps indexes of selected items
		let selectedCountryIndex  = 0;
		let selectedProvinceIndex = 0;
		let selectedRegionIndex   = 0;

		if ($scope.required === 'cityId') {
			self.locationConfig.onInitialize = function(selectize) {
				instances.city = selectize;
			}
		}

		if (!self.countryOptions) {
			const watch = $rootScope.$on('updateCountries', () => {
				const opts = StaticService.getCountries($scope.noOther);
				if (opts) {
					self.countryOptions = StaticService.getCountries($scope.noOther);
					setup();
					getFiltered();
					watch();
				}
			})
		}

		function setup() {
			if ($scope.selected.country && !$scope.selected.countryId) {
				$scope.selected.countryId = getId($scope.selected.country);

				if ($scope.selected.countryId === 'OTHER') {
					$scope.selected.countryName = $scope.selected.country.name;
				}
			}

			if ($scope.selected.province && !$scope.selected.provinceId) {
				$scope.selected.provinceId = getId($scope.selected.province);
			}

			if ($scope.selected.city && !$scope.selected.cityId) {
				$scope.selected.cityId = getId($scope.selected.city);
				if($scope.selected && $scope.selected.cityIds && $scope.selected.cityIds.indexOf($scope.selected.cityId) < 0) {
					$scope.selected.cityIds.push($scope.selected.cityId);
				}
			}

			if ($scope.selected.region && !$scope.selected.regionId) {
				$scope.selected.regionId = getId($scope.selected.region);
				if($scope.selected && $scope.selected.regionIds && $scope.selected.regionIds.indexOf($scope.selected.regionId) < 0) {
					$scope.selected.regionIds.push($scope.selected.regionId);
				}
			}

			$scope.selected.useString = $scope.useString;

			if ($scope.isBroker) {
				if ((($scope.broker || {}).regionsCoveredIds || []).length === 0 && !($scope.selected || {}).provinceId) {
					self.brokerRegionsConfig.placeholder = $filter('translate')('ADDRESS.ALERT_SELECT_FIRST');
				}
			}

			if (($scope.selected.addressString || '').includes('No Address')) {
				$scope.selected.addressString = '';
			}

			$rootScope.$emit('doneAddress');
		}

		self.filterProvinces = function() {
			$scope.selected.provinceId = null;
			$scope.selected.regionId = null;
			$scope.selected.regionIds = [];
			$scope.selected.cityId = null;
			$scope.selected.cityIds = [];

			self.filteredRegions = [];
			self.filteredCities = [];

			const countryId = $scope.selected.countryId;
			self.hideSublevels = countryId === 'OTHER';

			selectedCountryIndex = filter(countryId,'countryOptions','filteredProvinces','provinces');
		}

		self.filterRegions = function() {
			$scope.selected.regionId = null;
			$scope.selected.regionIds = [];
			$scope.selected.cityId = null;
			$scope.selected.cityIds = [];

			self.filteredCities = [];

			const provinceId = $scope.selected.provinceId;
			selectedProvinceIndex = filter(provinceId,'filteredProvinces','filteredRegions','regions');

			if ((self.filteredRegions || []).length === 1) {
				const regionId = (self.filteredRegions[0] || {}).id;
				$scope.selected.regionId = regionId;
				if($scope.selected.regionIds.indexOf(regionId) < 0) {
					$scope.selected.regionIds.push(regionId);
					if(self.schema != 'realtor') {
						self.filterCities(true);
					}
				}
			}

			if(self.schema === 'realtor') {
				self.filterCities();
			}
		}

		self.filterCities = function(ignoreFilterRegions = false) {
			$scope.selected.cityId = null;
			$scope.selected.cityIds = [];
			const regionId = $scope.selected.regionId;
			const regionIds = $scope.selected.regionIds;

			// No region field -> Address selector
			if ((regionId == null && (!regionIds || regionIds.length === 0)) || $scope.noRegion || self.schema === 'realtor') {
				if(self.schema !== 'realtor' && !ignoreFilterRegions) {
					self.filterRegions();
				}

				if ((self.filteredRegions || []).length > 1) {
					self.filteredCities = self.filteredRegions.reduce((arr,region) => {
						if (region.cities) {
							arr = arr.concat(region.cities);
						}
						return arr;
					}, []);
				} else if((self.filteredRegions || []).length === 1) {
					self.filteredCities = self.filteredRegions[0].cities;
				}
			}
			else {
				selectedRegionIndex = filter(regionIds && Array.isArray(regionIds) && regionIds.length > 0 && $scope.multi ? regionIds : regionId, 'filteredRegions', 'filteredCities', 'cities', $scope.multi);
			}
		}

		function getFiltered(){
			const selectedCountry = $scope.selected.countryId;
			const selectedProv    = $scope.selected.provinceId;
			const selectedRegion  = $scope.selected.regionId;
			const selectedCity    = angular.copy($scope.selected.cityId);

			// Country is already filled
			if (selectedCountry != undefined && selectedCountry != null) {
				self.hideSublevels = selectedCountry === 'OTHER';
				selectedCountryIndex = filter(selectedCountry,'countryOptions','filteredProvinces','provinces',[]);

				// Province is already filled
				if (selectedProv != null && selectedCountry != 'OTHER') {
					selectedProvinceIndex = filter(selectedProv,'filteredProvinces','filteredRegions','regions',[]);

					// Region is already filled
					if (selectedRegion != null && !$scope.broker) {
						selectedRegionIndex = filter(selectedRegion,'filteredRegions','filteredCities','cities',[]);
					}
					else if ((selectedCity == null && self.schema == 'realtor')|| !!$scope.noRegion || $scope.broker){
						self.filteredCities = self.filteredRegions.reduce((arr,region) => {
							arr = arr.concat(region.cities || []);
							return arr;
						},[]);
					}
				}
			}
		}

		$scope.$on('showWarnings',(event,field) => {
			self.showWarnings = field;

			if (!($scope.selected || {})[field] && instances.city) {
				instances.city.$control.toggleClass('ng-invalid', true);
			}

			try {
				const req = $('form .ng-invalid').first().parents('.form-group')[0];
				req.scrollIntoView();
			}
			catch(err) {}

		})

		$scope.$on('$destroy', () => {
			watchers.forEach((w) => {
				w();
			})
		})

		const w1 = $scope.$watch('selected', (newValue, oldValue) => {

			if (!newValue && !oldValue) {
				$scope.selected = {};
			}
			else if (newValue) {
				setup();
				getFiltered();
			}
		})

		const w2 = $scope.$watch('selected.countryId', (newValue) => {
			$scope.isState = newValue === 'USA';
		})

		const w3 = $scope.$watch('selected.provinceId', (newValue) => {
			if ($scope.isBroker && !!brokerRegionsInstance) {
				if (!newValue) {
					brokerRegionsInstance.settings.placeholder = $filter('translate')('ADDRESS.ALERT_SELECT_FIRST');
					self.filteredCities = [];
				}
				else {
					brokerRegionsInstance.settings.placeholder = ' ';
					getFiltered();
				}
				brokerRegionsInstance.updatePlaceholder();
			}
		})

		watchers.push(w1);
		watchers.push(w2);
		watchers.push(w3);

		self.findNames = function(cityId) {
			const ret = {};
			LocationService.getCity(cityId).then((res) => {
				for (const field in res) {
					ret[field] = res[field];
				}
			})
			return ret;
		}

		self.deleteItem = function(i) {
			if (!!$scope.previous) {
				$scope.previous.splice(i,1);
			}
		}

		self.addLocation = function(cityId) {
			if (!cityId) return;

			if (!!$scope.previous && (cityId != null || (Array.isArray(cityId) && cityId.length > 0))) {
				if(Array.isArray(cityId)) {
					$scope.previous = $scope.previous.concat(cityId);
				} else {
					$scope.previous.push(cityId);
				}
			}
			$scope.selected.countryId = null;
			$scope.selected.provinceId = null;
			$scope.selected.regionId = null;
			$scope.selected.regionIds = [];
			$scope.selected.cityId = null;
			$scope.selected.cityIds = [];
		}

		function getId(object) {
			object = object || {};
			if (object.id === 'CUSTOM') {
				return object.name;
			}
			else {
				return object.id;
			}
		}

		function filter(selectedId, upperOptions, downOptions, downField, multi = false) {

			let filtered = [];
			let index = null;

			if(multi && Array.isArray(selectedId)) {
				const upperOptionItems = self[upperOptions].filter((item) => {
					return selectedId.indexOf(item.id) >= 0;
				});
				if(upperOptionItems.length > 0) {
					for(let i = 0; i < upperOptionItems.length; i++) {
					 	filtered = filtered.concat(upperOptionItems[i][downField]);
					}
				}
			} else {
				if(Array.isArray(selectedId) && selectedId.length > 0) {
					selectedId = selectedId[0];
				}
				index = self[upperOptions].findIndex((item) => {
					return item.id === selectedId;
				});
				if (index >= 0) {
					filtered = self[upperOptions][index][downField];
				}
			}

			self[downOptions] = angular.copy(filtered);
			return index;
		}
	})

	/**
   * Includes Address field for Address string
   */
	.directive('addressSelector', () => {
		return {
			controller: 'CitySelectorController',
			controllerAs: 'selector',
			restrict: 'E',
			scope: {
				selected: '=',
				required: '=',
				noOther: '=',
				showOfficeName: '=?'
			},
			link: function ($scope, $element, $attrs) {
				$scope.noRegion = true;
				$scope.useString = true;
			},
			templateUrl: '/js/directives/citySelector/addressSelector.html'
		};
	})

	/**
   * Includes Region selection option instead of Address field
   *  + option to add array of previous selected items
   */
	.directive('cityAreaSelector', () => {
		return {
			controller: 'CitySelectorController',
			controllerAs: 'selector',
			restrict: 'E',
			scope: {
				selected: '=',
				required: '=',
				previous: '=',
				payment:'=',
				noOther: '=',
				multi: '=?'
			},
			link: function ($scope, $element, $attrs) {
				$scope.language = $scope.$root.language;
			},
			templateUrl: '/js/directives/citySelector/areaSelector.html'
		};
	})

	.directive('cityItem', () => {
		return {
			restrict: 'E',
			scope: {
				deleteItem: '=',
				city: '=',
				index: '='
			},
			link: function ($scope, $element, $attrs) {
				$scope.language = $scope.$root.language

				if ($scope.language === 'FR') {
					$scope.nameField = 'nameFr';
				}
				else {
					$scope.nameField = 'name';
				}
			},
			templateUrl: '/js/directives/citySelector/cityItem.html',
			controller: function($scope) {
				$scope.expanded = null;
				$scope.expand = function(what) {
					if ($scope.expanded === what) {
						$scope.expanded = null;
					}
					else {
						$scope.expanded = what;
					}
				}
			}
		};
	})

	/**
   * Includes Region selection option instead of Address field
   * Template is all one row instead of two
   */
	.directive('cityAreaSelectorOneRow', () => {
		return {
			controller: 'CitySelectorController',
			controllerAs: 'selector',
			restrict: 'E',
			scope: {
				selected: '=',
				required: '=',
				disabled: '=',
				noOther: '=',
				multi: '=?'
			},
			link: function ($scope, $element, $attrs) { },
			templateUrl: '/js/directives/citySelector/areaSelector_oneLine.html'
		};
	})

	.directive('paymentAddressSelector', () => {
		return {
			controller: 'CitySelectorController',
			controllerAs: 'selector',
			restrict: 'E',
			scope: {
				selected: '=',
				config: '='
			},
			link: function (scope, element, attrs) {},
			templateUrl: '/js/directives/citySelector/paymentAddressSelector.html'
		};
	})

	.directive('modalCitySelector', () => {
		return {
			controller: 'CitySelectorController',
			controllerAs: 'selector',
			restrict: 'E',
			scope: {
				selected: '='
			},
			link: function (scope, element, attrs) {},
			templateUrl: '/js/directives/citySelector/cityModal.html'
		};
	})

	.directive('multipleCitySelector', () => {
		return {
			controller: 'CitySelectorController',
			controllerAs: 'selector',
			restrict: 'E',
			scope: {
				selected: '=',
				broker: '=',
				noOther: '='
			},
			link: function (scope, element, attrs) {
				scope.noRegion = true;
				scope.isBroker = true;
			},
			templateUrl: '/js/directives/citySelector/multipleCitySelector.html'
		};
	})
})()
