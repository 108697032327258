(function () {

  "use strict";

  angular.module("smartbrokr")

    .controller("UserController", function ($uibModalInstance) {
//FeatureService
      var self = this;
        self.close = function () {
          $uibModalInstance.dismiss('cancel');
        };

      }) // End of controller
})(); // End of function()
