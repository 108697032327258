/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('UpdateSharedListingModalController', function (listingId, status, brokerName, agencyName, $filter, $log, $scope, $timeout, $translate, $uibModalInstance, AccountService, AgencyService, AlertService, BrokerService, GlobalVars, ListingService, MessageService, UploadService, UserService) {
		const self = this;

		// VARIABLES ============================================================================================================================
		self.selectedListing = listingId;

		self.showError = false;
		self.error = '';
		// SETUP VARIABLES ======================================================================================================================
		self.status = status;

		self.noteMessage = '';
		self.message = '';
		self.buttonText = '';
		self.successMessage = '';

		self.handleSharedListing = null;

		if(status === 'approved') {
			self.message = $filter('translate')('LISTING.MODAL.ACCEPT', { fullName: brokerName, agencyName: agencyName });
			self.noteMessage = $filter('translate')('LISTING.MODAL.NOTE.ACCEPT');
			self.handleSharedListing = BrokerService.approveSharedListing;
			self.successMessage = 'approvedShared';
			self.buttonText = $filter('translate')('LISTING.BUTTONS.APPROVE_SHARED');
		} else if (status === 'rejected') {
			self.message = $filter('translate')('LISTING.MODAL.REJECT');
			self.noteMessage = $filter('translate')('LISTING.MODAL.NOTE.REJECT');
			self.handleSharedListing = BrokerService.rejectSharedListing;
			self.successMessage = 'rejectShared';
			self.buttonText = $filter('translate')('LISTING.BUTTONS.REJECT_SHARED');
		} else {
			self.message = $filter('translate')('LISTING.MODAL.CANCEL');
			self.noteMessage = $filter('translate')('LISTING.MODAL.NOTE.CANCEL');
			self.handleSharedListing = BrokerService.cancelSharedListing;
			self.successMessage = 'cancelledShared';
			self.buttonText = $filter('translate')('LISTING.BUTTONS.CANCEL_SHARED');
		}

		$uibModalInstance.scope = $scope;
		$uibModalInstance.check = {
			original: {
				listingId: angular.copy(self.selectedListing)
			},
			clone: {
				listingId: self.selectedListing
			}
		}

		// FUNCTIONS ============================================================================================================================

		/**
		 *  Closes the modal window
		 */
		self.close = function () {
			$uibModalInstance.dismiss('cancel');
		}
		/**
		 *  Creates a new conversation with each recipient selected
		 *  and adds all files to that conversation (if required)
		 */
		self.send = function () {
			AlertService.loading();
			self.handleSharedListing(self.selectedListing).then((res) => {
				done();
			})
			.catch(doError);
		}

		/**
		 *  Callback function for uploader.onWhenAddingFileFailed
		 *  Shows a message to the user explaining why the file was not added to the message.
		 *  It assumes that it will only fail due to the file format not being allowed.
		 *  @param {object}  item  Item that could not be added to the uploader
		 */
		function doError(error) {
			AlertService.doneLoading();
			self.error = error.data.error.message;
			self.showError = true;
			$timeout(() => {
				self.showError = false;
			}, 5000);
		}

		/**
		 *  Called after the message is sent and all files have been uploaded.
		 *  Closes the modal.
		 */
		function done() {
			AlertService.doneLoading();
			AlertService.success('MESSAGES.MESSAGE', self.successMessage);
			self.shareListingForm.$setPristine();
			$uibModalInstance.close({ message: self.message });
		}

	}) // End of controller
})(); // End of function()
