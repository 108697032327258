/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('FinishSignupController', function (AccountService, $rootScope, $state, PaymentService, $translate, $filter, LocationService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.agencyPlans;         // List of plans for agencies
		self.brokerPlans;         // List of plans for independent brokers
		self.plans;               // Relevant plans for current onboarding flow

		self.broker;              // Independent broker that we are signing up
		self.agency;              // Agency that we are signing up
		self.brokers;             // Brokers that were added to the agency

		self.isBroker;            // An independent broker is setting up the account
		self.isAgency;            // An agency is setting up the account

		self.plan;                // Selected plan
		self.planDescription;     // Translated plan description

		self.startDate;           // Start date for the plan
		let monthEndDate;         // End of subscription if monthly
		let annualEndDate;        // End of subscription if annual

		self.visitedReview;       // Whether the user already went through the review page

		self.cardError;           // Error returned by Stripe when setting up the card
		self.error;               // Error to be displayed to the user

		// SETUP VARIABLES ======================================================================================================================

		$rootScope.$emit('loadingStart');

		self.visitedReview    = AccountService.getVisitedReview();
		self.plan             = AccountService.getPlan() || null;
		self.error            = null;
		self.planDescription  = '';

		self.agency           = null;
		self.broker           = null;
		self.isAgency         = $state.params.type == 'agency';
		self.isBroker         = !self.isAgency;

		self.startDate        = new Date(+new Date + 12096e5);
		monthEndDate          = new Date(self.startDate);
		annualEndDate         = new Date(self.startDate);
		self.startDate        = self.startDate.toDateString();

		monthEndDate.setMonth(monthEndDate.getMonth() + 1);
		monthEndDate = monthEndDate.toDateString();

		annualEndDate.setFullYear(annualEndDate.getFullYear() + 1);
		annualEndDate = annualEndDate.toDateString();

		self.cardError = {
			error:  false
		};

		self.card = AccountService.getBillingAddress() || {
			name:       '',
			phone:      '',
			address_1:  '',
			address_2:  '',
			city:       '',
			state:      '',
			country:    '',
			postalCode: '',
			address: {
				country:  null,
				province: null,
				region:   null,
				city:     null
			}
		};

		// Stripe form items
		const stripe    = Stripe(PaymentService.getStripeKey()); // Stripe API object
		const elements  = stripe.elements(); // Stripe API -> Elements

		const card = elements.create('card', { // Card element
			iconStyle:      'solid',
			hidePostalCode: true,
			style: {
				base: {
					iconColor:  '#8898AA',
					color:      '#22b573',
					lineHeight: '80px',
					fontWeight: 300,
					fontFamily: '"Montserrat", sans-serif',
					fontSize:   '20px',

					'::placeholder': {
						color:    '#22B573',
					},
				},
				invalid: {
					iconColor:  '#e85746',
					color:      '#e85746',
				}
			},
			classes: {
				focus:        'is-focused',
				empty:        'is-empty',
			}
		});
		card.mount('#card-element');
		card.on('change', (event) => {
			setOutcome(event);
		});

		const inputs = document.querySelectorAll('input.field'); // All input elements

		Array.prototype.forEach.call(inputs, (input) => {
			input.addEventListener('focus', () => {
				input.classList.add('is-focused');
			});
			input.addEventListener('blur', () => {
				input.classList.remove('is-focused');
			});
		});

		setup();

		// FUNCTIONS ============================================================================================================================

		/**
     *  Updates currently selected plan and shows translated message to the user.
     *  @param    plan    object    Selected plan
     */
		self.updatePlan = function(plan) {
			self.plan = plan;

			$translate('PAGES.SIGNUP.REVIEW.PLAN_DESC', {
				startDate:  self.startDate,
				plan:       self.plan.name,
				price:      $filter('currency')(self.plan.price/100),
				numBrokers: self.brokers.length,
				endDate:    self.plan.endDate })
			.then((desc) => {
				self.planDescription = desc;
			});
			//self.planDescription = "Billing will start 14 days from now, on " + self.startDate + ", and will be on the " + self.plan.name + " plan at " + $filter('currency')(self.plan.price/100) + " for " + self.brokers.length
			//            + " license(s). Your subscription will be covered until " + self.plan.endDate +"."
		}

		/**
     *  Navigates to previous page ('main.signup.situation')
     */
		self.prev = function () {
			$state.go('main.signup.situation');
		}

		/**
     *  Submits form and creates a stripe token for
     *  the current credit card information
     */
		self.submit = function () {
			self.error = null;

			if ((self.card.address || {}).cityId) {
				const address = angular.copy(self.card.address);

				LocationService.getCity(address.cityId).then((city) => {
					city = city || {};
					const form = document.querySelector('form');
					const extraDetails = {
						name: form.querySelector('input[name=cardholder-name]').value,
						address_line1: self.card.address_1,
						address_line2: self.card.address_2,
						address_zip: self.card.postalCode,
						address_city: city.name,
						address_state: ((city.region || {}).province || {}).name,
						address_country: (((city.region || {}).province || {}).country || {}).nameEn
					};
					stripe.createToken(card, extraDetails).then(setOutcome);
				})
			}
		}

		/**
     *  Sets up variables and data to be shown to the user.
     */
		function setup() {
			if (self.isAgency === true) {
				self.brokers = (AccountService.getInviteBrokers()).split(',') || [];

				PaymentService.getAgencyPlans()
				.then((res) => {
					self.agencyPlans = res;

					PaymentService.getAgencyBrokerPlans()
					.then((res) => {
						self.brokerPlans = res;

						self.plans = [        // 0 is monthly, 1 annual
							{
								type:       'agency',
								frequency:  'm',
								price:      0,
								name:       'Monthly',
								endDate:    monthEndDate
							},
							{
								type:       'agency',
								frequency:  'a',
								price:      0,
								name:       'Annual',
								endDate:    annualEndDate
							}
						];

						let i, length = self.agencyPlans.length;
						for (i = 0; i < length; i++) {
							if (self.agencyPlans[i].frequency == 'm') {
								self.plans[0].price += self.agencyPlans[i].price;
							}
							if (self.agencyPlans[i].frequency == 'a') {
								self.plans[1].price += self.agencyPlans[i].price;
							}
						}

						length = self.brokerPlans.length;
						for (i = 0; i < length; i++) {
							if (self.brokerPlans[i].frequency == 'm') {
								self.plans[0].price += self.brokerPlans[i].price * self.brokers.length;
							}
							if (self.brokerPlans[i].frequency == 'a') {
								self.plans[1].price += self.brokerPlans[i].price * self.brokers.length;
							}
						}

						length = self.plans.length;
						for (i = 0; i < length; i++) {
							if(self.plans[i].type == self.plan.type && self.plans[i].name == self.plan.name){
								self.plan = self.plans[i];
								self.updatePlan(self.plan);
							}
						}
					})
					.catch((err) => { })
					.finally(() => {
						$rootScope.$emit('loadingEnd');
					})
				})
				.catch((err) => {
					$rootScope.$emit('loadingEnd');
				})
			}
			else {
				PaymentService.getBrokerPlans()
				.then((res) => {
					self.brokers      = ['You'];
					self.brokerPlans  = res;

					self.plans = [      // 0 is monthly, 1 annual
						{
							type:       'broker',
							frequency:  'm',
							price:      0,
							name:       'Monthly',
							endDate:    monthEndDate
						},
						{
							type:       'broker',
							frequency:  'a',
							price:      0,
							name:       'Annual',
							endDate:    annualEndDate
						}
					];

					let j;
					const length = self.brokerPlans.length;
					for (j = 0; j < length; j++) {
						if (self.brokerPlans[j].frequency == 'm') {
							self.plans[0].price += self.brokerPlans[j].price;
						}
						if (self.brokerPlans[j].frequency == 'a') {
							self.plans[1].price += self.brokerPlans[j].price;
						}
					}
				})
				.catch((err) => { })
				.finally(() => {
					$rootScope.$emit('loadingEnd');
				});
			}
		}

		// Use the token to create a charge or a customer
		// https://stripe.com/docs/charges
		function setOutcome(result) {
			const errorElement = document.querySelector('.error');
			if (errorElement) {
				errorElement.classList.remove('visible');
			}

			if (result.token) {
				if (self.signupForm.$valid) {
					AccountService.savePlan(self.plan);
					AccountService.saveStripeToken(result.token.id);
					AccountService.saveBillingAddress(self.card);
					AccountService.saveCreditCard(result.token.card);
					$state.go('main.signup.review');
				}
			}
			else if (result.error) {
				self.cardError.error = true;
				$rootScope.$digest();
				if (errorElement) {
					errorElement.textContent = result.error.message;
					errorElement.classList.add('visible');
				}
			}
			else {
				self.cardError.error = false;
			}
		}
	});
})();
