/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/**
 * © copyright 2017-2019, 2818779 Canada Inc.
 * 
 * Use of this software is only permitted if you have entered into a
 * license agreement with 2818779 Canada Inc. and is subject to the terms
 * and conditions of such license agreement.
 *
 */

(function () {

	'use strict';

	angular.module('smartbrokr.directives', [])

	/* DATA ===============================================================================================  */
	.directive('errSrc', () => {
		return {
			link: function (scope, element, attrs) {
				element.bind('error', () => {
					if (attrs.src != attrs.errSrc) {
						attrs.$set('src', attrs.errSrc);
					}
				});

				attrs.$observe('ngSrc', (value) => {
					if (!value && attrs.errSrc) {
						attrs.$set('src', attrs.errSrc);
					}
				});
			}
		}
	})

	.directive('file', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/partials/file-item.html',
			link: function (scope, element, attrs) {
				if (scope.file) {
					const file = scope.file || {};
					element.attr('title', (file.originalFilename || file.name));
				}
			},
			controller: function (FileService) {
				const self = this;

				self.getImageUrl = FileService.getImageUrl;
			},
			controllerAs: 'fileController'
		};
	})

	.directive('filterArrow', () => {
		return {
			restrict: 'E',
			scope: {
				filter: '='
			},
			template: '<img ng-class="{ \'upside-down\' : filter.desc == -1 && filter.weight != 0 , \'flip-right\' : filter.weight == 0 }" src="/public/img/iconArrowDown.svg" width="8" height="6">'
		};
	})

	/* TEXT COLOR DIRECTIVES ==============================================================================  */

	// <red></red>
	.directive('red', () => {
		return {
			restrict: 'E',
			transclude: true,
			scope: {},
			template: '<span style="color: red" ng-transclude></span>'
		};
	})

	// <blue></blue>
	.directive('blue', () => {
		return {
			restrict: 'E',
			transclude: true,
			scope: {},
			template: '<span class="text-blue" ng-transclude></span>'
		};
	})

	/* TEXT TYPES =========================================================================================  */

	.directive('subtext', () => {
		return {
			restrict: 'E',
			transclude: true,
			scope: {},
			template: '<span class="subtext" ng-transclude></span>'
		};
	})

	.directive('email', ($sce) => {
		return {
			restrict: 'E',
			template: '<span class="hover-underline" ng-click="function(person,supplier);$event.stopPropagation();" ng-bind-html="email"></span><span ng-if="!person.email || person.email == \'\'" translate>{{ \'EMAIL.NO_EMAIL\' }}</span>',
			scope: {
				person: '=',
				function: '=',
				supplier: '='
			},
			link: function(scope, element, attr) {
				scope.$watch('person', () => {
					if (scope.person) {
						let email = (scope.person || {}).email || '';
						email = email.replace('@', '<wbr>@');
						scope.email = email.replace(/\./g, '<wbr>.');
					}
					else {
						scope.email = '';
					}
				})

			}
		};
	})

	.directive('phone', ($filter, $sce) => {
		return {
			restrict: 'E',
			template: '<a ng-class="{\'hover-underline\': !noPhone }" ng-click="noPhone ? true : $event.stopPropagation()" ng-href="{{ noPhone ? null : \'tel:\' + phoneLink }}">{{ noPhone ? noPhoneLabel : phone }}</a>',
			scope: {
				phone: '@?',
				person: '='
			},
			link: function (scope, element, attrs, ctrls) {

				const noPhoneLabel = $sce.valueOf($filter('translate')('PERSON.PHONE_TYPES.NO_PHONE'));

				if (attrs.person && !attrs.phone) {
					const person = scope.person || {};
					attrs.phone = person.phone || $sce.valueOf($filter('getOnePhone')(person.phones));
				}

				if (!attrs.phone || attrs.phone == noPhoneLabel || attrs.phone === ' ') {
					scope.noPhone = true;
					scope.noPhoneLabel = noPhoneLabel;
				}

				if (!scope.noPhone)  {
					let phone = attrs.phone;

					if (!phone.includes('+')) {
						phone = '+1' + phone;
					}

					phone = phone.replace(/[\(\)]/g, '-');      // Replace () for -
					phone = phone.replace(/\./g, '');           // Remove dots
					phone = phone.replace(/ /g, '');            // Remove spaces

					if (phone.match(/(\d|-)+(#|ext|x|ex|ete|extn){1}\d{1,}/i) != null) {     // Phone has extension number that we recognize
						phone = phone.replace(/(#|ext|x|ex|ete|extn{1})(\d{1,})(.)*/gi, ';ext=$2');  // Also removes anything that comes after the extension number
					}
					else {
						phone = phone.replace(/[A-z]/g, '');    // Remove any extra information
					}

					scope.phoneLink = phone;
				}

			}
		};
	})

	.directive('formWarning', () => {
		return {
			restrict: 'E',
			template: '<span class="form-warning pull-right" ' +
                    'ng-if="!form.$valid && form.$submitted && condition" ' +
                    'translate>{{ invalid && !field.$valid && field.$dirty ? \'FORMS.INVALID\' : \'FORMS.REQUIRED\' }}</span>',
			scope: {
				form: '=',
				condition: '=',
				invalid: '=?',
				field: '=?'
			},
			link: function(scope, el, attrs) {}
		};
	})

	.directive('invalidField', () => {
		return {
			restrict: 'E',
			template: '<span class="form-warning pull-right" ng-if="field.$dirty && !field.$valid" translate>{{ \'FORMS.INVALID\' }}</span>',
			scope: {
				field: '='
			},
		};
	})

	.directive('saveButton', () => {
		return {
			restrict: 'E',
			template: '<button type="submit" class="btn" ng-class="[{ \'pull-right\': pullRight }, size, color]" translate>{{ \'FORMS.SAVE\' }}</button>',
			scope: {
				size: '=',
				color: '=',
				pullRight: '='
			}
		};
	})

	.directive('resultsLine', () => {
		return {
			restrict: 'E',
			template: '<span>{{length || 0}} <span translate>{{ length == 1 ? \'SEARCH.RESULT\': \'SEARCH.RESULTS\'}}</span></span>',
			scope: {
				length: '='
			}
		};
	})

	.directive('closeButton', () => {
		return {
			restrict: 'E',
			template: '<button class="btn btn-link img-white btn-close" ng-click="close()"></button>',
			scope: {
				close: '=?'
			},
			controller: ['$scope', '$uibModalStack', function Controller($scope, $uibModalStack) {
				if (!$scope.close) {
					$scope.close = function() {
						const modal = $uibModalStack.getTop();
						if ((modal || {}).key) {
							modal.key.dismiss('cancel');
						}
					}
				}
			}]
		};
	})

	.directive('mobileBack', () => {
		return {
			restrict: 'E',
			template: '<button class="mobile-back" type="button" ng-click="smartbrokrController.navigateBack(pop)"></button>',
			scope: {
				pop: '@?'
			},
			link: function (scope, element, attrs, ctrls) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
			}
		}
	})

	/* FORM PATTERNS ======================================================================================  */

	.directive('firstLetterUpper', ($parse, $log) => {
		return {
			restrict: 'A',
			link : function (scope, element, attr) {
				if (element[0].tagName !== 'INPUT') {   // Should only be used with input elements
					$log.warn('[first-letter-upper] used with wrong tag:', element[0].tagName);
					return;
				}

				const watcher = scope.$watch(attr.ngModel, (newValue) => {
					if (typeof newValue === 'string') {
						const firstLetter = ((newValue || '').match(/[A-z]/) || [])[0];
						if (firstLetter) {                      // There is a letter
							if(firstLetter.match(/[a-z]/)) {    // Letter is lower case -> Replace first letter to upper case, then apply to scope
								newValue = newValue.replace(firstLetter, firstLetter.toUpperCase());
								$parse(attr.ngModel).assign(scope,newValue);
							}
						}
					}
				})

				scope.$on('$destroy', () => {
					watcher();
				})
			}
		};
	})

	/**
         *  After trying to implement fancy libraries, went back to this ugly and dumb filter
         */
	.directive('phonePattern', ($parse, $compile) => {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attrs, ctrls) {

				element[0].placeholder = '(555) 555-5555';

				const watcher = scope.$watch(attrs.ngModel, (newValue, oldValue) => {
					if (newValue) {
						if (!newValue.includes('+')) {  // Ignore cases where user is entering an international number
							oldValue = oldValue || '';

							// First chunk start: '(xxx'. Check oldValue to see if user is not deleting.
							if (newValue.length < 3 && !newValue.includes('\(') && !oldValue.includes('\(')) {
								newValue = '(' + newValue;
							}

							// First chunk end: '(xxx) '
							else if (newValue.length === 4 && !newValue.includes('\) ') && !oldValue.includes('\)')) {
								newValue = newValue + ') ';
							}

							// Second chunk end: '(xxx) xxx-'
							else if (newValue.length === 9 && !newValue.includes('-') && !oldValue.includes('-')) {
								newValue = newValue + '-';
							}

							// Third chunk end: '(xxx) xxx-xxxx '. Added so user can add extension number, for example
							else if (newValue.length === 14 && !oldValue.endsWith(' ')) {
								newValue = newValue += ' ';
							}
						}
						$parse(attrs.ngModel).assign(scope,newValue);
					}
				})

				scope.$on('$destroy', () => {
					watcher();
				})
			}
		}
	})

	.directive('stripeMoney', ($filter) => {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attrs, ngModel) {
				ngModel.$validators.validNumber = function(model) {
					return /[0-9]+/.test(model) && Number(model) > 50;
				}

				ngModel.$formatters.push((value) => {
					return $filter('currency')(value / 100, '$', 2);
				});

				//format text from the user (view to model)
				ngModel.$parsers.push((value) => {
					const val = (value || '').replace(/\$|,/g, '');
					return Number(val) * 100;
				});
			}
		}
	})

	/* PARTIALS ===========================================================================================  */
	.directive('formGroup', () => {
		return {
			restrict: 'E',
			scope: {
				sbForm: '=',
				sbField: '@',
				sbLabel: '@',
				sbType: '@',
				isSelect: '@?',
				sbSelect: '=?',
				sbDisabled: '=?',
				sbClass: '@',
				sbParent: '=',
				sbRequired: '=',
				min: '@?',
				max: '@?',
				sbPlaceholder: '@?',
				sbName: '@?',
				sbChange: '=?',
				sbMaxlength: '@?',
				sbMinlength: '@?',
				noInvalidField: '@?'
			},
			templateUrl: 'templates/partials/profile-edit/form-group.html',
			link : function (scope, element, attrs) {
				if (attrs.sbType === 'number') {
					if (attrs.min === undefined) scope.min = 0;
					if (attrs.max === undefined) scope.max = null;
				}

				if (!attrs.sbName) {
					attrs.sbName = attrs.sbField;
				}
			}
		};
	})


	.directive('initBind', ($compile) => {
		return {
			restrict: 'A',
			link : function (scope, element, attr) {
				attr.$observe('ngBindHtml',() => {
					if(attr.ngBindHtml){
						$compile(element[0].children)(scope);
					}
				})
			}
		};
	})

	// Listing details
	.directive('descriptions', () => {
		return {
			restrict: 'E',
			templateUrl: 'listings/listing-detail/description.html'
		};
	})
	.directive('costs', () => {
		return {
			restrict: 'E',
			templateUrl: 'listings/listing-detail/costs.html'
		};
	})
	.directive('addenda', () => {
		return {
			restrict: 'E',
			templateUrl: 'listings/listing-detail/addenda.html'
		};
	})
	.directive('building', () => {
		return {
			restrict: 'E',
			templateUrl: 'listings/listing-detail/building.html'
		};
	})
	.directive('features', () => {
		return {
			restrict: 'E',
			templateUrl: 'listings/listing-detail/features.html'
		};
	})
	.directive('rooms', () => {
		return {
			restrict: 'E',
			templateUrl: 'listings/listing-detail/rooms.html'
		};
	})
	.directive('lot', () => {
		return {
			restrict: 'E',
			templateUrl: 'listings/listing-detail/lot.html'
		};
	})
	.directive('evaluation', () => {
		return {
			restrict: 'E',
			templateUrl: 'listings/listing-detail/evaluation.html'
		};
	})

	.directive('listingPerson', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/listingPerson.html',
			scope: {
				person: '=',
				unlink: '=',
				userType: '@',
				goTo: '=',
				readOnly: '=',
				modal: '=',
				showCompanyName: '=?'
			},
			link: function (scope, element, attrs) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
			}
		};
	})

	.directive('listingBasic', () => {
		return {
			restrict: 'E',
			scope: {
				listing: '=',
				openModal: '=',
				convert: '=',
				readOnly: '=',
				hidePopover: '=?'
			},
			link: function (scope, element, attrs) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
			},
			templateUrl: 'templates/listing.html'
		};
	})

	.directive('fileFolders', () => {
		return {
			restrict: 'E',
			scope: {
				options: '=',
				folders: '='
			},
			link: function (scope, element, attrs) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
			},
			controller: ['$scope', function Controller($scope) {
				$scope.options = $scope.options || {};
				$scope.folders = $scope.folders || {};

				$scope.searchText = '';

				const defaults = {
					hideFunction: null,
					hideFlag: false,
					hideCondition: function() { return ; },
					missingLabel: 'FILES.SHOW_MISSING',
					folderName: function(folder) { return folder.name; },
					readOnly: false,
					setUrl: function(id) { return; },
					deleteFunction: function() { return; },
					editFunction: null,
					folderClass: function(folder) { return null; },
					deleteFolder: null,
					customUploadFunction: null,
					download: function(file, folder) { return null; },
					sbDocusign: function(file) { return null; },
					sbDocusignConnected: false,
					searchable: false
				}

				for (const field in defaults) {
					$scope.options[field] = $scope.options[field] || defaults[field];
				}

				$scope.getUploader = function(folder) {
					if ($scope.options.setupUploader && !$scope.options.uploader) {
						$scope.options.uploader = $scope.options.setupUploader();
						$scope.options.uploader.onBeforeUploadItem = function (item) {
							this.url = $scope.options.setUrl(folder.id);
						}
					}
				}
			}],
			templateUrl: 'templates/partials/file-folders.html'
		};
	})

	// People
	.directive('person', () => {
		return {
			restrict: 'E',
			scope: {
				person: '=',
				userType:'@',
				noLink: '=?'
			},
			link: function (scope, element, attrs) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
				scope.noLink = angular.isDefined(scope.noLink) ? scope.noLink : false;
			},
			templateUrl: 'templates/partials/person.html'
		};
	})
	.directive('supplier', () => {
		return {
			restrict: 'E',
			scope: {
				supplier: '=',
				addRole: '=',
				noLink: '=',
				isListing: '=',
				unlink: '=?'
			},
			link: function (scope, element, attrs) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
				scope.language = scope.$root.language;
				scope.isSeller = scope.smartbrokrController.getRole() === 'sellerProfile';
			},
			templateUrl: 'templates/partials/supplier.html'
		};
	})

	.directive('buyer', () => {
		return {
			restrict: 'E',
			scope: {
				buyer: '=',
				addRole: '=',
				noLink: '='
			},
			link: function (scope, element, attrs) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
			},
			templateUrl: 'templates/partials/buyer.html'
		};
	})

	.directive('buyerCity', () => {
		return {
			restrict: 'E',
			scope: {
				buyer: '=',
				city: '=',
				profileController: '=',
				noLink: '=',
				modified: '=',
			},
			link: function (scope, element, attrs) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
				scope.language = scope.$root.language;
			},
			templateUrl: 'templates/partials/buyerBottom.html'
		};
	})

	.directive('seller', () => {
		return {
			restrict: 'E',
			scope: {
				seller: '=',
				readonly: '=',
				addRole: '='
			},
			link: function (scope, element, attrs) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
			},
			templateUrl: 'templates/partials/seller.html'
		};
	})
	.directive('sellerBottom', () => {
		return {
			restrict: 'E',
			scope: {
				seller: '=',
				noLink: '='
			},
			link: function (scope, element, attrs) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
			},
			templateUrl: 'templates/partials/sellerBottom.html'
		};
	})

	.directive('taskGroup', () => {
		return {
			restrict: 'E',
			scope: {
				displayTitle: '=',
				tasks: '=',
				controller: '=',
				type: '=',
				itemId: '=',
				taskSuppliers: '='
			},
			link: function (scope, element, attrs) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
				scope.screenSize = scope.$root.screenSize;
			},
			templateUrl: 'tasks/task-partial.html'
		}
	})

	.directive('taskMin', () => {
		return {
			restrict: 'E',
			scope: {
				task: '='
			},
			link: function (scope, element, attrs) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
				scope.currentUser = scope.$root.currentUser;
			},
			templateUrl: 'templates/partials/task-min.html'
		}
	})

	// Files & photo pages
	.directive('fileContainer', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/fileContainer.html'
		};
	})
	.directive('photoCaravan', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/photos/caravan.html'
		};
	})
	.directive('photoVideo', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/photos/video.html'
		};
	})
	.directive('photoCentris', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/photos/centris.html'
		};
	})
	.directive('photoWebsite', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/photos/website.html'
		};
	})
	.directive('photoFeatureSheet', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/photos/featureSheet.html'
		};
	})

	.directive('photosContainer', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/photosContainer.html',
			scope: {
				id: '@',
				type: '@',
				photos: '=',
				min: '=',
				max: '=',
				drag: '=',
				drop: '=',
				handleDrop: '=',
				selectPhoto: '=',
				currentlyDragging: '=',
				viewPhoto: '=',
				deletePhoto: '=',
				getIconClass: '=?',
				transferring: '=',
				progress: '=',
				sbDownload: '=',
				orderer: '=?'
			},
			link: function (scope, element, attrs) {
				scope.getIconClass = scope.$root.getIconClass;
			},
			controller: function($scope, $sce, FileService) {
				if($scope) {
					$scope.$watch('photos', () => {
						if($scope.photos && $scope.photos.length > 0) {
							for(let i = 0; i < $scope.photos.length; i++) {
								if($scope.photos[i] && $scope.photos[i].file && $scope.photos[i].file.url && $scope.photos[i].file.url !== '') {
									$scope.photos[i].file.url = $sce.valueOf($sce.trustAsResourceUrl(FileService.getImageUrl($scope.photos[i].file.url)));
								} else if($scope.photos[i] && $scope.photos[i].url && $scope.photos[i].url !== '') {
									$scope.photos[i].url = $sce.valueOf($sce.trustAsResourceUrl(FileService.getImageUrl($scope.photos[i].url)));
								}
							}
						}
					});
				}
			}
		};
	})

	.directive('filterByOffice', () => {
		return {
			restrict: 'E',
			scope: {
				users: '=',
				receivers: '=',
				selectedOffice: '=',
				offices: '=',
				required: '=',
				idKey:'='
			},
			templateUrl: 'templates/partials/filter-by-office.html',
			link: function(scope, element, attrs) {

			},
			controller: function($scope) {
				const self = this;
				self.selectAll = function(ev) {
					if(ev) {
						if(ev.preventDefault) ev.preventDefault();
						if(ev.stopPropagation) ev.stopPropagation();
					}
					if($scope.selectedOffice) {
						const selectedOffice = $scope.offices.find((office) => {
							if(office.id === $scope.selectedOffice) return office;
						})
						if(selectedOffice) {
							for(let i = 0; i < selectedOffice.brokers.length; i++) {
								if($scope.receivers.indexOf(selectedOffice.brokers[i][$scope.idKey]) < 0 && selectedOffice.brokers[i].status === 'SB_USER') {
									$scope.receivers.push(selectedOffice.brokers[i][$scope.idKey]);
								}
							}
						}
					} else {
						for(let i = 0; i < $scope.users.length; i++) {
							if($scope.receivers.indexOf($scope.users[i][$scope.idKey]) < 0) {
								$scope.receivers.push($scope.users[i][$scope.idKey]);
							}
						}
					}

					$scope.receivers = JSON.parse(JSON.stringify($scope.receivers));
				}

				self.deselectAll = function(ev) {
					if(ev) {
						if(ev.preventDefault) ev.preventDefault();
						if(ev.stopPropagation) ev.stopPropagation();
					}
					if($scope.selectedOffice) {
						const selectedOffice = $scope.offices.find((office) => {
							if(office.id === $scope.selectedOffice) return office;
						});
						if(selectedOffice) {
							for(let i = 0; i < selectedOffice.brokers.length; i++) {
								if($scope.receivers.indexOf(selectedOffice.brokers[i][$scope.idKey]) > -1) {
									const position = $scope.receivers.indexOf(selectedOffice.brokers[i][$scope.idKey]);
									$scope.receivers.splice(position, 1);
								}
							}
						}
					} else {
						$scope.receivers = [];
					}
					$scope.receivers = JSON.parse(JSON.stringify($scope.receivers));
				}
			},
			controllerAs: 'filterByOffice'
		}
	})

	.directive('formRecipients', () => {
		return {
			restrict: 'E',
			scope: {
				condition: '=',
				form: '=',
				loading: '=',
				receivers: '=',
				required: '=',
				users: '=',
				idKey:'='
			},
			templateUrl: 'templates/partials/form-recipients.html',
			link: function(scope, element, attrs) {},
			controller: function($scope, GlobalVars) {
				const self = this;
				self.config = GlobalVars.createMultiConfig(false, $scope.idKey, 'fullName', true, 'hidden', 'search');
				self.config.render = {
					option: function(data, escape) {
						return '<div class="option" data-selectable="" data-value="' + data[$scope.idKey]  + '">' + data['user']['fullName'] + '</div>';
					},
					item: function(data, escape) {
						return '<div class="item" data-value="' + data[$scope.idKey] + '">' + data['user']['fullName']  + '</div>';
					}
				}
			},
			controllerAs: 'formRecipientsController'
		}
	})

	.directive('httpSrc', [
		'$http', function ($http) {
			const directive = {
				link: link,
				restrict: 'A'
			};
			return directive;

			function link(scope, element, attrs) {

				const requestConfig = {
					method: 'Get',
					url: attrs.httpSrc,
					responseType: 'arraybuffer',
					cache: 'true'
				};
				const get = function (url) {
					if (url) {
						requestConfig.url = url;
					}
					$http(requestConfig)
					.then((response) => {

						if (response.data) {

							const arr = new Uint8Array(response.data);
							let raw = '';
							let i, j, subArray;
							const chunk = 5000;
							for (i = 0, j = arr.length; i < j; i += chunk) {
								subArray = arr.subarray(i, i + chunk);
								raw += String.fromCharCode.apply(null, subArray);
							}
							const b64 = btoa(raw);

							attrs.$set('src', 'data:image/jpeg;base64,' + b64);
						}
					})
					.catch((err) => { });
				}
				scope.$watch(() => {
					return attrs['httpSrc'];
				}, (newValue) => {
					get(newValue);
				});
			}

		}
	])
	.directive('sbFile', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/partials/file.html',
			scope: {
				singleFile: '=',
				files: '=',
				folder: '=',
				enableExport: '=?',
				exportList: '=?',
				checkExportBox: '=?',
				controller: '=',
				delete: '=',
				readOnly: '=',
				searchable: '=',
				sbDownload: '=',
				sbDocusign: '=',
				sbDocusignConnected: '='
			},
			link: function (scope, element, attrs) {
				scope.limitedAccess = scope.$root.limitedAccess;
				scope.screenSize = scope.$root.screenSize;
			},
			controller: function($filter, FileService) {
				const self = this;

				self.getFileUrl = function(url) {
					return FileService.getImageUrl(url);
				}

				self.searchText = '';
				self.placeholder = $filter('translate')('FILES.SEARCH_FOLDER');
				self.clearSearch = function() {
					self.searchText = '';
				}
			},
			controllerAs: 'sbFileController'
		};
	})

	.directive('sbFileThumbnail', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/partials/file-thumbnail.html',
			scope: {
				file: '=',
				delete: '=',
				folder: '=',
				sbDownload: '='
			},
			link: function (scope, element, attrs) {
				scope.getIconClass  = scope.$root.getIconClass;
			},
			controller: function($scope, $log, $window, $sce, FileService, ModalService) {
				const self = this;

				FileService.doImageUrlGrab($scope.file.url).then((data) => {
					$scope.file.url = $sce.valueOf($sce.trustAsResourceUrl(data));
				}, (error) => {

				});

				self.download = function(file) {
					if ($scope.sbDownload) {
						$scope.sbDownload(file, $scope.folder);
					}
					else {
						$log.error('No download function was declared.');
					}
				}

				$scope.viewFile = function(file) {
					const type = (file || {}).type || '';
					if (type.includes('video') || type.includes('image')) {
						ModalService.openModal('viewImage',
							{
								media: function () { return file; },
								mediaType: function () { return 'img'; },
								sbDownload: function() { return self.download; }
							},
							'MediaController', 'mediaController');
					}
					else {
						$window.open((file || {}).url, '_blank');
					}
				}
			}
		};
	})

	.directive('sbFileTable', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/partials/file-table.html',
			scope: {
				files: '=',
				multiFilter: '=',
				sort: '=',
				length: '=',
				controller: '=',
				delete: '=',
				sbDownload: '=',
				sbDocusign: '=',
				sbDocusignConnected: '='
			},
			link: function (scope, element, attrs) {
				scope.limitedAccess = scope.$root.limitedAccess;
				scope.screenSize = scope.$root.screenSize;

				scope.$watch('files.length', (newValue, oldValue) => {
					scope.fileLength = newValue;
				}, true);
			},
			controller: function (FileService) {
				const self = this;

				self.getFileUrl = function(url) {
					return FileService.getImageUrl(url);
				}
			},
			controllerAs: 'fileTableController'
		};
	})

	.directive('pwCheck', [function () {
		return {
			require: 'ngModel',
			link: function (scope, elem, attrs, ctrl) {
				const firstPassword = '#' + attrs.pwCheck;
				elem.add(firstPassword).on('keyup', () => {
					scope.$apply(() => {
						const v = elem.val() === $(firstPassword).val();
						ctrl.$setValidity('pwmatch', v);
					});
				});
			}
		}
	}])
	.directive('sbImage', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/photos/sb-image.html',
			scope: {
				image: '=',
				menuImage: '=?',
				type: '@',
				classes: '@',
				angClasses: '=',
				square: '@'
			},
			link: function (scope, element, attrs) {
				// const missingImageRoles = ['buyer', 'seller', 'supplier', 'broker', 'agency'];
				// if(!missingImageRoles.includes(scope.type)) {
				// 	scope.type = '';
				// }
				// if(scope.image && scope.image.id == 'SMARTBROKR'){
				// 	// scope.image.photoUrl = '/public/img/smartBot.png';
				// 	scope.url = '/public/img/smartBot.png';
				// }
				// else if(scope.image && scope.image.url) {
				// 	// FileService.doImageUrlGrab($scope.image.url).then((data) => {
				// 	// 	$scope.url = data;
				// 	// }, (error) => {
				// 	// 	// console.log('error', error);
				// 	// });
				// 	scope.url = scope.image.url;
				// }
				// else if(scope.image && scope.image.photoUrl) {
				// 	// FileService.doImageUrlGrab($scope.image.photoUrl).then((data) => {
				// 	// 	$scope.url = data;
				// 	// }, (error) => {
				// 	// 	// console.log('error', error);
				// 	// });
				// 	scope.url = scope.image.photoUrl;
				// }
				// else {
				// 	scope.photoUrl = '/public/img/' + scope.type + '400.png';
				// 	scope.url = '/public/img/' + scope.type + '400.png';
				// 	// scope.url = '/public/img/smartBot.png';
				// }
			},
			controller: function($scope, $sce, FileService) {
				const missingImageRoles = ['agency', 'broker', 'buyer', 'listing', 'seller', 'supplier'];
				$scope.errorImage = $scope.type && missingImageRoles.includes($scope.type) ? '/public/img/' + $scope.type + '400.png' : '/public/img/missingFileIcon.png';
				$scope.$watch('image', (newValue, oldValue) => {
					if(newValue && newValue.id == 'SMARTBROKR'){
						$scope.url = '/public/img/smartBot.png';
						$scope.$apply();
					} else if(newValue && newValue.url) {
						FileService.doImageUrlGrab(newValue.url).then((data) => {
							$scope.url = $sce.trustAsResourceUrl(data);
							$scope.$apply();
						});
					}
					else if(newValue && newValue.photoUrl) {
						FileService.doImageUrlGrab(newValue.photoUrl).then((data) => {
							$scope.url = $sce.trustAsResourceUrl(data);
							$scope.$apply();
						});
					}
				});
			}
		};
	})

	.directive('profileBasic', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/partials/profile-basic-info.html',
			scope: {
				person: '=',
				type: '@',
				brokerStatus: '=',
				agencyCert: '=',
				expires: '='
			},
			link: function (scope, element, attrs) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;

				const w = scope.$watch('expires', (val) => {
					if (val) {
						scope.expired = moment(val).isBefore(moment());
						w();
					}
				})
			}
		};
	})

	.directive('profileCoBuyer', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/profile/partials/profile-co-buyer.html',
			scope: {
				person: '=',
				showCompanyName: '=?'
			},
			link: function (scope, element, attrs) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
			}
		};
	})

	.directive('editPhones', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/partials/profile-edit/phones.html',
			scope: {
				phones: '=',
				check: '=',
				form: '='
			},
			link: function(scope, element, attrs) {
				scope.phoneNumberParser = function(input) {
					for (const key of Object.keys(input)) {
						input[key] = input[key].replace(/[a-zA-Z\!\@\#\$\%\^\&\*\_\=\+\`\~\[\{\]\}\\\|\;\:\'\"\,\<\.\>\/\?]+/g, '');
						if (input[key].length > 14) {
							input[key] = input[key].substring(0, 14);
						}
					}
				};
			},
		};
	})

	.directive('addCoBuyer', () => {
		return {
			restrict: 'E',
			templateUrl: '/js/src/templates/partials/profile-edit/co-buyer.html',
			scope: {
				coBuyer: '='
			}
		};
	})

	.directive('editNameEmail', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/partials/profile-edit/name-email.html',
			scope: {
				user: '=',
				check: '=',
				form: '=',
				emailRequired: '=',
				emailDisabled: '=',
				emailField: '=?',
				showSecondaryEmail: '=?',
				showCompanyName: '=?'
			},
			link: function (scope, element, attrs) {
				const sbController = scope.$root.$$childHead.smartbrokrController;
				scope.langs = sbController.getGlobalVar('languageOptions');
				scope.config = {
					create: false,
					valueField: 'value',
					labelField: 'name',
					searchField: 'name',
					sortField: 'name',
					delimiter: '|',
					plugins: ['remove_button']
				};

				if (!attrs.emailField) {
					scope.emailField = null;
				}

				const setDefaultValues = () => {
					if (scope.user) {
						scope.user.languageIds = ['EN'];
					} else {
						setTimeout(() => setDefaultValues(), 100);
					}
				}
				setDefaultValues();
			}
		};
	})

	.directive('dashboardBuyers', () => {
		return {
			restrict: 'E',
			templateUrl: 'dashboard/dashboard-buyers.html'
		};
	})

	.directive('dashboardSellers', () => {
		return {
			restrict: 'E',
			templateUrl: 'dashboard/dashboard-sellers.html'
		};
	})

	.directive('dashboardSuppliers', () => {
		return {
			restrict: 'E',
			templateUrl: 'dashboard/dashboard-suppliers.html'
		};
	})

	.directive('dashboardListings', () => {
		return {
			restrict: 'E',
			templateUrl: 'dashboard/dashboard-listings.html'
		};
	})

	.directive('focusRequired', () => {
		return {
			restrict: 'A',
			link: function (scope, element, attr) {
				element.on('submit', () => {
					const invalid = element.find('.ng-invalid').first();

					if (invalid[0]) {
						try {
							const parent = invalid.parents('.form-group')[0];
							parent.scrollIntoView();
							invalid.focus();
						}
						catch(err) {}
					}
				})
			}
		};
	})

	.directive('popoverMenuList', () => {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				scope.limitedAccess = scope.$root.limitedAccess;
				scope.list = scope.$eval(attrs.popoverMenuList);
			}
		};
	})

	.directive('adminError', () => {
		return {
			restrict: 'E',
			scope: {
				error: '='
			},
			link: function(scope, elm, attrs) {
				scope.closeError = function() {
					scope.error.hide = true;
				}
			},
			templateUrl: 'admin/templates/error.html'
		};
	})

	.directive('comma', () => {
		return {
			restrict: 'E',
			template: '<div style="display: inline-block; padding: 0 2px">,</div>'
		};
	})

	.directive('agencyProfileDetails', () => {
		return {
			restrict: 'E',
			templateUrl: 'admin/agencies/agency.profile.details.html'
		};
	})

	.directive('agencyProfileEdit', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/partials/agency.profile.edit.html'
		};
	})

	.directive('createFolderForm', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/partials/create-folder-form.html',
			scope: {
				createFolder: '=',
				folder: '=',
				includeRequired: '=?'
			}
		}
	})

	.directive('eventPerson', ($filter) => {
		return {
			restrict: 'E',
			templateUrl: 'templates/partials/event-person.html',
			scope: {
				fullName: '=',
				isCoBuyer: '=',
				last: '=',
				person: '=',
				photo: '=?',
				status: '='
			},
			controller: function($sce, $scope, FileService) {
				if($scope.photo) {
					$scope.photo = $sce.valueOf($sce.trustAsResourceUrl(FileService.getImageUrl($scope.photo)));
				} else {
					if($scope.person.ownedSuppliers) {
						if($scope.person.ownedSuppliers.length > 0) {
							// TODO: Figure out why this does not resolve into a S3 signed url, maybe it just doesn't work with background image urls?
							$scope.photo = $sce.valueOf($sce.trustAsResourceUrl(FileService.getImageUrl($scope.person.ownedSuppliers[0].photoUrl)));
						} else {
							$scope.photo = $sce.valueOf($sce.trustAsResourceUrl(FileService.getImageUrl($scope.person.photoUrl)));
						}
					} else {
						$scope.photo = $sce.valueOf($sce.trustAsResourceUrl(FileService.getImageUrl($scope.person.photoUrl)));
					}
				}

				$scope.$watch('person', () => {
					if($scope.photo) {
						$scope.photo = $sce.valueOf($sce.trustAsResourceUrl(FileService.getImageUrl($scope.photo)));
					} else {
						if($scope.person.ownedSuppliers) {
							if($scope.person.ownedSuppliers.length > 0) {
								$scope.photo = $sce.valueOf($sce.trustAsResourceUrl(FileService.getImageUrl($scope.person.ownedSuppliers[0].photoUrl)));
							} else {
								$scope.photo = $sce.valueOf($sce.trustAsResourceUrl(FileService.getImageUrl($scope.person.photoUrl)));
							}
						} else {
							$scope.photo = $sce.valueOf($sce.trustAsResourceUrl(FileService.getImageUrl($scope.person.photoUrl)));
						}
					}
				});

				// if($scope.person && $scope.person.photoUrl) {
				// 	$scope.person.photoUrl = $sce.valueOf($sce.trustAsResourceUrl(FileService.getImageUrl($scope.person.photoUrl)));
				// }
			},
			link: function(scope, element, attrs) {
				scope.phone = $filter('getOnePhone')(scope.person.phones);
			},
		}
	})

	.directive('videoThumbnail', () => {
		return {
			restrict: 'E',
			scope: {
				height: '@',
				width: '@',
				vWidth: '@',
				vHeight: '@',
				videoSrc: '='
			},
			link: function(scope, element, attrs) {

				const w = scope.$watch('videoSrc', (src) => {
					if (!!src) {
						const width   = scope.width || 40;        // Result width
						const height  = scope.height || 40;       // Result height
						const vWidth  = scope.vWidth || 640;      // Video width
						const vHeight = scope.vHeight || 480;     // Video height

						const ratio   = width / height;           // Result ratio
						const viewWidth   = _getLength(ratio, vWidth, vHeight);
						const viewHeight  = _getLength(ratio, vHeight, vWidth);

						const videoHtml   = '<video width="' + width + '" height="' + height + '" style="display:none"/>';
						const canvasHtml  = '<canvas width="' + width + '" height="' + height + '" />';

						const canvas      = angular.element(canvasHtml).get(0);
						const video       = angular.element(videoHtml).get(0);

						video.currentTime = 1;
						video.src = scope.videoSrc;

						video.addEventListener('loadeddata', () => {
							canvas.getContext('2d').drawImage(video, viewWidth.start, viewHeight.start, viewWidth.end, viewHeight.end, 0, 0, width, height);
							video.remove();
						});

						element.append(canvas);
						w();
					}
				})

				function _getLength(ratio, original, other) {
					let size = original;

					if (original > other) {
						size = other * ratio;
					}
					else if (other > original) {
						size = original / ratio;
					}

					const diff = Math.abs(original - size);
					const start = diff / 2;

					return {
						start: start,
						end: size
					}
				}
			}
		}
	})
	.filter('searchFilter', () => {
		return function(files, searchText) {
			if(!searchText || searchText === '') return files;
			const searchRegex = new RegExp(searchText, 'i');
			return files.filter((value, index, array) => {
				if(searchRegex.test(value.originalFilename)) {
					return value;
				}
			});
		}
	});
})();
