/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('AddBuyerController', function (check, $filter, $rootScope, $scope, $stateParams, GlobalVars, AlertService, BuyerService, UserService, NavService, AccountService, StorageService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		let editing;

		self.newBuyer 		= null;			// Person that will be added (SbUser)
		self.buyerProfile	= null;			// SbUser's buyerProfile (Buyer)
		self.propertyTypes	= null;			// Possible property types
		self.multiConfig	= null;			// Config for selectize (multiple)
		self.selected		= null;			// Used for selecting Country/Province/Region/City
		self.note			= null;			// Note to be saved along with new buyer

		// SETUP VARIABLES ======================================================================================================================

		self.propertyTypes 	= GlobalVars.getCodes('propertyTypes');
		self.multiConfig 	= GlobalVars.multiConfig;
		self.note 			= { text: '' };
		self.newBuyer 		= $stateParams.newPerson;

		self.propertyTypes.shift();			// Remove first option, which is empty

		getAccount();

		// NewBuyer from previous state
		// User can't access this state straight from the URL -> Go back
		if (self.newBuyer == null && $stateParams.id == null) {
			NavService.navigateBack();
		}
		else if ($stateParams.id != null) {
			BuyerService.getUserAndBuyer($stateParams.id).then((res) => {
				if (!self.newPerson) {
					self.buyerProfile = res;
				}
				self.newBuyer = res.user;


				if(!self.newBuyer.cityIds) {
					self.newBuyer.cityIds = [];
				}

				check.clone = {
					profile: self.buyerProfile,
					buyer: self.newBuyer
				}

				check.original = angular.copy(check.clone);
			})
			editing = true;
		}
		else if ($stateParams.savedProp != null) {
			self.buyerProfile = $stateParams.savedProp;
			check.clone = {
				profile: self.buyerProfile,
				buyer: self.newBuyer
			}
			check.original = angular.copy(check.clone);
		}
		else {
			if (!self.newBuyer.buyerProfile) {
				// Buyer object was sent instead of SbUser
				if (!self.newBuyer.firstName) {
					self.buyerProfile = angular.copy(self.newBuyer);
					delete self.buyerProfile.user;

					self.newBuyer = angular.copy(self.newBuyer.user);
				}
				else {
					if (self.newBuyer.id != undefined) {
						self.buyerProfile = { sbUserId: self.newBuyer.id, cityIds: [] };
					}
					else {
						self.buyerProfile = { cityIds: [] };
					}
				}
			}
			else {
				self.buyerProfile = self.newBuyer.buyerProfile || {};
				self.buyerProfile.cityIds = [];
			}

			check.clone = {
				profile: self.buyerProfile,
				buyer: self.newBuyer
			}

			check.original = angular.copy(check.clone);
		}

		// FUNCTIONS ============================================================================================================================

		// Used in the mobile version
		self.goToStackItem = function (i) {
			if (!!self.newBuyer && self.newBuyer.id) {
				const id = self.newBuyer.id;
				NavService.navigateStack(i, { id: id, role: 'buyer', menu: $rootScope.BUYER_INDEX });
			}
			else {
				NavService.navigateStack(i, { newPerson: self.newBuyer, role: 'buyer', menu: $rootScope.BUYER_INDEX });
			}
		}

		// Save
		self.save = function (state, params) {
			// During this part, user can only select one province/city/area, but they can have several
			if (!self.buyerProfile.cityIds) {
				self.buyerProfile.cityIds = [];
			}

			if((self.selected.cityIds && self.selected.cityIds.length > 0) && (!self.buyerProfile.cityIds || self.buyerProfile.cityIds.length === 0)) {
				self.buyerProfile.cityIds = self.selected.cityIds;
			}

			if (!self.selected.cityId && self.buyerProfile.cityIds.length === 0) {
				// Sending it to citySelector directive
				$rootScope.$broadcast('showWarnings', true);
				return
			}

			if (!($scope.buyerForm || {}).$valid) return;

			check.isSaved = true;
			AlertService.loading();

			if (self.selected.cityId) {
				self.buyerProfile.cityIds.push(self.selected.cityId);
			}

			if ((self.newBuyer || {}).buyerProfile) {
				delete self.newBuyer.buyerProfile;
			}
			self.buyerProfile.person = angular.copy(self.newBuyer);
			BuyerService.saveBuyer(self.buyerProfile).then((res) => {
				StorageService.destroyBuyer();
				if (self.note.text != undefined && self.note.text != '') {
					const note = self.note;
					note.header = $filter('translate')('ROLES.BUYER') + ': ' + (self.newBuyer.fullName || (self.newBuyer.firstName + ' ' + self.newBuyer.lastName));
					note.created = new Date();
					note.aboutId = res.sbUserId;
					note.aboutType = 'SbUser';

					UserService.saveNote(note)
					.then((note) => {})
					.catch((err) => {})
				}

				params = null;

				if (state.includes('profile')) {
					params = { id: res.id, role: 'buyer' };
				}
				else {
					params = { id: res.id, buyer: res, };
				}
				$rootScope.$emit('showWarnings', false);

				AlertService.doneLoading();
				AlertService.success('ROLES.BUYER', 'save');

				if (editing && state.includes('profile')) {
					NavService.navigateStack(NavService.navStack.length - 2);
				}
				else {
					NavService.resetStack();
					NavService.navigate(state, params);
				}
			})
			.catch((err) => {
				AlertService.doneLoading();
			})
		}

		self.saveOptions = [
			{
				text: 'FORMS.SAVE_SEARCH',
				call: self.save,
				params: 'main.buyer.search-listings'
			},
			{
				text: 'FORMS.SAVE_BUYER',
				call: self.save,
				params: 'main.profile'
			}
		]

		function getAccount() {
			AccountService.getAccountDetails()
			.then((res) => {
				self.selected = {
					country: (((res.user || {})._address || {}).country || null),
					province: (((res.user || {})._address || {}).province || null)
				};
			})
			.catch((err) => {})
		}

		$scope.validatePriceRange = (input, maxPrice) => {
			if (maxPrice) {
				if (typeof input.minPrice !== 'object' && input.maxPrice < input.minPrice) {
					input.maxPrice = input.minPrice;
				}
			} else {
				if (typeof input.maxPrice !== 'object' && input.minPrice > input.maxPrice) {
					input.minPrice = input.maxPrice;
				}
			}
		}

	}) // End of controller
})(); // End of function()
