(function () {

	"use strict";

	angular.module("smartbrokr")

		.controller("PreferencesController", function (AccountService, AlertService, $rootScope) {

			var self = this;
			self.account = null;
			self.getAccount = function () {
				AccountService.getCurrentAccount()
					.then(function (res) {
						self.account = res;
					})
					.catch(function (err) {})
					.finally(function(){
						$rootScope.$emit("loadingEnd");
					})
			}

			self.savePreferences = function () {
				AccountService.savePreferences(self.account.automatedEmails, self.account.minNumberLogs, self.account.confirmationEmail)
					.then(function (res) {
						self.getAccount();
						AlertService.success('PAGES.SETTINGS.SUB.PREFERENCES', 'save');
					})
					.catch(function (err) {});
			}
				self.getAccount();
		});

})(); // End of function()
