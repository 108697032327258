(function () {

  "use strict";

  angular.module("smartbrokr")

    .controller("ManagerController", function ($uibModalInstance, $scope, $translate, AccountService, GlobalVars, ListingService, UserService, manager, isNew, managers) {
      var self = this;

      var userId          = AccountService.getUserId();
      self.manager        = angular.copy(manager);
      self.isNew          = isNew;
      self.personConfig   = GlobalVars.createMultiConfig(false, "id", "fullName", true);
      self.propertyConfig = GlobalVars.createMultiConfig(false, "id", "address", true);
      self.clientConfig   = GlobalVars.createMultiConfig(false, "id", "fullName", true);

      self.loading  = {
        people: true,
        properties: true,
        other: false
      };

      $uibModalInstance.scope = $scope;
    
      $translate(['PERSON.ALL_CLIENTS_SELECTED', 'LISTING.ALL_LISTINGS_SELECTED']).then(function(res){
        self.allClients   = res['PERSON.ALL_CLIENTS_SELECTED'];
        self.allListings  = res['LISTING.ALL_LISTINGS_SELECTED'];
      })

      getAgencyPeople();
      getClients();
      getProperties();

      self.close = function () {
        $uibModalInstance.dismiss('cancel');
      };

      self.saveManager = function () {
        self.loading.other = true;
        if (self.manager.managerId.length == 1) {
          var manager = {
            managerId: self.manager.managerId[0] || null,
            clientIds: self.manager.clientIds || [],
            listingIds: self.manager.listingIds || [],
            allListings: !!self.manager.allListings,
            allClients: !!self.manager.allClients
          }
          if(self.manager.id){
            manager.id = self.manager.id;
          }
          saveManager(manager);
        }
        else if (self.manager.managerId.length > 1){
          for (var i in self.manager.managerId) {
            var manager = {
              managerId: self.manager.managerId[i] || null,
              clientIds: self.manager.clientIds || [],
              listingIds: self.manager.listingIds || [],
              allListings: !!self.manager.allListings,
              allClients: !!self.manager.allClients,
            }
            if(self.manager.id){
              manager.id = self.manager.id;
            }
            saveManager(manager);
          }
        }
      }

      self.toggleSelectAllClients = function(){
        self.manager.allClients = !self.manager.allClients;
        self.manager.clientIds = [];
      }

      self.toggleSelectAllListings = function(){
        self.manager.allListings = !self.manager.allListings;
        self.manager.listingIds = [];
      }

      function getAgencyPeople() {
        self.loading.agencyPeople = true;
        self.filteredAgencyPeople = [];
        var managerIds = (managers || []).reduce(function(arr,curr) {
          arr.push(curr.managerId);
          return arr;
        }, []);

        UserService.getAll().then(function (res) {
          self.filteredAgencyPeople = (res.people || []).reduce(function(arr, curr) {
            if (curr.id !== userId && (managerIds.indexOf(curr.id) < 0 || !isNew)) {
              arr.push(curr);
            }
            return arr;
          }, []);
          self.loading.agencyPeople = false;
        })
        .catch(function (err) {})
        .finally(function() {
          $uibModalInstance.check = {
            original: angular.copy(self.manager),
            clone: self.manager
          }
        })
      }

      function getClients() {
        self.loading.myPeople = true;
        self.filteredMyPeople = [];
        UserService.getAllMine(false, false, true)
          .then(function (res) {
            self.filteredMyPeople = res;
            self.loading.myPeople = false;
          })
          .catch(function (err) {

          })
      }

      function getProperties() {
        self.loading.property = true;
        self.filteredProperties = [];
        ListingService.getInventory()
          .then(function (res) {
            var i, length = res.length;
            for (i = 0; i < length; i++) {
              if (res[i].property && res[i].property._address) {
                res[i].address = res[i].property._address.addressString;
              }
            }
            self.loading.property = false;
            self.filteredProperties = res;
          })
          .catch(function (err) {

          })
      }

      function saveManager(manager) {
        if (!!self.isNew) {
          UserService.createManager(manager)
            .then(function (res) {
              $uibModalInstance.close({ closed: true });
              self.loading.other = false;
            })
            .catch(function (err) {})
        }
        else if (!self.isNew) {
          UserService.updateManager(self.manager.id, manager)
            .then(function (res) {
              $uibModalInstance.close({ closed: true });
              self.loading.other = false;
            })
            .catch(function (err) {})
        }
        else {
          self.close();
        }
      }

    }) // End of controller
})(); // End of function()
