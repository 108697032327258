(function () {

    "use strict";

    angular.module("smartbrokr")
        .controller("EditPhotoController", function ($uibModalInstance, files, $q, ListingService, $state) {

            var self = this;
            self.files = angular.copy(files);

            for (var i in self.files) {
                self.files[i].oldValue = files[i].originalFilename || files[i].name;
                self.files[i].originalFilename = "";
            }

            self.close = function () {
                $uibModalInstance.dismiss('cancel');
            };

            self.save = function () {
                var promises = [];
                for (var i in self.files) {
                    var id = angular.copy(self.files[i].id);
                    delete self.files[i].hover;
                    delete self.files[i].selected;
                    delete self.files[i].id;
                    delete self.files[i].oldValue;
                    var extension = self.files[i].name.substring(self.files[i].name.lastIndexOf('.'));

                    if (!self.files[i].originalFilename.endsWith(extension))
                        self.files[i].originalFilename += extension;

                    var promise = ListingService.updateFile($state.params.id, id, self.files[i]);
                    promises.push(promise);
                }

                $q.all(promises)
                    .then(function (res) {
                        $uibModalInstance.close(res);
                    })
                    .catch(function (err) {});
            }
        }) // End of controller
})(); // End of function()
