/**
 * © copyright 2017, 2818779 Canada Inc.
 * 
 * Use of this software is only permitted if you have entered into a
license agreement with 2818779 Canada Inc. and is subject to the terms
and conditions of such license agreement.
 *
 * @Filename: accountService.js
 * @Author: Victoria Jurkfitz <arcticempirea>
 * @Date:   21-06-2017 06:13
 * @Project: Smartbrokr by Arctic Empire inc.
 * @Last modified by:   arcticempirea
 * @Last modified time: 28-06-2017 10:57
 */



(function () {

  "use strict";

  angular.module("smartbrokr.manager", [])

    .service("ManagerService", function (Manager,SbUser, AccountService) {
      var self = this;

      self.getManaging = function(){
          var filter = {
              include:{manager:{managing:"brokerProfile"}}
          };
          return SbUser.findById({id:AccountService.getUserId(),filter:filter}).$promise;
      }
    });
})(); // End of function()
