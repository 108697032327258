(function () {

    "use strict";

    angular.module("smartbrokr")

    .controller("InviteBrokersController", function ($state, $stateParams, AccountService, GlobalVars, PaymentService, $q, $scope, $timeout, $translate) {
        
        var self = this;
        self.visitedReview  = AccountService.getVisitedReview();
        self.data           = AccountService.getInviteBrokers() || "";
        self.brokerEmails   = [];
        self.warning        = '';

        PaymentService.getAgencyBrokerPlans().then(function(res){
            var plan = res.find(function(p){ return (p || {}).frequency === 'm'; }) || {};
            self.page = {
                numberOfBrokers: 1,
                monthlyCost: (plan.price || 1999) / 100
            }
            self.update();
        })

        $translate('PAGES.SIGNUP.INVITE_BROKERS.PLACEHOLDER')
        .then(function(res){
            self.placeholder = res;
        })
        .catch(function(err){

        })

        self.next = function () {
            self.update(true);

            GlobalVars.runPromises(AccountService.isEmailRegistered, self.brokerEmails).then(function(res){
                self.emailError = {
                    isError: false,
                    emails: []
                };
                for (var email in res) {
                    if (res[email].response === true) {
                        if (!self.emailError.isError) {
                            self.emailError.isError = true;
                        }
                        self.emailError.emails.push({ address: email, show: true});
                    }
                }
                if (!self.emailError.isError) {
                    AccountService.saveInviteBrokers(self.data);
                    $state.go('main.signup.invite-staff');
                }
            })
        }

        self.prev = function () {
            var lastState = $stateParams.system === 'centris' ? 'main.signup.permissions-centris' : 'main.signup.permissions-realtor';
            self.update(true);
            AccountService.saveInviteBrokers(self.data);
            $state.go(lastState);
        }

        self.count = function () {
            self.page.numberOfBrokers = self.brokerEmails ? self.brokerEmails.length : 0;
            self.page.cost = (self.page.numberOfBrokers * self.page.monthlyCost);
        }

        self.update = function (updateData) {
            var emails = self.data.split(',');
            self.brokerEmails = [];

            emails.forEach(function(email,i) {
                email = (email || '').trim();
                if (email !== '' && self.brokerEmails.indexOf(email) < 0) {
                    self.brokerEmails.push(email);
                }
                else if (email !== '') {
                    self.data = self.data.replace(email + ',','');
                    self.warning = 'Removed duplicated email: ' + email;
                }
            })

            $timeout(function(){
                self.warning = '';
            }, 10000);

            if (updateData) {
                self.data = self.brokerEmails.toString();
            }

            if (!!self.emailError && typeof self.emailError == 'object') {
                var isStillError = false;

                if (self.emailError.emails.length > 0) {
                    self.emailError.emails.forEach(function(email){
                        if (self.brokerEmails.indexOf(email.address) < 0) {
                            email.show = false;
                        }
                        else {
                            email.show = true;
                            isStillError = isStillError || true;
                        }
                    });

                    self.emailError.isError = isStillError;
                }
            }

            self.count();
        }
    });
})();
