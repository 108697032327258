/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.directives')

	/**
     *  Creates a button to be used as a date input
     */
	.directive('btnCalendar', () => {
		return {
			restrict: 'E',
			scope: {
				placeholder: '@',       // Displayed as button text if no date is selected
				calendar: '=',          // datepicker-options for uib-datepicker-popup
				onChange: '=',          // ngChange
				parent: '=',            // ngModel = parent[attr]
				attr: '@'
			},
			templateUrl: './js/directives/btn-calendar/btn-calendar.html',
		}
	})

	/**
     *  Creates a group of two calendar buttons,
     *  one for the minimum date and the other for the maximum date
     */
	.directive('btnCalendarGroup', (GlobalVars) => {
		return {
			restrict: 'E',
			scope: {
				doFilter: '=',      // Passed to each calendar's ngChange
				filters: '=',       // The object that will save dateMin and dateMax
				attrMin: '@',       // dateMin = filters[attrMin]
				attrMax: '@',       // dateMax = filters[attrMax]
				controller: '=?',   // Not required. Used to communicate between two btnCalendarGroups on the incomplete tasks page
				type: '@?',         // Not required. Used to save this scope to the controller
				minDate: '=',       // If set, calendarMin will always have this as minDate and calendarMax will either have dateMin or this as minDate
				maxDate: '='        // If set, calendarMax will always have this as maxDate and calendarMin will either have dateMax or this as maxDate
			},
			templateUrl: './js/directives/btn-calendar/btn-calendar-group.html',
			link: function($scope, element, attrs) {
				if (attrs.controller === 'incompleteTasksController' && !!attrs.type) {
					$scope.controller.calendars[$scope.type] = $scope;
				}

				const MAX_DATE = $scope.type === 'created' ? new Date() : null;

				$scope.calendarMin = GlobalVars.createCalendarConfig(null, MAX_DATE);
				$scope.calendarMax = GlobalVars.createCalendarConfig(null, MAX_DATE);

				const w1 = $scope.$watch('calendarMin.opened', (isOpen) => {
					if (isOpen) {
						$scope.calendarMax.open = false;
					}
				})

				const w2 = $scope.$watch('calendarMax.opened', (isOpen) => {
					if (isOpen) {
						$scope.calendarMin.open = false;
					}
				})

				const w3 = $scope.$watch('filters[attrMax].value', (maxDate) => {    // minSelected < maxSelected
					$scope.calendarMin.options.maxDate = _compare(maxDate || MAX_DATE, $scope.maxDate, 'isBefore');
				}, true)

				const w4 = $scope.$watch('filters[attrMin].value', (minDate) => {    // maxSelected > minSelected
					$scope.calendarMax.options.minDate = _compare(minDate, $scope.minDate, 'isAfter');
				}, true)

				const w5 = $scope.$watch('minDate', (minDate) => {       // minDate < minSelected < maxSelected
					$scope.calendarMax.options.minDate = _compare(minDate, $scope.filters[$scope.attrMin].value, 'isAfter');
					$scope.calendarMin.options.minDate = minDate;
				})

				const w6 = $scope.$watch('maxDate', (maxDate) => {       // maxDate > maxSelected > minSelected
					$scope.calendarMax.options.maxDate = maxDate;
					$scope.calendarMin.options.maxDate = _compare(maxDate, $scope.filters[$scope.attrMax].value, 'isBefore');
				})

				$scope.$on('$destroy', () => {
					w1(); w2(); w3(); w4(); w5(); w6();     // Cleanup listeners
				})

				/**
                 *  Compares two dates and returns the first if it passes the test passed as 'compare'
                 *  @param {string|object}  a           A date to be compared. If moment(a) passes a test against b, a is returned
                 *  @param {string|object}  b           A date to be compared. If moment(a) does not pass a test against b, b is returned
                 *  @param {string}         compare     The name of the function (from moment) used to test a against b
                 *  @return {string|object}             a or b
                 */
				function _compare(a, b, compare) {
					if (!a && !b) return a;
					if (!a) return b;
					if (!b) return a;
					return moment(a)[compare](b) ? a : b;
				}
			}
		}
	})
})();
