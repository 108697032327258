/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.directives')

	/**
     *  Creates an overlay element. Includes buttons (icons) if required.
     */
	.directive('sbOverlay', () => {
		return {
			restrict: 'E',
			scope: {
				icons: '='
			},
			templateUrl: './js/directives/sb-overlay/sb-overlay.html'
		}
	})
})();
