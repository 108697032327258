/* eslint-disable no-undef */
/**
 *
 */

(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('LoginController', function (RoleService, $sce, LanguageService, StorageService, AccountService, AlertService, $state, $stateParams, ModalService, $rootScope, GlobalVars, $translate, $window, $filter) {
		const self = this;

		self.managing           = [];
		self.roles              = [];           // Options for user to select current role from
		self.availableRoles     = [];           // Roles that user can login as -> Used to switch roles from main menu
		self.choosingRole       = false;
		self.step               = 1;

		const auditorProfiles     = {};
		$rootScope.inLoginPage  = true;
		$rootScope.pushContent  = false;

		self.auditorConfig  = GlobalVars.createSingleConfig(false, 'value', 'label', 'label');
		self.notaryConfig   = GlobalVars.createSingleConfig(false, 'value', 'label', 'label');
		self.roleConfig     = GlobalVars.createSingleConfig(false, 'role', 'translation', 'translation');
		self.userConfig     = GlobalVars.createSingleConfig(false, 'managingId', 'fullName', 'fullName');

		AccountService.clearUser();

		// FUNCTIONS ============================================================================================================================

		/*
            Calls login API and handles response for number of profiles and setting the next page that the user sees.
        */
		self.login = function (email, password) {
			self.step = 1;

			AccountService.login(email, password).then((res) => {
				$rootScope.danger = {};
				self.account    = res.account;
				self.user       = res.account.user;

				setupLanguage().then(() => {
					const roles = getRoleTranslations(res.roles);
					self.roles = angular.copy(roles);
					self.availableRoles = angular.copy(res.roles);

					if (res.numberOfProfiles === 0) {       // No profiles -> Error
						self.error = true;
						AlertService.errorMessage('noRoles');
						return;
					}
					else if (res.numberOfProfiles === 1) {  // Only one profile -> Nothing to choose
						setupAccount(self.account, self.user, res.roles[0], res.roles[0].type);
					}
					else {      // More than one profile -> Go to step 2, where user chooses which to profile to login as
						self.step = 2;
					}
				});
			})
			.catch((err) => {
				//console.error(err);
				self.error = true;
			})
		}

		/*
            Sets step and resets state
        */
		self.resetForm = function (step) {
			$rootScope.danger = {};
			if (step == 2)
				self.step = 2;
			else
				AccountService.logout(true);
		}

		/*
            Called when selecting a role. If requiring no further information it calls API.
            Otherwise it will setup brokers or agencies.
        */
		self.chooseRole = function (role) {
			for (let i = 0; i < self.roles.length; i++) {
				if (self.roles[i].role == role) {
					role = self.roles[i];
					break;
				}
			}

			setupAccount(self.account, self.user, role, role.type);
		}

		/*
            Calls API to choose role. If manager or admin it will setup brokers/agencies.
            Otherwise navigate to the dashboard.
        */
		function setupAccount(account, user, role, type) {
			AccountService.chooseRole(role.role).then((res) => {
				self.managing = [];
				AccountService.setAccessToken(res.id);
				if (res.type == 'managerProfile') {
					self.managing = ((res.role || {}).profiles || []).reduce((arr, curr) => {
						if ((curr.managing || {}).brokerProfile && (curr.managing || {}).account) {
							curr.fullName = (curr.managing || {}).fullName;
							arr.push(angular.copy(curr));
						}
						return arr;
					}, []);

					if (self.managing.length === 0) {
						$translate('PAGES.LOGIN.MANAGER_NO_BROKERS').then((res) => {
							self.userConfig.placeholder = res;
						})
					}

					self.selectingManager = true;
					self.step = 3;
				}
				else if (res.type === 'auditorProfile' || res.type === 'notaryProfile') {
					self.agencies = [];
					const agencyIds = [];
					for (let i = 0; i < res.role.profiles.length; i++) {
						agencyIds.push(res.role.profiles[i].agencyId);
					}

					RoleService.getAgencies(agencyIds)
					.then((names) => {
						self.agencyNames = {};

						for (let i = 0; i < res.role.profiles.length; i++) {
							if (!res.role.profiles[i].disabled && moment(res.role.profiles[i].expires || res.role.profiles[i].expiry).isAfter(moment())) {
								const thisId = res.role.profiles[i].agencyId;
								self.agencies.push({ value: thisId, label: names[thisId] });
								auditorProfiles[thisId] = res.role.profiles[i];
							}
						}
						if (self.agencies.length < 1) {
							AlertService.errorMessage('ALERT_MESSAGES.ERROR.SERVER.Account.login.420');
							self.error = true;
						}
						else {
							self.step = 3;
							self.selectedRole = res.type;
							self.selectingAuditor = true;
						}
					})
				}
				else {
					AccountService.setupRole(res, account, self.availableRoles, user);
					AccountService.goToRoleLandingPage();
				}
			})
			.catch((err) => {
				console.log('err: ', err);
			})
		}

		/*
            Calls API with your role and who you're managing to get a new token
        */
		self.chooseManaging = function (user) {

			const managerUser = {
				id: user
			};
			AccountService.chooseRole('SB_MANAGER', user).then((res) => {
				AccountService.setupManager(res, self.availableRoles, managerUser, self.account, 'managerProfile');
				AccountService.goToRoleLandingPage();
			})
			.catch((err) => {
			})
		}

		/*
            Sets agencies that are shown to auditors
        */
		self.chooseAuditorAgency = function (id) {
			let role;

			if (self.selectedRole === 'notaryProfile') {
				role = 'SB_NOTARY';
			}
			else {
				role = 'SB_AUDITOR';
			}

			AccountService.chooseRole(role)
			.then((res) => {
				AccountService.setupAuditor(res, self.account, self.availableRoles, self.user, id, self.selectedRole)
				AccountService.goToRoleLandingPage();
			})
			.catch((err) => {
			})
		}

		/*
            Call API to reset password
        */

		self.openReset = function () {
			const controller = function ($uibModalInstance, $scope) {
				this.resetEmail = self.resetEmail;
				if ((self.email || '').length > 0) {
					self.resetEmail = angular.copy(self.email);
				}

				this.resetPassword = function () {
					self.resetEmail = this.resetEmail;
					AccountService.isEmailRegistered(self.resetEmail).then((res) => {
						if ((res || {}).response) {
							AccountService.resetPassword(self.resetEmail)
							.then((res) => {
								$scope.hideForm = true;
								$scope.showWarning = false;
							})
							.catch((err) => {})
						}
						else {
							$scope.showWarning = true;
						}
					})
				}
				this.close = function () {
					$uibModalInstance.dismiss('cancel');
				}
			}
			ModalService.openModal('resetPassword', {}, controller, 'loginController', null, 'reset-password', 'less-opaque');
		}

		self.goBack = function() {
			if ($stateParams.internal) {
				$window.history.back();
			}
			else {
				$state.go('main.public.home');
			}
		}

		function setupLanguage(lang) {
			lang = lang || 'EN';

			if ((self.account || {}).mainLanguageId) {
				lang = self.account.mainLanguageId;
			}
			else if ((self.user || {}).languageIds) {
				lang = self.user.languageIds[0];
			}

			if ($rootScope.TRANSLATABLE.indexOf(lang) < 0) {
				lang = 'EN';
			}

			StorageService.setLanguage(lang);
			return LanguageService.setLanguage(lang).then(() => {
				$translate(['LOGIN.AUDITOR_CHOOSE', 'LOGIN.NOTARY_CHOOSE', 'LOGIN.CHOOSE_ROLE', 'LOGIN.MANAGER_CHOOSE']).then((translations) => {
					self.auditorConfig.placeholder  = translations['LOGIN.AUDITOR_CHOOSE'];
					self.notaryConfig.placeholder   = translations['LOGIN.NOTARY_CHOOSE'];
					self.roleConfig.placeholder     = translations['LOGIN.CHOOSE_ROLE'];
					self.userConfig.placeholder     = translations['LOGIN.MANAGER_CHOOSE'];
				})

				return true;
			});
		}

		function getRoleTranslations(roles) {
			roles = roles || [];

			const schemaDependent = [
				'BROKER',
				'AGENCY_MEMBER',
				'AGENCY_OWNER',
				'MEMBER',
				'OWNER'
			]


			return roles.reduce((arr, role) => {
				if (role) {
					let label = role.translation.label;
					if (schemaDependent.indexOf(label) >= 0) {
						label = 'LOGIN.ROLES.' + (role.translation.realtor ? 'realtor' : 'centris') + '.' + label;
					}
					role.translation = '' + $filter('translate')(label);
					arr.push(role);
				}
				return arr;
			}, []);
		}
	});
})();
