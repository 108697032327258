/**
 *  © copyright 2017, 2818779 Canada Inc.
 *   
 *  Use of this software is only permitted if you have entered into a
 *  license agreement with 2818779 Canada Inc. and is subject to the terms
 *  and conditions of such license agreement.
 *
 */

(function () {

  "use strict";

  angular.module("smartbrokr")

    .controller("ConfirmController", function ($uibModalInstance, message) {

      var self = this;
      self.message = message;
      
      self.close = function () {
        $uibModalInstance.dismiss('cancel');
      };
      self.confirm = function(){
        $uibModalInstance.close(true);
      }
      self.cancel = function(){
        self.close();
      }
      

      /*$scope.$on('modal.closing', function(event, reason, closed) {

        var isEmpty = function(object) {
          for (var cat in object) {
            if ((object[cat] || "").toString().length > 0) {
              return false;
            }
          }
          return true;
        }

        if ((reason == 'backdrop click' || reason == 'cancel') && !isEmpty(self.cost)) {
          if (!confirm('Your changes won\'t be saved. Continue?')) {
            event.preventDefault();
          }
        }
      });*/

    }) // End of controller
})(); // End of function()
