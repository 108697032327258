(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('FolderController', function ($uibModalInstance, $scope, GlobalVars, ListingService, ReportsService, SupplierService, BuyerService, SellerService, UserService, folder, type, options) {
		const self = this;

		self.folder   = {};
		folder        = angular.copy(folder);
		let API       = ReportsService;
		let id        = null;
		self.folders = options.folders || [];

		self.currentFolder = '';

		self.includeRequired = options.includeRequired ? options.includeRequired : false;

		self.action = self.folders.length === 0 ? 'new' : '';

		if (folder && folder.type) {
			switch (folder.type) {
			case 'buyer': API = BuyerService; break;
			case 'seller': API = SellerService; break;
			case 'supplier': API = SupplierService; break;
			case 'listing': API = ListingService; break;
			}
			id = folder.id;
		}
		else if (folder && folder.folder) {
			self.folder = folder.folder;
		} else {
			if(type == 'listing') {
				API = ListingService;
			}
		}

		$uibModalInstance.scope = $scope;
		$uibModalInstance.check = {
			original: angular.copy(self.folder),
			clone: self.folder
		}

		self.close = function () {
			$uibModalInstance.dismiss('cancel');
		};

		function addFileToFolder(res) {
			UserService.assignFileToFolder(options.fileId, res.id)
			.then((fileRes) => {
				$uibModalInstance.close(res);
			})
			.catch((err) => {
				self.error = true;
			})
		}

		self.selectFolder = function() {
			addFileToFolder({ id: self.currentFolder });
		}

		self.createFolder = function () {
			self.error = null;
			if(type === 'listing') {
				API.createFolder(options.listingId, self.folder.name, self.folder.required).then((res) => {
					$uibModalInstance.close(res);
				})
				.catch((err) => {
					self.error = true;
				})
			} else {
				if (folder && folder.folder) {
					ReportsService.updateFolderName(self.folder.id, self.folder.name)
					.then((res) => {
						if(!options.fileId) {
							$uibModalInstance.close(res);
						} else {
							addFileToFolder(res);
						}
					})
					.catch((err) => {
						self.error = true;
					})
				}
				else {
					if (id) {
						API.createFolder(id, self.folder.name, type, self.folder.reqWidth, self.folder.reqHeight)
						.then((res) => {
							if(!options.fileId) {
								$uibModalInstance.close(res);
							} else {
								addFileToFolder(res);
							}
						})
						.catch((err) => {
							self.error = true;
						})
					} else {
						API.createFolder(self.folder.name, type, self.folder.reqWidth, self.folder.reqHeight)
						.then((res) => {
							if(!options.fileId) {
								$uibModalInstance.close(res);
							} else {
								addFileToFolder(res);
							}
						})
						.catch((err) => {
							self.error = true;
						})
					}
				}
			}
		}

	}) // End of controller
})(); // End of function()
