/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('DuplicateListingModalController', function (id, apiCall, listing, $filter, $log, $scope, $timeout, $translate, $uibModalInstance, AccountService, AgencyService, AlertService, BrokerService, GlobalVars, MessageService, ResourceService, UserService) {
		const self = this;

		/**
		 *  Closes the modal window
		 */
		self.close = function (ev) {
			if(ev && ev.preventDefault) {
				ev.preventDefault();
			}
			$uibModalInstance.dismiss('cancel');
		}

		/**
		 *  Creates a new conversation with each recipient selected
		 *  and adds all files to that conversation (if required)
		 */
		self.send = function () {
			AlertService.loading();
			apiCall(id).then((res) => {
				done();
			})
			.catch($log.error);
		}

		/**
		 *  Called after the message is sent and all files have been uploaded.
		 *  Closes the modal.
		 */
		function done() {
			AlertService.doneLoading();
			AlertService.success('MESSAGES.MESSAGE', 'sell');
			$uibModalInstance.close();
		}

	}); // End of controller
})(); // End of function()
