(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('AddSellerController', function ($rootScope, $scope, $stateParams, check, AccountService, AlertService, GlobalVars, ListingService, NavService, SellerService, UserService,StorageService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.newSeller      = null;   // Person that will be added (SbUser)
		self.sellerProfile  = null;   // Seller object attached to SbUser

		self.listing        = null;   // Listing that will be created
		self.property       = null;   // Listing's property
		self.note           = null;   // Note that will be created along with seller profile

		self.propertyTypes  = null;   // Options for Property type dropdown
		self.singleConfig   = null;   // Selectize config (dropdown)

		// SETUP VARIABLES ======================================================================================================================
		self.newSeller      = $stateParams.newPerson;
		self.propertyTypes  = GlobalVars.getCodes('propertyTypes');
		self.singleConfig   = GlobalVars.singleConfig;
		self.property       = {};
		self.listing        = { status: 'Smart' };
		self.note           = { text: '' };

		// User can't access this state straight from the URL -> Go back
		if (self.newSeller == null) {
			NavService.navigateBack();
		}
		else {
			if (!self.newSeller.sellerProfile) {
				self.sellerProfile = {};
			}
			else {
				self.sellerProfile = self.newSeller.sellerProfile;
			}
		}

		AccountService.getAccountDetails().then((res)=> {
			self.property._address = {
				country: (((res.user || {})._address || {}).country || null),
				province: (((res.user || {})._address || {}).province || null)
			};

			check.original = angular.copy(self.property);
			check.clone = self.property;
		})

		// FUNCTIONS ============================================================================================================================

		self.save = function (skipListing) {

			if (!((self.property || {})._address || {}).addressString && !skipListing) {
				$rootScope.$broadcast('showWarnings', true);
			}
			else {
				AlertService.loading();
				check.isSaved = true;

				self.sellerProfile.person = angular.copy(self.newSeller);

				SellerService.saveSeller(self.sellerProfile).then((resSeller) => {
					StorageService.destroySeller();
					if (!skipListing) {
						ListingService.createListing(self.listing).then((resList) => {
							ListingService.addProperty(resList.id, self.property).then((resProp) => {
								ListingService.linkSeller(resList.id,resSeller.id).then((resLinkSeller)=> {
									if (self.note.text != undefined && self.note.text != '') {
										const note = self.note;
										//note.header = "Seller: " + resUser.fullName;
										note.created = new Date();
										note.aboutId = resSeller.sbUserId;
										note.aboutType = 'SbUser';

										UserService.saveNote(note).then((note)=> {})
										.catch((err)=> {})
									}
									AlertService.doneLoading();
									AlertService.success('ROLES.SELLER','save');
									NavService.navigate('main.profile',{ id: resSeller.id, role: 'seller' },((resSeller.user || {}).fullName || self.newSeller.firstName + ' ' + self.newSeller.lastName),{ title: 'ROLES.SELLERS', link: 'main.seller' },true,false);
								})
								.catch((err)=> {})
							})
							.catch((err)=> {})
						})
						.catch((err)=> {})
					}
					else {
						AlertService.doneLoading();
						AlertService.success('ROLES.SELLER','save');
						NavService.navigate('main.profile',{ id: resSeller.id,role:'seller' },((resSeller.user || {}).fullName || self.newSeller.firstName + ' ' + self.newSeller.lastName),{ title: 'ROLES.SELLERS', link: 'main.seller' },true,false);
					}
				})
				.catch((err) => {})
			}
		}

		self.copyAddress = function() {
			if (((self.newSeller._address || {}).country || {}).id !== 'OTHER' && self.newSeller._address.countryId !== 'OTHER') {
				self.property._address = angular.copy(self.newSeller._address);
			}
			else {
				AlertService.warning('Can\'t create Listing in "Other" country.');
			}
		}

	}); // End of controller
})(); // End of function()
