(function () {
  "use strict";
  angular.module("smartbrokr.bookmark", [])
    .service("BookmarkService", function (SbUser, AccountService, Bookmark) {
      var self = this;
      
      self.getBookmarks = function () {
        var filter = {
          order: "seq"
        }

        return SbUser.bookmarks({ id:AccountService.getUserId(), filter:filter }).$promise;
      }
      self.saveBookmark = function (bookmark) {
        if (bookmark.id) {
          return SbUser.bookmarks.updateById({ id:AccountService.getUserId(), fk:bookmark.id},bookmark).$promise;
        }

        return SbUser.bookmarks.create({ id:AccountService.getUserId() }, bookmark).$promise;
      }

      self.deleteBookmark = function(bookmarkId) {
        return SbUser.bookmarks.destroyById({id:AccountService.getUserId(), fk:bookmarkId}).$promise;
      }
    });
})(); // End of function()
