(function () {

    "use strict";

    angular.module("smartbrokr")

        .controller("StartSignupController", function ($state, AccountService) {
            var self = this;
            self.visitedReview = AccountService.getVisitedReview();

            self.next = function (option) {
                if(option == 1){
                    AccountService.saveStart("I am an agency owner");
                    $state.go('main.signup.tell-us-about-yourself',{type:'agency'});
                } else if(option == 2){
                    AccountService.saveStart("I am setting this up for an agency");
                    $state.go('main.signup.tell-us-about-yourself',{type:'agency'});
                } else if(option == 3){
                    AccountService.saveStart("I am a broker");
                    $state.go('main.signup.tell-us-about-yourself',{type:'broker'});
                }
            }
        });
})();
