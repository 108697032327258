/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('ConvertListingController', function ($uibModalInstance, AlertService, ListingService, $filter) {

		const self = this;

		self.close = function () {
			$uibModalInstance.dismiss('cancel');
		};
		self.data = [];
		self.MLSListings = [];
		self.searched = false;
		self.loading = false;
		self.chosen = {
			smart: {},
			mls:{}
		};
		self.filter;
		let data = [];
		const MLSListings = [];

		self.limitIncrement = 6;
		self.mlsLimit = self.limitIncrement;
		self.smartLimit = self.limitIncrement;


		self.filter = function () {
			self.searched = self.searchValue.length > 0;
			if (!self.loading) {
				if (!self.chosen.smart.id) {
					self.data = $filter('searchSingleFilter')(data, self.searchValue, { filter: 'filter' });

				}
				else {
					self.MLSListings = $filter('searchSingleFilter')(MLSListings, self.searchValue, { filter: 'filter' });

				}
			}
		}

		self.cancelSmart = function() {
			self.smartLimit = self.limitIncrement;
			self.mlsLimit = self.limitIncrement;
			self.chosen.smart = {};
			self.chosen.mls = {};
		}

		self.cancelMls = function() {
			self.mlsLimit = self.limitIncrement;
			self.chosen.mls = {};
		}

		self.selectSmart = function(listing) {
			self.chosen.smart = angular.copy(listing);
		}

		self.selectMLS = function(listing){
			self.chosen.mls = angular.copy(listing);
		}

		self.getListings = function () {
			self.loading = true;
			ListingService.getSmart()
			.then((listings) => {
				self.loading = false;
				data = [];
				let filter;
				for (let i = 0; i < listings.length; i++) {
					filter = listings[i].id + ';'
                                + (listings[i].id.includes('M') ? 'MLS' : 'Smart') + ';'
                                + (((listings[i].property || {})._address || {}).addressString || '') + ';'
                                + ((((listings[i].property || {})._address || {}).city || {}).name || '');
					data.push({ object: listings[i], filter: filter });
				}
				self.data = angular.copy(data);
				AlertService.doneLoading();
			})
			.catch((err) => {
				console.error(err);
			})
		}

		self.getMLSListings = function () {
			ListingService.getMLS()
			.then((listings) => {
				self.MLSListings = [];
				let filter;
				for (let i = 0; i < listings.length; i++) {
					filter = listings[i].id + ';'
                                + (listings[i].id.includes('M') ? 'MLS' : 'Smart') + ';'
                                + (((listings[i].property || {})._address || {}).addressString || '') + ';'
                                + ((((listings[i].property || {})._address || {}).city || {}).name || '');
					MLSListings.push({ object: listings[i], filter: filter });
				}
				self.MLSListings = angular.copy(MLSListings);
				AlertService.doneLoading();
			})
			.catch((err) => {
				console.error(err);
			})
		}

		self.loadMoreSmart = function() {
			if((self.smartLimit + self.limitIncrement) >= self.data.length) {
				self.smartLimit = self.data.length;
			} else {
				self.smartLimit += self.limitIncrement;
			}
		}

		self.loadMoreMls = function() {
			if((self.mlsLimit + self.limitIncrement) >= self.MLSListings.length) {
				self.mlsLimit = self.MLSListings.length;
			} else {
				self.mlsLimit += self.limitIncrement;
			}
		}

		self.convert = function(){
			ListingService.convert(self.chosen.smart.id,self.chosen.mls.id)
			.then(() => {
				AlertService.success('LISTING.LISTING','convert');
			})
			.catch(() => {})
			.finally(() => {
				AlertService.doneLoading();
				self.close();
			})
		}
		self.getListings();
		self.getMLSListings();
	}) // End of controller
})(); // End of function()
