/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

  	'use strict';

  	angular.module('smartbrokr.languages', [])

	.service('LanguageService', function (Language, SbUser, LocaleFactory, $localStorage, $rootScope, $q, AlertService, AccountService, GlobalVars) {
		const self = this;
		self.getLanguages = function(){
			return Language.find().$promise;
		}

		self.changeLanguage = function(lang) {
			return LocaleFactory.setLocale(lang);
		}

		self.getUserLanguages = function() {

			const translatable = $rootScope.TRANSLATABLE || [];
			const promises = [
				_userLangs(),
				_systemLangs()
			];

			return $q.all(promises).then((res) => {
				return res.reduce((arr, curr) => {
					return arr.concat(curr || []);
				}, []).sort((a, b) => {
					return (a.nativeRoman || a.nameNative).localeCompare(b.nativeRoman || b.nameNative);
				});
			})

			function _userLangs() {
				const filter = { where: {} };
				filter.where.and = translatable.reduce((arr, lang) => {
					arr.push({ id: { neq: lang } });
					return arr;
				}, []);

				return SbUser.languages({ id: AccountService.getUserId(), filter: filter }).$promise;
			}

			function _systemLangs() {
				const filter = { where: { id: { inq: translatable } } };
				return Language.find({ filter: filter }).$promise;
			}
		}

		self.setLanguage = function(lang) {
			lang = (lang || 'EN').toUpperCase();

			if ($rootScope.TRANSLATABLE.indexOf(lang) < 0) {
				lang = 'EN';
			}

			$localStorage.language = lang;
			$rootScope.language = lang;

			return self.changeLanguage(lang.toLowerCase()).then((data) => {
				AlertService.reload();
				GlobalVars.initCodes();
				return true;
			})
		}
	})

	.factory('LanguageErrorHandler', ( $q, $log ) => {
		return function (part, lang, response) {
			$log.error('The "' + part + '/' + lang + '" part was not loaded.');
			$log.debug('Response: ', response);
			return $q.when({});
		};
	})

	.factory('LocaleFactory', ( $locale, $translate, $window ) => {
		const locales = {
			// Copied from Angular i18n - en-ca
			en: {
				DATETIME_FORMATS: {
					AMPMS: [
						'AM',
						'PM'
					],
					DAY: [
						'Sunday',
						'Monday',
						'Tuesday',
						'Wednesday',
						'Thursday',
						'Friday',
						'Saturday'
					],
					ERANAMES: [
						'Before Christ',
						'Anno Domini'
					],
					ERAS: [
						'BC',
						'AD'
					],
					FIRSTDAYOFWEEK: 6,
					MONTH: [
						'January',
						'February',
						'March',
						'April',
						'May',
						'June',
						'July',
						'August',
						'September',
						'October',
						'November',
						'December'
					],
					SHORTDAY: [
						'Sun',
						'Mon',
						'Tue',
						'Wed',
						'Thu',
						'Fri',
						'Sat'
					],
					SHORTMONTH: [
						'Jan',
						'Feb',
						'Mar',
						'Apr',
						'May',
						'Jun',
						'Jul',
						'Aug',
						'Sep',
						'Oct',
						'Nov',
						'Dec'
					],
					STANDALONEMONTH: [
						'January',
						'February',
						'March',
						'April',
						'May',
						'June',
						'July',
						'August',
						'September',
						'October',
						'November',
						'December'
					],
					WEEKENDRANGE: [
						5,
						6
					],
					fullDate: 'EEEE, MMMM d, y',
					longDate: 'MMMM d, y',
					medium: 'MMM d, y h:mm:ss a',
					mediumDate: 'MMM d, y',
					mediumTime: 'h:mm:ss a',
					short: 'y-MM-dd h:mm a',
					shortDate: 'y-MM-dd',
					shortTime: 'h:mm a'
				},
				NUMBER_FORMATS: {
					CURRENCY_SYM: '$',
					DECIMAL_SEP: '.',
					GROUP_SEP: ',',
					PATTERNS: [
						{
							gSize: 3,
							lgSize: 3,
							maxFrac: 3,
							minFrac: 0,
							minInt: 1,
							negPre: '-',
							negSuf: '',
							posPre: '',
							posSuf: ''
						},
						{
							gSize: 3,
							lgSize: 3,
							maxFrac: 2,
							minFrac: 2,
							minInt: 1,
							negPre: '-\u00a4',
							negSuf: '',
							posPre: '\u00a4',
							posSuf: ''
						}
					]
				},
				pluralCat: function(n, opt_precision) {  const i = n | 0;  const vf = getVF(n, opt_precision);  if (i == 1 && vf.v == 0) {    return PLURAL_CATEGORY.ONE;  }  return PLURAL_CATEGORY.OTHER;}
			},
			// Copied from Angular i18n - fr-ca
			fr: {
				DATETIME_FORMATS: {
					AMPMS: [
						'a.m.',
						'p.m.'
					],
					DAY: [
						'Dimanche',
						'Lundi',
						'Mardi',
						'Mercredi',
						'Jeudi',
						'Vendredi',
						'Samedi'
					],
					ERANAMES: [
						'avant J\u00e9sus-Christ',
						'apr\u00e8s J\u00e9sus-Christ'
					],
					ERAS: [
						'av. J.-C.',
						'ap. J.-C.'
					],
					FIRSTDAYOFWEEK: 6,
					MONTH: [
						'Janvier',
						'F\u00e9vrier',
						'Mars',
						'Avril',
						'Mai',
						'Juin',
						'Juillet',
						'Ao\u00fbt',
						'Septembre',
						'Octobre',
						'Novembre',
						'D\u00e9cembre'
					],
					SHORTDAY: [
						'Dim',
						'Lun',
						'Mar',
						'Mer',
						'Jeu',
						'Ven',
						'Sam'
					],
					SHORTMONTH: [
						'Janv',
						'F\u00e9vr',
						'Mars',
						'Avr',
						'Mai',
						'Juin',
						'Juill',
						'Ao\u00fbt',
						'Sept',
						'Oct',
						'Nov',
						'D\u00e9c'
					],
					STANDALONEMONTH: [
						'Janvier',
						'F\u00e9vrier',
						'Mars',
						'Avril',
						'Mai',
						'Juin',
						'Juillet',
						'Ao\u00fbt',
						'Septembre',
						'Octobre',
						'Novembre',
						'D\u00e9cembre'
					],
					WEEKENDRANGE: [
						5,
						6
					],
					fullDate: 'EEEE d MMMM y',
					longDate: 'd MMMM y',
					medium: 'd MMM y HH:mm:ss',
					mediumDate: 'd MMM y',
					mediumTime: 'HH:mm:ss',
					short: 'yy-MM-dd HH \'h\' mm',
					shortDate: 'yy-MM-dd',
					shortTime: 'HH \'h\' mm'
				},
				NUMBER_FORMATS: {
					CURRENCY_SYM: '$',
					DECIMAL_SEP: ',',
					GROUP_SEP: '\u00a0',
					PATTERNS: [
						{
							gSize: 3,
							lgSize: 3,
							maxFrac: 3,
							minFrac: 0,
							minInt: 1,
							negPre: '-',
							negSuf: '',
							posPre: '',
							posSuf: ''
						},
						{
							gSize: 3,
							lgSize: 3,
							maxFrac: 2,
							minFrac: 2,
							minInt: 1,
							negPre: '-',
							negSuf: '\u00a0\u00a4',
							posPre: '',
							posSuf: '\u00a0\u00a4'
						}
					]
				},
				pluralCat: function(n, opt_precision) {  const i = n | 0;  if (i == 0 || i == 1) {    return PLURAL_CATEGORY.ONE;  }  return PLURAL_CATEGORY.OTHER;}
			}
		};

		moment.updateLocale('fr', {
			months: locales.fr.DATETIME_FORMATS.MONTH,
			monthsShort: locales.fr.DATETIME_FORMATS.SHORTMONTH,
			weekdays: locales.fr.DATETIME_FORMATS.DAY,
			weekdaysShort: locales.fr.DATETIME_FORMATS.SHORTDAY
		});

		return {
			setLocale: function (key) {
				angular.copy(locales[key], $locale);
				moment.locale(key);
				$window.intercomSettings.language_override = key;
				return $translate.use(key);
			}
		};
  	});

})(); // End of function()
