/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('ShareTemplateModalController', function (message, receiver, type, templateId, $filter, $log, $scope, $timeout, $translate, $uibModalInstance, AccountService, AgencyService, AlertService, BrokerService, GlobalVars, MessageService, ResourceService, UserService) {
		const self = this;

		// VARIABLES ============================================================================================================================

		self.newFiles     = [];     // Message files
		self.brokers        = [];     // All options for recipients
		self.title        = '';

		self.receivers    = [];     // Selected receivers for message
		self.message      = null;   // Message object that will be saved

		self.config       = null;   // Config for selectize dropdown

		self.shareMethod = null;

		self.offices = [];
		self.selectedOffice = null;
		self.singleConfig = GlobalVars.createSingleConfig(false, 'id', 'legalName', 'legalName');

		let userId        = null;   // Current user's id - used to get senderId

		// SETUP VARIABLES ======================================================================================================================

		userId        = AccountService.getUserId();
		self.config   = GlobalVars.createMultiConfig(false, 'id', 'fullName', true, 'hidden', 'search');

		self.message = {
			text: message || '',
			read: false,
			receiver: null,
			files: [],
		}

		if (receiver) {
			if (Array.isArray(receiver)) {
				self.receivers = [].concat(receiver);
			}
			else if (receiver.id) {
				self.receivers.push(receiver.id);
			}
			else if (typeof receiver === 'string') {
				self.receivers.push(receiver);
			}
		}

		if(type === 'task') {
			self.title = $filter('translate')('RESOURCES.MODAL.SHARE_TASK_TEMPLATE');
			self.shareMethod = ResourceService.shareTaskTemplate;
		} else {
			self.title = $filter('translate')('RESOURCES.MODAL.SHARE_EMAIL_TEMPLATE')
			self.shareMethod = ResourceService.shareEmailTemplate;
		}

		$uibModalInstance.scope = $scope;
		$uibModalInstance.check = {
			original: {
				message: angular.copy(self.message),
				receivers: angular.copy(self.receivers)
			},
			clone: {
				message: self.message,
				receivers: self.receivers
			}
		}

		getUsers();
		getOffices();

		// FUNCTIONS ============================================================================================================================

		/**
		 *  Closes the modal window
		 */
		self.close = function (ev) {
			if(ev && ev.preventDefault) {
				ev.preventDefault();
			}
			$uibModalInstance.dismiss('cancel');
		}

		/**
		 *  Creates a new conversation with each recipient selected
		 *  and adds all files to that conversation (if required)
		 */
		self.send = function () {
			AlertService.loading();
			self.shareMethod(templateId, self.receivers).then((res) => {
				done();
			})
			.catch($log.error);
		}

		// 	MessageService.newConversation(message, self.receivers).then((res) => {
		// 		done();
		// 	})
		// 	.catch($log.error)
		// }

		/**
		 *  Called after the message is sent and all files have been uploaded.
		 *  Closes the modal.
		 */
		function done() {
			AlertService.doneLoading();
			AlertService.success('MESSAGES.MESSAGE', 'shared');
			self.newMessageForm.$setPristine();
			$uibModalInstance.close();
		}

		/**
		 *  Gets users from the DB to be used a message receivers.
		 */
		function getUsers() {
			$scope.loading = true;
			BrokerService.getActiveBrokers().then((res) => {
				self.brokers = res.reduce((arr,item) => {
					if (item.user.id !== userId) {
						item.hidden = $filter('translate')(item.user.dataType);
						item.search = item.user.fullName + '|' + item.user.hidden;
						arr.push(item);
					}
					return arr;
				},[]);
			})
			.catch($log.error)
			.finally(() => {
				$scope.loading = false;
			});
		}

		function getOffices() {
			$scope.loading = true;
			AgencyService.getAgency(true).then((res) => {
				self.offices = res.offices.filter((office) => {
					if((office.brokers && office.brokers.length > 0) || (office.members && office.members.length > 0) || (office.owners && office.owners.length > 0)) return office;
				});
			});
		}

	}); // End of controller
})(); // End of function()
