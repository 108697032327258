/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/**
 *  Buyer search
 */
(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('BuyerSearchController', function ($rootScope, $scope, $stateParams, $filter, AccountService, AlertService, BuyerService, FileService, GlobalVars, ModalService, NavService, SortingService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.buyer;             // Buyer for which we are looking for listings
		self.listing;           // Listing for which we are looking for buyers

		self.buyers;            // Buyers found after search
		self.listings;          // Listings found after search

		self.agencyListings;    // Whether to include agency listings in the search
		self.otherButtons;      // Used to change buttons when using a different template

		self.filters;           // Possible filters for sorting
		let statuses;           // Options for status filter dropdown list
		let provinces;          // Options for province filter dropdown list
		let cities;             // Options for city filter dropdown list
		let propertyTypes;      // Options for property types filter dropdown list
		let language;           // Options for language dropdown
		let singleConfig;       // Selectize config
		let locationConfig;     // Selectize config (city, province, county)

		let page;

		// SETUP VARIABLES ======================================================================================================================

		self.buyer    = $stateParams.buyer;
		self.listing  = $stateParams.listing;
		self.buyers   = [];
		self.listings = [];

		singleConfig    = GlobalVars.singleConfig;
		locationConfig  = GlobalVars.locationConfig;

		self.agencyListings = false;

		AlertService.loading();
		if (self.buyer === null) {
			BuyerService.getBuyer($stateParams.id).then((res) => {
				self.buyer = res;
				getListings();
			})
			.catch((err) => {})
		}
		else if (self.listing === null) {
			BuyerService.getListing($stateParams.id).then((aux) => {    // First time: Gets listing from Broker's list
				BuyerService.getListingProperty(aux.id).then((res) => {   // Second time: Gets full listing info (can't get straight from Broker.listings)
					self.listing = res;
					getBuyers();
				})
				.catch((err) => {})
			})
			.catch((err) => {})
		}
		else if (!!self.buyer) {
			getListings();
		}
		else if (!!self.listing) {
			getBuyers();
		}

		// FUNCTIONS ============================================================================================================================

		/**
       *  Sorts buyers according to a property
       *  @param category: String (property that will be used for next sorting iteration)
       */
		self.sortBuyers = function (category, keepDesc) {
			SortingService.sort(self.buyers, self.filters, category, ['user'], 'buyerSearch', keepDesc);
		}

		/** Gets data for statuses, provinces, cities and property types */
		const setupBuyer = function () {
			statuses = [];
			statuses.push({ name: '------', value: 'xxx' });
			let aux = $filter('unique')(self.listings, 'status');

			let i;
			const length = aux.length;
			for (i = 0; i < length; i++) {
				statuses.push({ name: $filter('translate')('LISTING.STATUS.' + (aux[i] || '').toUpperCase()), value: aux[i] });
			}

			provinces = [];
			provinces.push({ name: '------', id: 'xxx' });
			aux = $filter('unique')(self.listings, 'property._address.province');
			provinces = provinces.concat(aux.sort((a, b) => { return a.name.localeCompare(b.name) - b.name.localeCompare(a.name); }));

			cities = [];
			cities.push({ name: '------', id: 'xxx' });
			aux = $filter('unique')(self.listings, 'property._address.city');
			cities = cities.concat(aux.sort((a, b) => { return a.name.localeCompare(b.name) - b.name.localeCompare(a.name); }));

			propertyTypes = GlobalVars.getCodes('propertyTypes');

			self.columns = [
				{
					name: 'ADDRESS.ADDRESS',
					filter: 'addressString',
					cardView: true,
					size: 2,
					field: function(listing) {
						return ((listing.property || {})._address || {}).addressString || '';
					}
				},
				{
					name: 'ADDRESS.REGION',
					filter: 'city',
					select: true,
					config: locationConfig,
					options: cities,
					cardView: true,
					field: function(listing) {
						return (((listing.property || {})._address || {}).city || {}).name || '';
					}
				},
				{
					name: 'ADDRESS.PROVINCE_STATE',
					filter: 'province',
					select: true,
					selectType: 'province',
					config: locationConfig,
					options: provinces,
					cardView: true,
					field: function(listing) {
						return (((listing.property || {})._address || {}).province || {}).id || '';
					}
				},
				{
					name: 'ADDRESS.COUNTRY',
					filter: 'country',
					select: true,
					config: locationConfig,
					options: [{ name: '------', id: 'xxx' },{ name: 'Canada', id: 'CAN' }],
					cardView: true,
					field: function(listing) {
						return (((listing.property || {})._address || {}).country || {}).name || '';
					}
				},
				{
					name: 'PROPERTY.PROPERTY_TYPE',
					filter: 'type',
					select: true,
					config: singleConfig,
					options: propertyTypes,
					cardView: true,
					field: function(listing) {
						return (((listing.property || {}).type || {}).labels || {})[$rootScope.language] || (((listing.property || {}).type || {}).labels || {})['EN'] || '';
					}
				},
				{
					name: 'COMMON.PRICE',
					filter: 'askingPrice',
					cardView: true,
					field: function(listing) {
						return listing.askingPrice;
					}
				},
				{
					name: 'LISTING.LISTING_TYPE',
					filter: 'mls',
					select: true,
					config: singleConfig,
					options: [{ name: '------', value: 'xxx' },{ name: 'MLS', value: 'M' },{ name: 'Smart', value: 'S' }],
					cardView: true,
					field: function(listing) {
						if ((listing.id || '').includes('M')) {
							return 'MLS';
						}
						else {
							return 'Smart';
						}
					}
				},
				{
					name: 'LISTING.LISTING_NO',
					filter: 'id',
					cardView: true,
					field: function(listing) {
						return listing.id;
					}
				},
				{
					name: 'COMMON.STATUS',
					filter: 'status',
					select: true,
					config: singleConfig,
					options: statuses,
					cardView: true,
					field: function(listing) {
						if (!!listing.status) {
							return $filter('translate')('LISTING.STATUS.' + listing.status.toUpperCase());
						}
						return '';
					}
				}
			]
		}

		const setupListing = function () {

			const allTypes = GlobalVars.getCodes('propertyTypes');

			propertyTypes = [];
			propertyTypes.push(allTypes[0]);

			let i, length = allTypes.length;
			for (i = 0; i < length; i++) {
				if (allTypes[i].value === self.listing.property.typeId) {
					propertyTypes.push(allTypes[i]);
					break;
				}
			}

			language = [];
			language.push({ name: '------', value: 'xxx' });
			const aux = $filter('unique')(self.buyers, 'user.languageIds');
			length = aux.length;

			for (i = 0; i < length; i++) {
				language.push({ name: $filter('translate')('LANGUAGES.' + aux[i]), value: aux[i] });
			}

			self.columns = [
				{
					name: 'PERSON.FIRST_NAME',
					filter: 'firstName',
					cardView: true,
					size: 3,
					field: function(buyer) {
						return buyer.user.firstName;
					}
				},
				{
					name: 'PERSON.LAST_NAME',
					filter: 'lastName',
					cardView: true,
					size: 3,
					field: function(buyer) {
						return buyer.user.lastName;
					}
				},
				{
					name: 'EMAIL.EMAIL',
					filter: 'email',
					cardView: true,
					size: 4,
					isEmail: true
				},
				{
					name: 'PERSON.PHONE',
					filter: 'phones',
					size: 3,
					field: function(buyer) {
						return $filter('getOnePhone')(buyer.user.phones);
					}
				},
				{
					name: 'ADDRESS.CITY',
					filter: 'city',
					size: 2,
					field: function(buyer) {
						return (((buyer.user || {})._address || {}).city || {}).name;
					}
				},
				{
					name: 'ADDRESS.POSTAL_CODE',
					filter: 'postalCode',
					size: 2,
					field: function(buyer) {
						return ((buyer.user || {})._address || {}).postalCode;
					}
				},
				{
					nameImgClass: 'btn-list-icon do-not-email',
					nameImgPopover: 'btnDoNotEmail',
					filter: 'emailEnabled',
					noSearch: true,
					size: 1,
					class: 'check',
					style: { 'text-align': 'center' },
					isHtml: true,
					field: function(buyer) {
						return buyer.user.emailEnabled ? '' : '<div class="icon-checked"></div>';
					}
				},
				{
					nameImgClass: 'btn-list-icon pre-approved',
					nameImgPopover: 'btnPreApproved',
					filter: 'preApproved',
					noSearch: true,
					size: 1,
					class: 'check',
					style: { 'text-align': 'center' },
					isHtml: true,
					field: function(buyer) {
						return buyer.preApproved ? '<div class="icon-checked"></div>' : '';
					}
				},
				{
					nameImgClass: 'btn-list-icon seek-rental',
					nameImgPopover: 'btnSeekRental',
					filter: 'seekRental',
					noSearch: true,
					size: 1,
					class: 'check',
					style: { 'text-align': 'center' },
					isHtml: true,
					field: function(buyer) {
						return buyer.seekRental ? '<div class="icon-checked"></div>' : '';
					}
				},
				{
					name: 'ADDRESS.REGION',
					filter: 'cities',
					cardView: true,
					size: 3,
					isHtml: true,
					field: function(buyer) {
						let ret = '';
						let i;
						const length = (buyer.cities || []).length || 0;

						for (i = 0; i < length; i++) {
							ret += buyer.cities[i].name;

							if (i < length - 1) {
								ret += ',<br>';
							}
						}
						return ret;
					}
				},
				{
					name: 'COMMON.TYPE',
					filter: 'propertyTypeIds',
					cardView: true,
					select: true,
					size: 3,
					isHtml: true,
					config: singleConfig,
					options: propertyTypes,
					field: function(buyer) {
						let ret = '';
						let i;
						const length = (buyer.propertyTypes || []).length || 0;

						for (i = 0; i < length; i++) {
							ret += buyer.propertyTypes[i].labels[$rootScope.language || 'EN'];

							if (i < length - 1) {
								ret += ',<br>';
							}
						}
						return ret;
					}
				},
				{
					name: 'COMMON.MIN',
					filter: 'minPrice',
					cardView: true,
					size: 2,
					field: function(buyer) {
						return buyer.minPrice;
					}
				},
				{
					name: 'COMMON.MAX',
					filter: 'maxPrice',
					cardView: true,
					size: 2,
					field: function(buyer) {
						return buyer.maxPrice;
					}
				},
				{
					name: 'PERSON.LANGUAGE',
					filter: 'languageIds',
					select: true,
					size: 2,
					config: singleConfig,
					options: language,
					cardView: true,
					isHtml: true,
					field: _getLangField
				}
			];
		}

		/** Looks for listings for a buyer */
		function getListings(isReload) {
			page = 'listingSearch';

			// All possible filters -> based on headers
			self.filters = SortingService.getPageFilter(page);

			if (!self.filters) {
				self.filters = SortingService.createPageFilter(
					page,
					{
						addressString: { weight: 1, value: '', desc: -1 },
						city: { weight: 0, value: null, desc: 1 },
						province: { weight: 0, value: null, desc: 1 },
						country: { weight: 0, value: null, desc: 1 },
						type: { weight: 0, value: null, desc: -1 },
						askingPrice: { weight: 0, value: null, desc: -1, num: true },
						mls: { weight: 0, value: null, desc: 1, id: true },
						id: { weight: 0, value: '', desc: 1 },
						status: { weight: 0, value: null, desc: 1 }
					}
				);
			}

			self.sortInfo = {
  				page: page,
  				sub: ['property', 'property._address']
  			}

			self.navParams = {
				onClick: function(listing) {
					listing = listing || {};
					if (!listing.readOnly) {
						NavService.navigate('main.listings.detail.submenu', { id: listing.id });
					}
					else {
						ModalService.listingSummary(listing.id);
					}
				}
			}
			BuyerService.propertySearch(self.buyer, self.agencyListings).then((res) => {
				const brokerId = AccountService.getBrokerId();
				const resLength = res.length;
				let i;
				self.listings = [];

				for (i = 0; i < resLength; i++) {
					if (res[i].property) {
						if ((self.buyer.cityIds || []).length > 0) {
							const citiesLength = self.buyer.cityIds.length;
							for (let j = 0; j < citiesLength; j++) {
								if (res[i].property._address.city.id == self.buyer.cityIds[j]) {

									if (self.agencyListings) {
										res[i].readOnly = true;
										res[i].ngClass = 'read-only';
										const brokersLength = res[i].brokers.length;

										for (let k = 0; k < brokersLength; k++) {
											if (res[i].brokers[k].id == brokerId) {
												res[i].readOnly = false;
												res[i].ngClass = null;
												break;
											}
										}
									}

									self.listings.push(res[i]);
									break;
								}
							}
						}
						else {
							self.listings.push(res[i]);
						}
					}
				}
				SortingService.sort(self.listings, self.filters, null, self.sortInfo.sub, page, true);
				setupBuyer();
			})
			.catch((err) => {
				console.error('ERR: ', err);
			})
			.finally(AlertService.doneLoading);
		}
		/** Looks for buyers for a listing */
		function getBuyers() {
			self.otherButtons = true;

			page = 'buyerSearch';

			// All possible filters -> based on headers
			self.filters = SortingService.getPageFilter(page);

			if (!self.filters) {
				self.filters = SortingService.createPageFilter(
					page,
					{
						firstName: { weight: 2, value: '', desc: -1 },
						lastName: { weight: 1, value: '', desc: 1 },
						emailEnabled: { weight: 0, value: '', desc: 1 },
						preApproved: { weight: 0, value: '', desc: 1 },
						seekRental: { weight: 0, value: '', desc: 1 },
						cities: { weight: 0, value: '', desc: 1, array: true, sub: 'name' },
						propertyTypeIds: { weight: 0, value: null, desc: 1, array: true },
						minPrice: { weight: 0, value: '', desc: 1, num: true },
						maxPrice: { weight: 0, value: null, desc: 1, num: true },
						languageIds: { weight: 0, value: null, desc: 1, array: true, sortFunction: _getLangField },
						phones: { weight: 0, value: '', desc: 1, phone: true },
						email: { weight: 0, value: '', desc: 1 },
						city: { weight: 0, value: '', desc: 1, sub: 'name' },
						postalCode: { weight: 0, value: '', desc: 1 },
					}
				);
			}

			self.sortInfo = {
  				page: page,
  				sub: ['user', 'user._address']
  			}

			self.navParams = {
  				state: 'main.profile',
  				getParams: function(entry) {
  					if (entry) {
  						return { id: entry.id, role:'buyer' };
  					}
  					return {};
  				}
  			}

			BuyerService.buyerSearch(self.listing).then((res) => {
				let i;
				const resLength = res.length;

				const checkCities = function (buyer) {
					return (buyer.cityIds || []).findIndex(x => x == self.listing.property._address.city.id) >= 0;
				}

				const checkPropTypes = function (buyer) {
					return (buyer.propertyTypeIds || []).findIndex(x => x == self.listing.property.typeId) >= 0;
				}

				self.buyers = [];

				for (i = 0; i < resLength; i++) {
					if (checkCities(res[i]) && checkPropTypes(res[i])) {
						self.buyers.push(res[i]);
					}
				}

				SortingService.sort(self.buyers, self.filters, null, self.sortInfo.sub, page, true);
				setupListing();
			})
			.catch((err) => {
				console.error('err: ', err);
			})
			.finally(AlertService.doneLoading)
		}


		self.reload = function () {
			self.agencyListings = !self.agencyListings;
			getListings(true);
		}

		/** Exports results as a CSV (Property Search) */
		self.exportPropertySearchCsv = function () {
			const toExport = $filter('searchMultiFilter')(self.listings, self.filters);
			FileService.exportPropertySearch(toExport);
		};

		function _getLangField(buyer) {
			const langs = (((buyer || {}).user || {}).languages || []).reduce((arr, lang) => {
				arr.push('' + $filter('translate')('LANGUAGES.' + lang.id));
				return arr;
			}, []).sort();

			if (langs.length === 0) {
				return '---';
			}

			return langs.join('<br>');
		}

		$scope.exportComplete = function () {
			self.isPopoverOpen = false;
			const toExport = $filter('searchMultiFilter')(self.buyers, self.filters);
			FileService.exportComplete(toExport, 'buyer');
		};

		// Export csv - Simplified (first name, last name and email)
		$scope.exportSimple = function() {
			self.isPopoverOpen = false;
			const toExport = $filter('searchMultiFilter')(self.buyers, self.filters);
			FileService.exportSimple(toExport, 'ROLES.BUYER');
		}
	}) // End of controller
})(); // End of function()
