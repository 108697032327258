(function () {

	'use strict';

	angular.module('smartbrokr.admin')
	.controller('AdminAgenciesController', function (AdminService, AlertService, SortingService, $filter, $log) {

		const self = this;

		// VARIABLES ============================================================================================================================

		const page        = 'admin.agencies';
		self.filters    = SortingService.getPageFilter(page);
		self.agencies   = null;
		self.columns    = null;

		self.sortInfo   = {
			page: page,
			sub: [ 'agencyOwners' ]
		}

		self.navParams  = {
			state: 'main.admin.agencies.profile',
			getParams: function(entry) {
				if (entry) {
					return { id: entry.id };
				}
				return {};
			}
		}

		if (!self.filters) {
			self.filters = SortingService.createPageFilter(
				page,
				{
					legalName: { weight: 2, value: '', desc: -1 },
					fullName: { weight: 1, value: '', desc: 1 },
					phones: { weight: 0, value: '', desc: 1, phone: true },
					email: { weight: 0, value: '', desc: 1 },
					subscription: { weight: 0, value: '', desc: 1,
						filterFunction: function(value, item) {
							const frequency = getSubFrequency(item).toLowerCase();
							return frequency.match(value) !== null;
						},
						sortFunction: function(item) {
							return getSubFrequency(item);
						}
					},
					office: { weight: 0, value: '', desc: 1, }
				}
			);
		}

		getAgencies();

		// FUNCTIONS ============================================================================================================================

		/**
         *  Gets Agency data to show in list
         *  and creates column information for list-view directive
         */
		function getAgencies() {
			AlertService.loading();
			AdminService.getAgencies().then((res) => {
				self.agencies = $filter('orderBy')(res, 'legalName');
			})
			.catch((err) => {
				$log.error(err);
			})
			.finally(() => {
				self.columns = [
					{
						name: 'Agency Name',
						filter: 'legalName',
						field: function(agency) {
							return agency.legalName || agency.legal_name || ('No name: ' + agency.id);
						}
					},
					{
						name: 'Owner Name(s)',
						filter: 'fullName',
						isHtml: true,
						field: function(agency) {
							let ret = '';

							if ((agency.agencyOwners || []).length > 0) {
								agency.agencyOwners.forEach((owner) => {
									if (!(owner.agencyOwnerProfile || {}).disabled)
										ret += '<div><a ui-sref="main.admin.profile({ id: \'' + owner.agencyOwnerProfile.id + '\', role: \'owner\', menu: \'agencies\'})">' +
                                            (owner.fullName || 'N/A') + '</a></div>';
								})
							}
							else {
								ret += 'N/A';
							}

							return ret;
						}
					},
					{
						name: 'Phone',
						filter: 'phones',
						isHtml: true,
						field: function(agency) {
							let ret = '';

							if ((agency.agencyOwners || []).length > 0) {
								agency.agencyOwners.forEach((owner) => {
									if (!(owner.agencyOwnerProfile || {}).disabled)
										ret += '<div>' + $filter('getOnePhone')(owner.phones || {}) + '</div>';
								})
							}
							else {
								ret += 'N/A';
							}

							return ret;
						}
					},
					{
						name: 'Email',
						filter: 'email',
						isHtml: true,
						field: function(agency) {
							let ret = '';

							if ((agency.agencyOwners || []).length > 0) {
								agency.agencyOwners.forEach((owner) => {
									if (!(owner.agencyOwnerProfile || {}).disabled) {
										const email = {
											id: owner.id,
											email: owner.email,
											fullName: owner.fullName
										}
										ret += '<div><email person=\'' + JSON.stringify(email) + '\' function="smartbrokrController.sendMessage"></email></div>';
									}
								})
							}
							else {
								ret += 'N/A';
							}

							return ret;
						}
					},
					{
						name: 'Subscription',
						filter: 'subscription',
						field: function(agency) {
							return getSubFrequency(agency);
						}
					},
					{
						name: 'Office',
						filter: 'office',
						isHtml: true,
						field: function(agency) {
							let ret = '';
							if ((agency.agencyOwners || []).length > 0) {
								agency.agencyOwners.forEach((owner) => {
									if(owner._address) {
										ret += '<div>' + owner._address.officeName + '</div>'
									}
								});
							} else {
								ret += 'N/A';
							}
							return ret;
						}
					}
				]

				AlertService.doneLoading();
			})
		}

		/**
         *  Gets label for 'Frequency' column in table based
         *  on agency (or broker) subscription.
         *  @param  {object}    agency      Agency object
         *  @param  {object=}   agency.subscriptions    Agency subscription
         *  @param  {object[]}  agency.brokers          If Agency belongs to an independent broker, array will have the broker
         *  @param  {object}    agency.brokers[].subscription   If Agency belongs to independent broker, will use this subscription to determine frequency
         *  @return {string}    Text to show in the 'Frequency' column
         */
		function getSubFrequency(agency) {
			agency = agency || {};
			let frequency = null;

			if (agency.subscriptions) {
				var sub = agency.subscriptions;
				if (sub.status === 'canceled') {
					return 'Agency - Cancelled';
				}

				frequency = (((((agency.subscriptions || {}).items || {}).data || [])[0] || {}).plan || {}).interval;
				if (frequency)
					return 'Agency - ' + (frequency === 'month' ? 'Monthly' : 'Yearly');
			}
			else if ((agency.brokers || []).length > 0) {
				var sub = agency.brokers[0].subscriptions || {};
				if (sub.status === 'canceled') {
					return 'Independent Broker - Cancelled';
				}

				frequency = ((((sub.items || {}).data || [])[0] || {}).plan || {}).interval;
				if (frequency)
					return 'Independent Broker - ' + (frequency === 'month' ? 'Monthly' : 'Yearly');
			}

			return '----';
		}

	});

})();
