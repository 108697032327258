/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.directives')
	.controller('PhotoCropperController', function ($filter, $scope) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.dimensions     = {};
		self.aspectRatio    = null;

		$scope.loadingText = $filter('translate')('ALERT_MESSAGES.ALERT.LOADING_OVERLAY');

		// FUNCTIONS ============================================================================================================================

		// Called after the link function is done setting up the options
		$scope.setupDimensions = function() {
			const options = $scope.sbOptions || {};
			self.aspectRatio = options.aspectRatio || 1;
			self.dimensions = {
				result: {
					w: (options.dimensions || {}).result_w || 480,
					h: (options.dimensions || {}).result_h || 480
				},
				init: {
					w: (options.dimensions || {}).init_w || 480,
					h: (options.dimensions || {}).init_h || 480
				}
			};
		};

		$scope.uploader.onAfterAddingFile = function (item) {
			if ($scope.uploader.queue.length > 1) {
				$scope.uploader.removeFromQueue(0);
			}

			resizeImage(item);
			$scope.canConfirm = true;
		};

		$scope.uploader.onBeforeUploadItem = function (item) {
			let blob = self.croppedPhoto;
			blob = dataURItoBlob(blob);
			item._file = blob;
		}

		$scope.uploader.onSuccessItem = function (fileItem, response, status, headers) {
			$scope.photoOwner.photoUrl = response.url;
			$scope.canConfirm = false;
			self.croppedPhoto = null;
			$scope.photo = null;
		};

		$scope.confirmPhoto = function (item) {
			$scope.uploader.uploadAll();
		}

		function resizeImage(item) {
			const reader = new FileReader();
			reader.onload = function (event) {
				$scope.$apply(() => {
					$scope.photo = event.target.result;
					$scope.bounds = {
						left: 60,
						top: 60
					};
				});
			};
			if(item._file instanceof Blob) {
				reader.readAsDataURL(item._file);
			} else {
				const blob = new Blob(item.data, { type: item.type });
				reader.readAsDataURL(blob);
			}
		}

		/**
         * Upload Blob (cropped image) instead of file.
         * @see
         *   https://developer.mozilla.org/en-US/docs/Web/API/FormData
         *   https://github.com/nervgh/angular-file-upload/issues/208
         */

		/**
         * Converts data uri to Blob. Necessary for uploading.
         * @see
         *   http://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
         * @param  {String} dataURI
         * @return {Blob}
         */
		function dataURItoBlob(dataURI) {
			const binary = atob(dataURI.split(',')[1]);
			const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
			const array = [];
			for (let i = 0; i < binary.length; i++) {
				array.push(binary.charCodeAt(i));
			}
			return new Blob([new Uint8Array(array)], { type: mimeString });
		};

	})

	.directive('photoUpload', () => {
		return {
			restrict: 'E',
			controller: 'PhotoCropperController',
			controllerAs: 'cropperController',
			scope: {
				uploader: '=',
				photoOwner: '=',
				canUpload: '=',
				sbOptions: '=?'
			},
			link: function (scope, elm, attrs) {
				scope.sbOptions = scope.sbOptions || {};
				scope.sbOptions.texts = scope.sbOptions.texts || {};
				scope.sbOptions.classes = scope.sbOptions.classes || {};

				scope.sbOptions.classes.upload = scope.sbOptions.classes.upload || 'btn btn-green no-size upload';

				scope.sbOptions.texts.upload = scope.sbOptions.texts.upload || 'PERSON.CHANGE_PHOTO';
				scope.sbOptions.texts.new = scope.sbOptions.texts.new || 'FILES.NEW_PHOTO';
				scope.sbOptions.texts.confirm = scope.sbOptions.texts.confirm || 'FILES.CONFIRM_PHOTO';
				scope.sbOptions.texts.current = scope.sbOptions.texts.current || 'FILES.CURRENT_PHOTO';

				scope.sbOptions.dimensions = scope.sbOptions.dimensions || {};
				scope.sbOptions.dimensions.init_w = scope.sbOptions.dimensions.init_w || 60;
				scope.sbOptions.dimensions.init_h = scope.sbOptions.dimensions.init_h || 60;
				scope.sbOptions.dimensions.result_w = scope.sbOptions.dimensions.result_w || 425;
				scope.sbOptions.dimensions.result_h = scope.sbOptions.dimensions.result_h || 425;

				scope.sbOptions.aspectRatio = scope.sbOptions.dimensions.result_w / scope.sbOptions.dimensions.result_h;

				scope.setupDimensions();
			},
			templateUrl: '/js/directives/photo-upload/photo-upload.html'
		};
	})

})();
