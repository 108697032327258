/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.admin')
	.controller('AdminHelpModalController', function ($uibModalInstance, $scope, GlobalVars, UploadService, article, lang, folderId) {

		const self = this;

		let uploaded = false;

		self.lang		= lang || 'EN';
		self.article 	= article || {};
		self.lockText 	= !!self.article.file;

		$uibModalInstance.scope = $scope;
		$uibModalInstance.check = {
			original: angular.copy(self.article),
			clone: self.article
		}

		self.toggleEnableVideo = function() {
			self.article.enableVideo = !self.article.enableVideo;
		}

		self.toggleEnableVideo = function() {
			self.article.enableVideo = !self.article.enableVideo;
		}

		_setupEditorConfig();
		_setupUploader();

		self.close = function() {
			if (!uploaded) {
				$uibModalInstance.dismiss('cancel');
			}
			else {
				$uibModalInstance.close({ article: null, uploaded: uploaded });
			}
		}

		self.deleteFile = function() {
			self.article.fileId = null;
			self.lockText = false;
		}

		self.save = function () {
			delete self.article.file;
			$uibModalInstance.close({ article: self.article, uploaded: uploaded });
		}

		function _setupEditorConfig() {
			const plugins = 'advlist anchor autolink charmap code codesample ' +
				'colorpicker contextmenu fullscreen help hr image link ' +
				'lists nonbreaking pagebreak paste preview ' +
				'searchreplace table textcolor textpattern toc visualblocks ' +
				'visualchars wordcount';

			const toolbar = 'formatselect bold italic strikethrough | bullist numlist | alignleft aligncenter alignright alignjustify | forecolor outdent indent link hr charmap | image code preview';
			const config 	= GlobalVars.createEditorConfig(false, false, plugins, toolbar);
			const imgUploader = UploadService.initAdminHelpUploader(folderId, true);			// Image uploader

			// Preview
			config.plugin_preview_width = 1250;
			config.plugin_preview_height = 800;

			// CSS styling
			config.content_style = '* { font-size: 16px; font-weight: 300; line-height: 1.4em; font-family: \'Montserrat\', sans-serif; }; strong { font-weight: 500; }';

			// Image
			config.image_advtab = true;
			config.menubar = 'file edit view insert format table tools help';

			// Image upload handler.
			config.images_upload_handler = function (blobInfo, success, failure) {
				imgUploader.onSuccessItem = function (fileItem, response, status, headers) {
					if (response.url) {
						uploaded = true;
						success(response.url);
					}
					else {
						failure('FAILED');
					}
				};

				imgUploader.addToQueue(blobInfo.blob());
				imgUploader.uploadAll();
			};

			self.editorConfig = config;
		}

		function _setupUploader() {
			self.fileUploader = UploadService.initAdminHelpUploader(folderId, false);
			self.fileUploader.autoUpload = true;

			self.fileUploader.onSuccessItem = function (fileItem, response, status, headers) {
				uploaded = true;

				self.article.text = '';
				self.article.file = response;
				self.article.fileId = response.id;
				self.lockText = true;
			};
		}


	}) // End of controller
})(); // End of function()
