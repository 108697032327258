/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
'use strict';
/**
 *  Filter functions
 */

(function () {

  	angular.module('smartbrokr.filters', [])

	/* FILTERS ============================================================================================  */

	/**
     * Returns label text in the desired language
     * @param lang: String (languageId of the desired language. English -> 'EN', French -> 'FR')
     */
	.filter('getLabel', () => {
		return function (labels, lang) {

			if (!labels) {
				return null;
			}

			lang = (lang || '').toUpperCase();

			return labels[lang] || labels['EN'];
		};
	})

	/**
     * Returns only one phone number out of a user's phones object.
     * Priority: work > mobile > home > fax
     */
	.filter('getOnePhone', ($filter) => {
		return function (phones) {
			phones = phones || {};
			if (phones.work) {
				return ('' + phones.work).trim();
			}
			if (phones.mobile) {
				return ('' + phones.mobile).trim();
			}
			if (phones.home) {
				return ('' + phones.home).trim();
			}
			if (phones.fax) {
				return ('' + phones.fax).trim();
			}
			if (phones.other) {
				return ('' + phones.other).trim();
			}
			return $filter('translate')('PERSON.PHONE_TYPES.NO_PHONE');
		};
	})

	/**
     *  Generic filter that looks into the categories of an object and checks
     *  if they contain all of the values of a set of filters.
     *  If 'categories' is defined, will only look into those.
     *  Otherwise, will look into every category.
     *  Used for combined search bars.
     *
     *  @param    {array}     array               Array to be sorted
     *  @param    {object}    filters             Filter options. Each field should have the name of an object's property.
     *                                            Example: { fullName: { value: 'Jane' }, created: { value: new Date(), date: true }, ... }
     *  @param    {string}    filters[*].value    Value to be used for filtering
     *  @param    {boolean}   filters[*].id       If true, skips searching for property in the object and just gets object.id for comparing.
     *  @param    {boolean}   filters[*].phone    If true, will run 'getOnePhone' filter to get the main phone for comparing
     *  @param    {boolean}   filters[*].num      If true, will compare field as number instead of a string
     *  @param    {boolean}   filters[*].exact    If true, filtering function will check is value is exactly equal to field (instead of using String.includes)
     *  @param    {boolean}   filters[*].array    If true, will treat the field as an array, looking into objects that it contains
     *  @param    {boolean}   filters[*].date     If true, will convert date to a string before comparing (using dateFormat3 filter)
     *  @param    {string=}   filters[*].dateFilter If provided, will user this filter instead of dateFormat3 to get the filtered value
     *  @param    {boolean}   filters[*].top      If true, will skip searching for property in the object and just get object[name] for comparing
     *  @param    {string}    filters[*].sub      Name of subproperty to look into. When filtering Location.city, for example, can look into city name for filtering
     *  @param    {function}  filters[*].filterFunction  If exists, will run filterFunction instead of our standard filter function. filterFunction should have signature (value, item)
     */
	.filter('searchMultiFilter', ($filter, $log) => {
		return function (array, filters, categories) {

			// Blank filter
			if (!filters) return array;

			// No data
			if (!array) return array;

			// Return array
			const ret = [];

			function getPhone(item) {
				item = item || {};
				if (item.user || item.owner) {
					return $filter('getOnePhone')((item.user || item.owner || {}).phones);
				}
				else if (item.phones){
					return $filter('getOnePhone')(item.phones);
				}
				else {
					return null;
				}
			}

			function filterName(value, filter, item) {
				if(!item.users || item.users.length == 0) return false;
				if(!item.users[0][filter] || item.users[0][filter] == '') return false;
				return item.users[0][filter].toLowerCase().indexOf(value.toLowerCase()) >= 0;
			}

			function filterPhone(filter,item) {
				const phone = '' + getPhone(item);
				return phone.toLowerCase().includes(filter.toLowerCase());
			}

			function getDate(str,dateFilter) {
				if (str.includes('T')) {
					dateFilter = dateFilter || 'dateFormat3';
					return '' + $filter(dateFilter)(str);
				}
				else {
					return '';
				}
			}

			const check = function (item, filter, isExact) {
				if (filter.includes('<')) {
					// Added so the filter won't trigger before user enters something
					if (filter.substring(1).length == 0) {
						return true;
					}
					else {
						return Number(item) < filter.substring(1) & 1;
					}
				}
				else if (filter.includes('>')) {
					return Number(item) > filter.substring(1) & 1;
				}
				else if (isExact) {
					item = item.toLowerCase();
					return item.localeCompare(filter.toLowerCase()) == 0;
				}
				else {
					item = item.toLowerCase();
					return item.includes(filter.toLowerCase());
				}
			}

			let i;
			const length = array.length;
			for (i = 0; i < length; i++) {
				let push = true;
				try {
					for (const cat in filters) {
						if ((filters[cat]['value'] === 0 ? '0' : filters[cat]['value'] || '').toString().length > 0) {
							if (cat == 'id' || filters[cat]['id'] == true) {
								push = array[i].id.toLowerCase().includes(filters[cat]['value'].toLowerCase());
							}
							else if(cat == 'person') {
								push = filterName(filters[cat]['value'], 'fullName', array[i]);
							}
							else if (filters[cat]['phone']) {
								push = filterPhone(filters[cat]['value'], array[i]);
							}
							else if (filters[cat]['top']) { // In case there are two objects with same field name -> look only into the 'main' object
								if (filters[cat]['date']) {
									const str = getDate(array[i][cat], filters[cat]['dateFilter']);
									push = str.toLowerCase().includes(filters[cat]['value'].toLowerCase());
								}
								else {
									push = (array[i][cat] || '').toLowerCase().includes(filters[cat]['value'].toLowerCase());
								}
							}
							else if (filters[cat]['filterFunction']) {
								// Returns true if array item should be filtered
								const filterFunction = filters[cat]['filterFunction'];
								push = filterFunction(filters[cat]['value'], array[i]);
							}
							else {
								const json = JSON.stringify(array[i]);
								if (json.includes(cat)) {
									let str = '';
									let sub = json.substring(json.indexOf(cat));
									let control = 0;

									// Control to avoid infinite loop in case of faulty logic
									while (true && control < 50) {
										control += 1;
										let toPush = '';
										if (filters[cat]['num']) {
											toPush = sub.split(',')[0].trim();
										}
										else if (!!filters[cat]['sub']) {

											let control2 = 0;
											sub = sub.substring(sub.indexOf('"' + filters[cat]['sub'] + '"'));

											if (!!filters[cat]['array']) {

												sub = sub.substring(0, sub.indexOf(']'));

												while (true && control2 < 50) {
													toPush += sub.split(',')[0].trim() + '|';
													toPush = toPush.replace(filters[cat]['sub'], '');

													if (sub.indexOf(filters[cat]['sub']) >= 0 && sub.indexOf(',') >= 0) {
														sub = sub.substring(sub.indexOf(','));
														sub = sub.substring(sub.indexOf(filters[cat]['sub']));
													}
													else {
														break;
													}

													control2 += 1;
												}
											}
											else {
												toPush = sub.split(',')[0].trim();
												toPush = toPush.replace(filters[cat]['sub'], '');
											}
											toPush = toPush.replace(/[:\"}]/g, '');
										}
										else if (!!filters[cat]['array']) {
											toPush = sub.split('"],')[0].trim();
										}
										else {
											toPush = sub.split('",')[0].trim();
										}

										if (toPush.length > 0) {
											if (str.length > 0) {
												str += '|';
											}
											str += toPush;
											sub = sub.substring(toPush.length);
											sub = sub.substring(sub.indexOf(cat));
											if (sub.indexOf(cat) < 0) {
												break;
											}
										}
										else {
											break;
										}
									}

									str = str.split('}')[0].trim();
									str = str.replace(new RegExp(cat + '\":', 'gi'), '');

									if (!filters[cat]['date']) {
										str = str.replace(/:|"/g, '');
									}
									else {
										str = str.replace(/"/g, '');
									}

									str = str.trim();

									// Filters that apply on a date object have to be treated differently
									if (filters[cat]['date']) {
										str = getDate(str, filters[cat]['dateFilter']);
									}
									push = push && check(str, filters[cat]['value'], filters[cat]['exact']);
								}
								else {
									push = false;
								}
							}
							if (!push) {
								break;
							}
						}
					}
					if (push) {
						ret.push(array[i]);
					}
				}
				catch (err) {
					$log.error('searchMultiFilter: ', err);
				}
			}

			return ret;
		};
	})

	/**
     * Generic filter that looks into the categories of an object and checks
     * if they contain a value (filter).
     * If 'categories' is defined, will only look into those fields.
     * Otherwise, will look into every field.
     * Used for search bars.
     */
	.filter('searchSingleFilter', ($filter) => {
		return function (array, filter, categories) {

			// Blank filter
			if (!filter) return array;

			// No data
			if (!array) return array;

			// Return array
			const ret = [];

			// Filter -> Case insensitive
			filter = filter.toLowerCase();

			const searchItem = function (item, filter, special) {

				// only happens if excluded from search
				if (item === null || item === undefined) {
					return false;
				}

				// String
				if (typeof item === 'string') {

					if (typeof special === 'object' && !!special) {
						if (special.date) {
							item = '' + $filter(special.dateFormat || 'dateFormatLong')(item);
						}
					}

					const parts = filter.split(' ');
					let isInclude = true;
					item = item.toLowerCase();

					for (let j = 0; j < parts.length; j++) {
						isInclude = isInclude && item.includes(parts[j]);
					}
					return isInclude;


					//filter = filter.replace(/ /g, '.*');
					//var regexp = new RegExp(filter, 'gi');
					//return item.match(regexp) != null;
					//return item.toLowerCase().includes(filter); // Only one property needs to contain value
				}

				// Array: Looks into every item
				if (angular.isArray(item)) {
					let ret = false;
					const auxLength = item.length;
					for (let i = 0; i < auxLength; i++) {
						ret = ret || searchItem(item[i], filter);
						if (ret == true) {
							break;
						}
					}
					return ret;
				}

				// Objects: Search into every property
				if (angular.isObject(item)) {
					let ret = false;
					for (const cat in item) {
						if (item.hasOwnProperty(cat)) {
							ret = ret || searchItem(item[cat], filter) || cat == 'ignoreFilter';
							if (ret == true) {
								break;
							}
						}
					}
					return ret;
				}

				// Any other type
				return item.toString().includes(filter);
			}

			if(Array.isArray(array)) {
				let i;
				const auxLength = array.length;
				for (i = 0; i < auxLength; i++) {
					let push = false;

					// Looks into all properties
					if (categories == undefined || categories == null) {
						push = searchItem(array[i], filter);
					}
					// Looks only into selected properties
					else {
						for (const cat in categories) {
							if (!!array[i]) {
								let item;
								if ((cat || '').includes('.')) {
									const check = cat.split('.');
									item = array[i];
									for (const j in check) {
										if (!!item[check[j]]) {
											item = item[check[j]];
										}
										else {
											// if selected property does not exist, exclude from search
											item = undefined;
											break;
										}
									}
								}
								else {
									item = array[i][cat];
								}
								push = push || searchItem(item, filter, categories[cat]) || array[i].ignoreFilter;
							}
						}
					}

					if (push) {
						ret.push(array[i]);
					}
				}

				return ret;
			} else {
				const returnal = {};
				for(const objKey in array) {
					let push = false;
					// Looks into all properties
					if (categories == undefined || categories == null) {
						push = searchItem(array[objKey], filter);
					} else {
						for (const cat in categories) {
							if (!!array[objKey]) {
								let item;
								if ((categories[cat] || '').includes('.')) {
									const check = cat.split('.');
									item = array[objKey];
									for (const j in check) {
										if (!!item[check[j]]) {
											item = item[check[j]];
										}
										else {
											break;
										}
									}
								}
								else {
									item = array[objKey][categories[cat]];
								}
								push = push || searchItem(item, filter, categories[cat]) || array[objKey].ignoreFilter;
							}
						}
					}

					if (push) {
						returnal[objKey] = array[objKey];
					}
				}
				return returnal;
			}

		};
	})

	// Filters max value of a category in an object
	.filter('max', () => {
		return function (array, category) {

			try {
				let max = 0;
				if (array != null && array != undefined) {
					const auxLength = array.length;
					let i;

					//Nested property
					if (category.includes('.')) {
						const parent = category.split('.')[0];
						const child = category.split('.')[1];


						for (i = 0; i < auxLength; i++) {
							if (array[i][parent]) {
								if (array[i][parent][child]) {
									if (array[i][parent][child] > max) {
										max = array[i][parent][child];
									}
								}
							}
						}
					}
					else {
						for (i = 0; i < auxLength; i++) {
							if (array[i][category]) {
								if (array[i][category] > max) {
									max = array[i][category];
								}
							}
						}
					}
				}
				return max;
			}
			catch (err) {
				return 0;
			}
		}
	})

	// Filters all unique values of a category belonging to an object.
	.filter('unique', () => {
		return function (array, category, discardEmpty) {
			const ret = [];

			const compareItems = function (item1, item2) {
				if (item1 === item2) {
					return false;
				}
				else if ((item1 || {}).id != undefined && (item2 || {}).id != undefined && (item1 || {}).id === (item2 || {}).id) {
					return false;
				}
				else if (discardEmpty && (item1 === undefined || item2 === undefined)) {
					return false;
				}
				else {
					return true;
				}
			}

			const checkNested = function (item, category, children) {
				if (children.length == 0) {
					let add = true;

					if (angular.isArray(item)) {
						const itemLength = item.length;
						let j;

						for (j = 0; j < itemLength; j++) {

							const retLength = ret.length;
							let k;

							for (k = 0; k < retLength; k++) {
								add = compareItems(((item[j] || {})[category] || null), ret[k]);
								if (!add) {
									break;
								}
							}
							if (add && ((item[j] || {})[category] || null != null)) {
								ret.push(item[j][category]);
							}
						}
					}
					else if (angular.isArray(item[category])) {
						const itemLength = item[category].length;
						let j;

						for (j = 0; j < itemLength; j++) {

							const retLength = ret.length;
							let k;

							for (k = 0; k < retLength; k++) {
								add = compareItems(((item[category] || {})[j] || null), ret[k]);
								if (!add) {
									break;
								}
							}
							if (add && ((item[category] || {})[j] || null != null)) {
								ret.push(item[category][j]);
							}
						}
					}
					else {
						const retLength = ret.length;
						let j;

						for (j = 0; j < retLength; j++) {
							add = compareItems(item[category], ret[j]);
							if (!add) {
								break;
							}
						}

						if (add && item[category] != null && item[category] != undefined) {
							ret.push(item[category]);
						}
					}
				}
				else {
					const children2 = angular.copy(children);
					const child = children2.shift();
					item = item[category];

					if (angular.isArray(item)) {
						for (const i in item) {
							checkNested(item[i], child, children2);
						}
					}
					else if (item != null) {
						checkNested(item, child, children2);
					}
				}
			}

			if (array != null) {
				const arrayLength = array.length;
				let i;

				//Filter nested property
				if (category.includes('.')) {
					for (i = 0; i < arrayLength; i++) {
						const children = category.split('.');
						const child = children.shift();
						checkNested(array[i], child, children);
					}
				} else {
					let subArray = false;
					for (i = 0; i < arrayLength; i++) {
						let add = true;
						if (angular.isArray(array[i][category])) {
							subArray = true;
							break;
						}

						const retLength = ret.length;
						let j;

						for (j = 0; j < retLength; j++) {
							add = compareItems(array[i][category], ret[j]);
							if (!add) {
								break;
							}
						}
						if (add) {
							ret.push(array[i][category]);
						}
					}
					//Nested property is an array -> looks for unique values within that subarray
					if (subArray) {
						for (i = 0; i < arrayLength; i++) {
							if (array[i][category]) {
								let j;
								const catLength = array[i][category].length;

								for (j = 0; j < catLength; j++) {
									let add = true;
									const retLength = ret.length;
									let k;

									for (k = 0; k < retLength; k++) {
										add = compareItems(array[i][category][j], ret[k]);
										if (!add) {
											break;
										}
									}
									if (add) {
										ret.push(array[i][category][j]);
									}
								}
							}
						}
					}
				}
			}
			ret.sort();
			return ret;
		};
	})

	/* DATA VISUALIZATION =================================================================================  */

	// Ignores all tags + replaces escaped characters with their proper values (used to preview email templates)
	.filter('ignoreTags', () => {
		return function (text) {
			text = text || '';
			return text.replace(/(<([\w]+)[^>]*>)|(<\/([\w]+)[^>]*>)/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;/g, ' ');
		}
	})

	.filter('emailTemplate', ($filter) => {
		return function (text) {
			text = text || '';

			if (text.includes('<div class="body">')) {
				text = text.split('<div class="body">')[1] || '';
			}

			if (text.includes('<div class="footer">')) {
				text = text.split('<div class="footer">')[0] || '';
			}

			return text;
		}
	})

	// If file name is too long, shows ellipsis + extension
	.filter('fileName', () => {
		return function (name) {
			let ret = '' + (name || '');

			if (ret.lastIndexOf('.') > 10) {
				ret = ret.substr(0, 12) + '..' + ret.substring(ret.lastIndexOf('.'));
			}

			return ret;
		}
	})

	// Adds link to text
	.filter('addLink', () => {
		return function (item) {

			item = ('' + item).replace(new RegExp('(http)*[s]*(:\/\/).+', 'gi'), '<a ng-href="$&" target="_blank">$&</a>');
			return item;
		}
	})

	// Adds link to MLS number in a note
	.filter('addMLS', () => {
		return function (item) {
			item = ('' + item).replace(new RegExp('([M|S]{1}[0-9]{6,9})', 'g'), '<a href="/listings/0/$&/details/menu/4" target="_blank">$&</a>');
			return item;
		}
	})


	// Returns first name + first letter of last name + '.'
	.filter('firstNameOnly', () => {
		return function (item) {
			if (!item) return 'N/A';
			item = item || '';
			return item.substring(0, item.indexOf(' ') + 2) + '.';
		}
	})

	// Adds <wbr> tag after slash and dots
	.filter('url', () => {
		return function (url) {
			return url.replace(/([/\.]{1})([0-9]+|[A-z]+)/gi, '$1<wbr>$2');
		}
	})

	// Highlights the item searched. Based on ui-bootstrap's typeahead filter
	.filter('highlightSearch', () => {
		return function (item, filter) {
			function escapeRegexp(queryToEscape) {
				// Regex: capture the whole query string and replace it with the string that will be used to match
				// the results, for example if the capture is "a" the result will be \a
				return queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
			}

			// Replaces the capture string with a the same string inside of a "strong" tag
			const ret = filter ? ('' + item).replace(new RegExp(escapeRegexp(filter) + '(?![^<]*>)', 'gi'), '<mark><strong>$&</strong></mark>') : item;


			return ret;
		};
	})

	.filter('addNewLines', ($sce) => {
		return function (item) {
			item = (item || '').replace(/&#10;/g, '<br>');
			item = (item || '').replace(/\n/g, '<br>');
			return item;
		};
	})

	// Transforms a number in a money format (North America) String, adding a comma for every three digits (right to left)
	.filter('money', () => {
		return function (item) {
			if (item == null) return 0;
			item += '';
			//Adds a comma every 3 digits (right to left)
			while (item.match(/\d{4}/)) {
				item = item.replace(/(\d{3})(,\d|$)/, ',$1$2');
			}
			return item;
		};
	})

	// 2017-03-08 11:40 am
	.filter('dateFormat', () => {
		return function (theDate, unix) {
			if (!theDate) return '';
			if (unix) return moment.unix(theDate).format('YYYY-MM-DD hh:mm a');
			return moment(theDate).format('YYYY-MM-DD hh:mm a');
		}
	})

	// Wednesday June 21
	.filter('dateFormat2', () => {
		return function (theDate) {
			if (!theDate) return '';
			return moment.utc(theDate).format('dddd MMMM DD');
		}
	})

	// Jun. 21, 2017
	.filter('dateFormat3', () => {
		return function (theDate, unix) {
			if (!theDate) return '';
			return (unix ? moment.unix(theDate) : moment.utc(theDate)).format('MMM. DD, YYYY');
		}
	})

	// 24-03-2018
	.filter('dateFormat4', () => {
		return function (theDate, unix) {
			if (!theDate) return '';
			return (unix ? moment.unix(theDate) : moment.utc(theDate)).format('DD-MM-YYYY');
		}
	})

	// June 21, 2017
	.filter('dateFormatLong', () => {
		return function (theDate, unix) {
			if (!theDate) return '';

			if (unix) {
				return moment.unix(theDate).format('MMMM DD, YYYY');
			}
			return moment.utc(theDate).format('MMMM DD, YYYY');
		}
	})

	// Jun. 21
	.filter('dateFormatTaskMobile', () => {
		return function (theDate) {
			if (!theDate) return '';
			return moment.utc(theDate).format('MMM. DD');
		}
	})

	// Tuesday April 2, 2017, 6:32 PM
	.filter('dateFormatComplete', () => {
		return function (theDate) {
			if (!theDate) return '';
			return moment(theDate).format('dddd MMMM DD, YYYY, h:mm A');
		}
	})

	.filter('dateFormatMoment', () => {
		return function (theDate, format, unix, utc) {
			if (!theDate) return '';

			let date;

			if (unix) {
				date = moment.unix(theDate);
			}
			else if (utc) {
				date = moment.utc(theDate);
			}
			else {
				date = moment(theDate);
			}

			return date.format(format);
		}
	})

	.filter('name', () => {
		return function (user) {
			let name = '';
			if (user) {
				if (user.firstName) {
					name = name.concat(' ' + user.firstName);
				}
				if (user.lastName) {
					name = name.concat(' ' + user.lastName);
				}
			}
			return name;
		}
	})

	.filter('duration', ($filter) => {
		return function (start, end) {
			let ret = '';
			if (start && end) {
				const diff = moment(end).diff(moment(start), 'minutes');

				const hours = Math.floor(diff / 60);
				const mins = diff - (hours * 60);

				if (hours > 0) {
					ret += '' + hours;
					if (mins > 0) {
						ret += ':' + mins;
					}
					ret += 'h';
				}
				else {
					ret += mins + ' min';
				}
			}
			else {
				ret = 'No duration';
			}
			return ret;
		}
	})

	.filter('condenseDay', ($filter) => {
		return function (day) {
			const now = moment();

			// Less than one hour ago
			if (now.diff(moment(day), 'hours') == 0) {
				let minutes = now.diff(moment(day), 'minutes');

				if (minutes == 0) {
					minutes = 1;
				}

				return $filter('translate')('DATES.MINUTES_AGO',{ num: minutes });
			}

			// Less than 24 hours ago
			else if (now.diff(moment(day), 'hours') < 24) {
				const hours = now.diff(moment(day), 'hours');
				return $filter('translate')('DATES.HOURS_AGO',{ num: hours });
			}

			// Yesterday
			else if (now.subtract(1, 'day').isSame(day, 'day')) {
				return $filter('translate')('DATES.YESTERDAY');
			}

			// Less than one week
			else if (now.isSame(day, 'week')) {
				return moment(day).format('dddd');
			}
			else {
				return moment(day).format('MMM D');
			}
		}
	})

	.filter('filterImage', () => {
		return function (list, term) {
			if (term) {
				const returnValue = [];
				for (let i = 0; i < list.length; i++) {
					if (list[i].name.indexOf(term) >= 0) {
						returnValue.push(list[i]);
					}
				}
				return returnValue;
			}
			else {
				return list;
			}
		};
	})

	.filter('filterContact', () => {
		return function (list, term) {
			if (term && list) {
				const regex = new RegExp(term, 'gi');
				const returnValue = list.reduce((arr,item) => {
					if (((item || {}).name || '').match(regex)) {
						arr.push(item);
					}
					return arr;
				}, []);

				return returnValue;
			}
			else {
				return list;
			}
		};
	})

	/** Takes the time stamp out of file names */
	.filter('noTimeStamp', () => {
		return function (fileName) {
			if (fileName.includes('_'))
				return fileName.substring(fileName.indexOf('_') + 1);

			return fileName;
		};
	})

	.filter('modalTitle', () => {
		return function (title) {
			switch (title) {
			case 'caravan': return 'Caravan Bulletin';
			case 'detailedFeature': return 'Detailed Feature Sheet';
			case 'video': return 'Video';
			case 'feature': return 'Feature Sheet';
			}
		};
	})

	.filter('documentType', () => {
		return function (title) {
			switch (title) {
			case 'APPRAISAL': return 'Appraisal';
			case 'DEED_OF_SALE': return 'Deed of Sale';
			case 'APPENDIX-DRINKING_WATER_AND_SEPTIC_INSTALLATION': return 'Appendix: Drinking Water and Septic Installation';
			case 'APPENDIX_DR-OUTLAY_AND_RETRIBUTIONS': return 'Appendix DR: Outlay and Retributions';
			case 'APPENDIX_F-FINANCING': return 'Appendix F: Financing';
			case 'APPENDIX_G-GENERAL': return 'Appendix G: General';
			case 'APPENDIX_R-RESIDENTIAL_BUILDING': return 'Appendix R: Residential Building';
			case 'BANK_STATEMENT': return 'Bank Statement';
			case 'BILLS_ELECTRICITY_OIL_GAS': return 'Bills: Electricity, Oil, Gas';
			case 'BROKERAGE_CONTRACT': return 'Brokerage Contract';
			case 'BUYER_INFORMATION': return 'Buyer Information';
			case 'CERTIFICATE_OF_LOCATION': return 'Certification of Location';
			case 'CONSENT_OF_COMMUNICATION': return 'Consent of Communication';
			case 'CONVENTIONS_AND_RESTRICTIONS': return 'Conventions and Restrictions';
			case 'DETAILED_LISTING': return 'Detailed Listing';
			case 'INFORMATION_REQUEST-MORTGAGE_LOAN': return 'Information Request: Mortgage Loan';
			case 'INSTALLATIONS_DESCRIPTION': return 'Installations Description';
			case 'INSURANCE': return 'Insurance';
			case 'LOCATION_CONTRACT_OF_INCLUDED_APPLIANCES': return 'Contract of Included Applicances';
			case 'MARKETING_PLAN': return 'Marketing Plan';
			case 'MEASUREMENTS': return 'Measurements';
			case 'MODIFICATIONS': return 'Modifications';
			case 'MUNICIPAL_TAXES': return 'Municipal Taxes';
			case 'NOTICE_OF_EXERCISE': return 'Notice of Exercise';
			case 'OFFER-COUNTER_OFFER': return 'Offer/Counter Offer';
			case 'OTHER': return 'Other';
			case 'PROPOSAL_TO_CLIENT': return 'Proposal To Client';
			case 'RENOVATION_BILLS': return 'Renovation Bills';
			case 'SCHOOL_TAXES': return 'School Taxes';
			case 'SELLERS_DECLARATIONS': return 'Seller\'s Declarations';
			case 'SERVITUDES': return 'Servitudes';
			case 'SURVEY/PLAN': return 'Survey/Plan';
			case 'WARRANTIES_OF_INCLUDED_APPLIANCES': return 'Warranties of Included Appliances';
			}
		};
	})

	.filter('folderName', ($filter) => {
		return function (title) {
			switch (title) {
			case 'lmlbb': return $filter('translate')('REPORTS.SUB.LISTINGS_BROKER');
			case 'lmsbb': return $filter('translate')('REPORTS.SUB.SALES_BROKER');
			case 'ler': return $filter('translate')('REPORTS.SUB.LISTING_EXPIRY');
			case 'sbr': return $filter('translate')('REPORTS.SUB.SALES_BY_REGION');
			case 'ytd': return 'Year to Date';
			}
			return title;
		};
	})
	.filter('role', ($filter) => {
		return function (profile) {

			let translateId = '';

			switch (profile) {
			case 'agencyOwnerProfile': translateId = 'ROLES.AGENCY_OWNER'; break;
			case 'agencyMemberProfile': translateId = 'ROLES.AGENCY_MEMBER'; break;
			case 'brokerProfile': translateId = 'ROLES.BROKER'; break;
			case 'managerProfile': translateId = 'ROLES.MANAGER'; break;
			case 'auditorProfile': translateId = 'ROLES.AUDITOR'; break;
			case 'notaryProfile': translateId = 'ROLES.NOTARY'; break;
			case 'adminProfile': translateId = 'ROLES.ADMIN'; break;
			default: translateId = 'ROLES.UNRECOGNIZED';
			}

			const translated = $filter('translate')(translateId);

			return translated.$$unwrapTrustedValue();
		};
	})
	.filter('role-short', () => {
		return function (profile) {
			if (!profile) {
				profile = null;
			}
			switch (profile) {
			case 'agencyOwnerProfile': return 'A-O';
			case 'agencyMemberProfile': return 'A-M';
			case 'brokerProfile': return 'B';
			case 'managerProfile': return 'M';
			case 'auditorProfile': return 'A';
			case 'notaryProfile': return 'N';
			case 'adminProfile': return 'ADM';
			case null: return '';
			}
			return 'N/A';
		};
	})
	.filter('priceRange', () => {
		return function (listing) {
			if (!listing || listing.id.match(/^S\d{6,}\b/) == null) {
				return listing;
			}
			let price = listing.askingPrice;
			if (price == undefined) {
				price = null;
			}
			if (price <= 400000) { listing.priceRange = 'A'; }
			else if (price > 400000 && price <= 800000) { listing.priceRange = 'B'; }
			else if (price > 800000) { listing.priceRange = 'C'; }
			else if (price == null) { listing.priceRange = ''; }
			else { listing.priceRange = '' }

			return listing;
		};
	})

	.filter('frequency', () => {
		return function(freq) {
			switch(freq){
			case 'm' : return 'month';
			case 'a': return 'year';
			}
		}
	})

	.filter('multilineArray', () => {
		return function(arr) {
			if (arr) {
				let toReturn = '';
				for (let i = 0; i < arr.length; i++) {
					toReturn += arr[i] + '<br>';
				}
				return toReturn;
			} else {
				return '---';
			}
		}
	})

	.filter('noteHeader', (translateFilter) => {
		return function (header) {
			const ret = (header || '').split(':');

			if (ret.length > 1) {
				header = translateFilter(ret[0]) + ':' + ret[1];
			}

			return header;
		};
	})

	.filter('titlecase', () => {
		return function(input, args) {
			if(!input) return '';
			return input.charAt(0).toUpperCase() + input.slice(1);
		}
	})

	.filter('nameFormatted', () => {
		return function(name) {

			if (!name) return name;

			let formatted = '';

			function _doReplace(ch, str, lower) {

				str = str || '';

				if (!str.includes(ch)) return str;

				const split = str.split(ch);
				let ret = '';

				split.forEach((part, i) => {
					if (part.length > 0) {
						ret += part.charAt(0).toUpperCase();
					}
					if (part.length > 1) {
						const sub = part.substring(1);
						if (lower) {
							ret += sub.toLowerCase();
						}
						else {
							ret += sub;
						}
					}

					if (i < split.length - 1) {
						ret += ch;
					}
				});

				return ret;
			}

			formatted = _doReplace(' ', name, true);
			formatted = _doReplace('-', formatted, false);
			formatted = _doReplace('\.', formatted, false);

			return formatted;
		}
	})
})();
