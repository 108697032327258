/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('PaymentController', function ($filter, $sce, AlertService, ModalService, PaymentService) {
		const self = this;

		// VARIABLES ============================================================================================================================

		let loadingSub;             // False when call to get subscription is finished
		let loadingHis;             // False when call to get history is finished
		let loadingCard;            // False when call to get card information is finished
		let invoiceFiles;           // Invoice files -> Used for download links in subscription history

		self.isOwner      = null;   // Whether the current user is the owner of the subscription -> If false, not all information is shown
		self.currentSub   = {};     // Current subscription
		self.currentPlan  = null;   // Basic information about plans that customer has
		self.history      = null;   // Invoice history
		self.card         = null;     // Credit card that user is currently using to pay for their subscription

		self.columns      = null;   // Columns for 'Subscription History' table

		// SETUP VARIABLES ======================================================================================================================

		self.columns = [
			{
				title: 'ACCOUNT.PLANS',
				size: 2,
				getData: function(subscription) {

					let ret = '';

					if (subscription.object === 'charge') {
						ret += $filter('translate')('ACCOUNT.CUSTOM_CHARGE');

						if (subscription.refunded) {
							ret += ' (' + $filter('translate')('ACCOUNT.REFUNDED') + ')';
						}
						else if (subscription.amount_refunded > 0) {
							ret += '<br>(' + $filter('translate')('ACCOUNT.PARTIAL_REFUND') + ': ' + $filter('currency')((subscription.amount_refunded / 100), '$', 2) + ')';
						}

						return ret;
					}

					const data = (subscription.lines || {}).data || [];

					data.forEach((item) => {
						let toAdd = '';
						if (item.plan) {
							toAdd = '' + $filter('translate')('ACCOUNT.SUBSCRIPTION_HISTORY_PLANS.' + (item.plan || {}).id || '') + '<br>';
						}
						else {
							toAdd = '' + $filter('translate')('COMMON.UPDATE') + '<br>';
						}

						if (ret.match(toAdd) === null) {
							ret += toAdd;
						}
					})
					return ret;
				}
			},
			{
				title: 'COMMON.PRICE',
				getData: function(subscription) {

					let ret = '' + $filter('currency')((subscription.subtotal / 100), '$', 2);

					if ((subscription.amount_refunded || 0) > 0) {
						ret = '<span class="line-through">' + ret + '</span><br>' + $filter('currency')(((subscription.subtotal - subscription.amount_refunded) / 100), '$', 2);
					}

					return ret;
				}
			},
			{
				title: 'DATES.CREATED',
				getData: function(subscription) {
					return $filter('date')(subscription.date * 1000);
				}
			},
			{
				title: 'ACCOUNT.PAID',
				getData: function(subscription) {
					return $filter('translate')((subscription.paid || subscription.amount_due == 0) ? 'ACCOUNT.PAID' : 'ACCOUNT.NOT_PAID');
				},
				getClass: function(subscription) {
					return (subscription.paid || subscription.amount_due == 0 )? '\'upper-case paid\'' : '\'upper-case not-paid\'';
				}
			},
			{
				title: '',
				getClass: function(subscription) {
					return '\'download\'';
				},
				getLink: function(subscription) {
					return subscription.invoice_pdf;
				},
				isDownload: true
			}
		];

		self.totalSpan = self.columns.reduce((total,col) => {
			total += col.size || 1;
			return total;
		}, 0)

		AlertService.loading();
		getSubscription();

		// FUNCTIONS ============================================================================================================================

		self.newCard = function () {
			const modal = ModalService.openModal('createCard', { oldCard: function () { return self.card; } }, 'CardController', 'cardController');
			modal.result.then((res) => {
				AlertService.saved();
			})
			.catch((err) => {
				console.log('error: ', err);
			})
			.finally(() => {
				getSubscription();
			})
		}

		self.updateCard = function () {
			const modal = ModalService.openModal('createCard', {}, 'CardController', 'cardController');
			modal.result.then((res) => {
				AlertService.saved();
			})
			.catch((err) => {
				console.log('error: ', err);
			})
			.finally(() => {
				getSubscription();
			})
		}

		self.getTrialEnd = function() {

			const trialEnd = (self.currentSub.sub || {}).trial_end || null;
			let ret;
			if (trialEnd) {
				const diff = moment.unix(trialEnd).diff(moment(), 'minutes');
				const oneDay = 24 * 60;

				if (diff > oneDay) {
					return { time: Math.ceil( diff / oneDay ), unit: 'd' };
				}
				else if (diff >= 60) {
					return { time: Math.floor( diff / 60 ), unit: 'h' };
				}
				else {
					return { time: diff, unit: 'm' };
				}
			}
			return { time: 0, unit: 'h' };
		}

		self.updateSubscription = function () {
			const modal = ModalService.openModal('updateSubscription', { subscription: function() { return angular.copy(self.currentSub); } }, 'SubscriptionController', 'subscriptionController');
			modal.result.then((res) => {
				AlertService.saved();
			})
			.catch((err) => {
				console.log('error: ', err);
			})
			.finally(() => {
				getSubscription();
			})
		}

		self.getPrice = function() {
			return '(' + $filter('currency')(self.currentPlan.priceBroker, '$', 2) + ' x ' +
          self.currentPlan.numBrokers + ' ' +
          $filter('translate')('ROLES.BROKER') + 's) = ' +
          '<strong>' + $filter('currency')(self.currentPlan.priceTotal, '$', 2) + '</strong>';
		}

		self.cancelPlan = function() {
			const sub = self.currentSub.sub || {};
			if (sub.id) {
				AlertService.loading();
				PaymentService.cancelPlan(sub.id).then((res) => {
					AlertService.saved();
				})
				.catch((err) => {
					console.log('error: ', err);
				})
				.finally(() => {
					getSubscription();
				})
			}
		}

		self.renewPlan = function() {
			const sub = self.currentSub.sub || {};
			if (sub.id) {
				AlertService.loading();
				PaymentService.renewPlan(sub.id).then((res) => {
					AlertService.saved();
				})
				.catch((err) => {
					console.log('error: ', err);
				})
				.finally(() => {
					getSubscription();
				})
			}
		}

		// When loading is finished, adds invoice file to each invoice data
		function checkLoading() {
			if (!loadingSub && !loadingHis && !loadingCard) {
				let i;
				const length1 = (invoiceFiles || []).length;
				let j;
				const length2 = (self.history || []).length;

				for (i = 0; i < length1; i++) {
					for (j = 0; j < length2; j++) {
						if (invoiceFiles[i].invoiceId == self.history[j].id) {
							self.history[j].file = angular.copy(invoiceFiles[i]);
							break;
						}
					}
				}
				AlertService.doneLoading();
			}
		}

		// All functions to get subscription data
		function getSubscription() {
			loadingSub = true;
			loadingHis = true;
			loadingCard = true;

			PaymentService.getSubscription()
			.then((res) => {
				self.currentSub = res;
				self.isOwner = res.isOwner;

				invoiceFiles = (res.sub || {}).invoices || [];

				const agencyPlan = (res.agency || {}).plan || {};
				const brokerPlan = (res.broker || {}).plan || {};
				self.currentPlan = {
					id: agencyPlan.id || brokerPlan.id || 'N/A',
					priceBasic: (agencyPlan.amount || brokerPlan.amount || 0) / 100,
					priceBroker: (brokerPlan.amount || 0) / 100,
					numBrokers: (res.broker || {}).quantity || 0,
					priceTotal: (agencyPlan.amount || 0) / 100,
					status: (res.sub || {}).status || '',
					cancelOnEnd: (res.sub || {}).cancel_at_period_end
				}

				if (self.currentPlan.priceBroker > 0 && self.currentPlan.numBrokers > 0) {
					self.currentPlan.priceTotal += self.currentPlan.priceBroker * self.currentPlan.numBrokers;
				}
			})
			.catch((err) => {
				self.isOwner = true;
				console.log('error: ', err);
			})
			.finally(() => {
				loadingSub = false;
				checkLoading();
			})

			PaymentService.getSubscriptionHistory()
			.then((res) => {
				self.history = res;
			})
			.catch((err) => {
				console.log('error: ', err);
			})
			.finally(() => {
				loadingHis = false;
				checkLoading();
			})

			PaymentService.getCard()
			.then((res) => {
				const [card] = res.data || [];
				if (card) {
					card.number = `**** **** **** ${self.card.last4}`
					self.card = card;
				}
			})
			.catch((err) => {
				console.log('error: ', err);
			})
			.finally(() => {
				loadingCard = false;
				checkLoading();
			})
		}

	});
})();
