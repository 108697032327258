/* eslint-disable no-undef */
(function() {

	'use strict';

	angular.module('smartbrokr.directives')
	.controller('ListController', ['$document', '$filter','$location', '$sce', '$scope', '$state', '$window','SortingService', function($document, $filter, $location, $sce, $scope, $state, $window, SortingService) {

		const self = this;

		let allItems     = $scope.entries;
		let sortInfo     = $scope.sort || {};
		let checkInfo    = $scope.checkInfo || {};
		self.show        = allItems;
		self.reorder     = false;

		self.doReorder = function() {
			if ($scope.onReorder) {
				$scope.entries.forEach((entry) => {
					const i = self.show.findIndex((item) => {
						return item.id == entry.id;
					})

					if (i >= 0) {
						entry.seq = i + 1;
					}
				})

				$scope.entries.sort((a,b) => {
					return a.seq - b.seq;
				})

				$scope.onReorder();
			}
		}

		if ($scope.inject) {
			$scope[$scope.inject.name] = $scope.inject.inject;
		}

		$scope.trustAsHtml = function(content) {
			return $sce.trustAsHtml(content);
		}

		self.filter = function() {
			self.show = $filter('searchMultiFilter')(allItems, $scope.filters);
		}

		self.sort = function (category, keepDesc) {
			SortingService.sort(self.show, $scope.filters, category, sortInfo.sub, sortInfo.page, keepDesc);
		};

		// Checks all items
		self.checkAll = function () {
			let i;
			const length = allItems.length;

			if ($scope.viewType === 'card') {
				checkInfo.allChecked = !checkInfo.allChecked;
			}

			for (i = 0; i < length; i++) {
				allItems[i].checked = checkInfo.allChecked;
			}
			checkInfo.nSelected = checkInfo.allChecked ? allItems.length : 0;
			self.enableSelect = true;
		}

		self.checkOne = function (id) {
			const i = allItems.findIndex((item) => { return item.id === id; });

			if ($scope.viewType === 'card') {
				allItems[i].checked = !allItems[i].checked;
			}

			// If true: 1 * 2 - 1 = 1; If false: 0 * 2 - 1 = -1
			checkInfo.nSelected += (allItems[i].checked && 1) * 2 - 1;
		}

		self.navigate = function(entry) {
			if(entry.shared && entry.shared.length > 0 && entry.shared[0].status === 'cancelled') {
				return;
			}

			const navParams = $scope.navParams || {};
			if (!entry.readOnly) {
				let params = {};

				if (navParams.state || navParams.getState) {
					let state = navParams.state;

					if (navParams.getParams) {
						params = navParams.getParams(entry);
					}
					else if (navParams.params) {
						params = navParams.params;
					}

					if (navParams.getState) {
						state = navParams.getState(entry);
					}

					if (!state) return;

					$state.go(state, params);
				}
				else if (navParams.hash) {
					let hash = angular.copy(navParams.hash);
					if (typeof hash == 'function') {
						hash = hash(entry);
					}
					$location.hash(hash);
				}
				else if (navParams.onClick) {
					navParams.onClick(entry);
				}
				else if (entry.externalLink) {
					$window.open(entry.externalLink);
				}
			}
			else if (navParams.onClick) {
				navParams.onClick(entry);
			}
		}
		// Added watch because entries are usually loaded asynchronously
		$scope.$watch('entries',() => {
			allItems    = $scope.entries || [];
			self.show   = allItems;
			sortInfo    = $scope.sort || {};
			checkInfo   = $scope.checkInfo || {};
			self.filter();
			doColumns();
		});

		function doColumns() {
			const columns = $scope.columns;
			let total = 0;

			if (columns) {
				columns.forEach((col) => {
					if (col.size) {
						total += col.size;
					}
					else {
						total += 1;
					}
				})

				if (!$scope.noFirstColumn) {
					total += 1;
				}
			}

			$scope.totalColumns = total;
		}
	}])

	/* DIRECTIVES =========================================================================================  */

	.directive('sbViewBtn', () => {
		return {
			restrict: 'E',
			template: '<button class="btn btn-green md-height no-padding-v" ng-click="changeView()"><i class="glyphicon" aria-hidden="true" ng-class="{ \'glyphicon-th-large\': cardView, \'glyphicon-th-list\': !cardView }"></i></button>',
			scope: {
				cardView: '=',
				page: '='
			},
			controller: ['$rootScope', '$scope', 'StorageService', function ViewBtnController($rootScope, $scope, StorageService) {

				$scope.changeView = function() {
					$scope.cardView = !$scope.cardView;

					if ($scope.page) {
						StorageService.setView($scope.page, ($scope.cardView && 'card') || 'list');
					}
				}

				const w = $rootScope.$watch('screenSize', (newValue) => {
					const storedView = StorageService.getView($scope.page);
					if (newValue === 'lg' && storedView !== 'card') {
						$scope.cardView = false;
					}
					else if (newValue !== 'lg') {
						$scope.cardView = true;
					}
				})

				// Destroy watcher
				$scope.$on('$destroy', () => {
					w();
				})
			}]
		};
	})

	.directive('sbListView', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/partials/list-view.html',
			controller: 'ListController',
			controllerAs: 'listController',
			scope: {
				entries: '=',
				sort: '=',
				columns: '=',
				filters: '=',
				special: '=',
				navParams: '=?',
				inject: '=',
				noCheck: '=',
				noFirstColumn: '=',
				topClass: '@',
				checkInfo: '=?',
				onReorder: '=',
				noSort: '@?',
				perPage: '=?',
				pagButtons: '@?',
				noResults: '@?'
			},
			link: function (scope, element, attrs) {
				scope.pagButtons = scope.pagButtons || 'both';
				scope.listId = 'sbItemPagination_' + scope.$id;
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
				scope.viewType = 'list';
				scope.limitedAccess = scope.$root.limitedAccess;

				if(!attrs.navParams) {
					attrs.navParams = {};
					scope.navParams = attrs.navParams;
				}

				if (!attrs.checkInfo) {
					attrs.checkInfo = {};
					scope.checkInfo = attrs.checkInfo;
				}

				if (!attrs.perPage) {
					attrs.perPage = 10;
					scope.perPage = attrs.perPage;
				}
			}
		};
	})

	.directive('sbCardView', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/partials/card-view.html',
			controller: 'ListController',
			controllerAs: 'listController',
			scope: {
				entries: '=',
				sort: '=',
				headers: '=',
				filters: '=',
				special: '=',
				model: '=',
				navParams: '=?',
				checkInfo: '=?',
				isListing: '='
			},
			link: function (scope, element, attrs) {
				scope.root = scope.$root;
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
				scope.viewType = 'card';
				scope.limitedAccess = scope.$root.limitedAccess;

				if (!attrs.model) {
					attrs.model = 'person';
				}

				if(!attrs.navParams) {
					attrs.navParams = {};
				}

				if (!attrs.checkInfo) {
					attrs.checkInfo = {};
				}
			}
		};
	})

	.directive('sbDndList', () => {
		return {
			restrict: 'E',
			templateUrl: 'templates/partials/dnd-list.html',
			link: function (scope, element, attrs) {}
		};
	})
})();
