/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('ResourcesController', function (ModalService, $filter, $state, $stateParams, $timeout, AccountService, AlertService, DocusignService, FileService, GlobalVars, NavService, SortingService, ResourceService, UploadService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.resources		= {};		// All loaded resources

		self.current		= {};		// Current page
		self.options		= null;		// Option for each page controlled by this controller

		self.singleConfig	= null;		// Selectize config
		self.filters		= null;		// Parameters for sort/filter
		self.type = '';
		self.shareType = 'email';
		self.templateId = '';

		self.userId = AccountService.getUserId();

		self.shared = ($stateParams.submenu || {}).indexOf('shared') > -1;

		const templateCats 	= [];

		// SETUP VARIABLES ======================================================================================================================

		self.singleConfig = GlobalVars.singleConfig;

		self.options = {
			'marketing-image': {
				load: ResourceService.getMarketingImages,
				columns: [
					{
						name: 'FILES.FILE_NAME',
						filter: 'originalFilename',
						size: 2,
						field: function (resource) {
							  return resource.originalFilename || resource.name;
						}
					},
					{
						name: 'FILES.SOURCE',
						noSearch: true,
						noSort: true,
						isHtml: true,
						size: 1,
						class: 'source-link',
						field: function (resource) {
							return '<a ng-href="' + resource.url + '" target="_blank">' + $filter('translate')('FILES.VIEW') + '</a>' +
									'<div ng-class="{ \'copied\': entry.copied }">' +
									'<a ng-disabled="entry.copied" href ng-click="resourcesController.copyLink(entry)">' +
									'<span ng-show="entry.copied">(' + $filter('translate')('COMMON.COPIED') + ')</span>' +
									'<span ng-hide="entry.copied">(' + $filter('translate')('FILES.COPY_LINK') + ')</span>' +
									'</a></div>';
						}
					},
					{
						name: 'FILES.PREVIEW',
						noSearch: true,
						noSort: true,
						isHtml: true,
						size: 3,
						class: 'marketing-preview',
						field: function (resource) {
							  return '<img src="' + resource.url + '">';
						}
					},
					{
						name: '',
						noSearch: true,
						noSort: true,
						isHtml: true,
						class: 'buttons',
						field: function (resource) {
							  return '<button class="btn btn-trash" ng-disabled="limitedAccess" ng-click="resourcesController.deleteResource(\'' + resource.id + '\')"></button>';
						}
					},
				],
				inject: {
					name: 'resourcesController',
        				inject: self
				}
			},
			'image-for-video': {
				load: ResourceService.getImagesForVideo
			},
			form: {
				load: ResourceService.getForms
			},
			task: {
				load: ResourceService.getTaskTemplates,
				columns: [
					{
						name: 'RESOURCES.TASK_TEMPLATE_NAME',
						filter: 'name',
						field: function (template) {
							  return template.name;
						}
					},
					{
						name: 'COMMON.DESCRIPTION',
						filter: 'description',
						isHtml: true,
						noSearch: true,
						field: function (template) {
							  return $filter('addNewLines')(template.description);
						}
					},
					{
						name: 'TASK.NUMBER_OF_TASKS',
						filter: 'tasks',
						noSearch: true,
						field: function (template) {
							  return (template.tasks || []).length || 0;
						}
					},
					{
						name: 'PROPERTY.CATEGORY',
						filter: 'category',
						select: true,
						options: templateCats,
						config: self.singleConfig,
						isHtml: true,
						field: function (template) {
							let ret = '';
							templateCats.forEach((cat) => {
								if (cat.value !== 'xxx' && template[cat.value]) {
									ret += '<div>' + cat.name + '</div>';
								}
							})

							return ret;
						}
					},
					{
						name: '',
						noSearch: true,
						noSort: true,
						isHtml: true,
						class: 'buttons',
						field: function (template) {
							const buttons = '<button class="btn btn-green medium"' +
									'ng-disabled="limitedAccess"' +
									'ng-click="smartbrokrController.navigate(\'main.resources.task-templates.edit\',{id:entry.id, template:entry})">' +
									$filter('translate')('COMMON.UPDATE') + '</button>' +
									'<button class="btn btn-link"' +
									'ng-disabled="limitedAccess"' +
									'ng-click="resourcesController.deleteTaskTemplate(entry.id)">' +
									'<img width="16" height="16" src="/public/img/iconTrash.svg"></button>';

                 				return buttons;
						}
					},
				],
				inject: {
					name: 'resourcesController',
        				inject: self
				}
			},
			email: {
				load: ResourceService.getEmailTemplates,
				columns: [
					{
						name: 'RESOURCES.EMAIL_TEMPLATE_NAME',
						filter: 'name',
						field: function (template) {
							return template.name;
						}
					},
					{
						name: 'FILES.PREVIEW',
						noSort: true,
						isHtml: true,
						noSearch: true,
						size: 2,
						class: 'preview-email',
						field: function (template) {
							return $filter('ignoreTags')(template.text);
						}
					},
					{
						name: '',
						noSort: true,
						isHtml: true,
						noSearch: true,
						class: 'buttons',
						field: function (template) {
							if(self.shared) {
								return '';
							} else {
								const buttons = getShareIndicator(template) +
								'<button class="btn btn-green medium"' +
										'ng-disabled="limitedAccess"' +
										'ng-click="resourcesController.openMailModal(entry)">' +
										$filter('translate')('COMMON.UPDATE') + '</button>' +
										'<button class="btn btn-link"' +
										'ng-disabled="limitedAccess"' +
										'ng-click="resourcesController.deleteEmailTemplate(entry.id)">' +
										'<img width="16" height="16" src="/public/img/iconTrash.svg"></button>';

								return buttons;
							}
						}
					}
				],
				inject: {
					name: 'resourcesController',
        				inject: self
				}
			},
			'shared-email': {
				load: ResourceService.getSharedEmailTemplates,
				columns: [
					{
						name: 'RESOURCES.EMAIL_TEMPLATE_NAME',
						filter: 'name',
						field: function (template) {
							return template.name;
						}
					},
					{
						name: 'FILES.PREVIEW',
						noSort: true,
						isHtml: true,
						noSearch: true,
						size: 2,
						class: 'preview-email',
						field: function (template) {
							return $filter('ignoreTags')(template.text);
						}
					},
					{
						name: '',
						noSort: true,
						isHtml: true,
						noSearch: true,
						class: 'buttons',
						field: function (template) {
							return '';
						}
					}
				],
				inject: {
					name: 'resourcesController',
        				inject: self
				}
			}
		}

		function getShareIndicator(template) {
			const shareButton = '<button class="btn btn-green medium mr-1"' +
			'ng-disabled="limitedAccess"' +
			'ng-click="resourcesController.share(entry.id)">' +
			$filter('translate')('RESOURCES.SHARE') +
			'</button>';

			if(template.sbUserId === self.userId) return shareButton;
			return '<label class="mr-2">' + $filter('translate')('RESOURCES.SHARED_WITH_YOU') + '</label>' ;
		}

		const load = function () {
			if (self.current.load) {
				AlertService.loading();

				self.current.load().then((res) => {
					self.resources = res;
				})
				.catch((err) => {})
		  			.finally(AlertService.doneLoading)
			}
		}

		function getOptions(marketingImages = false) {
			const options = {
				hideFunction: null,
				hideFlag: false,
				hideCondition: function() { return ; },
				setUrl: function(id, reqWidth, reqHeight) {
					self.uploader.url = UploadService.baseUrl + '/Folders/' + id + '/uploadFile';
					self.uploader.filters[self.uploader.filters.length - 1] = UploadService._sizeFilter({ w: reqWidth, h: reqHeight });
				},
				deleteFunction: function(id, fk, isGenerated, agencyFolder) {
					ResourceService.deleteDocument(id, fk, isGenerated, agencyFolder).then((res) => {
						if (res) {
							load();
						}
					})
					.catch((err) => {})
				},
				editFunction: function(event, folder){
					event.stopPropagation();
					event.preventDefault();
					const modal = ModalService.openModal('createFolder', { folder: function () { return { folder:folder }}, type: function() { return self.type }, options: function() { return { } }  }, 'FolderController', 'folderController');
					modal.result
					.then((res) => {
						load();
					})
					.catch((err) => {
					});
				},
				folderClass: function(folder) { return null; },
				deleteFolder: function (id) {
					ResourceService.deleteFolder(id).then((res) => {
						if (res) {
							load();
						}
					})
					.catch((err) => {})
				},
				download: function(file, folder) {
					file = file || {};
					folder = folder || {};
					if (file.id && folder.id) {
						FileService.download('Folders', folder.id, file.id);
					}
				},
				uploader: self.uploader,
				searchable: true
			}

			if(marketingImages) {
				options.folderName = function(folder) {
					return FileService.getFolderName(folder.name);
				}
				options.customUploadFunction = function(folder) {
					const type = 'marketing-images';
					const subtype = 'marketing';
					const dimensions = {
						result_w: folder.reqWidth,
						result_h: folder.reqHeight,
						init_h: folder.reqWidth,
						init_w: folder.reqHeight
					}

					const options = {
						includeMarketingImages: false,
						currentFolder: folder.id,
						subtype: subtype
					};

					const m = ModalService.openModal('wpImageUpload', {
						dimensions: () => dimensions,
						type:       () => type,
						options:    () => options
					},
					'WPImageUploadController', 'uploadController',  // controller, controllerAs
					null, 'wp-upload-modal wp-' + type  // scope, windowClass
					);

					m.result.then((res) => {
						load();
					})
					.catch((err) => {});
				}
			}

			return options;
		}

		if ($state.current.name == 'main.resources.marketing-images') {
			self.current = self.options['marketing-image'];
			self.type = 'marketing-images';
			self.uploader = UploadService.initUploadMarketingImage({ w: 1900, h: 600 });
			self.current.options = getOptions(true);
		}
		else if ($state.current.name == 'main.resources.images-for-video') {
			self.current = self.options['image-for-video'];
			self.type = 'images-for-video';
			self.uploader = UploadService.initImageForVideoUploader();
			self.current.options = getOptions();
		}
		else if ($state.current.name == 'main.resources.forms') {
			self.current = self.options['form'];
			self.uploader = UploadService.initFormUploader();
			self.current.options = null;
		}
		else if ($state.current.name == 'main.resources.task-templates') {
			self.current = self.options.task;
			self.current.options = null;
			templateCats.push({ value: 'xxx', name: '------' });
			templateCats.push({ value: 'isPersonal', name: $filter('translate')('PERSON.PERSONAL') });
			templateCats.push({ value: 'isListing', name: $filter('translate')('LISTING.LISTING') });
			templateCats.push({ value: 'isBuyer', name: $filter('translate')('ROLES.BUYER') });
			templateCats.push({ value: 'isSeller', name: $filter('translate')('ROLES.SELLER') });
			templateCats.push({ value: 'isSupplier', name: $filter('translate')('ROLES.SUPPLIER') });
		}
		else if ($state.current.name == 'main.resources.email-templates') {
			self.current = self.options.email;
			self.current.options = null;
		}
		else if ($state.current.name === 'main.resources.shared-email-templates') {
			self.current = self.options['shared-email'];
			self.current.options = null;
		}
		else{
			$state.go('main.resources.marketing-images');
		}

		if (!!self.uploader) {
			self.uploader.onSuccessItem = function(fileItem, response, status, header){
				load();
			}
		}

		load();

		self.filters = {
			marketing: {
				originalFilename: { weight: 0, value: '', desc: 1 }
			},
			video: {
				name: { weight: 0, value: '', desc: 1 }
			},
			forms: {
				name: { weight: 0, value: '', desc: 1 }
			},
			taskTemplates: {
				name: { weight: 0, value: '', desc: 1 },
				description: { weight: 0, value: '', desc: 1 },
				tasks: { weight: 0, value: 0, desc: 1 },
				category: { weight: 0, value: null, desc: 1,
					filterFunction: function(value, item) {
						return item[value];
					},
					sortFunction: function(item) {
						let ret = '';
						templateCats.forEach((cat) => {
							if (cat.value !== 'xxx' && item[cat.value]) {
								ret += cat.name + '+';
							}
						})
						return ret;
					}
				}
			},
			emailTemplates: {
				name: { weight: 0, value: '', desc: 1 },
				purpose: { weight: 0, value: '', desc: 1 },
				text: { weight: 0, value: '', desc: 1 }
			}
		};

		// FUNCTIONS ============================================================================================================================

		self.copyLink = function (file) {
			const fileName = file.url || '';
			const copyTextarea = document.createElement('textarea');
			copyTextarea.value = fileName;
			copyTextarea.style.display = 'hidden';
			document.body.appendChild(copyTextarea);
			copyTextarea.select();
			try {
				document.execCommand('copy');
			}
			catch (err) {
				return false;
			}
			finally {
				document.body.removeChild(copyTextarea);
				file.copied = true;

				$timeout(() => {
					file.copied = false;
				}, 2000);
			}
		}

		self.deleteTaskTemplate = function (id) {
			ResourceService.deleteTaskTemplate(id).then((deleted) => {
				if (deleted) {
					load();
				}
			})
			.catch((err) => {})
		}

		self.deleteEmailTemplate = function (id) {
			ResourceService.deleteEmailTemplate(id).then((deleted) => {
				if (deleted) {
					load();
				}
			})
			.catch((err) => {})
		}

		self.deleteResource = function (id) {
			ResourceService.deleteResource(id).then((res) => {
				if (res) {
					load();
				}
			})
			.catch((err) => {})
		}

		self.download = function(file) {
			file = file || {};
			FileService.download('SbUsers', AccountService.getUserId(), file.id);
		}

		self.sortResources = function (page, category) {
			SortingService.sort(self.resources, self.filters[page], category);
		};

		self.openTaskModal = function () {

			const retFunction = function (result) {
				NavService.navigate('main.resources.task-templates.edit',{ id:result.id, template:result });
			};

			ModalService.openModalWithFunction(
				'taskTemplate',
				{ template: function () { return { name: '', description: '' }; }, editTask: function () { return false; } },
				'TaskTemplateModalController',
				'taskTemplateModalController',
				retFunction
			);
		};

		self.openMailModal = function (template) {
			const newTemplate = template ? false: true;
			template = template || { name: '', text: '' };

			template.text = FileService.replaceUrlsInText(template.text);

			ModalService.openModalWithFunction('emailTemplate', {
				template: function () { return template; },
				newTemplate: function() { return newTemplate }
			},
			'EmailTemplateModalController',
			'emailTemplateModalController',
			load,
			null,
			'email-modal');
		};

		self.createFolder = function () {
			const modal = ModalService.openModal('createFolder', { folder: function () { return {} }, type: function() { return self.type }, options: function() { return { } } }, 'FolderController', 'folderController');
			modal.result
			.then((res) => {
				load();
			})
			.catch((err) => {
			});
		}


		function _getFolders() {
			self.current.load()
			.then((res) => {
				res.forEach((folder) => {
					folder.name = $sce.valueOf($filter('translate')(folder.name));
				});
				self.folders = res;
			})
			.catch((err) => {})
			.finally(() => {
				AlertService.doneLoading();
			});
		}


		self.docusignConnected = false;

		DocusignService.getStatus().then((res) => {
			self.docusignConnected = res.connected;
		})
		.catch((err) => {
			$log.error(err);
		});

		self.docusign = function(file) {
			AlertService.loading();
			DocusignService.sendDocument(file.id).then((res) => {
				AlertService.doneLoading();
				AlertService.successMessage('envelope');
				return DocusignService.popup(res.data.url);
			})
			.catch((err) => {
				AlertService.doneLoading();
				const message = err.data.error.message;
				if(message.indexOf('sent') > -1) {
					AlertService.errorMessage('envelopeExists');
				} else if(message.indexOf('integrated') > -1) {
					AlertService.errorMessage('docusignDisconnected');
				} else {
					AlertService.errorMessage('envelopeCreate');
				}
				$log.error(err);
			});
		}

		self.share = function(templateId) {
			self.templateId = templateId;
			function retFunction() {
				load();
			}
			ModalService.openModalWithFunction('shareTemplate', {
				message: function () { return null; },
				sender: function () { return null; },
				receiver: function () { return null; },
				type: function() { return self.shareType; },
				templateId: function() { return self.templateId }
			}, 'ShareTemplateModalController', 'shareTemplateModalController', retFunction);
		}

	}) // End of controller
})(); // End of function()
