(function () {

	'use strict';

	angular.module('smartbrokr.public', [])

	.service('PublicService', function (SbUser, VisitCoBuyer, VisitSbUser, Visit, Form) {
		const self = this;

		self.getVisit = function(secret){
			return Visit.getPublicVisit({ secret: secret }).$promise;
		}

		self.cancelVisit = function(secret, isOwner, reason, isCoBuyer){
			if(isCoBuyer) return VisitCoBuyer.userCancel({ secret: secret, isOwner: isOwner, reason: reason }).$promise;
			return VisitSbUser.userCancel({ secret: secret, isOwner: isOwner, reason: reason }).$promise;
		}

		self.confirmVisit = function(secret, isOwner, reason, isCoBuyer) {
			if(isCoBuyer) return VisitCoBuyer.userConfirm({ secret: secret, isOwner: isOwner }).$promise;
			return VisitSbUser.userConfirm({ secret: secret, isOwner: isOwner }).$promise;
		}

		self.sendEmail = function(form, type) {
			return Form.publicSupport({ form: form, type: type }).$promise;
		}

	}); // End of controller
})(); // End of function()
