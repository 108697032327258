/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('AccountController', function (AccountService, AlertService, UploadService, $filter, $rootScope, $scope, $window, check) {

		const self = this;

		// VARIABLES ============================================================================================================================

		$scope.photo 			= null;			// Photo being uploaded
		self.canConfirm 		= false;		// True when cropping an image
		self.canUpload 			= true;			// False when cropping an image
		self.cropper 			= null;			// Cropper object
		self.uploader 			= null;			// Uploader object

		self.isAgency			= false;		// True if current user belongs to an agency (all but Notary/Auditor)
		self.isBroker 			= false;		// True if current user is a broker

		self.account 			= null;			// Account object
		self.user 				= null;			// User object (belongs to Account, contains personal information)
		self.agency 			= null;			// User's agency
		self.managers 			= null;			// Broker's manager
		self.currentRole 		= null;			// Label for current role - to be displayed in the form
		self.isGoogleIntegrated = false;
		self.isCalendarIdSet    = false;
		self.showGoogleTrouble  = false;

		const watchers 	= [];						// Array with watchers to be cleaned up on scope.$destroy
		const role = AccountService.getRole();		// Current role

		// SETUP VARIABLES ======================================================================================================================

		self.isAgency 	= role !== 'auditorProfile' && role !== 'notaryProfile' && role !== 'adminProfile';
		self.isBroker 	= role === 'brokerProfile' || role === 'managerProfile';

		self.uploader 	= UploadService.initUserUploader(true, null, { w: 640, h: 640 });

		self.photoOptions = {
			classes: {
				upload: 'btn btn-green no-size upload',
				confirm: 'btn btn-green no-size upload',
				current: 'round avatar',
				new: 'round avatar'
			},
			dimensions: {
				init_w: 640,
				init_h: 640,
				result_w: 640,
				result_h: 640
			},
			checkSize: true
		}

		// Possible values for "role": adminProfile, agencyMemberProfile, agencyOwnerProfile, auditorProfile, brokerProfile, notaryProfile
		self.currentRole = $filter('translate')('ROLES.' + role.replace('Profile', '').replace('agency', 'agency_').toUpperCase());

		getAccount();

		$scope.$on('$destroy', () => {
			watchers.forEach((w) => {
				w();
			})
		})

		// FUNCTIONS ============================================================================================================================
		function getAccount() {
			AlertService.loading();
			AccountService.getAccountDetails(null,true).then((res) => {
				self.account 	= res.account;
				self.user 		= res.user;
				self.agency 	= res.agency;
				self.office 	= res.office;

				if (self.isBroker) {
					AccountService.getManagers().then((res) => {
						self.managers = res.reduce((arr, entry) => {
							arr.push(((entry || {}).manager || {}).fullName || '');
							return arr;
						}, []).join(', ');
					})

					if ((((self.user || {}).brokerProfile || {}).currentCourses || []).length > 0) {
						self.coursesOpts = self.user.brokerProfile.currentCourses.reduce((arr, item) => {
							arr.push({ value: item });
							return arr;
						}, [])
					}
				}
			})
			.catch((err) => {})
			.finally(() => {
				check.clone = {
					account: self.account,
					user: self.user
				}

				check.original = angular.copy(check.clone);

				if(check.original.user.isGoogleIntegrated) {
					self.isGoogleIntegrated = true;
					self.isCalendarIdSet = (check.original.user.googleCalendarId && check.original.user.googleCalendarId !== '');
				}

				if (watchers.length === 0) {
					const w1 = $rootScope.$on('doneAddress', () => {
						const addr = self.user._address;
						check.clone.user._address = addr;
						check.original.user._address = angular.copy(addr);
					})

					watchers.push(w1);
				}

				AlertService.doneLoading();
			})
		}

		self.signIntoGoogle = function() {
			AccountService.getGoogleAuthUrl().then((res) => {
				$window.open(res.url);
			})
			.catch((err) => {});
		}

		self.disableGoogleIntegration = function() {
			AccountService.disableGoogleIntegration().then((res) => {
				AlertService.successMessage('disconnectGoogle');
				self.isGoogleIntegrated = false;
			})
			.catch((err) => {});
		}

		self.syncGoogle = function() {
			AccountService.syncGoogle().then((res) => {
				AlertService.successMessage('synchronizeGoogle');
			})
			.catch((err) => {});
		}

		self.saveAccount = function () {
			check.isSaved = true;
			AccountService.saveAccount(self.account, self.user, self.agency, true)
			.then((res) => {
				AlertService.saved();
				$rootScope.$emit('updateAccount');
				getAccount();
			})
			.catch((err) => {});
		}

		self.googleTrouble = function(ev) {
			self.showGoogleTrouble = !self.showGoogleTrouble;
		}

		self.detachGoogleAccount = function(ev) {
			AccountService.detachGoogleAccount().then((res) => {
				AlertService.successMessage('disconnectGoogle');
				self.isGoogleIntegrated = false;
			})
			.catch((err) => {});
		}
	});

})(); // End of function()
