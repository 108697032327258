/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.upload', [])
	.service('UploadService', function (AlertService, FileUploader, $log, $rootScope, AccountService, resizeService) {

		const self = this;

		// Used to store long mime values
		const MIMES = {
			doc: 'msword',
			docx: 'vnd.openxmlformats-officedocument.wordprocessingml.document',
			xls: 'vnd.ms-excel',
			xlsx: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			ods: 'vnd.oasis.opendocument.spreadsheet'
		}

		const ALLOWED = {
			document: '|jpg|png|jpeg|bmp|gif|svg+xml|pdf|xls|doc|docx|xlsx|' + MIMES.docx +'|' + MIMES.doc + '|' + MIMES.xls + '|' + MIMES.xlsx + '|'+ MIMES.ods + '|',
			file:       '|jpg|png|jpeg|bmp|gif|svg+xml|pdf|xls|doc|docx|xlsx|' + MIMES.docx +'|' + MIMES.doc + '|' + MIMES.xls + '|' + MIMES.xlsx + '|'+ MIMES.ods + '|mp4|csv|',
			image:      '|jpg|png|jpeg|bmp|gif|svg+xml|',
			marketing:  '|pdf|mp4|',
			pdf:        '|pdf|',
			video:      '|mp4|',
			imageVideo: '|jpg|png|jpeg|bmp|gif|svg+xml|mp4|',
		}

		const MIN_SQUARE = {
			w: 480,
			h: 480
		}

		const MIN_RECTANGLE = {
			w: 640,
			h: 480
		}

		const baseUrl = $rootScope.baseURL;

		self.baseUrl = baseUrl;

		self.renameFilename = function (file) {
			const [filename, fileExtension] = file.split('.');
			return filename + '_' + Date.now() + '.' + fileExtension;
		}

		const Uploader = function (typeFilter, minDimensions) {
			const uploader = new FileUploader({
				url: null,
				autoUpload: true,
				removeAfterUpload: true,
				headers: { 'X-Access-Token': AccountService.getAccessToken() },
				withAuth: true,
				filters: []
			});

			if (!!typeFilter) {
				uploader.filters.push(_typeFilter(typeFilter));
			}

			if (typeFilter == 'image' && (!!(minDimensions || {}).h || !!(minDimensions || {}).w)) {
				uploader.filters.push(_sizeFilter(minDimensions));
			}

			uploader.onWhenAddingFileFailed = function (item, filter, options) {
				if ((filter || {}).name == 'sizeFilter') {
					AlertService.errorPopup('FILES.ALERT.IMAGE_ENSURE', { height: minDimensions.h, width: minDimensions.w });
				}
				else {
					AlertService.errorMessage('ALERT_MESSAGES.ALERT.FILE_FORMAT', { format: item.name.substring(item.name.indexOf('.')) });
				}

				AlertService.doneLoading();
			};

			uploader.onSuccessItem = function (fileItem, response, status, headers) {
				this.working = false;
				AlertService.success('FILES.FILE', 'upload');
			};

			uploader.onErrorItem = function (fileItem, response, status, headers) {
				$log.debug('Error uploading file.');
				$log.debug('Response: ', response);
				$log.debug('FileItem: ', fileItem);
				$log.debug('status: ', status);
				$log.debug('headers: ', headers);
				if (response.error && response.error.message.indexOf('contentType') >= 0 && response.error.message.indexOf('is not allowed') >= 0) {
					AlertService.errorPopup('ALERT_MESSAGES.ALERT.FILE_FORMAT_WITH_ALLOWED', {
						format: fileItem.file.name.substring(fileItem.file.name.indexOf('.')),
						allowed: ALLOWED.document.split('|').filter((e) => e).join(', '),
					});
				}
				this.working = false;
				AlertService.error('FILES.FILE', 'upload');
				AlertService.doneLoading();
			};

			return uploader;
		}

		self.initEmailTemplateUploader = function(photoOnly, templateId) {
			const type = photoOnly ? 'image' : 'file';
			const uploader = Uploader(type);
			uploader.url = baseUrl + '/EmailTemplates/uploadFile?templateId=' + templateId;
			return uploader;
		}

		self.initFileFolderUploader = function () {
			return Uploader('file');
		}

		self.getUploader = function (typeFilter, minDimensions) {
			return Uploader(typeFilter, minDimensions);
		}

		self.initAdminHelpUploader = function(folderId, isImage) {
			const type = isImage ? 'image' : 'file';
			const uploader = Uploader(type);
			uploader.url = baseUrl + '/Folders/' + folderId + '/uploadFile';
			uploader.autoUpload = false;
			return uploader;
		}

		self.initListingUploader = function (id) {
			const uploader = Uploader('image', MIN_RECTANGLE);
			uploader.url = baseUrl + '/Listings/' + id + '/uploadImage';
			uploader.autoUpload = false;
			uploader.onAfterAddingFile = function (item) {
				this.working = true;
				resizeImage(item, MIN_RECTANGLE.w, MIN_RECTANGLE.h);
			};
			return uploader;
		}

		self.initMarketingUploader = function (id) {
			const uploader = Uploader('marketing');
			uploader.url = baseUrl + '/Listings/' + id + '/uploadMarketing';
			return uploader;
		}
		self.initMessageUploader = function () {
			const uploader = Uploader('file');
			uploader.autoUpload = false;
			return uploader;
		}
		self.initOfferUploader = function () {
			const uploader = Uploader('pdf');
			uploader.autoUpload = false;
			return uploader;
		}

		self.initMessageUrl = function (id) {
			return baseUrl + '/Messages/' + id + '/uploadFile';
		}

		self.initOfferUrl = function (id) {
			return baseUrl + '/Offers/' + id + '/uploadFile';
		}

		self.initCommentUrl = function (id) {
			return baseUrl + '/Comments/' + id + '/uploadFile';
		}
		
		self.initDocumentUploader = function () {
			return Uploader('document');
		}

		self.initResourceUploader = function (id) {
			const uploader = Uploader('file');
			uploader.url = baseUrl + '/Listings/' + id + '/uploadResource';
			return uploader;
		}

		self.initFolderFileUploader = function (id) {
			return Uploader('file');
		}

		self.initAgencyFolderUploader = function (id) {
			const uploader = Uploader('file');
			uploader.autoUpload = false;
			return uploader;
		}

		self.initTaskUploader = function (id) {
			const uploader = Uploader('file');
			uploader.url = baseUrl + '/Tasks/' + id + '/uploadFile';
			return uploader;
		}

		self.initCommentUploader = function () {
			const uploader = Uploader('file');
			uploader.autoUpload = false;
			return uploader;
		}

		self.initUserUploader = function (photoOnly, id, minDimensions, documentOnly = false) {
			let type = photoOnly ? 'image' : 'file';
			type = documentOnly ? 'pdf' : type;
			const uploader = Uploader(type, minDimensions || MIN_SQUARE);
			uploader.url = baseUrl + '/SbUsers/' + (id || AccountService.getUserId()) + '/uploadFile';
			uploader.autoUpload = false;
			return uploader;
		}

		self.initWpUploader = function() {
			const uploader = Uploader('imageVideo');
			uploader.url = baseUrl + '/SbUsers/' + AccountService.getUserId() + '/uploadFile/user';
			uploader.autoUpload = false;
			return uploader;
		}

		self.initAnotherUserUploader = function (photoOnly) {
			const type = photoOnly ? 'image' : 'file';
			const uploader = Uploader(type, MIN_SQUARE);
			uploader.url = baseUrl + '/SbUsers/' + AccountService.getUserId() + '/uploadFile';
			return uploader;
		}

		self.initImageForVideoUploader = function () {
			const uploader = Uploader('image', MIN_RECTANGLE);
			uploader.url = baseUrl + '/SbUsers/' + AccountService.getUserId() + '/uploadFile/video';
			uploader.autoUpload = false;
			uploader.onAfterAddingFile = function (item) {
				resizeImage(item, MIN_RECTANGLE.w, MIN_RECTANGLE.h)
			};
			return uploader;
		}

		self.initFormUploader = function () {
			const uploader = Uploader('file');
			uploader.url = baseUrl + '/SbUsers/' + AccountService.getUserId() + '/uploadFile/forms';
			return uploader;
		}

		self.initUploadMarketingImage = function(minSize = null, folderId = null) {
			minSize = minSize || MIN_SQUARE;
			const uploader = Uploader('image', minSize);
			uploader.autoUpload = false;
			uploader.url = baseUrl + '/SbUsers/' + AccountService.getUserId() + '/uploadFile/marketing';
			if(folderId) {
				uploader.url += '?options=' + JSON.stringify({ folder: folderId });
			}
			return uploader;
		}

		self.initMediaManagerUploader = function (autoUpload = false, folder = null) {
			const uploader = Uploader('image', MIN_RECTANGLE);
			uploader.url = baseUrl + '/SbUsers/' + AccountService.getUserId() + '/uploadFile/mediaManager';
			if(folder) {
				uploader.url += '?options=' + JSON.stringify({ folder: folder });
			}
			uploader.autoUpload = autoUpload;
			return uploader;
		}

		function resizeImage(item, width, height) {
			const reader = new FileReader();

			reader.onload = function (event) {
				const image = event.target.result;
				const { type } = item.file;

				// always upload svg images as is
				if (type == 'image/svg+xml') {
					return item.upload();
				}

				const isSupportedDataURLType = type === 'image/jpg' || type === 'image/jpeg' || type === 'image/png';
				let outputFormat = 'image/png';

				// if file type is supported,
				if (isSupportedDataURLType) {
					// update the resize output format
					outputFormat = type;
				} else {
					// otherwise, append the file extension `png`
					item.file.name = item.file.name + '.png'
				}

				return resizeService.resizeImage(image, {
					width: width,
					height: height,
					outputFormat,
					step: 1
				})
				.then((resizedImage) => {
					const blob = dataURItoBlob(resizedImage);
					item._file = blob;

					item.upload();
				})
				.catch(console.error);
			};
			reader.readAsDataURL(item._file);
		};

		/**
             * Upload Blob (cropped image) instead of file.
             * @see
             *   https://developer.mozilla.org/en-US/docs/Web/API/FormData
             *   https://github.com/nervgh/angular-file-upload/issues/208
             */

		/**
             * Converts data uri to Blob. Necessary for uploading.
             * @see
             *   http://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
             * @param  {String} dataURI
             * @return {Blob}
             */
		function dataURItoBlob(dataURI) {
			const binary = atob(dataURI.split(',')[1]);
			const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
			const array = [];
			for (let i = 0; i < binary.length; i++) {
				array.push(binary.charCodeAt(i));
			}
			return new Blob([new Uint8Array(array)], { type: mimeString });
		}

		function _sizeFilter(dimensions) {
			const width = (dimensions || {}).w || MIN_SQUARE.w;
			const height = (dimensions || {}).h || MIN_SQUARE.h;

			return {
				name: 'sizeFilter',
				fn: function(item /*{File|FileLikeObject}*/, options, deferred) {
					const img = document.createElement('img');
					const reader = new FileReader();

					reader.onload = function (event) {
						img.src = event.target.result;

						img.onload = function() {
							if (img.width >= width && img.height >= height) {
								deferred.resolve();
							}
							else {
								deferred.reject();
							}
						}
					};
					if(item instanceof Blob) {
						reader.readAsDataURL(item);
					} else {
						const blob = new Blob(options.data, { type: options.type });
						reader.readAsDataURL(blob);
					}
				}
			}
		}

		self._sizeFilter = _sizeFilter;

		function _typeFilter(type) {
			type = type || 'file';

			const allowed = ALLOWED[type] || ALLOWED.file;

			return {
				name: type,
				fn: function (item /*{File|FileLikeObject}*/, options) {
					const itemType = ('|' + item.type.split('/').pop() + '|').toLowerCase();
					return allowed.indexOf(itemType) !== -1;
				}
			}
		}
	})  // End of service
})();
