(function () {
    
    "use strict";
  
    angular.module("smartbrokr.help", [])
    .service("HelpService", function (HelpTopic, $rootScope) {
        var self = this;

        self.getTopics = function() {
            var lang = $rootScope.language || 'EN';
            return HelpTopic.getTopics({ lang: lang }).$promise
        }
    });
})(); // End of function()