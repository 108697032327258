/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('NotesController', function ($filter, $rootScope, $sce, $scope, $state, $stateParams, $timeout, ModalService, DashboardService, FileService, AlertService, UserService, SupplierService, ListingService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.notes          = null;   // Notes
		self.logs           = null;   // Activity log
		self.data           = null;

		self.idFilter       = null;   // Used to filter notes/logs according to an ID
		self.idOwner        = null;   // If the id belongs to a supplier, also includes logs about their owner

		self.newNote        = null;   // New Note template with appropriate header, aboutId and aboutType

		self.searchTerm     = '';
		self.noteFilterExp  = '';     // Filter to search for notes
		self.logFilterExp   = '';     // Filter to search for logs

		self.checkedLogs    = [];

		let allItems;

		// SETUP VARIABLES ======================================================================================================================

		AlertService.loading();

		function doTranslations() {
			let i, length = allItems.length;

			const getOne = function(item) {
				item = item || '';
				if (typeof item === 'object') {
					if (item.vars.scheduled) {
						item.vars.scheduled = $sce.valueOf($filter('date')(item.vars.scheduled, 'EEE, MMM dd yyyy, hh:mm a'));
					}
					return '' + $filter('translate')(item.translation,item.vars);
				}
				else if (typeof item === 'string') {
					if (item.includes('LOG') || item.includes('TASK_LIST')){
						return '' + $filter('translate')(item);
					}
					else if (item.includes('Content')){     // Remove email content from old logs
						return item.substring(0, item.indexOf('Content'));
					}
				}

				return item || '';
			}

			for (i = 0; i < length; i++) {
				allItems[i].action = getOne(allItems[i].action);
				allItems[i].actionResult = getOne(allItems[i].actionResult);

				if ((allItems[i].actionResult || '').replace)
					allItems[i].actionResult = (allItems[i].actionResult || '').replace(/@/g, '@<wbr>');
			}

			self.data = angular.copy(allItems);
		}

		const getData = function () {
			if ($state.current.name.includes('note')) {
				const role = $stateParams.role || $stateParams.listing && 'listing' || null;
				DashboardService.getNotes(null, self.idFilter, role).then((res) => {
					self.data = res;
					allItems = res;

					if (!self.newNote) {
						self.newNote = $stateParams.newNote || null;
					}
				})
				.catch((err) => {})
				.finally(() => {
					AlertService.doneLoading();
				})
			}
			else {
				const getLogs = function () {
					self.options = {
						dashboard: DashboardService.getActionLog,
						user: UserService.getLogs,
						listing: ListingService.getLogs,
					}
					self.roleLogs = {
						buyer:UserService.getBuyerLogs,
						seller:UserService.getSellerLogs,
						supplier:UserService.getSupplierLogs
					}
					let current = null;
					let id = null;
					const profileId = $stateParams.id;

					if ($state.current.name.includes('dashboard')) {
						current = 'dashboard';
					} else if ($state.current.name.includes('listing')) {
						current = 'listing';
						id = $stateParams.id;
					} else {
						current = 'user';
						id = self.idFilter;

					}

					if (current == 'dashboard') {
						self.options[current](null, self.idFilter, self.idOwner)
						.then((res) => {
							allItems = res;
						})
						.catch((err) => {})
						.finally(() => {
							doTranslations();
							AlertService.doneLoading();
						})
					}
					else {
						self.options[current](id)
						.then((res) => {
							allItems = res;

							// Only applicable for profile state
							if (current == 'user') {
								return self.roleLogs[$stateParams.role](profileId)
								.then((res2) => {
									allItems = allItems.concat(res2);
								})
							}
						})
						.catch((err) => {})
						.finally(() => {
							allItems.sort((a,b) => {
								return moment(b.created).diff(moment(a.created));
							})
							doTranslations();
							AlertService.doneLoading();
						})
					}
				}

				if ($state.current.name.includes('suppliers')) {
					SupplierService.getOwnerId($stateParams.id).then((res) => {
						self.idOwner = res.ownerId;
						getLogs();
					})
					.catch((err) => {
						console.error(err);
					})
				}
				else {
					getLogs();
				}
			}
		}

		if ($stateParams.id) {
			self.idFilter = $stateParams.id;

			if ($stateParams.role) {
				const isSupplier = $stateParams.role === 'supplier';

				UserService.getUserId(self.idFilter, $stateParams.role)
				.then((res) => {

					self.idFilter = res.id || res.sbUserId || res.ownerId;

					const role = $filter('translate')('ROLES.' + ($stateParams.role || 'Person').toUpperCase());

					self.newNote = {
						header: role + ': ' + (isSupplier ? res.company : res.fullName),
						text: '',
						aboutId: self.idFilter,
						aboutType: 'SbUser'
					}

					getData();
				})
				.catch((err) => {
					console.error(err);
				});
			}
			else {
				if (self.idFilter.includes('S') || self.idFilter.includes('M')) {
					ListingService.getListingAddress(self.idFilter).then((res) => {
						const address = ((res.property || {})._address || {}).addressString || null;
						self.newNote = {
							header: $filter('translate')('LISTING.LISTING') + ': ' + self.idFilter,
							text: '',
							aboutId: self.idFilter,
							aboutType: 'Listing'
						}
						getData();
					})
				}
				else {
					getData();
				}
			}
		}
		else {
			self.newNote = null;
			getData();
		}

		// FUNCTIONS ============================================================================================================================

		// Delete from array
		$scope.deleteNote = function (note) {
			UserService.deleteNote(note.id).then((del) => {
				if (del) {
					AlertService.success('NOTES_LOG.NOTE','delete');
					getData();
				}
			})
			.catch((err) => {})
		};

		$scope.openModal = function (note) {
			self.openModal(note);
		}

		self.openModal = function (note) {
			if (note == null && self.newNote != null) {
				note = angular.copy(self.newNote);
			}

			const retFunction = function (result) {
				getData();
			}
			ModalService.openModalWithFunction('newNote', { note: function () { return note; } }, 'NotesModalController', 'notesModalController', retFunction);
		};

		self.getFiltered = function(text) {
			let ret = text || '';

			ret = $filter('linky')(ret, '_blank');
			ret = $filter('addNewLines')(ret);
			ret = $filter('addMLS')(ret);
			return $sce.trustAsHtml(ret);
		}

		self.exportLogsCsv = function () {
			let text = '';
			const data = self.data;
			text += '"User","Time","Action","Result"\n';

			let i, length = data.length;
			for (i = 0; i < length; i++) {
				text += '"' + ((data[i].user || {}).fullName || 'N/A') + '",';
				text += '"' + moment(data[i].created).format('YYYY-MM-DD hh:mm a') + '",';
				text += '"' + data[i].action + '",';
				text += '"' + data[i].actionResult + '"\n';
			}
			FileService.exportCsv(text, 'ActionLog');
		};

		self.exportLogsTxt = function () {
			let text = '';
			const data = self.data;
			let i, length = data.length;

			for (i = 0; i < length; i++) {
				text += ((data[i].user || {}).fullName || 'N/A') + '\n';
				text += moment(data[i].created).format('YYYY-MM-DD hh:mm a') + '\n';
				text += data[i].action + '\n' + ('' + (data[i].actionResult || '')).replace(/<br>/g, '\n') + '\n\n';
			}
			FileService.exportTxt(text, 'ActionLog');
		};

		self.filter = function(sub) {
			if (self.searchTerm) {
				self.data = $filter('searchSingleFilter')(allItems,self.searchTerm, sub);
			}
			else {
				self.data = angular.copy(allItems);
			}
		}

		self.expand = function(i) {
			if ($scope.expand === i) {  // Closing log that is open
				$scope.expand = null;
			}
			else if ($scope.expand !== null) {  // Changing opened log. Close first one, then open the next
				$scope.expand = null;

				$timeout(() => {
					$scope.expand = i;
				}, 175);

			}
			else {  // No other log is expanded -> Just open it
				$scope.expand = i;
			}
		}

		self.checkLog = function(log) {
			log.isChecked = !log.isChecked

			if (log.isChecked) {
				self.checkedLogs.push(log);
			}
			else {
				self.checkedLogs.splice(self.checkedLogs.findIndex((item) => { return item.id === log.id }), 1);
			}

			if (self.checkedLogs.length === self.data.length) {
				$scope.allChecked = true;
			}
		}

		self.checkAll = function(allChecked) {
			self.data.forEach((log) => {
				log.isChecked = allChecked;
			})

			if (allChecked) {
				self.checkedLogs = angular.copy(self.data);
			}
			else {
				self.checkedLogs = [];
			}
		}

		self.emailLogs = function() {

			const logs = angular.copy(self.checkedLogs);

			logs.forEach((log) => {
				log.created = moment(log.created).format('YYYY-MM-DD HH:mm a').replace(' ', ' <wbr>');
				log.author = (log.user || {}).fullName || 'N/A';

				if (log.expanded) {
					let pre = '';
					switch(log.expanded.type) {
					case 'VISIT':
						pre = $sce.valueOf($filter('translate')('LISTING.LISTING')) + ': ' + log.expanded.listing + '<br>';
						break;
					case 'TASK':
						if (log.expanded.complete) {
							pre = $sce.valueOf($filter('translate')('TASK.TASK_LEGENDS.COMPLETE')) + '<br>';
						}
						break;
					case 'VIDEO':
						pre = $sce.valueOf($filter('translate')('LISTING.LISTING')) + ': ' + log.expanded.listing + '<br>';

						if ((log.expanded.sellers || []).length > 0) {
							pre += $sce.valueOf($filter('translate')('ROLES.SELLERS')) + ': ' + '<br>';
							log.expanded.sellers.forEach((seller) => {
								pre += seller.fullName + ' (' + seller.email + ')<br>';
							})
						}

						pre += $sce.valueOf($filter('translate')('COMMON.VIDEO')) + ': ' + log.expanded.videoUrl;
						break;
					case 'EMAIL':
						pre = log.actionResult + '<br>';
						log.actionResult = log.expanded.html;
						break;
					}

					log.actionResult = pre + log.actionResult;
				}

				delete log.expanded;
				delete log.aboutId;
				delete log.aboutType;
				delete log.modified;
				delete log.sbUserId;
				delete log.user;
			})

			const resolve = {
				params: function() {
					return { logs: logs }
				}
			}

			ModalService.openModal('emailLogs', resolve, 'SendEmailController', 'sendEmailController', null, 'email-modal');
		}

		$scope.getUrl = function(state, params) {
			return ($rootScope.baseURL + $state.href(state,params)).replace(/([/\.]{1})([0-9]+|[A-z]+)/gi, '$1<wbr>$2');
		}

	}) // End of controller
})(); // End of function()
