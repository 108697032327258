(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('MediaController', function ($uibModalInstance, $rootScope, FileService, media, mediaType, sbDownload) {

		const self = this;
		self.media      = media || {};
		self.media.file = self.media.file || {};
		self.media.url  = self.media.url || self.media.file.url;
		self.media.url = FileService.getImageUrl(self.media.url);
		self.media.name = self.media.name || self.media.file.name;
		self.download = sbDownload;

		const type = self.media.type || (self.media.file || {}).type || '';

		if (type.includes('video')) {
			self.mediaType = 'vid';
		}
		else if (type.includes('image')) {
			self.mediaType = 'img';
		}
		else {
			self.mediaType = 'other';
		}

		self.close = function () {
			$uibModalInstance.dismiss('cancel');
		};

	})
})();
