

(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('ProfileFilesController', function ($state, AlertService, BuyerService, DocusignService, FileService, SellerService, SupplierService, ModalService, UploadService, ReportsService) {
		const self = this;
		const profile = $state.params.role;
		const id = $state.params.id;
		let fk = $state.params.fk;
		self.options = {
			buyer: BuyerService,
			seller: SellerService,
			supplier: SupplierService,
		}
		self.uploader = UploadService.getUploader();
		self.setUploader = function (id) {
			self.uploader.url = UploadService.baseUrl + '/Folders/' + id + '/uploadFile';
			self.uploader.onSuccessItem = function (fileItem, response, status, headers) {
				self.getFolders();
			};
		}
		self.getFolders = function () {
			self.options[profile].getThrough(id)
			.then((res) => {
				if(res.length > 0){
					res= res[0];
					fk = res.id;
				}
			})
			.catch((err) => {

			})
			self.options[profile].getFolders(id)
			.then((res) => {
				if(res.length > 0){
					res= res[0];
					fk = res.id;
					self.folders = res.folders;
				}

			})
			.catch((err) => {

			});
		}

		self.deleteFolder = function (id) {
			//need to update for current through model's id
			self.options[profile].deleteFolder(fk, id).then((res) => {
				if (res) {
					self.getFolders();
				}
			})
			.catch((err) => {})
		}
		self.deleteFile = function (id, fk, isGenerated, agencyFolder) {
			ReportsService.deleteDocument(id, fk, isGenerated, agencyFolder).then((res) => {
				if (res) {
					self.getFolders();
				}
			})
			.catch((err) => {})

		}

		self.createFolder = function () {
			const modal = ModalService.openModal('createFolder', { folder: function () { return { type: profile, id: fk } }, type: function() { return '' }, options: function() { return { } } }, 'FolderController', 'folderController');
			modal.result.then((res) => {
				if (res) {
					self.getFolders();
				}
			})
			.catch((err) => {});
		}
		self.editFolder = function(event, folder){
			event.stopPropagation();
			event.preventDefault();
			const modal = ModalService.openModal('createFolder', { folder: function () { return { folder:folder } }, type: function() { return '' }, options: function() { return { } } }, 'FolderController', 'folderController');
			modal.result
			.then((res) => {
				self.getFolders();
			})
			.catch((err) => {
			});
		}

		self.download = function(file, folder) {
			file = file || {};
			folder = folder || {};
			if (file.id && folder.id) {
				FileService.download('Folders', folder.id, file.id);
			}
		}
		self.getFolders();

		self.docusignConnected = false;

		DocusignService.getStatus().then((res) => {
			self.docusignConnected = res.connected;
		})
		.catch((err) => {
			$log.error(err);
		});

		self.docusign = function(file) {
			AlertService.loading();
			DocusignService.sendDocument(file.id).then((res) => {
				AlertService.doneLoading();
				AlertService.successMessage('envelope');
				return DocusignService.popup(res.data.url);
			})
			.catch((err) => {
				AlertService.doneLoading();
				const message = err.data.error.message;
				if(message.indexOf('sent') > -1) {
					AlertService.errorMessage('envelopeExists');
				} else if(message.indexOf('integrated') > -1) {
					AlertService.errorMessage('docusignDisconnected');
				} else {
					AlertService.errorMessage('envelopeCreate');
				}
				$log.error(err);
			});
		}
	});
})();
