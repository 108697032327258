(function () {

	'use strict';

	angular.module('smartbrokr.admin')
	.controller('AdminProfileController', function (AlertService, AdminService, GlobalVars, NavService, UploadService, $filter, $scope, $stateParams) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.profile        = null;     // Profile. Includes agency, user, user.account
		self.editProfile    = null;     // Copy of profile. Will edit this object when editing
		self.uploader       = null;     // Uploader for profile photo
		self.errors         = {};       // Object with errors caused when saving the parts of the profile
		self.edit           = false;    // If true, will change to edit view
		self.editWordpress  = {};

		self.role           = $stateParams.role || 'broker';

		self.calendar       = {
			expires: GlobalVars.createCalendarConfig(null, null),
			account: GlobalVars.createCalendarConfig(null, null)
		}

		self.photoUpOpts    = {         // Options for photoUpload directive
			texts: {
				current: 'Current',
				new: 'New',
				confirm: 'Confirm',
				upload: 'Change'
			},
			classes: {
				upload: 'upload',
				confirm: 'btn btn-link cropper-confirm',
				current: 'cropper-current',
				new: 'cropper-new'
			}
		};

		self.dropdowns = {              // Object with dropdown config and options (status, agency names)
			status: [
				{ value: 'ACTIVE', name: 'Active' },
				{ value: 'INACTIVE', name: 'Inactive' },
				{ value: 'DISABLED', name: 'Disabled' },
				{ value: 'SB_USER', name: 'SB User' }
			],
			memberStatus: [
				{ value: false, name: 'Active' },
				{ value: true, name: 'Inactive' }
			],
			memberRoles: GlobalVars.agencyMemberRoles,
			agency: [],
			courses: [],
			acctStatus: [
				{ value: 'active', name: 'Enabled' },
				{ value: 'disabled', name: 'Disabled' },
				{ value: 'canceled', name: 'Cancelled' }
			],
			wpAssets: [
				{ value: 'TEMPLATE', name: 'Template/Plugin' },
				{ value: 'PLUGIN', name: 'Plugin' }
			],
			licenseType: GlobalVars.licenseTypes,
			multi: GlobalVars.createMultiConfig(true, 'value', 'value', true),
			single: GlobalVars.createSingleConfig(false, 'value', 'name', 'name'),
		}

		self.logFilters = {
			action: { weight: 0, value: '', desc: -1 },
			created: { weight: 0, value: '', desc: -1, date: true, dateFilter: 'dateFormat' },
		}

		getProfile();
		getAgencies();

		// FUNCTIONS ============================================================================================================================

		self.remoteLogin = function() {
			AdminService.remoteWpLogin(self.editWordpress.id).then((res) => {
				AlertService.saved();
			})
		}

		/**
         *  Saves a part of the profile and then updates the page with the new data
         *  @param   {string}  type    Type of profile being saved. Accepted values: 'account', 'personal', 'broker'
         */
		self.save = function(type) {
			let api = null;
			let edit = angular.copy(self.editProfile);

			switch(type) {
			case 'account':
				edit = edit.user.account;
				api = AdminService.saveAccount(edit, self.editProfile.sbUserId, self.editProfile.agencyId);
				break;

			case 'personal':
				edit = edit.user;
				delete edit.account;
				api = AdminService.saveProfile(edit, 'sbUser', self.editProfile.agencyId);
				break;

			case 'profile':
				delete edit.user;
				delete edit.agency;

				if (edit.agencyRoleId === 'OTHER') {
					edit.agencyRoleId = self.roleOther;
				}

				api = AdminService.saveProfile(edit, self.role, self.editProfile.agencyId);
				break;
			}

			if (edit && api) {
				api.then((res) => {
					AlertService.saved();
					self.errors[type] = null;
				})
				.catch((err) => {
					self.errors[type] = (err.data || {}).error || null;
				})
				.finally(getProfile)
			}
		}

		self.saveWordpress = function() {
			AdminService.saveWordpress(self.editWordpress).then((res) => {
				AlertService.saved();
				self.errors.wordpress = null;
			})
			.catch((err) => {
				self.errors.wordpress = (err.data || {}).error || null;
			})
			.finally(getProfile);
		}

		/**
         *  Gets the email associated with this user's account
         */
		self.getEmail = function() {
			return (((self.profile || {}).user || {}).account || {}).email;
		}

		self.getRole = function() {
			switch(self.role) {
			case 'broker':
				if ((self.profile || {}).subscriptions) {
					return 'Independent Broker';
				}
				return 'Broker';
			case 'member': return 'Agency Member';
			case 'owner': return 'Agency Owner';
			case 'notary': return 'Notary';
			case 'auditor': return 'Auditor';
			}

			return '';
		}

		self.resetPassword = function() {
			$scope.disableReset = true;
			const email = (((self.profile || {}).user || {}).account || {}).email;

			if (email) {
				AdminService.resetPassword(email, self.profile.user.accountId).then((res) => {
					AlertService.saved();
				})
				.catch((err) => {
					self.errors['account'] = (err.data || {}).error || null;
				})
				.finally(getProfile);
			}
		}

		self.viewOnSb = function() {
			let profileType = null;

			switch(self.role) {
			case 'broker':
				profileType = 'brokerProfile'; break;
			case 'member':
				profileType = 'agencyMemberProfile'; break;
			case 'owner':
				profileType = 'agencyOwnerProfile'; break;
			case 'notary':
				profileType = 'notaryProfile'; break;
			case 'auditor':
				profileType = 'auditorProfile'; break;
			}

			if (!profileType) return;

			AdminService.loginAsUser(self.profile.sbUserId, self.profile.agencyId, self.profile.id, profileType);
		}

		/**
         *  Gets profile information from backend
         */
		function getProfile() {
			const id = $stateParams.id;

			if (!id) return;

			AlertService.loading();
			$scope.doneLoading = false;

			AdminService.getProfile(id, self.role, true).then((res) => {
				self.profile = res[self.role] || res;

				self.editWordpress = res.wordpress || {};

				if (((self.profile || {}).user || {}).account) {
					self.profile.accountEmail = {
						email: self.profile.user.account.email,
						fullName: self.profile.user.fullName
					}

					if (self.profile.user.account.disableDate ) {
						self.profile.user.account.disableDate = new Date(self.profile.user.account.disableDate);
					}
				}

				switch(self.role) {
				case 'broker':
					self.profileStatus = self.profile.status;
					self.activeAccount = self.profile.status === 'SB_USER';
					self.editWordpress.role = 'SB_BROKER';
					break;
				case 'owner':
					self.editWordpress.role = 'SB_AGENCY_OWNER';
				case 'member':
					self.profileStatus = self.profile.disabled ? 'INACTIVE' : 'ACTIVE';
					self.activeAccount = !self.profile.disabled;

					if (!!self.profile.agencyRoleId) {
						const i = self.dropdowns.memberRoles.findIndex(x => x.value == self.profile.agencyRoleId);
						if (i < 0) {
							self.roleOther = self.profile.agencyRoleId;
							self.profile.agencyRoleId = 'OTHER';
						}
					}

					break;
				case 'notary':
				case 'auditor':
					self.profileStatus = moment(self.profile.expires).isBefore(moment()) || self.profile.disabled ? 'INACTIVE' : 'ACTIVE';
					self.activeAccount = self.profileStatus === 'ACTIVE';
					break;
				default:
					self.profileStatus = 'INACTIVE';
					self.activeAccount = false;
				}

				self.editProfile = angular.copy(self.profile);

				self.logs = res.logs || [];

				if ((self.editProfile || {}).sbUserId) {
					self.uploader = UploadService.initUserUploader(true, self.editProfile.sbUserId, { w: 640, h: 640 });

					if (self.editProfile.user.account && !self.editProfile.user.account.status) {
						self.editProfile.user.account.status = 'active';
					}
				}

				self.activeAccount = self.activeAccount && ((self.profile.user || {}).account || {}).status !== 'disabled';

				if ((self.profile.currentCourses || []).length > 0) {
					self.dropdowns.courses = self.profile.currentCourses.reduce((arr, item) => {
						arr.push({ value: item });
						return arr;
					}, [])
				}

				if (self.role === 'notary' || self.role === 'auditor') {
					self.editProfile.expires = new Date(self.editProfile.expires);
				}

				self.editWordpress.profileId = self.editProfile.id;
				self.editWordpress.accountId = self.profile.user.accountId;
			})
			.finally(() => {

				self.logColumns = [
					AdminService.logColumns.action(),
					{
						name: 'Date',
						filter: 'created',
						style: 'vertical-align: top',
						field: function(log) {
							return $filter('dateFormat')(log.created);
						}
					}
				]

				AlertService.doneLoading();
				$scope.doneLoading = true;
			})
		}

		/**
         *  Gets agency options for agency dropdown
         */
		function getAgencies() {
			$scope.loadingAgencies = true;

			AdminService.getAgencyOptions('value', 'name').then((res) => {
				self.dropdowns.agency = res;
				$scope.loadingAgencies = false;
			})

			$scope.$watch('profileController.editProfile.agencyId', (agencyId) => {
				if (agencyId == ((self.profile || {}).agencyId) || (!agencyId && !(self.profile || {}).agencyId)) {
					$scope.warningAgency = false;
				}
				else {
					$scope.warningAgency = true;
				}
			})
		}

		self.viewSentEmails = function() {
			const id = $stateParams.id;
			NavService.navigate('main.admin.profile.emails', { id: id, profileId: self.role });
		}
	})

	.directive('profileDetails', () => {
		return {
			restrict: 'E',
			templateUrl: 'admin/profile/profile.details.html'
		};
	})

	.directive('profileEdit', () => {
		return {
			restrict: 'E',
			templateUrl: 'admin/profile/profile.edit.html'
		};
	})

	.directive('subSectionTitle', () => {
		return {
			restrict: 'E',
			transclude: true,
			templateUrl: 'admin/templates/subSection.html',
			scope: {
				errors: '=',
				type: '@'
			}
		};
	})
})();
