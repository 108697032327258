/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.directives')
	.directive('supplierSellerTop', () => {
		return {
			restrict: 'E',
			scope: {
				addFunction: '='
			},
			link: function(scope, element, attrs) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
			},
			templateUrl: '/js/src/suppliers/supplier.seller.html'
		};
	})

})();
