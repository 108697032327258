(function () {

	"use strict";

	angular.module("smartbrokr")
	.controller("HitsTrackerController", function (AlertService, GlobalVars, ModalService, ReportsService, $sce, $scope, $translate) {

		var self = this;

		// VARIABLES ============================================================================================================================

		self.report			= {};		// Data to show the user

		self.startDate		= null;		// Start date for report
		self.endDate		= null;		// End date for report

		self.startCalendar	= null;		// Calendar options (start date)
		self.endCalendar	= null;		// Calendar options (end date)

		var reportWord 		= null;		// Translated word for support (used in alerts)

		// SETUP VARIABLES ======================================================================================================================

		self.startDate 		= moment().subtract(7,'days').toDate();
		self.endDate 		= new Date();

		self.startCalendar 	= GlobalVars.createCalendarConfig(null, self.endDate);
		self.endCalendar 	= GlobalVars.createCalendarConfig(self.startDate, new Date());

		getReport();

		// FUNCTIONS ============================================================================================================================

		var w1 = $scope.$watch(function() { return self.startCalendar.opened }, function() {
			if (self.startCalendar.opened) {
				self.endCalendar.opened = false;
			}
		}) 

		var w2 = $scope.$watch(function() { return self.endCalendar.opened }, function() {
			if (self.endCalendar.opened) {
				self.startCalendar.opened = false;
			}
		})
		
		var w3 = $scope.$watch(function(){ return self.endDate; }, function(newValue, oldValue, scope) {
			self.startCalendar.options.maxDate = self.endDate;
		}, true)

		var w4 = $scope.$watch(function() { return self.startDate; }, function(newValue, oldValue, scope) {
			self.endCalendar.options.minDate = self.startDate;
		}, true)

		$scope.$on('$destroy', function(){
			w1(); w2(); w3(); w4();
		})
		
		function getReport() {
			ReportsService.getHitsReport(self.startDate, self.endDate).then(function (res) {
				self.report = {
					results: res
				}
			});
		}

		self.getReport = function() {
			if (!reportWord) {
				$translate('PAGES.REPORTS.REPORT').then(function(repWord) {
					reportWord = $sce.valueOf(repWord);
					getReport();
				})
			}
			else {
				getReport();
			}
		};

		self.sendReport = function (report) {

			report.min = self.startDate.toISOString();
			report.max = self.endDate.toISOString();

			report.emails = ReportsService.getHitsEmailTexts();

			report.sellers.forEach(function (seller, index) {
				if ((seller.user || {}).emailEnabled) {
					ReportsService.sendHitsReportEmail(seller.id, report)
					.then(function (res) {
						AlertService.success(reportWord, 'send');
					})
					.catch(function (err) {
						AlertService.error(reportWord, 'send');
					});
				}
				else {
					$translate('ALERT_MESSAGES.ALERT.CANT_EMAIL', { name: (seller.user || {}).fullName || null }).then(function(title) {
						ModalService.prompt(title, null, null, 'Ok');
					})
				}
			})
			report.sent = true;
		}

	}) // End of controller
})(); // End of function()
