/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.directives')

	.directive('logWpBuyer', () => {
		return {
			restrict: 'E',
			scope: {
				data: '=',
			},
			templateUrl: '/js/directives/log-types/wp-buyer.html',
			link: function(scope, element, attrs) {
				scope.lines = [
					{
						label: 'EMAIL.EMAIL',
						attr: 'email'
					},
					{
						label: 'LISTING.LISTING',
						attr: 'listing'
					},
					{
						label: 'PERSON.PHONE',
						attr: 'phone'
					},
					{
						label: 'MESSAGES.MESSAGE',
						attr: 'message'
					},
				];
			}
		}
	})

})();
