(function () {

  "use strict";

  angular.module("smartbrokr")

    .controller("FeatureController", function ($uibModalInstance, $scope, AccountService, GlobalVars, ListingService, id, feature, updating) {
      var self = this;

      self.id             = id;
      self.feature        = feature;
      self.updating       = updating;
      self.types          = GlobalVars.featureTypes;
      self.subtypes       = GlobalVars.featureValues;
      self.singleConfig   = GlobalVars.singleConfig;
      self.filteredSubtypes = [];

      var schema = AccountService.getSchema();

      var filters = {
        centris: function() {
          self.filteredSubtypes = [];
          var selectedNum = self.feature.characteristicTypeId.substr(self.feature.characteristicTypeId.length-2);

          for (var i in self.subtypes) {
            if (self.subtypes[i].value.includes(selectedNum)){
              self.filteredSubtypes.push(self.subtypes[i]);
            }
          }
        },
        realtor: function() {
          self.filteredSubtypes = [];
          var prefix = self.feature.characteristicTypeId.substr(0,4);

          for (var i in self.subtypes) {
            if (self.subtypes[i].value.includes(prefix)){
              self.filteredSubtypes.push(self.subtypes[i]);
            }
          }
        }
      }

      self.filterSubtypes = function() {
        filters[schema]();
      }

      if (self.feature.characteristicTypeId != undefined) {
        self.filterSubtypes();
      }

      $uibModalInstance.scope = $scope;
      $uibModalInstance.check = {
        original: angular.copy(self.feature),
        clone: self.feature
      }

      self.close = function () {
        $uibModalInstance.dismiss('cancel');
      };

      self.deleteFeature = function () {
        if (self.updating) {
          ListingService.deleteFeature(self.id,self.feature.id)
          .then(function(res){
            if (!!res) {
              $uibModalInstance.close(res);
            }
          })
          .catch(function(err){})
        }
      }

      self.saveFeature = function () {
        var featureId = self.feature.id;
        var feature = {
          characteristicTypeId: self.feature.characteristicTypeId || null,
          valueId: self.feature.valueId || null,
          num: self.feature.num || null,
          description: self.feature.description || null,
        }
        if (!self.updating) {

          //PropertyService.createFeature(self.id, feature)
          ListingService.createFeature(self.id, feature)
            .then(function (res) {
              $uibModalInstance.close(feature);
            })
            .catch(function (err) {});
        }
        else if (self.updating) {
          //PropertyService.updateFeature(self.id, self.feature.id, self.feature)
          ListingService.updateFeature(self.id, featureId, feature)
            .then(function (res) {
              $uibModalInstance.close(feature);
            })
            .catch(function (err) {});
        }
        else {
          $uibModalInstance.close();
        }
      }

    }) // End of controller
})(); // End of function()
