 (function() {

 	"use strict";

 	angular.module("smartbrokr")

   // Controller for modals that open when adding a new Seller or Buyer.
   // Works for modals duplicateUser.html and existingUser.html
 	.controller("DuplicateUserController", function($scope,$uibModalInstance, $q, newPerson, UserService) {

 		var self = this;

    // VARIABLES ============================================================================================================================

    self.users            = [];           // All possible users
    self.newPerson        = newPerson;    // SbUser object that is being created

    // FUNCTIONS ============================================================================================================================

 		self.close = function () {
 			$uibModalInstance.dismiss('cancel');
 		};

    self.select = function() {
      $uibModalInstance.close({ person: self.newPerson, useExisting: true});
    }

    self.selectNew = function() {
      $uibModalInstance.close({ person: null, useExisting: true});
    }

    self.searchUser = function(value) {
      var def = $q.defer();
      $scope.loading = true;
      UserService.searchExistant(value).then(function(res) {
        if (res) {
          res = res.sort(function(a,b) { 
            return (a.fullName || "").localeCompare(b.fullName || "") - (b.fullName || "").localeCompare(a.fullName || ""); 
          });
        }
        def.resolve(res);
      })
      .catch(function(err){
        def.resolve(err);
      })
      .finally(function(){
        $scope.loading = false;
      })

      return def.promise;
		}

 	}) // End of controller
 })(); // End of function()
