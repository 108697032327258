(function () {

	'use strict';

	angular.module('smartbrokr.admin')
	.controller('AdminBrokersController', function ($filter, $log, AdminService, AlertService, GlobalVars, SortingService) {

		const self = this;
		getBrokers();

		self.brokers    = null;
		self.columns    = null;

		const page        = 'admin.brokers';
		const config      = GlobalVars.createSingleConfig(false, 'id', 'name', 'name');       // Selectize config
		const status      = [         // Dropdown options for status filter
			{ name: '------', id: '' },
			{ name: 'ACTIVE', id: 'ACTIVE' },
			{ name: 'INACTIVE', id: 'INACTIVE' },
			{ name: 'SB_USER', id: 'SB_USER' },
		]
		const languages   = [         // Dropdown options for languages filter
			{ name: '------', id: '' },
			{ name: 'English', id: 'EN' },
			{ name: 'French', id: 'FR' }
		]

		self.filters    = SortingService.getPageFilter(page);

		if (!self.filters) {
			self.filters = SortingService.createPageFilter(
				page,
				{
					firstName: { weight: 2, value: '', desc: -1 },
					lastName: { weight: 1, value: '', desc: 1 },
					status: { weight: 0, value: '', desc: 1, exact: true },
					phones: { weight: 0, value: '', desc: 1, phone: true },
					email: { weight: 0, value: '', desc: 1 },
					legalName: { weight: 0, value: '', desc: 1 },
					languageIds: { weight: 0, value: '', desc: 1, array: true, sortFunction: _getLangField },
				}
			);
		}

		self.navParams  = {
			state: 'main.admin.profile',
			getParams: function(entry) {
				if (entry) {
					return { id: entry.id, role: 'broker', menu: 'brokers' };
				}
				return {};
			}
		}

		self.sortInfo = {
			page: page,
			sub: [ 'user', 'agency' ]
		}

		getBrokers();

		function getBrokers() {
			AlertService.loading();
			AdminService.getBrokers().then((res) => {
				self.brokers = $filter('orderBy')(res, 'user.firstName');
			})
			.catch((err) => {
				$log.error(err);
			})
			.finally(() => {
				self.columns = [
					{
						name: 'First Name',
						filter: 'firstName',
						field: function(broker) {
							return (broker.user || {}).firstName || 'N/A';
						}
					},
					{
						name: 'Last Name',
						filter: 'lastName',
						field: function(broker) {
							return (broker.user || {}).lastName || 'N/A';
						}
					},
					{
						name: 'Status',
						filter: 'status',
						select: true,
						config: config,
						options: status,
						field: function(broker) {
							return broker.status;
						}
					},
					{
						name: 'Phone',
						filter: 'phones',
						field: function(broker) {
							return $filter('getOnePhone')((broker.user || {}).phones);
						}
					},
					{
						name: 'Email',
						filter: 'email',
						isEmail: true,
					},
					{
						name: 'Agency',
						filter: 'legalName',
						isHtml: true,
						field: function(broker) {
							return '<a ui-sref="main.admin.agencies.profile({ id: \'' + broker.agencyId + '\'})">' + (broker.agency || {}).legalName + '</a>';
						}
					},
					{
						name: 'Office Name',
						filter: 'officeName',
						field: function(broker) {
							return (broker.user._address || {}).officeName || 'N/A';
						}
					},
					{
						name: 'Language',
						filter: 'languageIds',
						select: true,
						config: config,
						options: languages,
						isHtml: true,
						field: _getLangField
					}
				]

				AlertService.doneLoading();
			})
		}

		function _getLangField(broker) {
			const langs = (((broker || {}).user || {}).languages || []).reduce((arr, lang) => {
				arr.push('' + $filter('translate')('LANGUAGES.' + lang.id));
				return arr;
			}, []).sort();

			if (langs.length === 0) {
				return '---';
			}

			return langs.join('<br>');
		}
	});

})();
