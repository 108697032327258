/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.navigation', [])

	.service('NavService', function ($localStorage, $rootScope, $state, $timeout, $translate, $window, $sessionStorage, AgencyService, AlertService, UserService, ListingService, TaskService, ResourceService) {

		const self = this;

		// VARIABLES ========================================================================================================================
		const specialProfileStates  = /main\.(.)*profile/gi;      // Profile states - have submenu
		self.navStack             = $sessionStorage.navStack;   // Navigation stack -> Used for headers

		// FUNCTIONS ========================================================================================================================

		/**
       *  Adds a crumb to the Navigation Stack
       *  Emits 'changed-stack' event in the end
       *  @param {Object=}   state   State object ( same object structure as returned by $state.get('state.name') )
       *  @param {Object=}   params  Parameters for the link
       *  @param {string}    title   Translation lable or title for the crumb
       */
		self.addCrumb = function(state, params, title) {
			state = state || { name: '.' }
			params = params || {};

			let data = {};

			if (state.data) {
				data = angular.copy(state.data);
			}

			data.title = title;

			const crumb = createCrumb(state, params, data);
			addToStack(crumb);
			$rootScope.$emit('changed-stack');
		}

		self.changeLanguage = function(lang, dontReload) {
			$localStorage.language = (lang || 'EN').toUpperCase();
			AlertService.reload();

			if (!dontReload) {
				$state.reload();
				location.reload()
			}
			else {
				$rootScope.changeLanguage();
			}
		}

		self.addParam = function (i, key, value) {
			try {
				$sessionStorage.navStack[i].params[key] = value;
				self.navStack = $sessionStorage.navStack;
			}
			catch (e) {
				console.error('AddParam error: ', e);
			}
		}

		self.getCurrStackItem = function (toState) {
			if (!self.navStack) {
				self.navStack = [];
			}
			for (let i = self.navStack.length - 1; i >= 0; i--) {
				if (self.navStack[i].link == toState.name || self.navStack[i].original == toState.name) {
					return i;
				}
			}
			return null;
		}

		// When going back one state
		self.popStack = function (doNotEmit) {
			let ret = null;
			if (self.navStack.length > 1) {
				ret = $sessionStorage.navStack.pop();
				self.navStack = $sessionStorage.navStack;

				if (!doNotEmit) {
					$rootScope.$emit('changed-stack');
				}
			}
			return ret;
		}

		self.resetStack = function () {
			$sessionStorage.navStack = [];
			self.navStack = $sessionStorage.navStack;
		}

		self.navigateStack = function (i, params) {
			if (i < 0) {
				if (self.navStack.length > 1) {
					i = self.navStack.length - 2;
					self.navigateStack(i, params);
				}
				else {
					$window.history.back();
				}
			}
			else {
				if (!!params) {
					$sessionStorage.navStack[i].params = params;
				}
				self.navStack = $sessionStorage.navStack;
				$rootScope.$emit('changed-stack');

				// Crumb has no link -> Navigate to previous crumb instead.
				if ($sessionStorage.navStack[i].link == '.') {
					self.navigateStack(i - 1, params);
				}
				else {
					$state.go($sessionStorage.navStack[i].link, $sessionStorage.navStack[i].params);
				}
			}
		}

		/**
       *  Navigates states when an item in the menu is clicked.
       *  @return boolean   whether page should keep a wide vision (makes room for a third level submenu) or not
       */
		self.navigate = function (state, params, wipeStack) {

			const newWindow = false;

			if (wipeStack) {
				self.resetStack();
				delete $localStorage.advancedSearch;
			}

			if (newWindow) {
				const url = $state.href(state, params);
				$timeout(() => {
					$window.open(url,'_blank');
				}, 120)
			}
			else {
				$state.go(state, params);
			}
		};

		self.navigateBack = function (pop) {
			self.popStack();

			if ($state.current.name.match(specialProfileStates) != null || pop) {
				self.popStack();
			}

			self.navigateStack(self.navStack.length - 1);
		}

		self.getStack = function(fromState, toState, toParams) {
			const end = function() {
				$rootScope.$emit('changed-stack');
			}

			const check = fromState.name === '';

			// State that is getting added to stack
			const next = createCrumb(toState, toParams, toState.data || {});

			// Parent state
			const parentName  = next.link.substring(0, next.link.lastIndexOf('.'));
			const parent      = $state.get(parentName) || {};

			// It's a "Primary" state. Usually accessed through main menu.
			if (parent.abstract && !(toState.data || {}).super) {
				self.resetStack();
			}

			// "Super" crumb: Children states don't stack on one another
			// Example: Listing, Person.
			if (true === (parent.data || {}).super && !(toState.data || {}).skipParent) { // Added skip parent because of listings.detail.suppliers.profile - it's the only state that has it
				isSuperInStack(parent, toParams, check);

				const next2 = angular.copy(next);
				next2.title = (toState.data || {}).title || null;
				addToStack(next2, check);
				end();
			}
			// Current state is a "Super" state.
			// Add crumb for it, then placeholder title crumb if required
			else if ((toState.data || {}).super){
				isSuperInStack(toState, toParams, check);
				checkPrevious(toState.data.previous, toParams, check);
				const next2 = angular.copy(next);
				next2.link = '.';
				next2.title = (toState.data || {}).title || null;
				addToStack(next2);
				end();
			}
			// Regular state
			else {
				// Some states require a second crumb to be added before theirs.
				// Example: 'Edit buyer' needs to be [ buyer name ] > Edit Buyer

				if ((toState.data || {}).previous) {
					checkPrevious(toState.data.previous, toParams, check);
				}
				addToStack(next, true);
				end();
			}
		}

		self.getTaskCrumb = function(params) {
			if (params.task) {
				const aboutType = params.task.aboutType;
				return TaskService.getTaskOwner(params.taskId, aboutType);
			}
		}

		self.getAgencyName = function(id) {
			return AgencyService.getAgencyName(id);
		}

		self.getFullName = function(id, role) {
			return UserService.getFullName(id, role);
		}

		self.getTaskName = function(id) {
			return TaskService.getTaskName(id);
		}

		self.getState = function(name) {
			return $state.get(name);
		}

		self.getListingAddress = function(id) {
			return ListingService.getListingAddress(id);
		}

		self.getTaskTemplateName = function(id) {
			return ResourceService.getTaskTemplateName(id);
		}

		function addToStack(item, check) {
			if (item) {
				let notRepeated;
				if (check) {
					notRepeated = self.getCurrStackItem({ name: item.link }) == null;
				}
				if ((check && notRepeated) || !check) {
					if (!$sessionStorage.navStack) {
						$sessionStorage.navStack = [];
					}

					$sessionStorage.navStack.push(item);
					self.navStack = $sessionStorage.navStack;
				}
			}
		}

		/**
       *  Creates a new crumb.
       *  @param      state       Object      state information
       *  @param      params      Object      Parameters to be added to crumb
       *  @param      data        Object      state.data
       *  @return                 Object      new crumb
       */
		function createCrumb(state, params, data) {

			const crumb = {
				link: state.name,
				params: params
			}

			if (state.abstract === true && !!data.next) {
				crumb.link = data.next;
				crumb.original = state.name;
			}

			if (params.title) {
				crumb.title = params.title;
			}
			else if (data.getTitle) {
				data.getTitle(params, self).then((res) => {
					crumb.title = res;
				});
			}
			else if (data.title) {
				crumb.title = data.title;
			}
			else {
				crumb.title = null;
			}

			return crumb;
		}

		/**
       *  Checks if 'super' state is already in the stack.
       *  If true, pops stack up to that item. Otherwise, adds it to the end.
       *  @param    item        Object            state information
       *  @param    params      Object            Parameters used in current state transition
       *  @param    check       boolean           If true, performs check in stack before adding another item
       *  @return               boolean           True if it was already in the stack, false otherwise
       */
		function isSuperInStack(item, params, check) {
			const i = self.getCurrStackItem(item);
			if (i != null) {
				const toAdd = createCrumb(item, params, item.data || {});
				if(
					toAdd.link === self.navStack[i].link &&
					toAdd.original === self.navStack[i].original &&
					self.navStack[i].original === 'main.listings.detail' &&
					self.navStack[i].link === 'main.listings.detail.submenu'
				) {
					self.navStack[i] = toAdd;
				}
				// Remove other children states before adding new one
				while(i < self.navStack.length - 1 && self.navStack.length > 1) {
					self.popStack(true);
				}
				return true;
			}
			else {
				const toAdd = createCrumb(item, params, item.data || {});
				if ((item.data || {}).previous) {
					checkPrevious(item.data.previous, params, check);
				}
				toAdd.large = true;
				addToStack(toAdd,check);
				return false;
			}
		}

		/**
       *  Gets state information and adds it to nav stack.
       *  @param    previous    Object/function   state or function to get state
       *  @param    params      Object            Parameters used in current state transition
       *  @param    check       boolean           If true, performs check in stack before adding another item
       */
		function checkPrevious(previous, params, check) {

			if ('function' === typeof previous) {
				previous = previous(params, self);
			}
			// if a promise is returned
			if (previous && previous.$$state) {
				const toAdd = {
					link: '',
					title: '',
					params: ''
				};
				addToStack(toAdd, check);
				previous.then((data) => {
					previous = data;
					toAdd.title = previous.title;
					toAdd.link = previous.link;
					toAdd.params = previous.params;
				});
			}
			else {
				cont();
			}

			function cont() {
				if (previous) {
					// Is a state
					if (previous.data) {
						if (previous.data.super) {
							isSuperInStack(previous, params, check);
						}
						// Has a function to get the title
						else if ((previous.data || {}).getTitle) {
							previous.data.getTitle.then((res) => {
								if (res) {
									addToStack({
										link: previous.name,
										title: res,
										params: params
									}, check);
								}
							})
						}
						else {
							const toAdd = {
								link: previous.abstract ? '.' : previous.name,
								title: previous.data.title,
								params: previous.params
							};
							addToStack(toAdd, check);
						}
					}
					// Has a normal title. Just add it.
					else if (previous.title) {
						addToStack({
							link: previous.link,
							title: previous.title,
							params: previous.params,
							large: previous.super
						}, check);
					}
				}
			}
		}
	});
})(); // End of function()
