 (function() {

  "use strict";

  angular.module("smartbrokr")
  .controller("ImportTaskTemplateModalController", function($uibModalInstance,GlobalVars,ResourceService,type) {

    var self = this;

    // VARIABLES ============================================================================================================================

    self.templates    = [];       // Array of templates belonging to this user
    self.allChecked   = false;    // Whether all templates are checked
    self.calendar     = null;     // Object with all options for uib-datepicker
    self.startDate    = null;     // Selected start date for selected tasks

    // SETUP VARIABLES ======================================================================================================================

    self.startDate = new Date();

    self.calendar = GlobalVars.createCalendarConfig();

    ResourceService.getTaskTemplates(type).then(function(res){
			self.templates = res;
    })
    .catch(function (err) {})

    // FUNCTIONS ============================================================================================================================

    self.checkAll = function() {
      for (var i=0; i<self.templates.length; i++) {
        self.templates[i].checked = self.allChecked;
      }
    }

    self.checkOne = function(item) {
      self.allChecked = self.allChecked && item;
    }

    self.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    self.import = function() {
      var ret = [];
      var startDate = self.startDate;
      for (var i=0; i<self.templates.length; i++) {
        if (self.templates[i].checked) {

          self.templates[i].tasks.sort(function(a,b){ return a.seq - b.seq; })

          ret.push(self.templates[i]);
        }
      }
      $uibModalInstance.close({ templates: ret, startDate: startDate });
    };

  }) // End of controller
 })(); // End of function()
