(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('IncompleteTasksController', function ($filter, $log, $sce, $scope, $state, $timeout, $window, AlertService, GlobalVars, SortingService, TaskService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		let _tasks      = [];           // All tasks, as retrieved from the backend
		self.tasks      = [];           // Filtered tasks, as they will be displayed in the page
		self.columns    = [];           // Columns for the list of tasks

		self.calendars = {
			due: null,          // Scope created for the btnCalendarGroup directive used for the due date filter
			created: null,      // Scope created for the btnCalendarGroup directive used for the created date filter
			dueMin: null,       // Minimum date for the btnCalendarGroup directive used for the due date filter
			createdMax: null    // Maximum date for the btnCalendarGroup directive used for the created date filter
		}

		self.dropdowns  = {
			config: GlobalVars.createSingleConfig(false, 'id', 'label', 'label', false, 'type'),    // Selectize config for both dropdowns
			assignedTo: [],     // Options for the assignedTo filter dropdown
			about: []           // Options for the about filter dropdown
		}

		self.filters = {
			assignedTo: { value: '', sub: 'id' },
			about: { value: '', sub: 'id' },
			createdMin: { value: null, filterFunction: function(value, item) {
				return moment(value).isSameOrBefore(item.created, 'day');
			} },
			createdMax: { value: null, filterFunction: function(value, item) {
				return moment(value).isSameOrAfter(item.created, 'day');
			} },
			dueMin: { value: null, filterFunction: function(value, item) {
				return moment(value).isSameOrBefore(item.due, 'day');
			} },
			dueMax: { value: null, filterFunction: function(value, item) {
				return moment(value).isSameOrAfter(item.due, 'day');
			} },
		}

		self.sortFilters = {
			due: { weight: 2, desc: 1 },
			created: { weight: 1, desc: 1 }
		}

		_load();

		// FUNCTIONS ============================================================================================================================

		/**
         *  Sets all attributes in the filter object to null
         */
		self.clearFilters = function() {
			for (const i in self.filters) {
				self.filters[i].value = null;
			}
			self.doFilter();
		}

		/**
         *  Filters tasks according to the selected filters.
         */
		self.doFilter = function() {
			self.tasks = $filter('searchMultiFilter')(_tasks, self.filters);
			_sort(null, true);
		}

		/**
         *  Makes call to back end to generate a PDF file for this report.
         *  After the file is generated, it is opened in a new window
         */
		self.export = function() {
			AlertService.loading();
			let fileUrl = null;
			TaskService.exportIncompleteTasks(self.filters, self.dropdowns, self.sortFilters).then((file) => {
				fileUrl = file.url;
			})
			.finally(() => {
				$timeout(() => {
					if (!!fileUrl) {
						$window.open(fileUrl, '_blank');
					}
					AlertService.doneLoading();
				}, 2000);   // Wait 2s before opening the file so there is enough time to upload it
			});
		}

		/**
         *  Sorts the current tasks according to a column's, filter
         *  @param  {object}    column      Column where the ordering button was clicked
         */
		self.sort = function (column) {
			column = column || {};
			if (column.sort) {
				_sort(column.filter);
			}
		};

		$scope.$watch('incompleteTasksController.filters.createdMin.value', _watchCreated);
		$scope.$watch('incompleteTasksController.filters.createdMax.value', _watchCreated);
		$scope.$watch('incompleteTasksController.filters.dueMin.value', _watchDue);
		$scope.$watch('incompleteTasksController.filters.dueMax.value', _watchDue);

		/**
         *  Listener function for createdMin and createdMax.
         *  Determines the minDate for the btnCalendarGroup directive used for due date
         */
		function _watchCreated(val) {
			const min = self.filters.createdMin.value;
			const max = self.filters.createdMax.value;
			self.calendars.dueMin = min || max;
		}

		/**
         *  Listener function for dueMin and dueMax.
         *  Determines the maxDate for the btnCalendarGroup directive used for created date
         */
		function _watchDue(val) {
			const min = self.filters.dueMin.value || new Date();
			const max = self.filters.dueMax.value;
			self.calendars.createdMax = max || min;
		}

		/**
         *  Makes call to retrieve incomplete tasks from the database.
         *  The options for "assigned to" and "related" dropdowns are retrieved
         *  from the results.
         */
		function _load() {
			$scope.loading = true;
			AlertService.loading();
			TaskService.getIncompleteTasks().then((res) => {
				_tasks      = res || [];
				self.tasks  = res || [];

				self.dropdowns.assignedTo.push({ label: '------', id: 'xxx' });
				let aux = $filter('unique')(res, 'assignedTo');
				aux.forEach((entry) => {
					if (!!entry) {
						self.dropdowns.assignedTo.push(entry);
					}
				})

				self.dropdowns.about.push({ label: '------', id: 'xxx' });
				aux = $filter('unique')(res, 'about');
				aux.forEach((entry) => {
					if (!!entry) {
						if (entry.label === 'PERSON.PERSONAL') {
							entry.label = $filter('translate')(entry.label);
						}
						self.dropdowns.about.push(entry);
					}
				})
			})
			.catch($log.error)
			.finally(() => {
				_columns();
				AlertService.doneLoading();
				$scope.loading = false;
			});
		}

		/**
         *  Sorts the current tasks
         *  @param  {string}    category The name of the attribute to sort by
         *  @param  {boolean}   keepDesc Whether to keep the current ascending/descending order
         */
		function _sort(category, keepDesc) {
			SortingService.sort(self.tasks, self.sortFilters, category, null, null, keepDesc);
		}

		/**
         *  Sets the columns for displaying the list of tasks
         */
		function _columns() {
			self.columns = [
				{
					name: 'TASK.ASSIGNED_TO',
					filter: 'assignedTo',
					baseClass: 'col-xs-12 col-md-2 task',
					getClass: TaskService.getClass,
					field: function(task) {
						if (task.assignedTo)
							return $filter('firstNameOnly')(task.assignedTo.label);

						return 'N/A';
					}
				},
				{
					name: 'TASK.TASK_NAME',
					filter: null,
					baseClass: 'col-xs-12 col-md-3',
					getClass: angular.noop,
					field: function(task) {
						const url = $state.href('main.tasks.view', { taskId: task.id });
						const link = '<a href="' + url + '" class="task-link">' + $filter('translate')(task.name) + '</a>';
						return $sce.trustAsHtml(link);
					}
				},
				{
					name: 'COMMON.RELATED',
					filter: 'about',
					baseClass: 'col-xs-12 col-md-2',
					getClass: angular.noop,
					field: function(task) {
						let label = (task.about || {}).label || 'N/A';
						if (label === 'PERSON.PERSONAL') {
							label = $filter('translate')(label);
						}
						return label;
					}
				},
				{
					name: 'DATES.CREATED',
					filter: 'created',
					baseClass: 'col-xs-12 col-md-2 sorting',
					getClass: angular.noop,
					sort: true,
					field: function(task) {
						if (!task.created)
							return 'N/A';
						return $filter('dateFormat3')(task.created);
					}
				},
				{
					name: 'DATES.DUE_DATE',
					filter: 'due',
					baseClass: 'col-xs-12 col-md-2 sorting',
					getClass: angular.noop,
					sort: true,
					field: function(task) {
						if (!task.due)
							return 'N/A';
						return $filter('dateFormat3')(task.due);
					}
				},
			]
		}
	}) // End of controller
})(); // End of function()
