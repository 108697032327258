/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.sorter', [])

	.service('SortingService', function ($rootScope, $filter, $log, $sce) {
		const self = this;

		self.pageFilters = {};

		self.getPageFilter = function(page) {
			return self.pageFilters[page] || null;
		}

		self.createPageFilter = function(page,filters) {
			self.pageFilters[page] = filters
			return self.pageFilters[page];
		}

		function isDate(date) {
			return (new Date(date) !== 'Invalid Date') && !isNaN(new Date(date));
		}

		/** Compares items to check which one is greater */
		function compare(item1, item2) {
			if (!item1 && !item2) return 0;
			if (!item1) return -1;
			if (!item2) return 1;

			// Both are numbers
			if (item1 >= 0 && item2 >= 0) {
				// Force bool to int
				return (item1 > item2 & 1) - (item1 < item2 & 1);
			}
			const itemsAreDates = isDate(item1) && isDate(item2);
			if(itemsAreDates) {
				if(moment(item2).isBefore(item1)) return 1;
				if(moment(item2).isAfter(item1)) return -1;
				return 0;
			}

			// Item is array -> looks into first item of array
			if (angular.isArray(item1)) {
				return (item1[0] || '').toString().trim().localeCompare((item2 || '').toString().trim());
			}

			if (!!item1.id && !!item2.id) {

				if (item1.name != undefined && item2.name != undefined) {
					return item1.name.toString().trim().localeCompare(item2.name.toString().trim());
				}
				else if (!!item1.labels && !!item2.labels) {
					const lang = $rootScope.language;

					const comp1 = item1.labels[lang] || item1.labels['EN'] || '';
					const comp2 = item2.labels[lang] || item2.labels['EN'] || '';
					return comp1.localeCompare(comp2);
				}
				return item1.id.toString().trim().localeCompare(item2.id.toString().trim());
			}

			return item1.toString().trim().localeCompare(item2.toString().trim());
		};

		/**
		 *  Applies compare function to nested properties
		 *  @param  {object}    item1       item to be compared
		 *  @param  {object}    item2       item to be compared
		 *  @param  {string}    category    current category to apply to items
		 *  @param  {string[]}  children    list of nested properties to go through
		 */
		function checkNested(item1, item2, category, children) {

			if (!item1 && !item2) return 0;

			if (!item1) return -1;

			if (!item2) return 1;

			// Got to property we want
			if ((children || []).length === 0) {
				return compare(item2[category], item1[category]);
			}
			else {
				const children2 = angular.copy(children);
				const child = children2.shift();
				item1 = item1[category] || null;
				item2 = item2[category] || null;

				// We only want to compare one item in an array
				if (angular.isArray(item1) && angular.isArray(item2)) {
					return checkNested(item1[0], item2[0], child, children2);
				}
				else if (!!item1 && !!item2) {
					return checkNested(item1, item2, child, children2);
				}
				else if (!item1) {
					return -1;
				}
				else if (!item2) {
					return 1;
				}
				else {
					return 0;
				}
			}
		}

		/**
		 *  Sorts an array according to properties and weights.
		 *  Used for lists of objects that can be sorted according to different properties.
		 *  Each time a new property is used for sorting, the previous ones are saved to be used as secondary sorting methods (in reverse call order)
		 *
		 *  @param  {array<object>}   array       Array of objects to be sorted
		 *  @param  {object}          filters     Object with all possible filters.
		 *                                        Format: { property_name: { weight: int, value: anything, desc: 1|-1, sortFunction: function (opt), filterFunction: function (opt) } }
		 *  @param  {string=}         category    Property name that will be used for new sorting. If not provided, will get filter category with largest weight
		 *  @param  {array<string>=}  subcats     Array of Strings with properties that are objects.
		 *  @param  {string=}         page        Page name (used to save filters from previous searches in memory)
		 *  @param  {boolean=}        keepDesc    if true, won't revert (asc/desc) currently selected category
		 */
		self.sort = function (array, filters, category, subCats, page, keepDesc) {

			const maxWeight = Object.keys(filters).length;

			// Gives priority to previously stored filter info
			if (self.pageFilters[page]) {
				filters = self.pageFilters[page];
			}

			// If category is not specified, use the one with largest weight from
			// previous searches.
			if (!category) {
				let largest = 0;
				for (const cat in filters) {
					if (filters[cat].weight == maxWeight) {
						category = cat;
						break;
					}
					else if (filters[cat].weight >= largest) {
						category = cat;
						largest = filters[cat].weight;
					}
				}
			}

			const previousWeight = filters[category]['weight'] * 1; // * 1 in case it's not defined -> forced to 1

			filters[category]['weight'] = maxWeight * 1;

			// Don't change direction (asc or desc). Usually set to true in the first search when loading a page.
			if (!keepDesc) {
				filters[category]['desc'] *= -1;
			}

			// Adjust weights
			for (const cat in filters) {
				if (filters.hasOwnProperty(cat)) {
					if (filters[cat]['weight'] > previousWeight && cat.localeCompare(category) != 0) {
						filters[cat]['weight'] -= 1;
					}
				}
			}

			// Sort
			array.sort((a, b) => {
				const total = 0;
				try {
					const sortStep = function (filters, currSort, subCats) {
						let total = 0;

						for (const cat in filters) {
							if (filters.hasOwnProperty(cat)) {
								if (filters[cat]['weight'] == currSort) {
									if (filters[cat]['id'] == true) {
										total = compare(b['id'], a['id']) * filters[cat]['desc'];
									}
									else if (filters[cat]['phone']) {
										const compA = getPhone(a);
										const compB = getPhone(b);
										total = compare(compB, compA) * filters[cat]['desc'];
									}
									else if (filters[cat]['sortFunction']) {
										// If the sort value needs to be defined by comparing two or more properties
										// (Ex. rent/sell is determined by comparing askingPrice and rentalPrice).
										const sortFunction = filters[cat]['sortFunction'];
										a['isType'] = sortFunction(a);
										b['isType'] = sortFunction(b);
										total = compare(b['isType'], a['isType']) * filters[cat]['desc'];
									}
									// If it's a subproperty (like Listing -> Property -> Bathrooms)
									else if (!a[cat] && !b[cat] && !!subCats) {
										const length = (subCats || []).length;
										for (let i = 0; i < length; i++) {
											const children = subCats[i].split('.');
											children.push(cat);
											const child = children.shift();
											total = checkNested(a, b, child, children) * filters[cat]['desc'];

											if (total != 0) {
												break;
											}
										}
									}

									// Sometimes, it comes as an array. Only gets the first item of the array. (Buyer -> Property Types[0])
									else if (angular.isArray(a[cat]) && angular.isArray(b[cat])) {
										total = compare(b[cat][0], a[cat][0]) * filters[cat]['desc'];
									}
									// Regular property (Buyer -> FullName)
									else {
										total = compare(b[cat], a[cat]) * filters[cat]['desc'];
									}
									break;
								}
							}
						}
						if (total != 0) {
							return total;
						}
						else if (currSort > 0) {
							return sortStep(filters, currSort - 1, subCats);
						}
						else {
							return 0;
						}
					}
					return sortStep(filters, maxWeight, subCats);
				}
				catch (err) {
					$log.error(err);
					$log.debug('Filters: ', filters);
					$log.debug('subCats: ', subCats);
					return total;
				}
			});
			$rootScope.$emit('resetPagination');
		};

		/**
		 *  Sorts an array by its codes' labels.
		 *  Array must contain objects with relationship to Code and include Code.labels
		 *  Compares only the first label of each code.
		 *
		 *  @param  {array}   array   array to be sorted
		 *  @param  {array}   order   array of properties which are a relationship to Code, arranged
		 *                            in the order that must be sorted.
		 *                            Example: Sorting an array of Feature objects -> order = ['type','value'] -> Sorts by Type and then by Value
		 */
		self.sortByLabel = function (array, order) {
			try {
				const lang = $rootScope.language;
				array.sort((a, b) => {
					const orderCopy = angular.copy(order);

					const sortStep = function (curr) {
						let total = 0;

						const item1 = ((a[curr] || {}).labels || {})[lang] || ((a[curr] || {}).labels || {})['EN'] || '';
						const item2 = ((b[curr] || {}).labels || {})[lang] || ((b[curr] || {}).labels || {})['EN'] || '';

						total = compare(item1, item2) - compare(item2, item1);

						// Items are still the same and there are still fields to compare -> continue comparing
						if (total == 0 && orderCopy.length > 0) {
							return sortStep(orderCopy.shift());
						}

						return total;
					}

					return sortStep(orderCopy.shift());
				});
			}
			catch (err) {
				$log.error(err);
				$log.debug('array: ', array);
				$log.debug('order: ', order);
				return 0;
			}
		}

		function getPhone(item) {
			item = item || {};
			if (item.user || item.owner) {
				return $sce.valueOf($filter('getOnePhone')((item.user || item.owner || {}).phones));
			}
			else if (item.phones) {
				return $sce.valueOf($filter('getOnePhone')(item.phones));
			}
			else {
				return null;
			}
		}

	}); // End of controller
})(); // End of function()
