(function () {

	"use strict";

	angular.module("smartbrokr")

	.controller("ManagersController", function ($sce, $filter, AlertService, FileService, GlobalVars, ModalService, SortingService, UserService) {

		var self = this;

		// VARIABLES ============================================================================================================================

		self.manager	= {};
		self.columns	= null;
		self.managers	= [];

		self.filters 	= null;
		self.sortInfo 	= null;
		self.inject 	= null;

		var page;

		// SETUP VARIABLES ======================================================================================================================
		
		page 			= 'managers';
		self.filters 	= SortingService.getPageFilter(page);

		if (!self.filters) {
			self.filters = SortingService.createPageFilter(
				page,
				{
					fullName: { weight: 0, value: '', desc: 1 },
				}
			);
		}

		self.sortInfo = {
			page: page,
			sub: [ 'manager' ]
		}

		self.inject = {
			name: 'managersController',
			inject: self
		}

		getManagers();

		// FUNCTIONS ============================================================================================================================

		self.popover = function (id) {
			return GlobalVars.popover(id);
		};

		self.deleteUser = function (user) {
			UserService.deleteManager(user.id).then(function (res) {
				if (res) {
					getManagers();
				}
			})
			.catch(function (err) {})
		}

		self.export = function() {
			var toExport = $filter('searchMultiFilter')(self.managers, self.filters);
			FileService.exportManagersCsv(toExport);
		}

		self.openModal = function (manager, isNew) {
			var modal = ModalService.openModal('createManager', { 
				manager: function () { return manager }, 
				isNew: function () { return isNew },
				managers: function() { return self.managers }
			}, 'ManagerController', 'managerController');
			modal.result.then(function (res) {
				getManagers();
			})
			.catch(function (err) {});
		}

		function getManagers() {
			AlertService.loading();
			UserService.getManagers().then(function (res) {
				self.managers = res;
				SortingService.sort(self.managers, self.filters, 'fullName', [ 'manager' ], null);

				self.columns = [
					{
						name: 'PERSON.NAME',
						filter: 'fullName',
						size: 2,
						field: function (manager) {
							return (manager.manager || {}).fullName;
						}
					},
					{
						name: 'PROPERTY.PROPERTIES',
						noSearch: true,
						noSort: true,
						isHtml: true,
						size: 2,
						field: function (manager) {
							if (manager.allListings) {
								return $sce.valueOf($filter('translate')('LISTING.ALL_LISTINGS'));
							}
							else {
								var ret = '';
								var i, length = (manager.listings || []).length;
								for (i = 0; i < length; i++) {
									ret += ((((manager.listings[i] || {}).property || {})._address || {}).addressString || '') + '<br>';
								}
								return ret;
							}
						}
					},
					{
						name: 'PERSON.CLIENTS',
						noSearch: true,
						noSort: true,
						isHtml: true,
						size: 2,
						field: function (manager) {
							if (manager.allClients) {
								return $sce.valueOf($filter('translate')('PERSON.ALL_CLIENTS'));
							}
							else {
								var ret = '';
								var i, length = (manager.clients || []).length;
								for (i = 0; i < length; i++) {
									ret += ((manager.clients[i] || {}).fullName || '') + '<br>';
								}
								return ret;
							}
						}
					},
					{
						name: 'PERSON.PHONE',
						noSearch: true,
						noSort: true,
						size: 2,
						field: function (manager) {
							return $filter('getOnePhone')((manager.manager || {}).phones);
						}
					},
					{
						name: 'EMAIL.EMAIL',
						noSearch: true,
						noSort: true,
						isEmail: true,
						size: 2
					},
					{
						name: '',
						noSearch: true,
						noSort: true,
						isHtml: true,
						class: 'check',
						field: function(manager) {
							return '<a tabindex="0" class="btn btn-dots" uib-popover-template="\'' + self.popover('managerMenu') + '\'" popover-placement="auto bottom" popover-trigger="\'focus\'"></a>';
						}
					}
				]
			})
			.catch(function (err) {})
			.finally(function () {
				AlertService.doneLoading();
			})
		}

		self.sortUsers = function (category) {
			SortingService.sort(self.managers, self.filters, category, [ 'manager' ]);
		};
	});
})(); // End of function()
