/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('ListingsController', function (AlertService, ListingService, FileService, $scope, $sce, $state, $stateParams, $filter, $timeout, $translate, GlobalVars, SortingService, StorageService, $rootScope, NavService, ModalService, AccountService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		const cityOptions         = [];
		const provOptions         = [];
		const typeOptions         = [];
		const statusOptions       = [];
		const sharedOptions       = [];
		const rentSellOptions     = [];
		let defaultColumns      = [];

		const role                = AccountService.getRole();

		const singleConfig        = GlobalVars.singleConfig;
		const locationConfig      = GlobalVars.locationConfig;

		self.options            = null;
		self.current            = null;

		self.isDraft            = ($stateParams.submenu == 'drafts');
		self.isArchive          = ($stateParams.submenu == 'archive');

		self.mobileSearch       = '';
		self.cardView           = false;
		self.sortInfo           = null;
		self.filters            = null;

		self.navParams          = null;
		self.isShared           = $stateParams.submenu === 'shared';
		self.showButton         = ($stateParams.submenu == 'inventory' || $stateParams.submenu == 'smart' || $stateParams.submenu == 'drafts' || $state.submenu == 'shared') && AccountService.isLikeBroker();
		self.showAdmin          = ($stateParams.submenu === 'smart');
		self.legends            = GlobalVars.popover('listingStatusLegends');

		self.propertyConfig = GlobalVars.createSingleConfig(false, 'value', 'name', 'name');

		const NO_ADDRESS          = $filter('translate')('ADDRESS.NO_ADDRESS');

		// SETUP VARIABLES ======================================================================================================================

		$translate(['COMMON.RENT', 'LISTING.SELL', 'LISTING.RENT_SELL'])
		.then((translations) => {
			rentSellOptions.push({ name: '------', value: '' });
			rentSellOptions.push({ name: translations['COMMON.RENT'], value: 'Rent' });
			rentSellOptions.push({ name: translations['LISTING.SELL'], value: 'Sell' });
			rentSellOptions.push({ name: translations['LISTING.RENT_SELL'], value: 'Rent/Sell' });
		},
		(translationIds) => {
			rentSellOptions.push({ name: '------', value: '' });
			rentSellOptions.push({ name: 'Rent', value: 'Rent' });
			rentSellOptions.push({ name: 'Sell', value: 'Sell' });
			rentSellOptions.push({ name: 'Rent/Sell', value: 'Rent/Sell' });
		})

		defaultColumns = [
			{
				name: 'LISTING.LISTING_NO',
				filter: 'id',
				cardView: true,
				size: 2,
				field: function(listing) {
					return listing.id;
				}
			},
			{
				name: 'LISTING.HITS',
				filter: 'hits',
				noSearch: true,
				noSort: true,
				field: function(listing) {
					let i;
					let ret = 0;
					const length = (listing.clicks || []).length || 0;
					for (i = 0; i < length; i++) {
						ret += listing.clicks[i].total || 0;
					}
					return ret;
				}
			},
			{
				name: 'ADDRESS.ADDRESS',
				filter: 'addressString',
				cardView: true,
				size: 3,
				field: function(listing) {
					return ((listing.property || {})._address || {}).addressString || '';
				}
			},
			{
				name: 'ADDRESS.REGION',
				select: true,
				size: 2,
				options: cityOptions,
				filter: 'city',
				config: locationConfig,
				cardView: true,
				field: function(listing) {
					return (((listing.property || {})._address || {}).city || {}).name || '';
				}
			},
			{
				name: 'ADDRESS.PROVINCE_STATE',
				select: true,
				selectType: 'province',
				options: provOptions,
				filter: 'province',
				size: 2,
				config: locationConfig,
				cardView: true,
				field: function(listing) {
					return (((listing.property || {})._address || {}).province || {}).id || '';
				}
			},
			{
				name: 'DATES.EXPIRY_DATE',
				filter: 'expiry',
				cardView: false,
				size: 2,
				field: function(listing) {
					return $filter('dateFormat3')(listing.expiry);
				}
			},
			{
				name: 'PERSON.SELLER.SELLER_NAME',
				filter: 'fullName',
				cardView: false,
				isHtml: true,
				size: 2,
				field: function(listing) {
					let ret = '';

					if ((listing.sellers || []).length > 0) {
						listing.sellers.forEach((seller, i) => {
							ret += '<p>' + ((seller.user || {}).fullName || 'N/A') + '</p>';
						})
					}
					else {
						ret += '<p>-----</p>';
					}

					return ret;
				}
			},
			{
				name: 'PERSON.SELLER.SELLER_EMAIL',
				filter: 'email',
				isEmail: true,
				size: 2,
				field: function(listing) {
					return listing.sellers;
				}
			},
			{
				name: 'PROPERTY.PROPERTY_TYPE',
				select: true,
				options: typeOptions,
				filter: 'type',
				size: 2,
				config: singleConfig,
				cardView: true,
				field: function(listing) {
					return (((listing.property || {}).type || {}).labels || {})[$rootScope.language] || (((listing.property || {}).type || {}).labels || {})['EN'] || '';
				}
			},
			{
				name: 'COMMON.RENT',
				select: true,
				options: rentSellOptions,
				filter: 'rentSell',
				size: 2,
				config: singleConfig,
				cardView: true,
				field: function(listing) {
					if ((listing.askingPrice && listing.rentalPrice) > 0) {
						return $filter('translate')('LISTING.RENT_SELL');
					}
					else if (listing.askingPrice > 0) {
						return $filter('translate')('LISTING.SELL');
					}
					else if (listing.rentalPrice > 0){
						return $filter('translate')('COMMON.RENT');
					}
					else {
						return '----';
					}
				}
			},
			{
				name: 'COMMON.STATUS',
				select: true,
				options: statusOptions,
				filter: 'status',
				size: 2,
				config: singleConfig,
				cardView: true,
				field: function(listing) {
					return $filter('translate')('LISTING.STATUS.' + (listing.status || '').toUpperCase());
				}
			}
		];

		if($stateParams.submenu === 'shared') {
			const newStatusColumn = {
				name: 'COMMON.STATUS',
				select: true,
				options: statusOptions,
				filter: 'status',
				size: 2,
				config: singleConfig,
				cardView: true,
				field: function(listing) {
					return $filter('translate')('LISTING.SHARED_STATUS.' + (listing.shared[0].status || '').toUpperCase());
				}
			}
			defaultColumns.splice(defaultColumns.length - 1, 1, newStatusColumn);
		}

		if($stateParams.submenu === 'smart' || $stateParams.submenu === 'shared') {
			const propertyTypesColumn = {
				name: 'PROPERTY.PROPERTY_TYPE',
				select: true,
				options: typeOptions,
				filter: 'codes',
				size: 2,
				config: self.propertyConfig,
				isHtml: true,
				field: function(listing) {
					return ((listing.property || {}).codes || []).reduce((str, value) => {
						return str += '<p>' + ( value.labels ? value.labels[$rootScope.language || 'EN'] : value.id) + '</p>';
					}, '');
				}
			};
			defaultColumns.splice(8, 1, propertyTypesColumn);
		}


		if($stateParams.submenu === 'smart') {
			const sharedColumn = {
				name: 'RESOURCES.SHARED',
				select: true,
				options: sharedOptions,
				filter: 'shared',
				size: 2,
				config: singleConfig,
				cardView: true,
				field: function(listing) {
					return $filter('translate')('COMMON.' + (listing.shared.length > 0 ? 'YES' : 'NO'));
				}
			};

			defaultColumns.splice(defaultColumns.length - 4, 1, sharedColumn);
		}


		if (role != 'agencyMemberProfile' && role != 'agencyOwnerProfile') {
			self.navParams = {
				state: 'main.listings.detail.submenu',
				getParams: function(entry) {
					if (entry) {
						return { id: entry.id };
					}
					return {};
				}
			}
		}
		else {
			self.navParams = {
				onClick: function(entry) {
					ModalService.listingSummary(entry.id);
				}
			}

			defaultColumns.splice(1, 1);      // Remove "Hits" column
			defaultColumns.splice(5, 2);      // Remove "Seller name" and "Seller email" columns
			defaultColumns.push({
				name: 'ROLES.BROKERS',
				filter: 'fullName',
				cardView: false,
				isHtml: true,
				size: 2,
				field: function(listing) {

					const brokers = (listing || {}).brokers || [];

					if (brokers.length === 0)
						return '<p>-----</p>';

					return brokers.reduce((str, broker) => {
						if (((broker || {}).user || {}).fullName) {
							str += '<p>' + broker.user.fullName + '</p>';
						}
						return str;
					}, '')
				}
			})
		}

		self.options = {
			inventory: {
				getListings: ListingService.getInventory,
				columns: defaultColumns,
				title: 'Inventory',
				filtersPage: 'inventory'
			},
			smart: {
				getListings: ListingService.getSmart,
				columns: defaultColumns,
				title: 'Smart Listings',
				filtersPage: 'smart'
			},
			mls: {
				getListings: ListingService.getMLS,
				columns: defaultColumns,
				title: 'MLS Listings',
				filtersPage: 'mls'
			},
			shared: {
				getListings: ListingService.getShared,
				columns: defaultColumns,
				title: 'Shared SmartListings',
				filtersPage: 'shared'
			},
			archive: {
				getListings: ListingService.getArchive,
				columns: [],
				title: 'Archive',
				filtersPage: 'archive'
			},
			converted: {
				getListings: ListingService.getConverted,
				columns: [],
				title: 'Converted',
				filtersPage: 'converted'
			},
			drafts: {
				getListings: ListingService.getDrafts,
				columns: [],
				title: 'Drafts',
				filtersPage: 'drafts'
			}
		}

		self.current = {
			title: self.options[$stateParams.submenu].title,
			table: $stateParams.type,
			columns: self.options[$stateParams.submenu].columns,
			filtersPage: self.options[$stateParams.submenu].filtersPage
		};

		self.cardView = StorageService.getView(self.current.filtersPage) == 'card';

		self.sortInfo = {
			page: self.current.filtersPage,
			sub: ['property', 'property._address', 'sellers.user']
		}

		// All possible filters -> based on headers
		// self.filters = SortingService.getPageFilter(self.current.filtersPage);


		if (!self.filters) {
			self.filters = SortingService.createPageFilter(
				self.current.filtersPage,
				{
					addressString: { weight: 1, value: '', desc: -1 },
					hits: { weight: 0, value: '', desc: 1 },
					city: { weight: 0, value: '', desc: 1 },
					province: { weight: 0, value: null, desc: 1 },
					type: { weight: 0, value: '', desc: 1 },
					id: { weight: 2, value: '', desc: -1, id: true },
					status: { weight: 0, value: '', desc: 1 },
					expiry: { weight: 0, value: null, desc: 1, date: true },
					sold: { weight: 0, value: null, desc: 1, date: true },
					convertedTo: { weight: 0, value: '', desc: 1 },
					fullName: { weight: 0, value: '', desc: 1 },
					email: { weight: 0, value: '', desc: 1 },
					codes: {
						weight: 0, value: '', desc: 1,
						filterFunction: function(value, item) {
							if(!value || value === '') return true;
							if(!item.property) return false;
							if(!item.property.codes || item.property.codes === 0) return false;
							const foundValue = item.property.codes.find((code) => {
								if(code.id === value) return code;
							})
							return foundValue ? true : false;
						}
					},
					rentSell: {
						weight: 0, value: '', desc: 1,
						filterFunction: function(value, item) {
							if (value === 'Rent/Sell' && item.askingPrice > 0 && item.rentalPrice > 0) {
								return true;
							} else if (value === 'Sell' && item.askingPrice > 0) {
								return true;
							} else if (value === 'Rent' && item.rentalPrice > 0) {
								return true;
							} else {
								return false;
							}
						},
						sortFunction: function(item) {
							if (item.askingPrice > 0 && item.rentalPrice > 0) {
								return 'Rent/Sell';
							} else if (item.askingPrice > 0) {
								return 'Sell';
							} else if (item.rentalPrice > 0) {
								return 'Rent';
							} else {
								return '';
							}
						}
					},
					shared: {
						weight: 0, value: null, desc: 1,
						filterFunction: function(value, item) {
							if(value === 'Yes' && item.shared.length > 0) {
								return true;
							} else if(value === 'No' && (!item.shared || item.shared.length === 0)) {
								return true;
							}
							return false;
						}
					}
				}
			);
		}

		if (['agencyMemberProfile', 'agencyOwnerProfile'].indexOf(role) >= 0) {
			self.sortInfo.sub.pop();
			self.sortInfo.sub.push('brokers.user');
		}

		getListing(0);

		// FUNCTIONS ============================================================================================================================

		/**
         *  Gets columns for 'Archive' page
         *  - Replaces 'expiry date' with 'sold date'
         *  - Removes 'hits' column
         *  - Adds 'Delete' column
         */
		self.getArchiveColumns = function () {
			const columns = angular.copy(defaultColumns);
			const sold = {
				name: 'DATES.SOLD_DATE',
				filter: 'sold',
				field: function(listing) {
					return $filter('dateFormat3')(listing.sold);
				}
			};
			columns.splice(5, 1, sold);
			//var index = self.isDraft ? 5 : 3;
			self.options['archive'].columns = columns;
		}

		/**
         *  Gets columns for 'Drafts' page
         *  - Removes 'expiry date' and 'hits' columns
         *  - Adds 'Delete' column
         */
		self.getDraftColumns = function () {
			const columns = angular.copy(defaultColumns);
			columns.splice(5, 3);
			columns.splice(1, 1);
			columns.pop();
			columns.push({
				name: 'COMMON.DELETE',
				select: false,
				noSearch: true,
				noSort: true,
				isClick: true,
				isLimited: true,
				clickFunction: self.deleteListing,
				field: function() {
					return $sce.trustAsHtml('<button class="btn btn-trash" disabled="' + AccountService.limitedAccess() + '"></button>');
				}
			});
			self.options['drafts'].columns = columns;
		}

		/**
         *  Gets columns for 'Converted' page
         *  - Removes 'expiry date' and 'hits' columns
         *  - Adds 'Converted To' column
         */
		const getConvertedColumns = function() {
			const columns = angular.copy(defaultColumns);
			columns[5] = {
				name: 'LISTING.CONVERTED_TO',
				select: false,
				filter: 'convertedTo',
				size: 2,
				isClick: true,
				clickFunction: function(listing) {
					return true;
				},
				field: function(listing) {
					let link = '';
					if ((self.navParams || {}).state && !!listing.convertedTo) {
						link += ' href="' + $state.href(self.navParams.state, { id: listing.convertedTo }) + '" target="_blank"';
					}
					return $sce.trustAsHtml('<a class="hover-underline"' + link + '>' + listing.convertedTo + '</a>');
				}
			};
			columns.splice(1,1);
			self.options['converted'].columns = columns;
		}

		/*
        If Server Side pagination is used
        */
		self.getListings = function (pageNum) {
			getListing(pageNum);
		};

		self.columns = function () {
			return self.options[$stateParams.submenu].columns
		};

		function getListing(pageNum) {
			AlertService.loading();
			self.options[$stateParams.submenu].getListings(pageNum)
			.then((res) => {
				const now = moment();

				let i;
				const length = res.length;

				for (i = 0; i < length; i++) {
					if (res[i].expiry && !res[i].sold) {
						const diff = moment(res[i].expiry).diff(now, 'days');
						if (diff <= 5 && diff >= 0)
							res[i].ngClass = 'five-day-expiry';
						else if (diff <= 10 && diff > 5)
							res[i].ngClass = 'ten-day-expiry';
						else if (diff <= 30 && diff > 10)
							res[i].ngClass = 'thirty-day-expiry';
					}

					if ((((res[i].property || {})._address || {}).addressString || '').toLowerCase().includes('no address')) {
						res[i].property._address.addressString = NO_ADDRESS;
					}
				}
				self.listings = res;

				let aux = $filter('unique')(self.listings, 'property._address.city');
				cityOptions.push({ name: '------', id: '' });
				aux.forEach((item) => {
					cityOptions.push(item);
				})

				aux = $filter('unique')(self.listings, 'property._address.province');

				provOptions.push({ name: '------', id: '' });
				aux.forEach((item) => {
					provOptions.push(item);
				})

				if($stateParams.submenu === 'smart' || $stateParams.submenu === 'shared') {
					const auxCodes = $filter('unique')(self.listings, 'property.codes');
					typeOptions.push({ name: '------', value: 'xxx' });
					for (let i = 0; i < auxCodes.length; i++) {
						typeOptions.push({ name: auxCodes[i].labels ? auxCodes[i].labels[$rootScope.language || 'EN']: auxCodes[i].id, value: auxCodes[i].id });
					}
				} else {
					aux = $filter('unique')(self.listings, 'property.type');
					typeOptions.push({ name: '------', value: 'xxx' });
					for (let i = 0; i < aux.length; i++) {
						typeOptions.push({ name: aux[i].labels[$rootScope.language || 'EN'], value: aux[i].id });
					}
				}

				if($stateParams.submenu === 'smart') {
					sharedOptions.push({ name: '------', value: '' });
					const sharing = ['Yes', 'No'];
					for(let i = 0; i < sharing.length; i++) {
						sharedOptions.push(
							{
								name: $filter('translate')('COMMON.' + (sharing[i] || '').toUpperCase()),
								value: sharing[i]
							}
						);
					}
				}

				if($stateParams.submenu === 'shared') {
					statusOptions.push({ name: '------', value: '' });
					const sharedStatuses = ['Approved', 'Cancelled', 'Pending', 'Rejected',];
					for(let i = 0; i < sharedStatuses.length; i++) {
						statusOptions.push(
							{
								name: $filter('translate')('LISTING.SHARED_STATUS.' + (sharedStatuses[i] || '').toUpperCase()),
								value: sharedStatuses[i]
							}
						)
					}
				} else {
					aux = $filter('unique')(self.listings, 'status');
					statusOptions.push({ name: '------', value: '' });
					aux = aux.sort((a, b) => { return a.localeCompare(b) - b.localeCompare(a); });
					for (let i = 0; i < aux.length; i++) {
						if($stateParams.submenu !== 'smart' || ($stateParams.submenu === 'smart' && aux[i] !== 'Expired')) {
							statusOptions.push({ name: $filter('translate')('LISTING.STATUS.' + (aux[i] || '').toUpperCase()), value: aux[i] });
						}
					}
				}

				if (self.isDraft) {
					self.getDraftColumns();
				} else if (self.isArchive) {
					self.getArchiveColumns();
				}
				else if ($stateParams.submenu == 'converted') {
					getConvertedColumns();
				}

				self.headers = self.columns();

			})
			.catch((err) => {})
			.finally(() => {
				AlertService.doneLoading();
			})
		};

		self.createListing = function () {
			const listing = {
				property: {},
				status: 'Smart',
				isDraft: true
			};
			ListingService.createListing(listing)
			.then((res) => {
				ListingService.addProperty(res.id, {})
				.then((property) => {
					NavService.navigate('main.listings.detail.submenu', { id: res.id });
				})
				.catch((err) => {})
			})
			.catch((err) => {})
		}

		// Export csv
		self.exportListingsCsv = function () {
			let title = self.options[$stateParams.submenu].title;
			title = title.replace(' ', '-');
			const toExport = $filter('searchMultiFilter')(self.listings, self.filters);
			FileService.exportListingsCsv(toExport, title);
		};

		self.deleteListing = function (listing, i) {

			if (!AccountService.limitedAccess()) {
				const id = (listing || {}).id;

				ListingService.deleteListing(id, i).then((res) => {
					if (res) {
						getListing(0);
					}
				})
				.catch((err) => {
					console.log('err: ', err);
				})
			}
		}

		self.shareNewListing = function() {
			const modal = ModalService.openModal('shareListing', {
				selectedListing: function() { return false },
				listingId: function() { return null },
				brokers: function() { return [] },
				showBrokers: function() { return true },
				selectedRecipient: function () { return null },
				sharedBrokers: function() { return [] }
			}, 'ShareListingModalController', 'shareListingModalController');
			modal.result
			.then((res) => {})
			.catch((err) => {})
			.finally(() => {});
		}

		self.convertListing = function () {
			const modal = ModalService.openModal('convertListing', {}, 'ConvertListingController', 'convertListingController');
			modal.result
			.then((res) => {})
			.catch((err) => {})
			.finally(() => {
				getListing(0);
			})
		};

	}); // end controller

})(); // End of function()
