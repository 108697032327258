(function () {

    "use strict";

    angular.module("smartbrokr")
    .controller("TellUsController", function (AccountService, $state, $document) {
        var self = this;

        self.data = AccountService.getTellUs() || {
            firstName: "",
            lastName: "",
            password: "",
            passwordConfirmation: "",
            email: "",
            businessName: "",
            agencyName: "",
            realtorCode: "",
            centrisCode: "",
            brokerCode: ""
        };

        self.visitedReview  = !!AccountService.getVisitedReview();
        self.isAgency       = $state.params.type == 'agency';
        self.isBroker       = !self.isAgency;

        self.init = function () {
            var inputs = $document.find('input.field');
            Array.prototype.forEach.call(inputs, function (input) {
                input.addEventListener('focus', function () {
                    input.classList.add('is-focused');
                });
                input.addEventListener('blur', function () {
                    input.classList.remove('is-focused');
                });
            });
        }

        self.next = function () {
            AccountService.isEmailRegistered(self.data.email).then(function(res){
                if (res.response === true) {
                    self.emailError = {
                        isError: true,
                        email: self.data.email
                    };
                }
                else {
                    AccountService.saveTellUs(self.data);
                    var permissionState = 'main.signup.permissions-realtor';
                    var system = 'realtor';
                    if (self.data.centrisCode !== '') {
                        permissionState = 'main.signup.permissions-centris';
                        system = 'centris';
                    }
                    $state.go(permissionState, { system: system });
                }
            })
        }

        self.prev = function () {
            $state.go('main.signup.start-signup');
        }
        
        self.goToReview = function () {
            AccountService.saveTellUs(self.data);
            var system = self.data.centrisCode !== '' ? 'centris' : 'realtor';
            $state.go('main.signup.review', { system: system });
        }

        self.checkCodes = function(form,lose,isDirty) {
            if (isDirty && !!self.data[lose]) {
                self.data[lose] = null;
                form[lose].$setPristine();
            }
        }
    });
})();
