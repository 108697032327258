(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('NewTaskModalController', function ($uibModalInstance, $filter, $log, $rootScope, $scope, $translate, $window, AlertService, GlobalVars, TaskService, itemId, itemType, task, taskSuppliers, AccountService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.task               = null;   // Task that is being created/edited

		self.selectedPerson     = null;   // Broker that will be assigned the task
		self.selectedSupplier   = null;   // Supplier that will be assigned the task
		self.agencyPeople       = null;   // Options to select a broker
		self.suppliers          = null;   // Options to select a supplier

		self.calendar           = null;   // Object with all options for uib- datepicker object

		$scope.loadingPeople    = true;   // False when loading is done
		$scope.loadingSuppliers = true;   // False when loading is done
		$scope.addSupplier      = false;  // When true, shows input field to add supplier

		const currentRole         = AccountService.getRole();
		self.isBroker           = (currentRole == 'brokerProfile' || currentRole == 'managerProfile');

		const taskNames           = [];

		// SETUP VARIABLES ======================================================================================================================

		/** New Task */
		if (!task) {
			self.task = {
				name: '',
				assignedToId: null,
				assignedToType: null,
				description: null,
				due: new Date(),
				updates: [],
				files: [],
				supplierIds: [],
				suppliers: []
			};

			self.task.aboutId = itemId;
			self.task.aboutType = itemType;
		}

		/* Edit Task */
		else {
			self.task = angular.copy(task);
			self.task.due = new Date(self.task.due);  // Added because calendar needs a Date object and DB saves it as String
		}

		taskSuppliers = taskSuppliers || [];

		$uibModalInstance.scope = $scope;
		$uibModalInstance.check = {
			original: angular.copy(self.task),
			clone: self.task
		}

		/* Calendar popup object */
		self.calendar = GlobalVars.createCalendarConfig();

		/** A 'New Task' situation should come with a Listing. An 'Edit Task' one doesn't need it. */
		if (itemId != null) {
			self.itemId = itemId;
		}

		getAgencyPeople();
		getSuppliers();
		getTaskNames();

		// FUNCTIONS ============================================================================================================================

		/** Saves task and closes modal with a result */
		self.save = function () {

			const selectedPerson = self.selectedPerson || {};
			self.task.assignedToId    = selectedPerson.id || null;
			self.task.assignedToType  = selectedPerson.dataType || null;
			self.task.supplierIds = (self.task.suppliers || []).reduce(idArray, []);

			delete self.task.suppliers;

			if (!!self.task.name && typeof self.task.name === 'object') {
				self.task.name = self.task.name.label;
			}
			else if (self.task.translated){
				const found = taskNames.find((item) => {
					return item.translated === self.task.name;
				})

				if (found) {
					self.task.name = found.label;
				}

				delete self.task.translated;
				delete self.task.listing;
			}

			TaskService.saveTask(self.task).then((res) => {
				AlertService.saved();
				$uibModalInstance.close({ task: res });
			})
			.catch((err) => {
				AlertService.errorMessage('basic');
				$log.error(err);
			})
		};

		/** Typeahead function for Task.assignedTo input */
		self.searchAgency = function (value) {
			const ret = $filter('searchSingleFilter')(self.agencyPeople,value,{ 'user.fullName':'' });
			return ret.sort((a, b) => a.user.fullName.localeCompare(b.user.fullName) - b.user.fullName.localeCompare(a.user.fullName));
		};

		self.toggleSupplierField = function() {
			$scope.addSupplier = !$scope.addSupplier
			if (self.selectedSupplier) {
				self.selectedSupplier = null;
			}
		}

		/** Typeahead function for Task.name input
     *  Names were taken from the standard task list they had in BBM/LM
     */
		self.searchTaskName = function (value) {
			value = value.toLowerCase();
			const ret = taskNames.reduce((arr, curr) => {
				if (curr.translated.toLowerCase().includes(value)) {
					arr.push(curr);
				}
				return arr;
			}, []);
			return ret.sort((a, b) => a.translated.localeCompare(b.translated));
		}

		/** Typeahead function for Supplier input */
		self.searchSupplier = function (value) {
			const ret = $filter('searchSingleFilter')(self.suppliers, value, { search: '' });
			return ret.sort((a, b) => a.company.localeCompare(b.company) - b.company.localeCompare(a.company));
		}

		self.onSelect = function($item, $model, $label) {
			self.suppliers.splice(self.suppliers.indexOf($item), 1);
			self.task.suppliers.push($item);
			self.selectedSupplier = null;
		}

		self.deleteSupplier = function(supplier) {
			self.task.suppliers.splice(self.task.suppliers.indexOf(supplier),1);
			self.suppliers.push(supplier);
		}

		function getAgencyPeople() {
			TaskService.getAgencyPeople().then((res) => {
				self.agencyPeople = res;

				self.agencyPeople = res.reduce((arr,item) => {
					item.hidden = $filter('translate')(item.dataType);
					item.search = (item.user || {}).fullName + '|' + item.hidden;
					arr.push(item);
					return arr;
				},[]);

				if (!!self.task.assignedToId) {
					self.selectedPerson = res.find((item) => {
						return item.id == self.task.assignedToId && item.dataType == self.task.assignedToType;
					})
				}
			})
			.catch((err) => {})
			.finally(() => {
				$scope.loadingPeople = false;
			})
		}

		function getSuppliers() {
			TaskService.getSuppliers((self.task || {}).supplierIds || []).then((res) => {
				self.suppliers = res.concat(taskSuppliers || []);
				self.suppliers.forEach(_addSearch);
				self.task.suppliers.forEach(_addSearch);
			})
			.catch((err) => {})
			.finally(() => {
				$scope.loadingSuppliers = false;
			})

			function _addSearch(item) {
				item.hidden = ((item.industry || {}).labels || {})[$rootScope.language || 'EN'] || ((item.industry || {}).labels || {})['EN'];
				item.search = item.company + ' | ' + ((item.owner || {}).fullName || 'N/A') + ' | ' + item.hidden;
			}
		}

		function getTaskNames() {

			$scope.translating = true;

			const labels = [
				'TASK.TASK_LIST.AGREEMENT', 'TASK.TASK_LIST.ANALYTICS', 'TASK.TASK_LIST.APPRAISAL',
				'TASK.TASK_LIST.BUYER_NOTIFICATION', 'TASK.TASK_LIST.CARAVAN_SHEET', 'TASK.TASK_LIST.CREATE_LISTING',
				'TASK.TASK_LIST.CLOSED', 'TASK.TASK_LIST.CONDITIONS', 'TASK.TASK_LIST.COUNTER_OFFER',
				'TASK.TASK_LIST.DOCUMENTS_COLLECTED', 'TASK.TASK_LIST.CLOSING_DATE', 'TASK.TASK_LIST.FEATURE_SHEET',
				'TASK.TASK_LIST.FINANCE', 'TASK.TASK_LIST.INSPECTIONS', 'TASK.TASK_LIST.MEASUREMENTS',
				'TASK.TASK_LIST.MARKETING', 'TASK.TASK_LIST.NOTIFY', 'TASK.TASK_LIST.OFFER_PROGRESS',
				'TASK.TASK_LIST.RECEIVED_OFFER', 'TASK.TASK_LIST.OFFER_ACCEPTED', 'TASK.TASK_LIST.OFFER_REFUSED',
				'TASK.TASK_LIST.OPEN_HOUSE', 'TASK.TASK_LIST.PEER_TO_PEER', 'TASK.TASK_LIST.PHOTOGRAPHY',
				'TASK.TASK_LIST.PRINT_ADS', 'TASK.TASK_LIST.PROPOSAL', 'TASK.TASK_LIST.PROGRESS_REPORT',
				'TASK.TASK_LIST.SIGNAGE', 'TASK.TASK_LIST.UPDATE_BROKER_SITE', 'TASK.TASK_LIST.CREATE_VIDEO',
				'TASK.TASK_LIST.VIRTUAL_CARAVAN' ];

			$translate(labels).then(
				(translated) => {
					for (const label in translated) {
						const toAdd = { label: label, translated: '' + translated[label] };
						if (self.task.name === label) {
							self.task.name = '' + translated[label];
							self.task.translated = true;
						}

						taskNames.push(toAdd);
					}
				}, (ids) => {})
			.finally(() => {
				$scope.translating = false;
			})
		}

		function idArray(arr, item) {
			arr.push(item.id);
			return arr;
		}
	}) // End of controller
})(); // End of function()
