/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function() {

	'use strict';

	angular.module('smartbrokr')
	.controller('SuppliersController', function($filter, $scope, $sce, $state, $stateParams, AccountService, AlertService, FileService, ListingService, ModalService, NavService, OwnerService, SortingService, StorageService, SupplierService, UserService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.suppliers		= null;					// Data that is shown to user (filtered)
		self.filters		= null;					// Parameters for sort/filter
		self.sortInfo		= null;
		self.cardView		= false;
		self.navParams		= null;
		self.addState 		= null;					// Name of state to add a new supplier (different if accessing from a listing)
		self.isListing 		= $stateParams.listing;
		self.isSeller 		= AccountService.getRole() === 'sellerProfile';

		const page			= 'suppliers';

		// SETUP VARIABLES ======================================================================================================================

		self.cardView 	= StorageService.getView(page) == 'card';
		// self.filters 	= SortingService.getPageFilter(page);
		self.addState 	= getAddState();

		if (!self.filters) {
			self.filters = SortingService.createPageFilter(
				page,
				{
					company: { weight: 1, value: '', desc: -1 },
					industry: { weight: 0, value: '', desc: 1 },
					fullName: { weight: 0, value: '', desc: 1 },
					phones: { weight: 0, value: '', desc: 1, phone: true },
					email: { weight: 0, value: '', desc: 1 },
					city: { weight: 0, value: '', desc: 1 },
					postalCode: { weight: 0, value: '', desc: 1 },
					emailEnabled: { weight: 0, value: '', desc: 1 },
					// doNotEmailEnabled: { weight: 0, value: '', desc: 1 }
				}
			);
		}

		self.sortInfo = {
			page: page,
			sub: [ 'owner', 'owner._address' ]
		}

		self.navParams = {
			getState: function(entry) {
				entry = entry || {};
				if (self.isSeller || entry.isMine || !self.isListing) {
					return 'main.suppliers.profile';
				}
				return 'main.listings.detail.suppliers.profile';
			},
			getParams: function(entry) {
				if (entry) {
					if (self.isSeller || entry.isMine || !self.isListing) {
						return { id: entry.id };
					}
					return { sId: entry.id };
				}
				return {};
			}
		}

		getSuppliers();

		// FUNCTIONS ============================================================================================================================

		self.addToListing = function() {
			if (self.isListing) {
				SupplierService.getSuppliers().then((people) => {
					const linkType = 'listing';
					const m = ModalService.openModal('addPersonToListing', {
						id: function () { return $stateParams.id },
						people: function () { return people },
						peopleType: function () { return 'supplier' },
						linkType: function() { return linkType }
					}, 'AddPersonController', 'addPersonController');

					m.result.then((res) => {
						if (res) {
							getSuppliers();
						}
					}).catch((err) => {});
				})
			}
			else if (self.isSeller) {
				NavService.navigate('main.suppliers.add', { id: null });
			}
		}

		self.bulkEmail = function() {
			if(!AccountService.limitedAccess()) {
				UserService.processBulkEmails($filter('searchMultiFilter')(self.suppliers, self.filters), 'owner');
			}
		}

		/**
		 *	Opens modal to confirm deletion of supplier.
		 *	After modal is closed, updates view.
		 *	@param 		supplier 		Entry from list view representing the supplier to be deleted
		 */
		self.deleteSupplier = function(supplier) {
			if (!AccountService.limitedAccess()) {
				if (self.isListing) {
					if (supplier.isMine) {
						ListingService.unlinkSupplier($stateParams.id, supplier.id).then((res) => {
							getSuppliers();
						})
					}
				}
				else {
					ModalService.openModalWithFunction('deleteUser', {
						id: function () { return supplier.id },
						user: function () { return supplier; },
						deleteFunction: function () { return SupplierService.deleteSupplier; },
						userType: function () { return 'supplier' }
					},
					'DeleteUserController',
					'deleteUserController',
					getSuppliers
					);
				}
			}
		}

		// Export PDF
		self.exportSuppliersPdf = function() {
			ListingService.exportSuppliersPdf($stateParams.id || OwnerService.getListingId());
		}

		// Export csv - Complete
		$scope.exportComplete = function () {
			self.isPopoverOpen = false;
			const exportFilter = self.filters;
			exportFilter.checked = { weight: 0, value: 'true', desc: true }
			const toExport = $filter('searchMultiFilter')(self.suppliers, exportFilter);
			if(toExport.length > 0) {
				FileService.exportComplete(toExport, 'supplier');
			} else {
				const modal = ModalService.openModal('nothingToExport', { folder: function () { return {} }, type: function() { return '' }, options: function() { return { } } }, 'FolderController', 'folderController');
				modal.result
				.then((res) => {})
				.catch((err) => {});
			}
		};

		// Export csv - Simplified (first name, last name and email)
		$scope.exportSimple = function() {
			self.isPopoverOpen = false;
			const exportFilter = self.filters;
			exportFilter.checked = { weight: 0, value: 'true', desc: true }
			const toExport = $filter('searchMultiFilter')(self.suppliers, exportFilter);
			if(toExport.length > 0) {
				FileService.exportSimple(toExport, 'ROLES.SUPPLIER', true);
			} else {
				const modal = ModalService.openModal('nothingToExport', { folder: function () { return {} }, type: function() { return '' }, options: function() { return { } } }, 'FolderController', 'folderController');
				modal.result
				.then((res) => {})
				.catch((err) => {});
			}
		}


		function getAddState() {
			const params = {};

			if (self.isListing) {
				params.listingId = $stateParams.id;
			}

			return $state.href('main.suppliers.add', params);
		}

		function getSuppliers() {
			AlertService.loading();

			let api;

			if (self.isListing) {
				api = ListingService.getSuppliers($stateParams.id);
			}
			else if (self.isSeller) {
				api = OwnerService.getSuppliers();
			}
			else {
				api = SupplierService.getSuppliers();
			}

			api.then((res) => {
				self.suppliers = res;
				self.columns = SupplierService.getColumns(res, self.deleteSupplier, self.isListing || self.isSeller);
				SortingService.sort(self.suppliers,self.filters,null,['owner'],page,true);
			})
			.catch((err) => {})
			.finally(AlertService.doneLoading)
		}

	}) // End controller
})(); // End of function()
