/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('CardController', function ($uibModalInstance, $document, $rootScope, AlertService, PaymentService, LocationService, AccountService, oldCard) {

		const self = this;

		// VARIABLES ============================================================================================================================

		const stripeKey = PaymentService.getStripeKey(); // Our key with Stripe
		const stripe    = Stripe(stripeKey); // Object to use Stripe api
		let cardElem;
		self.card       = null;     // Card element
		self.errors     = {};       // Errors to be displayed

		// SETUP VARIABLES ======================================================================================================================



		self.errors = {
			card: null,
			address: null,
			zip: null
		}

		if (oldCard) {
			self.card = {
				name: oldCard.name,
				address_1: oldCard.address_line1,
				address_2: oldCard.address_line2,
				postalCode: oldCard.address_zip,
				customer: oldCard.customer,
				id: oldCard.id,
				city: '',
				state: '',
				country: '',
				address: {
					country: null,
					province: null,
					region: null,
					city: null
				}
			}
		}
		else {
			self.card = AccountService.getBillingAddress() || {
				name: '',
				address_1: '',
				address_2: '',
				city: '',
				state: '',
				country: '',
				postalCode: '',
				address: {
					country: null,
					province: null,
					region: null,
					city: null
				}
			};
		}

		$document.ready(init);

		// FUNCTIONS ============================================================================================================================

		self.close = function () {
			$uibModalInstance.dismiss('cancel');
		};

		self.save = function() {

			AlertService.loading();
			const extraDetails = {
				name: self.card.name,
				address_line1: self.card.address_1,
				address_line2: self.card.address_2,
				address_zip: self.card.postalCode
			};

			if ((self.card.address || {}).cityId) {
				const address = angular.copy(self.card.address);

				LocationService.getCity(address.cityId).then((city) => {
					city = city || {};

					extraDetails.address_city = city.name;
					extraDetails.address_state = ((city.region || {}).province || {}).name;
					extraDetails.address_country = (((city.region || {}).province || {}).country || {}).nameEn;

					stripe.createToken(cardElem, extraDetails).then(setOutcome);
				})
			}
			else {
				stripe.createToken(cardElem, extraDetails).then(setOutcome);
			}
		};

		function init() {
			const elements  = stripe.elements({ locale: $rootScope.language });

			cardElem = elements.create('card', {
				iconStyle: 'solid',
				hidePostalCode: true,
				style: {
					base: {
						iconColor: '#8898AA',
						color: '#555',
						lineHeight: '36px',
						fontWeight: 300,
						fontFamily: '"Montserrat", sans-serif',
						fontSize: '14px',

						'::placeholder': {
							color: '#989898',
						},
					},
					invalid: {
						iconColor: '#e85746',
						color: '#e85746',
					}
				},
				classes: {
					focus: 'is-focused',
					empty: 'is-empty',
				},
			});

			cardElem.mount('#card-element-new');

			const inputs = document.querySelectorAll('input.field');
			Array.prototype.forEach.call(inputs, (input) => {
				input.addEventListener('focus', () => {
					input.classList.add('is-focused');
				});
				input.addEventListener('blur', () => {
					input.classList.remove('is-focused');
				});
				input.addEventListener('keyup', () => {
					if (input.value.length === 0) {
						input.classList.add('is-empty');
					} else {
						input.classList.remove('is-empty');
					}
				});
			});

			cardElem.on('change', (event) => {
				setOutcome(event);
			});

			/*        document.querySelector('form').addEventListener('submit', function (e) {
          e.preventDefault();
          var form = document.querySelector('form');
          var extraDetails = {
            name: form.querySelector('input[name=cardholder-name]').value,
          };
          stripe.createToken(card, extraDetails).then(setOutcome);
        })*/
		}

		function setOutcome(result) {
			const errorElement = document.querySelector('.error');
			errorElement.classList.remove('visible');

			if (result.error) {
				errorElement.textContent = result.error.message;
				errorElement.classList.add('visible');
				AlertService.doneLoading();
			}
			else if (result.token) {
				PaymentService.updateCard(result.token.id, self.card).then((res) => {
					$uibModalInstance.close(res);
				})
				.catch((err) => {

					errorElement.classList.add('visible');

					const error = (err.data || {}).error || {};
					if (error.errors) {
						self.errors.card = error.errors.card;
						self.errors.zip = error.errors.zip;
						self.errors.address = error.errors.address;
					}
					else if (error.raw) {
						self.errors.card = {
							code: error.raw.code,
							type: error.raw.type
						}
					}
					else {
						self.errors.card = {
							type: 'card_error'
						};
					}

					AlertService.doneLoading();
				})
			}
		}
	}) // End of controller
})(); // End of function()
