(function () {

    "use strict";

    angular.module("smartbrokr.admin")
    .controller("AdminMenuController", function ($rootScope, $scope, $state, AccountService, MenuService, ModalService) {

        var self = this;

        self.menu       = MenuService.getAdminMenu();
        self.active     = $state.current.params.menu || $state.params.menu;
        self.activeSub  = null;
        self.isAdmin    = true;

        if ($state.current.params.submenu) {
            self.activeSub = self.active;
            self.active += '.' + $state.current.params.submenu;
        }
        
        var stateWatcher = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            self.active = (toParams || {}).menu || null;

            if ((toParams || {}).submenu) {
                self.active += '.' + toParams.submenu;
            }
        })

        $scope.$on('$destroy', function() {
            stateWatcher();
        })

        self.getLink = function(navItem) {
            navItem = navItem || {};

            if (navItem.link) {
                return $state.href(navItem.link);
            }

            return null;
        }

        self.logout = AccountService.logout;

        /** 
         *  Opens modal to make a search 
         */
        self.search = function () {
            var searchValue = self.searchValue;

            if (!self.modalOpen) {
                self.modal = ModalService.openModal('adminSearch', { searchValue: function () { return searchValue; } }, 'AdminSearchController', 'searchController', $scope);
                self.modal.opened.then(function () {
                    self.modalOpen = true;
                });
                self.modal.result.finally(function (selectedItem) {
                    self.modalOpen = false;
                    self.searchValue = "";
                });
            }
        }

        /**
         *  Opens or closes a menu item's submenu
         * 
         *  @param {string} name    Name of the menu item
         */
        self.openSub = function(name) {

            var menu    = $state.params.menu;
            var submenu = $state.params.submenu;

            if (self.activeSub !== name) {      // Will open a different submenu
                self.activeSub = name;

                if (menu === name && !!submenu) {
                    self.active = name + '.' + submenu;
                }
                else {
                    self.active = name;
                }
            }
            else if (name === menu) {           // Current state belongs to submenu that will be closed
                self.activeSub = null;
            }
            else {                              // Will close currently opened submenu and set 'active' to menu item that corresponds to current state
                self.active = menu;

                if (submenu) {
                    self.active += '.' + submenu;
                    self.activeSub = menu;
                }
                else {
                    self.activeSub = null;
                }
            }
        }
    });

})();
