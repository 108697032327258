/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('CostController', function ($uibModalInstance, $scope, GlobalVars, PropertyService, id, cost, updating) {

		const self = this;

		self.id           = id;
		self.updating     = updating || false;
		self.cost         = cost;
		self.costTypes    = {
			options: GlobalVars.costTypes,
			config: GlobalVars.singleConfig
		};

		self.frequencies  = {
			options: GlobalVars.frequencies,
			config: GlobalVars.createSingleConfig(false, 'value', 'name', 'name', 'name', false, false, 'order')
		};

		$uibModalInstance.scope = $scope;
		$uibModalInstance.check = {
			original: angular.copy(self.cost),
			clone: self.cost
		}

		self.close = function () {
			$uibModalInstance.dismiss('cancel');
		};
		self.deleteCost = function () {
			if (self.updating) {
				PropertyService.deleteCost(self.id, self.cost.id)
				.then((res) => {
					$uibModalInstance.close(res);
				})
				.catch((err) => {})
			}
		}

		self.saveCost = function () {
			const cost = {
				provider: self.cost.provider || null,
				typeId: self.cost.typeId || null,
				year: self.cost.year || null,
				value: self.cost.value || null,
				frequency: self.cost.frequency || 'Y'
			}
			if (!self.updating) {
				PropertyService.createCost(self.id, cost)
				.then((res) => {
					$uibModalInstance.close(cost);
				})
				.catch((err) => {});
			}
			else if (self.updating) {
				PropertyService.updateCost(self.id, self.cost.id, self.cost)
				.then((res) => {
					$uibModalInstance.close(cost);
				})
				.catch((err) => {});
			}
			else {
				$uibModalInstance.close();
			}
		}

	}) // End of controller
})(); // End of function()
