(function () {

    "use strict";

    angular.module("smartbrokr")
    .controller("SellerMainController", function (AlertService, ListingService, OwnerService, $filter, $log, $rootScope) {
        var self = this;

        var listingId   = OwnerService.getListingId();
        self.listing    = null;
        self.photo      = null;
        self.property   = null;
        self.brokers    = [];
        self.sellers    = [];
        self.address    = null;

        self.logout = OwnerService.logout;

        getListing();

        function getListing() {
            AlertService.loading();

            ListingService.getListingSummary(listingId).then(function(listing) {
                self.address = ((listing || {}).property || {})._address || {};
                self.photo = (listing.files || [])[0] || ((listing.mlsPhotos || [])[0] || {}).file || {};
                self.listing = {
                    id: listing.id,
                    type: listing.id.includes('M') ? 'MLS' : 'Smart',
                    created: $filter('dateFormatLong')(listing.created),
                    expiry: $filter('dateFormatLong')(listing.expiry),
                    askingPrice: $filter('currency')(listing.askingPrice),
                    rentalPrice: $filter('currency')(listing.rentalPrice),
                    externalLink: listing.externalLink,
                    isRent: listing.rentalPrice > 0,
                    isSell: listing.askingPrice > 0 || listing.rentalPrice == 0,
                    virtualTourURL: ((listing.property || {}).characteristics || []).reduce(function(str, curr) {
                        if (curr.languageId && curr.languageId === $rootScope.language || str.length === 0) {
                            str = curr.virtualTourURL || '';
                        }
                        return str;
                    }, '')
                }
                self.property = {
                    buildingType: (listing.property || {}).buildingType,
                    style: (listing.property || {}).style,
                    type: (listing.property || {}).type,
                    beds: (listing.property || {}).numBedrooms || 0,
                    baths: (listing.property || {}).numBathrooms || 0,
                    powder: (listing.property || {}).numPowderRooms || 0,
                    floors: (listing.property || {}).numFloors || 0,
                    area: $filter('number')((listing.property || {}).buildingLivingArea || 0)
                }

                self.sellers = listing.sellers || [];
                self.brokers = listing.listingBrokers || [];

                getListingUrl(self.brokers);
            })
            .catch($log.error)
            .finally(AlertService.doneLoading);
        }

        function getListingUrl(brokers) {
            var lang = $rootScope.language;
            var otherLang = lang === 'EN' ? 'FR' : 'EN';

            for (var i = 0; i < brokers.length; i++) {
                if ((brokers[i].broker || {}).pluginUrl) {
                    var link = brokers[i].broker.pluginUrl[lang] || brokers[i].pluginUrl[otherLang];
                    if (link) {
                        self.listing.externalLink = link + '?id=' + self.listing.id;

                        if (brokers[i].primary) break;
                    }
                }
            }
        }

    });
})();
