/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.directives')

	.controller('NotesBarController', function ($filter, $rootScope, $sce, $scope, $state, $timeout, DashboardService, ModalService, UserService) {
		const self = this;

		self.noteFilter   = '';
		self.logFilter    = '';
		self.newNote      = null;
		$scope.isSearch   = false;

		self.logs         = [];
		self.notes        = [];

		let allLogs       = [];
		let allNotes      = [];

		getNotes();
		getLogs();

		function getLogs() {

			const getOne = function(item) {
				item = item || '';
				if (typeof item === 'object') {
					if (item.vars.scheduled) {
						item.vars.scheduled = $sce.valueOf($filter('date')(item.vars.scheduled, 'EEE, MMM dd yyyy, hh:mm a'));
					}
					return '' + $sce.valueOf($filter('translate')(item.translation,item.vars));
				}
				else if (item.includes('LOG') || item.includes('TASK_LIST')){
					return $sce.valueOf($filter('translate')(item));
				}
				else {
					return item;
				}
			}


			DashboardService.getActionLog(25)
			.then((res) => {
				allLogs = res;

				allLogs.forEach((log) => {
					log.action = getOne(log.action);
					log.actionResult = getOne(log.actionResult);

					if ((log.actionResult || '').replace)
						log.actionResult = (log.actionResult || '').replace(/@/g, '@<wbr>');

				})
			})
			.catch((err) => {})
			.finally(() => {
				self.logs = angular.copy(allLogs);
			})
		}

		function getNotes() {
			DashboardService.getNotes(25)
			.then((res) => {
				allNotes = res;
				allNotes.forEach((note) => {
					note.header = $sce.valueOf($filter('noteHeader')(note.header));
					note.text = $scope.getFiltered(note.text);
				})
			})
			.catch((err) => {})
			.finally(() => {
				self.notes = angular.copy(allNotes);
			})
		}

		$scope.getFiltered = function(text) {
			let ret = text || '';

			ret = $filter('linky')(ret, '_blank');
			ret = $filter('addNewLines')(ret);
			ret = $filter('addMLS')(ret);

			return $sce.trustAsHtml(ret);
		}

		self.filterLogs = function() {
			self.logs = $filter('searchSingleFilter')(allLogs, self.logFilter, { action: '', actionResult: '' });

			self.logs.forEach((log) => {
				log.highlighted = {
					action: $filter('highlightSearch')(log.action, self.logFilter),
					actionResult: $filter('highlightSearch')(log.actionResult, self.logFilter)
				};
			})
		}

		self.filterNotes = function() {
			self.notes = $filter('searchSingleFilter')(allNotes, self.noteFilter, { header: '', text: '' });

			self.notes.forEach((note) => {
				note.highlighted = {
					header: $filter('highlightSearch')(note.header, self.noteFilter),
					text: $filter('highlightSearch')(note.text, self.noteFilter)
				};
			})
		}

		$scope.openModal = function(note) {
			note = note || null;
			if (note) {
				delete note.highlighted;
			}
			const retFunction = function (result) {
				getNotes();
			}
			ModalService.openModalWithFunction('newNote', {
				note: function () { return note; }
			}, 'NotesModalController', 'notesModalController', retFunction);
		}

		$scope.deleteNote = function(note) {
			UserService.deleteNote(note.id).then((del) => {
				getNotes();
			})
			.catch((err) => {});
		}

		$scope.$on('reloadLogs', () => {
			$timeout(() => {
				getLogs();
			}, 2000);   // Wait 2s before loading so there's enough time to create the logs
		})

		$scope.toggleSearch = function() {
			if($scope.isSearch) {
				$scope.isSearch = false;
			}
			else {
				$scope.isSearch = true;
			}
		}

		$scope.getUrl = function(state, params) {
			return $rootScope.baseURL + $state.href(state,params);
		}

		self.expand = function(i) {
			if ($scope.expand === i) {
				$scope.expand = null;
			}
			else {
				if ($rootScope.screenSize === 'xs') {
					$scope.expand = null;

					$timeout(() => {
						$scope.expand = i;
					}, 100)

					$timeout(() => {
						const anchor = document.getElementById(i);
						const container = angular.element(document.getElementById('main-content'));
						container.scrollToElement(anchor, 20, 300);
					}, 175)
				}
				else {
					$scope.expand = i;
				}
			}
		}
	})

	/**
     *  Notes Bar directive - desktop version.
     */
	.directive('notesBar', () => {
		return {
			restrict: 'E',
			controller: 'NotesBarController',
			controllerAs: 'controller',
			scope: {
				noteState: '=?',
				logState: '=?',
				logParams: '=?'
			},
			link: function (scope, element, attrs) {
				scope.limitedAccess = scope.$root.limitedAccess;
				scope.language = (scope.$root.language || '').toLowerCase();
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;

				if (!attrs.noteState) {
					scope.noteState = 'main.dashboard.notes';
				}

				if (!attrs.logState) {
					scope.logState = 'main.dashboard.action-log';
				}

				if (!attrs.logParams) {
					scope.logParams = {};
				}
			},
			templateUrl: '/js/directives/notesBar/notesBar.html'
		}
	})

	/**
     *  Notes Bar directive - mobile version.
     */
	.directive('notesBarMobile', () => {
		return {
			restrict: 'E',
			controller: 'NotesBarController',
			controllerAs: 'controller',
			scope: {
				noteState: '=?',
				logState: '=?',
				logParams: '=?'
			},
			link: function (scope, element, attrs) {
				scope.limitedAccess = scope.$root.limitedAccess;
				scope.language = (scope.$root.language || '').toLowerCase();
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;

				if (!attrs.noteState) {
					scope.noteState = 'main.dashboard.notes';
				}

				if (!attrs.logState) {
					scope.logState = 'main.dashboard.action-log';
				}

				if (!attrs.logParams) {
					scope.logParams = {};
				}
			},
			templateUrl: '/js/directives/notesBar/notesBarMobile.html'
		}
	})

	/**
     *  Includes expanded log information about a Task
     */
	.directive('logTask', ($filter) => {
		return {
			restrict: 'E',
			scope: {
				log: '=',
				logFilter: '='
			},
			templateUrl: '/js/directives/notesBar/log-task.html',
			link: function(scope, element, attrs) {
				((scope.log || {}).changes || []).forEach((change) => {

					const label = change.label || change.property || '';

					if (Array.isArray(label) && label.length == 2) {
						change.label = $filter('translate')(label[0]) + ' (' + $filter('translate')(label[1]) + ')';
					}
					else {
						change.label = $filter('translate')(label);
					}

					if (change.property === 'completed' && !!change.newValue) {
						change.newValue = $filter('dateFormat')(change.newValue);
					}
				})
			}
		}
	})


})();
