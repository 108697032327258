/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.admin')
	.controller('AdminAgencyProfileController', function (AccountService, AdminService, AgencyService, AlertService, GlobalVars, ModalService, $filter, $log, $parse, $sce, $scope, $stateParams, $timeout) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.agency         = null;
		self.subscription   = null;
		self.offices        = [];
		self.edit           = false;
		self.columns        = {};
		self.error          = null;
		$scope.forms        = {};

		self.dropdowns = {              // Object with dropdown config and options
			licenseType: [],
			agencyPlans: [],
			brokerPlans: [],
			indBrokerPlans: [],
			single: GlobalVars.createSingleConfig(false, 'value', 'label', 'label'),
		}

		self.inject = {
			name: 'profileController',
			inject: self
		}

		self.logFilters = {
			action: { weight: 0, value: '', desc: -1 },
			created: { weight: 0, value: '', desc: -1, date: true, dateFilter: 'dateFormat' },
		}

		getAgency();
		getCodes();
		getPlans();

		// FUNCTIONS ============================================================================================================================

		$scope.registerForm = function(i) {
			const name = 'officeForm_' + i;
			$scope.forms[name] = {};
			return 'forms.' + name;
		}

		self.getForm = function(name) {
			const split = (name || '').split('.');
			let form = $scope || {};

			split.forEach((curr) => {
				form = form[curr] || {};
			})

			return form;
		}

		self.addOffice = function() {
			const last = (angular.copy(self.editOffices) || []).pop() || {};
			if (last.id) {
				self.editOffices.push({
					agencyId: self.agency.id,
					_address: {},
					phones: {},
					main: !self.mainOffice,
					brokers: [],
					members: []
				})
			}
			else {
				$scope.highlightNew = true;

				$timeout(() => {
					$scope.highlightNew = false;
				}, 500);
			}
		}

		/**
         *  Opens modal to assign brokers to an office.
         */
		self.assignOffices = function() {
			const modal = ModalService.openModal('assignOffices', { offices: function () { return self.editAgency.offices } }, 'AssignOfficesController', 'controller');

			modal.result.then((res) => {
				if (res.officeId && (res.people || []).length > 0) {
					AlertService.loading();
					AdminService.assignOffices(res.officeId, res.people, self.editAgency.id).then((res) => {
						AlertService.saved();
						getAgency();
					})
					.catch($log.error)
					.finally(AlertService.doneLoading);
				}
			})
			.catch((err) => {});
		}

		self.createPayment = function() {

			if (!(self.subscription || {}).customer) return;

			AdminService.getCards(self.subscription.customer.id).then((cards) => {
				const model = {
					source: (self.subscription.card || {}).id || null,
					amount: 0
				}

				const config = GlobalVars.createSingleConfig(false, 'id', 'last4', 'last4', true);

				config.render = {
					item: function(data, escape) {
						return '<div class="option" data-selectable="" data-value="' + data.id + '">' +
                            (data.brand ? '<i class="cc-brand fab fa-cc-' + data.brand.toLowerCase() + '"></i>' : '') +
                            '· · · · · · · · ' + data.last4 +
                            (data.id === self.subscription.card.id ? ' <i class="fas fa-star"></i> ' : '') +
                            '<div class="pull-right">' + data.expMonth + '/' + data.expYear + '</div>' +
                            '</div>';
					},
					option: function(data,escape) {
						return '<div class="option" data-selectable="" data-value="' + data.id + '">' +
                            (data.brand ? '<i class="cc-brand fab fa-cc-' + data.brand.toLowerCase() + '"></i>' : '') +
                            '· · · · · · · · ' + data.last4 +
                            (data.id === self.subscription.card.id ? ' <i class="fas fa-star"></i> ' : '') +
                            '<div class="pull-right">' + data.expMonth + '/' + data.expYear + '</div>' +
                            '</div>';
					}
				}

				const fields = [
					{
						label: 'Amount',
						class: 'amount-field',
						model: 'amount'
					},
					{
						label: 'Payment Source',
						class: 'source-cc',
						model: 'source',
						isSelect: true,
						select: {
							options: cards,
							config: config
						}
					}
				]

				const m = ModalService.openForm(fields, 'Create New Payment', true, model, 'adminPayment', 'admin-payment-modal');

				m.then((res) => {
					const charge = {
						amount: res.amount,
						source: res.source,
						currency: 'cad',
						customer: self.subscription.customer.id,
						description: 'Custom Payment',
						statement_descriptor: 'SMARTBROKR',
						metadata: {
							adminId: AccountService.getAdminId(),
							agencyId: self.agency.id,
							brokerId: (self.agency.brokerOwner || {}).id || null
						}
					}

					AdminService.createCharge(charge, self.agency.id).then((res) => {
						AlertService.saved();
					})
					.catch(doError)
					.finally(getAgency)

				})
				.catch((err) => {})
			})

		}

		self.deleteOffice = function(office, index) {
			if (office.id) {
				AdminService.deleteItem('Office', office.id).then((res) => {
					if (res) {
						AlertService.saved();
						getAgency();
					}
				})
				.catch(doError);
			}
			else {
				self.editOffices.splice(index, 1);
			}
		}

		self.editCreditCard = function() {
			const modal = ModalService.openModal('createCard', { oldCard: self.subscription.card }, 'CardController', 'cardController');
			modal.result.then((res) => {
				AlertService.saved();
				getAgency();
			})
			.catch((err) => {})
			.finally(AlertService.doneLoading)
		}

		self.editPlan = function() {

			const model = {
				brokerPlanId: ((self.subscription || {}).brokerPlan || {}).id,
				quantity: ((self.subscription || {}).brokerPlan || {}).quantity
			}

			let fields = [];

			if ((self.subscription || {}).agencyPlan) {
				model.agencyPlanId = ((self.subscription || {}).agencyPlan || {}).id;

				fields = [
					{
						label: 'Plan',
						model: 'agencyPlanId',
						isSelect: true,
						select: {
							options: self.dropdowns.agencyPlans,
							config: self.dropdowns.single
						}
					},
					{
						label: 'Broker Licenses',
						model: 'quantity',
						required: true,
						inputType: 'number',
						step: 1,
						min: 1
					}
				]
			}
			else {
				fields = [
					{
						label: 'Plan',
						model: 'brokerPlanId',
						isSelect: true,
						select: {
							options: self.dropdowns.indBrokerPlans,
							config: self.dropdowns.single
						}
					}
				]
			}

			const m = ModalService.openForm(fields, 'Edit Subscription', true, model, 'adminForm');

			m.then((res) => {
				AlertService.loading();

				if (res.agencyPlanId) {
					if (res.agencyPlanId === 'AGENCY_M') {
						res.brokerPlanId = 'AGENCY_BROKER_M';
					}
					else {
						res.brokerPlanId = 'AGENCY_BROKER_A';
					}
				}

				const toChange = {
					brokerPlan: {
						id: res.brokerPlanId,
						quantity: res.quantity
					}
				}

				if (res.agencyPlanId) {
					toChange.agencyPlan = { id: res.agencyPlanId };
				}

				AdminService.editSubscription(self.agency.id, self.subscription.id, '', toChange).then((changed) => {
					AlertService.saved();
					getAgency();
				})
				.catch((err) => {
					$log.error(err);
					self.error = err;

					$timeout(() => {
						const container = angular.element(document.getElementById('top'));
						container.scrollTopAnimated(1, 100);
					}, 50)

				})
				.finally(() => {
					AlertService.doneLoading();
				})
			})
			.catch((err) => {})
		}

		self.editSubscription = function(sub) {

			let plan = (sub.plans || []).find((item) => {
				return ((item || {}).id || '').includes('BROKER');
			})

			plan = angular.copy(plan);

			if (plan) {
				const fields = [
					{
						justText: true,
						labels: [ 'SmartBrokr - ' + $filter('translate')('ACCOUNT.PLAN_NAMES.' + plan.id) + ' • ' + plan.id ]
					},
					{
						label: 'Quantity',
						model: 'quantity',
						required: true,
						inputType: 'number',
						step: 1,
						min: 1
					}
				]

				const m = ModalService.openForm(fields, 'Edit Subscription', true, plan, 'adminForm');

				m.then((res) => {
					AlertService.loading();
					AdminService.editSubscription(self.agency.id, self.subscription.id, plan.sId, res).then((changed) => {
						AlertService.saved();
						getAgency();
					})
					.catch(doError)
					.finally(() => {
						AlertService.doneLoading();
					})
				})
				.catch((err) => {})
			}
		}

		self.refund = function(invoice) {

			if (!invoice) return;

			const amount = invoice.total || (invoice.amount - invoice.amount_refunded);

			if (!invoice.paid || !invoice.object || amount < 0) return;

			const model = {
				reason: '',
				amount: amount
			}

			const options = {
				agencyId: self.agency.id,
				brokerId: (self.agency.brokerOwner || {}).id,
				customer: invoice.customer
			};

			options[invoice.object] = invoice;

			const fields = [
				{
					label: 'Amount',
					model: 'amount',
					class: 'amount-field',
					inputType: 'money',
					required: true
				},
				{
					label: 'Reason',
					model: 'reason',
					isSelect: true,
					select: {
						options: [
							{ value: 'duplicate', label: 'Duplicate' },
							{ value: 'fraudulent', label: 'Fraudulent' },
							{ value: 'requested_by_customer', label: 'Requested by Customer' },
							{ value: 'none', label: 'Other' }
						],
						config: self.dropdowns.single
					}
				}
			]

			const m = ModalService.openForm(fields, 'Refund Payment', true, model, 'adminForm');

			m.then((res) => {
				options.amount = res.amount;
				options.reason = res.reason;

				AdminService.refundCharge(options).then((res) => {
					AlertService.saved();
				})
				.catch(doError)
				.finally(getAgency)
			})
			.catch((err) => {})

		}

		self.save = function(type, data, formName) {

			if (formName) {
				const form = $parse(formName)($scope);

				if (!form.$valid) {
					return false;
				}
			}

			let edit = null;

			switch(type) {
			case 'agency':
				edit = angular.copy(self.editAgency);
				break;
			case 'office':
				edit = angular.copy(data);
				edit.agencyId = self.agency.id;
				if (self.offices.length < 1) {
					edit.main = true;
				}
				break;
			}

			if (edit) {
				AlertService.loading();
				AdminService.saveProfile(edit, type).then((res) => {
					AlertService.saved();
					self.error = null;
				})
				.catch(doError)
				.finally(getAgency)
			}

		}

		self.toggleSubscription = function(sub) {
			AdminService.toggleSubscription(sub.id, self.agency.id, sub.status !== 'canceled' && !sub.willCancel).then((res) => {
				if (res) {
					AlertService.saved();
					getAgency();
				}
			})
			.catch(doError)
			.finally(AlertService.doneLoading);
		}

		self.undoAction = function(logId) {
			AdminService.undoAction(logId)
			.then((res) => {
				if (res) {
					AlertService.saved();
					getAgency();
				}
			})
			.catch(doError)
			.finally(AlertService.doneLoading);
		}

		/**
         *  Opens SmartBrokr as the 'main' agency owner (broker or agencyOwners[0])
         */
		self.viewOnSb = function() {
			if ((self.agency || {}).brokerOwner) {
				const owner = self.agency.brokerOwner;
				AdminService.loginAsUser(owner.sbUserId, self.agency.id, owner.id, 'brokerProfile');
			}
			else if (((self.agency || {}).agencyOwners || []).length > 0) {
				const owner = self.agency.agencyOwners.find((item) => { return !!item && ((item || {}).agencyOwnerProfile || {}).disabled === false });
				const profileType = 'agencyOwnerProfile';
				AdminService.loginAsUser(owner.id, self.agency.id, owner[profileType].id, profileType);
			}

		}

		$scope.getPlanAmount = function() {

			if (!self.subscription) return null;

			let total = 0;

			if (self.subscription.agencyPlan && self.subscription.status !== 'canceled') {
				total += self.subscription.agencyPlan.amount || 0;
			}

			if (self.subscription.brokerPlan && self.subscription.status !== 'canceled') {
				total += (self.subscription.brokerPlan.amount || 0) * self.subscription.brokerPlan.quantity;
			}

			total /= 100;

			return $filter('currency')(total, '$', 2);
		}

		function getAgency() {
			$scope.doneLoading = false;
			AlertService.loading();
			AdminService.getAgencyProfile($stateParams.id, true).then((res) => {
				const ag = res.agency;

				self.offices = ag.offices || [];
				self.mainOffice = ag.mainOffice || { main: true };

				delete ag.mainOffice;

				self.logs = res.logs;
				self.agency = ag;

				if (ag.brokerOwner) {
					$scope.isIndependentBroker = true;

					self.mainOffice._address = (ag.brokerOwner.user || {})._address || {};
					self.mainOffice.phones = (ag.brokerOwner.user || {}).phones || {};

					self.agency.agencyOwners = [ ag.brokerOwner.user ];
				}

				if (!self.offices.includes(self.mainOffice)) {
					self.offices.push(self.mainOffice);
				}

				$scope.disableViewOnSB = (self.agency.agencyOwners || []).length === 0;

				self.subscription = self.agency.subscription;

				self.agency.isActive = (!!self.subscription && (self.subscription || {}).status !== 'canceled') || self.agency.id === 'CAN_CENTRIS_ROA';
			})
			.catch($log.error)
			.finally(() => {
				self.editAgency = angular.copy(self.agency);
				self.editOffices = angular.copy(self.offices);

				if (self.subscription) {
					self.columns.upcoming = [
						{
							name: 'Amount',
							noSearch: true,
							noSort: true,
							isClick: true,
							clickFunction: function(invoice, i) {
								invoice.showExpanded = !invoice.showExpanded;
							},
							field: function(invoice) {
								let ret = '<div class="click-cursor">' + $filter('currency')(invoice.total / 100, '$', 2) +
                                    ' <span class="refund-total">(' + $filter('currency')(invoice.amount_due / 100, '$', 2) + ')</span></div>';

								if ((invoice.lines || {}).data) {
									ret += '<div class="invoice-lines" ng-show="entry.showExpanded" style="width: 500%">';

									invoice.lines.data.forEach((line) => {
										ret +=
                                            '<div class="invoice-line">' +
                                                '<div class="description">' + (line.description || 'Subscription Update') + '</div>' +
                                                '<div class="amount">' + $filter('currency')(line.amount / 100, '$', 2) + '</div>' +
                                            '</div>';
									})

									if (invoice.starting_balance !== 0) {
										ret +=
                                            '<div class="invoice-line">' +
                                                '<div class="description">Applied Balance</div>' +
                                                '<div class="amount">' + $filter('currency')(invoice.starting_balance / 100, '$', 2) + '</div>' +
                                            '</div>';
									}

									ret += '</div>';
								}

								return $sce.trustAsHtml(ret);
							}
						},
						{
							name: 'Billing Date',
							noSearch: true,
							noSort: true,
							field: function(invoice) {
								return $filter('dateFormat4')(invoice.next_payment_attempt, true);
							}
						},
						{
							name: 'Invoice Number',
							noSearch: true,
							noSort: true,
							field: function(invoice) {
								return invoice.number;
							}
						},
						{
							name: 'Bill To',
							noSearch: true,
							noSort: true,
							field: function(invoice) {
								return self.subscription.customer.email;
							}
						},
						{
							name: 'Billing Method',
							noSearch: true,
							noSort: true,
							field: function(invoice) {
								switch(invoice.billing) {
								case 'charge_automatically':
									return 'Charge payment source on file';
								case 'send_invoice':
									return 'Invoice will be sent by email';
								default:
									return 'Not specified'
								}
							}
						}
					]

					self.columns.invoices = [
						{
							name: 'Amount',
							noSearch: true,
							noSort: true,
							isClick: true,
							size: 3,
							clickFunction: function(invoice, i) {
								invoice.showExpanded = !invoice.showExpanded;
							},
							field: function(invoice) {
								let ret = '<div class="click-cursor">' + $filter('currency')(invoice.total / 100, '$', 2);
								let due = invoice.amount_due;

								if ((invoice._charge || {}).amount_refunded > 0) {
									due -= invoice._charge.amount_refunded;
								}

								ret += ' <span class="refund-total">(' + $filter('currency')(due / 100, '$', 2) + ')</span></div>';

								if ((invoice.lines || {}).data) {
									ret += '<div class="invoice-lines" ng-show="entry.showExpanded">';

									invoice.lines.data.forEach((line) => {
										ret +=
                                            '<div class="invoice-line">' +
                                                '<div class="description">' + (line.description || 'Subscription Update') + '</div>' +
                                                '<div class="amount">' + $filter('currency')(line.amount / 100, '$', 2) + '</div>' +
                                            '</div>';
									})

									if (invoice.starting_balance !== 0) {
										ret +=
                                            '<div class="invoice-line">' +
                                                '<div class="description">Applied Balance</div>' +
                                                '<div class="amount">' + $filter('currency')(invoice.starting_balance / 100, '$', 2) + '</div>' +
                                            '</div>';
									}

									if ((invoice._charge || {}).amount_refunded > 0) {
										ret +=
                                            '<div class="invoice-line">' +
                                                '<div class="description">Refund</div>' +
                                                '<div class="amount">' + $filter('currency')((invoice._charge.amount_refunded * -1) / 100, '$', 2) + '</div>' +
                                            '</div>';
									}

									ret += '</div>';
								}

								return $sce.trustAsHtml(ret);
							}
						},
						{
							name: 'Status',
							noSearch: true,
							noSort: true,
							isHtml: true,
							size: 3,
							field: function(invoice) {
								if (invoice._charge.refunded) {
									return '<span class="text-warning">Refunded (' + $filter('currency')(invoice._charge.amount_refunded / 100, '$', 2) + ')</span>';
								}
								if (invoice.paid && invoice._charge.amount_refunded > 0) {
									return '<span class="text-active">Paid with partial refund (' + $filter('currency')(invoice._charge.amount_refunded / 100, '$', 2) + ')</span>';
								}
								return invoice.paid ? '<span class="text-active">Paid</span>' : '<span class="text-red">Pending</span>';
							}
						},
						{
							name: 'Invoice Number',
							noSearch: true,
							noSort: true,
							size: 3,
							isHtml: true,
							field: function(invoice) {
								return '<a target="_blank" href="' + invoice.invoice_pdf + '">' + invoice.number + '</a>';
							}
						},
						{
							name: 'Billing Date',
							noSearch: true,
							noSort: true,
							size: 3,
							field: function(invoice) {
								return $filter('dateFormat')(invoice.date, true);
							}
						},
						{
							name: '',
							noSearch: true,
							noSort: true,
							isHtml: true,
							class: 'stripe-edit',
							field: function(sub) {
								return '<i class="fas fa-ellipsis-h pull-right"' +
                                    'uib-popover-template="\'/js/src/admin/templates/popover.invoice.html\'" ' +
                                    'popover-placement="auto bottom-left" ' +
                                    'popover-trigger="\'outsideClick\'"></i>';
							}
						}
					]

					self.columns.history = [
						{
							name: 'Product',
							noSearch: true,
							noSort: true,
							size: 5,
							field: function(sub) {
								let ret = '';
								if ((sub.plans || []).length > 0) {
									ret += 'Smartbrokr - ';
									sub.plans.forEach((plan, i) => {
										if (plan.id === 'TRIAL') {
											ret += 'Free Trial';
										}
										else {
											ret += $filter('translate')('ACCOUNT.PLAN_NAMES.' + plan.id);

											if (plan.id.includes('AGENCY_BROKER')) {
												ret += ' (' + plan.quantity + ') ';
											}

											if (i < sub.plans.length - 1) {
												ret += ' and ';
											}
										}
									})
								}
								return ret;
							}
						},
						{
							name: 'Status',
							noSearch: true,
							noSort: true,
							isHtml: true,
							size: 2,
							field: function(sub) {

								let tClass = 'text-inactive';
								let status = '';

								switch(sub.status) {
								case 'active':
								case 'trialing':
									if (!sub.willCancel) {
										tClass = 'text-active';
										status = 'Active';
									}
									else {
										tClass = 'text-warning';
										status = 'Cancel at period end';
									}

									break;
								case 'past_due':
									tClass = 'text-red';
									status = 'Past Due';
									break;
								case 'canceled':
									status = 'Cancelled';
									break;
								default: status = 'Inactive';
								}

								return '<span class="' + tClass + '">' + status + '</span>';
							}
						},
						{
							name: 'Billing',
							noSearch: true,
							noSort: true,
							field: function(sub) {
								switch(sub.billing) {
								case 'charge_automatically': return 'Auto';
								case 'send_invoice': return 'Email';
								default: return 'None';
								}
							}
						},
						{
							name: 'Started',
							noSearch: true,
							noSort: true,
							size: 2,
							field: function(sub) {
								return $filter('dateFormat')(sub.created, true);
							}
						},
						{
							name: '',
							noSearch: true,
							noSort: true,
							isHtml: true,
							class: 'stripe-edit',
							field: function(sub) {
								return '<i class="fas fa-ellipsis-h pull-right"' +
                                    'uib-popover-template="\'/js/src/admin/templates/popover.subscription.html\'" ' +
                                    'popover-placement="auto bottom-left" ' +
                                    'popover-trigger="\'outsideClick\'"></i>';
							}
						},
					]

					self.columns.charges = [
						{
							name: 'Amount',
							noSearch: true,
							noSort: true,
							isHtml: true,
							size: 3,
							field: function(charge) {
								let ret = '' + $filter('currency')(charge.amount / 100, '$', 2)

								if (charge.amount_refunded > 0) {
									ret += ' <span class="refund-total">(' + $filter('currency')((charge.amount - charge.amount_refunded) / 100, '$', 2) + ')</span>';
								}

								return ret;
							}
						},
						{
							name: 'Status',
							noSearch: true,
							noSort: true,
							isHtml: true,
							size: 3,
							field: function(charge) {

								if (charge.refunded) {
									return '<span class="text-warning">Refunded (' + $filter('currency')(charge.amount_refunded / 100, '$', 2) + ')</span>';
								}

								if (charge.paid && charge.amount_refunded > 0) {
									return '<span class="text-active">Paid with partial refund (' + $filter('currency')(charge.amount_refunded / 100, '$', 2) + ')</span>';
								}

								return charge.paid ? '<span class="text-active">Paid</span>' :
									charge.status === 'failed' ? '<span class="text-red">Failed</span>' : '<span class="text-red">Pending</span>';
							}
						},
						{
							name: 'Receipt Number',
							noSearch: true,
							noSort: true,
							size: 3,
							field: function(charge) {
								return charge.receipt_number || '----';
							}
						},
						{
							name: 'Billing Date',
							noSearch: true,
							noSort: true,
							size: 3,
							field: function(charge) {
								return $filter('dateFormat')(charge.created, true);
							}
						},
						{
							name: '',
							noSearch: true,
							noSort: true,
							isHtml: true,
							class: 'stripe-edit',
							field: function(sub) {
								return '<i class="fas fa-ellipsis-h pull-right"' +
                                    'uib-popover-template="\'/js/src/admin/templates/popover.invoice.html\'" ' +
                                    'popover-placement="auto bottom-left" ' +
                                    'popover-trigger="\'outsideClick\'"></i>';
							}
						}

					]
				}

				self.columns.logs = [
					AdminService.logColumns.action(4),
					AdminService.logColumns.date(4, null, 'vertical-align: top'),
					AdminService.logColumns.button
				]

				$scope.doneLoading = true;
				AlertService.doneLoading();
			})
		}

		function getCodes() {
			$scope.loadingCodes = true;
			GlobalVars.queryCodes('AGENCY_CERT', 'centris').then((res) => {
				res = res || [];

				res.forEach((item) => {
					self.dropdowns.licenseType.push({
						value: item.id,
						label: item.labels['EN']
					})
				})
			})
			.catch((err) => {
				$log.error(err);
			})
			.finally(() => {
				$scope.loadingCodes = false;
			})
		}

		function getPlans() {
			AdminService.getPlans().then((res) => {
				res.agency = res.agency || [];
				res.agencyBroker = res.agencyBroker || [];

				res.agency.forEach((item) => {
					self.dropdowns.agencyPlans.push({
						value: item.id,
						label: item.name
					})
				})

				res.agencyBroker.forEach((item) => {
					self.dropdowns.brokerPlans.push({
						value: item.id,
						label: item.name
					})
				})

				res.indBroker.forEach((item) => {
					self.dropdowns.indBrokerPlans.push({
						value: item.id,
						label: item.name
					})
				})
			})
		}

		function doError(err) {
			$log.error(err);
			self.error = err;

			$timeout(() => {
				const container = angular.element(document.getElementById('top'));
				container.scrollTopAnimated(1, 100);
			}, 50)
		}
	})
})();
