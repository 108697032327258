(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('AgencyProfileController', function (AgencyService, AccountService, AlertService, NavService, $rootScope, $stateParams, $scope) {

		const self = this;

		const role    = AccountService.getRole();
		self.agency = {};
		$scope.role = role;
		$scope.id   = $stateParams.id;
		$scope.type = $stateParams.type;

		self.viewSentEmails = function() {
			NavService.navigate('main.agency.sent-emails', { id: AccountService.getAgencyId(), fk: self.agency.user.id, role: $scope.type });
		}

		getAccount();

		function getAccount() {

			let apiCall;

			switch($stateParams.type) {
			case 'member':  apiCall = AgencyService.getMember; break;
			case 'auditor': apiCall = AgencyService.getAuditor; break;
			case 'notary':  apiCall = AgencyService.getNotary; break;
			case 'owner':   apiCall = AgencyService.getOwner; break;
			default: AlertService.errorMessage('accountDetails'); return;
			}

			AlertService.loading();

			apiCall($stateParams.id).then((res) => {
				if (res.length === 1) {
					self.agency = res[0];
				}
				else {
					AlertService.errorMessage('accountDetails');
				}
			})
			.catch((err) => {})
			.finally(() => {
				AlertService.doneLoading();
			})
		}
	});
})(); // End of function()
