/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('AgencyProfileEditController', function (AgencyService, AlertService, GlobalVars, ModalService, $log, $parse, $rootScope, $scope, $timeout, check) {

		const self = this;

		self.editAgency     = null;         // User's agency
		self.editOffices    = null;         // Agency's office
		self.dropdowns      = {};           // Object with dropdown options and config
		$scope.forms        = {};           // Object with one form for each office in the agency
		self.forms          = $scope.forms;
		self.isOwner        = true;         // Because we reuse the page for Admin/Agency profile, this flag is added to differentiate them

		self.dropdowns = {
			licenseType: [],
			single: GlobalVars.createSingleConfig(false, 'value', 'label', 'label')
		}

		getAgency();
		getCodes();

		// FUNCTIONS ============================================================================================================================

		$scope.registerForm = function(i) {
			const name = 'officeForm_' + i;
			$scope.forms[name] = {};
			return 'forms.' + name;
		}

		self.getForm = function(name) {
			const split = (name || '').split('.');
			let form = $scope || {};

			split.forEach((curr) => {
				form = form[curr] || {};
			})

			return form;
		}

		/**
         *  Adds an empty object to offices (so it can be modified)
         */
		self.addOffice = function() {
			const last = (angular.copy(self.editOffices) || []).pop() || {};
			if (last.id) {
				self.editOffices.push({
					agencyId: self.editAgency.id,
					_address: {},
					phones: {},
					featuredListing: '',
					main: !self.mainOffice,
					brokers: [],
					members: []
				})
			}
			else {
				$scope.highlightNew = true;

				$timeout(() => {
					$scope.highlightNew = false;
				}, 500);
			}
		}

		/**
         *  Opens modal to assign brokers to an office.
         */
		self.assignOffices = function() {
			const modal = ModalService.openModal('assignOffices', { offices: function () { return self.editAgency.offices } }, 'AssignOfficesController', 'controller');

			modal.result.then((res) => {
				if (res.officeId && (res.people || []).length > 0) {
					AlertService.loading();
					AgencyService.assignOffices(res.officeId, res.people).then((res) => {
						AlertService.saved();
						getAgency();
					})
					.catch($log.error)
					.finally(AlertService.doneLoading);
				}
			})
			.catch((err) => {});
		}

		/**
         *  Deletes an office. If office was already persisted in the DB (has an ID),
         *  deletes from the database. Otherwise, just remove office from array.
         *  @param  {object}    office  Office to be deleted
         *  @param  {number}    index   Index of this office in self.editOffices array
         */
		self.deleteOffice = function(office, index) {
			if (office.id) {
				AgencyService.deleteOffice(office.id).then((res) => {
					if (res) {
						AlertService.saved();
						getAgency();
					}
				})
				.catch((err) => {
					$log.error(err);
				})
			}
			else {
				self.editOffices.splice(index, 1);
			}
		}

		/**
         *  Submits a form and saves its data.
         *  @param  {string}    type        Type of object that will be saved. Either 'agency' or 'office'
         *  @param  {object}    data        Data that will be saved
         *  @param  {string=}   formName    Form name (for offices)
         */
		self.save = function(type, data, formName) {
			if (formName) {
				const form = $parse(formName)($scope);

				if (!form.$valid) {
					return false;
				}
			}

			let edit = null;

			switch(type) {
			case 'agency':
				edit = angular.copy(self.editAgency);
				delete edit.id;
				break;
			case 'office':
				edit = angular.copy(data);
				edit.agencyId = self.editAgency.id;
				if (self.editOffices.length === 1) {
					edit.main = true;
				}
				break;
			}

			if (edit) {
				AlertService.loading();
				AgencyService.saveAgency(edit, type).then((res) => {
					AlertService.saved();
				})
				.catch((err) => {
					$log.error(err);
				})
				.finally(getAgency)
			}

		}

		/**
         *  Gets the user's Agency from the DB
         */
		function getAgency() {
			AlertService.loading();
			$scope.doneLoading = false;
			AgencyService.getAgency().then((res) => {
				self.editAgency = res || {};
				self.editOffices = self.editAgency.offices || [];
				self.mainOffice = res.mainOffice || {};
				delete self.editAgency.mainOffice;
			})
			.catch($log.error)
			.finally(() => {
				$timeout(() => {
					check.clone = {
						agency: self.editAgency,
						offices: self.editOffices
					}
					check.original = angular.copy(check.clone);
				})
				AlertService.doneLoading();
				$scope.doneLoading = true;
			})
		}

		/**
         *  Gets codes for License Type dropdown
         */
		function getCodes() {
			$scope.loadingCodes = true;
			self.dropdowns.licenseType.push({ value: 'xxx', label: '------' });
			GlobalVars.queryCodes('AGENCY_CERT').then((res) => {
				res = res || [];

				res.forEach((item) => {
					self.dropdowns.licenseType.push({
						value: item.id,
						label: item.labels[$rootScope.language || 'EN'] || item.labels['EN']
					})
				})
			})
			.catch((err) => {
				$log.error(err);
			})
			.finally(() => {
				$scope.loadingCodes = false;
			})
		}
	})

	/**
     *  Controller for Assign Offices modal
     */
	.controller('AssignOfficesController', function ($filter, $scope, $q, $uibModalInstance, AgencyService, GlobalVars, offices) {
		const self        = this;
		let people      = null;
		const UNASSIGNED  = $filter('translate')('AGENCY.UNASSIGNED');

		self.config     = GlobalVars.createSingleConfig(false, 'id', 'legalName', 'legalName');
		self.current    = null;
		self.offices    = offices || [];
		self.officeId   = null;
		self.selected   = [];

		$uibModalInstance.scope = $scope;
		$uibModalInstance.check = {
			original: angular.copy(self.selected),
			clone: self.selected
		}

		self.close = function() {
			$uibModalInstance.dismiss('cancel');
		}

		self.save = function() {
			$uibModalInstance.close({ officeId: self.officeId, people: self.selected });
		}

		self.searchPeople = function(value) {
			const def = $q.defer();
			$scope.loading = true;

			if (!people) {
				AgencyService.getPeopleOffices().then((res) => {
					people = res || [];
					searchPeople();
				})
				.catch((err) => {
					def.reject(err);
					$scope.loading = false;
				})
			}
			else searchPeople();

			function searchPeople() {
				const comp = value.split(' ');
				const isInclude = function(val) {
					for (const i in comp) {
						if (val.toLowerCase().includes(comp[i]))
							return true;
					}
					return false;
				}

				const ret = people.reduce((arr, curr) => {
					if (isInclude((curr.user || {}).firstName || '') || isInclude((curr.user || {}).lastName || '')) {
						curr.title = (curr.user || {}).fullName + ' (' + ( curr.office ? curr.office.legalName : UNASSIGNED ) + ')';
						curr.hidden = '' + $filter('translate')('ROLES.' + curr.type);
						arr.push(curr);
					}

					return arr;
				}, []);

				$scope.loading = false;
				def.resolve(ret);
			}

			return def.promise;
		}

		self.select = function(item) {
			if (people) {
				people.splice(people.indexOf(item), 1);
			}
			self.selected.push(item);
			self.current = null;
		}

		self.unselect = function(item) {
			if (people) {
				people.push(item);
			}
			self.selected.splice(self.selected.indexOf(item), 1);
		}

	})
})()
