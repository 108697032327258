/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.directives')
	.controller('AdvSearchController', ['AlertService', 'GlobalVars', 'DashboardService', 'StaticService', '$q', '$scope', function (AlertService, GlobalVars, DashboardService, StaticService, $q, $scope) {
		const self = this;

		// VARIABLES ============================================================================================================================

		self.currentPage 	= 1;		// Current page in adv. search pagination
		self.pagMaxSize		= null;		// Used to determine how many pagination buttons we can show (depends on screen size)

		self.singleConfig	= null;		// Selectize config (dropdown lists)
		self.propertyConfig = null;     // Selectize config (dropdown lists)
		self.locationConfig	= null; 	// Selectize config (dropdown lists)
		self.countryConfig	= null;		// Selectize config (dropdown lists)

		self.showResults 	= false;	// Whether to show results (only used for mobile)
		self.results		= null;		// Advanced search results

		self.propertyTypes 	= null;		// Property type options
		self.countries 		= null;		// Options for country dropdown in adv. search
		self.provinces 		= null;		// Options for province dropdown in adv. search
		self.cities 		= null;		// Options for city dropdown in adv. search

		self.searchParameters = null;	// Parameters for search

		self.listingsMaxPrice = 0;		// Max listing price

		self.priceSlider 	= null;		// Slider config (price)
		self.bedroomSlider	= null;		// Slider config (number of bedrooms)
		self.bathroomSlider	= null;		// Slider config (number of bathrooms)

		// SETUP VARIABLES ======================================================================================================================

		self.searchParameters 	= { data: { what: 1 } };
		self.singleConfig 		= GlobalVars.singleConfig;
		self.propertyConfig     = GlobalVars.createMultiConfig(false, 'value', 'name', true, null, 'name');
		self.locationConfig 	= GlobalVars.createSingleConfig(false, 'id', 'name', 'name');
		self.countryConfig 		= GlobalVars.createSingleConfig(false, 'id', 'nameEn', 'nameEn');
		self.propertyTypes 		= GlobalVars.getCodes('propertyTypes');
		self.officeConfig       = GlobalVars.createSingleConfig(false, 'id', 'legalName', 'legalName');

		self.countries          = StaticService.getCountries(true, true);
		self.provinces 			= [{ id: 'xxx', name: '------' }];
		self.cities 			= [{ id: 'xxx', name: '------' }];
		self.offices            = [{ legalName: '------', id: 'xxx' }];

		// Setup max number of pagination numbers (controles were stacking in small screens)
		if (document.getElementById('content').offsetWidth < 350) {
			self.pagMaxSize = 4;
		}
		else if (document.getElementById('content').offsetWidth < 800) {
			self.pagMaxSize = 6;
		}
		else {
			self.pagMaxSize = 10;
		}

		self.priceSlider = {
			options: {
				floor: 0,
				step: 10000,
				onChange: function() {
					self.updatePriceRange();
				}
			}
		};

		self.bedroomSlider = {
			options: {
				floor: 0,
				step: 1,
				ceil: 10
			}
		};

		self.bathroomSlider = {
			options: {
				floor: 0,
				step: 1,
				ceil: 10
			}
		};

		// Checks for any previous stored search
		getSearchFilters()
		.then(() => {
			if (DashboardService.getStoredSearch() != null) {
				const aux = DashboardService.getStoredSearch();
				self.searchParameters.data = angular.copy(aux);

				const countryId = aux.country;
				const provinceId = aux.province;

				if (countryId) {
					self.searchParameters.data.country = countryId;
					self.filterProvinces(countryId);
				}

				if (provinceId) {
					self.searchParameters.data.province = provinceId;
					self.filterCities(provinceId);
				}

				if (aux.city) {
					self.searchParameters.data.city = aux.city;
				}

				refreshSlider();
				self.search(true);
			}
			else {
				self.searchParameters = {
					data: {
						propertyTypes: [],
						city: null,
						province: null,
						country: null,
						office: null,
						priceRange: 0,
						minPrice: 0,
						maxPrice: self.listingsMaxPrice || 1000000,
						broker: null,
						bedrooms: 0,
						bedsPlus: true,
						bathrooms: 0,
						bathsPlus: true,
						nameEmail: '',
						mls: '',
						what: 1
					}
				};
				self.results = [];
			}
			refreshSlider();
		})
		.catch((err) => {})

		// FUNCTIONS ============================================================================================================================

		/** Updates price range dropdown when sliders are used */
		self.updatePriceRange = function () {
			const minPrice = self.searchParameters.data.minPrice;
			const maxPrice = self.searchParameters.data.maxPrice;
			let priceRange = 0;

			if (maxPrice == minPrice + 400000 || minPrice == 800000) {
				priceRange = ((minPrice / 400000) + 1).toFixed(0);

			}
			else {
				priceRange = null;
			}
			self.searchParameters.data.priceRange = priceRange;
		}

		/** Updates price range sliders when price range dropdown is used */
		self.updateSliders = function () {
			if (self.searchParameters.data.priceRange != undefined) {
				switch (self.searchParameters.data.priceRange) {
				case '1':
					self.searchParameters.data.minPrice = 0;
					self.searchParameters.data.maxPrice = 399999;
					break;
				case '2':
					self.searchParameters.data.minPrice = 400000;
					self.searchParameters.data.maxPrice = 799999;
					break;
				case '3':
					self.searchParameters.data.minPrice = 800000;
					self.searchParameters.data.maxPrice = self.listingsMaxPrice;
					break;
				default: self.searchParameters.data.minPrice = 0;
					self.searchParameters.data.maxPrice = self.listingsMaxPrice;
				}
			}
			else {
				self.searchParameters.data.minPrice = 0;
				self.searchParameters.data.maxPrice = self.listingsMaxPrice;
			}
		}

		self.clearSearch = function () {
			DashboardService.resetStoredSearch();
			self.showResults = false;
			self.searchParameters = {
				data: {
					propertyType: null,
					city: null,
					province: null,
					country: null,
					priceRange: 0,
					minPrice: 0,
					maxPrice: self.listingsMaxPrice || 10000000,
					broker: null,
					bedrooms: 0,
					bedsPlus: true,
					bathrooms: 0,
					bathsPlus: true,
					nameEmail: '',
					mls: '',
					what: 1
				}
			};
			self.results = [];
			refreshSlider();
		}

		/** Makes a search based on the parameters */
		self.search = function (dontStore) {
			self.showResults = false;
			let advancedSearch;

			AlertService.loading();
			if ($scope.broker) {
				advancedSearch = DashboardService.search;
			} else {
				advancedSearch = DashboardService.searchAgency;
				self.searchParameters.data.what = 2;
			}
			advancedSearch(self.searchParameters.data)
			.then((res) => {
				self.results = res;
				self.results.sort();

				if (!dontStore) {
					DashboardService.storeSearch(self.searchParameters.data);
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				self.currentPage = 1;
				self.showResults = true;
				AlertService.doneLoading();
			})
		}

		self.filterProvinces = function(countryId) {
			self.searchParameters.data.province = '';
			self.searchParameters.data.city = '';

			self.cities = [{ id: 'xxx', name: '------' }];
			self.provinces = [{ id: 'xxx', name: '------' }];

			if (countryId) {
				const i = self.countries.findIndex((item) => {
					return item.id === countryId;
				})

				if (i >= 0) {
					self.provinces = self.provinces.concat(angular.copy(self.countries[i].provinces));
				}
			}
		}

		self.filterCities = function() {
			const provinceId = self.searchParameters.data.province;

			self.searchParameters.data.city = '';
			self.cities = [{ id: 'xxx', name: '------' }];

			if (provinceId && provinceId !== 'xxx') {
				const i = self.provinces.findIndex((item) => {
					return item.id === provinceId;
				})

				if (i >= 0) {
					self.provinces[i].regions.forEach((item) => {
						self.cities = self.cities.concat(item.cities);
					})
				}
			}
		}

		function refreshSlider() {
			$scope.$broadcast('rzSliderForceRender');
		}

		function getSearchFilters() {
			let searchFilters;
			if (!$scope.broker) {
				searchFilters = DashboardService.getAgencySearchFilters;
			}
			else {
				searchFilters = DashboardService.getSearchFilters;
			}
			return $q((resolve, reject) => {
				searchFilters()
				.then((res) => {
					self.brokers = [];
					self.brokers.push({ name: '------', value: 'xxx' });
					for (let i = 0; i < res.brokers.length; i++) {
						if (res.brokers[i].user) {
							self.brokers.push({ name: res.brokers[i].user.fullName, value: res.brokers[i].id });
						}
					}
					if (!$scope.broker) {
						self.offices = self.offices.concat(res.offices);
					}
					self.searchParameters.data.maxPrice = res.maxPrice;
					self.listingsMaxPrice = res.maxPrice;
					self.priceSlider.options.ceil = self.listingsMaxPrice;
					resolve();
				})
				.catch((err) => {
					reject();
				});
			});
		}

		const w = $scope.$watch('controller.searchParameters.data.country', (newValue) => {
			$scope.isState = newValue === 'USA';
		})

		$scope.$on('$destroy', w);
	}]) // End of controller

	.directive('advancedSearch', () => {
		return {
			restrict: 'E',
			controller: 'AdvSearchController',
			controllerAs: 'controller',
			scope: {
				broker: '='
			},
			link: function (scope, element, attrs) {
				scope.smartbrokrController = scope.$root.$$childHead.smartbrokrController;
			},
			templateUrl: './js/src/dashboard/dashboard-adv-search.html'
		}
	})
})()
