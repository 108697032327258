/* eslint-disable no-unused-vars */
(function () {

	'use strict';

	angular.module('smartbrokr.admin')
	.controller('AdminCodesController', function (AlertService, AdminService, GlobalVars, ModalService, $filter) {

		const self = this;

		self.columns    = [];
		self.codes      = [];
		self.languages  = [ 'EN', 'FR' ];

		self.sortInfo   = {                                         // Sort info for List view directive
			page:   null,
			sub:    [ 'labels' ]
		}

		self.filters = {
			id: { weight: 0, value: '', desc: 1, id: true },
			type: { weight: 0, value: '', desc: 1, top: true },
			realtor: { weight: 0, value: '', desc: 1, top: true },
			centris: { weight: 0, value: '', desc: 1, top: true },
		}

		self.inject = {
			name: 'codesController',
			inject: self
		}

		const config  = GlobalVars.createSingleConfig(false, 'id', 'label');
		const types   = [];

		getCodes();

		// FUNCTIONS ============================================================================================================================

		self.deleteCode = function(code) {
			AdminService.deleteItem('Code', code.id).then((res) => {
				if (res) {
					AlertService.saved();
				}
			})
			.finally(getCodes);
		}

		self.newCode = function() {
			const fields = [
				{
					label: 'ID',
					model: 'id',
					required: true,
				},
				{
					label: 'Type',
					model: 'type',
					required: true,
				}
			]

			const m = ModalService.openForm(fields, 'Create Code', true);

			m.then((res) => {
				if (res) {
					res.isNew = true;

					AdminService.saveCode(res).then((res) => {
						AlertService.saved();
					})
					.finally(getCodes)
				}
			})
			.catch((err) => {})
		}

		self.saveCode = function(code) {
			AlertService.loading();
			AdminService.saveCode(code).then((res) => {
				AlertService.saved();
			})
			.finally(AlertService.doneLoading)
		}

		function getColumns() {
			self.columns = [
				{
					name: 'ID',
					filter: 'id',
					size: 2,
					field: function(code) {
						return code.id;
					}
				},
				{
					name: 'Type',
					filter: 'type',
					select: true,
					options: types,
					config: config,
					size: 1,
					field: function(code) {
						return code.type;
					}
				},
				{
					name: 'Realtor',
					filter: 'realtor',
					isHtml: true,
					noSearch: true,
					class: 'check',
					field: function(code) {
						return '<input type="checkbox" class="regular-checkbox" ng-model="entry.realtor">';
					}
				},
				{
					name: 'Centris',
					filter: 'centris',
					isHtml: true,
					noSearch: true,
					class: 'check',
					field: function(code) {
						return '<input type="checkbox" class="regular-checkbox" ng-model="entry.centris">';
					}
				}
			]

			self.languages.forEach((lang) => {
				self.filters[lang] = { weight: 0, value: '', desc: 1 };
				self.filters[lang + '_alt'] = { weight: 0, value: '', desc: 1 };
				self.columns.push({
					name: lang,
					filter: lang,
					size: 2,
					isHtml: true,
					class: 'form-group codes',
					field: function(code) {
						return '<input type="text" class="form-control" ng-model="entry.labels.' + lang + '">';
					}
				})

				self.columns.push({
					name: lang + '_alt',
					filter: lang + '_alt',
					size: 2,
					isHtml: true,
					class: 'form-group codes',
					field: function(code) {
						return '<input type="text" class="form-control" ng-model="entry.labels.' + lang + '_alt">';
					}
				})
			})

			self.columns.push({
				name: '',
				noSort: true,
				noSearch: true,
				isHtml: true,
				class: 'codes-btns',
				field: function (code) {
					return '<button class="btn btn-icon btn-green" ng-click="codesController.saveCode(entry)"><i class="fas fa-save"></i></button>' +
                        '<button class="btn btn-icon btn-green" ng-click="codesController.deleteCode(entry)"><i class="fas fa-trash-alt"></i></button>';
				}
			})
		}

		function getCodes() {
			AlertService.loading();
			AdminService.getCodes().then((res) => {
				self.codes = res;

				const aux = $filter('unique')(self.codes, 'type');
				types.push({ label: '------', id: '' });
				aux.forEach((item) => {
					types.push({ id: item, label: item });
				})
			})
			.finally(() => {
				getColumns();
				AlertService.doneLoading();
			});
		}
	})

})();
