/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.buyer', [])

	.service('BuyerService', function (Agency, Buyer, Broker, SbUser, Listing, AccountService, BuyerBroker, ModalService) {
		const self = this;

		const supplierRel = {
			include: [
				{
					relation: 'owner',
					scope: {
						fields: [ 'id', 'firstName', 'lastName', 'email', 'emailEnabled', 'fullName', 'phones', 'languageIds', 'photoUrl', '_address' ],
						include: [
							'languages',
							{
								relation: 'buyerProfile',
								scope: {
									fields: [ 'id', 'sbUserId', 'preApproved', 'minPrice', 'maxPrice', 'cityIds', 'propertyTypeIds', '_coBuyer' ],
									include: [
										'propertyTypes',
										{
											relation: 'cities',
											scope: {
												fields: [ 'name' ]
											}
										}
									]
								}
							}
						]
					}
				},
				{
					relation: 'industry',
					scope: {
						fields: [
							'supplierId',
							'id',
							'type',
							'labels',
							'centris',
							'realtor'
						]
					}
				}
			]
		}

		/** Gets all Buyers linked the Broker currently using the system */
		self.getBuyers = function () {
			return Broker.getRelatedList({ id: AccountService.getBrokerId(), rel: 'buyers' }).$promise;
		}

		self.getBuyerId = function(id){
			return SbUser.findById({ id:id,filter: { include:'buyerProfile' } }).$promise;
		}

		self.getPerson = function (id) {
			const filter = {
				where: {
					brokerId: AccountService.getBrokerId()
				},
				include: {
					relation: 'buyer',
					scope: {
						fields: [ 'id', 'sbUserId', 'preApproved', 'minPrice', 'maxPrice', 'cityIds', 'propertyTypeIds', '_coBuyer', 'modified' ],
						include: [
							{
								relation: 'user',
								scope: {
									fields: [ 'id', 'firstName', 'lastName', 'email', 'emailEnabled', 'fullName', 'phones', 'languageIds', 'photoUrl', '_address' ],
									include: [
										'languages',
										{
											relation: 'sellerProfile',
											scope: {
												fields: [ 'id', 'listingId' ],
												include: {
													relation: 'listings',
													scope: {
														fields: [ 'id', 'status', 'askingPrice', 'rentalPrice', 'mainPhotoId' ],
														include: [
															{
																relation: 'property',
																scope: {
																	fields: [ '_address', 'listingId', 'id' ]
																}
															},
															{
																relation: 'files',
																scope: {
																	fields: ['listingId'],
																	where: {
																		bucket: 'listing'
																	},
																	order: 'seq ASC',
																	limit: 1
																}
															},
															{
																relation: 'mainPhoto',
																scope: {}
															}
														]
													}
												}
											}
										},
										{
											relation: 'logsAbout',
											scope: {
												include: 'user',
												order: 'created DESC',
												limit: 2
											}
										}
									]
								}
							},
							'propertyTypes',
							{
								relation: 'cities',
								scope: {
									fields: [ 'name' ]
								}
							},
							{
								relation: 'buyerCities',
								scope: {
									fields: ['buyerId', 'cityId'],
									include: {
										relation: 'city',
										scope: {
											fields: ['name'],
										}
									}
								}
							},
							{
								relation: 'logsAbout',
								scope: {
									include: 'user',
									order: 'created DESC',
									limit: 2
								}
							},
							{
								relation: 'suppliers',
								scope: supplierRel
							}
						]
					}
				}
			}

			return Buyer.buyerBrokers({ id:id, filter: filter }).$promise;
		}

		self.getUserAndBuyer = function(id){
			return Buyer.findById({ id: id ,filter:{ include:['user','cities'] } }).$promise;
		}


		/** Gets one Buyer */
		self.getBuyer = function (fk) {
			//May need to switch to Agency.buyers or Broker.buyers
			return Broker.buyers.findById({ id: AccountService.getBrokerId(), fk: fk }).$promise;
		}
		self.getThrough = function (id) {
			return Buyer.buyerBrokers({ id:id, filter: { where: { brokerId: AccountService.getBrokerId() }, fields: { id: true } } }).$promise;
		}
		self.getFolders = function (id) {
			return Buyer.buyerBrokers({ id:id, filter: { where: { brokerId: AccountService.getBrokerId() }, include: { folders: 'files' } } }).$promise;
		}
		self.createFolder = function (id, folder) {
			return BuyerBroker.folders.create({ id: id }, { name: folder }).$promise;
		}
		self.deleteFolder = function(id, fk){
			const del = function() {
				return BuyerBroker.folders.destroyById({ id: id, fk:fk }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FOLDER');
		}

		/** Create/Update Buyer */
		self.saveBuyer = function (buyer) {
			if (!buyer.id) {
				return Broker.buyers.create({ id: AccountService.getBrokerId() }, buyer).$promise;
			}
			else {
				return Broker.buyers.updateById({ id: AccountService.getBrokerId(), fk: buyer.id }, buyer).$promise;
			}
		}

		/** Gets a listing based on its id */
		self.getListing = function (fk) {
			return Broker.listings.findById({ id: AccountService.getBrokerId(), fk: fk }).$promise;
		}

		/** Gets listing including Property */
		self.getListingProperty = function (id) {
			return Listing.findById({ id: id, filter: { include: 'property' } }).$promise;
		}

		/** Searches for listings using a buyer's search parameters
       * @param buyer: Buyer
       */
		self.propertySearch = function (buyer, isAgency) {

			const filter = {
				include: [
					{
						relation: 'property',
						scope: {
							where: {
								and: []
							},
							include: 'type'
						}
					},
					{
						relation: 'files',
						scope: {
							fields: ['listingId'],
							where: { bucket: 'listing' },
							order: 'seq ASC',
							limit: 1

						}
					},
					{
						relation: 'mlsPhotos',
						scope: {
							include: 'file',
							order: 'seq ASC',
							limit: 1
						}
					}
				],
				where: {
					and: [
						{
							or: [
								{ status: 'Active' },
								{ status: 'Smart' }
							]
						}
					]
				}
			}

			if ((buyer.cityIds || []).length > 0) {
				const citiesOr = [];
				const citiesLength = buyer.cityIds.length;

				for (let i = 0; i < citiesLength; i++) {
					citiesOr.push({ '_address.city.id': buyer.cityIds[i] });
				}

				if (citiesLength == 1) {
					filter.include[0].scope.where.and.push(citiesOr[0]);
				}
				else if (citiesLength > 1) {
					filter.include[0].scope.where.and.push({ or: citiesOr });
				}
			}

			if ((buyer.propertyTypeIds || []).length > 0) {

				const typesLength = buyer.propertyTypeIds.length;

				const typesOr = [];

				for (let i = 0; i < typesLength; i++) {
					typesOr.push({ typeId: buyer.propertyTypeIds[i] });
				}

				if (typesOr.length == 1) {
					filter.include[0].scope.where.and.push(typesOr[0]);
				}
				else if (typesOr.length > 0) {
					filter.include[0].scope.where.and.push({ or: typesOr });
				}
			}

			if (buyer.maxPrice > 0) {
				filter.where.and.push({ askingPrice: { between: [buyer.minPrice, buyer.maxPrice] } });
			}
			else if (buyer.minPrice > 0) {
				filter.where.and.push({ askingPrice: { gt: buyer.minPrice } });
			}

			if (filter.include[0].scope.where.and.length == 0) {
				delete filter.include[0].scope.where;
			}

			if (!isAgency) return Broker.listings({ id: AccountService.getBrokerId(), filter: filter }).$promise;
			return Agency.getListings({ id: AccountService.getAgencyId(), filter: filter }).$promise;
		}

		/** Searches for buyers using a listing's characteristics
       * @param listing: Listing
       */
		self.buyerSearch = function (listing) {
			const filter = {
				include: [
					{ user: 'languages' },
					{
						relation: 'cities',
						scope: {
							fields: {
								name: true
							}
						}
					},
					'propertyTypes'
				],
				where: {
					and: [
						{
							or: [
								{ maxPrice: 0 },
								{
									maxPrice: {
										gt: listing.askingPrice
									}
								},
								{ maxPrice: listing.askingPrice }
							]
						},
						{
							or: [
								{
									minPrice: {
										lt: listing.askingPrice
									}
								},
								{ minPrice: listing.askingPrice }
							]
						},
						{
							propertyTypeIds: {
								like: '.*' + listing.property.typeId + '.*'
							}
						}
					]
				}
			}

			if (((((listing.property || {})._address || {}).city || {}).id || null) != null) {
				filter.where.and.push({ cityIds: { like: '.*' + listing.property._address.city.id + '.*' } });
			}

			return Broker.buyers({ id: AccountService.getBrokerId(), filter: filter }).$promise;
		}

		self.deleteBuyer = function (fk) {
			return Broker.buyers.destroyById({ id: AccountService.getBrokerId(), fk: fk }).$promise;
		}

		self.createBuyerListing = function (id, listing) {
			return Buyer.listings.create({ id: id }, listing).$promise;
		}

		self.getVisits = function(lId) {
			return Buyer.getVisits({ id: lId }).$promise;
		}

		self.getAppointments = function (lId) {
			return Buyer.getAppointments({ id: lId }).$promise;
		}

		self.getSentEmails = function(id) {
			return Buyer.sentEmails({ id: id }).$promise;
		}

		self.getSuppliers = function(id) {
			return Buyer.suppliers({ id: id, filter: supplierRel }).$promise;
		}

		self.linkSupplier = function(id, fk) {
			return Buyer.suppliers.link({ id: id, fk: fk }, null).$promise;
		}

		self.unlinkSupplier = function(id, fk) {
			return Buyer.suppliers.unlink({ id: id, fk: fk }).$promise;
		}
	});

})(); // End of function()
