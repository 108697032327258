/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/**
 *  globalVars
 */

(function () {

	'use strict';

	angular.module('smartbrokr.globalVars', [])

	.service('GlobalVars', function (City, Language, AccountService, StaticService, StorageService, $filter, $rootScope, $q, $timeout, $translate) {

		const self = this;
		let lang = StorageService.getLanguage();
		lang = (lang || 'en').toUpperCase();

		// Used to sort selection menu items by their name
		const sortSelections = function (array) {
			return array.sort((a, b) => {
				return (a.name || '').localeCompare(b.name || '') - (b.name || '').localeCompare(a.name || '');
			})
		}

		let schema = AccountService.getSchema();

		// Selectize configs --------------------------------------------------------------------------------------------------

		/**
       *  Creates a config object for dropdown lists (single select)
       *
       *  @param {boolean}  create        Whether config accepts creating new items
       *  @param {string}   valueField    Name of value field
       *  @param {string}   labelField    Name of label field
       *  @param {string}   searchField   Name of search field
       *  @param {boolean}  doNotSort     If true, options won't be sorted
       *  @param {string}   tag           Name of tag field   (optional)
       *  @param {string}   sortField     Name of sort field  (optional: if not defined, options are sorted by label)
       */
		self.createSingleConfig = function (create, valueField, labelField, searchField, doNotSort, tag, sortField, placeholder = '') {
			const config = {
				create: create,
				valueField: valueField,
				labelField: labelField,
				maxItems: 1,
				maxOptions: 2000,
				searchField: searchField,
				sortField: doNotSort ? null : !!sortField ? sortField : labelField,
				placeholder: placeholder,
				allowEmptyOption: true,
				persist: true,
				onChange: function (value) {
					if ((value || '').length == 0 || value === 'xxx') {       // Empty option ({ name: '-----', value: 'xxx'}) -> go back to ng-pristine
						this.clear();
					}
					this.clearCache();  // Previously selected items are deselected
				}
			}

			if (!!tag) {
				config.render = {
					option: function(data,escape) {
						let ret = '<div class="option" data-selectable="" data-value="' + data[valueField] + '"><span>' + data[labelField] + '</span>';
						let tagged = data[tag];
						if (tagged !== null && tagged !== undefined) {
							tagged = tagged + '';
							tagged.split('|').forEach((curr) => {
								ret += '<div class="tag">' + $filter('translate')(curr) + '</div>';
							})
						}
						ret += '</div>';
						return ret;
					}
				}
			}

			return config;
		}

		self.createLocationConfig = function(valueField, labelField, isFrench, labelFrench) {
			const config = self.createSingleConfig(false, valueField, labelField, labelField);

			if (isFrench) {
				config.render = {
					option: function(data, escape) {
						return '<div class="option" data-selectable="" data-value="' + data[valueField] + '">' +
                (data[labelFrench] ? data[labelFrench] : data[labelField]) + '</div>';
					},
					item: function(data, escape) {
						return '<div class="item" data-value="' + data[valueField] + '">' +
                (data[labelFrench] ? data[labelFrench] : data[labelField]) + '</div>';
					}
				}
			}

			return config;
		}

		self.createLocationMultiConfig = function(valueField, labelField, isFrench, labelFrench) {
			const config = self.createMultiConfig(false, valueField, labelField, true, null, labelField);

			if (isFrench) {
				config.render = {
					option: function(data, escape) {
						return '<div class="option" data-selectable="" data-value="' + data[valueField] + '">' +
                (data[labelFrench] ? data[labelFrench] : data[labelField]) + '</div>';
					},
					item: function(data, escape) {
						return '<div class="item" data-value="' + data[valueField] + '">' +
                (data[labelFrench] ? data[labelFrench] : data[labelField]) + '</div>';
					}
				}
			}

			return config;
		}

		/**
       *  Creates a config object for text editors (TinyMCE)
       *  TinyMCE docs: https://www.tinymce.com/docs/
       *
       *  @param {boolean}  inline    If true, shows editor inline instead of a separate input
       *  @param {boolean}  menubar   Whether to show the menu bar
       *  @param {string}   plugins   Editor plugins (https://www.tinymce.com/docs/plugins/)
       *  @param {array}    toolbar   What will be shown in the toolbar (array of strings)
       *  @param {object}   baseObj   Base object with any other relevant configuration
       */
		self.createEditorConfig = function (inline, menubar, plugins, toolbar, baseObj) {
			baseObj = baseObj || {};

			baseObj.inline = inline;
			baseObj.menubar = menubar;
			baseObj.plugins = plugins;
			baseObj.toolbar = toolbar;
			baseObj.skin = baseObj.skin || 'lightgray';
			baseObj.theme = baseObj.theme || 'modern';
			baseObj.max_width = baseObj.max_width || 338;
			baseObj.baseURL = '/assets/tinymce';
			baseObj.branding = false;

			return baseObj;
		}

		// Left those here because several pages use these references
		self.singleConfig = self.createSingleConfig(false, 'value', 'name', 'name');
		self.locationConfig = self.createSingleConfig(false, 'id', 'name', 'name');
		self.locationConfig2 = self.createSingleConfig(false, 'name', 'name', 'name');
		self.singleCanCreate = self.createSingleConfig(true, 'value', 'name', 'name');
		self.brokerConfig = self.createSingleConfig(false, 'id', 'id', 'id');

		self.createMultiConfig = function (create, value, label, remove, tag, search) {
			const config = {
				create: create,
				valueField: value,
				labelField: label,
				searchField: search || label,
				sortField: label,
				plugins: (remove ? ['remove_button'] : [])
			}

			if (!!tag) {
				config.render = {
					option: function(data,escape) {
						let ret = '<div class="option" data-selectable="" data-value="' + data[value] + '">' + data[label];
						let tagged = data[tag];

						if (tagged !== null && tagged !== undefined) {
							tagged = tagged + '';
							tagged.split('|').forEach((curr) => {
								ret += '<div class="tag">' + $filter('translate')(curr) + '</div>';
							})
						}

						ret += '</div>';
						return ret;
					}
				}
			}

			return config;
		}

		// Multiple selectable items. Value: 'value', Name: 'name'. Includes remove button.

		self.multiConfig = self.createMultiConfig(false, 'value', 'name', true);

		/** Gets static codes and their labels to use as selectize options */
		const codes = [
			'propertyTypes',
			'buildingTypes',
			'featureTypes',
			'featureValues',
			'suppIndustryTypes',
			'roomTypes',
			'floorTypes',
			'floorCover',
			'measureUnits',
			'costTypes',
			'propertyStyles',
			'licenseTypes',
			'agencyMemberRoles'
		]
		for (const i in codes) {
			self[codes[i]] = [];
		}

		const getCodes = function (j, language) {
			const res = StaticService.getCodes(codes[j], schema);
			if (res) {
				self[codes[j]].push({ name: '------', value: 'xxx' });
				for (let i = 0; i < res.length; i++) {
					self[codes[j]].push({
						name: (
							(
								(
									res[i] || {}
								).labels || {}
							)
							[language || lang] ||
							(
								(
									res[i] || {}
								).labels || {}
							)['EN'] ||
							res[i].id
						),
						value: res[i].id, labels: (res[i] || {}).labels || {}
					});
				}

				if (codes[j] === 'agencyMemberRoles') {
					self[codes[j]].push({
						name: '' + $filter('translate')('COUNTRIES.OTHER'),
						value: 'OTHER'
					})
				}
				sortSelections(self[codes[j]]);
			}
			else {
				$timeout(() => {
					getCodes(j);
				}, 5000)
			}
		}

		self.initCodes = function() {
			lang = StorageService.getLanguage();
			lang = (lang || 'en').toUpperCase();
			schema = AccountService.getSchema();

			codes.forEach((code, i) => {
				self[codes[i]] = [];
				getCodes(i, lang);
			});

			getLanguages();

			self.frequencies = [];

			const freqs = [
				'DATES.F.D',
				'DATES.F.W',
				'DATES.F.M',
				'DATES.F.Q',
				'DATES.F.Y'
			]

			$translate(freqs).then((res) => {
				for (const i in res) {
					const val = i.substr(-1);
					self.frequencies.push({ value: val, name: res[i], order: freqs.indexOf(i) });
				}
			})
		}

		// Only attemps to grab codes after user is logged in
		const watcher = $rootScope.$watch('loggedIn', (isLoggedIn) => {
			if (isLoggedIn) {
				codes.forEach((code, i) => {
					getCodes(i);
				});
				watcher();
			}
		})

		self.getCodes = function(type, includeDefault = true) {
			const typesCopy = angular.copy(self[type]);
			if(!includeDefault) {
				const splicedElement = typesCopy.splice(0, 1);
				// Check for duplicates of the spliced default item
				const dupIndex = typesCopy.findIndex((value) => {
					return value.id === splicedElement.value;
				})
				if(dupIndex > -1) {
					typesCopy.splice(dupIndex, 1);
				}
			}

			return angular.copy(typesCopy);
		}

		// Hour options for appointments. Starts at 6am and ends at 5:45 am
		self.hours = [];
		let i = 1;
		for (let h = 6; h < 24; h++) {
			for (let min = 0; min < 60; min += 15) {
				const toAdd = moment(h + ':' + min, 'H:m').toDate();
				self.hours.push({ value: toAdd, name: $filter('date')(toAdd, 'shortTime'), order: i });
				i++;
			}
		}

		for (let h = 0; h < 6; h++) {
			for (let min = 0; min < 60; min += 15) {
				const toAdd = moment(h + ':' + min, 'H:m').toDate();
				self.hours.push({ value: toAdd, name: $filter('date')(toAdd, 'shortTime'), order: i });
				i++;
			}
		}

		self.createCalendarConfig = function(minDate, maxDate) {

			if (!minDate && minDate !== null) {
				minDate = new Date();
			}

			if (!maxDate && maxDate !== null) {
				maxDate = new Date(2050, 5, 22);
			}

			return {
				opened: false,
				focusMe: function(event) {
					this.opened = !this.opened;

					if (!this.opened) {
						$timeout(() => {
							event.target.blur();
						}, 100);
					}
				},
				openMe: function() {
					this.opened = !this.opened;
				},
				options: {
					formatYear: 'yy',
					maxDate: maxDate || null,
					minDate: minDate || null,
					startingDay: 1,
					showWeeks: false
				}
			};
		}

		// Templates
		self.templates = {

			// Modals
			addBookmark: { template: 'templates/modal/addBookmark.html' },
			addBroker: { template: 'templates/modal/addBroker.html' },
			addNotary: { template: 'templates/modal/addNotary.html' },
			addPersonToListing: { template: 'templates/modal/addPersonToListing.html' },
			addSeller: { template: 'templates/modal/addSeller.html' },
			adminForm: { template: 'admin/templates/modal.form.html' },
			adminPayment: { template: 'admin/templates/modal.payment.html' },
			adminSearch: { template: 'admin/menu/admin.search.modal.html' },
			agencySendFiles: { template: 'templates/modal/agencySendFiles.html' },
			assignOffices: { template: 'templates/modal/assignOffices.html' },
			confirm: { template: 'templates/modal/confirm.html' },
			convertListing: { template: 'templates/modal/convertListing.html' },
			createMarketingPdf: { template: 'listings/modal/create-pdf.html' },
			createCard:{ template: 'templates/modal/createCard.html' },
			createCost: { template: 'templates/modal/createCost.html' },
			createFeature: { template: 'templates/modal/createFeature.html' },
			createFolder: { template: 'templates/modal/createFolder.html' },
			createOrSelectFolder: { template: 'templates/modal/createOrSelectFolder.html' },
			createManager: { template: 'templates/modal/createManager.html' },
			createRoom: { template: 'templates/modal/createRoom.html' },
			createTeam: { template: 'templates/modal/createTeam.html' },
			createUser: { template: 'templates/modal/createUser.html' },
			createVideo: { template: 'modal/create-video/create-video.html' },
			createVisit: { template: 'templates/modal/createVisit.html' },
			deleteUser: { template: 'templates/modal/deleteUser.html' },
			duplicateListing: { template: 'templates/modal/duplicateListing.html' },
			duplicateUser: { template: 'templates/modal/duplicateUser.html' },
			editNote: { template: 'templates/modal/addNote.html' },
			editPhoto: { template: 'templates/modal/editPhoto.html' },
			emailLogs: { template: 'templates/modal/notes/emailLogs.html' },
			emailTemplate: { template: 'templates/modal/emailTemplate.html' },
			existingUser: { template: 'templates/modal/existingUser.html' },
			finishedCreation: { template: 'listings/modal/create-pdf-finished.html' },
			generalForm: { template: 'templates/modal/generalForm.html' },
			importTaskTemplate: { template: 'tasks/modal/import-task-template.html' },
			linkListing: { template: 'templates/modal/linkListing.html' },
			listingSummary: { template: 'templates/modal/listing/listingSummary.html' },
			manageSharedListingStatus: { template: 'templates/modal/manageSharedListingStatus.html' },
			menuSearch: { template: 'main/menu-search.html' },
			newMessage: { template: 'templates/modal/newMessage.html' },
			newNote: { template: 'templates/modal/addNote.html' },
			newTask: { template: 'tasks/modal/new-task.html' },
			nothingToExport: { template: 'templates/modal/nothingToExport.html' },
			personalEvent: { template: 'templates/modal/personalEvent.html' },
			resetPassword: { template: 'templates/modal/resetPassword.html' },
			sellListing: { template: 'templates/modal/sellListing.html' },
			sellerSecret: { template: 'templates/modal/listing/listing-seller-secret.html' },
			sendEmail: { template: 'templates/modal/sendEmail.html' },
			shareListing: { template: 'templates/modal/shareListing.html' },
			shareTemplate: { template: 'templates/modal/shareTemplate.html' },
			taskTemplate: { template: 'resources/task-templates/modal/task-template-new.html' },
			taskTemplateAddTask: { template: 'resources/task-templates/modal/task-template-add.html' },
			updateSubscription: { template: 'templates/modal/updateSubscription.html' },
			viewImage: { template: 'templates/modal/view-image-modal.html' },
			viewMedia: { template: 'templates/modal/viewMedia.html' },
			visitNotes: { template: 'templates/modal/visitNotes.html' },
			wpContentEdit: { template: '/js/src/wordpress/content-modal/wp-content-modal.html' },
			wpImageUpload: { template: 'modal/image-upload/image-upload.html' },

			// Popovers
			agencyAdd: { template: 'templates/popover/agency-add.html' },
			bookmarks: { template: 'templates/popover/bookmarksPop.html' },
			brokerStatusLegends: { template: 'templates/popover/brokerStatusLegends.html' },
			buyerMenu: { template: 'templates/popover/buyerMenu.html' },
			calendarCreateEvent: { template: 'calendar/popover/create-event.html' },
			calendarDay: { template: 'calendar/popover/calendar-day.html' },
			calendarEvent: { template: 'calendar/popover/calendar-event.html' },
			calendarPersonal: { template: 'calendar/popover/calendar-personal.html' },
			codeInputs: { template: 'templates/popover/codeInputs.html' },
			costMenu: { template: 'templates/popover/costMenu.html' },
			exportCsv: { template: '/js/src/templates/popover/export-csv.html' },
			featureMenu: { template: 'templates/popover/featureMenu.html' },
			filterSelect: { template: 'templates/popover/selectFilter.html' },
			listingLegend: { template: 'templates/popover/listingLegend.html' },
			listingMenu: { template: 'templates/popover/listingMenu.html' },
			listingStatusLegends: { template: 'templates/popover/listingStatusLegends.html' },
			logMenu: { template: 'templates/popover/logMenu.html' },
			logHeaderMenu: { template: 'templates/popover/logHeaderMenu.html' },
			managerMenu: { template: 'templates/popover/managerMenu.html' },
			menuList: { template: 'templates/popover/menuList.html' },
			noteMenu: { template: 'templates/popover/noteMenu.html' },
			photosMenu: { template: 'templates/popover/photosMenu.html' },
			propertyMenu: { template: 'templates/popover/propertyMenu.html' },
			roleMenu: { template: 'templates/popover/roleMenu.html' },
			roomMenu: { template: 'templates/popover/roomMenu.html' },
			sellerMenu: { template: 'templates/popover/sellerMenu.html' },
			supplierMenu: { template: 'templates/popover/supplierMenu.html' },
			taskLegends: { template: 'templates/popover/taskLegends.html' },
			teamMenu: { template: 'templates/popover/teamMenu.html' },
			tooltip: { template: 'templates/popover/tooltip.html' },
			visitMenu: { template: 'templates/popover/visitMenu.html' },
			reportLink: { template: 'templates/popover/reportLink.html' },

			// Button legends
			btnDelete: { template: 'templates/popover/button-legend/delete.html' },
			btnDeleteSel: { template: 'templates/popover/button-legend/delete-selected.html' },
			btnDoNotEmail: { template: 'templates/popover/button-legend/do-not-email.html' },
			btnDownload: { template: 'templates/popover/button-legend/download.html' },
			btnDownloadSel: { template: 'templates/popover/button-legend/download-selected.html' },
			btnEdit: { template: 'templates/popover/button-legend/edit.html' },
			btnEmail: { template: 'templates/popover/button-legend/email.html' },
			btnEmailNoContent: { template: 'templates/popover/button-legend/no-email-content.html' },
			btnEmailSel: { template: 'templates/popover/button-legend/email-selected.html' },
			btnExport: { template: 'templates/popover/button-legend/export.html' },
			btnMenuLanguage: { template: 'templates/popover/help/menu-language.html' },
			btnNewAppt: { template: 'templates/popover/button-legend/new-appointment.html' },
			btnNewVisit: { template: 'templates/popover/button-legend/new-visit.html' },
			btnPreApproved: { template: 'templates/popover/button-legend/pre-approved.html' },
			btnRenameSel: { template: 'templates/popover/button-legend/rename-selected.html' },
			btnSeekRental: { template: 'templates/popover/button-legend/seek-rental.html' },
			btnSendEmail: { template: 'templates/popover/button-legend/send-email.html' },
			btnSentEmails: { template: 'templates/popover/button-legend/sent-emails.html' },
			btnShareTemplate: { template: 'templates/popover/button-legend/share-with-agency.html' },
			btnToggleStatus: { template: 'templates/popover/button-legend/toggle-status.html' },
			btnTransferSel: { template: 'templates/popover/button-legend/transfer-selected.html' },
			btnUploadPhoto: { template: 'templates/popover/button-legend/upload-photo.html' },
			btnVideoSpinner: { template: 'templates/popover/button-legend/video-generating.html' },
			btnView: { template: 'templates/popover/button-legend/view.html' },
			btnWarnNewSeller: { template: 'templates/popover/button-legend/new-seller-warning.html' },

			// Help bubbles
			helpPayMinQuantity: { template: 'templates/popover/help/payment-min-broker.html' },

			// Partials
			buyer: { template: 'templates/partials/buyer.html' },
			buyerBottom: { template: 'templates/partials/buyerBottom.html' },
			listing: { template: 'templates/listing.html' },
			notesBar: { template: 'templates/partials/notesBar.html' },
			notesBarMobile: { template: 'templates/partials/notesBarMobile.html' },
			person: { template: 'templates/partials/person.html' },
			seller: { template: 'templates/partials/seller.html' },
			sellerBottom: { template: 'templates/partials/sellerBottom.html' },
			supplier: { template: 'templates/partials/supplier.html' },
			task: { template: 'templates/partials/task.html' }
		};

		self.popover = function (id) {
			return (self.templates[id] || false).template || false;
		};

		self.getCity = function (code) {
			if (code != null && code != undefined) {
				const ret = { name: '', province: '' };
				City.findById({ id: code, filter: { include: 'region' } }, (city, err) => {
					ret.name = city.name;
					ret.province = city.region.provinceId;
				});
				return ret;
			}
			else {
				return {};
			}
		}

		self.queryCodes = function(type, schema) {
			return StaticService.queryCodes(type, schema || AccountService.getSchema());
		}

		/**
       *  Runs promises and returns the results.
       *  @param  toRun     function      Function that will be called every for every set of arguments
       *  @param  args      array         Sets of arguments to pass to the function
       */
		self.runPromises = function(toRun, args) {

			const newPromise = function (call) {
				return $q((resolve, reject) => {
					call.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					})
				});
			}

			const promises = [];

			args.forEach((arg) => {
				promises.push(newPromise(toRun(arg)));
			})

			return $q.all(promises);
		}

		function getLanguages() {
			self.languageOptions = [];

			Language.find({}, (res) => {
				if ((res || []).length > 0) {
					res.reduce((arr, item) => {
						if (item.id) {
							const toAdd = {
								value: item.id,
								name: $filter('translate')('LANGUAGES.' + item.id),
								labels: {}
							}
							_translate(toAdd.labels, 'en', 'LANGUAGES.' + item.id);
							_translate(toAdd.labels, 'fr', 'LANGUAGES.' + item.id);
							arr.push(toAdd);
						}
						return arr;
					}, self.languageOptions);
				}
			});

			function _translate(parent, lang, term) {
				$translate(term, null, null, null, lang).then((translated) => {
					parent[lang.toUpperCase()] = '' + translated;
				})
			}
		}

	}); // End of service
})(); // End of function()
