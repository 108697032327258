(function () {

  "use strict";

  angular.module("smartbrokr")

  .controller("RoomController", function ($scope, $uibModalInstance, GlobalVars, ListingService, id, room, updating, maxOrder) {

    var self = this;
    
    self.id           = id;
    self.room         = room || {};
    self.updating     = updating || false;
    self.maxOrder     = maxOrder;
    self.singleConfig = GlobalVars.singleConfig;
    self.roomTypes    = GlobalVars.roomTypes;
    self.floorTypes   = GlobalVars.floorTypes;
    self.floorCover   = GlobalVars.floorCover;

    if (!self.room.order && self.room.order !== 0) {
      self.room.order = self.maxOrder + 1;
    }
    
    $uibModalInstance.scope = $scope;
    $uibModalInstance.check = {
      original: angular.copy(self.room),
      clone: self.room
    }

    self.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    self.deleteRoom = function () {
      if (self.updating) {
        ListingService.deleteRoom(self.id,self.room.id)
        .then(function(res) {
          if (!!res) {
            $uibModalInstance.close(res);
          }
        })
        .catch(function(err){})
      }
    }

    self.addOrder = function() {
      if (self.room.order <= self.maxOrder) {
        self.room.order += 1;
      }
    }

    self.minusOrder = function() {
      if (self.room.order > 1) {
        self.room.order -= 1;
      }
    }

    self.saveRoom = function () {
      var room = {
        order: self.room.order || maxOrder+1,
        typeId: self.room.typeId || null,
        dimensions: self.room.dimensions || null,
        irregular: self.room.irregular || false,
        floorId: self.room.floorId || null,
        floorCoverId: self.room.floorCoverId || null,
        extraInfo: self.room.extraInfo || null,
      }
      if (!self.updating) {
        ListingService.createRoom(self.id, room)
          .then(function (res) {
            $uibModalInstance.close(res);
          })
          .catch(function (err) {});
      }
      else if (self.updating) {
        ListingService.updateRoom(self.id, self.room.id, room)
          .then(function (res) {
            $uibModalInstance.close(res);
          })
          .catch(function (err) {});
      }
      else {
        self.close();
      }
    }

  }) // End of controller
})(); // End of function()
