(function () {

    "use strict";

    angular.module("smartbrokr")
    .controller("SellerLoginController", function (AccountService, NavService, OwnerService, $log) {
        var self = this;

        //self.token = 'e687092a6a811b12ad01fe47748140100748f7065c0dc38d';
        self.error = false;

        AccountService.clearUser();

        self.login = function(token) {
            OwnerService.login(token).then(function(listingId) {
                NavService.navigate('main.owner.listing', { id: listingId });
            })
            .catch(function(error) {
                self.error = true;
                $log.error(error);
            })
        }

    });
})();
