/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.dashboard', [])

	.service('DashboardService', function (AccountService, Listing, Broker, Agency, SbUser, Log, $localStorage, StaticService) {

		const self = this;
		self.filters = {
			recentlyUpdated: {
				name: 'Recently Updated',
				order: 'modified DESC'
			},
			newest: {
				name: 'Newest',
				order: 'created DESC'
			},
			oldest: {
				name: 'Oldest',
				order: 'created ASC'
			}
		};

		self.getFilterOrder = function(sortBy) {
			return self.filters[sortBy].order;
		}


		/** Gets buyers for dashboard */
		self.getBuyers = function (order, skip, limit) {
			const filter = {
				fields: [ 'id', 'sbUserId', 'minPrice', 'maxPrice', 'preApproved', 'cityIds' ],
				include: [
					{
						relation: 'user',
						scope: {
							fields: [ 'id', 'fullName', 'email', 'emailEnabled', 'photoUrl', 'phones', 'languageIds' ]
						}
					},
					{
						relation: 'buyerCities',
						scope: {
							fields: ['buyerId', 'cityId'],
							include: {
								relation: 'city',
								scope: {
									fields: ['name'],
								}
							}
						}
					},
					'propertyTypes'
				],
				order: self.filters[order].order,
				skip: skip || 0,
				limit: limit || 8
			}
			return Broker.buyers({ id: AccountService.getBrokerId(), filter: filter }).$promise;
		}

		/** Gets sellers for dashboard */
		self.getSellers = function (order, skip, limit) {
			const filter = {
				fields: [ 'id', 'sbUserId' ],
				include: [
					{
						relation: 'user',
						scope: {
							fields: [ 'id', 'fullName', 'email', 'phones', 'emailEnabled', 'languageIds', 'photoUrl' ]
						}
					},
					{
						relation: 'listings',
						scope: {
							fields: [ 'id', 'status', 'askingPrice', 'rentalPrice' ],
							include: {
								relation: 'property',
								scope: {
									fields: [ 'id', 'listingId', '_address' ]
								}
							}
						}
					}
				],
				order: self.filters[order].order,
				skip: skip || 0,
				limit: limit || 8
			}

			return Broker.sellers({ id: AccountService.getBrokerId(), filter: filter }).$promise;
		}

		/** Gets broker suppliers for dashboard */
		self.getSuppliers = function (order, skip, limit) {
			const filter = {
				fields: [
					'id',
					'company',
					'industryId',
					'photoUrl',
					'ownerId'
				],
				include: [
					{
						relation: 'owner',
						scope: {
							fields: [
								'id',
								'fullName',
								'phones',
								'email',
								'languageIds',
								'photoUrl',
								'emailEnabled'
							]
						}
					},
					{
						relation: 'industry',
						scope: {
							fields: [
								'supplierId',
								'id',
								'type',
								'labels',
								'centris',
								'realtor'
							]
						}
					}
				],
				order: self.filters[order].order,
				skip: skip || 0,
				limit: limit || 8
			}

			return Broker.suppliers({ id: AccountService.getBrokerId(), filter: filter }).$promise;
		}

		/** Gets agency suppliers for dashboard */
		self.getAgencySuppliers = function (order, skip, limit) {
			const filter = {
				fields: [
					'id',
					'company',
					'industryId',
					'photoUrl',
					'ownerId'
				],
				include: [
					{
						relation: 'owner',
						scope: {
							fields: [
								'id',
								'fullName',
								'phones',
								'email',
								'languageIds',
								'photoUrl',
								'emailEnabled'
							]
						}
					},
					{
						relation: 'industry',
						scope: {
							fields: [
								'supplierId',
								'id',
								'type',
								'labels',
								'centris',
								'realtor'
							]
						}
					}
				],
				order: self.filters[order].order,
				skip: skip || 0,
				limit: limit || 8
			}

			return Broker.suppliers({ id: AccountService.getBrokerId(), filter: filter }).$promise;
		}

		/** Gets notes for dashboard */
		self.getNotes = function (limit, about, role) {
			const filter = {
				include: ['createdBy'],
				order: 'created DESC',
				limit: limit || 0
			}

			if (!!about && !!role) {
				let model = null;
				switch(role) {
				case 'buyer':
				case 'seller':
				case 'supplier':  model = SbUser; break;
				case 'listing':   model = Listing; break;
				}
				if (model != null) {
					return model.notesAbout({ id: about, filter: filter }).$promise;
				}
			}
			return SbUser.notes({ id: AccountService.getUserId(), filter: filter }).$promise;
		}

		/** Gets logs for dashboard */
		self.getActionLog = function (limit, about, aboutOwner) {
			const filter = {
				include: [
					{
						relation: 'user',
						scope: {
							fields: [ 'id', 'fullName' ]
						}
					}
				],
				order: 'created DESC',
				limit: limit
			}

			if (!!about && !aboutOwner) {
				filter.where = { aboutId: about };
			}
			else if (!!about && !!aboutOwner) {
				filter.where = { or: [{ aboutId: about }, { aboutId: aboutOwner }] }
			}

			return SbUser.logs({ id: AccountService.getUserId(), filter: filter }).$promise;
		}

		/** Gets listings for dashboard -> Only active listings */
		self.getListings = function (order, all, skip, limit) {
			const filter = {
				where: {
					status: 'Active'
				},
				fields: [ 'id', 'status', 'askingPrice', 'rentalPrice', 'mainPhotoId' ],
				include: [
					{
						relation: 'property',
						scope: {
							fields: [ 'id', 'listingId', '_address' ]
						}
					},
					{
						relation: 'mlsPhotos',
						scope: {
							fields: ['id', 'listingId', 'fileId'],
							include: {
								relation: 'file',
								scope: {
									fields: [ 'id', 'listingId', 'url', 'type', 'name' ]
								}
							},
							order: 'seq ASC',
							limit: 1
						}
					},
					{
						relation: 'files',
						scope: {
							fields: ['listingId'],
							where: { bucket: 'listing' },
							order: 'seq ASC',
							limit: 1
						}
					},
					{
						relation: 'mainPhoto',
						scope: {
							fields: [ 'id', 'listingId', 'url', 'type', 'name' ]
						}
					},
					{
						relation: 'brokers',
						scope: {
							fields: [ 'id', 'sbUserId', 'agencyId' ],
							include: {
								relation: 'user',
								scope: {
									fields: [ 'id', 'fullName' ]
								}
							}
						}
					}
				],
				order: self.filters[order].order,
				skip: skip || 0,
				limit: limit || 8
			}

			if (all) {
				delete filter.where;
			}
			return Broker.listings({ id: AccountService.getBrokerId(), filter: filter }).$promise;
		}

		self.getAgencyListings = function () {
			return Agency.getListings()
		}
		/**
		 *  Stores parameters and results from a search made by the user
		 *  so it can be retrieved later. Data is stored in window's local storage.
		 *  @param parameters: Object (parameters used for search)
		 */
		self.storeSearch = function (parameters) {
			StaticService.storeAdvancedSearch(parameters);
		}
		self.getSearchFilters = function () {
			return Broker.getSearchFilters({ id: AccountService.getBrokerId() }).$promise;
		}

		self.getAgencySearchFilters = function () {
			return Agency.getSearchFilters({ id: AccountService.getAgencyId() }).$promise;
		}

		/**
		 *  Gets data from localStorage regarding a previous search.
		 *  @return Object (object that stored paramters/results. if there's nothing, returns null)
		 */
		self.getStoredSearch = function () {
			return StaticService.getAdvancedSearch();
		}

		/**
		 *  Deletes stored advanced search.
		 */
		self.resetStoredSearch = function() {
			StaticService.resetAdvancedSearch();
		}

		self.search = function (filter) {
			return Broker.search({ id: AccountService.getBrokerId(), filter: filter }).$promise;
		}

		self.searchAgency = function (filter) {
			return Agency.getAdvancedSearch({ id: AccountService.getAgencyId(), filter: filter }).$promise;
		}

	});
})(); // End of function()
