/* eslint-disable no-undef */
/**
 *
 */

(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('EnableController', function(AccountService, $stateParams, $state) {
		const self = this;

		self.token = $stateParams.token;

		self.showError = false;
		self.showSuccess = false;

		self.goToLogin = function() {
			$state.go('main.login');
		}

		self.unlock = function() {
			AccountService.unlock($stateParams.token).then((res) => {
				self.showSuccess = true;
			}, (error) => {
				self.showError = true;
			});
		}
		self.unlock();
	});
})();
