(function () {

      "use strict";

      angular.module("smartbrokr")

        .controller("SubscriptionController", function ($uibModalInstance, AlertService, PaymentService, GlobalVars, subscription, translateFilter) {

          var self = this;

          // VARIABLES ============================================================================================================================

          self.isBroker       = null;   // Whether the current user is an independent broker
          self.isAgency       = null;   // Whether the current user belongs to an agency plan
          self.agencyPlan     = {};     // Current agency plan
          self.brokerPlan     = null;   // Current broker plan
          self.planConfig     = null;   // Selectize config
          self.popover        = null;   // Popover template for help button (Min Quantity)
          self.brokerPlans    = [];
          self.agencyPlans    = [];

          self.error          = null;   // Error message - Only used when plans don't have the same frequency
          self.warning        = null;   // Warning message - Used when plan is annual and user decreases number of licenses
          self.warningBoth    = false;

          var subId;                    // Current subscription id
          var oldAgency;
          var oldBroker;
          var originalQuantity;
          var originalFrequency;
          var currFrequency;

          // SETUP VARIABLES ======================================================================================================================
          self.planConfig = GlobalVars.createSingleConfig(false,'id','name','name');
          self.planConfig.allowEmptyOption = false;

          self.popover    = GlobalVars.popover('helpPayMinQuantity');

          self.brokerPlan = {
            quantity: 1,
            minQuantity: 1,
            addQuantity: function() {
              this.quantity += 1;

              if (this.quantity === originalQuantity && currFrequency === originalFrequency) {
                self.warning = null;
              }
            },
            subQuantity: function() {
              if (this.quantity > this.minQuantity) {
                this.quantity -= 1;
              }

              if (this.quantity < originalQuantity && originalFrequency === currFrequency) {
                self.warning = 'ACCOUNT.OUTCOME.WARNING_LICENSES';
                self.warningBoth = false;
              }
            }
          };

          if (subscription) {
            self.agencyPlan.id = ((subscription.agency || {}).plan || {}).id || null;
            self.brokerPlan.id = ((subscription.broker || {}).plan || {}).id || null;
            self.brokerPlan.quantity = (subscription.broker || {}).quantity || 0;
            self.brokerPlan.minQuantity = (subscription.agency || {}).minBrokers || 1;
            originalQuantity = self.brokerPlan.quantity;
            currFrequency = ((subscription.agency || {}).plan || {}).interval === 'year' ? 'a' : 'm';
            originalFrequency = currFrequency;

            self.isAgency = !!subscription.agency.id;
            self.isBroker = !self.isAgency;

            subId = (subscription.sub || {}).id || null;
            oldAgency = angular.copy(self.agencyPlan);
            oldBroker = angular.copy(self.brokerPlan);
          }
          
          if(self.isBroker) {
            PaymentService.getBrokerPlans().then(function(res){
              self.brokerPlans = res;

              self.brokerPlans.forEach(function(plan) {
                plan.name = translateFilter('ACCOUNT.PLAN_NAMES.' + plan.id);
              })
            })
            .catch(function(err){})
          }
          else {
            PaymentService.getAgencyPlans().then(function(res){
              self.agencyPlans = res;

              self.agencyPlans.forEach(function(plan) {
                plan.name = translateFilter('ACCOUNT.PLAN_NAMES.' + plan.id);
              })
            })
            .catch(function(err){})

            PaymentService.getAgencyBrokerPlans().then(function(res){
              self.brokerPlans = res;

              self.brokerPlans.forEach(function(plan) {
                plan.name = translateFilter('ACCOUNT.PLAN_NAMES.' + plan.id);
              })
            })
            .catch(function(err){})
          }

          

          // FUNCTIONS ============================================================================================================================

          self.close = function () {
            $uibModalInstance.dismiss('cancel');
          };

          self.checkPlans = function() {
            var selectedPlanA = self.agencyPlans.find(function(item){
              return item.id == self.agencyPlan.id;
            })

            var selectedPlanB = self.brokerPlans.find(function(item){
              return item.id == self.brokerPlan.id;
            })

            if ((selectedPlanA || {}).frequency == (selectedPlanB || {}).frequency) {
              self.error = null;

              currFrequency = selectedPlanA.frequency;

              if ( originalFrequency === 'a' && (currFrequency === 'm' || self.brokerPlan.quantity < originalQuantity ) ) {
                self.warning = 'ACCOUNT.OUTCOME.WARNING_LICENSES';  
                self.warningBoth = true;
              }
              else {
                self.warning = null;
                self.warningBoth = false;
              }

              return true;
            }
            else {
              self.error = 'ACCOUNT.OUTCOME.ERROR_FREQUENCY';
              return false;
            }
          }

          self.save = function(){

            if (subId) {

              var updateBroker = false;
              var updateAgency = false;

              if (self.agencyPlan.id != oldAgency.id) {
                updateAgency = true;
              }

              if (self.brokerPlan.id != oldBroker.id || self.brokerPlan.quantity != oldBroker.quantity) {
                updateBroker = true;
              }

              if (updateAgency || updateBroker) {
                if ((self.isAgency && self.checkPlans()) || !self.isAgency) {
                  self.error = null;
                  AlertService.loading();
                  PaymentService.updatePlan(subId, updateAgency ? self.agencyPlan : null, updateBroker ? self.brokerPlan : null).then(function(res){
                    $uibModalInstance.close(res);
                  })
                  .catch(function(err){
                    AlertService.doneLoading();
                    console.log("err: ", err);
                    $uibModalInstance.close(err);
                  })
                }
              }
              else {
                self.close();
              }
            }
            else {
              self.close();
            }
          }

        }) // End of controller
    })(); // End of function()
