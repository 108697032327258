/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
(function () {

	'use strict';

	angular.module('smartbrokr.directives')

	.controller('HeaderController', ['$rootScope', '$scope', '$timeout', 'AccountService', 'NavService', function ($rootScope, $scope, $timeout, AccountService, NavService) {
		const self = this;
		$scope.currItem = {};

		const setup = function () {
			// Navigation stack
			$scope.navStack = NavService.navStack;

			if (($scope.navStack || []).length > 1) {
				$scope.maxIndex = $scope.navStack.length - 1;
			}
			else {
				$scope.maxIndex = 0;
			}

			// Current item -> Used for mobile header
			$scope.currItem = $scope.navStack[$scope.maxIndex];

			// Gets name for crumb from the $rootScope, based on currentUser.
			if (($scope.currItem || {}).title === 'PAGES.CALENDAR.TITLE') {
				let name = null;
				const role = AccountService.getRole();

				/* Gets currentUser from $rootScope using a timeout function.
           * If currentUser is still not populated, tries again with a greater timeout */
				const getName = function(timeout) {
					$timeout(() => {
						name = ($rootScope.currentUser || {}).fullName || null;

						if (!name && timeout < 500) { // Control to avoid infinite loop
							timeout += 25;
							getName(timeout);
						}
						else {
							$scope.currItem.translateAttr = { user: name, s: (name || '').endsWith('s') ? false : true };
						}
					}, timeout);
				}

				// Admin or Manager -> Get name of person that they are managing
				if (role == 'adminProfile' || role == 'managerProfile') {
					const id = AccountService.getManagingBrokerId();
					NavService.getFullName(id, 'broker').then((res) => {
						name = ((res || {}).user || {}).fullName || '';
						$scope.currItem.translateAttr = { user: name, s: (name || '').endsWith('s') ? false : true };
					})
				}
				else {
					getName(0);
				}
			}
		}

		// Navigate stack
		$scope.goToStackItem = function (i) {

			let params = null;

			if (i < 0) {
				NavService.navigateBack();
			}
			else if (i < NavService.navStack.length - 1) {
				if ($scope.params != undefined && $scope.params != null) {
					params = $scope.params;
				}
				else if ($scope.hash != undefined && $scope.hash != null) {
					params = $scope.hash;
				}
				NavService.navigateStack(i, params);
			}
		}

		// Reloads stack if it was changed by NavService
		$rootScope.$on('changed-stack', (event, args) => {
			setup();
		});

		setup();

	}])

	/**
     * nav-header directive. Used for all headers.
     */
	.directive('navHeader', () => {
		return {
			restrict: 'E',
			controller: 'HeaderController',
			controllerAs: 'headerController',
			scope: {
				params: '=',
				addOn: '='
			},
			link: function ($scope, $element, $attrs) { },
			templateUrl: '/js/directives/header/nav-header.html'
		};
	})

	/**
     * mobile-header directive. Used for headers in the mobile version.
     */
	.directive('mobileHeader', () => {
		return {
			restrict: 'E',
			controller: 'HeaderController',
			controllerAs: 'headerController',
			scope: {},
			link: function ($scope, $element, $attrs) {},
			template: '<span translate="{{currItem.title}}" translate-values="{{currItem.translateAttr}}"></span>'
		};
	})

	/**
     * task-details-header directive.
     * Used for task details, which requires only one link back to Tasks with
     * hash parameters
     */
	.directive('taskDetailsHeader', () => {
		return {
			restrict: 'E',
			controller: 'HeaderController',
			controllerAs: 'headerController',
			scope: {
				hash: '='
			},
			link: function ($scope, $element, $attrs) { },
			template: '<span class="header" ng-repeat="page in navStack track by $index"><a href ng-click="goToStackItem(-1)" translate>{{page.title}}</a> <span ng-if="!$last"> > </span></span>'
		};
	})

})();
