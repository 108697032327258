(function () {

  "use strict";

  angular.module("smartbrokr")
    .controller("DeleteUserController", function ($uibModalInstance, AlertService, user, deleteFunction, id, userType) {

      var self = this;
      self.userType = userType;
      self.person = user;
      self.close = function () {
        $uibModalInstance.dismiss('cancel');
      };

      self.delete = function () {
        self.person.name = self.person.owner ? self.person.owner.fullName || self.person.fullName: self.person.fullName;
        deleteFunction(id)
          .then(function (res) {
            AlertService.success(self.person.name, 'delete');
            $uibModalInstance.close(res);
          })
          .catch(function (err) {})

      }

    }) // End of controller
})(); // End of function()
