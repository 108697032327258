(function () {

    "use strict";

    angular.module("smartbrokr")
    .controller("DocusignController", function ($localStorage, $filter, $log, $scope, AdminService, AlertService, DocusignService, GlobalVars, SortingService) {
        var self = this;
        self.showTooltips = $localStorage.docusignTooltips;

        self.enabled = true;
        self.disabled = !self.disabled;

        self.getDocusignStatus = function() {
            DocusignService.getStatus().then(function(response) {
                self.enabled = response.connected;
                self.disabled = !response.connected;
            }, function(error) {
                console.log('error', error);
            });
        }

        self.disconnect = function() {
            DocusignService.disconnect().then(function(response) {
                AlertService.successMessage('disconnect');
                self.enabled = false;
                self.disabled = true;
            }, function(error) {
                AlertService.errorMessage('disconnect');
            });
        }

        self.connect = function() {
            DocusignService.connect();
        }

        self.getDocusignStatus();

        $scope.$watch("docusignController.showTooltips", function(value) {
            $localStorage.docusignTooltips = value;
        });
    });
})();
