/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('PersonalEventController', function ($uibModalInstance, $scope, GlobalVars, VisitService, AlertService, event) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.event        = null;   // Event to be saved
		self.eventDate    = null;   // Date of event
		self.startTime    = null;   // Start time of event
		self.duration     = null;   // Duration of event
		self.calendar     = null;   // Object with calendar options for directive

		// SETUP VARIABLES ======================================================================================================================

		self.event = event || {};

		// Event exist, is getting edited
		if (event.start) {
			self.eventDate = new Date(event.start);
			self.startTime = new Date(event.start);
		}
		else {
			self.startTime = new Date();
			self.startTime.setHours( 8 );
			self.startTime.setMinutes( 0 );
		}

		// Event has a duration specified
		if (event.start && event.end) {
			/* Create Date object with hours and minutes set to
         * the difference between 'end' and 'start' */
			const diff = moment(event.end).diff(moment(event.start), 'minutes');
			const hours = Math.floor(diff / 60);
			const mins = diff - (hours * 60);
			self.duration = moment().hour(hours).minute(mins).toDate();
		}

		self.calendar = GlobalVars.createCalendarConfig(null,null);

		$uibModalInstance.scope = $scope;
		$uibModalInstance.check = {
			original: {
				event: angular.copy(self.event),
				date: angular.copy(self.eventDate),
				time: angular.copy(self.startTime)
			}
		}

		$scope.$on('modal.closing', () => {
			$uibModalInstance.check.clone = {
				event: self.event,
				date: self.eventDate,
				time: self.startTime
			}
		})

		// FUNCTIONS ============================================================================================================================

		/** Close modal without saving */
		self.close = function () {
			$uibModalInstance.dismiss('cancel');
		};

		/** Saves current event */
		self.save = function() {
			const start = moment(self.eventDate);
			const time  = moment(self.startTime);

			start.hour(time.hour());
			start.minute(time.minute());

			// Start = eventDate + startTime in one object
			self.event.start = start.toDate();

			if (self.duration) {
				const duration  = moment(self.duration);
				const end       = start.clone();

				end.add(duration.hour(), 'hours');
				end.add(duration.minute(), 'minutes');

				// End = startDate + duration
				self.event.end = end.toDate();
			}

			VisitService.savePersonal(self.event).then((res) => {
				$uibModalInstance.close(res);
			})
		}

	}) // End of controller
})(); // End of function()
