(function () {

    "use strict";

    angular.module("smartbrokr")
    .controller("VisitNotesController", function ($rootScope,$scope,$uibModalInstance,ListingService,visit,add) {

        var self = this;

        self.add = add;
        self.visit = visit;
        var author = ($rootScope.managing || {}).fullName || ($rootScope.currentUser || {}).fullName;

        self.newNote = {
          text: "",
          author: author
        }

        $uibModalInstance.scope = $scope;
        $uibModalInstance.check = {
          original: angular.copy(self.newNote),
          clone: self.newNote
        }

        self.save = function() {
          if (self.newNote.text.length > 0) {

            if (!self.visit.notes) {
              self.visit.notes = [];
            }
            self.newNote.created = new Date();
            self.visit.notes.push(self.newNote);
            self.visit.notes.sort(function(a,b){ return Date.parse(b.created) - Date.parse(a.created); })

            ListingService.updateVisit(self.visit.listingId, self.visit.id, self.visit).then(function(res) {
              self.newNote = {
                text: "",
                author: author
              }
              self.add = false;

              $uibModalInstance.check = {
                original: angular.copy(self.newNote),
                clone: self.newNote
              }
            })
          }
        }

        self.cancel = function() {
          if (!!self.newNote.created) {
            self.visit.notes.push(self.newNote);
            self.visit.notes.sort(function(a,b){ return Date.parse(b.created) - Date.parse(a.created); })
          }
          self.newNote = {
            text: "",
            author: author
          }
          self.add = false;
          $uibModalInstance.check = {
            original: angular.copy(self.newNote),
            clone: self.newNote
          }
        }

        self.editNote = function(i) {
          self.newNote = self.visit.notes.splice(i,1)[0];
          self.add = true;
          $uibModalInstance.check = {
            original: angular.copy(self.newNote),
            clone: self.newNote
          }
        }

        self.deleteNote = function(i) {
          self.visit.notes.splice(i,1);
          ListingService.updateVisit(self.visit.listingId, self.visit.id, self.visit).then(function(res) {});
        }

        self.close = function () {
          $uibModalInstance.dismiss('cancel');
        };

  }) // End of controller
})(); // End of function()
