/* eslint-disable no-undef */
'use strict';
(function () {
	angular.module('smartbrokr.docusign', [])
	.service('DocusignService', function (Docusign, $http, $rootScope, $sce, $window, AccountService) {
		const self = this;

		self.sendDocument = function(fileId) {
			const url = $sce.valueOf($sce.trustAsResourceUrl($rootScope.baseURL + '/smartFiles/' + fileId + '/docusign/create-envelope?access_token=' + AccountService.getAccessToken()));
			return $http.get(url);
		}

		self.connect = function() {
			connect();
		}

		self.disconnect = function() {
			return Docusign.disconnect().$promise;
		}

		self.getStatus = function() {
			return Docusign.getStatus().$promise;
		}

		function connect() {
			let url = $sce.valueOf($sce.trustAsResourceUrl($rootScope.baseURL + '/docusign/connect'));
			const params = {
				access_token: AccountService.getAccessToken(),
			};

			url = [url, $.param(params)].join('?');

			return popup(url, 300, 400);
		}

		function popup(url, width, height) {
			const windowArea = {
				width: width || ($window.outerWidth - ($window.outerWidth / 3)),            // If not specified, screen width - 1/3
				height: height || ($window.outerHeight - ($window.outerHeight / 8)),        // If not specified, screen height - 1/8
			};

			windowArea.left = Math.floor($window.screenX + (($window.outerWidth - windowArea.width) / 2));
			windowArea.top = Math.floor($window.screenY + (($window.outerHeight - windowArea.height) / 4));

			const windowOpts = 'toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0,' +
                'width=' + windowArea.width + ',height=' + windowArea.height + ',' +
                'left=' + windowArea.left + ',top=' + windowArea.top;

			// Create IE + others compatible event handler
			const eventMethod = $window.addEventListener ? 'addEventListener' : 'attachEvent';
			const eventer = $window[eventMethod];
			const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

			// Listen to message from child window
			const authPromise = new Promise(((resolve, reject) => {
				eventer(messageEvent, (e) => {
					if (e.data) {
						return resolve(e.data);
					}
					reject(':(');
				}, false);
			}));

			$window.open(url, 'DocuSign', windowOpts);
			return authPromise;
		}

		self.popup = popup;

	});
})() // End of function()
